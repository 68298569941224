import api from "./api";
import axios from "axios";
import CommonHelper from "../helpers/common";

const vehicleApi = {
  get_vehicle_usage: async () => {
    var callApi = await api.get("api/vehicle/get_vehicle_usage");
    if (callApi.code == 200) {
      return callApi.data;
    } else if (callApi.code === 401) {
      console.log(callApi);
    }
    return null;
  },
  get_vehicle_type: async () => {
    var session_id = CommonHelper.get_session_id();
    /*if (CommonHelper.is_not_empty(session_id)) {
      var callApi = await api.get(
        "vehicle/get_vehicle_type?session_id=" + session_id
      );
      if (callApi.code == 200) {
        return callApi.data;
      } else if (callApi.code === 401) {
        console.log(callApi);
      }
    }*/
    var callApi = await api.get(
      "vehicle/get_vehicle_type?session_id=" + session_id
    );
    if (callApi.code == 200) {
      return callApi.data;
    } else if (callApi.code === 401) {
      console.log(callApi);
    }
    return null;
  },
  get_mvi_condition_premium: async (model) => {
    if (model != null) {
      var callApi = await api.post(
        "vehicle/get_mvi_condition_premium", model
      );
      if (callApi.code == 200) {
        return callApi.data;
      } else if (callApi.code === 401) {
        console.log(callApi);
      }
    }
    return null;
  },
  get_mvi_premium: async (model) => {
    if (model != null) {
      var callApi = await api.post(
        "vehicle/get_mvi_premium", model
      );
      if (callApi.code == 200) {
        return callApi.data;
      } else if (callApi.code === 401) {
        console.log(callApi);
      }
    }
    return null;
  },
  get_mvi_ctplext_premium: async (model) => {
    if (model != null) {
      var callApi = await api.post(
        "vehicle/get_mvi_ctplext_premium", model
      );
      if (callApi.code == 200) {
        return callApi.data;
      } else if (callApi.code === 401) {
        console.log(callApi);
      }
    }
    return null;
  },
  get_premium_vehicle: async (model) => {
    if (model != null) {
      var callApi = await api.post(
        "vehicle/get_premium", model
      );
      if (callApi.code == 200) {
        return callApi.data;
      } else if (callApi.code === 401) {
        console.log(callApi);
      }
      /*callApi.then((res) => {
        if (res.code == 200) {
          return res.data;
        } else if (res.code === 401) {
          console.log(res);
        }
      })*/
    }
    return null;
  },
  get_premium_moto: async (model) => {
    if (model != null) {
      var callApi = await api.post(
        "vehicle/get_premium_moto", model
      );
      if (callApi.code == 200) {
        return callApi.data;
      } else if (callApi.code === 401) {
        console.log(callApi);
      }
    }
    return null;
  },
  pay_ctpl_vehicle: async (model) => {
    if (model != null) {
      var callApi = await api.post(
        "vehicle/pay_ctpl_vehicle", model
      );
      if (callApi.code == 200) {
        return callApi.data;
      } else if (callApi.code === 401) {
        console.log(callApi);
      }
    }
    return null;
  },
  post_order: async (model) => {
    if (model != null) {
      var session_id = CommonHelper.get_session_id();
      var data = {
        session_id: session_id,
        customer_id: model.veh_customer_id,
        sc_code: model.veh_org_sc_code,
        total_amt: model.veh_premium,
        order_detail: model,
        return_url: window.location.origin,
        cancel_url: model.veh_url,
        order_no: model.veh_order_no,
        notify_url: process.env.REACT_APP_API_ENDPOINT
      };
      //console.log(data);
      var callApi = await api.post(
        "order/post", data
      );
      if (callApi.code === 204 || callApi.code === 704 || callApi.code === 721) {
        return callApi;
      } else if (callApi.code === 401) {
        console.log(callApi);
      }
    }
  },
  get_order: async (id) => {
    var callApi = await api.get(
      "order/" + id
    );
    if (callApi.code == 200) {
      return callApi.data;
    } else if (callApi.code === 401) {
      console.log(callApi);
    }
    return null;
  },
  get_order_by_customer: async () => {
    var session_id = CommonHelper.get_session_id();
    var callApi = await api.get(
      "order/get_order_by_customer?session_id=" + session_id
    );
    /*if (callApi.code === 200) {
      return callApi.data;
    } else if (callApi.code === 401) {
      console.log(callApi);
    }*/
    return callApi;
  },
  get_orders: async (param) => {
    var session_id = CommonHelper.get_session_id();
    var _q = "?session_id="+session_id;
    var callApi = await api.get(
      "order/get_orders" + _q + param
    );
    return callApi;
  },
  get_payment_url: async (form) => {
    var response = await axios.post(process.env.REACT_APP_PAYMENT_GW_URL, form);
    return response;
  },
  get_payment_bank_key: async () => {
    var callApi = await api.get("order/get_payment_bank_key");
    return callApi;
  },
  get_payment_bank_list: async (url, form) => {
    var response = await axios.post(url, form);
    return response;
  },
  get_mbv_premium: async (model) => {
    if (model != null) {
      var callApi = await api.get(
        "vehicle/get_mbv_premium?date=" + model.date + "&seat=" + model.seat + "&num_of_years=" + model.years, model
      );
      if (callApi.code == 200) {
        return callApi.data;
      } else if (callApi.code === 401) {
        console.log(callApi);
      }
    }
    return null;
  },
  get_mvc_premium_vehicle: async (model) => {
    if (model != null) {
      var callApi = await api.post(
        "vehicle/get_mvc_premium", model
      );
      if (callApi.code == 200) {
        return callApi.data;
      } else if (callApi.code === 401) {
        console.log(callApi);
      }
    }
    return null;
  },
  get_mdp_premium: async (model) => {
    if (model != null) {
      var callApi = await api.get(
        "vehicle/get_mdp_premium?date=" + model.date + "&seat=" + model.seat + "&num_of_years=" + model.years, model
      );
      if (callApi.code == 200) {
        return callApi.data;
      } else if (callApi.code === 401) {
        console.log(callApi);
      }
    }
    return null;
  },
  get_payment_check_status: async (id) => {
    var callApi = await api.get("order/get_payment_check_order_key?order=" + id);
    return callApi;
  },
  put_order_token: async (order, token) => {
    var callApi = await api.put("order/update_token_code?order_no=" + order + "&token=" + token);
    return callApi;
  }
};

export default vehicleApi;
