import React from 'react'
import vi from 'date-fns/locale/vi';
import ReactDatePicker, { registerLocale } from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
registerLocale('vi', vi);

function DateInput(props) {
    const handleDateChange = (date) => {
        if (date) {
            // Set default time to 12:00 PM (noon)
            date.setHours(12);
            date.setMinutes(0);
            date.setSeconds(0);
        }
        props.onChange(date);
    };

    return (
        <ReactDatePicker
            {...props}
            shouldCloseOnSelect
            isClearable
            showPopperArrow={false}
            // showTimeSelect
            // timeCaption='Giờ'
            locale="vi"
            dateFormat={props.dateFormat === undefined ? "dd/MM/yyyy HH:mm:00" : props.dateFormat}
            showMonthDropdown={true}
            dropdownMode="select"
            className={'form-control form-control-sm ' + props.className} 
            // onChange={handleDateChange} 
            />
    )
}

export default DateInput