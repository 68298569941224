import React, { useState, useEffect } from 'react';
import { Modal, Table } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

const PetPackageDetail = ({ isOpen, selectedPackage, onClose }) => {
    const [show, setShow] = useState(isOpen);
    useEffect(() => {
        setShow(isOpen);
    }, [isOpen]);

    const handleClose = () => {
        setShow(false);
        onClose();
    };

    return (
        <>
            <Modal 
                show={show} 
                onHide={handleClose} 
                size="lg" 
                backdrop="static"
            >
                <Modal.Header closeButton>
                    <Modal.Title style={{color: '#20c997'}}>
                        • {selectedPackage.pkg_name}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ maxHeight: '70vh', overflowY: 'auto' }}>
                    <div className="wrapper-tb-detail justify-content-center w-100 d-md-flex">
                        <Table 
                            borderless 
                            className="table-content-benefit"
                        >
                            <tbody>
                                <tr className="table-success">
                                    <td 
                                        style={{
                                            verticalAlign: 'middle', 
                                            width: '80%', 
                                            color: 'rgb(118 90 90)', 
                                            paddingTop: '12px', 
                                            paddingBottom: '0px'
                                        }}
                                    >
                                        <ol 
                                            className="benefit-table w-100" 
                                            type="I"
                                        >
                                            <strong>
                                                Phần 1: CHI PHÍ ĐIỀU TRỊ NGOẠI TRÚ <br /> 
                                                (Giới hạn: điều trị các bệnh liên quan đến da & lông: tối đa 2 lần)
                                            </strong>
                                        </ol>
                                    </td>
                                    <td style={{verticalAlign: 'middle'}}>
                                        <strong 
                                            className="d-block text-break" 
                                            style={{
                                                verticalAlign: 'middle', 
                                                color: 'rgb(118 90 90)', 
                                                paddingTop: '22px'
                                            }}
                                        >
                                        </strong>
                                    </td>
                                </tr>
                                <tr className="level-1 border-bottom-0">
                                    <td>
                                        <ol 
                                            start={1} 
                                            className="m-0 pl-0 list-style-type-alpha"
                                        >
                                            <li>
                                                Tối đa mỗi lần
                                            </li>
                                        </ol>
                                    </td>
                                    <td style={{fontWeight: 'bold'}}>
                                        {selectedPackage.smi1.list[0].si_amt + " VND"} 
                                    </td>
                                </tr>
                                <tr className="level-1 border-bottom-0">
                                    <td>
                                        <ol 
                                            start={2} 
                                            className="m-0 pl-0 list-style-type-alpha"
                                        >
                                            <li>
                                                Tổng mức bồi thường tối đa
                                            </li>
                                        </ol>
                                    </td>
                                    <td style={{fontWeight: 'bold'}}>
                                        {selectedPackage.smi1.list[1].si_amt + " VND"} 
                                    </td>
                                </tr>
                                <tr className="table-success">
                                    <td 
                                        style={{
                                            verticalAlign: 'middle', 
                                            color: 'rgb(118 90 90)', 
                                            paddingTop: '12px', 
                                            paddingBottom: '0px'
                                        }}
                                    >
                                        <ol 
                                            className="benefit-table w-100" 
                                            type="I"
                                        >
                                            <strong>
                                                Phần 2: CHI PHÍ ĐIỀU TRỊ NỘI TRÚ
                                            </strong>
                                        </ol>
                                    </td>
                                    <td 
                                        style={{
                                            verticalAlign: 'middle', 
                                            color: 'rgb(118 90 90)', 
                                            paddingTop: '22px'
                                        }}
                                    >
                                        <strong className="d-block text-break">
                                        </strong>
                                    </td>
                                </tr>
                                <tr className="level-1 border-bottom-0">
                                    <td>
                                        <ol 
                                            start={1} 
                                            className="m-0 pl-0 list-style-type-number"
                                        >
                                            <li>
                                                Tối đa mỗi lần
                                            </li>
                                        </ol>
                                    </td>
                                    <td style={{fontWeight: 'bold'}}>
                                        {selectedPackage.smi2.list[0].si_amt + " VND"} 
                                    </td>
                                </tr>
                                <tr className="level-1 border-bottom-0">
                                    <td>
                                        <ol 
                                            start={2} 
                                            className="m-0 pl-0 list-style-type-alpha"
                                        >
                                            <li>
                                                Tổng mức bồi thường tối đa
                                            </li>
                                        </ol>
                                    </td>
                                    <td style={{fontWeight: 'bold'}}>
                                        {selectedPackage.smi2.list[1].si_amt + " VND"} 
                                    </td>
                                </tr>
                                <tr className="table-success">
                                    <td 
                                        style={{
                                            verticalAlign: 'middle', 
                                            color: 'rgb(118 90 90)', 
                                            paddingTop: '12px', 
                                            paddingBottom: '0px'
                                        }}
                                    >
                                        <ol 
                                            className="benefit-table w-100" 
                                            type="I"
                                        >
                                            <strong>
                                                Phần 3: CHI PHÍ PHẪU THUẬT
                                            </strong>
                                        </ol>
                                    </td>
                                    <td 
                                        style={{
                                            verticalAlign: 'middle', 
                                            color: 'rgb(118 90 90)', 
                                            paddingTop: '22px'
                                        }}
                                    >
                                        <strong></strong>
                                    </td>
                                </tr>
                                <tr className="level-1 border-bottom-0">
                                    <td>
                                        <ol 
                                            start={1} 
                                            className="m-0 pl-0 list-style-type-number"
                                        >
                                            <li>
                                                Tối đa mỗi lần
                                            </li>
                                        </ol>
                                    </td>
                                    <td style={{fontWeight: 'bold'}}>
                                        {selectedPackage.smi3.list[0].si_amt + " VND"}
                                    </td>
                                </tr>
                                <tr className="level-1 border-bottom-0">
                                    <td>
                                        <ol 
                                            start={2} 
                                            className="m-0 pl-0 list-style-type-number"
                                        >
                                            <li>
                                                Tổng mức bồi thường tối đa
                                            </li>
                                        </ol>
                                    </td>
                                    <td style={{fontWeight: 'bold'}}>
                                        {selectedPackage.smi3.list[0].si_amt + " VND"}
                                    </td>
                                </tr>
                                <tr className="table-success">
                                    <td 
                                        style={{
                                            verticalAlign: 'middle', 
                                            color: 'rgb(118 90 90)', 
                                            paddingTop: '12px', 
                                            paddingBottom: '0px'
                                        }}
                                    >
                                        <ol 
                                            className="benefit-table w-100" 
                                            type="I"
                                        >
                                            <strong>
                                                Phần 4: TRÁCH NHIỆM BỒI THƯỜNG THIỆT HẠI DO THÚ CƯNG GÂY RA
                                            </strong>
                                        </ol>
                                    </td>
                                    <td 
                                        style={{
                                            verticalAlign: 'middle', 
                                            color: 'rgb(118 90 90)', 
                                            paddingTop: '22px'
                                        }}
                                    >
                                        <strong></strong>
                                    </td>
                                </tr>
                                <tr className="level-1 border-bottom-0">
                                    <td>
                                        <ol 
                                            start={1} 
                                            className="m-0 pl-0 list-style-type-number"
                                        >
                                            <li>
                                                Tổng mức bồi thường tối đa
                                            </li>
                                        </ol>
                                    </td>
                                    <td style={{fontWeight: 'bold'}}>
                                        {selectedPackage.smi4.list[0].si_amt + " VND"}
                                    </td>
                                </tr>
                                <tr className="table-success">
                                    <td 
                                        style={{
                                            verticalAlign: 'middle', 
                                            color: 'rgb(118 90 90)', 
                                            paddingTop: '12px', 
                                            paddingBottom: '0px'
                                        }}
                                    >
                                        <ol 
                                            className="benefit-table w-100" 
                                            type="I"
                                        >
                                            <strong>
                                                Phần 5: CHI PHÍ QUẢNG CÁO TÌM KIẾM THÚ CƯNG BỊ LẠC
                                            </strong>
                                        </ol>
                                    </td>
                                    <td 
                                        style={{
                                            verticalAlign: 'middle', 
                                            color: 'rgb(118 90 90)', 
                                            paddingTop: '22px'
                                        }}
                                    >
                                        <strong></strong>
                                    </td>
                                </tr>
                                <tr className="level-1 border-bottom-0">
                                    <td>
                                        <ol 
                                            start={1} 
                                            className="m-0 pl-0 list-style-type-number"
                                        >
                                            <li>
                                                Tổng mức bồi thường tối đa
                                            </li>
                                        </ol>
                                    </td>
                                    <td style={{fontWeight: 'bold'}}>
                                        {selectedPackage.smi5.list[0].si_amt + " VND"} 
                                    </td>
                                </tr>
                                <tr className="table-success">
                                    <td 
                                        style={{
                                            verticalAlign: 'middle', 
                                            color: 'rgb(118 90 90)', 
                                            paddingTop: '12px', 
                                            paddingBottom: '0px'
                                        }}
                                    >
                                        <ol 
                                            className="benefit-table w-100" 
                                            type="I"
                                        >
                                            <strong>
                                                Phần 6: CHI PHÍ CHĂM SÓC THÚ CƯNG TRONG THỜI GIAN NĐBH NẰM VIỆN (tối đa 10 ngày)
                                            </strong>
                                        </ol>
                                    </td>
                                    <td 
                                        style={{
                                            verticalAlign: 'middle', 
                                            color: 'rgb(118 90 90)', 
                                            paddingTop: '22px'
                                        }}
                                    >
                                        <strong></strong>
                                    </td>
                                </tr>
                                <tr className="level-1 border-bottom-0">
                                    <td>
                                        <ol 
                                            start={1} 
                                            className="m-0 pl-0 list-style-type-number"
                                        >
                                            <li>
                                                Tổng mức bồi thường tối đa
                                            </li>
                                        </ol>
                                    </td>
                                    <td style={{fontWeight: 'bold'}}>
                                        {selectedPackage.smi6.list[0].si_amt + " VND"} 
                                    </td>
                                </tr>
                                <tr className="table-success">
                                    <td 
                                        style={{
                                            verticalAlign: 'middle', 
                                            color: 'rgb(118 90 90)', 
                                            paddingTop: '12px', 
                                            paddingBottom: '0px'
                                        }}
                                    >
                                        <ol 
                                            className="benefit-table w-100" 
                                            type="I"
                                        >
                                            <strong>
                                                Phần 7: CHI PHÍ TANG LỄ CHO THÚ CƯNG
                                            </strong>
                                        </ol>
                                    </td>
                                    <td 
                                        style={{
                                            verticalAlign: 'middle', 
                                            color: 'rgb(118 90 90)', 
                                            paddingTop: '22px'
                                        }}
                                    >
                                        <strong></strong>
                                    </td>
                                </tr>
                                <tr className="level-1 border-bottom-0">
                                    <td>
                                        <ol 
                                            start={1} 
                                            className="m-0 pl-0 list-style-type-number"
                                        >
                                            <li>
                                                Tổng mức bồi thường tối đa
                                            </li>
                                        </ol>
                                    </td>
                                    <td style={{fontWeight: 'bold'}}>
                                        {selectedPackage.smi7.list[0].si_amt + " VND"} 
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default PetPackageDetail;