import React, { useEffect, useState } from 'react'
import { Container, Col, Row } from 'react-bootstrap'
import HomeProductDetail from './component/bs-product-detail';
import ProductImage_1 from '../../assets/images/MBC.png'
import ProductImage_2 from '../../assets/images/mvc.png'
import ProductImage_3 from '../../assets/images/PAI.png'
import ProductImage_4 from '../../assets/images/PET.png'
import FBGate from '../../components/fb-gate';

const products = [
    {
        "img_url": <img src={ProductImage_1} />,
        "title": "Bảo hiểm xe máy",
        "short_content": "Tổng mức bồi thường lên tới 150 triệu VND",
        "long_content": "Bảo vệ cả người và tài sản xe cơ giới khi có tai nạn xảy ra",
        "url": "/sale/ctpl-moto",
    },
    {
        "img_url": <img src={ProductImage_2} />,
        "title": "Bảo hiểm xe ô tô",
        "short_content": "Tổng mức bồi thường lên tới 150 triệu VND",
        "long_content": "Bảo vệ cả người và tài sản xe cơ giới khi có tai nạn xảy ra",
        "url": "/sale/ctpl-vehicle",
    },
    {
        "img_url": <img src={ProductImage_3} />,
        "title": "Bảo hiểm tai nạn cá nhân",
        "short_content": "Trợ cấp mất thu nhập trong thời gian nằm viện",
        "long_content": "Chi trả về chi phí y tế, tổn thất nếu có thương tật hay tử vong do tai nạn",
        "url": "/sale/pa-insurance",
    },
    /*{
        "img_url": <img src={ProductImage_4} />,
        "title": "Bảo hiểm thú cưng",
        "short_content": "",
        "long_content": "",
        "url": "/sale/pet-home",
    },*/
];


function Home() {
    const [{ showOpt, showBSProduct }, setShow] = useState({
        showOpt: true,
        showBSProduct: false
    });
    const [{ product }, setSelected] = useState({
        product: products[0],
    })
    const handle_change_product = (item) => {
        console.log(item)
        setSelected((prev) => ({ ...prev, product: item }));
        setShow((prev) => ({ ...prev, showBSProduct: true }));
    }
    return (
        <>
            <Row>
                <div className='col-sm-12'>
                    <div className='container'>
                        <div className='row'>
                            {products.map((item, idx) => {
                                return (
                                    <div className='col-sm-4 prod-item' key={idx}>
                                        <a>
                                            <div className='prod-image'>
                                                {item.img_url}
                                            </div>
                                            <div className='prod-title'>
                                                <h2>{item.title}</h2>
                                                <p><b>{item.short_content}</b></p>
                                                <p style={{ height: "48px" }}>{item.long_content}</p>
                                                <div className="read-more-btn">
                                                    <a className="edu-btn btn-small btn-secondary" href="javascript:;" onClick={() => { handle_change_product(item); }}>Chi tiết</a></div>
                                            </div>
                                        </a>
                                    </div>
                                );
                            })}
                        </div>
                        <div className='clearfix'></div>
                    </div>
                </div>
                <div className='clearfix'></div>
            </Row>
            {showOpt && (<FBGate onClose={() => { setShow((prev) => ({ ...prev, showOpt: false })) }} />)}
            <div></div>
            <HomeProductDetail show={showBSProduct} url={product.url} title={product.title} onClose={(e) => { setShow((prev) => ({ ...prev, showBSProduct: e })) }} />
        </>
    )
}

export default Home