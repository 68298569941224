/* eslint-disable react/jsx-no-target-blank */
import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import * as FaIcon from "react-icons/fa";
import fbBanner from "../../../../assets/images/logo.svg";
import "./index.css";
import trainingApi from '../../../../api/trainingApi';
import RadioGroup from './component/radio-group';
import CriteriaTable from './component/criteria-table';
import { FaSave, FaBackward } from "react-icons/fa";
import AlertBox from '../../../../components/alert';
import { Link } from 'react-router-dom';

const initialAlert = {
    is_show: false,
    title: "",
    content: "",
    variant: "danger"
};

const VoteExam = () => {
    const [listVoteCriteria1, setListVoteCriteria1] = useState([]);
    const [listVoteCriteria2, setListVoteCriteria2] = useState([]);
    const [listVoteCriteria3, setListVoteCriteria3] = useState([]);
    const [satisfaction, setSatisfaction] = useState('');
    const [applicability, setApplicability] = useState('');
    const [criteriaRatings1, setCriteriaRatings1] = useState({});
    const [criteriaRatings2, setCriteriaRatings2] = useState({});
    const [criteriaRatings3, setCriteriaRatings3] = useState({});
    const [likes, setLikes] = useState('');
    const [dislikes, setDislikes] = useState('');
    const [loading, setLoading] = useState(true);
    const [alertData, setAlertData] = useState(initialAlert);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [lst_vote_1, lst_vote_2, lst_vote_3] = await Promise.all([
                    trainingApi.get_vote_criterias(0, 1),
                    trainingApi.get_vote_criterias(0, 2),
                    trainingApi.get_vote_criterias(0, 3)
                ]);
                setListVoteCriteria1(lst_vote_1.data);
                setListVoteCriteria2(lst_vote_2.data);
                setListVoteCriteria3(lst_vote_3.data);
                setLoading(false);
            } catch (error) {
                console.error("Error fetching vote criteria data:", error);
                setLoading(false);
            }
        };
        fetchData();
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        const data = {
            "Review_1": satisfaction,
            "Review_2": applicability,
            "Review_3": JSON.stringify(criteriaRatings1),
            "Review_4": JSON.stringify(criteriaRatings2),
            "Review_5": JSON.stringify(criteriaRatings3),
            "Review_6": likes,
            "Review_7": dislikes,
            "FullName": localStorage.getItem("ACC_NAME") ?? "",
            "UserName": localStorage.getItem("ACC_EXAM") ?? ""
        };
        try {
            setAlertData((prev) => ({
                ...prev,
                title: "Thông báo",
                content: "Lưu bài đánh giá/nhận xét thành công!!!",
                is_show: true,
                variant: "success"
            }));
            var res = await trainingApi.post_vote(data);
            if (res.code === 200) {
                setAlertData((prev) => ({
                  ...prev,
                  title: "Thông báo",
                  content: "Bài đánh giá/nhận xét đã được lưu thành công.",
                  is_show: true,
                  variant: "success"
                }));
              } else {
                setAlertData((prev) => ({
                  ...prev,
                  title: "Lỗi",
                  content: "Đã xảy ra lỗi khi lưu bài đánh giá/nhận xét. Vui lòng kiểm tra lại!!!",
                  is_show: true,
                  variant: "danger"
                }));
              }
            // await trainingApi.submit_vote(data);
            console.log("data", data);
        } catch (error) {
            console.error("Error submitting vote data:", error);
        }
    };

    const handleRadioChange = (setState) => (e) => {
        setState(e.target.value);
    };

    const handleCriteriaChange = (setState) => (index, value) => {
        setState(prevState => ({ ...prevState, [index]: value }));
    };

    return (
        <>
            <div className="container">
                <form onSubmit={handleSubmit}>
                    <div className='row mb-4'>
                        {/* <h1 className='text-center'>ĐÁNH GIÁ/NHẬN XÉT ĐÀO TẠO THI ĐẠI LÝ</h1> */}
                    </div>
                    {loading ? (
                        <div className="text-center">
                            <div className="spinner-border" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>
                        </div>
                    ) : (
                        <>
                            <h5 className='vote-required'>1. Đánh giá mức độ hài lòng về khóa học</h5>
                            <RadioGroup name="satisfaction" options={["Rất hài lòng", "Hài lòng", "Không hài lòng"]} onChange={handleRadioChange(setSatisfaction)} />
                            <h5 className='vote-required mt30px'>2. Đánh giá khả năng ứng dụng vào công việc</h5>
                            <RadioGroup name="applicability" options={["Rất cao", "Cao", "Trung bình"]} onChange={handleRadioChange(setApplicability)} />
                            <h5 className='vote-required mt30px'>3. Đánh giá về nội dung khóa học</h5>
                            <CriteriaTable criteriaList={listVoteCriteria1} prefix="3" onChange={handleCriteriaChange(setCriteriaRatings1)} />
                            <h5 className='vote-required mt30px' style={{paddingTop: '50px'}}>4. Đánh giá về giảng viên</h5>
                            <CriteriaTable criteriaList={listVoteCriteria2} prefix="4" onChange={handleCriteriaChange(setCriteriaRatings2)} />
                            <h5 className='vote-required mt30px'>5. Đánh giá về công tác tổ chức</h5>
                            <CriteriaTable criteriaList={listVoteCriteria3} prefix="5" onChange={handleCriteriaChange(setCriteriaRatings3)} />
                            <h5 className='mt30px'>6. Đánh giá khác của học viên (điều học viên thích)</h5>
                            <div className="row">
                                <textarea className="form-control" style={{ resize: 'vertical', height: '100px', borderRadius: '0px' }} value={likes} onChange={(e) => setLikes(e.target.value)} />
                            </div>
                            <h5 className='mt30px'>7. Đánh giá khác của học viên (điều học viên không thích)</h5>
                            <div className="row">
                                <textarea className="form-control" style={{ resize: 'vertical', height: '100px', borderRadius: '0px' }} value={dislikes} onChange={(e) => setDislikes(e.target.value)} />
                            </div>
                            <div className="mt30px text-center">
                                {/* <Link to="/exam" className="btn btn-outline-primary" style={{ borderRadius: '0px', marginRight: '16px' }}><FaBackward /> Quay lại</Link> */}
                                <Button type="submit" className="btn btn-primary" style={{ borderRadius: '0px' }}>Lưu bài <FaSave /></Button>
                            </div>
                        </>
                    )}
                </form>
            </div >
            <AlertBox data={alertData} onChange={(e) => { setAlertData((prev) => ({ ...prev, is_show: e })) }} />
        </>
    );
};

export default VoteExam;
