import React from 'react';
import { FaFileContract } from "react-icons/fa";
import { FaCartPlus } from "react-icons/fa";
import { FaMoneyCheckAlt } from "react-icons/fa";
import { FaRegCreditCard } from "react-icons/fa";
import { FaDog } from "react-icons/fa";

function PetGuide(props) {
    return (
        <>
            <section className="container">
                <div 
                    className="title_section" 
                    data-descr="Hướng dẫn mua bảo hiểm" 
                    style={{color: '#564741' }}
                >
                    <span>
                        Hướng dẫn mua bảo hiểm
                    </span>
                </div>
                <div 
                    className="row justify-content-center" 
                    style={{alignItems: 'center'}}
                >
                    <div className="col-lg-2 col-sm-12">
                        <div
                            className="card text-center font-weight-bold mg-sm-b-15 mg-sm-x-30"
                            style={{ 
                                borderRadius: 4, 
                                border: "6px solid #20c997", 
                                height: 145, 
                                marginTop: '16px' 
                            }}
                        >
                            <div 
                                className="numberCircleGuide" 
                                style={{backgroundColor: '#564741'}}
                            >
                                01
                            </div>
                            <div>
                                <FaDog 
                                    style={{
                                        fontSize: '30px', 
                                        color: 'black'
                                    }} 
                                />
                            </div>
                            <p>
                                Chọn loại thú cưng
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-2 col-sm-12">
                        <div
                            className="card text-center font-weight-bold mg-sm-b-15 mg-sm-x-30"
                            style={{ 
                                borderRadius: 4, 
                                border: "6px solid #20c997", 
                                height: 145, 
                                marginTop: '16px' 
                            }}
                        >
                            <div 
                                className="numberCircleGuide" 
                                style={{backgroundColor: '#564741'}}
                            >
                                02
                            </div>
                            <div>
                                <FaFileContract 
                                    style={{
                                        fontSize: '30px', 
                                        color: 'orange'
                                    }} 
                                />
                            </div>
                            <p>
                                Chọn gói bảo hiểm
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-2 col-sm-12">
                        <div
                            className="card text-center font-weight-bold mg-sm-b-15 mg-sm-x-30"
                            style={{ 
                                borderRadius: 4, 
                                border: "6px solid #20c997", 
                                height: 145, 
                                marginTop: '16px' 
                            }}
                        >
                            <div 
                                className="numberCircleGuide" 
                                style={{backgroundColor: '#564741'}}
                            >
                                03
                            </div>
                            <div>
                                <FaCartPlus 
                                    style={{
                                        fontSize: '30px', 
                                        color: 'green'
                                    }} 
                                />
                            </div>
                            <p>
                                Yêu cầu bảo hiểm
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-2 col-sm-12">
                        <div
                            className="card text-center font-weight-bold mg-sm-b-15 mg-sm-x-30"
                            style={{ 
                                borderRadius: 4, 
                                border: "6px solid #20c997", 
                                height: 145, 
                                marginTop: '16px' 
                            }}
                        >
                            <div 
                                className="numberCircleGuide" 
                                style={{backgroundColor: '#564741'}}
                            >
                                04
                            </div>
                            <div>
                                <FaMoneyCheckAlt 
                                    style={{
                                        fontSize: '30px', 
                                        color: 'blue'
                                    }} 
                                />
                            </div>
                            <p>
                                Xác nhận thanh toán
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-2 col-sm-12">
                        <div
                            className="card text-center font-weight-bold mg-sm-b-15 mg-sm-x-30"
                            style={{ 
                                borderRadius: 4, 
                                border: "6px solid #20c997", 
                                height: 145, 
                                marginTop: '16px' 
                            }}
                        >
                            <div 
                                className="numberCircleGuide" 
                                style={{backgroundColor: '#564741'}}
                            >
                                05
                            </div>
                            <div>
                                <FaRegCreditCard 
                                    style={{
                                        fontSize: '30px', 
                                        color: 'red'
                                    }} 
                                />
                            </div>
                            <p>
                                Thanh toán và nhận giấy chứng nhận qua email
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default PetGuide;