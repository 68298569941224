const Nationality = [
    { "code": "VT", "name": "VIETNAM" },
    { "code": "AF", "name": "AFGHANISTAN" },
    { "code": "AC", "name": "AFRICA" },
    { "code": "AJ", "name": "AJASTAN" },
    { "code": "AX", "name": "ALAND ISLANDS" },
    { "code": "AB", "name": "ALBANIA" },
    { "code": "AL", "name": "ALGERIA" },
    { "code": "AD", "name": "ANDORRA" },
    { "code": "AN", "name": "ANGOLA" },
    { "code": "AI", "name": "ANGUILLA" },
    { "code": "AQ", "name": "ANTARCTICA" },
    { "code": "AT", "name": "ANTIGUA" },
    { "code": "AR", "name": "ARGENTINA" },
    { "code": "AM", "name": "ARMENIA" },
    { "code": "AW", "name": "ARUBA" },
    { "code": "AS", "name": "ASIA" },
    { "code": "AA", "name": "AUSTRALIA" },
    { "code": "AU", "name": "AUSTRIA" },
    { "code": "AZ", "name": "AZERBAIJAN" },
    { "code": "BH", "name": "BAHAMAS" },
    { "code": "BN", "name": "BAHRAIN" },
    { "code": "BS", "name": "BANGLADESH" },
    { "code": "BA", "name": "BARBADOS" },
    { "code": "BC", "name": "BELARUS" },
    { "code": "BG", "name": "BELGIUM" },
    { "code": "BE", "name": "BELIZE" },
    { "code": "EN", "name": "BENIN" },
    { "code": "BB", "name": "BENIN" },
    { "code": "BM", "name": "BERMUDA" },
    { "code": "BT", "name": "BHUTAN" },
    { "code": "BV", "name": "BOLIVIA" },
    { "code": "BO", "name": "BOLTUTA" },
    { "code": "OS", "name": "BONAIRE, SINT EUSTATIUS AND SABA" },
    { "code": "IA", "name": "BOSNIA" },
    { "code": "BW", "name": "BOTSWANA" },
    { "code": "BD", "name": "BOUVET ISLAND" },
    { "code": "BR", "name": "BRAZIL" },
    { "code": "IO", "name": "BRITISH INDIAN OCEAN TERRITORY THE" },
    { "code": "BI", "name": "BRUNEI" },
    { "code": "BL", "name": "BULGARIA" },
    { "code": "BF", "name": "BULGARIA" },
    { "code": "BK", "name": "BURKINA FASO" },
    { "code": "BU", "name": "BURUNDI" },
    { "code": "RR", "name": "CABE VERDE" },
    { "code": "CB", "name": "CAMBODIA" },
    { "code": "CM", "name": "CAMEROON" },
    { "code": "CA", "name": "CANADA" },
    { "code": "CL", "name": "CANARY ISLANDS" },
    { "code": "CV", "name": "CAPE VERDI ISL." },
    { "code": "CC", "name": "CASTRIES" },
    { "code": "KY", "name": "CAYMAN ISLANDS THE" },
    { "code": "CF", "name": "CENTRAL AFRICAN REPUBLIC THE" },
    { "code": "TD", "name": "CHAD" },
    { "code": "CE", "name": "CHILE" },
    { "code": "CN", "name": "CHINA" },
    { "code": "CI", "name": "CHRISTMAS ISL." },
    { "code": "CX", "name": "CHRISTMAS ISLAND" },
    { "code": "CS", "name": "CIS (RUSSIA)" },
    { "code": "OO", "name": "COCOS KEELING ISLANDS THE" },
    { "code": "CO", "name": "COLOMBIA" },
    { "code": "KM", "name": "COMOROS THE" },
    { "code": "CG", "name": "CONGO" },
    { "code": "CD", "name": "CONGO (THE DEMOCRATIC REPUBLIC OF THE)" },
    { "code": "CK", "name": "COOK ISLANDS THE" },
    { "code": "CR", "name": "COSTA RICA" },
    { "code": "RI", "name": "CRIMEA" },
    { "code": "CT", "name": "CROATIA" },
    { "code": "CU", "name": "CUBA" },
    { "code": "UO", "name": "CURAẸAO" },
    { "code": "CY", "name": "CYPRUS" },
    { "code": "CZ", "name": "CZECH REPUBLIC" },
    { "code": "ZE", "name": "CZECHIA" },
    {
        "code": "DD", "name": "CÔTE D'IVOIRE"
    },
    { "code": "DK", "name": "DENMARK" },
    { "code": "DJ", "name": "DJIBOUTI" },
    { "code": "DO", "name": "DOMINICA" },
    { "code": "EC", "name": "ECUADOR" },
    { "code": "EG", "name": "EGYPT" },
    { "code": "EL", "name": "EL SALVADOR" },
    { "code": "GQ", "name": "EQUATORIAL GUINEA" },
    { "code": "ER", "name": "ERITREA" },
    { "code": "EA", "name": "ESTIONIA" },
    { "code": "EE", "name": "ESTONIA" },
    { "code": "ET", "name": "ETHIOPIA" },
    { "code": "FA", "name": "FALKLAND ISL." },
    { "code": "FO", "name": "FAROE ISLANDS THE" },
    { "code": "FI", "name": "FIJI ISLAND" },
    { "code": "FL", "name": "FINLAND" },
    { "code": "FR", "name": "FRANCE" },
    { "code": "FG", "name": "FRENCH GUYANA" },
    { "code": "PF", "name": "FRENCH POLYNESIA" },
    { "code": "TF", "name": "FRENCH SOUTHERN TERRITORIES THE" },
    { "code": "GO", "name": "GABON" },
    { "code": "GA", "name": "GAMBIA" },
    { "code": "GG", "name": "GEORGIA" },
    { "code": "GE", "name": "GERMANY" },
    { "code": "GH", "name": "GHANA" },
    { "code": "GI", "name": "GIBRALTAR" },
    { "code": "GR", "name": "GREECE" },
    { "code": "GL", "name": "GREENLAND" },
    { "code": "GD", "name": "GRENADA" },
    { "code": "GP", "name": "GUADELOUPE" },
    { "code": "GM", "name": "GUAM" },
    { "code": "GT", "name": "GUATEMALA" },
    { "code": "GY", "name": "GUERNSEY" },
    { "code": "GB", "name": "GUINE-BISSAU" },
    { "code": "GN", "name": "GUINEA" },
    { "code": "GU", "name": "GUYANA" },
    { "code": "HA", "name": "HAITI" },
    { "code": "HW", "name": "HAWAII" },
    { "code": "HM", "name": "HEARD ISLAND AND MCDONALD ISLANDS" },
    { "code": "NL", "name": "HOLLAND" },
    { "code": "HS", "name": "HOLY SEE THE" },
    { "code": "HO", "name": "HONDURAS" },
    { "code": "HK", "name": "HONG KONG" },
    { "code": "HG", "name": "HUNGARY" },
    { "code": "IE", "name": "ICELAND" },
    { "code": "ID", "name": "INDIA" },
    { "code": "IN", "name": "INDONESIA" },
    { "code": "IR", "name": "IRAN" },
    { "code": "IQ", "name": "IRAQ" },
    { "code": "IL", "name": "IRELAND" },
    { "code": "IM", "name": "ISLE OF MAN" },
    { "code": "IS", "name": "ISRAEL" },
    { "code": "IT", "name": "ITALY" },
    { "code": "IC", "name": "IVORY COAST" },
    { "code": "JA", "name": "JAMAICA" },
    { "code": "JP", "name": "JAPAN" },
    { "code": "JE", "name": "JERSEY" },
    { "code": "JO", "name": "JORDAN" },
    { "code": "KZ", "name": "KAZAKHSTAN" },
    { "code": "KE", "name": "KENYA" },
    { "code": "KI", "name": "KIRIBATI" },
    { "code": "KO", "name": "KOREA" },
    { "code": "KR", "name": "KOREA (NORTH)" },
    { "code": "KU", "name": "KUWAIT" },
    { "code": "KG", "name": "KYRGYZSTAN" },
    {
        "code": "LO", "name": "LAO PEOPLE'S DEMOCRATIC REPUBLIC THE"
    },
    { "code": "LA", "name": "LATVIA" },
    { "code": "LE", "name": "LEBANON" },
    { "code": "LS", "name": "LESOTHO" },
    { "code": "LR", "name": "LIBERIA" },
    { "code": "LB", "name": "LIBYA" },
    { "code": "LY", "name": "LIBYA" },
    { "code": "LT", "name": "LIECHTENSTEIN" },
    { "code": "LN", "name": "LITHUANIA" },
    { "code": "LU", "name": "LUXEMBOURG" },
    { "code": "MU", "name": "MACAU" },
    { "code": "MK", "name": "MACEDONIA THE FORMER YUGOSLAV REPUBLIC OF" },
    { "code": "MG", "name": "MADAGASCAR" },
    { "code": "MA", "name": "MALAWI" },
    { "code": "MY", "name": "MALAYSIA" },
    { "code": "MD", "name": "MALDIVES" },
    { "code": "LL", "name": "MALI" },
    { "code": "ML", "name": "MALTA" },
    { "code": "MI", "name": "MARIANA ISLANDS" },
    { "code": "MH", "name": "MARSHALL ISLANDS THE" },
    { "code": "MQ", "name": "MARTINIQUE" },
    { "code": "MT", "name": "MAURITANIA" },
    { "code": "MR", "name": "MAURITIUS" },
    { "code": "YT", "name": "MAYOTTE" },
    { "code": "ME", "name": "MEXICO" },
    { "code": "FM", "name": "MICRONESIA FEDERATED STATES OF" },
    { "code": "MW", "name": "MIDDLE EAST" },
    { "code": "MV", "name": "MOLDOVA THE REPUBLIC OF" },
    { "code": "MN", "name": "MONACO" },
    { "code": "ON", "name": "MONGOLIA" },
    { "code": "OT", "name": "MONTENEGRO" },
    { "code": "MS", "name": "MONTSERRAT" },
    { "code": "MC", "name": "MOROCCO" },
    { "code": "MO", "name": "MOZAMBIQUE" },
    { "code": "MM", "name": "MYANMAR" },
    { "code": "N/A", "name": "N/A (Anywhere worldwide except North Korean and Iran)" },
    { "code": "NB", "name": "NAMIBIA" },
    { "code": "NR", "name": "NAURU" },
    { "code": "NP", "name": "NEPAL" },
    { "code": "ND", "name": "NETHERLANDS THE" },
    { "code": "NE", "name": "NEW CALEDONIA" },
    { "code": "PG", "name": "NEW GUINEA" },
    { "code": "NZ", "name": "NEW ZEALAND" },
    { "code": "NC", "name": "NICARAGUA" },
    { "code": "NH", "name": "NICARAGUA" },
    { "code": "NG", "name": "NIGER" },
    { "code": "NI", "name": "NIGERIA" },
    { "code": "NU", "name": "NIUE" },
    { "code": "NA", "name": "NLAND ANTILLES" },
    { "code": "NO", "name": "NORFOLK ISLANDS" },
    { "code": "NK", "name": "NORTH KOREA" },
    { "code": "NW", "name": "NORWAY" },
    { "code": "OM", "name": "OMAN" },
    { "code": "OA", "name": "OTHER AREA" },
    { "code": "PK", "name": "PAKISTAN" },
    { "code": "PW", "name": "PALAU" },
    { "code": "PS", "name": "PALESTINE, STATE OF" },
    { "code": "PN", "name": "PANAMA" },
    { "code": "PA", "name": "PARAGUAY" },
    { "code": "PE", "name": "PERU" },
    { "code": "PH", "name": "PHILIPPINES" },
    { "code": "PC", "name": "PITCAIRN" },
    { "code": "PL", "name": "POLAND" },
    { "code": "PT", "name": "PORTUGAL" },
    { "code": "PR", "name": "PUERTO RICO" },
    { "code": "QA", "name": "QATAR" },
    { "code": "EI", "name": "REP OS IRELAND" },
    { "code": "RE", "name": "REUNION" },
    { "code": "RU", "name": "ROMANIA" },
    { "code": "RS", "name": "RUSSIA" },
    { "code": "RW", "name": "RWANDA" },
    { "code": "RY", "name": "RYUKU ISLAND" },
    { "code": "BY", "name": "SAINT BARTHÉLEMY" },
    { "code": "HC", "name": "SAINT HELENA, ASCENSION AND TRISTAN DA CUNHA" },
    { "code": "KN", "name": "SAINT KITTS AND NEVIS" },
    { "code": "LC", "name": "SAINT LUCIA" },
    { "code": "SF", "name": "SAINT MARTIN FRENCH PART" },
    { "code": "PM", "name": "SAINT PIERRE AND MIQUELON" },
    { "code": "VC", "name": "SAINT VINCENT AND THE GRENADINES" },
    { "code": "SM", "name": "SAMOA" },
    { "code": "NM", "name": "SAN MARINO" },
    { "code": "ST", "name": "SAO TOME AND PRINCIPE" },
    { "code": "SJ", "name": "SAUDI ARABIA" },
    { "code": "SN", "name": "SENEGAL" },
    { "code": "EB", "name": "SERBIA" },
    { "code": "SY", "name": "SEYCHELLES" },
    { "code": "SI", "name": "SIERRA LEONE" },
    { "code": "SG", "name": "SINGAPORE" },
    { "code": "MP", "name": "SINT MAARTEN DUTCH PART" },
    { "code": "VV", "name": "SLOVAKIA" },
    { "code": "SB", "name": "SLOVENIA" },
    { "code": "SO", "name": "SOLOMON ISLANDS" },
    { "code": "OL", "name": "SOMALIA" },
    { "code": "SA", "name": "SOUTH AFRICA" },
    { "code": "GS", "name": "SOUTH GEORGIA AND THE SOUTH SANDWICH ISLANDS" },
    { "code": "SK", "name": "SOUTH KOREA" },
    { "code": "SSD", "name": "SOUTH SUDAN" },
    { "code": "SP", "name": "SPAIN" },
    { "code": "ES", "name": "SPAIN" },
    { "code": "SH", "name": "SPANISH N. AFR" },
    { "code": "SL", "name": "SRI LANKA" },
    { "code": "SV", "name": "ST. VINCENT" },
    { "code": "SD", "name": "SUDAN" },
    { "code": "SS", "name": "SURINAME" },
    { "code": "JJ", "name": "SVALBARD AND JAN MAYEN" },
    { "code": "SZ", "name": "SWAZILAND" },
    { "code": "SE", "name": "SWEDEN" },
    { "code": "CH", "name": "SWITZERLAND" },
    { "code": "SR", "name": "SYRIA" },
    { "code": "TA", "name": "TAHITI" },
    { "code": "TW", "name": "TAIWAN" },
    { "code": "TJ", "name": "TAJIKISTAN" },
    { "code": "TZ", "name": "TANZANIA" },
    { "code": "TH", "name": "THAILAND" },
    { "code": "TS", "name": "TIMOR-LESTE" },
    { "code": "TO", "name": "TOGO" },
    { "code": "TL", "name": "TOKELAU" },
    { "code": "TN", "name": "TONGA" },
    { "code": "TT", "name": "TRINIDAD&TOBAGO" },
    { "code": "TU", "name": "TUNISIA" },
    { "code": "TR", "name": "TURKIYE" },
    { "code": "TM", "name": "TURKMENISTAN" },
    { "code": "TC", "name": "TURKS AND CAICOS ISLANDS THE" },
    { "code": "TV", "name": "TUVALU" },
    { "code": "TK", "name": "TUYKEY" },
    { "code": "UA", "name": "U.A.E." },
    { "code": "US", "name": "U.S.A." },
    { "code": "SU", "name": "U.S.S.R." },
    { "code": "UG", "name": "UGANDA" },
    { "code": "UE", "name": "UKRAINE" },
    { "code": "AE", "name": "UNITED ARAB EMIRATES (THE)" },
    { "code": "UK", "name": "UNITED KINGDOM" },
    { "code": "UM", "name": "UNITED STATES MINOR OUTLYING ISLANDS THE" },
    { "code": "UV", "name": "UPPER VOLTA" },
    { "code": "UR", "name": "URUGUAY" },
    { "code": "UZ", "name": "UZBEKISTAN" },
    { "code": "UIO", "name": "Unidentified" },
    { "code": "VA", "name": "VALENCIA" },
    { "code": "VU", "name": "VANUATU" },
    { "code": "VE", "name": "VENEZUELA" },
    { "code": "VI", "name": "VIRGIN ISLANDS" },
    { "code": "VG", "name": "VIRGIN ISLANDS BRITISH" },
    { "code": "VL", "name": "VIRGIN ISLANDS U.S." },
    { "code": "WF", "name": "WALLIS AND FUTUNA" },
    { "code": "SC", "name": "WEST INDIES" },
    { "code": "EH", "name": "WESTERN SAHARA" },
    { "code": "WW", "name": "WORLDWIDE" },
    { "code": "YN", "name": "YEMEN" },
    { "code": "YG", "name": "YUGOSLAVIA" },
    { "code": "ZA", "name": "ZAIRE" },
    { "code": "ZM", "name": "ZAMBIA" },
    { "code": "ZI", "name": "ZIMBABWE" },
];
export default Nationality;