import React from 'react'

function SwitchCheck(props) {
    return (
        <div className="form-check form-switch">
            <input className="form-check-input" type="checkbox" id={props.id} defaultChecked={props.defaultChecked}
                checked={props.checked} onChange={props.onChange} />
            <label className="form-check-label" htmlFor={props.id}>{props.label}</label>
        </div>
    )
}

export default SwitchCheck