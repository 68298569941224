import React, { useState, useEffect } from 'react';
import { Spinner } from 'react-bootstrap';
import petApi from '../../../api/petApi';
import CommonHelper from '../../../helpers/common';

function InsuredPetConfirmation(props) {
    const {
        dataPet: valuePropPet,
        onEditPet,
        onDelPet,
        hasActionPet,
        kind_of_pet,
        step_code,
        res_post_order,
        updateListPetPass,
        updateListPetFailed,
        state_appraisal,
        setStateAppraisal,
        ...other
    } = props;
    const [listPetPass, setListPetPass] = useState([]);
    const [listPetFailed, setListPetFailed] = useState([]);
    const [loading, setLoading] = useState(false);
    const [state_total_tax, setStateTotalTax] = useState(0);
    const [state_total_premium, setStateTotalPremium] = useState(0);
    useEffect(() => {
        if (res_post_order && (res_post_order.allow_payment || res_post_order.pending_appraisal)) {
            fetchPetPass();
            fetchPetFailed();
            localStorage.setItem("state_total_tax", res_post_order && res_post_order.allow_payment ? res_post_order.allow_payment.tax_amt : 0);
            localStorage.setItem("state_total_premium", res_post_order && res_post_order.allow_payment ? res_post_order.allow_payment.total_amt : 0);
        } else {
            fetchListPetPass();
            fetchListPetReject();
            if (res_post_order && res_post_order.lst_pass && res_post_order.lst_pass.length > 0) {
                const totalPremium = res_post_order.lst_pass.reduce((sum, item) => sum + item.premium, 0);
                const totalFlexNum1 = res_post_order.lst_pass.reduce((sum, item) => sum + item.flexNum1, 0);
                localStorage.setItem("state_total_tax", totalFlexNum1);
                localStorage.setItem("state_total_premium", totalPremium + totalFlexNum1);
            }
        }
    }, [res_post_order]);

    const fetchPetPass = async () => {
        setLoading(true);
        try {
            let listPetPassTemp = [];
            if (res_post_order && res_post_order.allow_payment?.list?.length > 0) {
                for (const item of res_post_order.allow_payment.list) {
                    const res = await petApi.get_insured_pet(item.id);
                    listPetPassTemp.push(res.data);
                }
            }
            setListPetPass(listPetPassTemp);
            updateListPetPass((prev) => ({
                ...prev,
                listPetPass: listPetPassTemp,
            }));
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };
    const fetchPetFailed = async () => {
        setLoading(true);
        try {
            let listPetFailedTemp = [];
            if (res_post_order && res_post_order.pending_appraisal?.list?.length > 0) {
                for (const item of res_post_order.pending_appraisal.list) {
                    const res = await petApi.get_insured_pet(item.id);
                    listPetFailedTemp.push(res.data);
                }
            }
            setListPetFailed(listPetFailedTemp);
            updateListPetFailed((prev) => ({
                ...prev,
                listPetFailed: listPetFailedTemp,
            }));
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };
    const fetchListPetPass = async () => {
        setLoading(true);
        try {
            let listPetPassTemp = [];
            if (res_post_order && res_post_order.lst_pass?.length > 0) {
                for (const item of res_post_order.lst_pass) {
                    const res = await petApi.get_insured_pet(item.id);
                    listPetPassTemp.push(res.data);
                }
            }
            setListPetPass(listPetPassTemp);
            updateListPetPass((prev) => ({
                ...prev,
                listPetPass: listPetPassTemp,
            }));
            let total_tax_temp = 0;
            let total_premium_temp = 0;
            if (listPetPassTemp && listPetPassTemp.length > 0) {
                listPetPassTemp.forEach((item, index) => {
                    total_tax_temp += JSON.parse(listPetPassTemp.package_detail).taxAmt;
                    total_premium_temp += JSON.parse(listPetPassTemp.package_detail).premium;
                })
            }
            setStateTotalTax(total_tax_temp);
            setStateTotalPremium(total_premium_temp);
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };
    const fetchListPetReject = async () => {
        setLoading(true);
        try {
            let listPetFailedTemp = [];
            if (res_post_order && res_post_order.lst_reject?.length > 0) {
                for (const item of res_post_order.lst_reject) {
                    const res = await petApi.get_insured_pet(item.id);
                    listPetFailedTemp.push(res.data);
                }
            }
            setListPetFailed(listPetFailedTemp);
            updateListPetFailed((prev) => ({
                ...prev,
                listPetFailed: listPetFailedTemp,
            }));
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div>

            <div>
                {((res_post_order.allow_payment
                    && res_post_order.allow_payment?.list?.length > 0)
                    || (res_post_order.lst_pass
                    && res_post_order.lst_pass.length > 0))
                    && (<>
                        <h4
                            style={{
                                borderLeft: '4px solid green',
                                paddingLeft: '8px',
                                fontSize: '1.25rem',
                                color: 'black',
                                fontWeight: '700'
                            }}
                        >
                            DANH SÁCH THÚ CƯNG ĐƯỢC BẢO HIỂM
                        </h4>
                        <div id="insured-pet">
                            <div className='row mt-3'>
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th 
                                                scope="col" 
                                                style={{ width: "45px" }}
                                            >
                                                STT
                                            </th>
                                            <th scope="col">
                                                Loại
                                            </th>
                                            <th scope="col">
                                                Tên
                                            </th>
                                            <th scope="col">
                                                Số Chip
                                            </th>
                                            <th scope="col">
                                                Giống
                                            </th>
                                            <th scope="col">
                                                Loài
                                            </th>
                                            <th scope="col">
                                                Ngày sinh
                                            </th>
                                            <th scope="col">
                                                Tuổi
                                            </th>
                                            <th scope="col">
                                                Gói bảo hiểm
                                            </th>
                                            {hasActionPet && <th scope="col" style={{ width: "65px" }}></th>}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {loading ? (
                                            <tr>
                                                <td colSpan="9" className="text-center">
                                                    <Spinner 
                                                        animation="border" 
                                                        role="status"
                                                    >
                                                        <span className="visually-hidden">
                                                            Loading...
                                                        </span>
                                                    </Spinner>
                                                </td>
                                            </tr>
                                        ) : (
                                            listPetPass.length === 0 ? (
                                                <tr>
                                                    <td 
                                                        colSpan="9" 
                                                        className="text-center"
                                                    >
                                                        Đang tải dữ liệu...
                                                    </td>
                                                </tr>
                                            ) : (
                                                listPetPass.map((item, i) => (
                                                    <tr 
                                                        key={i} 
                                                        style={{ 
                                                            textAlign: 'center', 
                                                            verticalAlign: 'middle' 
                                                        }}
                                                    >
                                                        <td scope="row">
                                                            {i + 1}
                                                        </td>
                                                        <td>
                                                            {item.pet_type === "PET_DOG" ? "Chó" : "Mèo"}
                                                        </td>
                                                        <td>
                                                            {item.object_name}
                                                        </td>
                                                        <td>
                                                            {item.id_card}
                                                        </td>
                                                        <td>
                                                            {item.gender === 1 ? "Đực" : "Cái"}
                                                        </td>
                                                        <td>
                                                            {item.breed_of_pet}
                                                        </td>
                                                        <td>
                                                            {CommonHelper.convertToDDMMYYYY(item.dob)}
                                                        </td>
                                                        <td>
                                                            {CommonHelper.calculate_age_pet(CommonHelper.parseStringToDate(item.dob, "yyyyMMdd"))}
                                                        </td>
                                                        <td>
                                                            {item.package_detail && (
                                                                <>
                                                                    <div>
                                                                        {JSON.parse(item.package_detail).pkg_name}
                                                                    </div>
                                                                    <div className='text-end'>
                                                                        <b>
                                                                            {CommonHelper.format_currency_vnd_style(JSON.parse(item.package_detail).premium)}
                                                                        </b>
                                                                    </div>
                                                                </>
                                                            )}
                                                        </td>
                                                    </tr>
                                                ))
                                            )
                                        )}
                                        {
                                            step_code && (
                                                <>
                                                    <tr>
                                                        <td></td>
                                                        <td colSpan={4}>
                                                            Tổng phí VAT
                                                        </td>
                                                        <td colSpan={4}>
                                                            <div className='text-end'>
                                                                <b style={{ color: 'blue' }}>
                                                                    {
                                                                        res_post_order.allow_payment && res_post_order.allow_payment.tax_amt
                                                                        ? CommonHelper.format_currency_vnd_style(res_post_order.allow_payment.tax_amt)
                                                                        : CommonHelper.format_currency_vnd_style(localStorage.getItem("state_total_tax")
                                                                        ? localStorage.getItem("state_total_tax")
                                                                        : state_total_tax)
                                                                    }
                                                                </b>
                                                            </div>
                                                        </td>
                                                        {hasActionPet && (
                                                            <td></td>
                                                        )}
                                                    </tr>
                                                    <tr>
                                                        <td></td>
                                                        <td 
                                                            colSpan={4} 
                                                            style={{ verticalAlign: 'middle' }}
                                                        >
                                                            <b>
                                                                Tổng phí bảo hiểm
                                                            </b>
                                                        </td>
                                                        <td colSpan={4}>
                                                            <div className='text-end'>
                                                                <b 
                                                                    style={{ 
                                                                        fontSize: '24px', 
                                                                        color: 'red' 
                                                                    }}
                                                                >
                                                                    {
                                                                        res_post_order.allow_payment && res_post_order.allow_payment.total_amt
                                                                        ? CommonHelper.format_currency_vnd_style(res_post_order.allow_payment.total_amt)
                                                                        : CommonHelper.format_currency_vnd_style(localStorage.getItem("state_total_premium")
                                                                        ? localStorage.getItem("state_total_premium")
                                                                        : state_total_premium)
                                                                    }
                                                                </b>
                                                            </div>
                                                        </td>
                                                        {hasActionPet && (
                                                            <td></td>
                                                        )}
                                                    </tr>
                                                </>
                                            )
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </>)}
                {((res_post_order.pending_appraisal
                    && res_post_order.pending_appraisal?.list?.length > 0)
                    || (res_post_order.lst_reject
                    && res_post_order.lst_reject.length > 0))
                    && (
                        <>
                            <hr 
                                style={{ 
                                    border: '2px dashed black', 
                                    marginBottom: '32px' 
                                }} 
                            />
                            <h4
                                style={{
                                    borderLeft: '4px solid red',
                                    paddingLeft: '8px',
                                    fontSize: '1.25rem',
                                    color: 'black',
                                    fontWeight: '700'
                                }}
                            >
                                DANH SÁCH THÚ CƯNG CHỜ THẨM ĐỊNH
                            </h4>
                            <div id="insured-pet-exclusive">
                                <div className='row mt-3'>
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th 
                                                    scope="col" 
                                                    style={{ width: "45px" }}
                                                >
                                                    STT
                                                </th>
                                                <th scope="col">
                                                    Loại
                                                </th>
                                                <th scope="col">
                                                    Tên
                                                </th>
                                                <th scope="col">
                                                    Số Chip
                                                </th>
                                                <th scope="col">
                                                    Giống
                                                </th>
                                                <th scope="col">
                                                    Loài
                                                </th>
                                                <th scope="col">
                                                    Ngày sinh
                                                </th>
                                                <th scope="col">
                                                    Tuổi
                                                </th>
                                                <th scope="col">
                                                    Gói bảo hiểm
                                                </th>
                                                {hasActionPet && <th scope="col" style={{ width: "65px" }}></th>}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {loading ? (
                                                <tr>
                                                    <td colSpan="9" className="text-center">
                                                        <Spinner 
                                                            animation="border" 
                                                            role="status"
                                                        >
                                                            <span className="visually-hidden">
                                                                Loading...
                                                            </span>
                                                        </Spinner>
                                                    </td>
                                                </tr>
                                            ) : (
                                                listPetFailed.length === 0 ? (
                                                    <tr>
                                                        <td 
                                                            colSpan="9" 
                                                            className="text-center"
                                                        >
                                                            Đang tải dữ liệu...
                                                        </td>
                                                    </tr>
                                                ) : (
                                                    listPetFailed.map((item, i) => (
                                                        <tr 
                                                            key={i} 
                                                            style={{ 
                                                                textAlign: 'center', 
                                                                verticalAlign: 'middle' 
                                                            }}
                                                        >
                                                            <td scope="row">
                                                                {i + 1}
                                                            </td>
                                                            <td>
                                                                {item.pet_type === "PET_DOG" ? "Chó" : "Mèo"}
                                                            </td>
                                                            <td>
                                                                {item.object_name}
                                                            </td>
                                                            <td>
                                                                {item.id_card}
                                                            </td>
                                                            <td>
                                                                {item.gender === 1 ? "Đực" : "Cái"}
                                                            </td>
                                                            <td>
                                                                {item.breed_of_pet}
                                                            </td>
                                                            <td>
                                                                {CommonHelper.convertToDDMMYYYY(item.dob)}
                                                            </td>
                                                            <td>
                                                                {CommonHelper.calculate_age_pet(CommonHelper.parseStringToDate(item.dob, "yyyyMMdd"))}
                                                            </td>
                                                            <td>
                                                                {item.package_detail && (
                                                                    <>
                                                                        <div>
                                                                            {JSON.parse(item.package_detail).pkg_name}
                                                                        </div>
                                                                        <div className='text-end'>
                                                                            <b>
                                                                                {CommonHelper.format_currency_vnd_style(JSON.parse(item.package_detail).premium)}
                                                                            </b>
                                                                        </div>
                                                                    </>
                                                                )}
                                                            </td>
                                                        </tr>
                                                    ))
                                                )
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                                <div className='col-sm-12 mt-2'>
                                    <div className='card'>
                                        <div className='card-body'>
                                            <div className='form-check'>
                                                <input
                                                    type="checkbox"
                                                    className='form-check-input'
                                                    id="check-pet-failed"
                                                    name='confirm1'
                                                    value={state_appraisal}
                                                    onChange={() => setStateAppraisal(true)}
                                                />
                                                <label 
                                                    className='form-check-label' 
                                                    htmlFor='check-pet-failed'
                                                >
                                                    Tôi muốn thẩm định thú cưng của mình
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
            </div>
        </div>
    );
}

export default InsuredPetConfirmation;
