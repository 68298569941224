import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Container, Row, Col } from 'react-bootstrap';
import CommonHelper from '../../../../helpers/common';

const initial = {
    "origin_premium": 0,
    "vat": 0,
    "mvi_premium": 0,
    "premium": 0,
    "si_amt1": 0,
    "si_amt2": 0,
    "si_amt3": 0,
    "si_amt4": 0
};

function VehiclePremium(props) {

    const { onChange, scCode, data: valueProp, ...other } = props;
    const [state, setState] = useState(initial);
    useEffect(() => {
        if (valueProp != null) {
            setState(valueProp);
        } else {
            setState(initial);
        }
    }, [valueProp])

    const handleChange = (e) => {
        //setValue(e);
        onChange(scCode);
    }

    return (
        <>
            {
                state.premium > 0 && (
                    <a onClick={handleChange} style={{ cursor: "pointer" }}>
                        <div className={"card " + other.className}>
                            <div className="card-body">
                                <Container style={{ padding: "0" }}>
                                    <Row>
                                        <Col xs={7}>
                                            <label style={{ fontSize: "14px" }}>Phí gốc (1)</label>
                                        </Col>
                                        <Col className='text-end'>
                                            <label className="card-title text-end">{CommonHelper.format_currency(state.origin_premium)}&nbsp;VNĐ</label></Col>
                                    </Row>
                                    <Row>
                                        <Col xs={7}>
                                            <label style={{ fontSize: "14px" }}>VAT (2)</label>
                                        </Col>
                                        <Col className='text-end'>
                                            <label className="card-title text-end">{CommonHelper.format_currency(state.vat)}&nbsp;VNĐ</label></Col>
                                    </Row>
                                    {state.mvi_premium === 0 && (
                                        <Row>
                                            <Col xs={5}>
                                                <label style={{ fontSize: "14px" }}>Tổng phí (1+2)</label>
                                            </Col>
                                            <Col>
                                                <h4 className="card-title text-end">{CommonHelper.format_currency(state.premium)}&nbsp;VNĐ</h4></Col>
                                        </Row>
                                    )}
                                    {state.mvi_premium > 0 && (
                                        <>
                                            <Row>
                                                <Col xs={7}>
                                                    <label style={{ fontSize: "14px" }}>Phí BH người ngồi trên xe (3)</label>
                                                </Col>
                                                <Col className='text-end'>
                                                    <label className="card-title text-end">{CommonHelper.format_currency(state.mvi_premium)}&nbsp;VNĐ</label></Col>
                                            </Row>
                                            <Row>
                                                <Col xs={5}>
                                                    <label style={{ fontSize: "14px" }}>Tổng phí (1+2+3)</label>
                                                </Col>
                                                <Col>
                                                    <h4 className="card-title text-end">{CommonHelper.format_currency(state.premium)}&nbsp;VNĐ</h4></Col>
                                            </Row>
                                        </>
                                    )}
                                </Container>
                            </div>
                        </div>
                    </a>
                )}
        </>
    )
}

export default VehiclePremium

VehiclePremium.propTypes = {
    onChange: PropTypes.func.isRequired,
}