/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import trainingApi from "../../../../api/trainingApi";
import AlertBox from "../../../../components/alert";
import CommonHelper from "../../../../helpers/common";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import * as FaIcon from "react-icons/fa";
import fbBanner from "../../../../assets/images/logo.svg";
import { FaEdit } from "react-icons/fa";
import EditAccount from "./edit-account";

const initialAlert = {
    is_show: false,
    title: "",
    content: "",
    variant: "danger"
};

const AccountExam = () => {
    const [accounts, setAccounts] = useState([]);
    const [alertData, setAlertData] = useState(initialAlert);
    const [accParams, setAccParams] = useState(0);
    const [showEditModal, setShowEditModal] = useState(false);
    const [selectedAccount, setSelectedAccount] = useState(null);

    useEffect(() => {
        fetchQuestions();
    }, []);

    const fetchQuestions = async () => {
        try {
            const response = await trainingApi.get_accounts_exam(accParams);
            console.log("response", response);
            if (response.code === 200) {
                setAccounts(response.data);
            } else {
                setAlertData((prev) => ({
                    ...prev,
                    title: "Lỗi",
                    content: "Tải dữ liệu danh sách tài khoản thi không thành công. ",
                    is_show: true,
                    variant: "danger"
                }));
            }
        } catch (error) {
            setAlertData((prev) => ({
                ...prev,
                title: "Lỗi",
                content: "Oops... Đã có lỗi xảy ra, vui lòng kiểm tra lại!",
                is_show: true,
                variant: "danger"
            }));
            console.log(error);
        }
    };

    const handleEditClick = (account) => {
        setSelectedAccount(account);
        setShowEditModal(true);
    };

    const handleModalClose = () => {
        setShowEditModal(false);
        setSelectedAccount(null);
        fetchQuestions();
    };

    return (
        <>
            <div className="clearfix" style={{ marginTop: "75px" }}></div>
            <div className="container" >
                <div className="row row-frm">
                    <h1 className="text-center">Quản lý tài khoản thi Đại lý Fubon</h1>
                    <div className="table-container" style={{ overflowY: 'auto', maxHeight: '600px' }}>
                        <table className="table table-striped table-bordered table-hover">
                            <thead className="center-vertically" style={{ backgroundColor: '#3498db', position: 'sticky', top: '-2px' }}>
                                <tr style={{ verticalAlign: 'middle', color: 'white' }}>
                                    <th style={{ width: '5%' }}>#</th>
                                    <th style={{ width: '20%' }}>Họ và tên</th>
                                    <th style={{ width: '10%' }}>Số điện thoại</th>
                                    <td style={{ width: '10%', fontWeight: '700' }}>Email</td>
                                    <th style={{ width: '10%' }}>Số CMTND</th>
                                    <th style={{ width: '10%' }}>Tên tài khoản</th>
                                    <th style={{ width: '10%' }}>Block</th>
                                    <th style={{ width: '10%' }}>Ngày tạo</th>
                                    <th style={{ width: '10%' }}>Đăng nhập gần đây</th>
                                    <th style={{ width: '5%' }}>Sửa</th>
                                </tr>
                            </thead>
                            <tbody>
                                {accounts.length === 0 ? (
                                    <tr>
                                        <td colSpan="5" style={{ textAlign: 'center' }}>Không có dữ liệu phù hợp</td>
                                    </tr>
                                ) :
                                    (
                                        accounts.map((acc, index) => (
                                            <>
                                                <tr key={acc.id} style={{ verticalAlign: 'middle', textAlign: 'center' }}>
                                                    <td>
                                                        {acc.id}
                                                    </td>
                                                    <td style={{ textAlign: 'center', fontWeight: '500' }}>
                                                        {acc.fullName}
                                                    </td>
                                                    <td>
                                                        {acc.phone}
                                                    </td>
                                                    <td>
                                                        {acc.email}
                                                    </td>
                                                    <td>
                                                        {acc.identity}
                                                    </td>
                                                    <td>
                                                        {acc.userName}
                                                    </td>
                                                    {/* <td>
                                                        <Form>
                                                            <Form.Check
                                                                checked={acc.isAdmin}
                                                                type="switch"
                                                            />
                                                        </Form>
                                                    </td> */}
                                                    <td>
                                                        <Form>
                                                            <Form.Check
                                                                checked={acc.isDeleted}
                                                                type="switch"
                                                            />
                                                        </Form>
                                                    </td>
                                                    <td>
                                                        {acc.lastLoginDate ? CommonHelper.formatDateExam(acc.createdDate) : ""}
                                                    </td>
                                                    <td>
                                                        {acc.lastLoginDate ? CommonHelper.formatDateExam(acc.lastLoginDate) : ""}
                                                    </td>
                                                    <td>
                                                        <FaEdit
                                                            style={{ fontSize: "20px", cursor: 'pointer' }}
                                                            title="Sửa thông tin tài khoản"
                                                            onClick={() => handleEditClick(acc)}
                                                        />
                                                    </td>
                                                </tr>
                                            </>
                                        ))
                                    )
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
                <AlertBox data={alertData} onChange={(e) => { setAlertData((prev) => ({ ...prev, is_show: e })) }} />
            </div>
            {showEditModal && (
                <EditAccount
                    showModal={showEditModal}
                    handleClose={handleModalClose}
                    selectedAccount={selectedAccount}
                />
            )}
        </>
    );
}

export default AccountExam;
