import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Alert, Fade } from 'react-bootstrap';

const initial = {
    is_show: false,
    title: "",
    content: "",
    variant: "danger",
};

function AlertBox(props) {
    const { onChange, data, ...other } = props;

    const [state, setState] = useState(initial);
    const handle_close = () => {
        setState((prev) => ({
            ...prev,
            is_show: false
        }))
        onChange(false);
    }

    useEffect(() => {
        setState(data);
        setTimeout(() => { handle_close() }, 20000)
    }, [data])

    return (
        <>
            {state.is_show && (
                <div style={{ position: "fixed", inset: "0", zIndex: "9999" }}>
                    <div className='position-relative'>
                        <div className='position-absolute end-0' style={{ top: "20px", width: "350px" }}>
                            <Alert show={state.is_show} variant={state.variant} onClose={handle_close} dismissible >
                                <Alert.Heading>{state.title}</Alert.Heading>
                                <p>
                                    {state.content}
                                </p>
                            </Alert>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}

export default AlertBox

AlertBox.propTypes = {
    onChange: PropTypes.func.isRequired,
}