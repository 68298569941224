import React from "react";
import { Modal, Form, Button } from "react-bootstrap";
import { MdAddToPhotos } from "react-icons/md";

function DownloadInfoModal({ showModal, handleClose, data }) {

  const copyToClipboard = () => {
    const qrCodeValue = document.getElementsByName('qrCode')[0].value;
    navigator.clipboard.writeText(qrCodeValue).then(() => {
      alert('QR Code link copied to clipboard');
    }).catch(err => {
      alert('Failed to copy text: ', err);
    });
  };

  return (
    <>
      <Modal
        show={showModal}
        dialogClassName="custom-modal"
        style={{ marginTop: "200px" }}
        backdrop="static"
        onHide={handleClose}
        size="sm"
      >
        <Modal.Header closeButton>
          <Modal.Title as="h3">
            Copy Fubon Card
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Label>
                Copy this FubonCard.
              </Form.Label>
            </Form.Group>
            <Form.Group
              style={{
                display: 'flex',
                alignItems: 'center',
                textDecoration: 'none',
                marginTop: '16px',
                marginBottom: '16px'
              }}
            >
              <MdAddToPhotos
                style={{
                  fontSize: '40px',
                  marginRight: '8px'
                }}
              >
              </MdAddToPhotos>
              <Form.Control 
                type="text" 
                name="qrCode" 
                value={data} 
              />
              <Button 
                onClick={copyToClipboard} 
                id="myQRCode" 
                style={{ 
                  marginLeft: '16px', 
                  marginRight: '16px' 
                }}
              >
                Copy
              </Button>
            </Form.Group>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default DownloadInfoModal;
