import React, { useEffect, useState } from "react";
import { useLoaderData, useMatches } from "react-router-dom";
import addMonths from "date-fns/addMonths";
import addDays from "date-fns/addDays";
import addYears from "date-fns/addYears";
import { Container, Row, Col } from "react-bootstrap";
import TextInput from "../../../components/text-input";
import AutoComplete from "../../../components/auto-complete";
import vehicleApi from "../../../api/vehicleApi";
import DateInput from "../../../components/date-input";
import ShortDateInput from "../../../components/short-date-input";
import NumberInput from "../../../components/number-input";
import MotoPremium from "./component/moto-premium";
import CommonHelper from "../../../helpers/common";
import MotoConfirmation from "./component/confirmation";
import Loading from "../../../components/loading";
import AlertBox from "../../../components/alert";
import * as FaIcon from "react-icons/fa";
import Tooltip from "../../../components/tooltip";
import OnlineApi from "../../../api/onlineApi";
import bgPromotion from "../../../assets/images/banner-promotion.jpg";
import MemberRegister from "../../register/components/member-reg";
import VehicleMDPExample from "../vehicle/component/vehicle-mdp-example";

const flagLoading = true;

export async function loader({ params }) {
  if (params.id !== undefined && CommonHelper.is_not_empty(params.id)) {
    var data = await vehicleApi.get_order(params.id);
    if (data != null) {
      return { data };
    }
  }
  return null;
}

const initial = {
  veh_pol_no: "",
  veh_certificate_no: "",
  veh_cert_no: "",
  veh_cert_no_prefix: "12MBC",
  veh_org_sc_code: "MBC",
  veh_sc_code: "MBC",
  veh_business: "N",
  veh_type: "",
  veh_type_name: "",
  veh_seat: "2",
  veh_weight: "0",
  veh_reg_no: "",
  veh_chassis_no: "",
  veh_engine_no: "",
  veh_month: "1",
  veh_fm_dt: null,
  veh_to_dt: null,
  veh_issued_date: null,
  veh_handler_code: "",
  veh_handler_name: "",
  veh_handler_name_vi: "",
  veh_is_pax: false,
  veh_pax: "0",
  veh_customer_id: 0,
  veh_customer: "",
  veh_customer_idcard: "",
  veh_phone: "",
  veh_email: "",
  veh_address: "",
  veh_issue_invoice: false,
  inv_tax: "",
  inv_company_name: "",
  inv_address: "",
  veh_origin_premium: 0,
  veh_tax_ratio: 0,
  veh_tax_amt: 0,
  veh_pax_premium: 0,
  veh_premium: 0,
  veh_si_amt1: 0,
  veh_si_amt2: 0,
  veh_si_amt3: 0,
  veh_si_amt4: 0,
  veh_is_truck: false,
  veh_url: window.location.href,
  veh_order_no: "",
  veh_get_cert_via: 1,
  veh_sob_code: "",
  veh_sob_name: "",
  veh_agency_code: "",
  veh_agency_name: "",
  veh_broker_code: "",
  veh_broker_name: "",
  veh_is_agent: false,
};
const initialMember = {
  cus_id: 0,
  cus_name: "",
  cus_title: "",
  cus_idcard: "",
  cus_tel: "",
  cus_email: "",
  cus_address: "",
  cus_dist_code: "",
  cus_dist_name: "",
  cus_prov_code: "",
  cus_prov_name: "",
  cus_confirm_collect: false,
  cus_pwd: "",
  cus_pwd_cfm: "",
  veh_sob_code: "",
  veh_sob_name: "",
  veh_agency_code: "",
  veh_agency_name: "",
  veh_broker_code: "",
  veh_broker_name: "",
}
const premiumRequest = {
  "veh_usage": "N",
  "veh_type": "",
  "from_date": "",
  "to_date": "",
  "sc_code": "MBC",
};

const initialAlert = {
  is_show: false,
  title: "",
  content: "",
  variant: "danger"
};
const steps = [{ "code": 1, "title": "ĐĂNG KÝ TRỞ THÀNH KHÁCH HÀNG THÂN THIẾT CỦA FUBON" }, { "code": 2, "title": "TÍNH PHÍ" }, { "code": 3, "title": "THÔNG TIN BẢO HIỂM" }, { "code": 4, "title": "TÓM TẮT ĐƠN HÀNG - XÁC NHẬN YÊU CẦU BẢO HIỂM" }];
function CtplMoto() {
  const _title = "Bảo hiểm bắt buộc TNDS của chủ xe cơ giới - xe gắn máy";
  const [state, setState] = useState(initial);
  const [stateMember, setStateMember] = useState(initialMember);
  const policy = useLoaderData();
  const [showLoading, setShowLoading] = useState(flagLoading);
  const [{ showExample }, setShow] = useState({
    showExample: false
  });
  const [alertData, setAlertData] = useState(initialAlert);
  const [premiumRq, setPremiumRq] = useState(premiumRequest);
  const [confirm, setConfirm] = useState(false);
  const [stateError, setStateError] = useState({
    veh_type: false,
    veh_reg_no: false,
    veh_fm_dt: false,
    veh_customer: false,
    veh_phone: false,
    veh_email: false,
    veh_address: false,
    veh_customer_idcard: false,
  });
  const [currUser, setCurrUser] = useState(null);
  const [lockField, setLockField] = useState({
    veh_customer: false,
    veh_phone: false,
    veh_email: false,
    veh_address: false,
    veh_handler_code: false,
    veh_customer_idcard: false,
    veh_sob: false,
    veh_agent: false,
  });
  const [{ curr_step }, setStep] = useState({
    curr_step: steps[0]
  });
  const [typeOpts, setTypeOpts] = useState([
    { 'id': 'LXF002', 'name': 'Mô tô 2 bánh từ 50 cc trở lên.' },
    { 'id': 'LXF001', 'name': 'Mô tô 2 bánh dưới 50 cc' },
    { 'id': 'LXF025', 'name': 'Xe máy điện' },
    { 'id': 'LXF003', 'name': 'Xe mô tô ba bánh.' },
  ]);
  const [{ vehTypeSel, mbvPremiumSel }, setSelected] = useState({
    vehTypeSel: [],
    mbvPremiumSel: {
      "id": 0,
      "premium": 0,
      "si_amt": 0,
      "seat": 2
    }
  });
  const [{ sobSel, lst_sob, lst_agency, agentSel, lst_handler, handlerSel }, setList] = useState({
    sobSel: [],
    lst_sob: [],
    lst_agency: [],
    agentSel: [],
    lst_handler: [],
    handlerSel: []
  })
  const [{ mbvPremium }, setPremium] = useState({
    mbvPremium: [],
  });
  const [{ fm_dt, to_dt }, setEffDt] = useState({
    fm_dt: null,
    to_dt: null,
  });

  useEffect(() => {
    document.title = "Fubon Insurance - " + _title;
    var resUser = CommonHelper.get_user();
    resUser.then((res) => {
      var veh_handler_code = "";
      var veh_handler_name = "";
      var veh_agency_code = "";
      var veh_agency_name = "";
      var veh_is_agent = false;
      var veh_customer = "";
      var veh_customer_idcard = "";
      var veh_phone = "";
      var veh_email = "";
      var veh_address = "";
      if (res !== null) {
        skip_reg()
        setCurrUser(res);
        if (res.user_type > 1) {
          veh_handler_code = res.handler_code;
          veh_handler_name = res.handler_name;
          var lst_agency = [];
          var agentSel = lst_agency;
          var lst_handler = [{ "code": veh_handler_code, "name": veh_handler_name }];
          var handlerSel = lst_handler
          if (res.user_type == 2) {
            get_agent_by_handler();
          } else if (res.user_type == 3) {
            veh_is_agent = true;
            veh_agency_code = res.code;
            veh_agency_name = res.name;
            lst_agency = [{ "code": veh_agency_code, "name": veh_agency_name }];
            agentSel = lst_agency;
          }
          setList((prev) => ({
            ...prev,
            lst_agency,
            agentSel,
            handlerSel,
            lst_handler,
          }))
        } else {
          veh_customer = res.name;
          veh_address = res.address;
          veh_email = res.email;
          veh_phone = res.phone;
          veh_customer_idcard = res.id_card;
        }
      }
      if (policy !== null && policy.data !== null) {
        policy.data.order_detail.veh_order_no = policy.data.veh_order_no;
        policy.data.order_detail.status = policy.data.status;

        set_data(policy.data.order_detail);
      } else {
        setState((prev) => ({
          ...prev,
          veh_handler_code,
          veh_handler_name,
          veh_agency_code,
          veh_agency_name,
          veh_is_agent,
          veh_customer,
          veh_customer_idcard,
          veh_phone,
          veh_email,
          veh_address,
        }))
      }
    })
    get_sob("");
    var _skipReg = CommonHelper.get_storage_skip_reg();
    if (_skipReg) {
      next_step();
    }
    setShowLoading(false);
  }, []);
  useEffect(() => {
    setState((prevData) => ({
      ...prevData,
      veh_premium: 0,
      veh_origin_premium: 0,
      veh_tax_amt: 0,
      veh_tax_ratio: 0,
      //veh_pax: 0,
      //veh_pax_premium: 0,
      veh_si_amt1: 0,
      veh_si_amt2: 0,
      //veh_si_amt3: 0,
      veh_si_amt4: 0,
      //veh_is_pax: false
    }));
    var _model = {
      "veh_usage": state.veh_business,
      "veh_type": (vehTypeSel !== null && vehTypeSel.length) > 0 ? vehTypeSel[0].id : "",
      "from_date": CommonHelper.formatToYYYYMMDD(fm_dt),
      "to_date": CommonHelper.formatToYYYYMMDD(to_dt),
      "sc_code": "MBC",
      "seat": "2",
    };
    get_premium_moto(_model);
  }, [vehTypeSel, state.veh_month, fm_dt, to_dt]);
  const set_data = (data) => {
    data.veh_sc_code = "MBC";
    var sobSel = [];
    if (CommonHelper.is_not_empty(data.veh_sob_code)) {
      sobSel = [{ "code": data.veh_sob_code, "name": data.veh_sob_name }]
    }
    var agentSel = []
    if (CommonHelper.is_not_empty(data.veh_agency_code)) {
      agentSel = [{ "code": data.veh_agency_code, "name": data.veh_agency_name }]
    }
    setList((prev) => ({
      ...prev,
      sobSel,
      agentSel
    }))
    setState(data);
    var _idx = typeOpts.findIndex(x => x.id === data.veh_type);
    if (_idx >= 0) {
      setSelected((prev) => ({ ...prev, vehTypeSel: [typeOpts[_idx]] }));
    }
    setEffDt((prev) => ({
      ...prev,
      fm_dt: CommonHelper.parseStringToDate(data.veh_fm_dt, "yyyyMMdd HHmmss"),
      to_dt: CommonHelper.parseStringToDate(data.veh_to_dt, "yyyyMMdd HHmmss"),
    }))
    if (policy.data.status == 2) {
      setStep((prev) => ({ ...prev, curr_step: steps[3] }))
    } else {
      setStep((prev) => ({ ...prev, curr_step: steps[1] }))
    }

    setSelected((prev) => ({
      ...prev, mbvPremiumSel: {
        "premium": data.veh_pax_premium, "si_amt": data.veh_si_amt3, "id": 0
      }
    }))
    if (data.veh_is_pax) {
      var _date = data.veh_fm_dt.substr(0, 8);
      var response = vehicleApi.get_mbv_premium({ date: _date, seat: data.veh_pax, years: data.veh_month });
      response.then((res) => {
        if (res != null && res.length > 0) {
          var _idx = res.findIndex(x => x.premium === data.veh_pax_premium && x.si_amt === data.veh_si_amt3);
          if (_idx >= 0) {
            setSelected((prev) => ({ ...prev, mbvPremiumSel: res[_idx] }))
          }
          setPremium((prev) => ({ ...prev, mbvPremium: res }));
        }
      })
    }
    //get_mbv_premium(CommonHelper.parseStringToDate(data.veh_fm_dt, "yyyyMMdd HHmmss"), data.veh_pax, data.veh_month);
  }
  const change_sob = (val) => {
    var veh_sob_code = "";
    var veh_sob_name = "";
    if (val.length > 0) {
      veh_sob_code = val[0].code;
      veh_sob_name = val[0].name;
    }
    //get_districts(_code);
    setList((prev) => ({
      ...prev,
      sobSel: val
    }))
    setState((prev) => ({
      ...prev,
      veh_sob_code,
      veh_sob_name,
    }))
  };
  const change_agent = (val) => {
    var veh_agency_code = "";
    var veh_agency_name = "";
    if (val.length > 0) {
      veh_agency_code = val[0].code;
      veh_agency_name = val[0].name;
      var arr = veh_agency_name.split(" - ");
      veh_agency_name = arr[1];
    }
    //get_districts(_code);
    setList((prev) => ({
      ...prev,
      agentSel: val
    }))
    setState((prev) => ({
      ...prev,
      veh_agency_code,
      veh_agency_name,
    }))
  };
  const get_agent_by_handler = () => {
    var response = OnlineApi.get_agent_by_handler();
    response.then((res) => {
      if (res != null && res.length > 0) {
        var lst_agency = res.map((item) => { return { "code": item.code, "name": item.code + " - " + item.name } });

        setList((prev) => ({ ...prev, lst_agency }));
      }
    })
  }
  const change_veh_type = (val) => {
    var _type_id = "";
    var _type_name = "";
    setSelected((prev) => ({ ...prev, vehTypeSel: val }));
    if (val.length > 0) {
      _type_name = val[0].name;
      _type_id = val[0].id;
    }
    setStateError((prev) => ({ ...prev, veh_type: val.length === 0 }));
    setState((prev) => ({
      ...prev,
      veh_type: _type_id,
      veh_type_name: _type_name,
    }));
  };
  const handle_change = (e) => {
    const { name, value } = e.target;
    var _val = value;
    if (name === "veh_month") {
      if (fm_dt != null) {
        var _to_date = addYears(fm_dt, value);
        setEffDt((prev) => ({
          ...prev,
          to_dt: _to_date,
        }));
        setPremiumRq((prev) => ({
          ...prev,
          to_date: _to_date
        }))
      }
      get_mbv_premium(fm_dt, state.veh_seat, _val);
    } else if (name === "veh_phone" || name === "cus_tel") {
      _val = CommonHelper.to_phone_number(value);
    } else if (name === "veh_customer_idcard") {
      _val = value.replace(/[^0-9]/g, "");
    } else if (name === "veh_cert_no") {
      _val = value.replace(/[^0-9]/g, "");
      _val = _val.substr(0, 6)
    }
    setState((prevData) => ({
      ...prevData,
      [name]: _val,
    }));
  };
  const handle_check = (e) => {
    const { name, checked } = e.target;
    setState((prevData) => ({
      ...prevData,
      [name]: checked,
    }));
    if (name === "veh_is_pax") {
      var veh_cert_no_prefix = "12MBC";
      if (checked) {
        veh_cert_no_prefix += "V";
        setState((prev) => ({
          ...prev,
          veh_pax: state.veh_seat,
          veh_cert_no: "",
          veh_cert_no_prefix
        }))
        get_mbv_premium(fm_dt, state.veh_seat, state.veh_month);
      } else {
        setPremium((prev) => ({ ...prev, mbvPremium: [] }));
        setSelected((prev) => ({
          ...prev, mbvPremiumSel: {
            "premium": 0, "si_amt": 0, "id": 0
          }
        }))
        setState((prev) => ({
          ...prev,
          veh_pax: "0",
          veh_pax_premium: 0,
          veh_si_amt3: 0,
          veh_premium: state.veh_origin_premium + state.veh_tax_amt,
          veh_cert_no: "",
          veh_cert_no_prefix
        }))
      }
    }
  };
  const change_fm_dt = (val) => {
    if (val != null) {
      setEffDt((prev) => ({
        ...prev,
        fm_dt: val,
        to_dt: addYears(val, state.veh_month),
      }));
    } else {
      setEffDt((prev) => ({
        ...prev,
        fm_dt: null,
        to_dt: null,
      }));
    }
  };
  const get_premium_moto = (model) => {
    var flag = true;
    if (CommonHelper.is_empty(model.veh_type)) {
      flag = false;
    }
    if (CommonHelper.is_empty(model.from_date)) {
      flag = false;
    }
    if (flag) {
      setShowLoading(true);
      var response = vehicleApi.get_premium_moto(model);
      response.then((data) => {
        setShowLoading(false);
        var _state = { ...state };
        var origin_premium = 0;
        var tax_amt = 0;
        var tax_ratio = 0;
        var si_amt1 = 0;
        var si_amt2 = 0;
        if (data != null) {
          origin_premium = data.origin_premium;
          tax_ratio = data.tax_ratio;
          tax_amt = data.vat;
          si_amt1 = data.si_amt1;
          si_amt2 = data.si_amt2;
        }
        set_premium({
          veh_origin_premium: origin_premium,
          veh_tax_amt: tax_amt,
          veh_tax_ratio: tax_ratio,
          veh_si_amt1: si_amt1,
          veh_si_amt2: si_amt2,
          veh_pax: _state.veh_pax,
          veh_pax_premium: _state.veh_pax_premium,
          veh_si_amt3: _state.veh_si_amt3,
        });
      })
    }
  }
  const get_mbv_premium = (date, pax, years) => {
    var _date = CommonHelper.formatToYYYYMMDD(date);
    var response = vehicleApi.get_mbv_premium({ date: _date, seat: pax, years: years });
    setShowLoading(true);
    response.then((data) => {
      setShowLoading(false);
      var _state = { ...state };
      var _pax_premium = 0;
      var _si_amt3 = 0;
      if (data === null) {
        data = [];
      } else {
        var _mbvPremiumSel = { ...mbvPremiumSel };
        if (_mbvPremiumSel.premium > 0) {
          var _idx = data.findIndex(x => x.premium === _mbvPremiumSel.premium && x.si_amt === _mbvPremiumSel.si_amt);
          if (_idx >= 0) {
            setSelected((prev) => ({ ...prev, mbvPremiumSel: data[_idx] }))
            _pax_premium = data[_idx].premium;
            _si_amt3 = data[_idx].si_amt;
          }
        } else {
          setSelected((prev) => ({ ...prev, mbvPremiumSel: data[0] }))
          _pax_premium = data[0].premium;
          _si_amt3 = data[0].si_amt;
        }
      }
      setPremium((prev) => ({ ...prev, mbvPremium: data }));
      set_premium({
        veh_origin_premium: _state.veh_origin_premium,
        veh_tax_amt: _state.veh_tax_amt,
        veh_tax_ratio: _state.veh_tax_ratio,
        veh_si_amt1: _state.veh_si_amt1,
        veh_si_amt2: _state.veh_si_amt2,
        veh_pax: pax,
        veh_pax_premium: _pax_premium,
        veh_si_amt3: _si_amt3,
      });
    })

  }
  const handle_change_mbv = (e) => {
    setSelected((prev) => ({ ...prev, mbvPremiumSel: e }))
    var _state = { ...state };
    set_premium({
      veh_origin_premium: _state.veh_origin_premium,
      veh_tax_amt: _state.veh_tax_amt,
      veh_tax_ratio: _state.veh_tax_ratio,
      veh_si_amt1: _state.veh_si_amt1,
      veh_si_amt2: _state.veh_si_amt2,
      veh_pax: _state.veh_pax,
      veh_pax_premium: e.premium,
      veh_si_amt3: e.si_amt,
    });
    /*setState((prev) => ({
      ...prev,
      veh_pax_premium: e.premium,
      veh_si_amt3: e.si_amt,
      veh_premium: state.veh_origin_premium + state.veh_tax_amt + e.premium,
    }))*/
  }
  const validation = () => {
    setStateError((prev) => ({
      ...prev,
      veh_type: CommonHelper.is_empty(state.veh_type),
      veh_reg_no: CommonHelper.is_empty(state.veh_reg_no),
      veh_fm_dt: fm_dt === null,
      veh_customer: CommonHelper.is_empty(state.veh_customer),
      veh_email: CommonHelper.is_empty(state.veh_email),
      veh_phone: CommonHelper.is_empty(state.veh_phone),
      veh_address: CommonHelper.is_empty(state.veh_address),
      veh_customer_idcard: CommonHelper.is_empty(state.veh_customer_idcard),
    }))
    var flag = true;
    if (CommonHelper.is_empty(state.veh_type)) {
      flag = false;
    }
    if (CommonHelper.is_empty(state.veh_reg_no)) {
      flag = false;
    }
    if (fm_dt === null) {
      flag = false;
    } else {
      var _curr_dt = CommonHelper.formatToYYYYMMDD(new Date());
      if (_curr_dt == CommonHelper.formatToYYYYMMDD(fm_dt)) {
        flag = false;
        setAlertData((prev) => ({
          ...prev,
          title: "Lỗi",
          content: "Hiệu lực bảo hiểm không hợp lệ. Vui lòng chọn lại ngày bắt đầu hiệu lực!",
          is_show: true,
          variant: "danger"
        }))
      }
    }
    if (CommonHelper.is_empty(state.veh_customer)) {
      flag = false;
    }
    /*if (CommonHelper.is_empty(state.veh_customer_idcard)) {
      flag = false;
    }*/
    if (CommonHelper.is_empty(state.veh_email)) {
      flag = false;
    } else {
      if (!CommonHelper.validate_email_format(state.veh_email)) {
        setAlertData((prev) => ({
          ...prev,
          title: "Lỗi",
          content: "Email không đúng định dạng. Vui lòng kiểm tra lại!",
          is_show: true,
          variant: "danger"
        }))
        flag = false;
      }
    }
    if (CommonHelper.is_empty(state.veh_phone)) {
      flag = false;
    }
    if (CommonHelper.is_empty(state.veh_address)) {
      flag = false;
    }
    return flag;
  }
  const handle_checkout = () => {
    setConfirm(validation())
    var _domain = window.location.origin;
    var _path = "/sale/ctpl-moto";
    var _model = { ...state };
    setState((prev) => ({
      ...prev,
      veh_fm_dt: CommonHelper.formatToYYYYMMDDHHMMSS(fm_dt),
      veh_to_dt: CommonHelper.formatToYYYYMMDDHHMMSS(to_dt),
      veh_url: _domain + _path
    }))
  }
  const handle_payment = async () => {
    var _data = { ...state };
    var _dataMember = { ...stateMember };
    var _domain = window.location.origin;
    var _path = "/sale/ctpl-moto";
    _data.veh_url = _domain + _path;
    setShowLoading(true);
    var resPayment = await vehicleApi.post_order(_data);
    setShowLoading(false);
    if (resPayment !== null) {
      if (resPayment.code === 204) {
        setState((prev) => ({
          ...prev,
          veh_order_no: resPayment.data.orderNo
        }));
        var res = resPayment;
        if (res.data !== null && res.data.payment !== null) {
          if (res.data.payment.result_code === "0000" && res.data.payment.result_data != null) {
            window.location.href = res.data.payment.result_data.checkout_url;
          }
        }
        /*var objPayment = JSON.parse(resPayment.data.paymentData);
        if (objPayment !== null) {
          if (objPayment.result_code === "0000") {
            vehicleApi.put_order_token(resPayment.data.orderNo, objPayment.result_data.token_code);
            window.location.href = objPayment.result_data.checkout_url;
          } else {
            var _res_msg = CommonHelper.get_payment_gw_msg(objPayment.result_code);
            setAlertData((prev) => ({
              ...prev,
              title: "Lỗi",
              content: _res_msg + ". Vui lòng kiểm tra lại!",
              is_show: true,
              variant: "danger"
            }))
          }
        }*/
      } else if (resPayment.code === 721) {
        setAlertData((prev) => ({
          ...prev,
          title: "Lỗi",
          content: "Hiệu lực bảo hiểm không hợp lệ. Vui lòng chọn lại ngày bắt đầu hiệu lực!",
          is_show: true,
          variant: "danger"
        }))
      } else {
        var msg_content = "Cổng thanh toán online đang bảo trì. Vui lòng liên hệ nhân viên Fubon.";
        var _msg = resPayment.msg;
        if (_msg == "Invalidbuyer_email") {
          msg_content = "Email khách hàng không hợp lệ."
        } else if (_msg == "Invalidbuyer_mobile") {
          msg_content = "Số điện thoại khách hàng không hợp lệ."
        }
        setAlertData((prev) => ({
          ...prev,
          title: "Lỗi",
          content: msg_content,
          is_show: true,
          variant: "danger"
        }))
        if (resPayment.data != null) {
          setState((prev) => ({
            ...prev,
            veh_order_no: resPayment.data.orderNo
          }));
        }
      }
    }
  }
  const validate_email_format = (e) => {
    const { name, value } = e.target;
    var flag = false;
    if (!CommonHelper.validate_email_format(value)) {
      setAlertData((prev) => ({
        ...prev,
        title: "Lỗi",
        content: "Email không đúng định dạng. Vui lòng kiểm tra lại!",
        is_show: true,
        variant: "danger"
      }))
      flag = true;
    }
    setStateError((prev) => ({
      ...prev,
      veh_email: flag,
    }))
  };
  const next_step = () => {
    var _idx = steps.findIndex(x => x.code === curr_step.code);
    var flag = true;
    if (curr_step.code === 2) {
      if (fm_dt === null) {
        flag = false;
        setStateError((prev) => ({
          ...prev,
          veh_fm_dt: true,
        }))
      }
      if (vehTypeSel.length === 0) {
        flag = false; setStateError((prev) => ({
          ...prev,
          veh_type: CommonHelper.is_empty(state.veh_type),
        }))
      }
      var _user = { ...currUser };
      if (_user != null && lst_sob != null && lst_sob.length > 0) {
        if (_user.user_type == 3) {
          var sobSel = [[...lst_sob][0]]
          setList((prev) => ({
            ...prev,
            sobSel
          }))
          setLockField((prev) => ({ ...prev, veh_sob: true }))
          var veh_sob_code = sobSel[0].code;
          var veh_sob_name = sobSel[0].name;
          setState((prev) => ({
            ...prev,
            veh_sob_code,
            veh_sob_name,
          }))
        }
      }
    }
    if (curr_step.code === 3) {
      if (!CommonHelper.validate_email_format(state.veh_email)) {
        setAlertData((prev) => ({
          ...prev,
          title: "Lỗi",
          content: "Email không đúng định dạng. Vui lòng kiểm tra lại!",
          is_show: true,
          variant: "danger"
        }))
        flag = false;
      }
      handle_checkout();
    }
    if (flag) {
      setStep((prev) => ({
        ...prev,
        curr_step: steps[_idx + 1]
      }))
    }
  };
  const back_step = () => {
    var _idx = steps.findIndex(x => x.code === curr_step.code);
    setStep((prev) => ({
      ...prev,
      curr_step: steps[_idx - 1]
    }))
  };
  const get_sob = (id, obj) => {
    var res_sob = OnlineApi.get_sob(id);
    res_sob.then((data) => {
      if (data !== null) {
        setList((prev) => ({
          ...prev,
          lst_sob: data,
        }));
        if (obj !== undefined && obj !== null) {
          setList((prev) => ({
            ...prev,
            sobSel: data.filter(x => x.code == obj.code)
          }))
        }
      }
    })
  }
  const handle_register = (res) => {
    if (res != null && res.data != null) {
      setStateMember(res.data);
      var strAddr = res.data.cus_address;
      if (CommonHelper.is_not_empty(res.data.cus_dist_name)) {
        strAddr = strAddr + ", " + res.data.cus_dist_name;
      }
      if (CommonHelper.is_not_empty(res.data.cus_prov_name)) {
        strAddr = strAddr + ", " + res.data.cus_prov_name;
      }
      setState((prev) => ({
        ...prev,
        veh_customer_id: res.data.cus_id,
        veh_customer: res.data.cus_name,
        veh_email: res.data.cus_email,
        veh_phone: res.data.cus_tel,
        veh_address: strAddr,
      }))

      next_step();
    }
  }
  const set_premium = (e) => {
    setState((prev) => ({
      ...prev,
      veh_premium: e.veh_origin_premium + e.veh_tax_amt + e.veh_pax_premium,
      veh_origin_premium: e.veh_origin_premium,
      veh_tax_amt: e.veh_tax_amt,
      veh_tax_ratio: e.veh_tax_ratio,
      veh_pax: e.veh_pax,
      veh_pax_premium: e.veh_pax_premium,
      veh_si_amt1: e.veh_si_amt1,
      veh_si_amt2: e.veh_si_amt2,
      veh_si_amt3: e.veh_si_amt3,
      veh_si_amt4: e.veh_si_amt4,
    }))
  }
  const skip_reg = (e) => {
    CommonHelper.set_storage_skip_reg(true);
    next_step();
  }
  return (
    <>
      <div>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item"><a href="/">Trang chủ</a></li>
            <li className="breadcrumb-item active" aria-current="page">{_title}</li>
          </ol>
        </nav>
      </div>
      <Row className="d-none">
        <Col>
          <h1>{_title}</h1>
        </Col>
      </Row>
      <Row>
        <div className="col-sm-12">
          <h1>{curr_step.title}</h1>
        </div>
      </Row>
      {curr_step.code === 1 && (
        <MemberRegister data={null} title="" onCancel={skip_reg} onRegister={handle_register}
          label={{ "lb_cancel": "BỎ QUA BƯỚC NÀY >>", "lb_reg": "TIẾP TỤC >" }} />
      )}
      {curr_step.code === 2 && (
        <>
          <Row>
            <div className="col-sm-6 mt10px">
              <Container>
                <Row className="d-none">
                  <div className="col-sm-12">
                    <label>{curr_step.title}</label>
                  </div>
                </Row>
                <Row className="mt10px">
                  <label className="form-label col-sm-4 required">
                    Thời hạn bảo hiểm
                  </label>
                  <div className="col-sm-8">
                    <select
                      className="form-select form-select-sm"
                      name="veh_month"
                      value={state.veh_month}
                      onChange={handle_change}
                    >
                      <option value="1">1 năm</option>
                      <option value="2">2 năm</option>
                      <option value="3">3 năm</option>
                    </select>
                  </div>
                </Row>
                <Row className="mt10px">
                  <label className="form-label col-sm-4 required">
                    Hiệu lực bảo hiểm
                  </label>
                  <div className="col-sm-8">
                    <div className="float-start" style={{ width: "48%" }}>
                      <DateInput
                        selected={fm_dt}
                        minDate={addDays(new Date(), 1)}
                        maxDate={addMonths(new Date(), 2)}
                        onChange={change_fm_dt}
                        className={stateError.veh_fm_dt ? "error" : ""}
                      />
                    </div>
                    <div className="float-end" style={{ width: "48%" }}>
                      <DateInput
                        selected={to_dt}
                        minDate={new Date()}
                        disabled
                      />
                    </div>
                    <div className="clearfix"></div>
                  </div>
                </Row>
                <Row className="mt10px">
                  <label className="form-label col-sm-4 required">Loại xe</label>
                  <div className="col-sm-8">
                    <AutoComplete
                      id="veh_type"
                      options={typeOpts}
                      onChange={change_veh_type}
                      selected={vehTypeSel}
                      className={stateError.veh_type ? "error" : ""} />
                  </div>
                </Row>
                <Row className="mt10px">
                  <div style={{ background: "#d2d2d2" }}>
                    <div className="col-sm-12">
                      PHẠM VI BẢO HIỂM
                    </div>
                  </div>
                </Row>
                <Row className="mt10px">
                  <div className="col-sm-12">
                    <div className="form-check">
                      <input className="form-check-input" type="checkbox" checked readOnly />
                      <label className="form-check-label" >
                        Bảo hiểm bắt buộc trách nhiệm dân sự của chủ xe cơ giới. <a href="#">(Tìm hiểu thêm)</a>
                      </label>
                    </div>
                  </div>
                  <div className="col-sm-12">
                    <div className="container">
                      <label className="form-label col-sm-6">- Mức trách nhiệm về người:</label>
                      <label className="form-label col-sm-6 text-end">{CommonHelper.format_currency_vnd_style(state.veh_si_amt1)}</label>
                    </div>
                  </div>
                  <div className="col-sm-12">
                    <div className="container">
                      <label className="form-label col-sm-6">- Mức trách nhiệm về tài sản:</label>
                      <label className="form-label col-sm-6 text-end">{CommonHelper.format_currency_vnd_style(state.veh_si_amt2)}</label>
                    </div>
                  </div>
                  <div className="col-sm-12 mt10px">
                    <div className="form-check">
                      <label className="form-check-label" >
                        <input className="form-check-input" type="checkbox" checked={state.veh_is_pax} name="veh_is_pax" onChange={handle_check} />
                        Bảo hiểm tai nạn người ngồi trên xe. <a onClick={() => { setShow((prev) => ({ ...prev, showExample: true })) }} style={{ color: "var(--bs-link-color)", cursor: "pointer" }}>(Tìm hiểu thêm)</a>
                      </label>
                    </div>
                  </div>
                  {state.veh_is_pax && (
                    <div className="col-sm-12 mt10px">
                      {mbvPremium.length > 0 && mbvPremium.map((e, i) => {
                        return (
                          <div className="container" key={i}>
                            <div className="col-sm-8" style={{ float: "left" }}>
                              <div className="form-check">
                                <input className="form-check-input" type="radio" name="isBusiness" id={`radio-` + e.id} defaultValue={e.id}
                                  checked={e.id === mbvPremiumSel.id} onChange={() => { handle_change_mbv(e) }} />
                                <label className="form-check-label" htmlFor={`radio-` + e.id}>{CommonHelper.format_currency_vnd_style(e.si_amt) + `/người/vụ`}</label>
                              </div>
                            </div>
                            <label className="form-label col-sm-4 text-end" htmlFor={`radio-` + e.id}>{`Phí: ` + CommonHelper.format_currency_vnd_style(e.premium)}</label>
                            <div className="clearbox"></div>
                          </div>
                        )
                      })}
                    </div>
                  )}
                </Row>
                <Row className="mt10px">
                  <div style={{ background: "#d2d2d2" }}>
                    <div className="col-sm-12">
                      PHÍ BẢO HIỂM
                    </div>
                  </div>
                </Row>
                <Row className="mt10px">
                  <Col className="col-sm-8">Bảo hiểm bắt buộc trách nhiệm dân sự của chủ xe cơ giới</Col>
                  <Col className="col-sm-4 text-end">{CommonHelper.format_currency_vnd_style(state.veh_origin_premium)}</Col>
                </Row>
                <Row className="mt10px">
                  <Col className="col-sm-8">Bảo hiểm tai nạn người ngồi trên xe</Col>
                  <Col className="col-sm-4 text-end">{CommonHelper.format_currency_vnd_style(state.veh_pax_premium)}</Col>
                </Row>
                <Row className="mt10px" style={{ background: "#dfe2ff", paddingTop: "5px", paddingBottom: "5px" }}>
                  <Col className=" col-sm-8">Tổng phí bảo hiểm</Col>
                  <Col className="col-sm-4 text-end">{CommonHelper.format_currency_vnd_style(state.veh_origin_premium + state.veh_pax_premium)}</Col>
                </Row>
                <Row className="mt10px">
                  <Col className="col-sm-8">Thuế giá trị gia tăng (VAT)</Col>
                  <Col className="col-sm-4 text-end">{CommonHelper.format_currency_vnd_style(state.veh_tax_amt)}</Col>
                </Row>
                <Row className="mt10px" style={{ background: "#dfe2ff", paddingTop: "5px", paddingBottom: "5px" }}>
                  <Col className="col-sm-8">Tổng cộng</Col>
                  <Col className="col-sm-4 text-end">{CommonHelper.format_currency_vnd_style(state.veh_premium)}</Col>
                </Row>
                <Row className="mt10px">
                  <Col className="col-sm-6 text-start" style={{ paddingLeft: "0px" }}>
                    <button type="button" className="btn btn-warning" onClick={back_step}>{"< QUAY LẠI"}</button>
                  </Col>
                  <Col className="col-sm-6 text-end" style={{ paddingRight: "0px" }}>
                    <button type="button" className="btn btn-info" onClick={next_step}>{"TIẾP TỤC >"}</button>
                  </Col>
                </Row>
              </Container>
            </div>
            <div className="col-sm-6 mt10px">
              <div style={{ background: "#008080", paddingBottom: "10px", color: "#fff" }}>
                <Container style={{ background: "#0097b2", paddingTop: "10px" }}>
                  <Row>
                    <div className="col-sm-12">
                      <label>THÔNG TIN ĐƠN HÀNG</label>
                    </div>
                  </Row>
                  <Row className="mt10px">
                    <label className="form-label col-sm-4">Sản phẩm:</label>
                    <label className="form-label col-sm-8"><b>Bảo hiểm xe máy</b></label>
                  </Row>
                </Container>
                <Container>
                  <Row className="mt10px">
                    <label className="form-label col-sm-4">Tên chủ xe:</label>
                    <label className="form-label col-sm-8"><b>{state.veh_customer}</b></label>
                  </Row>
                  <Row className="mt10px">
                    <label className="form-label col-sm-4">Loại xe:</label>
                    <label className="form-label col-sm-8"><b>{state.veh_type_name}</b></label>
                  </Row>
                  <Row className="mt10px">
                    <label className="form-label col-sm-4">Biển số xe:</label>
                    <label className="form-label col-sm-8"><b>{state.veh_reg_no}</b></label>
                  </Row>
                  <Row className="mt10px">
                    <label className="form-label col-sm-4">Thời hạn bảo hiểm:</label>
                    <label className="form-label col-sm-8"><b>{CommonHelper.formatToDDMMYYYY(fm_dt) + " - " + CommonHelper.formatToDDMMYYYY(to_dt)}</b></label>
                  </Row>
                  <Row style={{ borderTop: "1px solid #0097b2", paddingTop: "15px", marginTop: "10px" }}>
                    <label className="form-label col-sm-4">Tổng phí thanh toán:</label>
                    <label className="form-label col-sm-8"><b>{CommonHelper.format_currency_vnd_style(state.veh_premium)}</b></label>
                  </Row>
                </Container>
              </div>
            </div>
          </Row>
        </>
      )}
      {curr_step.code === 3 && (
        <>
          <Row className="mt10px">
            <div className="col-sm-6 mt10px">
              <Container>
                <Row className="d-none">
                  <div className="col-sm-12">
                    <label>{curr_step.title}</label>
                  </div>
                </Row>
                <Row className="mt10px">
                  <div style={{ background: "#d2d2d2" }}>
                    <div className="col-sm-12">
                      THÔNG TIN XE
                    </div>
                  </div>
                </Row>
                <Row className="mt10px">
                  <label className="form-label col-sm-3 required">Tên chủ xe</label>
                  <div className="col-sm-9">
                    <TextInput
                      name="veh_customer"
                      onChange={handle_change}
                      value={state.veh_customer}
                    />
                  </div>
                </Row>
                <Row className="mt10px">
                  <label className="form-label col-sm-3 required">Biển số xe</label>
                  <div className="col-sm-9">
                    <TextInput
                      name="veh_reg_no"
                      onChange={handle_change}
                      value={state.veh_reg_no}
                      onBlur={(e) => { setState((prev) => ({ ...prev, veh_reg_no: CommonHelper.format_regno_moto(e.target.value) })) }}
                      className={stateError.veh_reg_no ? "error" : ""} />
                  </div>
                </Row>
                <Row className="mt10px">
                  <label className="form-label col-sm-3 required">Số Khung</label>
                  <div className="col-sm-9">
                    <TextInput
                      name="veh_chassis_no"
                      value={state.veh_chassis_no}
                      onChange={handle_change} />
                  </div>
                </Row>
                <Row className="mt10px">
                  <label className="form-label col-sm-3 required">Số máy</label>
                  <div className="col-sm-9">
                    <TextInput
                      name="veh_engine_no"
                      value={state.veh_engine_no}
                      onChange={handle_change} />
                  </div>
                </Row>
                <Row className="mt10px">
                  <div style={{ background: "#d2d2d2" }}>
                    <div className="col-sm-12">
                      THÔNG TIN GIAO HÀNG
                    </div>
                  </div>
                </Row>
                <Row className="mt10px">
                  <label className="form-label col-sm-3 required">Số điện thoại</label>
                  <div className="col-sm-9">
                    <TextInput
                      name="veh_phone"
                      value={CommonHelper.format_phone_number(state.veh_phone)}
                      onChange={handle_change}
                      disabled={lockField.veh_phone}
                      className={stateError.veh_phone ? "error" : ""} />
                  </div>
                </Row>
                <Row className="mt10px">
                  <label className="form-label col-sm-3 required">Email</label>
                  <div className="col-sm-9">
                    <TextInput
                      name="veh_email"
                      value={state.veh_email}
                      onChange={handle_change}
                      onBlur={validate_email_format}
                      disabled={lockField.veh_email}
                      className={stateError.veh_email ? "error" : ""} />
                  </div>
                </Row>
                <Row className="mt10px">
                  <label className="form-label col-sm-3 required">Địa chỉ</label>
                  <div className="col-sm-9">
                    <TextInput
                      name="veh_address"
                      value={state.veh_address}
                      onChange={handle_change}
                      disabled={lockField.veh_address}
                      className={stateError.veh_address ? "error" : ""} />
                  </div>
                </Row>
                <Row className="mt10px">
                  <div className="col-sm-12">
                    <div className="form-check">
                      <input className="form-check-input" type="radio" name="isBusiness" id="radio-1" defaultValue={1}
                        checked={state.veh_get_cert_via === 1}
                        onChange={() => { setState((prev) => ({ ...prev, veh_get_cert_via: 1 })) }} />
                      <label className="form-check-label" htmlFor="radio-1">
                        Nhận đơn bảo hiểm điện tử qua sms và email người mua bảo hiểm.
                      </label>
                    </div>
                  </div>
                  <div className="col-sm-12">
                    <div className="form-check">
                      <input className="form-check-input" type="radio" name="isBusiness" id="radio-2" defaultValue={2}
                        checked={state.veh_get_cert_via === 2}
                        onChange={() => { setState((prev) => ({ ...prev, veh_get_cert_via: 2 })) }} />
                      <label className="form-check-label" htmlFor="radio-2">
                        Nhận đơn bảo hiểm theo địa chỉ liên hệ.
                      </label>
                    </div>
                    {state.veh_get_cert_via == 2 && (
                      <div className="mt10px row">
                        <label className="form-label col-sm-3">Số giấy chứng nhận</label>
                        <div className="col-sm-9">
                          <div className="input-group input-group-sm">
                            <TextInput
                              name="veh_cert_no_prefix"
                              className="text-end"
                              disabled
                              value={state.veh_cert_no_prefix}
                              onChange={handle_change} style={{ flex: "none", width: "40%" }}
                            />
                            <TextInput name="veh_cert_no" value={state.veh_cert_no} onChange={handle_change} />
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </Row>
                <Row className="mt10px">
                  <div className="col-sm-12">
                    <div >
                      <div className="form-check form-switch">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          name="veh_issue_invoice"
                          checked={state.veh_issue_invoice}
                          onChange={handle_check}
                        />
                        <label className="form-check-label">THÔNG TIN HÓA ĐƠN GIÁ TRỊ GIA TĂNG</label>
                      </div>
                      <div className="clearfix"></div>
                    </div>
                    <div className={state.veh_issue_invoice ? "" : "d-none"}>
                      <div className="mt10px">
                        <label className="form-label">Mã số thuế</label>
                        <TextInput
                          name="inv_tax"
                          value={state.inv_tax}
                          onChange={handle_change}
                        />
                      </div>
                      <div className="mt10px">
                        <label className="form-label">Tên công ty</label>
                        <TextInput
                          name="inv_company_name"
                          value={state.inv_company_name}
                          onChange={handle_change}
                        />
                      </div>
                      <div className="mt10px">
                        <label className="form-label">Địa chỉ công ty</label>
                        <TextInput
                          name="inv_address"
                          value={state.inv_address}
                          onChange={handle_change}
                        />
                      </div>
                    </div>
                  </div>
                </Row>
                <Row className="mt10px">
                  <Col className="col-sm-6 text-start" style={{ paddingLeft: "0px" }}>
                    <button type="button" className="btn btn-warning" onClick={back_step}>{"< QUAY LẠI"}</button>
                  </Col>
                  <Col className="col-sm-6 text-end" style={{ paddingRight: "0px" }}>
                    <button type="button" className="btn btn-info" onClick={next_step}>{"TIẾP TỤC >"}</button>
                  </Col>
                </Row>
              </Container>
            </div>
            <div className="col-sm-6 mt10px">
              <div className="mt10px" style={{ background: "#008080", paddingBottom: "10px", color: "#fff" }}>
                <Container style={{ background: "#0097b2", paddingTop: "10px" }}>
                  <Row>
                    <div className="col-sm-12">
                      <label>THÔNG TIN ĐƠN HÀNG</label>
                    </div>
                  </Row>
                  <Row className="mt10px">
                    <label className="form-label col-sm-4">Sản phẩm:</label>
                    <label className="form-label col-sm-8"><b>Bảo hiểm xe máy</b></label>
                  </Row>
                </Container>
                <Container>
                  <Row className="mt10px">
                    <label className="form-label col-sm-4">Tên chủ xe:</label>
                    <label className="form-label col-sm-8"><b>{state.veh_customer}</b></label>
                  </Row>
                  <Row className="mt10px">
                    <label className="form-label col-sm-4">Loại xe:</label>
                    <label className="form-label col-sm-8"><b>{state.veh_type_name}</b></label>
                  </Row>
                  <Row className="mt10px">
                    <label className="form-label col-sm-4">Biển số xe:</label>
                    <label className="form-label col-sm-8"><b>{state.veh_reg_no}</b></label>
                  </Row>
                  <Row className="mt10px">
                    <label className="form-label col-sm-4">Thời hạn bảo hiểm:</label>
                    <label className="form-label col-sm-8"><b>{CommonHelper.formatToDDMMYYYY(fm_dt) + " - " + CommonHelper.formatToDDMMYYYY(to_dt)}</b></label>
                  </Row>
                  <Row style={{ borderTop: "1px solid #0097b2", paddingTop: "15px", marginTop: "10px" }}>
                    <label className="form-label col-sm-4">Tổng phí thanh toán:</label>
                    <label className="form-label col-sm-8"><b>{CommonHelper.format_currency_vnd_style(state.veh_premium)}</b></label>
                  </Row>
                </Container>
              </div>
              {currUser != null && currUser.user_type > 1 && (
                <div className="mt10px" style={{ background: "#234a8f", paddingBottom: "10px", color: "#fff" }}>
                  <Container style={{ background: "#4472c4", paddingTop: "10px" }}>
                    <Row>
                      <div className="col-sm-12">
                        <label>PHẦN DÀNH CHO NHÂN VIÊN/ ĐẠI LÝ FUBON</label>
                      </div>
                    </Row>
                  </Container>
                  <Container>
                    <Row className="mt10px d-none">
                      <label className="form-label col-sm-3">Nhân viên</label>
                      <div className="col-sm-9">
                        <AutoComplete
                          id="veh_handler"
                          options={lst_handler}
                          onChange={(e) => { setList((prev) => ({ ...prev, handlerSel: e })) }}
                          selected={handlerSel}
                          placeholder={"Nhân viên Fubon"} />
                        {/* <div className="input-group input-group-sm">
                          <TextInput
                            name="veh_handler_code"
                            value={state.veh_handler_code}
                            placeholder="Mã nhân viên"
                            onChange={handle_change} style={{ flex: "none", width: "40%" }}
                          />
                          <TextInput name="veh_handler_name" value={state.veh_handler_name} disabled onChange={handle_change} />
                        </div> */}
                      </div>
                    </Row>
                    <Row className="mt10px">
                      <label className="form-label col-sm-3">Kênh khai thác</label>
                      <div className="col-sm-9">
                        <AutoComplete
                          id="veh_sob"
                          options={lst_sob}
                          onChange={change_sob}
                          selected={sobSel}
                          disabled={lockField.veh_sob}
                          placeholder={"Kênh khai thác"} />
                      </div>
                    </Row>
                    <Row className="mt10px">
                      <label className="form-label col-sm-3">Đại lý</label>
                      <div className="col-sm-9">
                        <AutoComplete
                          id="veh_agency"
                          options={lst_agency}
                          onChange={change_agent}
                          selected={agentSel}
                          placeholder={"Đại lý"} disabled={currUser != null && currUser.user_type == 3} />
                        {/*<div className="input-group input-group-sm">
                           <TextInput
                            name="veh_agency_code"
                            value={state.veh_agency_code}
                            placeholder="Mã đại lý"
                            onChange={handle_change} style={{ flex: "none", width: "40%" }} />
                          <TextInput name="veh_agency_name" value={state.veh_agency_name} disabled onChange={handle_change} />
                        </div>*/}
                      </div>
                    </Row>
                    <Row className="mt10px d-none">
                      <label className="form-label col-sm-3">Người giới thiệu</label>
                      <div className="col-sm-9">
                        <div className="input-group input-group-sm">
                          <TextInput
                            name="veh_broker_code"
                            value={state.veh_broker_code}
                            placeholder="Mã giới thiệu"
                            onChange={handle_change} style={{ flex: "none", width: "40%" }}
                          />
                          <TextInput name="veh_broker_name" value={state.veh_broker_name} disabled onChange={handle_change} />
                        </div>
                      </div>
                    </Row>
                  </Container>
                </div>
              )}
              {(currUser == null || (currUser != null && currUser.user_type < 2)) && (
                <div className="mt10px d-none" style={{ background: "#234a8f", paddingBottom: "10px", color: "#fff" }}>
                  <Container style={{ background: "#4472c4", paddingTop: "10px" }}>
                    <Row>
                      <div className="col-sm-12">
                        <label>PHẦN DÀNH CHO NHÂN VIÊN/ ĐẠI LÝ FUBON</label>
                      </div>
                    </Row>
                  </Container>
                  <Container>
                    <Row className="mt10px">
                      <label className="form-label col-sm-3">Nhân viên</label>
                      <div className="col-sm-9">
                        <div className="input-group input-group-sm">
                          <TextInput
                            name="veh_handler_code"
                            value={state.veh_handler_code}
                            placeholder="Mã nhân viên"
                            onChange={handle_change} style={{ flex: "none", width: "40%" }}
                          />
                          <TextInput name="veh_handler_name" value={state.veh_handler_name} disabled onChange={handle_change} />
                        </div>
                      </div>
                    </Row>
                    <Row className="mt10px">
                      <label className="form-label col-sm-3">Kênh khai thác</label>
                      <div className="col-sm-9">
                        <div className="input-group input-group-sm">
                          <TextInput
                            name="veh_sob_code"
                            value={state.veh_sob_code}
                            placeholder="Mã kênh"
                            onChange={handle_change} style={{ flex: "none", width: "40%" }} />
                          <TextInput name="veh_sob_name" value={state.veh_sob_name} disabled onChange={handle_change} />
                        </div>
                      </div>
                    </Row>
                    <Row className="mt10px">
                      <label className="form-label col-sm-3">Đại lý</label>
                      <div className="col-sm-9">
                        <div className="input-group input-group-sm">
                          <TextInput
                            name="veh_agency_code"
                            value={state.veh_agency_code}
                            placeholder="Mã đại lý"
                            onChange={handle_change} style={{ flex: "none", width: "40%" }} />
                          <TextInput name="veh_agency_name" value={state.veh_agency_name} disabled onChange={handle_change} />
                        </div>
                      </div>
                    </Row>
                    <Row className="mt10px d-none">
                      <label className="form-label col-sm-3">Người giới thiệu</label>
                      <div className="col-sm-9">
                        <div className="input-group input-group-sm">
                          <TextInput
                            name="veh_broker_code"
                            value={state.veh_broker_code}
                            placeholder="Mã giới thiệu"
                            onChange={handle_change} style={{ flex: "none", width: "40%" }}
                          />
                          <TextInput name="veh_broker_name" value={state.veh_broker_name} disabled onChange={handle_change} />
                        </div>
                      </div>
                    </Row>
                  </Container>
                </div>
              )}
            </div>
          </Row>
        </>
      )}
      {curr_step.code === 4 && (
        <Row className="mt10px">
          <MotoConfirmation data={state} onCancel={back_step} onPay={handle_payment} user={currUser} />
        </Row>
      )}
      {showLoading && <Loading />}
      <VehicleMDPExample show={showExample} onClose={(e) => { setShow((prev) => ({ ...prev, showExample: e })) }} />
      <AlertBox data={alertData} onChange={(e) => { setAlertData((prev) => ({ ...prev, is_show: e })) }} />
    </>
  );
}

export default CtplMoto;
