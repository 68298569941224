import React from 'react'
import { Spinner } from 'react-bootstrap'

function Loading(props) {
    return (
        <div style={{ position: "fixed", inset: "0", background: '#000', zIndex: "9999", opacity: "0.5" }}>
            <Spinner animation="border" variant="warning" />
        </div>
    )
}

export default Loading