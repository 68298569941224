import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types';
import * as FaIcons from 'react-icons/fa'
import CommonHelper from '../../../helpers/common';

const initial = [
    {
        "idx": 1,
        "id": 1,
        "fullName": "Trần Văn An",
        "gender": 0,
        "dob": "20000215",
        "age": "24"
    }
]

function InsuredPerson(props) {
    const [state, setState] = useState([initial]);
    const { data: valueProp, onEdit, onDel, hasAction, ...other } = props;
    const [{ total_premium }, setSelected] = useState({
        total_premium: 0
    })

    const handle_edit = (item) => {
        onEdit(item);
    }
    const handle_delete = (item) => {
        onDel(item);
    }

    useEffect(() => {
        var _total = 0;
        if (valueProp != null && valueProp.length > 0) {
            valueProp.forEach((item) => {
                _total += item.premium;
            })
            setSelected((prev) => ({
                ...prev,
                total_premium: _total
            }))
        }
        setState(valueProp);
    }, [valueProp]);
    return (
        <div>
            {state !== null && state.length > 0 && (
                <>
                    <div className='row mt-3'>
                        {hasAction && (
                            <div className='col-sm-12'>
                                Số người tham gia bảo hiểm: <span className='fw-bolder'>{state.length}</span>
                            </div>
                        )}
                    </div>
                    <div className='row mt-3'>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th scope="col" style={{ width: "55px" }}>STT</th>
                                    <th scope="col">Họ Tên</th>
                                    <th scope="col" style={{ width: "15%" }}>Giới tính</th>
                                    <th scope="col" style={{ width: "15%" }}>Ngày sinh</th>
                                    <th scope="col" style={{ width: "10%" }}>Tuổi</th>
                                    <th scope="col" style={{ width: "20%" }}>Gói BH/ Phí</th>
                                    {hasAction && (<th scope="col" style={{ width: "65px" }}></th>)}
                                </tr>
                            </thead>
                            <tbody>
                                {state.map((item, i) => {
                                    return (
                                        <tr key={i}>
                                            <td scope="row">{i + 1}</td>
                                            <td>{item.fullName}</td>
                                            <td>{item.gender === 1 ? "Nam" : (item.gender === 2 ? "Nữ" : "Khác")}</td>
                                            <td>{CommonHelper.convertToDDMMYYYY(item.dob)}</td>
                                            <td>{CommonHelper.calculate_age(CommonHelper.parseStringToDate(item.dob, "yyyyMMdd"))}</td>
                                            <td>
                                                {item.objPackage != null && (
                                                    <>
                                                        <div>{item.objPackage.pkg_name}</div>
                                                        <div className='text-end'><b>{item.objPackage.premium > 0 ? CommonHelper.format_currency_vnd_style(item.objPackage.premium) : ""}</b></div>
                                                    </>
                                                )}
                                            </td>
                                            {hasAction && (
                                                <td>
                                                    <a href='javascript:;' onClick={() => { handle_edit(item) }}><FaIcons.FaPencilAlt /></a>
                                                    &nbsp;&nbsp;&nbsp;
                                                    <a href='javascript:;' onClick={() => { handle_delete(item) }}><FaIcons.FaTimes style={{ color: "#ff0000" }} /></a>
                                                </td>
                                            )}

                                        </tr>
                                    )
                                })}
                                <tr>
                                    <td></td>
                                    <td colSpan={2}><b>Tổng phí bảo hiểm</b></td>
                                    <td colSpan={3}>
                                        <div className='text-end'>
                                            <b>{CommonHelper.format_currency_vnd_style(total_premium)}</b>
                                        </div>
                                    </td>
                                    {hasAction && (
                                        <td></td>
                                    )}
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className='row mt-3 d-none'>
                        <div className='col-sm-12'>
                            <p><b><i>Lưu ý:</i></b></p>
                            <p style={{ fontStyle: "italic" }}>Nếu muốn "Tăng/Giảm" số người được bảo hiểm. Quý khách click vào nút "Quay lại" và cập nhật trường "Số người được bảo hiểm".</p>
                        </div>
                    </div>
                </>
            )}

        </div>
    )
}

export default InsuredPerson

InsuredPerson.propTypes = {
    data: PropTypes.array.isRequired,
    onEdit: PropTypes.func.isRequired,
    onDel: PropTypes.func.isRequired,
}