import React, { useState, useEffect, useRef } from 'react'
import addYears from "date-fns/addYears";
import addMonths from 'date-fns/addMonths';
import addDays from 'date-fns/addDays';
import TextInput from '../../../components/text-input';
import ShortDateInput from '../../../components/short-date-input';
import CommonHelper from '../../../helpers/common';
import AlertBox from '../../../components/alert';
import AutoComplete from '../../../components/auto-complete';
import ValidateMessage from '../../../components/validate-msg';
import { initialAlert } from '../util';
import { Modal } from 'react-bootstrap';
import petApi from '../../../api/petApi';
import { initial_pet_info } from '../util';
import PetImage1 from "../../../assets/images/Golden_Retrievers_dans_pet_care.jpeg";
import PetImage2 from "../../../assets/images/American_Wirehair_3.jpg";
import { lst_pet_decline, initial_survey_questions } from '../util';
import "./index.css";
import { FaTimes } from 'react-icons/fa';
import { Button } from 'react-bootstrap';
import NoImage from "../../../assets/images/gallery.png";

function PetInfo(props) {
    const {
        onClosePet,
        handleTogglePetInfo,
        handlePostForPetInfo,
        setStatePet,
        data: valueProp,
        show,
        kind_of_pet,
        setListIdImages,
        setListImages,
        ...other
    } = props;
    const [state, setState] = useState(initial_pet_info);
    const [{ stateError, stateErrorMsg }, setStateError] = useState({
        stateError: {
            chipNo: false,
            pet_name: false,
            dob: false,
            gender: false,
            typeOfPet: false,
            age: false,
            petPackage: false,
            survey_questions: false,
        },
        stateErrorMsg: {
            chipNo: "",
            pet_name: "",
            dob: "",
            gender: "",
            typeOfPet: "",
            age: "",
            petPackage: "",
            survey_questions: "",
        }
    });
    const [{
        lst_pkg,
        lst_pet,
        lst_kindOfPet,
        lst_typeOfPet
    }, setList] = useState({
        lst_pet: [],
        lst_pkg: [],
        lst_kindOfPet: [
            { 'code': 'PET_DOG', 'name': 'Chó' },
            { 'code': 'PET_CAT', 'name': 'Mèo' },
        ],
        lst_typeOfPet: ""
    });
    const [alertData, setAlertData] = useState(initialAlert);
    const [{
        dob,
        maxDOB,
        minDOB,
        isBusiness,
        packageSel,
        showPackage
    }, setSelected] = useState({
        dob: null,
        minDOB: addDays(addYears(new Date(), -8), 1), // under 8 years old (7 years 364 days ago)
        maxDOB: addMonths(new Date(), -2), // At least 2 months old
        isBusiness: false,
        packageSel: [],
        showPackage: show,
    });
    const [images, setImages] = useState([]); // upload pet images
    const [imagesRaw, setImagesRaw] = useState([]); // upload pet images
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [currentImage, setCurrentImage] = useState('');
    const [showImageViewer, setShowImageViewer] = useState(false); // view image
    const [selectedImage, setSelectedImage] = useState(null);
    // const [surveyQuestions, setSurveyQuestions] = useState(initial_survey_questions);
    const [showDelImg, setShowDelImg] = useState(false);
    const [selectedImageId, setSelectedImageId] = useState(null);
    const inputRef = useRef(null);

    useEffect(() => {
        if (show) {
            var _dob = null;
            var _def_pkg = []
            var _lst_pet = other.listPet;
            set_opt_pkg(other.list_pkg);
            if (valueProp.objPackage != null && CommonHelper.is_not_empty(valueProp.objPackage.pkg_code)) {
                _def_pkg = other.list_pkg.filter(x => x.pkg_code == valueProp.objPackage.pkg_code && x.type == kind_of_pet);
            }
            console.log("VALUE PROP", valueProp.survey_questions, initial_survey_questions);
            if (valueProp.action === "edit") {
                _dob = CommonHelper.parseStringToDate(valueProp.dob, "yyyyMMdd");
                // setSurveyQuestions(valueProp.survey_questions);
                setStatePet((prev) => ({
                    ...prev,
                    survey_questions: valueProp.survey_questions,
                }));
            } else if (valueProp.action === "new") {
                console.log("ACTION NEW", valueProp.action, valueProp);
                // resetSurveyQuestions();
                resetPetInputs();
                handle_clear_images();
            }
            setState((prev) => ({
                ...prev,
                "idx": valueProp.idx,
                "id": valueProp.id,
                "orderNo": valueProp.orderNo,
                "pet_name": valueProp.pet_name,//update
                "chipNo": valueProp.chipNo,
                "dob": valueProp.dob,
                "gender": valueProp.gender,
                "kindOfPet": valueProp.kindOfPet,
                "typeOfPet": valueProp.typeOfPet,
                "relationship": valueProp.relationship,
                "isForeigner": valueProp.isForeigner,
                "premium": valueProp.objPackage.premium,
                "action": valueProp.action,
                "objPackage": valueProp.objPackage,
            }))
            setSelected((prev) => ({
                ...prev,
                isBusiness: other.masterData.veh_business === "Y",
                packageSel: _def_pkg,
                dob: _dob,
            }))
            setStateError((prev) => ({
                ...prev,
                stateError: {
                    chipNo: false,
                    pet_name: false,
                    dob: false,
                    age: false,
                    kindOfPet: false,
                    typeOfPet: false,
                    premium: false,
                    petPackage: false,
                },
                stateErrorMsg: {
                    chipNo: "",
                    pet_name: "",
                    dob: "",
                    age: "",
                    kindOfPet: "",
                    typeOfPet: "",
                    premium: "",
                    petPackage: "",
                }
            }))
            if (valueProp.images != null && valueProp.images.length > 0) {
                setImages(valueProp.images);
            }
            if (valueProp.survey_questions != null && valueProp.survey_questions.length > 0) {
                // setSurveyQuestions(valueProp.survey_questions);
                setStatePet((prev) => ({
                    ...prev,
                    survey_questions: valueProp.survey_questions,
                }));
            }
        }
    }, [
        lst_kindOfPet,
        other.listPet,
        other.list_pkg,
        other.masterData.veh_business,
        show,
        showPackage,
    ]
        // valueProp]
    );

    const resetPetInputs = () => {
        inputRef.current.value = null;
        // setSurveyQuestions(initial_survey_questions);
    };
    const handle_clear_images = () => {
        setImages([]);
        setImagesRaw([]);
        setCurrentImage('');
        setSelectedImage(null);
    };
    const handle_close_reset = () => {
        // setSurveyQuestions(initial_survey_questions);
        setSelected((prev) => ({
            ...prev,
            showPackage: false
        }));
        onClosePet(false, null);
        resetPetInputs();
    };
    const handleImageUpload = (event) => {
        const files = Array.from(event.target.files);
        const newImages = files.map((file) => URL.createObjectURL(file));
        setImages((prevImages) => [
            ...prevImages,
            ...newImages
        ]);
        setImagesRaw((prevImages) => [
            ...prevImages,
            ...files
        ]);
    };
    const handleDeleteImage = async () => {
        try {
            const res = await petApi.delete_insured_attachment(selectedImageId);
            if (res.code === 204) {
                setAlertData((prev) => ({
                    ...prev,
                    title: "Thông báo",
                    content: "Xóa ảnh thú cưng thành công!",
                    is_show: true,
                    variant: "success",
                }));
                setImages((prevImages) => prevImages.filter((image) => image.id != selectedImageId));
                handleDelClose();
            }
        } catch (error) {
            console.error(error);
            handleDelClose();
        }
    };
    const handleSaveImage = async () => {
        try {
            let listIdImageTemp = [];
            let listImageTemp = [];
            if (images && images.length) {
                let countSuccess = 0;
                let listImageSaved = [];
                if (images.length == 0 || images.length < 2) {
                    setAlertData((prev) => ({
                        ...prev,
                        title: "Cảnh báo",
                        content: "Vui lòng upload đủ 2 ảnh thú cưng!",
                        is_show: true,
                        variant: "warning",
                    }));
                } else {
                    const uploadPromises = imagesRaw.map(async (image) => {
                        let _data = {
                            id: 0,
                            order_no: null,
                            pet_attachment: image,
                        };
                        try {
                            const res = await petApi.upload_pet_attachment(_data);
                            if (res.code === 200) {
                                countSuccess++;
                                listImageSaved.push(image);
                                listIdImageTemp.push(res.data.id);
                                listImageTemp.push(res.data);
                            };
                        } catch (uploadError) {
                            setAlertData((prev) => ({
                                ...prev,
                                title: "Lỗi",
                                content: "Lỗi upload ảnh" + image.name + "!",
                                is_show: true,
                                variant: "danger"
                            }));
                            console.error(`Error uploading image: ${image.name}`, uploadError);
                        }
                    });
                    setState((prevData) => ({
                        ...prevData,
                        images: listImageSaved
                    }));
                    await Promise.all(uploadPromises);
                    if (imagesRaw.length === countSuccess) {
                        setListIdImages(listIdImageTemp);
                        setListImages(listImageTemp);
                        setAlertData((prev) => ({
                            ...prev,
                            title: "Thông báo",
                            content: "Upload ảnh thú cưng thành công!",
                            is_show: true,
                            variant: "success",
                        }));
                    } else {
                        setAlertData((prev) => ({
                            ...prev,
                            title: "Thông báo",
                            content: `Upload thành công ${countSuccess}/${imagesRaw.length} ảnh.`,
                            is_show: true,
                            variant: countSuccess > 0 ? "warning" : "danger",
                        }));
                    }
                }
            }
        } catch (error) {
            setAlertData((prev) => ({
                ...prev,
                title: "Thông báo",
                content: "Có lỗi xảy ra khi upload ảnh.",
                is_show: true,
                variant: "danger",
            }));
            console.error("Error uploading images:", error);
        }
    };
    const closeImageModal = () => {
        setIsModalOpen(false);
        setCurrentImage('');
    };
    const handleImageClick = (imageSrc) => {
        setSelectedImage(imageSrc);
        setShowImageViewer(true);
    };
    const handle_change = (e) => {
        const { name, value } = e.target;
        var _val = value;
        var _state = { ...state };
        setState((prevData) => ({
            ...prevData,
            [name]: _val,
        }));
        //reset validate
        reset_validate();
        validate_control(e);
    };
    const reset_validate = () => {
        var _obj = { ...stateError };
        Object.keys(_obj).map((item) => {
            _obj[item] = false;
        });
        setStateError((prev) => ({
            ...prev,
            stateError: _obj
        }));
    };
    const validate_control = (e) => {
        const { name, value } = e.target;
        var _obj = { ...stateError };
        var _objMsg = { ...stateErrorMsg };
        var _arr = [];
        Object.keys(_obj).map((item) => {
            _arr.push(item);
        });
        if (_arr.indexOf(name) >= 0) {
            _obj[name] = false;
            _objMsg[name] = "";
            if (CommonHelper.is_empty(value)) {
                _obj[name] = true;
                _objMsg[name] = "required";
            }
            setStateError((prev) => ({
                ...prev,
                stateError: _obj,
                stateErrorMsg: _objMsg,
            }));
        }
        if (name === 'typeOfPet') {
            if (lst_pet_decline.includes(value)) {
                _obj[name] = true;
                _objMsg[name] = "Loài thú cưng nằm trong danh sách bị loại trừ. Vui lòng kiểm tra lại!";
                setStateError((prev) => ({
                    ...prev,
                    stateError: _obj,
                    stateErrorMsg: _objMsg,
                }))
            }
        }
    };
    const validate_chipNo = (e) => {
        const { name, value } = e.target;
        var _flag = CommonHelper.check_chipno_valid(value);
        setStateError((prev) => ({
            ...prev,
            stateError: {
                ...stateError,
                [name]: !_flag
            },
            stateErrorMsg: {
                ...stateErrorMsg,
                [name]: !_flag ? "invalid" : ""
            },
        }));
    };
    const change_dob = (val) => {
        setSelected((prev) => ({
            ...prev,
            dob: val,
        }));
        var _dob = "";
        if (val != null) {
            _dob = CommonHelper.formatToYYYYMMDD(val);
        }
        setState((prev) => ({
            ...prev,
            dob: _dob,
        }));
        setStateError((prev) => ({
            ...prev,
            stateError: {
                ...stateError,
                dob: val == null
            },
            stateErrorMsg: {
                ...stateErrorMsg,
                dob: (val == null ? "required" : "")
            },
        }));
    };
    const change_raw_dob = (e) => {
        var _val = e.target.value;
        if (CommonHelper.is_not_empty(_val)) {
            _val = _val.replace(/[^0-9]/g, "");
            if (_val.length === 8) {
                var _dob = CommonHelper.parseStringToDate(_val, "ddMMyyyy");
                if (_dob >= minDOB && _dob <= maxDOB) {
                    setSelected((prev) => ({
                        ...prev,
                        dob: _dob,
                    }));
                } else {

                }
            }
        }
    };
    const handle_radio_check = (e) => {
        const { name, value } = e.target;
        if (name === "pet_gender") {
            setState((prevData) => ({
                ...prevData,
                gender: CommonHelper.convertToInt(value),
            }));
        }
    };
    const validate_form = () => {
        var flag = true;
        var _data = { ...state };
        var _obj = { ...stateError };
        var _objMsg = { ...stateErrorMsg };
        var _arr = [];
        Object.keys(_obj).map((item) => {
            if (_obj[item]) {
                flag = false;
            }
        })
        if (CommonHelper.is_empty(_data.typeOfPet)) {
            flag = false;
            _obj.typeOfPet = true;
            _objMsg.typeOfPet = "required";
        }
        if (CommonHelper.is_empty(_data.pet_name)) {
            flag = false;
            _obj.pet_name = true;
            _objMsg.pet_name = "required";
        }
        if (CommonHelper.is_empty(_data.chipNo)) {
            flag = false
            _obj.chipNo = true;
            _objMsg.chipNo = "required";
        }
        if (dob === null) {
            flag = false
            _obj.dob = true;
            _objMsg.dob = "required";
        }
        var _arr = other.listPet;
        if (_arr.length > 0) {
            _arr.forEach((item) => {
                if (_data.action === "new") {
                    if (item.pet_name === _data.pet_name) {
                        flag = false;
                        _obj.pet_name = true;
                        _objMsg.pet_name = "Tên thú cưng được bảo hiểm đã tồn tại trong danh sách. Vui lòng kiểm tra lại!";
                    }
                    if (item.chipNo === _data.chipNo) {
                        flag = false;
                        _obj.chipNo = true;
                        _objMsg.chipNo = "Số seri chip thú cưng đã tồn tại trong danh sách. Vui lòng kiểm tra lại!";
                    }
                }
            })
        }

        // surveyQuestions.forEach((question, index) => {
        //     if (CommonHelper.is_empty(question.answer_yn)) {
        //         flag = false;
        //         setAlertData((prev) => ({
        //             ...prev,
        //             title: "Lỗi",
        //             content: "Chưa trả lời khai báo thú cưng!",
        //             is_show: true,
        //             variant: "danger"
        //         }));
        //     } else
        //         if (question.answer_yn === 'Y' && CommonHelper.is_empty(question.note)) {
        //             flag = false;
        //             setAlertData((prev) => ({
        //                 ...prev,
        //                 title: "Lỗi",
        //                 content: "Chưa điền nội dung khai báo!",
        //                 is_show: true,
        //                 variant: "danger"
        //             }));
        //         }
        // });
        valueProp.survey_questions.forEach((question, index) => {
            if (CommonHelper.is_empty(question.answer_yn)) {
                flag = false;
                setAlertData((prev) => ({
                    ...prev,
                    title: "Lỗi",
                    content: "Chưa trả lời khai báo thú cưng!",
                    is_show: true,
                    variant: "danger"
                }));
            } else
                if (question.answer_yn === 'Y' && CommonHelper.is_empty(question.note)) {
                    flag = false;
                    setAlertData((prev) => ({
                        ...prev,
                        title: "Lỗi",
                        content: "Chưa điền nội dung khai báo!",
                        is_show: true,
                        variant: "danger"
                    }));
                }
        });

        if (images.length == 0 || images.length < 2) {
            flag = false;
            setAlertData((prev) => ({
                ...prev,
                title: "Cảnh báo",
                content: "Vui lòng upload đủ 2 ảnh thú cưng!",
                is_show: true,
                variant: "warning",
            }));
        }

        if (!flag) {
            setStateError((prev) => ({
                ...prev,
                stateError: _obj,
                stateErrorMsg: _objMsg,
            }));
        }
        return flag;
    };
    const handle_save = async () => {
        var flag = validate_form();
        if (flag) {
            var _data = { ...state };
            // _data.survey_questions = surveyQuestions;
            _data.survey_questions = valueProp.survey_questions;
            setSelected((prev) => ({
                ...prev,
                showPackage: false
            }));
            // let result = await onClosePet(false, _data);
            // console.log("RESULT_CLOSE", result);
            // if(result !== 705) {
            //     resetPetInputs();
            // }
            const res = await handlePostForPetInfo(_data);
            console.log("RESULT_CLOSE", res);
            if (res !== 705) {
                handleTogglePetInfo(false);
            }
        }
    };
    const select_pkg = (e) => {
        var objPackage = {};
        setSelected((prev) => ({
            ...prev,
            packageSel: e
        }));
        var _premium = 0;
        if (e.length > 0) {
            _premium = e[0].premium;
            objPackage = {
                id: e[0].id,
                pkg_code: e[0].pkg_code,
                pkg_name: e[0].pkg_name,
                type: e[0].type,
                smi1: e[0].smi1,
                smi2: e[0].smi2,
                smi3: e[0].smi3,
                smi4: e[0].smi4,
                smi5: e[0].smi5,
                smi6: e[0].smi6,
                smi7: e[0].smi7,
                premium: e[0].premium,
                taxAmt: e[0].taxAmt,
                taxRatio: e[0].taxRatio,
            };
        }
        setState((prev) => ({
            ...prev,
            objPackage,
            _premium
        }))
        setStateError((prev) => ({
            ...prev,
            stateError: {
                ...stateError,
                petPackage: e.length == 0
            },
            stateErrorMsg: {
                ...stateErrorMsg,
                petPackage: (e.length == 0 ? "required" : "")
            },
        }))
    };
    const set_opt_pkg = (lst) => {
        if (lst != null && lst.length > 0) {
            const filteredList = lst.filter((item) => item.type === kind_of_pet).map((item) => {
                item["name"] = item.pkg_name;
                return item;
            });
            setList((prev) => ({
                ...prev,
                lst_pkg: filteredList,
            }));
        } else {
            setList((prev) => ({
                ...prev,
                lst_pkg: [],
            }));
        }
    };
    const handleAnswerChange = (index, value) => {
        // setSurveyQuestions(prevQuestions => {
        //     const updatedQuestions = [...prevQuestions];
        //     updatedQuestions[index].answer_yn = value;
        //     if (value === 'N') {
        //         updatedQuestions[index].note = '';
        //     }
        //     return updatedQuestions;
        // });
        setStatePet((prevState) => {
            const updatedQuestions = [...prevState.survey_questions];
            updatedQuestions[index].answer_yn = value;
            if (value === 'N') {
                updatedQuestions[index].note = '';
            }
            return {
                ...prevState,
                survey_questions: updatedQuestions,
            };
        });

    };
    const handleNoteChange = (index, value) => {
        // setSurveyQuestions(prevQuestions => {
        //     const updatedQuestions = [...prevQuestions];
        //     updatedQuestions[index].note = value;
        //     return updatedQuestions;
        // });
        setStatePet((prevState) => {
            const updatedQuestions = [...prevState.survey_questions];
            updatedQuestions[index].note = value;
            return {
                ...prevState,
                survey_questions: updatedQuestions,
            };
        });

    };
    const handleDelClose = () => setShowDelImg(false);
    const handleDelShow = (id) => {
        setSelectedImageId(id);
        setShowDelImg(true);
    };
    // const resetSurveyQuestions = () => {
    // //    setSurveyQuestions(prevQuestions => {
    // //         const updatedQuestions = [...prevQuestions];
    // //         updatedQuestions[index].answer_yn = value;
    // //         if (value === 'N') {
    // //             updatedQuestions[index].note = '';
    // //         }
    // //         return updatedQuestions;
    // //     });
    //     setSurveyQuestions(prevQuestions => {
    //         const updatedQuestions = prevQuestions.map(item => ({
    //             ...item,
    //             answer_yn: '', // Clear radio button selection
    //             note: ''       // Clear note if necessary
    //         }));
    //         return updatedQuestions;
    //     });
    // };

    return (
        <>
            <div
                style={{
                    display: show ? "block" : "none",
                    borderRadius: 'opx',
                    border: '1px solid #20c997',
                    padding: '16px'
                }}
                className={(show ? " showbs" : "")}
            >
                <div className={(show ? " showbs" : "")}>
                    <div className='bottom-sheet-header'>
                        <div className='close-box'>
                            <button
                                type="button"
                                className="btn-close"
                                aria-label="Close"
                                onClick={handle_close_reset}
                            >
                            </button>
                        </div>
                    </div>
                    <div className='bottom-sheet-body'>
                        <div className='mt-4'>
                            <div className='col-md-12'>
                                <div className='container'>
                                    <div className='row'>
                                        <div
                                            className='col-sm-12'
                                            style={{
                                                backgroundColor: '#20c997',
                                                paddingBottom: '8px',
                                                color: 'white',
                                                height: '32px',
                                                marginBottom: '12px',
                                                textAlign: 'center'
                                            }}
                                        >
                                            <p>
                                                <b>
                                                    <label className="form-label fs-5 font-semibold">
                                                        THÚ CƯNG ĐƯỢC BẢO HIỂM
                                                    </label>
                                                </b>
                                            </p>
                                        </div>
                                    </div>
                                    {!isBusiness && (
                                        <div className='row'>
                                            <div className='col-sm-6'>
                                                <label className="form-label required">
                                                    Loại thú cưng
                                                </label>
                                                <div className='col-sm-12'>
                                                    <select
                                                        className={'form-select form-select-sm ' + (stateError.kindOfPet ? "error" : "")}
                                                        name='kindOfPet'
                                                        onChange={handle_change}
                                                        value={kind_of_pet}
                                                    >
                                                        <option value={kind_of_pet} >
                                                            {kind_of_pet == "PET_DOG" ? "Chó" : "Mèo"}
                                                        </option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className='col-sm-6'>
                                                <label className="form-label required">
                                                    Giống loài
                                                </label>
                                                <TextInput
                                                    value={state.typeOfPet}
                                                    name="typeOfPet"
                                                    onChange={handle_change}
                                                    className={"ptb-16px " + (stateError.typeOfPet ? "error" : "")}
                                                />
                                                <ValidateMessage
                                                    isShow={stateError.typeOfPet}
                                                    msgType={stateErrorMsg.typeOfPet}
                                                />
                                            </div>
                                        </div>
                                    )}
                                    <div className='row mt-2'>
                                        <div className='col-sm-6'>
                                            <div className='row'>
                                                <label className="form-label required">
                                                    Tên thú cưng
                                                </label>
                                                <div className='col-sm-12'>
                                                    <TextInput
                                                        value={state.pet_name}
                                                        name="pet_name"
                                                        onChange={handle_change}
                                                        className={"ptb-16px " + (stateError.pet_name ? "error" : "")}
                                                    />
                                                    <ValidateMessage
                                                        isShow={stateError.pet_name}
                                                        msgType={stateErrorMsg.pet_name}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-sm-6'>
                                            <div className='row'>
                                                <label className="form-label required">
                                                    Số seri của chip
                                                </label>
                                                <div className='col-sm-12'>
                                                    <TextInput
                                                        value={state.chipNo}
                                                        name="chipNo"
                                                        onBlur={validate_chipNo}
                                                        onChange={handle_change}
                                                        className={"ptb-16px " + (stateError.chipNo ? "error" : "")}
                                                    />
                                                    <ValidateMessage
                                                        isShow={stateError.chipNo}
                                                        msgType={stateErrorMsg.chipNo}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row mt-2'>
                                        <div className='col-sm-6'>
                                            <div className='row'>
                                                <label className="form-label required">
                                                    Ngày sinh
                                                </label>
                                                <div className='col-sm-12'>
                                                    <ShortDateInput
                                                        selected={dob}
                                                        minDate={minDOB}
                                                        maxDate={maxDOB}
                                                        onChange={change_dob}
                                                        onChangeRaw={change_raw_dob}
                                                        className={"ptb-16px " + (stateError.dob ? "error" : "")}
                                                    />
                                                    <ValidateMessage
                                                        isShow={stateError.dob}
                                                        msgType={stateErrorMsg.dob}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-sm-6'>
                                            <div className='row'>
                                                <label className="form-label required">
                                                    Giống
                                                </label>
                                                <div className='col-sm-12'>
                                                    <div className='d-flex'>
                                                        <div className='mr15px'>
                                                            <div className="form-check" >
                                                                <input
                                                                    className="form-check-input"
                                                                    type="radio"
                                                                    name="pet_gender"
                                                                    id={`radio-pet-gender-1`}
                                                                    defaultValue={"1"}
                                                                    checked={state.gender == 1}
                                                                    value="1"
                                                                    onChange={handle_radio_check}
                                                                    style={{ padding: '8px' }}
                                                                />
                                                                <label
                                                                    className="form-check-label"
                                                                    htmlFor={`radio-pet-gender-1`}
                                                                >
                                                                    Đực
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className='mr15px'>
                                                            <div className="form-check" >
                                                                <input
                                                                    className="form-check-input"
                                                                    type="radio"
                                                                    name="pet_gender"
                                                                    id={`radio-pet-gender-2`}
                                                                    checked={state.gender == 2}
                                                                    value="2"
                                                                    onChange={handle_radio_check}
                                                                    style={{ padding: '8px' }}
                                                                />
                                                                <label
                                                                    className="form-check-label"
                                                                    htmlFor={`radio-pet-gender-2`}
                                                                >
                                                                    Cái
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row mt-2'>
                                        <label className='form-label required'>
                                            Gói bảo hiểm
                                        </label>
                                        <div className='col-sm-6'>
                                            <AutoComplete
                                                id="veh_pet_pkg"
                                                renderMenuItemChildren={(option) => (
                                                    <>
                                                        <div >
                                                            <b>
                                                                {option.name}
                                                            </b>
                                                        </div>
                                                        <div>
                                                            <small>
                                                                Phí (VND/năm): {CommonHelper.format_currency_vnd_style(option.premium)}
                                                            </small>
                                                        </div>
                                                    </>
                                                )}
                                                className={stateError.petPackage ? "error" : ""}
                                                options={lst_pkg}
                                                selected={packageSel}
                                                onChange={select_pkg}
                                            />
                                            <ValidateMessage
                                                isShow={stateError.petPackage}
                                                msgType={stateErrorMsg.petPackage}
                                            />
                                        </div>
                                        <div className='col-sm-6'>
                                            <label className='form-label'>
                                                {packageSel.length > 0 && (
                                                    <>
                                                        {"Phí (VND/năm): " + CommonHelper.format_currency_vnd_style(packageSel[0].premium)}
                                                    </>
                                                )}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row mt-4">
                            <div
                                className='col-sm-12'
                                style={{
                                    backgroundColor: '#20c997',
                                    paddingBottom: '8px',
                                    color: 'white',
                                    height: '32px',
                                    marginBottom: '12px',
                                    textAlign: 'center'
                                }}
                            >
                                <p>
                                    <b>
                                        <label className="form-label fs-5 font-semibold">
                                            HẠNG MỤC KHAI BÁO
                                        </label>
                                    </b>
                                </p>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-md-10'>
                                <h5 className='bold'>
                                    Hãy trả lời các câu hỏi dưới đây:
                                </h5>
                            </div>
                        </div>
                        {/* <div className="row mt-1">
                            {surveyQuestions.map((item, index) => (
                                <div className="card mb-3" key={item.code}>
                                    <div className="card-body">
                                        <p className="card-text font-weight-bold">
                                            {index + 1}. {item.question}
                                        </p>
                                        <div className="form-check form-check-inline">
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                name={`question_${index}`}
                                                id={`question_${index}_yes`}
                                                value="Y"
                                                checked={item.answer_yn === 'Y'}
                                                onChange={() => handleAnswerChange(index, 'Y')}
                                            />
                                            <label 
                                                className="form-check-label" 
                                                htmlFor={`question_${index}_yes`}
                                            >
                                                Có
                                            </label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                name={`question_${index}`}
                                                id={`question_${index}_no`}
                                                value="N"
                                                checked={item.answer_yn === 'N'}
                                                onChange={() => handleAnswerChange(index, 'N')}
                                            />
                                            <label 
                                                className="form-check-label" 
                                                htmlFor={`question_${index}_no`}
                                            >
                                                Không
                                            </label>
                                        </div>
                                        {item.answer_yn === 'Y' && (
                                            <div className="mt-3">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id={`note_${index}`}
                                                    placeholder={item.placeholder}
                                                    value={item.note}
                                                    onChange={(e) => handleNoteChange(index, e.target.value)}
                                                    style={{ width: '660px' }}
                                                />
                                            </div>
                                        )}
                                    </div>
                                </div>
                            ))}
                        </div> */}
                        <div className="row mt-1">
                            {valueProp.survey_questions && valueProp.survey_questions.length > 0 ? (
                                valueProp.survey_questions.map((item, index) => (
                                    <div className="card mb-3" key={item.code}>
                                        <div className="card-body">
                                            <p className="card-text font-weight-bold">
                                                {index + 1}. {item.question}
                                            </p>
                                            <div className="form-check form-check-inline">
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    name={`question_${index}`}
                                                    id={`question_${index}_yes`}
                                                    value="Y"
                                                    checked={item.answer_yn === 'Y'}
                                                    onChange={() => handleAnswerChange(index, 'Y')}
                                                />
                                                <label
                                                    className="form-check-label"
                                                    htmlFor={`question_${index}_yes`}
                                                >
                                                    Có
                                                </label>
                                            </div>
                                            <div className="form-check form-check-inline">
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    name={`question_${index}`}
                                                    id={`question_${index}_no`}
                                                    value="N"
                                                    checked={item.answer_yn === 'N'}
                                                    onChange={() => handleAnswerChange(index, 'N')}
                                                />
                                                <label
                                                    className="form-check-label"
                                                    htmlFor={`question_${index}_no`}
                                                >
                                                    Không
                                                </label>
                                            </div>
                                            {item.answer_yn === 'Y' && (
                                                <div className="mt-3">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id={`note_${index}`}
                                                        placeholder={item.placeholder}
                                                        value={item.note}
                                                        onChange={(e) => handleNoteChange(index, e.target.value)}
                                                        style={{ width: '660px' }}
                                                    />
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                ))
                            ) : (
                                <p>No survey questions available</p>
                            )}
                        </div>
                        <div className="row mt-4">
                            <div
                                className='col-sm-12'
                                style={{
                                    backgroundColor: '#20c997',
                                    paddingBottom: '8px',
                                    color: 'white',
                                    height: '100px',
                                    marginBottom: '12px',
                                    textAlign: 'center'
                                }}
                            >
                                <p
                                    style={{
                                        marginBottom: '0px',
                                        paddingBottom: '0px'
                                    }}
                                >
                                    <b>
                                        <label className="form-label fs-5 font-semibold">
                                            UPLOAD ẢNH THÚ CƯNG
                                        </label>
                                    </b>
                                </p>
                                <h6
                                    style={{
                                        fontSize: '14px',
                                        marginTop: '0px',
                                        paddingTop: '0px'
                                    }}
                                >
                                    <span style={{ color: 'red' }}>
                                        (*)</span> Khách hàng chụp <span style={{ color: 'yellow', fontSize: '20px' }}>2</span> ảnh thú cưng theo mẫu, hình chụp toàn thân phải rõ ràng và nhìn thấy rõ 4 chân. Ảnh thú cưng phải khớp với thú cưng được bảo hiểm, hồ sơ yêu cầu bồi thường có thể bị từ chối nếu thông tin cung cấp không chính xác.
                                </h6>
                            </div>
                            <h5>
                                Ảnh chụp mẫu thú cưng
                            </h5>
                            <div className="gallery">
                                <div className="image-container">
                                    <img
                                        src={PetImage1}
                                        alt={`ảnh mẫu 1`}
                                        width={60}
                                        height={50}
                                        onClick={() => handleImageClick(PetImage1)}
                                    />
                                    <button
                                        className="delete-button"
                                        style={{ backgroundColor: '#20c997' }}
                                    >
                                        1
                                    </button>
                                </div>
                                <div className="image-container">
                                    <img
                                        src={PetImage2}
                                        alt={`ảnh mẫu 2`}
                                        width={60}
                                        height={50}
                                        onClick={() => handleImageClick(PetImage2)}
                                    />
                                    <button
                                        className="delete-button"
                                        style={{ backgroundColor: '#20c997' }}
                                    >
                                        2
                                    </button>
                                </div>
                            </div>
                            <hr style={{ marginTop: '16px' }} />
                            <h5>
                                Ảnh chụp thú cưng
                            </h5>
                            <div className="gallery">
                                {images != null && images.length > 0 && (
                                    <>
                                        {images.map((image, i) => (
                                            <div
                                                key={i}
                                                className="image-container"
                                            >
                                                <img
                                                    src={image.file_url ?? (image ? image : NoImage)}
                                                    alt={`uploaded-${i}`}
                                                    width={60}
                                                    height={50}
                                                    onClick={() => handleImageClick(image.file_url ?? (image ? image : NoImage))}
                                                />
                                                <button
                                                    className="delete-button"
                                                    // onClick={() => handleDeleteImage(image.id)}
                                                    onClick={() => handleDelShow(image.id)}
                                                >
                                                    <FaTimes />
                                                </button>
                                            </div>
                                        ))}
                                    </>
                                )}
                            </div>
                            <div
                                className='row'
                                style={{ alignItems: 'center' }}
                            >
                                <div
                                    className='col-md-12'
                                    style={{ textAlign: "left" }}
                                >
                                    <h6
                                        style={{
                                            fontSize: '16px',
                                            marginTop: '16px',
                                            paddingTop: '0px'
                                        }}
                                    >
                                        <span style={{ color: 'red' }}>(*) </span> Upload ảnh thú cưng
                                    </h6>
                                    <input
                                        type="file"
                                        multiple
                                        onChange={handleImageUpload}
                                        ref={inputRef}
                                    />
                                    <button
                                        className="btn btn-primary"
                                        onClick={handleSaveImage}
                                        style={{
                                            borderRadius: '0',
                                            fontWeight: '700',
                                            marginLeft: '4px',
                                            marginRight: '4px'
                                        }}
                                    >
                                        {"Lưu ảnh"}
                                    </button>
                                </div>
                            </div>
                        </div>
                        <hr
                            style={{
                                border: '2px dashed black',
                                marginTop: '20px',
                                marginBottom: '20px'
                            }}
                        />
                        <div className='row mt-3'>
                            <div className='text-center'>
                                <button
                                    className="btn btn-secondary"
                                    onClick={handle_close_reset}
                                    style={{
                                        borderRadius: '0',
                                        fontWeight: '700',
                                        marginLeft: '4px',
                                        marginRight: '4px'
                                    }}
                                >
                                    {"Hủy"}
                                </button>
                                <button
                                    className="btn btn-outline-success ms-1"
                                    onClick={handle_save}
                                    style={{
                                        borderRadius: '0',
                                        fontWeight: '700'
                                    }}
                                >
                                    {"Lưu"}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal
                isOpen={isModalOpen}
                onRequestClose={closeImageModal}
                contentLabel="Image Modal"
                className="image-modal"
                overlayClassName="image-overlay"
            >
                <button
                    onClick={closeImageModal}
                    className="close-modal"
                >
                    Close
                </button>
                <img
                    src={currentImage}
                    alt="Zoomed"
                    className="modal-image"
                />
            </Modal>
            <AlertBox
                data={alertData}
                onChange={(e) => { setAlertData((prev) => ({ ...prev, is_show: e })) }}
            />
            {showImageViewer && (
                <div
                    className="modal-zoom-image"
                    onClick={() => setShowImageViewer(false)}
                >
                    <div
                        className="modal-content-zoom-image"
                        onClick={(e) => e.stopPropagation()}
                    >
                        <img
                            src={selectedImage}
                            alt="Selected"
                            style={{
                                width: '750px',
                                height: '500px'
                            }}
                        />
                        <button
                            onClick={() => setShowImageViewer(false)}
                            className="close-button-zoom-image"
                        >
                            Đóng
                        </button>
                    </div>
                </div>
            )}
            {/* Modal */}
            <Modal
                show={showDelImg}
                onHide={handleDelClose}
                centered
                backdrop="static"
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        Xác nhận xóa ảnh thú cưng
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Bạn có chắc chắn muốn xóa ảnh này không?
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={handleDelClose}
                        style={{ borderRadius: '0px' }}
                    >
                        Hủy
                    </Button>
                    <Button
                        variant="danger"
                        onClick={handleDeleteImage}
                        style={{ borderRadius: '0px' }}
                    >
                        Xóa
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default PetInfo;