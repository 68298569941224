import React, { useState, useEffect } from 'react'
import "../agent/index.css"
import "../../App.css"
import { Link, Outlet } from 'react-router-dom'
import Sidebar from './component/sidebar'
import Navigation from './component/navbars'
import CommonHelper from '../../helpers/common'
import Authentication from '../../api/authApi'

function FbHandlerLayout() {
  const [state_user, setStateUser] = useState({});

  const get_user = () => {
    var data = Authentication.get_user();
    data.then((res) => {
      if (res.code === 506) {
        CommonHelper.clear_storage_user();
        window.location.href = "/nhan-vien/dang-nhap";
      } else {
        var objUser = CommonHelper.get_user();
        objUser.then((e) => {
          var _url = window.location.pathname;
          if (e.user_type == 3) {
            window.location.href = _url != "/" ? _url.replace("fubon", "dai-ly") : "/dai-ly/ctpl-moto";
          }
          setStateUser(e);
        })
      }
    })
  };
  useEffect(() => {
    get_user();
  }, [])
  const log_out = () => {
    var response = Authentication.logout();
    response.then((res) => {
      window.location.href = "/nhan-vien/dang-nhap";
    })
  }
  return (
    <>
      <div className="portal-wrapper">
        <Sidebar />
        <div className="main-panel">
          <Navigation onLogout={log_out} data={state_user} />
          <div className="content" style={{ background: "#f7f7f7" }}>
            <Outlet />
          </div>
        </div>
      </div>
    </>

  )
}

export default FbHandlerLayout