import React from 'react';

const CriteriaTable = ({ criteriaList, prefix, onChange }) => {
    // console.log("criteriaList", criteriaList);
    // console.log("prefix", prefix);
    // console.log("onChange", onChange);
    return (
        <div className="table-container" style={{ maxHeight: '600px' }}>
            <table className="table table-bordered table-hover">
                <thead className="center-vertically" style={{ backgroundColor: 'white', color: 'black', top: '0' }}>
                    <tr className='row' style={{ verticalAlign: 'middle' }}>
                        <th className='col-md-2 pt-2' style={{ padding: '0' }}>Tiêu chí/thang điểm đánh giá</th>
                        {[...Array(10)].map((_, i) => (
                            <th key={`${prefix}-header-${i}`} className='col-md-1 pt-3'>{i + 1}</th>
                        ))}
                    </tr>
                </thead>
                <tbody className="center-vertically">
                    {criteriaList.length === 0 ? (
                        <tr>
                            <td colSpan="11" style={{ textAlign: 'center' }}>Không có dữ liệu!!!</td>
                        </tr>
                    ) : (
                        criteriaList.map((item, i) => (
                            <tr className='row' style={{ verticalAlign: 'middle' }} key={`${prefix}-row-${i}`}>
                                <td className='col-md-2 pt-2' style={{ textAlign: 'left' }}>{`${prefix}.${i + 1} ${item.title}`}</td>
                                {[...Array(10)].map((_, j) => (
                                    <td key={`${prefix}-cell-${i}-${j}`} className='col-md-1 pt-3'>
                                        <input 
                                            type='radio' 
                                            name={`criteria-${prefix}-${i}`} 
                                            value={item.title + "|" + (j + 1)}
                                            onChange={(e) => onChange(i, e.target.value)} 
                                            style={{ width: '16px', height: '16px', cursor: 'pointer' }} 
                                        />
                                    </td>
                                ))}
                            </tr>
                        ))
                    )}
                </tbody>
            </table>
        </div>
    );
};

export default CriteriaTable;
