export const initialAlert = {
    is_show: false,
    title: "",
    content: "",
    variant: "danger"
};

export const initial = {
    id: 0,
    full_name: "",
    name: "",
    phone: "",
    email: "",
    identity: "",
    username: "",
    password: "",
    topicName: "",
    quesTotal: "",
    begin: false,
    finish: false,
};