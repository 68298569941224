import React, { useState, useEffect } from 'react'
import { redirect, useLoaderData } from 'react-router-dom';
import CommonHelper from '../../../helpers/common';
import Authentication from '../../../api/authApi';
import bgLogin from "../../../assets/images/bg-login.jpg";
import TextInput from '../../../components/text-input';
import Loading from '../../../components/loading';
import AlertBox from '../../../components/alert';
import fbBanner from '../../../assets/images/logo.svg';
import { IoReload } from "react-icons/io5";
import { Link, useNavigate } from 'react-router-dom';
import localforage from 'localforage';

const initial = {
    "old_password": "",
    "password": "",
    "re_password": ""
};

const initialAlert = {
    is_show: false,
    title: "",
    content: "",
    variant: "danger"
};

function EmployeeReset() {

    const [state, setState] = useState(initial);
    const [alertData, setAlertData] = useState(initialAlert);
    const [showLoading, setShowLoading] = useState(false);
    const navigate = useNavigate();

    const handle_change = (e) => {
        const { name, value } = e.target;
        setState((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    }
    const handle_reset = async () => {
        var _req = { ...state };
        var flag = true;
        if (CommonHelper.is_empty(_req.old_password)) {
            flag = false;
        }
        if (CommonHelper.is_empty(_req.password)) {
            flag = false;
        }
        if (CommonHelper.is_empty(_req.re_password)) {
            flag = false;
        }
        if (_req.password !== _req.re_password) {
            flag = false;
        }
        if (flag) {
            setShowLoading(true);
            try {
                const res = await Authentication.handler_changepass(_req);

                setShowLoading(false);
                console.log("res", res);

                if (res.code === 200) {
                    setAlertData((prev) => ({
                        ...prev,
                        is_show: true,
                        variant: "success",
                        title: "Thông báo",
                        content: "Đổi mật khẩu thành công!!!"
                    }));

                    setTimeout(async () => {
                        try {
                            const logoutRes = await Authentication.logout();
                            if (logoutRes.code === 506) {
                                console.log("Logging out and navigating to /nhan-vien/dang-nhap");
                                navigate("/nhan-vien/dang-nhap");
                            }
                        } catch (logoutError) {
                            console.error("Error during logout:", logoutError);
                        }
                    }, 2000);
                } else if (res.code === 704) {
                    setAlertData((prev) => ({
                        ...prev,
                        is_show: true,
                        variant: "danger",
                        title: "Lỗi",
                        content: "Tài khoản không tồn tại!"
                    }));
                }
            } catch (error) {
                setShowLoading(false);
                console.error("Error during password change:", error);
                setAlertData((prev) => ({
                    ...prev,
                    is_show: true,
                    variant: "danger",
                    title: "Lỗi",
                    content: "Có lỗi xảy ra. Vui lòng thử lại!"
                }));
            }
        } else {
            setAlertData((prev) => ({
                ...prev,
                is_show: true,
                variant: "danger",
                title: "Lỗi",
                content: "Vui lòng nhập đầy đủ thông tin!"
            }));
        }
    }
    const handle_enter = (e) => {
        //console.log(e);
        if (e.key === "Enter") {
            handle_reset();
        }
    }
    return (
        <div className='bg-modal-gate bg-employee-login'>
            <div style={{ position: "absolute", top: "20px", left: "20px" }}>
                <a className="navbar-brand" href="https://fubonins.com.vn/" target="_blank">
                    <img src={fbBanner} />
                </a>
            </div>
            <div className='login-panel' >
                <div className='text-center'><h1>ĐỔI MẬT KHẨU</h1></div>
                <div >
                    <div className='mt15px'>
                        <div className="form-floating mt15px">
                            <input name="old_password" type="password" placeholder="Mật khẩu cũ" className='form-control' value={state.old_password} onChange={handle_change} onKeyDown={handle_enter} />
                            <label className='required'>Mật khẩu cũ</label>
                        </div>
                        <div className="form-floating mt15px">
                            <input name="password" type="password" placeholder="Mật khẩu mới" className='form-control' value={state.password} onChange={handle_change} onKeyDown={handle_enter} />
                            <label className='required'>Mật khẩu mới</label>
                        </div>
                        <div className="form-floating mt15px">
                            <input name="re_password" type="password" placeholder="Xác nhận mật khẩu mới" className='form-control' value={state.re_password} onChange={handle_change} onKeyDown={handle_enter} />
                            <label className='required'>Xác nhận mật khẩu mới</label>
                        </div>
                    </div>
                    <div className='mt15px'>
                        <button className='btn btn-primary' style={{ color: "#fff", height: '50px' }} onClick={handle_reset}>
                            ĐỔI MẬT KHẨU
                        </button>
                    </div>
                    <div className='mt10px'>
                        <div className='container'>
                            {/* <div className='float-start'><a href='/quen-mat-khau'>Quên mật khẩu?</a></div> */}
                            <div className='float-end'><Link to='/fubon/ctpl-moto'>Quay lại</Link></div>
                            <div className='clearfix'></div>
                        </div>
                    </div>
                </div>
            </div>
            {showLoading && <Loading />}
            <AlertBox data={alertData} onChange={(e) => { setAlertData((prev) => ({ ...prev, is_show: e })) }} />
        </div>
    )
}

export default EmployeeReset