/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from 'react'
import { redirect, useLoaderData } from 'react-router-dom';
import CommonHelper from '../../../helpers/common';
import Authentication from '../../../api/authApi';
import bgLogin from "../../../assets/images/bg-login.jpg";
import TextInput from '../../../components/text-input';
import Loading from '../../../components/loading';
import AlertBox from '../../../components/alert';
import fbBanner from '../../../assets/images/logo.svg';
import { Link } from 'react-router-dom';

const initial = {
    "user_name": "",
    "password": ""
};

const initialAlert = {
    is_show: false,
    title: "",
    content: "",
    variant: "danger"
};

function CMSLogin() {

    const [state, setState] = useState(initial);
    const [alertData, setAlertData] = useState(initialAlert);
    const [showLoading, setShowLoading] = useState(false);
    const handle_change = (e) => {
        const { name, value } = e.target;
        setState((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    }
    const handle_login = () => {
        var _req = { ...state };
        var flag = true;
        if (CommonHelper.is_empty(_req.user_name)) {
            flag = false;
        }
        if (CommonHelper.is_empty(_req.password)) {
            flag = false;
        }
        if (flag) {
            setShowLoading(true);
            var response = Authentication.cms_login(_req);
            response.then((res) => {
                setShowLoading(false);
                console.log(res);
                var _q = window.location.search;
                if (CommonHelper.is_not_empty(_q)) {
                    _q = _q.substring(5);
                } else {
                    _q = "/cms/danh-sach-cau-hoi";
                }
                if (res.code === 208) {
                    window.location.href = _q;
                } else if (res.code === 700) {
                    setAlertData((prev) => ({
                        ...prev,
                        is_show: true,
                        variant: "danger",
                        title: "Lỗi",
                        content: "Tài khoản không tồn tại!"
                    }))
                } else if (res.code === 710) {
                    setAlertData((prev) => ({
                        ...prev,
                        is_show: true,
                        variant: "danger",
                        title: "Lỗi",
                        content: "Sai mật khẩu. Vui lòng kiểm tra lại!"
                    }))
                }
            })
        } else {
            //console.log(flag);
            setAlertData((prev) => ({
                ...prev,
                is_show: true,
                variant: "danger",
                title: "Lỗi",
                content: "Vui lòng nhập đầy đủ thông tin đăng nhập!"
            }))
        }
    }
    const handle_enter = (e) => {
        //console.log(e);
        if (e.key === "Enter") {
            handle_login();
        }
    }
    return (
        <div className='bg-modal-gate bg-cms-login'>
            <div style={{ position: "absolute", top: "20px", left: "20px" }}>
                <a className="navbar-brand" href="https://fubonins.com.vn/" target="_blank">
                    <img src={fbBanner} />
                </a>
            </div>
            <div className='login-panel' >
                <div className='text-center'><h1>ĐĂNG NHẬP</h1></div>
                <div >
                    <div className='mt15px'>
                        <div className="form-floating">
                            <TextInput name="user_name" placeholder="Tên đăng nhập" value={state.user_name} onChange={handle_change} onKeyDown={handle_enter} />
                            <label className='required'>Tên đăng nhập</label>
                        </div>
                        <div className="form-floating mt15px">
                            <input name="password" type="password" placeholder="Mật khẩu" className='form-control' value={state.password} onChange={handle_change} onKeyDown={handle_enter} />
                            <label className='required'>Mật khẩu</label>
                        </div>
                    </div>
                    <div className='mt15px'>
                        <button className='btn btn-info btn-login' style={{ color: "#fff", height: "60px", width: "200px", borderRadius: '0px' }} onClick={handle_login}>ĐĂNG NHẬP</button>
                    </div>
                </div>
            </div>
            {showLoading && <Loading />}
            <AlertBox data={alertData} onChange={(e) => { setAlertData((prev) => ({ ...prev, is_show: e })) }} />
        </div>
    )
}

export default CMSLogin;