import React, { useEffect, useState } from 'react';
import PetIntroduce from './pet-introduce';
import PetGuide from './pet-guide';
import PetQA from './pet-qa';
import PetConverage from './pet-coverage';
import ScrollToTop from './scroll-to-top';
import PetSvg from './pet-svg';
import PetBenefits from './pet-benefits';
import PetPopup from './pet-popup';
import petApi from '../../../api/petApi';
import "./index.css";
import { useTranslation } from '../util';
import FlagVietNam from "../../../assets/images/flag-vn.webp";
import FlagEnglish from "../../../assets/images/flag-uk.webp";
import FlagTaiwan from "../../../assets/images/flag-china.webp";

const PetLandingPage = () => {
    const [petPackages, setPetPackages] = useState([]);
    const [{ showPetPopup }, setShowPetPopup] = useState({
        showPetPopup: true,
    });
    const { t, toggleLanguage } = useTranslation();

    useEffect(() => {
        get_pet_packages();
    }, [])

    const get_pet_packages = async () => {
        try {
            const _data = {
                "type": "",
                "from_date": "",
                "sob": "",
            }
            var res = await petApi.get_pet_premium(_data);
            if (res.code === 200) {
                setPetPackages(res.data);
            }
        } catch (error) {
            console.error("Error fetching user data:", error);
        }
    };

    return (
        <>
            {/* <div className="d-flex justify-content-end mb-3">
                <img
                    src={FlagVietNam}
                    alt='Ngôn ngữ tiếng Việt'
                    style={{ 
                        width: '40px', 
                        height: '30px', 
                        marginRight: '4px', 
                        cursor: 'pointer' 
                    }}
                    onClick={() => toggleLanguage('vi')}
                />
                <img
                    src={FlagEnglish}
                    alt='Ngôn ngữ tiếng Anh'
                    style={{ 
                        width: '40px', 
                        height: '30px', 
                        marginRight: '4px', 
                        cursor: 'pointer' 
                    }}
                    onClick={() => toggleLanguage('en')}
                />
                <img
                    src={FlagTaiwan}
                    alt='Ngôn ngữ tiếng Đài'
                    style={{ 
                        width: '40px', 
                        height: '30px', 
                        marginRight: '4px', 
                        cursor: 'pointer' 
                    }}
                    onClick={() => toggleLanguage('zh')}
                />
            </div> */}
            <PetIntroduce
                t={t}
                toggleLanguage={toggleLanguage}
            />
            <PetBenefits
                list={petPackages}
                t={t}
                toggleLanguage={toggleLanguage}
            />
            <PetGuide
                t={t}
                toggleLanguage={toggleLanguage}
            />
            <PetConverage
                t={t}
                toggleLanguage={toggleLanguage}
            />
            <PetQA
                t={t}
                toggleLanguage={toggleLanguage}
            />
            <PetSvg />
            <ScrollToTop />
            {showPetPopup && (<PetPopup onClose={() => { setShowPetPopup((prev) => ({ ...prev, showPetPopup: false })) }} />)}
        </>
    );
};

export default PetLandingPage;