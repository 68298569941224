import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FaCheck } from "react-icons/fa";
import { ImCross } from "react-icons/im";

const initial = {
    ques_no: "0",
    ques: "",
    answer: "",
    result: "",
    list: [
        { key: "A", answer: "BMBH" },
        { key: "B", answer: "Người thụ hưởng" },
        { key: "C", answer: "A, B đúng" },
        { key: "D", answer: "A, B sai" }
    ]
};

function QuestionNaire(props) {
    const { data, begin, finish, list_wrong, onSelect, id, updateButtonState, ...other } = props;
    const [state, setState] = useState(initial);
    const [full_time, setFullTime] = useState(finish);
    const [score, setScore] = useState(other.score);
    
    useEffect(() => {
        setState(data);
        setFullTime(finish);
        setScore(other.score);
    }, [data, finish, other.score]);

    const handleChange = (e) => {
        const { value } = e.target;
        setState((prev) => ({ ...prev, result: value }));
        onSelect(value);
    };

    const [selectedAnswer, setSelectedAnswer] = useState(null);

    const handleSelect = (answer) => {
        setSelectedAnswer(answer);
        onSelect(answer, id);
        updateButtonState(id);
        console.log("answer", answer)
        console.log("props", props)
    };

    return (
        <div className='container mt-3' id={"btn" + props.id}>
            <div className='row'>
                <div className='col-sm-12'>
                    <h6>{"Câu hỏi " + (id + 1) + " :"}</h6>
                    <h6 style={{ color: 'rgb(110 92 92)' }}>{data.ques}</h6>
                    <h6 style={{ color: 'rgb(110 92 92)', fontStyle: 'italic' }}>(Chọn 1 phương án đúng)</h6>
                    <div className="form-check">
                        {data.list.map((item, index) => (
                            <div className="form-check" key={id} style={{ paddingLeft: '0' }}>
                                <input
                                    className="form-check-input"
                                    type="radio"
                                    id={`${data.ques_no}-${item.key}`}
                                    name={`question-${data.ques_no}`}
                                    value={item.key}
                                    checked={selectedAnswer === item.key}
                                    onChange={() => handleSelect(item.key)}
                                    disabled={finish}
                                    style={{cursor: 'pointer'}}
                                />
                                <label
                                    className={"form-check-label " + (full_time && item.key === state.answer ? "text-success" : "")}
                                    htmlFor={`${data.ques_no}-${item.key}`}>
                                    <b>{item.key}</b> - {item.answer}
                                    {(full_time || finish) && (
                                        item.key === data.answer ? (
                                            <FaCheck style={{ fontSize: '16px', color: 'green', display: 'inline', marginLeft: '10px' }} />
                                        ) : (
                                            selectedAnswer === item.key && <ImCross style={{ fontSize: '16px', color: 'red', display: 'inline', marginLeft: '10px' }} />
                                        )
                                    )}
                                </label>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}

QuestionNaire.propTypes = {
    data: PropTypes.object.isRequired,
    finish: PropTypes.bool.isRequired,
    onSelect: PropTypes.func.isRequired,
    id: PropTypes.string.isRequired,
    other: PropTypes.object
};

export default QuestionNaire;
