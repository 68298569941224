import React, { useState, useEffect } from 'react'
import * as FaIcons from 'react-icons/fa'
import CommonHelper from '../../helpers/common';
import { useLoaderData, useMatches } from "react-router-dom";
import addMonths from "date-fns/addMonths";
import addDays from "date-fns/addDays";
import addYears from "date-fns/addYears";
import TextInput from '../../components/text-input';
import AutoComplete from '../../components/auto-complete';
import DateInput from '../../components/date-input';
import ShortDateInput from '../../components/short-date-input';
import Loading from '../../components/loading';
import AlertBox from '../../components/alert';
import NumberInput from '../../components/number-input';

const flagLoading = true;

export async function loader({ params }) {
    var data = {};
    if (params.id !== undefined && CommonHelper.is_not_empty(params.id)) {
        var orderNo = params.id
        data = { ...data, orderNo };
    }
    return data;
}


function PtiInsurance() {
    return (
        <div>PTI</div>
    )
}

export default PtiInsurance