/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import trainingApi from "../../../../api/trainingApi";
import AlertBox from "../../../../components/alert";
import { FaCheckCircle } from "react-icons/fa";

const initialAlert = {
    is_show: false,
    title: "",
    content: "",
    variant: "danger"
};

const TopicsList = () => {
    const [alertData, setAlertData] = useState(initialAlert);
    const [listTopic, setListTopic] = useState([]);

    //#region HANDLE
    useEffect(() => {
        fetchTopics();
    }, []);

    const fetchTopics = async () => {
        try {
            const response = await trainingApi.get_topics();
            if (response.code === 200) {
                setListTopic(response.data);
            } else {
                setAlertData((prev) => ({
                    ...prev,
                    title: "Lỗi",
                    content: "Tải dữ liệu chủ đề thi không thành công. ",
                    is_show: true,
                    variant: "danger"
                }));
            }
        } catch (error) {
            setAlertData((prev) => ({
                ...prev,
                title: "Lỗi",
                content: "Oops... Đã có lỗi xảy ra, vui lòng kiểm tra lại!",
                is_show: true,
                variant: "danger"
            }));
        }
    }
    //#endregion

    return (
        <>
            <div className="container" style={{maxWidth: '700px'}}>
                <div className="row row-frm">
                    <h1 className="text-center">Chủ đề câu hỏi</h1>
                    <div className="table-container" style={{ overflowY: 'auto', maxHeight: '600px' }}>
                        <table className="table table-striped table-bordered table-hover">
                            <thead className="center-vertically" style={{ backgroundColor: '#3498db', position: 'sticky', top: '-2px' }}>
                                <tr style={{ verticalAlign: 'middle', color: 'white' }}>
                                    <th style={{ width: '50%' }}>Chủ đề câu hỏi</th>
                                    {/* <th style={{ width: '15%' }}>Thi theo chủ đề</th> */}
                                </tr>
                            </thead>
                            <tbody className="center-vertically">
                                {listTopic.length === 0 ? (
                                    <tr>
                                        <td colSpan="4" style={{ textAlign: 'center' }}>Không có dữ liệu phù hợp</td>
                                    </tr>
                                ) :
                                    (
                                        listTopic.map((topic, index) => (
                                            <>
                                                <tr key={index} style={{ verticalAlign: 'middle' }}>
                                                    <td style={{ textAlign: 'left', fontWeight: '500' }}>{topic.id + ". " + topic.topicName}</td>
                                                    {/* <td style={{
                                                        backgroundColor: topic.isTest ? 'lightgreen' : '',
                                                        fontWeight: topic.isTest ? 'bold' : '',
                                                        color: topic.isTest ? 'green' : ''
                                                    }}>
                                                        {topic.isTest ? <FaCheckCircle style={{ marginRight: '5px' }} /> : ''}
                                                        {topic.isTest ? 'Kích hoạt' : 'Chưa kích hoạt'}
                                                    </td> */}
                                                </tr>
                                            </>
                                        ))
                                    )
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
                <AlertBox data={alertData} onChange={(e) => { setAlertData((prev) => ({ ...prev, is_show: e })) }} />
            </div>
        </>
    );
}

export default TopicsList;
