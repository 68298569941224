const Header = ({ title, setTitle }) => {
  return (
    <>
      <div style={{ marginBottom: '24px' }}>
        <div className="border-top" style={{ borderTopWidth: '8px', borderTopColor: 'rgb(30, 155, 240)' }}>
          <input
            defaultValue={title}
            onBlur={(e) => setTitle(e.target.value)}
            placeholder="Nhập câu hỏi bộ đề bảo hiểm"
            name="title"
            style={{
              marginBottom: '8px',
              width: '100%',
              padding: '8px',
              border: '1px solid rgba(0, 0, 0, 0.23)',
              borderRadius: '4px',
              fontSize: '1.6rem',
              outline: 'none',
              textAlign: 'center',
              fontWeight: '700'
            }}
            disabled
          />
        </div>
      </div>
    </>
  );
};

export default Header;
