import React, { useState, useEffect } from "react";
import { Modal, Form, Button, Row, Col } from "react-bootstrap";
import { FaSave } from "react-icons/fa";
import { GrPowerReset } from "react-icons/gr";
import trainingApi from "../../../../api/trainingApi";
import AlertBox from "../../../../components/alert";
import CommonHelper from "../../../../helpers/common";

const initialAlert = {
  is_show: false,
  title: "",
  content: "",
  variant: "danger"
};

function EditAccount({ showModal, handleClose, selectedAccount, updateAccountList }) {
  const [accountData, setAccountData] = useState({
    id: "",
    fullName: "",
    phone: "",
    email: "",
    identity: "",
    userName: "",
    password: "",
    isAdmin: false,
    isDeleted: false
  });
  const [alertData, setAlertData] = useState(initialAlert);

  useEffect(() => {
    if (selectedAccount) {
      setAccountData({
        id: selectedAccount.id || "",
        fullName: selectedAccount.fullName || "",
        phone: selectedAccount.phone || "",
        email: selectedAccount.email || "",
        identity: selectedAccount.identity || "",
        userName: selectedAccount.userName || "",
        password: selectedAccount.password || "",
        isAdmin: selectedAccount.isAdmin || false,
        isDeleted: selectedAccount.isDeleted || false
      });
    }
  }, [selectedAccount]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setAccountData((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value
    }));
  };

  const handleReset = () => {
    setAccountData({
      fullName: "",
      phone: "",
      email: "",
      identity: "",
      userName: "",
      password: "",
      isAdmin: false,
      isDeleted: false
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if(!accountData.id) {
      setAlertData((prev) => ({
        ...prev,
        title: "Lỗi",
        content: "Oops... Không tìm thấy tài khoản cần sửa!",
        is_show: true,
        variant: "danger"
      }));
      return;
    }
    try {
      const _data = JSON.stringify(accountData);
      console.log("_data", _data)
      const response = await trainingApi.put_account_exam(_data);
      if (response.code === 200) {
        setAlertData((prev) => ({
          ...prev,
          title: "Thông báo",
          content: "Lưu cập nhật tài khoản thành công.",
          is_show: true,
          variant: "success"
        }));
      } else {
        setAlertData((prev) => ({
          ...prev,
          title: "Lỗi",
          content: "Lưu cập nhật tài khoản không thành công.",
          is_show: true,
          variant: "danger"
        }));
      }
    } catch (error) {
      setAlertData((prev) => ({
        ...prev,
        title: "Lỗi",
        content: "Oops... Đã có lỗi xảy ra, vui lòng kiểm tra lại!",
        is_show: true,
        variant: "danger"
      }));
    }
    updateAccountList(accountData);
    handleClose();
  };

  return (
    <>
      <Modal
        show={showModal}
        dialogClassName="custom-modal"
        style={{ marginTop: "100px" }}
        backdrop="static"
        onHide={handleClose}
        size="md"
      >
        <Modal.Header closeButton>
          <Modal.Title as="h3">Cập nhật tài khoản thi Đại lý Fubon</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group>
              <Row>
                <Col md={6}>
                  <Form.Label>Họ và tên</Form.Label>
                  <Form.Control type="text" name="fullName" autoFocus value={accountData.fullName} onChange={handleChange} />
                </Col>
                <Col md={6}>
                  <Form.Label>Số điện thoại</Form.Label>
                  <Form.Control type="text" name="phone" value={accountData.phone} onChange={handleChange} />
                </Col>
              </Row>
            </Form.Group>
            <Form.Group>
              <Form.Label>Email</Form.Label>
              <Form.Control type="text" name="email" value={accountData.email} onChange={handleChange} />
            </Form.Group>
            <Form.Group>
              <Form.Label>Số CMTND</Form.Label>
              <Form.Control type="text" name="identity" value={accountData.identity} onChange={handleChange} />
            </Form.Group>
            <Form.Group>
              <Form.Label>Tên tài khoản thi</Form.Label>
              <Form.Control type="text" name="userName" value={accountData.userName} onChange={handleChange} />
            </Form.Group>
            <Form.Group>
              <Form.Label>Mật khẩu</Form.Label>
              <Form.Control type="password" name="password" value={accountData.password} onChange={handleChange} />
            </Form.Group>
            <Form.Group>
              <Row>
                <Col md={6}>
                  <Form.Label>Tài khoản Admin?</Form.Label>
                  <Form.Check checked={accountData.isAdmin} name="isAdmin" type="switch" onChange={handleChange} />
                </Col>
                <Col md={6}>
                  <Form.Label>Khóa tài khoản?</Form.Label>
                  <Form.Check checked={accountData.isDeleted} name="isDeleted" type="switch" onChange={handleChange} />
                </Col>
              </Row>
            </Form.Group>
            <Form.Group>
              <hr />
              <Form.Label>Ngày tạo tài khoản: <span style={{fontWeight: '500'}}>
                  {selectedAccount.createdDate && CommonHelper.formatDateExam(selectedAccount.createdDate)}
                </span>
              </Form.Label>&emsp;
              <Form.Label>Ngày cập nhật: <span style={{fontWeight: '500'}}>
                {selectedAccount.modifiedDate && CommonHelper.formatDateExam(selectedAccount.modifiedDate)}
                </span>
              </Form.Label>
              <Form.Label>Đăng nhập gần đây: <span style={{fontWeight: '500'}}>
                {selectedAccount.lastLoginDate && CommonHelper.formatDateExam(selectedAccount.lastLoginDate)}
                </span>
              </Form.Label>
            </Form.Group>
            <Modal.Footer>
              <Button variant="warning" style={{ marginRight: "4px" }} onClick={handleReset}>
                <span style={{ display: "flex", alignItems: "center" }}>
                  <GrPowerReset style={{ marginRight: "4px" }} />Reset
                </span>
              </Button>
              <Button variant="primary" type="submit">
                <span style={{ display: "flex", alignItems: "center" }}>
                  <FaSave style={{ marginRight: "4px" }} />Lưu lại
                </span>
              </Button>
            </Modal.Footer>
          </Form>
        </Modal.Body>
      </Modal>
      <AlertBox data={alertData} onChange={(e) => { setAlertData((prev) => ({ ...prev, is_show: e })) }} />
    </>
  );
}

export default EditAccount;
