import React, { useRef, useState } from 'react'
import CommonHelper from '../../../helpers/common';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Navigation } from 'swiper/modules';
import { FaCheckCircle } from "react-icons/fa";
import PetPackageDetail from './packages-detail';
import { FaCat, FaDog } from "react-icons/fa";

function Packages(props) {
    const { 
        onSelect, 
        list, 
        pkg_selected, 
        pet_option, 
        name, 
        kind_of_pet, 
        ...other 
    } = props;
    const [showModal, setShowModal] = useState(false);
    const [selectedPackage, setSelectedPackage] = useState(null);

    const handle_select = (e) => {
        onSelect(e);
    };

    return (
        <>
            <div className='d-block'>
                <div className='d-flex'>
                    <div className='w-50'>
                        <div 
                            className="plan-description mat-card" 
                            style={{ marginTop: "18px", border: 'none' }}
                        >
                            <h3 style={{ color: 'black' }}>
                                Phạm vi Bảo hiểm thú cưng
                            </h3>
                            <div 
                                className='body' 
                                style={{ border: 'none' }}
                            >
                                <p className='p-location'>
                                    <div className='row'>
                                        <div className='col-md-3'>
                                            <div 
                                                className='row' 
                                                style={{ marginTop: '120px' }}
                                            >
                                                <p 
                                                    className='p-location' 
                                                    style={{ border: 'none' }}
                                                >
                                                    <FaCheckCircle 
                                                        style={{ 
                                                            fontSize: '20px', 
                                                            marginRight: '8px', 
                                                            color: '#20c997' 
                                                        }} 
                                                    />
                                                    Bảo hiểm chi phí y tế cho thú cưng (Mức khấu trừ 10%)
                                                </p>
                                            </div>
                                        </div>
                                        <div className='col-md-4'>
                                            <div className='row'>
                                                <p className='p-location'>
                                                    1. Chi phí điều trị ngoại trú (Giới hạn: điều trị các bệnh liên quan đến da & lông: tối đa 2 lần)
                                                </p>
                                            </div>
                                            <div className='row'>
                                                <p 
                                                    className='p-location' 
                                                    style={{ 
                                                        height: '104px', 
                                                        paddingTop: '32px' 
                                                    }}
                                                >
                                                    2. Chi phí điều trị nội trú
                                                </p>
                                            </div>
                                            <div className='row'>
                                                <p 
                                                    className='p-location'
                                                    style={{ 
                                                        border: 'none', 
                                                        paddingBottom: '0px', 
                                                        marginBottom: '0px', 
                                                        paddingTop: '32px' 
                                                    }}
                                                >
                                                    3. Chi phí phẫu thuật
                                                </p>
                                            </div>
                                        </div>
                                        <div className='col-md-5'>
                                            <div className='row'>
                                                <p 
                                                    className='p-location' 
                                                    style={{ height: '49px' }}
                                                >
                                                    Tối đa mỗi lần
                                                </p>
                                            </div>
                                            <div className='row'>
                                                <p 
                                                    className='p-location' 
                                                    style={{ height: '48px' }}
                                                >
                                                    Tổng mức bồi thường tối đa
                                                </p>
                                            </div>
                                            <div className='row'>
                                                <p 
                                                    className='p-location' 
                                                    style={{ height: '47px' }}
                                                >
                                                    Tối đa mỗi lần
                                                </p>
                                            </div>
                                            <div className='row'>
                                                <p 
                                                    className='p-location' 
                                                    style={{ height: '47px' }}
                                                >
                                                    Tổng mức bồi thường tối đa
                                                </p>
                                            </div>
                                            <div className='row'>
                                                <p className='p-location'>
                                                    Tối đa mỗi lần
                                                </p>
                                            </div>
                                            <div className='row'>
                                                <p 
                                                    className='p-location' 
                                                    style={{ 
                                                        border: 'none', 
                                                        paddingBottom: '0px', 
                                                        marginBottom: '0px' 
                                                    }}
                                                >
                                                    Tổng mức bồi thường tối đa
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </p>
                                <p className='p-location'>
                                    <div className='row'>
                                        <div className='col-md-7'>
                                            <p 
                                                className='p-death' 
                                                style={{ height: '59px' }}
                                            >
                                                <FaCheckCircle 
                                                    style={{ 
                                                        fontSize: '20px', 
                                                        marginRight: '8px', 
                                                        color: '#20c997' 
                                                    }} 
                                                />
                                                Trách nhiệm bồi thường thiệt hại do thú cưng gây ra
                                            </p>
                                            <p 
                                                className='p-full-damage' 
                                                style={{ height: '59px' }}
                                            >
                                                <FaCheckCircle 
                                                    style={{ 
                                                        fontSize: '20px', 
                                                        marginRight: '8px', 
                                                        color: '#20c997' 
                                                    }} 
                                                />
                                                Chi phí quảng cáo tìm kiếm thú cưng bị lạc
                                            </p>
                                            <p 
                                                className='p-medical-fee' 
                                                style={{ height: '90px' }}
                                            >
                                                <FaCheckCircle 
                                                    style={{ 
                                                        fontSize: '20px', 
                                                        marginRight: '8px', 
                                                        color: '#20c997' 
                                                    }} 
                                                />
                                                Chi phí chăm sóc thú cưng trong thời gian người được bảo hiểm nằm viện (tối đa 10 ngày)
                                            </p>
                                            <p 
                                                className='p-allowance' 
                                                style={{ 
                                                    border: 'none', 
                                                    paddingBottom: '0px', 
                                                    marginBottom: '0px' 
                                                }}
                                            >
                                                <FaCheckCircle 
                                                    style={{ 
                                                        fontSize: '20px', 
                                                        marginRight: '8px', 
                                                        color: '#20c997' 
                                                    }} 
                                                />
                                                Chi phí tang lễ cho thú cưng
                                            </p>
                                        </div>
                                        <div className='col-md-5'>
                                            <div className='row'>
                                                <p 
                                                    className='p-location' 
                                                    style={{ height: '59px' }}
                                                >
                                                    Tổng mức bồi thường tối đa
                                                </p>
                                            </div>
                                            <div className='row'>
                                                <p 
                                                    className='p-location' 
                                                    style={{ height: '59px' }}
                                                >
                                                    Tổng mức bồi thường tối đa
                                                </p>
                                            </div>
                                            <div className='row'>
                                                <p 
                                                    className='p-location' 
                                                    style={{ height: '90px' }}
                                                >
                                                    Tổng mức bồi thường tối đa
                                                </p>
                                            </div>
                                            <div className='row'>
                                                <p 
                                                    className='p-location' 
                                                    style={{ 
                                                        border: 'none', 
                                                        paddingBottom: '0px', 
                                                        marginBottom: '0px' 
                                                    }}
                                                >
                                                    Tổng mức bồi thường tối đa
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </p>
                                <p 
                                    className='fs-4 fw-bolder' 
                                    style={{ 
                                        height: "48px", 
                                        color: 'rgb(252, 168, 109)' 
                                    }}
                                >
                                    Phí Bảo hiểm năm đầu tiên
                                    <span className='fs-6'>
                                        (VND/ năm)
                                    </span>
                                    {pet_option.pet_code === "PETPKG01"
                                        ? <FaDog 
                                            style={{ 
                                                fontSize: "40px", 
                                                marginLeft: '20px', 
                                                marginRight: '4px', 
                                                color: 'rgb(252, 168, 109)', 
                                                marginBottom: '10px' 
                                            }} 
                                        />
                                        : <FaCat 
                                            style={{ 
                                                fontSize: "40px", 
                                                marginLeft: '20px', 
                                                marginRight: '4px', 
                                                color: 'rgb(252, 168, 109)', 
                                                marginBottom: '10px' 
                                            }} 
                                        />
                                    }
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className='w-50 px-1'>
                        <Swiper
                            slidesPerView={2}
                            spaceBetween={10}
                            navigation={true}
                            modules={[Navigation]}
                            initialSlide={3}
                            className="mySwiper"
                        >
                            {list.map((item, i) => {
                                if (item.type == kind_of_pet)
                                    return (
                                        <SwiperSlide key={i}>
                                            <div 
                                                key={i}
                                                className={((pkg_selected !== null && pkg_selected.pkg_code) == item.pkg_code ? "active" : '')} 
                                                style={{ 
                                                    flex: "1", 
                                                    display: 'flex', 
                                                    flexDirection: 'column', 
                                                    justifyContent: 'space-between' 
                                                }}
                                            >
                                                <div 
                                                    className={"plan-pricing mat-card " + item.text_color}
                                                    style={{ 
                                                        background: item.bg_color, 
                                                        borderRadius: '16px', 
                                                        display: 'flex', 
                                                        flexDirection: 'column', 
                                                        justifyContent: 'space-between' 
                                                    }}
                                                >
                                                    <div 
                                                        className='head text-center position-relative'
                                                        style={{ 
                                                            backgroundColor: '#564741', 
                                                            color: 'white' 
                                                        }}
                                                    >
                                                        <label className='d-block'>{item.pkg_name}</label>
                                                        {item.pkg_code === "PETRISK02" && <span class="bestsell">
                                                            BEST SELL
                                                        </span>}
                                                    </div>
                                                    <div 
                                                        className='body' 
                                                        style={{ 
                                                            flex: '1', 
                                                            display: 'flex', 
                                                            flexDirection: 'column', 
                                                            justifyContent: 'center' 
                                                        }}
                                                    >
                                                        <p 
                                                            className='text-center' 
                                                            rowspan="2" 
                                                            style={{ 
                                                                height: '49px', 
                                                                verticalAlign: 'center', 
                                                                fontSize: '24px' 
                                                            }}
                                                        >
                                                            {item.smi1.list[0].si_amt ? item.smi1.list[0].si_amt : "-"}
                                                        </p>
                                                        <p 
                                                            className='text-center' 
                                                            rowspan="2" 
                                                            style={{ 
                                                                height: '46px', 
                                                                verticalAlign: 'center', 
                                                                fontSize: '24px' 
                                                            }}
                                                        >
                                                            {item.smi1.list[1].si_amt ? item.smi1.list[1].si_amt : "-"}
                                                        </p>
                                                        <p 
                                                            style={{ 
                                                                height: '47px', 
                                                                fontSize: '24px' 
                                                            }}
                                                        >
                                                            {item.smi2.list[0].si_amt ? item.smi2.list[0].si_amt : "-"}
                                                        </p>
                                                        <p 
                                                            style={{ 
                                                                height: '47px', 
                                                                fontSize: '24px' 
                                                            }}
                                                        >
                                                            {item.smi2.list[1].si_amt ? item.smi2.list[1].si_amt : "-"}
                                                        </p>
                                                        <p 
                                                            style={{ 
                                                                height: '46px', 
                                                                fontSize: '24px', 
                                                                marginTop: '-8px', 
                                                                marginBottom: '16px' 
                                                            }}
                                                        >
                                                            {item.smi3.list[0].si_amt ? item.smi3.list[0].si_amt : "-"}
                                                        </p>
                                                        <p 
                                                            style={{ 
                                                                height: '44px', 
                                                                fontSize: '24px', 
                                                                marginTop: '-16px', 
                                                                marginBottom: '16px' 
                                                            }}
                                                        >
                                                            {item.smi3.list[1].si_amt ? item.smi3.list[1].si_amt : "-"}
                                                        </p>
                                                        <p 
                                                            style={{ 
                                                                height: '52px', 
                                                                fontSize: '24px' 
                                                            }}
                                                        >
                                                            {item.smi4.list[0].si_amt ? item.smi4.list[0].si_amt : "-"}
                                                        </p>
                                                        <p 
                                                            style={{ 
                                                                height: '60px', 
                                                                fontSize: '24px' 
                                                            }}
                                                        >
                                                            {item.smi5.list[0].si_amt ? item.smi5.list[0].si_amt : "-"}
                                                        </p>
                                                        <p 
                                                            style={{ 
                                                                height: '90px', 
                                                                fontSize: '24px' 
                                                            }}
                                                        >
                                                            {item.smi6.list[0].si_amt ? item.smi6.list[0].si_amt : "-"}
                                                        </p>
                                                        <p 
                                                            style={{ 
                                                                height: '44px', 
                                                                fontSize: '24px', 
                                                                marginTop: '-8px', 
                                                                marginBottom: '16px' 
                                                            }}
                                                        >
                                                            {item.smi7.list[0].si_amt ? item.smi7.list[0].si_amt : "-"}
                                                        </p>
                                                        <p 
                                                            style={{ 
                                                                height: "48px", 
                                                                fontSize: '24px', 
                                                                fontWeight: '600', 
                                                                color: 'rgb(252, 168, 109)', 
                                                                marginTop: '-8px', 
                                                                marginBottom: '16px' 
                                                            }}
                                                        >
                                                            {item.premium ? CommonHelper.format_currency_normal(item.premium) : "-"}
                                                        </p>
                                                    </div>
                                                    <div className='foot fw-bolder text-center'>
                                                        <div className="read-more-btn">
                                                            <a 
                                                                className="edu-btn btn-small btn-secondary"
                                                                style={{ backgroundColor: '#564741' }}
                                                                href='javascript:;' 
                                                                onClick={() => { handle_select(item) }} 
                                                            >
                                                                Chọn mua
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                    )
                            })}
                        </Swiper>
                    </div>
                </div>
            </div>
            {showModal && (
                <>
                    <PetPackageDetail
                        isOpen={showModal}
                        selectedPackage={selectedPackage}
                        onClose={() => setShowModal(false)}
                    />
                </>
            )}
        </>
    )
}

export default Packages;