import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import CommonHelper from '../../../../helpers/common'
import TermCondition from '../../vehicle/component/term-condition';

const initial = {
    veh_pol_no: "",
    veh_certificate_no: "",
    veh_sc_code: "MVC",
    veh_business: "N",
    veh_type: "",
    veh_type_name: "",
    veh_seat: "0",
    veh_weight: "0",
    veh_reg_no: "",
    veh_chassis_no: "",
    veh_engine_no: "",
    veh_month: "12",
    veh_fm_dt: null,
    veh_to_dt: null,
    veh_issued_date: null,
    veh_handler_code: "",
    veh_handler_name: "",
    veh_is_pax: false,
    veh_pax: "0",
    veh_customer: "",
    veh_phone: "",
    veh_email: "",
    veh_address: "",
    veh_issue_invoice: false,
    inv_tax: "",
    inv_company_name: "",
    inv_address: "",
    veh_origin_premium: 0,
    veh_tax_ratio: 0,
    veh_tax_amt: 0,
    veh_pax_premium: 0,
    veh_premium: 0,
    veh_si_amt1: 0,
    veh_si_amt2: 0,
    veh_si_amt3: 0,
    veh_si_amt4: 0,
    veh_is_truck: false,
    veh_url: window.location.href,
    veh_order_no: "",
    is_confirm: false,
    status: 1,
};

function MotoConfirmation(props) {
    const { onPay, onCancel, user, data: valueProp, ...other } = props;
    const [state, setState] = useState(initial);
    const [{ showLoading, showModal }, setShow] = useState(
        { showLoading: false, showModal: false }
    );
    const [{ curr_user }, setSelected] = useState({
        curr_user: user,
    })
    useEffect(() => {
        if (valueProp != null) {
            setState(valueProp);
        } else {
            setState(initial);
        }
    }, [valueProp])
    useEffect(() => {
        var curr_user = user ?? { user_type: 1 };
        setState((prev) => ({ ...prev, is_confirm: curr_user.user_type > 1 }));
        setSelected((prev) => ({ ...prev, curr_user }));
    }, [user])
    const handle_check = (e) => {
        const { name, checked } = e.target;
        setState((prevData) => ({
            ...prevData,
            [name]: checked,
        }));
    };
    const handle_payment = (e) => {
        //setValue(e);
        onPay(e);
    }
    const handle_cancel = (e) => {
        //setValue(e);
        onCancel(e);
    }

    return (
        <div className='container' style={{ minHeight: "500px" }}>
            <div className='row'>
                <div className='col-sm-12 d-none'>
                    <h3 className='text-center'>XÁC NHẬN YÊU CẦU BẢO HIỂM</h3>
                </div>
                <div className='col-sm-12 mt15px'>
                    <div className='card'>
                        <div className='card-body'>
                            <h5>THÔNG TIN CHỦ XE</h5>
                            <div className='mb10px'>
                                <div className='float-start' style={{ width: "40%" }}>
                                    <label>Họ và tên:</label>
                                </div>
                                <div className='float-end text-end' style={{ width: "59%" }}>
                                    <strong>{CommonHelper.to_upper(state.veh_customer)}</strong>
                                </div>
                                <div className='clearfix'></div>
                            </div>
                            <div className='mb10px'>
                                <div className='float-start' style={{ width: "40%" }}>
                                    <label>Số điện thoại:</label>
                                </div>
                                <div className='float-end text-end' style={{ width: "59%" }}>
                                    <strong>{state.veh_phone}</strong>
                                </div>
                                <div className='clearfix'></div>
                            </div>
                            <div className='mb10px'>
                                <div className='float-start' style={{ width: "40%" }}>
                                    <label>Email:</label>
                                </div>
                                <div className='float-end text-end' style={{ width: "59%" }}>
                                    <strong>{state.veh_email}</strong>
                                </div>
                                <div className='clearfix'></div>
                            </div>
                            <div className='mb10px'>
                                <div className='float-start' style={{ width: "40%" }}>
                                    <label>Địa chỉ:</label>
                                </div>
                                <div className='float-end text-end' style={{ width: "59%" }}>
                                    <strong>{state.veh_address}</strong>
                                </div>
                                <div className='clearfix'></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-sm-12 mt15px'>
                    <div className='card'>
                        <div className='card-body'>
                            <h5>THÔNG TIN XE THAM GIA BẢO HIỂM</h5>
                            <div className='mb10px d-none'>
                                <div className='float-start' style={{ width: "40%" }}>
                                    <label>Mục đích sử dụng:</label>
                                </div>
                                <div className='float-end text-end' style={{ width: "59%" }}>
                                    <strong>{state.veh_business === "N" ? "Không kinh doanh vận tải" : "Kinh doanh vận tải"}</strong>
                                </div>
                                <div className='clearfix'></div>
                            </div>
                            <div className='mb10px'>
                                <div className='float-start' style={{ width: "40%" }}>
                                    <label>Loại xe:</label>
                                </div>
                                <div className='float-end text-end' style={{ width: "59%" }}>
                                    <strong>{state.veh_type_name}</strong>
                                </div>
                                <div className='clearfix'></div>
                            </div>
                            <div className='mb10px'>
                                <div className='float-start' style={{ width: "40%" }}>
                                    <label>Biển kiểm soát:</label>
                                </div>
                                <div className='float-end text-end' style={{ width: "59%" }}>
                                    <strong>{state.veh_reg_no}</strong>
                                </div>
                                <div className='clearfix'></div>
                            </div>
                            <div className='mb10px'>
                                <div className='float-start' style={{ width: "40%" }}>
                                    <label>Số khung:</label>
                                </div>
                                <div className='float-end text-end' style={{ width: "59%" }}>
                                    <strong>{state.veh_chassis_no}</strong>
                                </div>
                                <div className='clearfix'></div>
                            </div>
                            <div className='mb10px'>
                                <div className='float-start' style={{ width: "40%" }}>
                                    <label>Số máy:</label>
                                </div>
                                <div className='float-end text-end' style={{ width: "59%" }}>
                                    <strong>{state.veh_engine_no}</strong>
                                </div>
                                <div className='clearfix'></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-sm-12 mt15px'>
                    <div className='card'>
                        <div className='card-body'>
                            <h5>THỜI HẠN BẢO HIỂM</h5>
                            <div className='mb10px'>
                                <div className='float-start' style={{ width: "50%" }}>
                                    <label>Hiệu lực bảo hiểm:</label>
                                </div>
                                <div className='float-end text-end' style={{ width: "49%" }}>
                                    <strong>{state.veh_month + " năm"}</strong>
                                </div>
                                <div className='clearfix'></div>
                            </div>
                            <div className='text-end'>
                                <strong>
                                    {CommonHelper.convertToDDMMYYYYHHMM(state.veh_fm_dt) + " - " + CommonHelper.convertToDDMMYYYYHHMM(state.veh_to_dt)}
                                </strong>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-sm-12 mt15px'>
                    <div className='card'>
                        <div className='card-body'>
                            <h5>MỨC TRÁCH NHIỆM VÀ PHÍ BẢO HIỂM</h5>
                            <div className='containter'>
                                <div className='row' style={{ padding: "5px 0", background: "#ededed" }}>
                                    <div className='col-sm-4'>
                                        <div>Phạm vi bảo hiểm</div>
                                    </div>
                                    <div className='col-sm-4 text-center'>
                                        <div>Mức trách nhiệm</div>
                                    </div>
                                    <div className='col-sm-4 text-end'>
                                        <div>Phí bảo hiểm (đã bao gồm VAT)</div>
                                    </div>
                                </div>
                                <div className='row' >
                                    <div className='col-sm-4'>
                                        <div>• Bảo hiểm bắt buộc trách nhiệm dân sự</div>
                                    </div>
                                    <div className='col-sm-4'>
                                        <div>Giới hạn trách nhiệm bảo hiểm đối với người thứ ba</div>
                                        <div>{`- Về người ` + CommonHelper.format_currency_vnd_style(state.veh_si_amt1) + `/người/vụ`}</div>
                                        <div>{`- Về tài sản ` + CommonHelper.format_currency_vnd_style(state.veh_si_amt2) + `/vụ`}</div>
                                    </div>
                                    <div className='col-sm-4 text-end'>
                                        <div>{CommonHelper.format_currency_vnd_style(state.veh_origin_premium + state.veh_tax_amt)}</div>
                                    </div>
                                </div>
                                {state.veh_is_pax && (
                                    <div className='row' >
                                        <div className='col-sm-4'>
                                            <div>• Bảo hiểm tai nạn người ngồi trên xe</div>
                                        </div>
                                        <div className='col-sm-4'>
                                            <div>Giới hạn trách nhiệm bảo hiểm đối với người ngồi trên xe</div>
                                            <div>{`- Về người ` + CommonHelper.format_currency_vnd_style(state.veh_si_amt3) + `/người/vụ`}</div>
                                        </div>
                                        <div className='col-sm-4 text-end'>
                                            <div>{CommonHelper.format_currency_vnd_style(state.veh_pax_premium)}</div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-sm-12 mt15px'>
                    <div className='alert alert-info'>
                        <div className='row'>
                            <div className='col'>
                                <b>Tổng phí bảo hiểm</b> (<i>Đã bao gồm VAT</i>)
                            </div>
                            <div className='col text-end'>
                                <strong>{CommonHelper.format_currency_vnd_style(state.veh_premium)}</strong>
                            </div>
                        </div>
                    </div>
                </div>
                {state.status != 2 && (
                    <>
                        {(curr_user == null || (curr_user != null && curr_user.user_type == 1)) && (
                            <div className='col-sm-12'>
                                <div className='card'>
                                    <div className='card-body'>
                                        <div className='form-check'>
                                            <input type="checkbox" className='form-check-input' value="" id="chk-confirm" name='is_confirm' onChange={handle_check} />
                                            <label className="form-check-label" htmlFor="chk-confirm">
                                                Tôi cam kết các thông tin trên đều trung thực và chính xác, đồng thời tôi cam kết đã đọc, hiểu và đồng ý với <a onClick={() => { setShow((prev) => ({ ...prev, showModal: true })) }} style={{ color: "var(--bs-link-color)", cursor: "pointer" }}>Quy định và Quy tắc điều khoản</a> của FUBON cho chương trình Bảo hiểm này.
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}                        
                        <div className='col-sm-12 mt15px'>
                            <div className='container'>
                                <div className='row'>
                                    <div className='col' style={{ paddingLeft: "0" }}>
                                        <button type='button' className='btn btn-warning' onClick={handle_cancel}>{"< Quay lại"}</button>
                                    </div>
                                    <div className='col text-end' style={{ paddingRight: "0" }}>
                                        <button type='button' disabled={!state.is_confirm} className='btn btn-info' onClick={handle_payment}>{"Thanh toán"}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )}

            </div>
            <TermCondition show={showModal} onClose={(e) => { setShow((prev) => ({ ...prev, showModal: e })) }} />
        </div>
    )
}

export default MotoConfirmation

MotoConfirmation.propTypes = {
    onPay: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
}