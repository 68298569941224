import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Popup from '../../../components/popup'


const TermContent = () => {
    return (
        <>
            <p>1.	Tôi đã hiểu rõ quyền và nghĩa vụ của Chủ thể dữ liệu theo quy định tại Nghị định 13/2023/NĐ-CP quy định về bảo vệ Dữ liệu cá nhân.</p>
            <p>2.	Tôi đồng ý cung cấp và đồng ý cho phép FUBON xử lý dữ liệu cá nhân mà tôi/Người được bảo hiểm cung cấp tại trang web này cho mục đích: <br />
                -	Giao kết Hợp đồng bảo hiểm (HĐBH), phát hành Giấy chứng nhận bảo hiểm (GCNBH), bao gồm thực hiện hoạt động tái bảo hiểm. <br />
                -	Giải quyết khiếu nại bồi thường, trả tiền bảo hiểm. <br />
                -	Cung cấp cho Kiểm toán độc lập thực hiện kiểm toán theo quy định pháp luật. <br />
                -	Cung cấp cho Công ty mẹ nhằm mục đích thống kê và báo cáo, chia sẻ cho các Đơn vị trong cùng Tập đoàn Fubon cho các mục đích liên quan tới nghiệp vụ mà không làm phương hại đến lợi ích chính đáng của Tôi/Người được bảo hiểm. <br />
                -	Và cho các mục đích hợp pháp khác theo quy định của pháp luật. <br /></p>
        </>
    )
}
function TermCondition(props) {
    const { onClose, show, ...other } = props;
    const handle_close = () => {
        onClose(false);
    }
    return (
        <Popup show={show} title="Điều kiện và điều khoản" content={<TermContent />} onClose={handle_close} />
    )
}

export default TermCondition

TermCondition.propTypes = {
    onClose: PropTypes.func.isRequired,
}