import api from "./api";
import axios from "axios";
import CommonHelper from "../helpers/common";

const petApi = {
    get_order: async (id) => {
        var callApi = await api.get(
            "pet/" + id
        );
        console.log(callApi);
        if (callApi.code === 401) {
            console.log(callApi);
        }
        return callApi;
    },
    get_sob: async (id) => {
        var callApi = await api.get(
            "online/get_sob?code=" + id
        );
        if (callApi.code === 401) {
            console.log(callApi);
        }
        return callApi;
    },
    get_pet_proposal: async (id) => {
        var token = await CommonHelper.get_token();
        var session_id = CommonHelper.get_session_id();
        axios({
            url: process.env.REACT_APP_API_ENDPOINT + "order/get_pet_proposal?order_no=" + id + "&session_id=" + session_id,
            method: 'GET',
            headers: { Authorization: `Bearer ${token}`, },
            responseType: 'blob', // important
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', id + '.pdf');
            document.body.appendChild(link);
            link.click();
        });
    },
    get_pet_policy: async (id) => {
        var token = await CommonHelper.get_token();
        axios({
            url: process.env.REACT_APP_API_ENDPOINT + "order/get_pet_policy?order_no=" + id,
            method: 'GET',
            headers: { Authorization: `Bearer ${token}`, },
            responseType: 'blob', // important
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', id + '.pdf');
            document.body.appendChild(link);
            link.click();
        });
    },
    get_pet_debitnote: async (id) => {
        var token = await CommonHelper.get_token();
        axios({
            url: process.env.REACT_APP_API_ENDPOINT + "order/get_pet_debitnote?order_no=" + id,
            method: 'GET',
            headers: { Authorization: `Bearer ${token}`, },
            responseType: 'blob', // important
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', id + '.pdf');
            document.body.appendChild(link);
            link.click();
        });
    },
    post_pet_order: async (model) => {
        if (model != null) {
            var session_id = CommonHelper.get_session_id();
            model.session_id = session_id;
            var callApi = await api.post(
                // "pet/post_order", data
                "pet/post_order", model
            );
            if (callApi.code === 401) {
                console.log(callApi);
            }
            return callApi;
        }
    },
    post_insured_object: async (model) => {
        if (model != null) {
            var session_id = CommonHelper.get_session_id();
            model.session_id = session_id;
            console.log("model", model);
            var callApi = await api.post(
                "pet/post_insured_object", model
            );
            if (callApi.code === 401) {
                console.log(callApi);
            }
            return callApi;
        }
    },
    issue_pet_policy: async (model) => {
        if (model != null) {
            var session_id = CommonHelper.get_session_id();
            var data = {
                session_id: session_id,
                order_no: model.veh_order_no,
                order_detail: model,
            };
            var callApi = await api.post(
                "order/issue_pet_policy", data
            );
            if (callApi.code === 401) {
                console.log(callApi);
            }
            return callApi;
        }
    },
    delete_insured_pet: async (model) => {
        if (model != null) {
            var session_id = CommonHelper.get_session_id();
            var data = {
                session_id: session_id,
                id: model.id
            };
            var callApi = await api.post(
                "pet/delete_insured_object", data
            );
            if (callApi.code === 401) {
                console.log(callApi);
            }
            return callApi;
        }
    },
    get_insured_pet: async (id = 0) => {
        var callApi = await api.get(
            "pet/get_insured_object/" + id
        );
        if (callApi.code === 401) {
            console.log(callApi);
        }
        return callApi;
    },
    get_pet_payment: async (model) => {
        if (model != null) {
            var session_id = CommonHelper.get_session_id();
            let data = {}
            console.log("CHECK MODAL", model);
            if(model.appraisaled === true) { // check thẩm định
                if(model.res_post_order.lst_pass == null) { // không quay lại sửa thông tin
                    data = {
                        session_id: session_id,
                        allow_payment: model.res_post_order.allow_payment,
                        pending_appraisal: model.res_post_order.pending_appraisal,
                        order_no: model.order_no,
                        return_url: window.location.origin,
                        cancel_url: model.cancel_url,
                        notify_url: process.env.REACT_APP_API_ENDPOINT
                    };
                } else { // quay lại sửa thông tin
                    let totalPremium = 0;
                    let totalFlexNum1 = 0;
                    model.res_post_order.lst_pass.forEach(item => {
                        totalPremium += item.premium;
                        totalFlexNum1 += item.flexNum1;
                    });
                    const grandTotal = totalPremium + totalFlexNum1;
                    const _allow_payment = {
                        order_no: model.order_no,
                        list: model.res_post_order.lst_pass,
                        origin_premium: totalPremium,
                        tax_amt: totalFlexNum1,
                        total_amt: grandTotal,
                    };
                    data = {
                        session_id: session_id,
                        allow_payment: _allow_payment,
                        pending_appraisal: model.res_post_order.pending_appraisal,
                        order_no: model.order_no,
                        return_url: window.location.origin,
                        cancel_url: model.cancel_url,
                        notify_url: process.env.REACT_APP_API_ENDPOINT
                    };
                }
            } else { // không check thẩm định
                if(model.res_post_order.lst_pass == null) { // không qua lại sửa thông tin
                    data = {
                        session_id: session_id,
                        allow_payment: model.res_post_order.allow_payment,
                        order_no: model.order_no,
                        return_url: window.location.origin,
                        cancel_url: model.cancel_url,
                        notify_url: process.env.REACT_APP_API_ENDPOINT
                    };
                } else { // quay lại sửa thông tin
                    let totalPremium = 0;
                    let totalFlexNum1 = 0;
                    model.res_post_order.lst_pass.forEach(item => {
                        totalPremium += item.premium;
                        totalFlexNum1 += item.flexNum1;
                    });
                    const grandTotal = totalPremium + totalFlexNum1;
                    const _allow_payment = {
                        order_no: model.order_no,
                        list: model.res_post_order.lst_pass,
                        origin_premium: totalPremium,
                        tax_amt: totalFlexNum1,
                        total_amt: grandTotal,
                    };
                    data = {
                        session_id: session_id,
                        allow_payment: _allow_payment,
                        order_no: model.order_no,
                        return_url: window.location.origin,
                        cancel_url: model.cancel_url,
                        notify_url: process.env.REACT_APP_API_ENDPOINT
                    };
                }
            }
            
            var callApi = await api.post(
                "pet/get_payment", data
            );
            if (callApi.code === 401) {
                console.log(callApi);
            }
            return callApi;
        }
    },
    upload_pet_proposal: async (model) => {
        var token = await CommonHelper.get_token();
        var session_id = CommonHelper.get_session_id();
        const formData = new FormData();
        formData.append("pet_pp", model.file);
        formData.append("order_no", model.order_no);
        formData.append("session_id", session_id);
        var response = await axios.post(process.env.REACT_APP_API_ENDPOINT + "order/upload_pet_proposal_form", formData, {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "multipart/form-data",
            },
        })
        return response;
    },
    get_pet_payment_check_order: async (id) => {
        var callApi = await api.get(
            "order/get_pet_payment_check_order?order=" + id
        );
        if (callApi.code === 401) {
            console.log(callApi);
        }
        return callApi;
    },
    get_payment_status: async (id) => {
        var session_id = CommonHelper.get_session_id();
        var callApi = await api.get(
            "pet/get_payment_status/" + id
        );
        //console.log("res", callApi);
        if (callApi.code === 401) {
            console.log(callApi);
        }
        return callApi;
    },
    get_aml_online_check: async (model) => {
        if (model != null) {
            /*var session_id = CommonHelper.get_session_id();
            var data = {
                session_id: session_id,
                insured_person: model,
            };*/
            var callApi = await api.get(
                "customer/get_aml_online_check?" + model
            );
            if (callApi.code === 401) {
                console.log(callApi);
            }
            return callApi;
        }
    },
    send_notification_check_aml: async (model) => {
        if (model != null) {
            var callApi = await api.post(
                "order/send_notification_check_aml", model
            );
            if (callApi.code === 401) {
                console.log(callApi);
            }
            return callApi;
        }
    },
    get_pet_order_confirmation: async (req) => {
        var callApi = await api.post(
            "order/get_pet_order_confirmation", req
        );
        if (callApi.code === 401) {
            console.log(callApi);
        }
        return callApi;
    },
    get_pet_excel_template: async () => {
        var token = await CommonHelper.get_token();
        axios({
            url: process.env.REACT_APP_API_ENDPOINT + "order/get_pet_excel_template",
            method: 'GET',
            headers: { Authorization: `Bearer ${token}`, },
            responseType: 'blob', // important
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'pet_template.xlsx');
            document.body.appendChild(link);
            link.click();
        });
    },
    import_insured_pet: async (model) => {
        var token = await CommonHelper.get_token();
        var session_id = CommonHelper.get_session_id();
        // create a new FormData object and append the file to it
        const formData = new FormData();
        formData.append("pet_import", model.file);
        formData.append("default_pkg", model.pkg_code);
        formData.append("veh_business", model.veh_business);
        formData.append("session_id", session_id);
        var response = await axios.post(process.env.REACT_APP_API_ENDPOINT + "order/import_insured_pet", formData, {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "multipart/form-data",
            },
        })
        return response;
    },
    get_pet_appr_pending_appraisal: async (req) => {
        if (CommonHelper.is_not_empty(req)) {
            var callApi = await api.get("pet/pet_appr_pending_appraisal/" + req);
            if (callApi.code === 401) {
                console.log(callApi);
            }
            return callApi;
        }
        return null;
    },
    send_confirmation_to_customer: async (order_no) => {
        if (order_no != null) {
            const session_id = CommonHelper.get_session_id();
            const url_redirect = window.location.origin;
            const data = {
                session_id,
                url_redirect
            };
            const apiUrl = `pet/send_confirmation_to_customer/${order_no}`;
            try {
                const callApi = await api.put(apiUrl, data);
                console.log("SEND MAIL", order_no, callApi);
                if (callApi.code === 401) {
                    console.log(callApi);
                }
                return callApi;
            } catch (error) {
                console.error("Error sending confirmation to customer:", error);
            }
        }
    },    
    get_pet_customer_confirmation: async (req) => {
        if (CommonHelper.is_not_empty(req)) {
            var callApi = await api.get(
                "pet/pet_customer_confirmation/" + req
            );
            if (callApi.code === 401) {
                console.log(callApi);
            }
            return callApi;
        }
        return null;
    },
    get_pet_premium: async (model) => {
        if (model != null) {
            var data = model;
            var callApi = await api.post(
                "pet/get_pet_premium", data)
            if (callApi.code === 500) {
                console.log(callApi);
            }
            return callApi;
        }
    },
    upload_pet_attachment: async (model) => {
        var token = await CommonHelper.get_token();
        var session_id = CommonHelper.get_session_id();
        const formData = new FormData();
        if (model.id != null) {
            formData.append("id", model.id);
        }
        if (model.order_no != null) {
            formData.append("order_no", model.order_no);
        }
        if (model.pet_attachment != null) {
            formData.append("pet_attachment", model.pet_attachment);
        }
        if (session_id != null) {
            formData.append("session_id", session_id);
        }
        var response = await axios.post(process.env.REACT_APP_API_ENDPOINT + "pet/upload_pet_attachment", formData, {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "multipart/form-data",
            },
        })
        return response.data;
    },
    delete_insured_attachment: async (model) => {
        if (model != null) {
            var session_id = CommonHelper.get_session_id();
            var data = {
                session_id: session_id,
                id: model
            };
            var callApi = await api.post(
                "pet/delete_insured_attachment", data
            );
            if (callApi.code === 401) {
                console.log(callApi);
            }
            return callApi;
        }
    },
};

export default petApi;