import React from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import CommonHelper from '../helpers/common';

const popover = ({ title, content }) => (
    <Popover id="popover-basic">
        {CommonHelper.is_not_empty(title) && (<Popover.Header as="h3">{title}</Popover.Header>)}
        <Popover.Body>{content}</Popover.Body>
    </Popover>
);

function Tooltip({ title, content, children }) {
    return (
        <OverlayTrigger overlay={popover({ title, content })}>
            <a href='#'>{children}</a>
        </OverlayTrigger>
    )
}

export default Tooltip