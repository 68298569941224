/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import { useEffect, useState } from "react";
import AddForm from "../add-form/add-form.js";
import Header from "../header";
import { Link } from "react-router-dom";

const formEl = [
  {
    label: "Radio",
    value: "radio",
    title: "Câu nhiều lựa chọn"
  },
];

const ExaminationManager = ({flag, questionEditData, onQuestionUpdate}) => {
  const initVal = formEl[0]?.value;
  const [title, setTitle] = useState("Nhập câu hỏi bộ đề bảo hiểm");
  const [description, setDescription] = useState("");
  const [data, setData] = useState([]);
  const [formData, setFormData] = useState("radio");

  const hasRadioInput = data.some(item => item.type === 'radio');
  const items = data;

  const initForm = () => {
    if (!hasRadioInput) {
      const newData = {
        value: null,
        type: formData,
        required: false,
      };
      setData(prevState => [...prevState, newData]);
      setFormData(initVal);
    }
  };
  initForm();

  const renderElements = (item) => {
    if (item === null || item === undefined) item.type = "radio"
    switch (item.type) {
      case "radio":
        return (
          <AddForm
            item={item}
            handleValue={(id, e) => handleValue(id, e)}
            handleOptionValues={handleOptionValues}
            disableAdd={!hasRadioInput}
            questionEditData={questionEditData}
            flag={flag}
            onQuestionUpdate={onQuestionUpdate}
          />
        );
      default:
        return null;
    }
  };

  const handleValue = (id, e) => {
    const newData = data.map((el) => {
      if (el.id === id) {
        return { ...el, value: e.target.value };
      }
      return el;
    });
    setData(newData);
  };

  const handleOptionValues = (elId, optionId, optionVal) => {
    const newData = data.map((el) => {
      if (el.id === elId) {
        const newOptions = el?.options?.map((opt) => {
          if (opt.id === optionId) {
            return { ...opt, value: optionVal };
          }
          return opt;
        });
        return { ...el, options: newOptions };
      }
      return el;
    });
    setData(newData);
  };

  return (
    <>
      <div className="container-fluid" >
        <div className="row justify-content-md-center">
          <div className="col-md-6">
            <Header
              title={title}
              setTitle={setTitle}
              description={description}
              setDescription={setDescription}
            />
            {data.map(item => renderElements(item))}
          </div>
          {
            flag ? (
              <div className="col-md-1">
                <Link to="/cms/danh-sach-cau-hoi" className="btn btn-primary" style={{display: 'none', borderRadius: '0px'}}>
                  Trở về
                </Link>
              </div>
            ) : (
              <div className="col-md-1">
                <Link to="/cms/danh-sach-cau-hoi" className="btn btn-primary" style={{borderRadius: '0px'}}>
                  Trở về
                </Link>
              </div>
            )
          }
        </div>
      </div>
    </>
  );
};
export default ExaminationManager;
