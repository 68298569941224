import { useState, useEffect } from 'react';
import trainingApi from '../../../../../api/trainingApi';

export const useTopics = () => {
    const [topics, setTopics] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await trainingApi.get_topics();
                console.log("TOPIC", response);
                setTopics(response.data);
            } catch (error) {
                console.error("Error fetching topics data:", error);
            }
        };
        fetchData();
    }, []);

    return topics;
};
