import { useState, useCallback } from 'react';
//#region INIT

import CommonHelper from "../../../helpers/common";

export const initial_person_info = {
    "idx": 0,
    "id": 0,
    "orderNo": "",
    "fullName": "",
    "idCard": "",
    "dob": "",
    "gender": 1,
    "phone": "",
    "email": "",
    "occupation": "",
    "nationality": "",
    "relationship": "0",
    "address": "",
    "isForeigner": false,
    "createdBy": "",
    "createdDate": "",
    "modifiedBy": "",
    "modifiedDate": "",
    "insPackage": "",
    "objPackage": {},
    "action": "new", //[new, edit]
};

export const initial_pet_info = {
    "idx": 0,
    "id": 0,
    "orderNo": "",
    "pet_name": "",
    "chipNo": "",
    "dob": "",
    "gender": "1",
    "kindOfPet": "",
    "typeOfPet": "",
    "relationship": "0",
    "isForeigner": false,
    "insPackage": "",
    "objPackage": {},
    "action": "", //[new, edit]
    "survey_questions": [
        {
            "code": "PET_QUES_1",
            "question": "Thú cưng được bảo hiểm trong vòng 02 tháng qua có từng bị thương và được điều trị y tế, khám hoặc dùng thuốc không?",
            "answer_yn": "",
            "note": "",
            "placeholder": "ví dụ: bệnh nấm mèo, viêm tai điều trị tại bệnh viện X từ 01/2024 – 03/2024 đã khỏi bệnh"
        },
        {
            "code": "PET_QUES_2",
            "question": "Thú cưng được bảo hiểm có bị khuyết tật cơ thể nào sau đây không? Mù, dị tật chân tay, xuất huyết, tiêu chảy, điếc.",
            "answer_yn": "",
            "note": "",
            "placeholder": "ví dụ: mù, chân dính ngón, điếc tai trái…"
        },
        {
            "code": "PET_QUES_3",
            "question": "Nếu thú cưng được bảo hiểm có khám sức khoẻ, thì nội dung khám có dấu hiệu bất thường nào không? bao gồm: ung thư, lệch xương đầu gối, loạn sản xương hông, thoát vị đĩa đệm, bệnh tim, bệnh thận, động kinh, tiểu đường, bệnh tuyến giáp hoặc bệnh lý khác.",
            "answer_yn": "",
            "note": "",
            "placeholder": "ví dụ: khám sức khỏe tổng quát tại bệnh viện X 04/2024 phát hiện bệnh nấm da…"
        },
        {
            "code": "PET_QUES_4",
            "question": "Bạn có mua bảo hiểm cho các thú cưng trên ở doanh nghiệp bảo hiểm khác không?",
            "answer_yn": "",
            "note": "",
            "placeholder": "ví dụ: mua bảo hiểm tại Liberty hiệu lực 01/01/2024 – 31/12/2024"
        }
    ],
    "images": [],
    "list": [],
};

export const initial_insured_pet = [
    {
        "idx": 0,
        "id": 0,
        "orderNo": "",
        "pet_name": "",
        "chipNo": "",
        "dob": "",
        "gender": "",
        "kindOfPet": "",
        "typeOfPet": "",
        "relationship": "0",
        "isForeigner": false,
        "insPackage": "",
        "objPackage": {},
        "action": "", //[new, edit]
        // survey
        "survey_questions": [
            {
                "code": "PET_QUES_1",
                "question": "Thú cưng được bảo hiểm trong vòng 02 tháng qua có từng bị thương và được điều trị y tế, khám hoặc dùng thuốc không?",
                "template": {
                    "answer_yn": "",
                    "note": ""
                }
            },
            {
                "code": "PET_QUES_2",
                "question": "Thú cưng được bảo hiểm có bị khuyết tật cơ thể nào sau đây không? Mù, dị tật chân tay, xuất huyết, tiêu chảy, điếc.",
                "template": {
                    "answer_yn": "",
                    "note": ""
                }
            },
            {
                "code": "PET_QUES_3",
                "question": "Nếu thú cưng được bảo hiểm có khám sức khoẻ, thì nội dung khám có dấu hiệu bất thường nào không? bao gồm: ung thư, lệch xương đầu gối, loạn sản xương hông, thoát vị đĩa đệm, bệnh tim, bệnh thận, động kinh, tiểu đường, bệnh tuyến giáp hoặc bệnh lý khác.",
                "template": {
                    "answer_yn": "",
                    "note": ""
                }
            },
            {
                "code": "PET_QUES_4",
                "question": "Bạn có mua bảo hiểm cho các thú cưng trên ở DNBH khác không?",
                "template": {
                    "answer_yn": "",
                    "note": ""
                }
            }
        ],
    }
];

export const steps = [{ code: 1 }, { code: 2 }, { code: 3 }, { code: 4 }, { code: 5 }];

export const pet_packages = [
    {
        // pkg_id: 0,
        id: 0,
        pkg_code: "PETRISK01",
        pkg_name: "Gói cơ bản",
        premium: 1650000,
        taxRatio: 10,
        taxAmt: 165000,
        type: "PET_DOG",
        smi1: {
            title: "Chi phí điều trị ngoại trú",
            note: "(Giới hạn: điều trị các bệnh liên quan đến da & lông: tối đa 2 lần)",
            list: [
                { description: "Tối đa mỗi lần", si_amt: "500.000" },
                { description: "Tổng mức bồi thường tối đa", si_amt: "1.500.000" }
            ]
        },
        smi2: {
            title: "Chi phí điều trị nội trú",
            note: "",
            list: [
                { description: "Tối đa mỗi lần", si_amt: "3.000.000" },
                { description: "Tổng mức bồi thường tối đa", si_amt: "3.000.000" }
            ]
        },
        smi3: {
            title: "Chi phí phẫu thuật",
            note: "",
            list: [
                { description: "Tối đa mỗi lần", si_amt: "6.000.000" },
                { description: "Tổng mức bồi thường tối đa", si_amt: "6.000.000" }
            ]
        },
        smi4: {
            title: "Trách nhiệm bồi thường thiệt hại do thú cưng gây ra",
            note: "",
            list: [
                { description: "Tổng mức bồi thường tối đa", si_amt: "300.000.000" }
            ]
        },
        smi5: {
            title: "Chi phí quảng cáo tìm kiếm thú cưng bị lạc",
            note: "",
            list: [
                { description: "Tổng mức bồi thường tối đa", si_amt: "1.000.000" }
            ]
        },
        smi6: {
            title: "Chi phí chăm sóc thú cưng trong thời gian NĐBH nằm viện (tối đa 10 ngày)",
            note: "",
            list: [
                { description: "Tổng mức bồi thường tối đa", si_amt: "3.000.000" }
            ]
        },
        smi7: {
            title: "Chi phí tang lễ cho thú cưng",
            note: "",
            list: [
                { description: "Tổng mức bồi thường tối đa", si_amt: "3.000.000" }
            ]
        }
    },
    {
        // pkg_id: 1,
        id: 1,
        pkg_code: "PETRISK02",
        pkg_name: "Gói cao cấp",
        premium: 2500000,
        taxRatio: 10,
        taxAmt: 250000,
        type: "PET_DOG",
        smi1: {
            title: "Chi phí điều trị ngoại trú",
            note: "(Giới hạn: điều trị các bệnh liên quan đến da & lông: tối đa 2 lần)",
            list: [
                { description: "Tối đa mỗi lần", si_amt: "1.000.000" },
                { description: "Tổng mức bồi thường tối đa", si_amt: "3.000.000" }
            ]
        },
        smi2: {
            title: "Chi phí điều trị nội trú",
            note: "",
            list: [
                { description: "Tối đa mỗi lần", si_amt: "6.000.000" },
                { description: "Tổng mức bồi thường tối đa", si_amt: "9.000.000" }
            ]
        },
        smi3: {
            title: "Chi phí phẫu thuật",
            note: "",
            list: [
                { description: "Tối đa mỗi lần", si_amt: "9.000.000" },
                { description: "Tổng mức bồi thường tối đa", si_amt: "13.500.000" }
            ]
        },
        smi4: {
            title: "Trách nhiệm bồi thường thiệt hại do thú cưng gây ra",
            note: "",
            list: [
                { description: "Tổng mức bồi thường tối đa", si_amt: "500.000.000" }
            ]
        },
        smi5: {
            title: "Chi phí quảng cáo tìm kiếm thú cưng bị lạc",
            note: "",
            list: [
                { description: "Tổng mức bồi thường tối đa", si_amt: "2.000.000" }
            ]
        },
        smi6: {
            title: "Chi phí chăm sóc thú cưng trong thời gian NĐBH nằm viện (tối đa 10 ngày)",
            note: "",
            list: [
                { description: "Tổng mức bồi thường tối đa", si_amt: "5.000.000" }
            ]
        },
        smi7: {
            title: "Chi phí tang lễ cho thú cưng",
            note: "",
            list: [
                { description: "Tổng mức bồi thường tối đa", si_amt: "4.000.000" }
            ]
        }
    },
    {
        // pkg_id: 2,
        id: 2,
        pkg_code: "PETRISK01",
        pkg_name: "Gói cơ bản (MÈO)",
        premium: 1500000,
        taxRatio: 10,
        taxAmt: 150000,
        type: "PET_CAT",
        smi1: {
            title: "Chi phí điều trị ngoại trú",
            note: "(Giới hạn: điều trị các bệnh liên quan đến da & lông: tối đa 2 lần)",
            list: [
                { description: "Tối đa mỗi lần", si_amt: "500.000" },
                { description: "Tổng mức bồi thường tối đa", si_amt: "1.500.000" }
            ]
        },
        smi2: {
            title: "Chi phí điều trị nội trú",
            note: "",
            list: [
                { description: "Tối đa mỗi lần", si_amt: "3.000.000" },
                { description: "Tổng mức bồi thường tối đa", si_amt: "3.000.000" }
            ]
        },
        smi3: {
            title: "Chi phí phẫu thuật",
            note: "",
            list: [
                { description: "Tối đa mỗi lần", si_amt: "6.000.000" },
                { description: "Tổng mức bồi thường tối đa", si_amt: "6.000.000" }
            ]
        },
        smi4: {
            title: "Trách nhiệm bồi thường thiệt hại do thú cưng gây ra",
            note: "",
            list: [
                { description: "Tổng mức bồi thường tối đa", si_amt: "300.000.000" }
            ]
        },
        smi5: {
            title: "Chi phí quảng cáo tìm kiếm thú cưng bị lạc",
            note: "",
            list: [
                { description: "Tổng mức bồi thường tối đa", si_amt: "1.000.000" }
            ]
        },
        smi6: {
            title: "Chi phí chăm sóc thú cưng trong thời gian NĐBH nằm viện (tối đa 10 ngày)",
            note: "",
            list: [
                { description: "Tổng mức bồi thường tối đa", si_amt: "3.000.000" }
            ]
        },
        smi7: {
            title: "Chi phí tang lễ cho thú cưng",
            note: "",
            list: [
                { description: "Tổng mức bồi thường tối đa", si_amt: "3.000.000" }
            ]
        }
    },
    {
        // pkg_id: 3,
        id: 3,
        pkg_code: "PETRISK02",
        pkg_name: "Gói cao cấp (MÈO)",
        premium: 2200000,
        taxRatio: 10,
        taxAmt: 220000,
        type: "PET_CAT",
        smi1: {
            title: "Chi phí điều trị ngoại trú",
            note: "(Giới hạn: điều trị các bệnh liên quan đến da & lông: tối đa 2 lần)",
            list: [
                { description: "Tối đa mỗi lần", si_amt: "1.000.000" },
                { description: "Tổng mức bồi thường tối đa", si_amt: "3.000.000" }
            ]
        },
        smi2: {
            title: "Chi phí điều trị nội trú",
            note: "",
            list: [
                { description: "Tối đa mỗi lần", si_amt: "6.000.000" },
                { description: "Tổng mức bồi thường tối đa", si_amt: "9.000.000" }
            ]
        },
        smi3: {
            title: "Chi phí phẫu thuật",
            note: "",
            list: [
                { description: "Tối đa mỗi lần", si_amt: "9.000.000" },
                { description: "Tổng mức bồi thường tối đa", si_amt: "13.500.000" }
            ]
        },
        smi4: {
            title: "Trách nhiệm bồi thường thiệt hại do thú cưng gây ra",
            note: "",
            list: [
                { description: "Tổng mức bồi thường tối đa", si_amt: "500.000.000" }
            ]
        },
        smi5: {
            title: "Chi phí quảng cáo tìm kiếm thú cưng bị lạc",
            note: "",
            list: [
                { description: "Tổng mức bồi thường tối đa", si_amt: "2.000.000" }
            ]
        },
        smi6: {
            title: "Chi phí chăm sóc thú cưng trong thời gian NĐBH nằm viện (tối đa 10 ngày)",
            note: "",
            list: [
                { description: "Tổng mức bồi thường tối đa", si_amt: "5.000.000" }
            ]
        },
        smi7: {
            title: "Chi phí tang lễ cho thú cưng",
            note: "",
            list: [
                { description: "Tổng mức bồi thường tối đa", si_amt: "4.000.000" }
            ]
        }
    }
];

export const initialAlert = {
    is_show: false,
    title: "",
    content: "",
    variant: "danger"
};

export const initial = {
    veh_pol_no: "",
    veh_certificate_no: "",
    veh_org_sc_code: "PET",
    veh_sc_code: "PET",
    veh_business: "N",
    veh_type: "",
    veh_type_name: "",
    veh_seat: "0",
    veh_weight: "0",
    veh_reg_no: "",
    veh_chassis_no: "",
    veh_engine_no: "",
    veh_month: "1",
    veh_fm_dt: null,
    veh_to_dt: null,
    veh_issued_date: null,
    veh_handler_code: "",
    veh_handler_name: "",
    veh_handler_name_vi: "",
    veh_is_pax: false,
    veh_pax: "",
    veh_customer_id: 0,
    veh_customer: "",
    veh_customer_idcard: "",
    veh_phone: "",
    veh_email: "",
    veh_address: "",
    "veh_province_code": "",
    "veh_province_name": "",
    "veh_district_code": "",
    "veh_district_name": "",
    veh_issue_invoice: false,
    inv_tax: "",
    inv_company_name: "",
    inv_address: "",
    veh_origin_premium: 0,
    veh_tax_ratio: 0,
    veh_tax_amt: 0,
    veh_pax_premium: 0,
    veh_premium: 0,
    veh_si_amt1: 0,
    veh_si_amt2: 0,
    veh_si_amt3: 0,
    veh_si_amt4: 0,
    veh_is_truck: false,
    veh_url: window.location.href,
    veh_order_no: "",
    veh_get_cert_via: 1,
    veh_sob_code: "",
    veh_sob_name: "",
    veh_is_agent: false,
    veh_agency_code: "",
    veh_agency_name: "",
    veh_broker_code: "",
    veh_broker_name: "",
    pol_voucher_code: "",
    veh_gender: 1,
    veh_customer_dob: "",
    veh_time_eff: "000000",
    veh_rep_company: "",
    veh_tax_code: "",
    veh_occupation_code: "",
    veh_occupation_name: "",
    veh_national_code: "",
    veh_national_name: "",
    veh_job_type: "",
    veh_job_desc: "",
    veh_ex_occ_confirm: false,
    veh_ex_pet_confirm: false,
    is_confirm: false,
    aml_total_score: 0,
    aml_risk_score: 0,
    aml_checked: false,
    status: 1,
    user_type: 1,
    pet_option: {}, // save item of pet_options list
    pet_package: {},
    res_post_order: {}, // save response of post pet order
    cert_url: "",
    pet_action: "new",
    ins_promoter_code: "",
};

export const pet_options = [
    {
        "pet_code": "PETPKG01",
        // "pet_type": "Thú cưng - Chó",
        "pet_type": "PET_DOG",
        "bg_color": "#564741",
        "btn_color": "#564741",
        "bg_image": "Image1",
        "name": "Chó"
    },
    {
        "pet_code": "PETPKG02",
        // "pet_type": "Thú cưng - Mèo",
        "pet_type": "PET_CAT",
        "bg_color": "#564741",
        "btn_color": "#564741",
        "bg_image": "Image2",
        "name": "Mèo"
    },
]

export const faqData = [
    {
        question: "NGƯỜI ĐƯỢC BẢO HIỂM?",
        answer: "ĐỦ 18 TUỔI TRỞ LÊN VÀ LÀ CHỦ SỞ HỮU CỦA THÚ CƯNG ĐƯỢC BẢO HIỂM"
    },
    {
        question: "THÚ CƯNG ĐƯỢC BẢO HIỂM (THÚ CƯNG) ?",
        answer: "LÀ CHÓ HOẶC MÈO ĐƯỢC NUÔI HOẶC QUẢN LÝ VÌ MỤC ĐÍCH GIẢI TRÍ HOẶC BẦU BẠN KHÔNG BAO GỒM CHÓ HOẶC MÈO ĐƯỢC SỬ DỤNG CHO MỤC ĐÍCH: CHĂN NUÔI SĂN BẮN LÀM VIỆC THÍ NGHIỆM CHO Y TẾ"
    },
    {
        question: "TUỔI CỦA THÚ CƯNG ?",
        answer: "ĐỦ 2 THÁNG TUỔI ~ DƯỚI 8 TUỔI (ÁP DỤNG CHO THÚ CƯNG THAM GIA BẢO HIỂM LẦN ĐẦU)"
    },
    {
        question: "ĐIỀU KIỆN THAM GIA BẢO HIỂM ?",
        answer: "THÚ CƯNG CẦN PHẢI CẤY CHÍP ĐIỆN TỬ (ISO MICROCHIP - CÓ MÃ KÝ HIỆU GỒM 15 SỐ)"
    },
    {
        question: "THỜI GIAN CHỜ ?",
        answer: "1. DO TAI NẠN: 0 NGÀY\n2. DO BỆNH TẬT: 30 NGÀY\n - BỆNH THÔNG THƯỜNG: 90 NGÀY.\n - UNG THƯ, LỆCH XƯƠNG ĐẦU GỐI, LOẠN SẢN XƯƠNG HÔNG, THOÁT VỊ ĐĨA ĐỆM, BỆNH TIM, BỆNH THẬN, ĐỘNG KINH, TIỂU ĐƯỜNG HOẶC BỆNH TUYẾN GIÁP."
    },
];

export const lst_pet_decline = [
    'chó bò Pháp',
    'chó Pit Bull (bao gồm nhưng không giới hạn các giống chó sục Pit Bull Mỹ, giống Pit Bull Terrier, giống Staffordshire Bull Terrier, giống American Staffordshire Terrier)',
    'chó Tosa Nhật Bản',
    'chó ngao Neapolitan',
    'chó Dogo Argentino',
    'chó Fila Brasileiro và chó Ngao (bao gồm nhưng không giới hạn các giống chó Ngao Tạng',
    'chó Ngao Anh',
    'chó Ngao Tây Tạng',
    'chó Ngao Bò',
    'chó Ngao Ý (Cane Corso)',
    'chó Ngao Pháp (Dogue de Bordeaux)',
    'bò Pháp',
    'Pháp bò',
    'Pit Bull (bao gồm nhưng không giới hạn các giống chó sục Pit Bull Mỹ, giống Pit Bull Terrier, giống Staffordshire Bull Terrier, giống American Staffordshire Terrier)',
    'Pit Bull',
    'Bull Pit',
    'Pit',
    'Bull',
    'Pit Bull Mỹ',
    'Pit Bull Terrier',
    'Terrier Pit Bull',
    'Bull Terrier',
    'Staffordshire Bull Terrier',
    'Terrier Staffordshire Bull',
    'Staffordshire Bull',
    'Bull Staffordshire',
    'American Staffordshire Terrier',
    'Tosa Nhật Bản',
    'Nhật Bản Tosa',
    'ngao Neapolitan',
    'Neapolitan ngao',
    'Neapolitan',
    'Dogo Argentino',
    'Argentino Dogo',
    'Dogo',
    'Argentino',
    'Fila Brasileiro và chó Ngao (bao gồm nhưng không giới hạn các giống chó Ngao Tạng',
    'Fila Brasileiro',
    'Brasileiro Fila',
    'Brasileiro',
    'Fila',
    'chó Ngao',
    'Ngao chó',
    'chó Ngao Tạng',
    'Ngao Tạng chó',
    "Ngao",
    'Ngao Anh',
    'Anh Ngao',
    'Ngao Tây Tạng',
    'Tây Tạng Ngao',
    'Tây Tạng',
    'Ngao Bò',
    'Bò Ngao',
    'Ngao Ý (Cane Corso)',
    'Cane Corso',
    'Ngao Ý',
    'Ý Ngao',
    'Ngao Pháp (Dogue de Bordeaux)',
    'Dogue de Bordeaux',
    'Bordeaux',
    'Ngao Pháp',
    'Pháp Ngao',
]

export const declaredItem = [
    {
        "code": "PET_QUES_1",
        "question": "Thú cưng được bảo hiểm trong vòng 02 tháng qua có từng bị thương và được điều trị y tế, khám hoặc dùng thuốc không?",
        "template": {
            "answer_yn": "N",
            "note": ""
        }
    },
    {
        "code": "PET_QUES_2",
        "question": "Thú cưng được bảo hiểm có bị khuyết tật cơ thể nào sau đây không? Mù, dị tật chân tay, xuất huyết, tiêu chảy, điếc.",
        "template": {
            "answer_yn": "N",
            "note": ""
        }
    },
    {
        "code": "PET_QUES_3",
        "question": "Nếu thú cưng được bảo hiểm có khám sức khoẻ, thì nội dung khám có dấu hiệu bất thường nào không? bao gồm: ung thư, lệch xương đầu gối, loạn sản xương hông, thoát vị đĩa đệm, bệnh tim, bệnh thận, động kinh, tiểu đường, bệnh tuyến giáp hoặc bệnh lý khác.",
        "template": {
            "answer_yn": "N",
            "note": ""
        }
    },
    {
        "code": "PET_QUES_4",
        "question": "Bạn có mua bảo hiểm cho các thú cưng trên ở DNBH khác không?",
        "template": {
            "answer_yn": "N",
            "note": ""
        }
    }
]

export const lst_pet_decline_home = [
    'chó bò Pháp',
    'chó Pit Bull (bao gồm nhưng không giới hạn các giống chó sục Pit Bull Mỹ, giống Pit Bull Terrier, giống Staffordshire Bull Terrier, giống American Staffordshire Terrier)',
    'chó Tosa Nhật Bản',
    'chó ngao Neapolitan',
    'chó Dogo Argentino',
    'chó Fila Brasileiro và chó Ngao (bao gồm nhưng không giới hạn các giống chó Ngao Tạng',
    'chó Ngao Anh',
    'chó Ngao Tây Tạng',
    'chó Ngao Bò',
    'chó Ngao Ý (Cane Corso)',
    'chó Ngao Pháp (Dogue de Bordeaux)',
]

export const initial_confirmation = {
    veh_pol_no: "",
    veh_certificate_no: "",
    veh_org_sc_code: "PET",
    veh_sc_code: "PET",
    veh_business: "N",
    veh_type: "",
    veh_type_name: "",
    veh_seat: "0",
    veh_weight: "0",
    veh_reg_no: "",
    veh_chassis_no: "",
    veh_engine_no: "",
    veh_month: "1",
    veh_fm_dt: null,
    veh_to_dt: null,
    veh_issued_date: null,
    veh_handler_code: "",
    veh_handler_name: "",
    veh_handler_name_vi: "",
    veh_is_pax: false,
    veh_pax: "",
    veh_customer_id: 0,
    veh_customer: "",
    veh_customer_idcard: "",
    veh_phone: "",
    veh_email: "",
    veh_address: "",
    veh_issue_invoice: false,
    inv_tax: "",
    inv_company_name: "",
    inv_address: "",
    veh_origin_premium: 0,
    veh_tax_ratio: 0,
    veh_tax_amt: 0,
    veh_pax_premium: 0,
    veh_premium: 0,
    veh_si_amt1: 0,
    veh_si_amt2: 0,
    veh_si_amt3: 0,
    veh_si_amt4: 0,
    veh_is_truck: false,
    veh_url: window.location.href,
    veh_order_no: "",
    veh_get_cert_via: 1,
    veh_sob_code: "",
    veh_sob_name: "",
    veh_agency_code: "",
    veh_agency_name: "",
    veh_broker_code: "",
    veh_broker_name: "",
    pol_voucher_code: "",
    pol_gender: "0",
    veh_customer_dob: "",
    pet_package: {}
};

export const initial_survey_questions = [
    {
        code: "PET_QUES_1",
        question: "Thú cưng được bảo hiểm trong vòng 02 tháng qua có từng bị thương và được điều trị y tế, khám hoặc dùng thuốc không?",
        answer_yn: "",
        note: "",
        placeholder: "ví dụ: bệnh nấm mèo, viêm tai điều trị tại bệnh viện X từ 01/2024 – 03/2024 đã khỏi bệnh"
    },
    {
        code: "PET_QUES_2",
        question: "Thú cưng được bảo hiểm có bị khuyết tật cơ thể nào sau đây không? Mù, dị tật chân tay, xuất huyết, tiêu chảy, điếc.",
        answer_yn: "",
        note: "",
        placeholder: "ví dụ: mù, chân dính ngón, điếc tai trái…"
    },
    {
        code: "PET_QUES_3",
        question: "Nếu thú cưng được bảo hiểm có khám sức khoẻ, thì nội dung khám có dấu hiệu bất thường nào không? bao gồm: ung thư, lệch xương đầu gối, loạn sản xương hông, thoát vị đĩa đệm, bệnh tim, bệnh thận, động kinh, tiểu đường, bệnh tuyến giáp hoặc bệnh lý khác.",
        answer_yn: "",
        note: "",
        placeholder: "ví dụ: khám sức khỏe tổng quát tại bệnh viện X 04/2024 phát hiện bệnh nấm da…"
    },
    {
        code: "PET_QUES_4",
        question: "Bạn có mua bảo hiểm cho các thú cưng trên ở doanh nghiệp bảo hiểm khác không?",
        answer_yn: "",
        note: "",
        placeholder: "ví dụ: mua bảo hiểm tại Liberty hiệu lực 01/01/2024 – 31/12/2024"
    }
];

export const error_msg_list = [
    { msg: 1 },
    { msg: 2 },
    { msg: 3 },
    { msg: 4 },
    { msg: 5 }
];

//#endregion

//#region FUNCTIONS

export const insuredObjectMapper = (data) => {
    return {
        // "session_id": data.session_id,
        "id": data.id,
        "order_no": data.orderNo,
        "object_name": data.pet_name,
        "id_card": data.chipNo,
        "dob": data.dob,
        "gender": data.gender,
        "relationship": 0,
        "package_code": data.objPackage.pkg_code,
        "package_detail": data.insPackage,
        "premium": parseFloat(data.premium).toFixed(6),
        "is_foreigner": true,
        "survey_questions": data.survey_questions,
        "pet_type": data.objPackage.type,
        "breed_of_pet": data.typeOfPet,
        "flex_value4": "",
        "flex_value5": "",
        "flex_num1": 0,
        "flex_num2": 0,
        "flex_num3": 0,
        "flex_num4": 0,
        "flex_num5": 0,
        "list": data.list,
    }
}

export const orderObjectMapper = (data) => {
    return {
        // "session_id": "string",
        "ins_organization": data.masterData.veh_customer,
        "ins_order_no": data.masterData.veh_order_no,
        "ins_customer": data.masterData.veh_customer,
        "ins_customer_idcard": data.masterData.veh_customer_idcard,
        "ins_customer_dob": data.masterData.veh_customer_dob,
        "ins_phone": data.masterData.veh_phone,
        "ins_email": data.masterData.veh_email,
        "ins_address": data.masterData.veh_address + ", " + data.masterData.veh_district_name + ", " + data.masterData.veh_province_name,
        "ins_fm_dt": data.masterData.veh_fm_dt,
        "ins_to_dt": data.masterData.veh_to_dt,
        "ins_issue_invoice": data.masterData.veh_issue_invoice.toString(),
        "ins_inv_tax": data.masterData.inv_tax,
        "ins_inv_company_name": data.masterData.inv_company_name,
        "ins_inv_address": data.masterData.ins_inv_address,
        "ins_origin_premium": parseFloat(data.origin_premium).toFixed(6),
        "ins_premium": parseFloat(data.masterData.veh_premium).toFixed(6),
        // "ins_tax_amt": data.masterData.veh_tax_amt,
        // tạm thời gán bằng cách này
        // "ins_tax_amt": parseFloat(data.masterData.veh_premium / 10).toFixed(13),
        "ins_tax_amt": parseFloat(data.taxAmt).toFixed(13),
        "ins_gender": data.masterData.veh_gender.toString(),
        "ins_rep_company": data.masterData.veh_rep_company,
        "ins_tax_code": data.masterData.veh_tax_code,
        "ins_occupation_code": data.masterData.veh_occupation_code,
        "ins_occupation_name": data.masterData.veh_occupation_name,
        "ins_national_code": data.masterData.veh_national_code,
        "ins_national_name": data.masterData.veh_national_name,
        "ins_pkg_code": data.masterData.pet_package["pkg_code"],
        "ins_objects": data.ins_objects,
        "ins_handler_code": data.masterData.veh_handler_code,
        "ins_handler_name": data.masterData.veh_handler_name,
        "ins_agency_code": data.masterData.veh_agency_code,
        "ins_agency_name": data.masterData.veh_agency_name,
        "ins_sob_code": data.masterData.veh_sob_code,
        "ins_sob_name": data.masterData.veh_sob_name,
        "ins_province_code": data.masterData.veh_province_code,
        "ins_province_name": data.masterData.veh_province_name,
        "ins_district_code": data.masterData.veh_district_code,
        "ins_district_name": data.masterData.veh_district_name,
        "ins_type_of_pet": data.masterData.pet_package["type"],
        "ins_promoter_code": data.masterData.ins_promoter_code,
    }
}

export const insuredObjectMaster = (data) => {
    return {

    }
}

export const paymentObjectMaster = (data) => {
    return {
        allow_payment: data.allow_payment,
        pending_appraisal: data.pending_appraisal,
    }
}

export const orderObjectMaster = (data) => {
    const map_pet_option = data.ins_type_of_pet === "PET_DOG" ? {
        "pet_code": "PETPKG01",
        "pet_type": "PET_DOG",
        "bg_color": "#564741",
        "btn_color": "#564741",
        "bg_image": "Image1",
        "name": "Chó"
    } : {
        "pet_code": "PETPKG02",
        "pet_type": "PET_CAT",
        "bg_color": "#564741",
        "btn_color": "#564741",
        "bg_image": "Image2",
        "name": "Mèo"
    };

    var pending_appraisal = null;
    var allow_payment = null;
    var lst_pending = [];
    var lst_payment = [];
    data.ins_objects.forEach((item) => {
        if (item.survey_questions != null && item.survey_questions.length > 0) {
            var lst_obj = item.survey_questions.filter(x => x.answer_yn == "Y");
            if (lst_obj.length > 0) {
                var existing = lst_pending.findIndex(x => x.id == item.id);
                if (existing < 0) {
                    lst_pending.push(item);
                }
            } else {
                var existing = lst_pending.findIndex(x => x.id == item.id);
                if (existing < 0) {
                    lst_payment.push(item);
                }
            }
        }
    })

    if (lst_pending.length > 0) {
        pending_appraisal = {
            "order_no": data.ins_order_no,
            "list": lst_pending.map((item) => { return { id: item.id } }),
            "origin_premium": data.ins_origin_premium,
            "tax_amt": data.ins_tax_amt,
            "total_amt": data.ins_premium
        }
    }
    if (lst_payment.length > 0) {
        allow_payment = {
            "order_no": data.ins_order_no,
            "list": lst_payment.map((item) => { return { id: item.id } }),
            "origin_premium": data.ins_origin_premium,
            "tax_amt": data.ins_tax_amt,
            "total_amt": data.ins_premium
        }
    }

    const res_post_order = {
        allow_payment,
        pending_appraisal
    }

    const map_state_lst_pet = data.ins_objects?.map((item, index) => ({
        "idx": index + 1,
        "id": item.id,
        // "orderNo": data.ins_order_no,
        "orderNo": data.ins_order_no,
        "pet_name": item.object_name,
        "chipNo": item.id_card,
        "dob": item.dob,
        "gender": item.gender,
        "kindOfPet": item.pet_type,
        "typeOfPet": item.breed_of_pet,
        "relationship": "0",
        "isForeigner": true,
        "createdBy": "",
        "createdDate": "",
        "modifiedBy": "",
        "modifiedDate": "",
        "insPackage": item.package_detail,
        "objPackage": safelyParseJSON(item.package_detail, {}),
        "action": "new",
        "survey_questions": generateSurveyQuestions(item.survey_questions),
        "images": generateImages(item.attachments),
        "premium": item.premium,
    })) ?? [];

    const firstObject = data.ins_objects?.[0] || {};

    return {
        "state": {
            "veh_pol_no": "",
            "veh_certificate_no": "",
            "veh_org_sc_code": "PET",
            "veh_sc_code": "PET",
            "veh_business": "N",
            "veh_type": "",
            "veh_type_name": "",
            "veh_seat": "0",
            "veh_weight": "0",
            "veh_reg_no": "",
            "veh_chassis_no": "",
            "veh_engine_no": "",
            "veh_month": "1",
            "veh_fm_dt": data.ins_fm_dt,
            "veh_to_dt": data.ins_to_dt,
            "veh_issued_date": null,
            "veh_handler_code": data.ins_handler_code,
            "veh_handler_name": data.ins_handler_name,
            "veh_handler_name_vi": data.ins_handler_name,
            "veh_is_pax": false,
            "veh_pax": data.ins_objects.length,
            "veh_customer_id": 0,
            "veh_customer": data.ins_customer,
            "veh_customer_idcard": data.ins_customer_idcard,
            "veh_phone": data.ins_phone,
            "veh_email": data.ins_email,
            "veh_address": data.ins_address,
            "veh_province_code": data.ins_province_code,
            "veh_province_name": data.ins_province_name,
            "veh_district_code": data.ins_district_code,
            "veh_district_name": data.ins_district_name,
            "veh_issue_invoice": false,
            "inv_tax": "",
            "inv_company_name": "",
            "inv_address": "",
            "veh_origin_premium": data.ins_origin_premium ?? 0,
            "veh_tax_ratio": 0,
            "veh_tax_amt": data.ins_tax_amt ?? 0,
            "veh_pax_premium": 0,
            "veh_premium": data.ins_premium ?? 0,
            "veh_si_amt1": 0,
            "veh_si_amt2": 0,
            "veh_si_amt3": 0,
            "veh_si_amt4": 0,
            "veh_is_truck": false,
            "veh_url": "http://localhost:5000/sale/pet-insurance",
            "veh_order_no": data.ins_order_no,
            "veh_get_cert_via": 1,
            "veh_sob_code": data.ins_sob_code,
            "veh_sob_name": data.ins_sob_name,
            "veh_is_agent": false,
            "veh_agency_code": data.ins_agency_code,
            "veh_agency_name": data.ins_agency_name,
            "pol_voucher_code": "",
            "veh_gender": data.ins_gender,
            "veh_customer_dob": data.ins_customer_dob,
            "veh_time_eff": "000000",
            "veh_rep_company": "",
            "veh_tax_code": "",
            "veh_occupation_code": data.ins_occupation_code,
            "veh_occupation_name": data.ins_occupation_name,
            "veh_national_code": data.ins_national_code,
            "veh_national_name": data.ins_national_name,
            "veh_job_type": "",
            "veh_job_desc": "",
            "veh_ex_occ_confirm": false,
            "veh_ex_pet_confirm": false,
            "is_confirm": false,
            "aml_total_score": 0,
            "aml_risk_score": 0,
            "aml_checked": false,
            "status": data.ins_status,
            "user_type": 1,
            "pet_option": map_pet_option,
            "pet_package": safelyParseJSON(firstObject.package_detail, {}),
            "pet_action": "edit",
            "ins_promoter_code": data.ins_promoter_code,
            res_post_order
        },
        "state_pet": {
            "idx": 1,
            "id": firstObject.id,
            "orderNo": CommonHelper.is_not_empty(data.ins_order_no) ? data.ins_order_no : firstObject.id,
            "pet_name": firstObject.object_name,
            "chipNo": firstObject.id_card,
            "dob": firstObject.dob,
            "gender": firstObject.gender,
            "kindOfPet": firstObject.pet_type,
            "typeOfPet": firstObject.breed_of_pet,
            "relationship": "0",
            "isForeigner": true,
            "createdBy": "",
            "createdDate": "",
            "modifiedBy": "",
            "modifiedDate": "",
            "insPackage": "",
            "objPackage": safelyParseJSON(firstObject.package_detail, {}),
            "action": "",
            "survey_questions": generateSurveyQuestions(),
            "images": []
        },
        "state_lst_pet": map_state_lst_pet,
        "lst_sob": [],
        "lst_agency": [],
    };
};

export const safelyParseJSON = (jsonString, fallbackValue) => {
    try {
        return JSON.parse(jsonString);
    } catch (error) {
        console.error('Error parsing JSON:', error);
        return fallbackValue;
    }
};

export const generateImages = (data) => {
    if (data != null && data.length > 0) {
        return data;
    }
    return [];
};

export const generateSurveyQuestions = (data) => {
    return data;
};

//#endregion

//#region TRANSLATE

export const translations = {
    vi: {
        model_reject: '',
        model_reject_title: 'Thông báo',
        model_reject_message: 'Rất tiếc hồ sơ yêu cầu bảo hiểm của bạn chưa phù hợp với quy định của chúng tôi. Xin vui lòng liên hệ Fubon để được tư vấn và biết thêm chi tiết.',
        model_reject_companyName: 'CÔNG TY TNHH BẢO HIỂM FUBON (VIỆT NAM)',
        model_reject_phone: 'Điện thoại: (84) 28 39435678',
        model_reject_fax: 'Fax: (84) 28 39435363',
        model_reject_address: 'Địa chỉ: Tầng 15, Tòa nhà Ree Tower, Số 9, Đường Đoàn Văn Bơ, Quận 4, TP.HCM',
        model_reject_buttonText: 'Tôi đã hiểu',
        model_reject_switchLanguage: 'Chuyển sang Tiếng Anh',
        pet_introduce: '',
        pet_introduce_title: 'Giới thiệu Sản phẩm',
        pet_introduce_petInsuranceTitle: 'PET Insurance',
        pet_introduce_petInsuranceSubtitle: 'Yêu thương và bảo vệ, thú cưng luôn bên bạn.',
        pet_introduce_petInsuranceDesc1: 'Bảo hiểm cho thú cưng chó và mèo',
        pet_introduce_petInsuranceDesc2: 'Gói bảo hiểm được thiết kế phù hợp, tối ưu',
        pet_introduce_petInsuranceDetail: `Thú cưng không chỉ là bạn đồng hành, mà còn là thành viên trong gia đình.
                        Để bảo vệ sức khỏe và hạnh phúc của chúng, bảo hiểm thú cưng là một lựa chọn thông minh và cần thiết.
                        Với bảo hiểm thú cưng, bạn sẽ nhận được sự hỗ trợ tài chính khi thú cưng cần điều trị y tế,
                        từ những vấn đề nhỏ như khám bệnh định kỳ đến các trường hợp khẩn cấp như tai nạn hoặc bệnh nặng.
                        Đừng để những lo toan về chi phí y tế làm bạn phải lựa chọn giữa tình yêu thương và khả năng tài chính,
                        hãy để bảo hiểm thú cưng <span style="font-weight: 700; color: #564741; padding-right: 8px;">PET Insurance</span>giúp bạn chăm sóc những người bạn nhỏ của mình tốt nhất.`,
        pet_introduce_insuranceInfo: 'Thông tin bảo hiểm',
        pet_introduce_buyNow: 'Mua ngay',
        pet_introduce_switchLanguage: 'Chuyển sang Tiếng Anh',
        pet_benefits: '',
        pet_benefits_title: 'Phạm vi bảo hiểm & Phí bảo hiểm',
        pet_benefits_small_title: 'Phạm vi bảo hiểm & Phí bảo hiểm',
        pet_benefits_sub_title: 'QUYỀN LỢI',
        pet_benefits_money_unit: 'Đơn vị: VNĐ',
        pet_benefits_subtitle: 'Phạm vi Bảo hiểm thú cưng',
        pet_benefits_desc1: 'Bảo hiểm chi phí y tế cho thú cưng (Mức khấu trừ 10%)',
        pet_benefits_desc2: '1. Chi phí điều trị ngoại trú (Giới hạn: điều trị các bệnh liên quan đến da & lông: tối đa 2 lần)',
        pet_benefits_desc3: '2. Chi phí điều trị nội trú',
        pet_benefits_desc4: '3. Chi phí phẫu thuật',
        pet_benefits_desc5: 'Tối đa mỗi lần',
        pet_benefits_desc6: 'Tổng mức bồi thường tối đa',
        pet_benefits_desc7: 'Trách nhiệm bồi thường thiệt hại do thú cưng gây ra',
        pet_benefits_desc8: 'Chi phí quảng cáo tìm kiếm thú cưng bị lạc',
        pet_benefits_desc9: 'Chi phí chăm sóc thú cưng trong thời gian người được bảo hiểm nằm viện (tối đa 10 ngày)',
        pet_benefits_desc10: 'Chi phí tang lễ cho thú cưng',
        pet_benefits_desc11: 'Phí bảo hiểm năm đầu tiên',
        pet_benefits_desc12: 'Chó',
        pet_benefits_desc13: 'Mèo',

    },
    en: {
        model_reject_title: 'Notification',
        model_reject_message: 'Unfortunately, your insurance application does not comply with our regulations. Please contact Fubon for consultation and more details.',
        model_reject_companyName: 'FUBON INSURANCE COMPANY LTD (VIETNAM)',
        model_reject_phone: 'Phone: (84) 28 39435678',
        model_reject_fax: 'Fax: (84) 28 39435363',
        model_reject_address: 'Address: 15th Floor, Ree Tower, No. 9, Doan Van Bo Street, District 4, HCMC',
        model_reject_buttonText: 'I Understand',
        model_reject_switchLanguage: 'Switch to Vietnamese',
        pet_introduce_title: 'Product Introduction',
        pet_introduce_petInsuranceTitle: 'PET Insurance',
        pet_introduce_petInsuranceSubtitle: 'Love and protect, pets are always with you.',
        pet_introduce_petInsuranceDesc1: 'Insurance for dogs and cats',
        pet_introduce_petInsuranceDesc2: 'The insurance package is designed appropriately and optimally',
        pet_introduce_petInsuranceDetail: `Pets are not just companions but also family members.
                        To protect their health and happiness, pet insurance is a smart and necessary choice.
                        With pet insurance, you will receive financial support when your pet needs medical treatment,
                        from minor issues like regular check-ups to emergencies like accidents or serious illnesses.
                        Don’t let concerns about medical expenses make you choose between love and financial capability,
                        let <span style="font-weight: 700; color: #564741; padding-right: 8px;">PET Insurance</span>help you take care of your little friends in the best way.`,
        pet_introduce_insuranceInfo: 'Insurance Information',
        pet_introduce_buyNow: 'Buy Now',
        pet_introduce_switchLanguage: 'Switch to Vietnamese',
        pet_benefits: '',
        pet_benefits_title: 'INSURANCE SCOPE & INSURANCE FEES',
        pet_benefits_small_title: 'INSURANCE SCOPE & INSURANCE FEES',
        pet_benefits_sub_title: 'INTEREST',
        pet_benefits_money_unit: 'Unit: VND',
        pet_benefits_subtitle: 'Pet Insurance Coverage',
        pet_benefits_desc1: 'Pet medical expense insurance (10% deductible)',
        pet_benefits_desc2: '1. Outpatient treatment costs (Limited: treatment of diseases related to skin & hair: maximum 2 times)',
        pet_benefits_desc3: '2. Inpatient treatment costs',
        pet_benefits_desc4: '3. Surgery costs',
        pet_benefits_desc5: 'Maximum per time',
        pet_benefits_desc6: 'Total maximum compensation',
        pet_benefits_desc7: 'Liability to compensate for damage caused by pets',
        pet_benefits_desc8: 'Advertising costs for finding lost pets',
        pet_benefits_desc9: `Pet care expenses during the insured person's hospitalization (maximum 10 days)`,
        pet_benefits_desc10: 'Funeral costs for pets',
        pet_benefits_desc11: 'First year insurance premium',
        pet_benefits_desc12: 'Dog',
        pet_benefits_desc13: 'Cat',
    },
    zh: {
        model_reject_title: '通知',
        model_reject_message: '很抱歉，您的保险申请不符合我们的规定。请联系富邦保险咨询并了解更多详情。',
        model_reject_companyName: '富邦保险有限公司（越南）',
        model_reject_phone: '电话: (84) 28 39435678',
        model_reject_fax: '传真: (84) 28 39435363',
        model_reject_address: '地址: 第4区, Doan Van Bo街9号, Ree大厦15楼, 胡志明市',
        model_reject_buttonText: '我明白了',
        model_reject_switchLanguage: '切换到越南语',
        pet_introduce_title: '产品介绍',
        pet_introduce_petInsuranceTitle: '宠物保险',
        pet_introduce_petInsuranceSubtitle: '爱与保护，宠物永远陪伴你。',
        pet_introduce_petInsuranceDesc1: '狗和猫的保险',
        pet_introduce_petInsuranceDesc2: '保险套餐设计合理，优化',
        pet_introduce_petInsuranceDetail: `宠物不仅是伴侣，也是家庭成员。
                        为了保护它们的健康和幸福，宠物保险是一个明智而必要的选择。
                        有了宠物保险，当您的宠物需要医疗时，您将获得财务支持，
                        无论是常规检查的小问题还是事故或严重疾病等紧急情况。
                        不要让对医疗费用的担忧迫使您在爱与经济能力之间做出选择，
                        让 <span style="font-weight: 700; color: #564741; padding-right: 8px;">宠物保险</span> 帮助您以最好的方式照顾您的小伙伴们。`,
        pet_introduce_insuranceInfo: '保险信息',
        pet_introduce_buyNow: '立即购买',
        pet_introduce_switchLanguage: '切换到英文',
        pet_benefits_title: '保险范围和费用',
        pet_benefits_small_title: '保险范围和费用',
        pet_benefits_sub_title: '权益',
        pet_benefits_money_unit: '单位: VND',
        pet_benefits_subtitle: '宠物保险覆盖范围',
        pet_benefits_desc1: '宠物医疗费用保险（10%免赔额）',
        pet_benefits_desc2: '1. 门诊治疗费用（限制：皮肤和毛发相关疾病治疗：最多2次）',
        pet_benefits_desc3: '2. 住院治疗费用',
        pet_benefits_desc4: '3. 手术费用',
        pet_benefits_desc5: '每次最高',
        pet_benefits_desc6: '总赔偿最高限额',
        pet_benefits_desc7: '赔偿宠物造成的损害责任',
        pet_benefits_desc8: '寻找丢失宠物的广告费用',
        pet_benefits_desc9: '被保险人住院期间的宠物护理费用（最多10天）',
        pet_benefits_desc10: '宠物葬礼费用',
        pet_benefits_desc11: '第一年保险费',
        pet_benefits_desc12: '狗',
        pet_benefits_desc13: '猫',
    },
};

//#endregion

//#region HOOKS

// export const useTranslation = () => {
//     const [language, setLanguage] = useState('vi'); // Default language is Vietnamese

//     // Function to get the current translations based on the language
//     const t = useCallback(
//         (key) => translations[language][key] || key,
//         [language]
//     );

//     // Function to toggle language
//     const toggleLanguage = useCallback(() => {
//         setLanguage((prevLang) => (prevLang === 'vi' ? 'en' : 'vi'));
//     }, []);

//     return { t, toggleLanguage, language };
// }

export const useTranslation = () => {
    const [language, setLanguage] = useState('vi'); // Default language is Vietnamese

    // Function to get the current translations based on the language
    const t = useCallback(
        (key) => translations[language][key] || key,
        [language]
    );

    // Function to set language directly
    const toggleLanguage = useCallback((lang) => {
        setLanguage(lang);
    }, []);

    return { t, toggleLanguage, language };
};
//#endregion