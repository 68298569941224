import React from 'react'
import PropTypes from 'prop-types'
import Popup from '../../../../components/popup'

const Content = () => {
    return (
        <>
            <p><b>Tình huống:</b><br />
                -	Hai vợ chồng anh A chở nhau đi chơi, họ vô tình va chạm với một xe khác, khiến anh A gãy xương cánh tay sau khi điều trị có thể cử động bình thường, vợ anh bị vỡ xương bánh chè, phải phẫu thuật điều trị.<br />
                -	Xe anh A có tham gia bảo hiểm tai nạn người ngồi trên xe 100.000.000 đồng/người/vụ.<br />
            </p>
            <p><b>Bồi thường:</b><br />
                Theo quy tắc bảo hiểm tai nạn lái xe và người ngồi trên xe:<br />
                •	Gãy xương cánh tay, can tốt, cử động bình thường : 15%-25% STBH<br />
                •	Vỡ xương bánh chè, phải phẫu thuật điều trị : 20% STBH<br />
                Số tiền bồi thường tối đa anh A nhận được là 25.000.000 triệu đồng<br />
                Số tiền bồi thường tối đa vợ anh A nhận được là 20.000.000 triệu đồng<br />
            </p>
        </>
    )
}

function VehicleMDPExample(props) {
    const { onClose, show, ...other } = props;
    const handle_close = () => {
        onClose(false);
    }
    return (
        <Popup show={show} title="Ví dụ" content={<Content />} onClose={handle_close} />
    )
}

export default VehicleMDPExample

VehicleMDPExample.propTypes = {
    onClose: PropTypes.func.isRequired,
}