import React, { useState, useEffect } from 'react'
import { useLocation, NavLink, Link } from "react-router-dom";
import { Nav } from "react-bootstrap";
import fbBanner from "../../../assets/images/logo.svg"


const sidebarRoutes = [
    {
        path: "/fubon/ctpl-moto",
        name: "Bảo hiểm xe máy",
        // icon: "nc-icon nc-circle-09",
        layout: "/nhan-vien"
    },
    {
        path: "/fubon/ctpl-vehicle",
        name: "Bảo hiểm ô tô",
        // icon: "nc-icon nc-delivery-fast",
        layout: "/nhan-vien"
    },
    {
        path: "/fubon/pa-insurance",
        name: "Bảo hiểm tai nạn",
        // icon: "nc-icon nc-pin-3",
        layout: "/nhan-vien"
    },
    /*{
        path: "/fubon/pet-insurance",
        name: "Bảo hiểm thú cưng",
        // icon: "nc-icon nc-pin-3",
        layout: "/nhan-vien"
    },*/
    {
        path: "/fubon/danh-sach-hop-dong",
        name: "Quản lý Policy",
        // icon: "nc-icon nc-notes",
        layout: "/nhan-vien"
    },/*
    {
        path: "/fubon/bao-gia-xe-co-gioi",
        name: "Báo giá xe cơ giới",
        layout: "/dai-ly"
    },*/
];

function Sidebar() {
    const location = useLocation();
    const [{ active }, setActive] = useState({
        active: "",
    })
    useEffect(() => {
        setActive((prev) => ({ ...prev, active: location.pathname }))
    }, [location])

    return (
        <div className="sidebar">
            {/* <div className="sidebar-background" /> */}
            <div className="sidebar-wrapper bg-white">
                <div className="logo d-flex align-items-center justify-content-start bg-white">
                    <a href="/" className="simple-text logo-mini mx-1">
                        <img src={fbBanner} />
                    </a>
                </div>
                <div className="mt-1">
                    <ul className="list-group list-group-flush">
                        {sidebarRoutes.map((prop, key) => {
                            if (!prop.redirect)
                                return (
                                    <li className={"list-group-item " + (active === prop.path ? "active" : "")} key={key} >
                                        <a href={prop.path} className="nav-link fw-bolder" activeClassName="active">
                                            {prop.name}
                                        </a>
                                    </li>
                                );
                            return null;
                        })}
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default Sidebar