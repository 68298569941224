import React from 'react'
import vi from 'date-fns/locale/vi';
import ReactDatePicker, { registerLocale } from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
registerLocale('vi', vi);
//ref: https://github.com/pushtell/react-bootstrap-date-picker#readme

function ShortDateInput(props) {
    return (
        <ReactDatePicker
            {...props}
            shouldCloseOnSelect
            isClearable
            showPopperArrow={false}
            locale="vi"
            placeholderText='dd/mm/yyyy'
            dateFormat="dd/MM/yyyy"
            showYearDropdown={true}
            showMonthDropdown={true}
            dropdownMode="select"
            className={'form-control form-control-sm ' + props.className} />
    )
}

export default ShortDateInput