import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Container, Row, Col } from 'react-bootstrap';
import CommonHelper from '../../../helpers/common';
import TextInput from '../../../components/text-input';
import SwitchCheck from '../../../components/switch-check';

const initial = {
    fa_id: '',
    fa_fq_id: '',
    fa_currency: 'VNĐ',
    fa_no: 0,
    fa_ac_id: '',
    fa_addition_vn: '',
    fa_addition_en: '',
    fa_addition_ch: '',
    fa_rate: 0,
    fa_prem: 0,
    fa_prem_tax: 0,
    fa_cr_id: '',
    fa_cr_dt: '',
    fa_upd_id: '',
    fa_upd_dt: '',
    fa_fq_idx: 0,
    fa_rate_orin: 0,
    fa_checked: false,
};

function Conditions(props) {
    const { onChange, data: valueProp, ...other } = props;
    const [state, setState] = useState([initial]);
    useEffect(() => {
        if (valueProp !== null) {
            setState(valueProp);
        } else {
            setState([initial]);
        }
        //console.log(state)
    }, [valueProp]);
    const handle_check = (e) => {
        //var {id, checked} = e.target;
        //console.log(item);
        onChange(e.target);
    }
    const render = (item) => {
        return (
            <Row key={item.fa_ac_id}>
                <div className='col-sm-4 mt15px'>
                    <SwitchCheck id={item.fa_ac_id} checked={item.fa_checked}
                        onChange={handle_check} label={`${item.fa_ac_id} - ${item.fa_addition_vn}`} />
                </div>
                <div className='col-sm-4 mt15px'>
                    <Row className={item.fa_checked ? "" : "d-none"}>
                        <Col>
                            <label className="form-label">Tỉ lệ</label>
                            <div className="input-group input-group-sm">
                                <TextInput
                                    className="text-end"
                                    disabled
                                    value={CommonHelper.format_ratio(item.fa_rate)}
                                />
                                <span className="input-group-text">%</span>
                            </div>
                        </Col>
                        <Col>
                            <label className="form-label">Phí</label>
                            <div className="input-group input-group-sm">
                                <TextInput
                                    className="text-end"
                                    disabled
                                    value={CommonHelper.format_currency(item.fa_prem)}
                                />
                                <span className="input-group-text">VNĐ</span>
                            </div>
                        </Col>
                    </Row>
                </div>
                <div className='col-sm-4 mt15px'>
                    <Row className={item.fa_checked ? "" : "d-none"}>
                        <Col>
                            <label className="form-label">VAT</label>
                            <div className="input-group input-group-sm">
                                <TextInput
                                    className="text-end"
                                    disabled
                                    value={CommonHelper.format_currency(item.fa_prem_tax)}
                                />
                                <span className="input-group-text">VNĐ</span>
                            </div>
                        </Col>
                        <Col>
                            <label className="form-label">Số tiền</label>
                            <div className="input-group input-group-sm">
                                <TextInput
                                    className="text-end"
                                    disabled
                                    value={CommonHelper.format_currency(item.fa_prem_tax + item.fa_prem)}
                                />
                                <span className="input-group-text">VNĐ</span>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Row>
        );
    }
    return (
        <>
            {state !== null && state.length > 0 && (
                <Container>
                    {state.map((item) => {
                        return render(item)
                    })}
                </Container>
            )}
        </>
    )
}

export default Conditions

Conditions.propTypes = {
    onChange: PropTypes.func.isRequired,
}