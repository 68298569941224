import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

const tabs = [
    {
        code: 1,
        title: "Quyền lợi"
    }, {
        code: 2,
        title: "Giới thiệu"
    }, {
        code: 3,
        title: "Bồi thường"
    }, {
        code: 4,
        title: "Ví dụ"
    },
];

function HomeProductDetail(props) {
    const { onClose, show, ...other } = props;
    const handle_close = () => {
        onClose(false);
    }
    const [tab, setTab] = useState(tabs[0]);
    useEffect(() => {
        /*if (show) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "none";
        }*/
    }, [show])
    return (
        <>
            <div className={'bg-modal-bottom-sheet' + (show ? " showbs" : "")}>
                <div className={'bg-bottom-sheet' + (show ? " showbs" : "")}>
                    <div className='bottom-sheet-header'>
                        <div className='close-box'>
                            <button type="button" className="btn-close" aria-label="Close" onClick={handle_close}></button>
                        </div>
                        <span className='bottom-sheet-header-title'>{other.title}</span>
                    </div>
                    <div className='bottom-sheet-body'>
                        <nav class="nav nav-pills">
                            {tabs.map((item, idx) => {
                                return (
                                    <a className={"nav-link" + (tab.code == item.code ? " active" : "")} aria-current="page" onClick={() => { setTab(item) }}>{item.title}</a>
                                )
                            })}
                            <a className='edu-btn nav-link' href={other.url}>Mua ngay</a>
                        </nav>
                        <div style={{ height: "330px", marginTop: "10px", overflowY: "scroll" }}>
                            {tab.code == 1 && (
                                <div>
                                    <p>Nghị định 67/2023/NĐ-CP quy định về bảo hiểm bắt buộc của chủ xe cơ giới, có hiệu lực từ ngày 06/9/2023.</p>
                                    <p>1. Khi tai nạn xảy ra, trong phạm vi bảo hiểm thuộc trách nhiệm, Fubon sẽ bồi thường cho chủ xe cơ giới số tiền mà chủ xe cơ giới đã bồi thường hoặc sẽ phải bồi thường cho người bị thiệt hại như sau:</p>
                                    <p>Thiệt hại về người: theo bảng quy định trả tiền bồi thường thiệt hại về người với mức trách nhiệm tối đa là 150.000.000 đ/người/vụ.</p>
                                    <p>Thiệt hại về tài sản: được bồi thường trên cơ sở thiệt hại thực tế và mức độ lỗi của các bên. Tổng số tiền chi trả không vượt 100.000.000 đ/vụ cho các tài sản bị thiệt hại liên quan đến tai nạn do lỗi của chủ xe/lái xe.</p>
                                    <p>2. Trường hợp cần thiết, Fubon sẽ tạm ứng ngay một phần những chi phí cần thiết và hợp lý trong phạm vi trách nhiệm bảo hiểm nhằm khắc phục hậu quả tai nạn.</p>
                                </div>
                            )}
                            {tab.code == 2 && (
                                <div>
                                    <p>Nghị định 67/2023/NĐ-CP quy định về bảo hiểm bắt buộc của chủ xe cơ giới, có hiệu lực từ ngày 06/9/2023. Xem Tại đây</p>
                                    <p>1. Chủ xe cơ giới phải tham gia bảo hiểm bắt buộc trách nhiệm dân sự của chủ xe cơ giới theo quy định tại Nghị định về chế độ bảo hiểm bắt buộc trách nhiệm dân sự của chủ xe cơ giới và Quy tắc, điều khoản, biểu phí do Bộ Tài chính ban hành.</p>
                                    <p>2. Khi chủ xe tham gia bảo hiểm theo quy định, sẽ được bồi thường theo các trường hợp sau:</p>
                                    <p>a. Thiệt hại ngoài hợp đồng về thân thể, tính mạng và tài sản đối với bên thứ ba do xe cơ giới gây ra.</p>
                                    <p>b. Thiệt hại về thân thể và tính mạng của hành khách theo hợp đồng vận chuyển hành khách do xe cơ giới gây ra.</p>
                                    <p>3.  Ngoài việc tham gia hợp đồng bảo hiểm bắt buộc trách nhiệm dân sự, chủ xe cơ giới có thể thỏa thuận với Fubon để tham gia hợp đồng bảo hiểm trách nhiệm dân sự tự nguyện vượt mức bắt buộc.</p>
                                </div>
                            )}
                            {tab.code == 3 && (
                                <div>
                                    <p><b>1. Tiếp nhận yêu cầu xử lý bồi thường</b></p>
                                    <p><b>a. Khai báo:</b></p>
                                    <p>Khi xảy ra tổn thất, Quý khách liên hệ ngay với Fubon để thông báo tai nạn theo một trong các cách sau:</p>
                                    <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 1. Tổng đài chăm sóc khách hàng: <a href='mailto:cskh.ins@fubon.com'>cskh.ins@fubon.com</a></p>
                                    <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 2. Nếu quý khách ở Tp. Hồ Chí Minh: <a href='tel:+84903900758'>(+84) 903 900 758</a> (Trung tâm bồi thường Tp. Hồ Chí Minh)</p>
                                    <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 3. Nếu quý khách ở Hà Nội: <a href='tel:+84943705166'>(+84) 943 705 166</a> (Trung tâm bồi thường Hà Nội)</p>
                                    <p><b>b. Giám định:</b></p>
                                    <p>Trên cơ sở thông báo tai nạn của khách hàng, Fubon sẽ phối hợp với khách hàng để tiến hành giám định thiệt hại bên cạnh việc giám định hiện trường và xác định lỗi của các bên trong vụ tai nạn được thực hiện bởi cảnh sát giao thông và các cơ quan chức năng.</p>
                                    <p><b>c. Lập hồ sơ bồi thường:</b></p>
                                    <p>Sau khi Fubon cùng quý khách hàng tiến hành giám định thiệt hại và thu thập đầy đủ các tài liệu sau để làm cơ sở giải quyết bồi thường:</p>
                                    <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; a. Tài liệu liên quan đến xe, lái xe;</p>
                                    <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; b. Các giấy tờ chứng minh thiệt hại về người và tài sản;</p>
                                    <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; c. Tài liệu liên quan của cơ quan có thẩm quyền về vụ tai nạn.</p>
                                    <p><i>(*) Fubon sẽ hỗ trợ, hướng dẫn quý khách hàng thu thập, cung cấp các tài liệu liên quan trong hồ sơ bồi thường</i></p>
                                    <p><b>d. Thời hạn yêu cầu, thanh toán & khiếu nại bồi thường:</b></p>
                                    <p>1. Thời hạn yêu cầu bồi thường của chủ xe cơ giới là 01 năm kể từ ngày xảy ra tai nạn, trừ trường hợp chậm trễ do nguyên nhân khách quan và bất khả kháng theo quy định của pháp luật.</p>
                                    <p>2. Trong thời hạn 05 ngày kể từ ngày xảy ra tai nạn (trừ trường hợp bất khả kháng), chủ xe cơ giới phải gửi thông báo bằng văn bản cho Bảo Minh kèm theo các tài liệu quy định trong hồ sơ yêu cầu bồi thường thuộc trách nhiệm của chủ xe cơ giới;</p>
                                    <p>3. Thời hạn thanh toán bồi thường của doanh nghiệp bảo hiểm là 15 ngày kể từ khi nhận được đầy đủ hồ sơ bồi thường trách nhiệm của chủ xe cơ giới và không quá 30 ngày trong trường hợp phải tiến hành xác minh hồ sơ.</p>
                                    <p>4. Trường hợp từ chối bồi thường, doanh nghiệp bảo hiểm phải thông báo bằng văn bản cho chủ xe cơ giới biết lý do từ chối bồi thường trong thời hạn 30 ngày kể từ ngày nhận được hồ sơ yêu cầu bồi thường bảo hiểm.</p>
                                    <p>5. Thời hiệu khởi kiện về việc bồi thường bảo hiểm là 03 năm kể từ ngày doanh nghiệp bảo hiểm thanh toán bồi thường hoặc từ chối bồi thường. Quá thời hạn trên quyền khởi kiện không còn giá trị.</p>
                                </div>
                            )}
                            {tab.code == 4 && (
                                <div>
                                    <p><b>Tình huống:</b><br />
                                        -	Hai vợ chồng anh A chở nhau đi chơi, họ vô tình va chạm với một xe khác, khiến anh A gãy xương cánh tay sau khi điều trị có thể cử động bình thường, vợ anh bị vỡ xương bánh chè, phải phẫu thuật điều trị.<br />
                                        -	Xe anh A có tham gia bảo hiểm tai nạn người ngồi trên xe 100.000.000 đồng/người/vụ.<br />
                                    </p>
                                    <p><b>Bồi thường:</b><br />
                                        Theo quy tắc bảo hiểm tai nạn lái xe và người ngồi trên xe:<br />
                                        •	Gãy xương cánh tay, can tốt, cử động bình thường : 15%-25% STBH<br />
                                        •	Vỡ xương bánh chè, phải phẫu thuật điều trị : 20% STBH<br />
                                        Số tiền bồi thường tối đa anh A nhận được là 25.000.000 triệu đồng<br />
                                        Số tiền bồi thường tối đa vợ anh A nhận được là 20.000.000 triệu đồng<br />
                                    </p>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default HomeProductDetail

HomeProductDetail.propTypes = {
    onClose: PropTypes.func.isRequired,
}