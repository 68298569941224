import React from 'react';

const RadioGroup = ({ options, name, onChange }) => {
    return (
        <div className="row">
            {options.map((option, index) => {
                const inputId = `${name}-${index}`;
                return (
                    <div className="col-md-3" key={inputId}>
                        <input 
                            type="radio" 
                            id={inputId} 
                            name={name} 
                            value={option}
                            onChange={onChange} 
                            style={{ width: '16px', height: '16px', cursor: 'pointer' }} 
                        />
                        <label 
                            htmlFor={inputId} 
                            style={{ display: 'inline', marginLeft: '8px' }}
                        >
                            {option}
                        </label>
                    </div>
                );
            })}
        </div>
    );
};

export default RadioGroup;
