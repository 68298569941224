/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import trainingApi from "../../../../api/trainingApi";
import AlertBox from "../../../../components/alert";
import CommonHelper from "../../../../helpers/common";
import { Container, Row, Col, Button } from "react-bootstrap";
import * as FaIcon from "react-icons/fa";
import fbBanner from "../../../../assets/images/logo.svg";
import { FaSortUp, FaSortDown, FaMedal } from "react-icons/fa";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FaCheckCircle } from "react-icons/fa";

const initialAlert = {
    is_show: false,
    title: "",
    content: "",
    variant: "danger"
};

const currentMonth = (new Date()).getMonth() + 1;
const currentYear = (new Date()).getFullYear();

const initialHistory = {
    month: currentMonth,
    year: currentYear,
    id: "0"
}

const HistoryList = () => {
    const [histories, setHistories] = useState([]);
    const [historiesDetail, setHistoriesDetail] = useState([]);
    const [alertData, setAlertData] = useState(initialAlert);
    const [status, setStatus] = useState(false);
    const [historyParams, setHistoryParams] = useState(initialHistory);
    const [sortOrder, setSortOrder] = useState("default");

    useEffect(() => {
        fetchQuestions();
    }, []);

    const fetchQuestions = async () => {
        try {
            const response = await trainingApi.get_history_exam(historyParams.month, historyParams.year, historyParams.id);
            const responseDetail = await trainingApi.get_history_exam_detail(historyParams.month, historyParams.year, historyParams.id);
            if (response.code === 200 && responseDetail.code === 200) {
                setHistories(response.data);
                setHistoriesDetail(responseDetail.data);
            } else {
                setAlertData((prev) => ({
                    ...prev,
                    title: "Lỗi",
                    content: "Tải dữ liệu lịch sử thi không thành công. ",
                    is_show: true,
                    variant: "danger"
                }));
            }
        } catch (error) {
            setAlertData((prev) => ({
                ...prev,
                title: "Lỗi",
                content: "Oops... Đã có lỗi xảy ra, vui lòng kiểm tra lại!",
                is_show: true,
                variant: "danger"
            }));
            console.log(error);
        }
    };

    const handleSort = () => {
        const sortedHistories = [...historiesDetail].sort((a, b) => {
            if (sortOrder === "default") {
                if (b.score === a.score) {
                    const durationA = new Date(a.endDate) - new Date(a.beginDate);
                    const durationB = new Date(b.endDate) - new Date(b.beginDate);
                    return durationA - durationB;
                }
                return b.score - a.score;
            } else {
                const dateA = new Date(a.beginDate);
                const dateB = new Date(b.beginDate);
                return dateB - dateA;
            }
        });
        setHistoriesDetail(sortedHistories);
        setSortOrder(sortOrder === "default" ? "toprank" : "default");
    };

    const renderScore = (score, index) => {
        let icon = null;
        if (sortOrder === "toprank") {
            if (index === 0) icon = <FaMedal style={{ color: 'gold', marginLeft: '5px', fontSize: '30px' }} />;
            if (index === 1) icon = <FaMedal style={{ color: 'silver', marginLeft: '5px', fontSize: '30px' }} />;
            if (index === 2) icon = <FaMedal style={{ color: 'bronze', marginLeft: '5px', fontSize: '30px' }} />;
        }
        return <>{score}{icon}</>;
    };

    return (
        <>
            <div className="clearfix" style={{ marginTop: "75px" }}></div>
            <div className="container" >
                <div className="row row-frm">
                    <div className="row">
                        <div className="col col-md-4">
                            {
                                status === false ? <button className="btn btn-success" style={{borderRadius: '0px'}} onClick={() => setStatus(!status)}>Xem chi tiết</button> :
                                    <button className="btn btn-primary" style={{borderRadius: '0px'}} onClick={() => setStatus(!status)}>Xem tổng quan</button>
                            }
                        </div>
                    </div>
                    <h1 className="text-center">Lịch sử thi đại lý FUBON</h1>
                    <div className="table-container" style={{ overflowY: 'auto', maxHeight: '600px' }}>
                        <table className="table table-striped table-bordered table-hover">
                            {
                                status ? (
                                    <>
                                        <thead className="center-vertically" style={{ backgroundColor: '#3498db', position: 'sticky', top: '-2px' }}>
                                            <tr style={{ verticalAlign: 'middle', color: 'white' }}>
                                                <th style={{ width: '5%' }}>#</th>
                                                <th style={{ width: '15%' }}>Tài khoản người thi</th>
                                                <th style={{ width: '10%' }}>Số CCCD</th>
                                                <th style={{ width: '10%' }} onClick={handleSort}>
                                                    Điểm {sortOrder === "default"
                                                        ? <FaSortUp style={{ width: '30px', height: '30px', cursor: 'pointer' }} />
                                                        : <FaSortDown style={{ width: '30px', height: '30px', cursor: 'pointer' }} />}
                                                </th>
                                                <td style={{ width: '10%', fontWeight: '700' }}>Đánh giá</td>
                                                <th style={{ width: '10%' }}>Chủ đề thi</th>
                                                <th style={{ width: '10%' }}>Thời gian bắt đầu</th>
                                                <th style={{ width: '10%' }}>Thời gian kết thúc</th>
                                                <th style={{ width: '10%' }}>Thời gian đã thi</th>
                                                <th style={{ width: '10%' }}>KQ</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {historiesDetail.length === 0 ? (
                                                <tr>
                                                    <td colSpan="5" style={{ textAlign: 'center' }}>Không có dữ liệu phù hợp</td>
                                                </tr>
                                            ) :
                                                (
                                                    historiesDetail.map((history, index) => (
                                                        <>
                                                            <tr key={history.id} style={{ verticalAlign: 'middle', textAlign: 'center' }}>
                                                                <td>{history.id}</td>
                                                                <td style={{ textAlign: 'center', fontWeight: '500' }}>{history.name} (Thi lần {history.numberOfTests})</td>
                                                                <td>{history.userName}</td>
                                                                <td>
                                                                    {history.score ? renderScore(history.score, index, history.quesTotal) : "Không có điểm"}
                                                                </td>
                                                                <td>
                                                                    {history.score ? (
                                                                        history.quesTotal ? (
                                                                            `${history.score}/${history.quesTotal} Câu, Được ${(history.score / history.quesTotal * 100).toFixed()}%`
                                                                        ) : (
                                                                            <p style={{ fontStyle: 'italic' }}>Chưa có thông tin</p>
                                                                        )
                                                                    ) : (
                                                                        "Không có điểm"
                                                                    )}
                                                                </td>
                                                                <td>
                                                                    {history.topicName ? history.topicName : <p style={{ fontStyle: 'italic' }}>Tất cả</p>}
                                                                </td>
                                                                <td>{history.beginDate ? CommonHelper.formatDateExam(history.beginDate) : ""}</td>
                                                                <td>{history.endDate ? CommonHelper.formatDateExam(history.endDate) : ""}</td>
                                                                <td>
                                                                    {history.beginDate && history.endDate ? (
                                                                        (() => {
                                                                            const beginDate = new Date(history.beginDate);
                                                                            const endDate = new Date(history.endDate);
                                                                            const diffInMs = endDate - beginDate;
                                                                            const diffInMinutes = Math.floor(diffInMs / 1000 / 60);
                                                                            return diffInMinutes + " phút";
                                                                        })()
                                                                    ) : ""}
                                                                </td>
                                                                <td style={{
                                                                    backgroundColor: history.score / history.quesTotal * 100 >= 75 ? 'lightgreen' : '',
                                                                    fontWeight: history.score / history.quesTotal * 100 >= 75 ? 'bold' : '',
                                                                    color: history.score / history.quesTotal * 100 >= 75 ? 'green' : ''
                                                                }}>
                                                                    {history.score / history.quesTotal * 100 >= 75 ? <FaCheckCircle style={{ marginRight: '5px' }} /> : ''}
                                                                    {history.score / history.quesTotal * 100 >= 75 ? 'Đạt' : 'Không đạt'}
                                                                </td>
                                                            </tr>
                                                        </>
                                                    ))
                                                )
                                            }
                                        </tbody>
                                    </>
                                ) : (
                                    <>
                                        <thead className="center-vertically" style={{ backgroundColor: '#3498db', position: 'sticky', top: '-2px' }}>
                                            <tr style={{ verticalAlign: 'middle', color: 'white' }}>
                                                <th style={{ width: '20%' }}>Tài khoản người thi</th>
                                                <th style={{ width: '7%' }}>Số lần thi</th>
                                                <th style={{ width: '53%' }}>Số điểm các lần thi</th>
                                                <th style={{ width: '20%' }}>Tổng thời gian thi (phút)</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {histories.length === 0 ? (
                                                <tr>
                                                    <td colSpan="4" style={{ textAlign: 'center' }}>Không có dữ liệu phù hợp</td>
                                                </tr>
                                            ) :
                                                (
                                                    histories.map((history, index) => (
                                                        <>
                                                            <tr key={index} style={{ verticalAlign: 'middle', textAlign: 'center' }}>
                                                                <td style={{ textAlign: 'center', fontWeight: '500' }}>{history.name}</td>
                                                                <td>
                                                                    {history.numberOfTests
                                                                        || history.numberOfTests === "0" ? history.numberOfTests
                                                                        : "Chưa thi lần nào"}
                                                                </td>
                                                                <td>
                                                                    {history.score
                                                                        ? history.score.split(', ').map((score, index) => `Lần ${index + 1}: ${score}đ`).join("; ")
                                                                        : "Không có điểm"}
                                                                </td>
                                                                <td >{history.totalTime ? history.totalTime : "0"}</td>
                                                            </tr>
                                                        </>
                                                    ))
                                                )
                                            }
                                        </tbody>
                                    </>
                                )
                            }
                        </table>
                    </div>
                </div>
                <AlertBox data={alertData} onChange={(e) => { setAlertData((prev) => ({ ...prev, is_show: e })) }} />
            </div>
        </>
    );
}

export default HistoryList;
