import React, { useLayoutEffect, useState, useEffect, useRef } from 'react'
import * as FaIcons from 'react-icons/fa'
import CommonHelper from '../../helpers/common';
import { useLoaderData, useMatches } from "react-router-dom";
import addMonths from "date-fns/addMonths";
import addDays from "date-fns/addDays";
import addYears from "date-fns/addYears";
import TextInput from '../../components/text-input';
import AutoComplete from '../../components/auto-complete';
import DateInput from '../../components/date-input';
import ShortDateInput from '../../components/short-date-input';
import Loading from '../../components/loading';
import AlertBox from '../../components/alert';
import NumberInput from '../../components/number-input';
import { Accordion } from 'react-bootstrap';
import PersonalInfo from './component/personal-info';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import { format, locale } from 'date-fns';
import InsuredPerson from './component/insured-persons';
import Packages from './component/packages';
import paiApi from '../../api/paiApi';
import OnlineApi from "../../api/onlineApi";
import PaiConfirmation from './component/confirmation';
import ModalPayment from './component/modal-payment';
import Nationality from '../../nationality';
import Occupation from '../../occupation';
import ModalUpload from './component/modal-upload';
import ValidateMessage from '../../components/validate-msg';
import PaiProgressBar from './component/progress-bar';
import ModalExclusiveOccupation from './component/modal-exclusive-occupation';
import ModalReject from './component/modal-reject';


const flagLoading = false;

export async function loader({ params }) {
    var data = {};
    if (params.id !== undefined && CommonHelper.is_not_empty(params.id)) {
        var orderNo = params.id
        data = { ...data, orderNo };
    }
    return data;
}
const initial_person_info = {
    "idx": 0,
    "id": 0,
    "orderNo": "",
    "fullName": "",
    "idCard": "",
    "dob": "",
    "gender": 1,
    "phone": "",
    "email": "",
    "occupation": "",
    "nationality": "",
    "relationship": "0",
    "address": "",
    "occupation": "",
    "isForeigner": false,
    "createdBy": "",
    "createdDate": "",
    "modifiedBy": "",
    "modifiedDate": "",
    "insPackage": "",
    "objPackage": {},
    "action": "new", //[new, edit]
};
const steps = [{ code: 1 }, { code: 2 }, { code: 3 }, { code: 4 }, { code: 5 }]
const pa_packages = [
    {
        "code": "PAIPKG01",
        "title": "ĐỒNG",
        "location": "Toàn cầu",
        "death_si": 40000000,
        "partial_damage": "Chi tiết tại Bảng tỷ lệ thương tật",
        "medical_fee": 0,
        "daily_allowance": 0,
        "missing": 40000000,
        "toxic": true,
        "funeral_allowance": 0,
        "sos_service": 0,
        "premium": 30000,
        "bg_color": "#FFF",
        //"bg_color": "#016a70",
        "text_color": "",
        "level": 0,
        "suggestion": false,
        "condition": [
            { "code": "AAH0197", "name_en": "War And Civil War Exclusion Clause.", "name_vn": "Điều khoản loại trừ chiến tranh và nội chiến  (LRMA G51)." },
            { "code": "AAH0305", "name_en": "Terrorism Exclusion Endorsement.", "name_vn": "Điều khoản bổ sung loại trừ khủng bố." },
            { "code": "AAH0364", "name_en": "Sanction Limitation And Exclusion Clause.", "name_vn": "Điều khoản loại trừ và hạn chế do lệnh cấm vận." },
            { "code": "AAH0377", "name_en": "Automatic Reinstatement of validity of policy.", "name_vn": "Điều khoản tự động phục hồi hiệu lực đơn bảo hiểm." },
            { "code": "AAH0384", "name_en": "Premium Payment Warranty – 30 Days.", "name_vn": "Điều khoản bảo đảm thanh toán phí – 30 ngày." },
            { "code": "AAHTAC", "name_en": "As Per Fubon Insurance Vietnam Co., Ltd Original Wording.", "name_vn": "Theo quy tắc bảo hiểm của Công ty TNHH Bảo hiểm Fubon (Việt Nam)" },
            { "code": "AAH0395", "name_en": "Effective Date Of The Insurance Contract.", "name_vn": "Thời Điểm Có Hiệu Lực Của Hợp Đồng Bảo Hiểm." },
            { "code": "AAH0396A & AAH0396B", "name_en": "Conditions For A Person To Be Insured.", "name_vn": "Điều Kiện Tham Gia Bảo Hiểm Đối Với Người Được Bảo Hiểm." },
            { "code": "AAH0393", "name_en": "Beneficiary Clause.", "name_vn": "Điều khoản người thụ hưởng." },
            { "code": "AAH0219", "name_en": "Suffocation And Inhalation Or Poisonous Fumes/ Gas Extension.", "name_vn": "Điều khoản mở rộng cho nhiễm độc khí ga." },
        ],
    },
    {
        "code": "PAIPKG02",
        "title": "BẠC",
        "location": "Toàn cầu",
        "death_si": 40000000,
        "partial_damage": "Chi tiết tại Bảng tỷ lệ thương tật",
        "medical_fee": 15000000,
        "daily_allowance": 100000,
        "missing": 40000000,
        "toxic": true,
        "funeral_allowance": 5000000,
        "sos_service": 0,
        "premium": 150000,
        "bg_color": "#FFF",
        //"bg_color": "#ffffdd",
        "text_color": "",
        "level": 0,
        "suggestion": false,
        "condition": [
            { "code": "AAH0197", "name_en": "War And Civil War Exclusion Clause.", "name_vn": "Điều khoản loại trừ chiến tranh và nội chiến  (LRMA G51)." },
            { "code": "AAH0305", "name_en": "Terrorism Exclusion Endorsement.", "name_vn": "Điều khoản bổ sung loại trừ khủng bố." },
            { "code": "AAH0364", "name_en": "Sanction Limitation And Exclusion Clause.", "name_vn": "Điều khoản loại trừ và hạn chế do lệnh cấm vận." },
            { "code": "AAH0377", "name_en": "Automatic Reinstatement of validity of policy.", "name_vn": "Điều khoản tự động phục hồi hiệu lực đơn bảo hiểm." },
            { "code": "AAH0384", "name_en": "Premium Payment Warranty – 30 Days.", "name_vn": "Điều khoản bảo đảm thanh toán phí – 30 ngày." },
            { "code": "AAHTAC", "name_en": "As Per Fubon Insurance Vietnam Co., Ltd Original Wording.", "name_vn": "Theo quy tắc bảo hiểm của Công ty TNHH Bảo hiểm Fubon (Việt Nam)." },
            { "code": "AAH0395", "name_en": "Effective Date Of The Insurance Contract.", "name_vn": "Thời Điểm Có Hiệu Lực Của Hợp Đồng Bảo Hiểm." },
            { "code": "AAH0396A & AAH0396B", "name_en": "Conditions For A Person To Be Insured.", "name_vn": "Điều Kiện Tham Gia Bảo Hiểm Đối Với Người Được Bảo Hiểm." },
            { "code": "AAH0393", "name_en": "Beneficiary Clause.", "name_vn": "Điều khoản người thụ hưởng." },
            { "code": "AAH0219", "name_en": "Suffocation And Inhalation Or Poisonous Fumes/ Gas Extension.", "name_vn": "Điều khoản mở rộng cho nhiễm độc khí ga." },
            { "code": "AAH0270", "name_en": "Daily Allowance per Person (maximum: 60 days/ year).", "name_vn": "Trợ cấp 01 ngày cho 1 người (tối đa: 60 ngày/ năm)." },
            { "code": "AAH0370", "name_en": "Burial Allowance.", "name_vn": "Trợ cấp chôn cất." },
        ],
    },
    {
        "code": "PAIPKG03",
        "title": "VÀNG",
        "location": "Toàn cầu",
        "death_si": 100000000,
        "partial_damage": "Chi tiết tại Bảng tỷ lệ thương tật",
        "medical_fee": 30000000,
        "daily_allowance": 200000,
        "missing": 100000000,
        "toxic": true,
        "funeral_allowance": 10000000,
        "sos_service": 0,
        "premium": 430000,
        "bg_color": "#FFF",
        //"bg_color": "#b4c6e7",
        "text_color": "",
        "level": 1,
        "suggestion": false,
        "condition": [
            { "code": "AAH0197", "name_en": "War And Civil War Exclusion Clause.", "name_vn": "Điều khoản loại trừ chiến tranh và nội chiến  (LRMA G51)." },
            { "code": "AAH0305", "name_en": "Terrorism Exclusion Endorsement.", "name_vn": "Điều khoản bổ sung loại trừ khủng bố." },
            { "code": "AAH0364", "name_en": "Sanction Limitation And Exclusion Clause.", "name_vn": "Điều khoản loại trừ và hạn chế do lệnh cấm vận." },
            { "code": "AAH0377", "name_en": "Automatic Reinstatement of validity of policy.", "name_vn": "Điều khoản tự động phục hồi hiệu lực đơn bảo hiểm." },
            { "code": "AAH0384", "name_en": "Premium Payment Warranty – 30 Days.", "name_vn": "Điều khoản bảo đảm thanh toán phí – 30 ngày." },
            { "code": "AAHTAC", "name_en": "As Per Fubon Insurance Vietnam Co., Ltd Original Wording.", "name_vn": "Theo quy tắc bảo hiểm của Công ty TNHH Bảo hiểm Fubon (Việt Nam)." },
            { "code": "AAH0395", "name_en": "Effective Date Of The Insurance Contract.", "name_vn": "Thời Điểm Có Hiệu Lực Của Hợp Đồng Bảo Hiểm." },
            { "code": "AAH0396A & AAH0396B", "name_en": "Conditions For A Person To Be Insured.", "name_vn": "Điều Kiện Tham Gia Bảo Hiểm Đối Với Người Được Bảo Hiểm." },
            { "code": "AAH0393", "name_en": "Beneficiary Clause.", "name_vn": "Điều khoản người thụ hưởng." },
            { "code": "AAH0219", "name_en": "Suffocation And Inhalation Or Poisonous Fumes/ Gas Extension.", "name_vn": "Điều khoản mở rộng cho nhiễm độc khí ga." },
            { "code": "AAH0270", "name_en": "Daily Allowance per Person (maximum: 60 days/ year).", "name_vn": "Trợ cấp 01 ngày cho 1 người (tối đa: 60 ngày/ năm)." },
            { "code": "AAH0370", "name_en": "Burial Allowance.", "name_vn": "Trợ cấp chôn cất." },
        ],
    },
    {
        "code": "PAIPKG04",
        "title": "BẠCH KIM",
        "location": "Toàn cầu",
        "death_si": 200000000,
        "partial_damage": "Chi tiết tại Bảng tỷ lệ thương tật",
        "medical_fee": 50000000,
        "daily_allowance": 200000,
        "missing": 200000000,
        "toxic": true,
        "funeral_allowance": 10000000,
        "sos_service": 0,
        "premium": 750000,
        //"bg_color": "#FFF",
        "bg_color": "#f8ff99",
        "text_color": "",
        "level": 1,
        "suggestion": true,
        "condition": [
            { "code": "AAH0197", "name_en": "War And Civil War Exclusion Clause.", "name_vn": "Điều khoản loại trừ chiến tranh và nội chiến  (LRMA G51)." },
            { "code": "AAH0305", "name_en": "Terrorism Exclusion Endorsement.", "name_vn": "Điều khoản bổ sung loại trừ khủng bố." },
            { "code": "AAH0364", "name_en": "Sanction Limitation And Exclusion Clause.", "name_vn": "Điều khoản loại trừ và hạn chế do lệnh cấm vận." },
            { "code": "AAH0377", "name_en": "Automatic Reinstatement of validity of policy.", "name_vn": "Điều khoản tự động phục hồi hiệu lực đơn bảo hiểm." },
            { "code": "AAH0384", "name_en": "Premium Payment Warranty – 30 Days.", "name_vn": "Điều khoản bảo đảm thanh toán phí – 30 ngày." },
            { "code": "AAHTAC", "name_en": "As Per Fubon Insurance Vietnam Co., Ltd Original Wording.", "name_vn": "Theo quy tắc bảo hiểm của Công ty TNHH Bảo hiểm Fubon (Việt Nam)." },
            { "code": "AAH0395", "name_en": "Effective Date Of The Insurance Contract.", "name_vn": "Thời Điểm Có Hiệu Lực Của Hợp Đồng Bảo Hiểm." },
            { "code": "AAH0396A & AAH0396B", "name_en": "Conditions For A Person To Be Insured.", "name_vn": "Điều Kiện Tham Gia Bảo Hiểm Đối Với Người Được Bảo Hiểm." },
            { "code": "AAH0393", "name_en": "Beneficiary Clause.", "name_vn": "Điều khoản người thụ hưởng." },
            { "code": "AAH0219", "name_en": "Suffocation And Inhalation Or Poisonous Fumes/ Gas Extension.", "name_vn": "Điều khoản mở rộng cho nhiễm độc khí ga." },
            { "code": "AAH0270", "name_en": "Daily Allowance per Person (maximum: 60 days/ year).", "name_vn": "Trợ cấp 01 ngày cho 1 người (tối đa: 60 ngày/ năm)." },
            { "code": "AAH0370", "name_en": "Burial Allowance.", "name_vn": "Trợ cấp chôn cất." },
        ],
    },
    {
        "code": "PAIPKG05",
        "title": "KIM CƯƠNG",
        "location": "Toàn cầu",
        "death_si": 500000000,
        "partial_damage": "Chi tiết tại Bảng tỷ lệ thương tật",
        "medical_fee": 100000000,
        "daily_allowance": 200000,
        "missing": 500000000,
        "toxic": true,
        "funeral_allowance": 10000000,
        "sos_service": 0,
        "premium": 1500000,
        "bg_color": "#FFF",
        //"bg_color": "#d3de32",
        "text_color": "",
        "level": 1,
        "suggestion": false,
        "condition": [
            { "code": "AAH0197", "name_en": "War And Civil War Exclusion Clause.", "name_vn": "Điều khoản loại trừ chiến tranh và nội chiến  (LRMA G51)." },
            { "code": "AAH0305", "name_en": "Terrorism Exclusion Endorsement.", "name_vn": "Điều khoản bổ sung loại trừ khủng bố." },
            { "code": "AAH0364", "name_en": "Sanction Limitation And Exclusion Clause.", "name_vn": "Điều khoản loại trừ và hạn chế do lệnh cấm vận." },
            { "code": "AAH0377", "name_en": "Automatic Reinstatement of validity of policy.", "name_vn": "Điều khoản tự động phục hồi hiệu lực đơn bảo hiểm." },
            { "code": "AAH0384", "name_en": "Premium Payment Warranty – 30 Days.", "name_vn": "Điều khoản bảo đảm thanh toán phí – 30 ngày." },
            { "code": "AAHTAC", "name_en": "As Per Fubon Insurance Vietnam Co., Ltd Original Wording.", "name_vn": "Theo quy tắc bảo hiểm của Công ty TNHH Bảo hiểm Fubon (Việt Nam)." },
            { "code": "AAH0395", "name_en": "Effective Date Of The Insurance Contract.", "name_vn": "Thời Điểm Có Hiệu Lực Của Hợp Đồng Bảo Hiểm." },
            { "code": "AAH0396A & AAH0396B", "name_en": "Conditions For A Person To Be Insured.", "name_vn": "Điều Kiện Tham Gia Bảo Hiểm Đối Với Người Được Bảo Hiểm." },
            { "code": "AAH0393", "name_en": "Beneficiary Clause.", "name_vn": "Điều khoản người thụ hưởng." },
            { "code": "AAH0219", "name_en": "Suffocation And Inhalation Or Poisonous Fumes/ Gas Extension.", "name_vn": "Điều khoản mở rộng cho nhiễm độc khí ga." },
            { "code": "AAH0270", "name_en": "Daily Allowance per Person (maximum: 60 days/ year).", "name_vn": "Trợ cấp 01 ngày cho 1 người (tối đa: 60 ngày/ năm)." },
            { "code": "AAH0370", "name_en": "Burial Allowance.", "name_vn": "Trợ cấp chôn cất." },
        ],
    },
];
const initialAlert = {
    is_show: false,
    title: "",
    content: "",
    variant: "danger"
};
const initial = {
    veh_pol_no: "",
    veh_certificate_no: "",
    veh_org_sc_code: "PAI",
    veh_sc_code: "PAI",
    veh_business: "N",
    veh_type: "",
    veh_type_name: "",
    veh_seat: "0",
    veh_weight: "0",
    veh_reg_no: "",
    veh_chassis_no: "",
    veh_engine_no: "",
    veh_month: "1",
    veh_fm_dt: null,
    veh_to_dt: null,
    veh_issued_date: null,
    veh_handler_code: "",
    veh_handler_name: "",
    veh_handler_name_vi: "",
    veh_is_pax: false,
    veh_pax: "",
    veh_customer_id: 0,
    veh_customer: "",
    veh_customer_idcard: "",
    veh_phone: "",
    veh_email: "",
    veh_address: "",
    "veh_province_code": "",
    "veh_province_name": "",
    "veh_district_code": "",
    "veh_district_name": "",
    veh_issue_invoice: false,
    inv_tax: "",
    inv_company_name: "",
    inv_address: "",
    veh_origin_premium: 0,
    veh_tax_ratio: 0,
    veh_tax_amt: 0,
    veh_pax_premium: 0,
    veh_premium: 0,
    veh_si_amt1: 0,
    veh_si_amt2: 0,
    veh_si_amt3: 0,
    veh_si_amt4: 0,
    veh_is_truck: false,
    veh_url: window.location.href,
    veh_order_no: "",
    veh_get_cert_via: 1,
    veh_sob_code: "",
    veh_sob_name: "",
    veh_is_agent: false,
    veh_agency_code: "",
    veh_agency_name: "",
    veh_broker_code: "",
    veh_broker_name: "",
    pol_voucher_code: "",
    veh_gender: 1,
    veh_customer_dob: "",
    veh_time_eff: "000000",
    veh_rep_company: "",
    veh_tax_code: "",
    veh_occupation_code: "",
    veh_occupation_name: "",
    veh_national_code: "",
    veh_national_name: "",
    veh_job_type: "",
    veh_job_desc: "",
    veh_ex_occ_confirm: false,
    is_confirm: false,
    aml_total_score: 0,
    aml_risk_score: 0,
    aml_checked: false,
    status: 1,
    user_type: 1,
    pai_package: {}
};
function PaInsurance() {
    const _title = "BẢO HIỂM TAI NẠN CÁ NHÂN";

    const [state, setState] = useState(initial);
    const [state_person, setStatePerson] = useState(initial_person_info);
    //const [state_lst_person, setStateLstPerson] = useState([]);
    const [alertData, setAlertData] = useState(initialAlert);
    const [{ stateError, stateErrorMsg }, setStateError] = useState({
        stateError: {
            veh_fm_dt: false,
            veh_pax: false,
            veh_customer: false,
            dob: false,
            veh_customer_idcard: false,
            veh_job_desc: false,
            veh_occupation: false,
            veh_nation: false,
            veh_phone: false,
            veh_email: false,
            veh_address: false,
            veh_rep_company: false,
            veh_prov: false,
            veh_dist: false,
            veh_tax_code: false,
            inv_tax: false,
            inv_company_name: false,
            inv_address: false,
            confirmOccDecline: false,
        },
        stateErrorMsg: {
            veh_fm_dt: "",
            veh_pax: "",
            veh_customer: "",
            dob: "",
            veh_customer_idcard: "",
            veh_job_desc: "",
            veh_occupation: "",
            veh_nation: "",
            veh_phone: "",
            veh_email: "",
            veh_address: "",
            veh_rep_company: "",
            veh_prov: "",
            veh_dist: "",
            veh_tax_code: "",
            inv_tax: "",
            inv_company_name: "",
            inv_address: "",
            confirmOccDecline: "",
        }
    });
    const [{ state_lst_person, lst_prov, lst_dist, lst_nation, lst_occupation, lst_sob, lst_agency }, setList] = useState({
        lst_prov: [],
        lst_dist: [],
        state_lst_person: [],
        lst_nation: Nationality,
        lst_occupation: [],
        lst_sob: [],
        lst_agency: []
    })
    const [lockField, setLockField] = useState({
        veh_customer: false,
        veh_phone: false,
        veh_email: false,
        veh_address: false,
        veh_handler_code: false,
        veh_customer_idcard: false,
        veh_job_desc: true,
        veh_sob: false,
        veh_agent: false,
    });
    const [{ fm_dt, to_dt, dob, curr_step, show_personal_info, minDOB, maxDOB, user, show_ex_occ, show_reject, msg_reject,
        show_payment, show_upload, provSel, distSel, nationalSel, occupationSel, sobSel, confirmOccDecline, agentSel }, setSelected] = useState({
            fm_dt: null,
            to_dt: null,
            dob: null,
            curr_step: steps[0],
            show_personal_info: false,
            show_ex_occ: false,
            show_reject: false,
            show_payment: false,
            show_upload: false,
            minDOB: addYears(new Date(), -65),
            maxDOB: addYears(new Date(), -18),
            provSel: [],
            distSel: [],
            occupationSel: [],
            nationalSel: [],
            sobSel: [],
            user: null,
            msg_reject: "",
            confirmOccDecline: 0,
            agentSel: [],
        })
    const [{ loading }, setShow] = useState({
        loading: flagLoading
    });
    const policy = useLoaderData();
    const select_pkg = (e) => {
        //validate_control(e);
        var _pax = 0;
        var _premium = 0;
        var _str_pax = state.veh_pax;
        if (CommonHelper.is_not_empty(_str_pax)) {
            _pax = parseInt(_str_pax);
        }
        if (e != null) {
            _premium = e.premium;
            var _pkg = {
                code: e.code,
                pkg_name: e.title,
                pkg_location: e.location,
                si: e.death_si,
                si_medical_fee: e.medical_fee,
                si_daily_allowance: e.daily_allowance,
                si_missing: e.missing,
                si_funeral_allowance: e.funeral_allowance,
                si_sos: e.sos_service,
                premium: e.premium,
                conditions: e.condition,
                //poison: e.toxic,
            };
        }
        setState((prev) => ({
            ...prev,
            veh_premium: _pax * _premium,
            pai_package: _pkg
        }))
    }
    useEffect(() => {
        document.title = "Fubon Insurance - " + _title;

        var _lst_occ = Occupation.filter(x => x.organization === false);
        _lst_occ.sort((a, b) => a.name.localeCompare(b.name));
        setList((prev) => ({ ...prev, lst_occupation: _lst_occ }))

        var resUser = CommonHelper.get_user();

        resUser.then((res) => {
            var veh_handler_code = "";
            var veh_handler_name = "";
            var veh_agency_code = "";
            var veh_agency_name = "";
            var veh_is_agent = false;
            var veh_customer = "";
            var veh_customer_idcard = "";
            var veh_phone = "";
            var veh_email = "";
            var veh_address = "";
            var lst_agency = [];
            var agentSel = [];
            if (res !== null) {
                veh_handler_code = res.handler_code;
                veh_handler_name = res.handler_name;
                if (res.user_type > 1) {
                    if (res.user_type == 2) {
                        get_agent_by_handler();
                    } else if (res.user_type == 3) {
                        veh_agency_code = res.code;
                        veh_agency_name = res.name;
                        lst_agency = [{ code: veh_agency_code, name: veh_agency_name }]
                        agentSel = lst_agency;
                        //console.log(agentSel)
                        veh_is_agent = true;
                        setLockField((prev) => ({ ...prev, veh_agent: true }));
                    }
                    get_sob("", res.user_type);
                } else {
                    veh_customer = res.name;
                    veh_address = res.address;
                    veh_email = res.email;
                    veh_phone = res.phone;
                    veh_customer_idcard = res.id_card;
                }
                setList((prev) => ({
                    ...prev,
                    lst_agency
                }))
                setSelected((prev) => ({
                    ...prev,
                    user: res,
                    agentSel,
                }));
                setState((prev) => ({
                    ...prev,
                    user_type: res.user_type,
                    veh_is_agent,
                    veh_handler_code,
                    veh_handler_name,
                    veh_agency_code,
                    veh_agency_name,
                    veh_customer,
                    veh_customer_idcard,
                    veh_phone,
                    veh_email,
                    veh_address,
                }));       
            }
        })
        get_provices();
    }, []);
    useEffect(() => {
        if (policy !== undefined && CommonHelper.is_not_empty(policy.orderNo)) {
            setShow((prev) => ({ ...prev, loading: true }));
            const response = paiApi.get_order(policy.orderNo);
            response.then((res) => {
                if (res != null && res.code === 200) {
                    if (res.data.status === 1 && CommonHelper.is_not_empty(res.data.token)) {
                        get_pai_payment_check_order(res.data.veh_order_no);
                    } else {
                        set_data(res.data);
                        setShow((prev) => ({ ...prev, loading: false }));
                    }
                }
            })
        }
    }, [policy])
    useEffect(() => {
        var veh_occupation_code = "", veh_occupation_name = "";
        var veh_national_code = "", veh_national_name = "";
        if (nationalSel.length > 0) {
            veh_national_code = nationalSel[0].code;
            veh_national_name = nationalSel[0].name;
        }
        if (occupationSel.length > 0) {
            veh_occupation_code = occupationSel[0].code;
            veh_occupation_name = occupationSel[0].name;
        }
        setState((prev) => ({
            ...prev,
            veh_national_code,
            veh_national_name,
            veh_occupation_code,
            veh_occupation_name,
        }))
    }, [nationalSel, occupationSel])
    useEffect(() => {
        var veh_agency_code = "";
        var veh_agency_name = "";
        if (agentSel.length > 0) {
            veh_agency_code = agentSel[0].code;
            veh_agency_name = agentSel[0].name;
            var arr = veh_agency_name.split(" - ");
            if (arr.length == 2) {
                veh_agency_name = arr[1]
            }
        }
        setState((prev) => ({
            ...prev, veh_agency_code, veh_agency_name
        }))
    }, [agentSel])
    useEffect(() => {
        var veh_sob_code = "";
        var veh_sob_name = "";
        if (sobSel.length > 0) {
            veh_sob_code = sobSel[0].code;
            veh_sob_name = sobSel[0].name;
        }
        setState((prev) => ({
            ...prev, veh_sob_code, veh_sob_name
        }))
    }, [sobSel])
    useEffect(() => {
        var inv_tax = "";
        var inv_company_name = "";
        var inv_address = "";
        if (state.veh_issue_invoice && state.veh_business == "Y") {
            inv_tax = state.veh_tax_code;
            inv_company_name = state.veh_customer;
            inv_address = state.veh_address;
        }
        setState((prev) => ({ ...prev, inv_tax, inv_company_name, inv_address }))
    }, [state.veh_issue_invoice])
    const set_data = (data) => {
        data.order_detail.veh_order_no = data.veh_order_no;
        data.order_detail.veh_sc_code = "PAI";
        data.order_detail.status = data.status;
        data.order_detail["cert_url"] = data.cert_url;
        data.order_detail["file_upload"] = data.file_upload
        var _lst_occ = []
        if (data.order_detail.veh_business === "Y") {
            _lst_occ = Occupation.filter(x => x.organization === true);
        } else {
            _lst_occ = Occupation.filter(x => x.organization === false);
        }
        var _occ_sel = _lst_occ.filter(x => x.code == data.order_detail.veh_occupation_code);
        setList((prev) => ({
            ...prev,
            lst_occupation: _lst_occ,
        }))
        if (CommonHelper.is_not_empty(data.order_detail.veh_fm_dt)) {
            var _time = data.order_detail.veh_fm_dt.substr(9);
            data.order_detail.veh_time_eff = _time;
        }
        var _dob = null;
        if (CommonHelper.is_not_empty(data.order_detail.veh_customer_dob)) {
            _dob = CommonHelper.parseStringToDate(data.order_detail.veh_customer_dob, "yyyyMMdd");
        }
        var provSel = [];
        if (CommonHelper.is_not_empty(data.order_detail.veh_province_code)) {
            provSel = [{ code: data.order_detail.veh_province_code, name: data.order_detail.veh_province_name }]
        }
        var distSel = [];
        if (CommonHelper.is_not_empty(data.order_detail.veh_district_code)) {
            distSel = [{ code: data.order_detail.veh_district_code, name: data.order_detail.veh_district_name }]
        }
        var sobSel = [];
        if (CommonHelper.is_not_empty(data.order_detail.veh_sob_code)) {
            sobSel = [{ code: data.order_detail.veh_sob_code, name: data.order_detail.veh_sob_name }];
        }
        var agentSel = [];
        if (CommonHelper.is_not_empty(data.order_detail.veh_agency_code)) {
            agentSel.push({ code: data.order_detail.veh_agency_code, name: data.order_detail.veh_agency_code + " - " + data.order_detail.veh_agency_name });
        }

        get_provices(null);
        if (CommonHelper.is_not_empty(data.order_detail.veh_district_code)) {
            get_districts(data.order_detail.veh_province_code, null);
        }
        setState(data.order_detail);
        setSelected((prev) => ({
            ...prev,
            fm_dt: CommonHelper.parseStringToDate(data.order_detail.veh_fm_dt, "yyyyMMdd HHmmss"),
            to_dt: CommonHelper.parseStringToDate(data.order_detail.veh_to_dt, "yyyyMMdd HHmmss"),
            curr_step: steps[2],
            dob: _dob,
            occupationSel: _occ_sel,
            nationalSel: Nationality.filter(x => x.code === data.order_detail.veh_national_code),
            confirmOccDecline: 1,
            sobSel,
            provSel,
            distSel,
            agentSel
        }))
        if (data.insured_persons != null && data.insured_persons.length > 0) {
            for (var i = 0; i < data.insured_persons.length; i++) {
                data.insured_persons[i]["idx"] = data.insured_persons[i].id;
                data.insured_persons[i].objPackage = JSON.parse(data.insured_persons[i].insPackage);
            }
            setList((prev) => ({ ...prev, state_lst_person: data.insured_persons }));
        }
    }
    const change_fm_dt = (val) => {
        var _fm_dt = null;
        var _to_dt = null;
        if (val != null) {
            _fm_dt = val;
            _to_dt = addYears(val, state.veh_month);
        }
        setStateError((prev) => ({
            ...prev,
            stateError: { ...stateError, veh_fm_dt: _fm_dt == null },
            stateErrorMsg: { ...stateErrorMsg, veh_fm_dt: (_fm_dt == null ? "required" : "") },
        }))
        setSelected((prev) => ({
            ...prev,
            fm_dt: _fm_dt,
            to_dt: _to_dt,
        }));
        setState((prev) => ({
            ...prev,
            veh_fm_dt: CommonHelper.formatToYYYYMMDDHHMMSS(_fm_dt),
            veh_to_dt: CommonHelper.formatToYYYYMMDDHHMMSS(_to_dt),
        }))
    };
    const change_dob = (val) => {
        setSelected((prev) => ({
            ...prev,
            dob: val,
        }));
        setStateError((prev) => ({
            ...prev,
            stateError: { ...stateError, dob: val == null },
            stateErrorMsg: { ...stateErrorMsg, dob: (val == null ? "required" : "") },
        }))
    };
    const change_occupation = (val) => {
        var _obj = { ...stateError };
        _obj.veh_occupation = false;
        var _objMsg = { ...stateErrorMsg };
        _objMsg.veh_occupation = "";
        if (val.length > 0) {
            if (val[0].exception) {
                _obj.veh_occupation = true;
                _objMsg.veh_occupation = "\"" + val[0].name + "\" thuộc ngành nghề không được bảo hiểm. Vui lòng chọn ngành nghề khác!";
            }
            var veh_job_desc = true;
            if (state.veh_business == "N") {
                if (val[0].code === "OCCR038_0100052") {
                    veh_job_desc = false;
                    _obj.veh_job_desc = true;
                    _objMsg.veh_job_desc = "required";
                } else {
                    _obj.veh_job_desc = false;
                    _objMsg.veh_job_desc = "";
                }
            } else {
                if (val[0].code === "OCCR038_1100025") {
                    veh_job_desc = false;
                    _obj.veh_job_desc = true;
                    _objMsg.veh_job_desc = "required";
                } else {
                    _obj.veh_job_desc = false;
                    _objMsg.veh_job_desc = "";
                }
            }
            setLockField((prev) => ({ ...prev, veh_job_desc }));
        } else {
            _obj.veh_occupation = true;
            _objMsg.veh_occupation = "required";
        }
        setSelected((prev) => ({
            ...prev,
            occupationSel: val
        }))
        setStateError((prev) => ({
            ...prev,
            stateError: _obj, //{ ...stateError, veh_occupation: val.length == 0 },
            stateErrorMsg: _objMsg, //{ ...stateErrorMsg, veh_occupation: (val.length == 0 ? "required" : "") },
        }))
    };
    const change_nation = (val) => {
        var _code = "";
        var _name = "";
        if (val.length > 0) {
            _code = val[0].code;
            _name = val[0].name
        }
        setState((prev) => ({
            ...prev,
            veh_national_code: _code,
            veh_national_name: _name,
        }))
        setSelected((prev) => ({
            ...prev,
            nationalSel: val
        }))
        setStateError((prev) => ({
            ...prev,
            stateError: { ...stateError, veh_nation: val.length == 0 },
            stateErrorMsg: { ...stateErrorMsg, veh_nation: (val.length == 0 ? "required" : "") },
        }))
    };
    const get_sob = (id, user_type) => {
        var res_sob = OnlineApi.get_sob(id);
        res_sob.then((data) => {
            if (data !== null && data.length > 0) {
                setList((prev) => ({
                    ...prev,
                    lst_sob: data,
                }));
                if (user_type == 3) {
                    setSelected((prev) => ({
                        ...prev,
                        sobSel: [data[0]]
                    }))
                    var veh_sob_code = "";
                    var veh_sob_name = "";
                    if (sobSel.length > 0) {
                        veh_sob_code = data[0].code;
                        veh_sob_name = data[0].name;
                    }
                    setState((prev) => ({
                        ...prev, veh_sob_code, veh_sob_name
                    }))
                }
            }
        })
    }
    const get_agent_by_handler = () => {
        var response = OnlineApi.get_agent_by_handler();
        response.then((res) => {
            if (res != null && res.length > 0) {
                var lst_agency = res.map((item) => { return { "code": item.code, "name": item.code + " - " + item.name } });

                setList((prev) => ({ ...prev, lst_agency }));
            }
        })
    }
    const change_sob = (val) => {
        var _code = "";
        var _name = "";
        if (val.length > 0) {
            _code = val[0].code;
            _name = val[0].name;
        }
        //get_districts(_code);
        setSelected((prev) => ({
            ...prev,
            sobSel: val
        }))
        setState((prev) => ({
            ...prev,
            veh_sob_code: _code,
            veh_sob_name: _name,
        }))
    };
    const get_provices = (obj) => {
        var res_provinces = OnlineApi.get_provinces();
        res_provinces.then((data) => {
            if (data !== null) {
                setList((prev) => ({
                    ...prev,
                    lst_prov: data,
                }));
                if (obj !== undefined && obj !== null) {
                    setSelected((prev) => ({
                        ...prev,
                        provSel: data.filter(x => x.code == obj.code)
                    }))
                }
            }
        })
    }
    const change_prov = (val) => {
        var _code = "";
        var _name = "";
        if (val.length > 0) {
            _code = val[0].code;
            _name = val[0].name;
        }
        get_districts(_code);
        setSelected((prev) => ({
            ...prev,
            provSel: val
        }))
        setState((prev) => ({
            ...prev,
            veh_province_code: _code,
            veh_province_name: _name,
        }))
        setStateError((prev) => ({
            ...prev,
            stateError: { ...stateError, veh_prov: val.length == 0 },
            stateErrorMsg: { ...stateErrorMsg, veh_prov: (val.length == 0 ? "required" : "") },
        }))
    };
    const change_dist = (val) => {
        var _code = "";
        var _name = "";
        if (val.length > 0) {
            _code = val[0].code;
            _name = val[0].name;
        }
        setSelected((prev) => ({
            ...prev,
            distSel: val
        }))
        setState((prev) => ({
            ...prev,
            veh_district_code: _code,
            veh_district_name: _name,
        }))
        setStateError((prev) => ({
            ...prev,
            stateError: { ...stateError, veh_dist: val.length == 0 },
            stateErrorMsg: { ...stateErrorMsg, veh_dist: (val.length == 0 ? "required" : "") },
        }))
    };
    const get_districts = (id, obj) => {
        setSelected((prev) => ({
            ...prev,
            distSel: [],
        }));
        var res_districts = OnlineApi.get_districts(id, "");
        res_districts.then((data) => {
            if (data === null) {
                data = [];
            }
            setList((prev) => ({
                ...prev,
                lst_dist: data,
            }));
            if (obj !== undefined && obj !== null) {
                setSelected((prev) => ({
                    ...prev,
                    distSel: data.filter(x => x.code == obj.code)
                }))
            }
        })
    };
    const handle_radio_check = (e) => {
        const { name, value } = e.target;

        if (name === "veh_gender") {
            setState((prevData) => ({
                ...prevData,
                [name]: CommonHelper.convertToInt(value),
            }));
        } else if (name == "confirm_ex_occ") {
            setSelected((prev) => ({ ...prev, confirmOccDecline: CommonHelper.convertToInt(value) }))

            var _obj = { ...stateError };
            var _objMsg = { ...stateErrorMsg };
            if (CommonHelper.convertToInt(value) == 1) {
                _obj.confirmOccDecline = false;
            } else {
                _obj.confirmOccDecline = true;
                _objMsg.confirmOccDecline = "Thông tin của Quý khách chưa phù hợp để cấp đơn bảo hiểm.";
            }
            setStateError((prev) => ({
                ...prev,
                stateError: _obj,
                stateErrorMsg: _objMsg,
            }))
        }

    };
    const handle_change = (e) => {
        const { name, value } = e.target;
        var _val = value;
        if (name === "veh_phone") {
            _val = value.replace(/[^0-9]/g, "");
        } else if (name === "veh_pax") {
            var _pax = 0;
            var _premium = 0;
            if (CommonHelper.is_not_empty(_val) && CommonHelper.convertToInt(_val) > 0) {
                _pax = CommonHelper.convertToInt(_val);
            } else {
                _val = '0';
            }
            if (state.pai_package != null) {
                _premium = state.pai_package.premium;
            }
            setState((prev) => ({
                ...prev,
                veh_premium: _pax * _premium,
            }))
        } else if (name === "veh_business") {
            var _lst_occ = [];
            if (_val === "Y") {
                _lst_occ = Occupation.filter(x => x.organization === true);
            } else {
                _lst_occ = Occupation.filter(x => x.organization === false);
            }
            _lst_occ.sort((a, b) => a.name.localeCompare(b.name));
            setList((prev) => ({
                ...prev,
                lst_occupation: _lst_occ,
            }))
            setState((prevData) => ({
                ...prevData,
                veh_customer: "",
                veh_customer_idcard: "",
                veh_phone: "",
                veh_email: "",
                veh_address: "",
            }));
            setSelected((prevData) => ({
                ...prevData,
                dob: null,
                nationalSel: [],
                occupationSel: [],
                provSel: [],
                distSel: [],
            }));
            reset_validate(e);
        /*} else if (name === "veh_job_type") {
            var _occ_sel = [];
            if (_val === "indoor") {
                _occ_sel = [...lst_occupation].filter(x => x.code === "OCCR038_191");
            } else if (_val === "outdoor") {
                _occ_sel = [...lst_occupation].filter(x => x.code === "OCCR038_246");
            }
            setSelected((prev) => ({
                ...prev, occupationSel: _occ_sel
            }))*/
        }
        setState((prevData) => ({
            ...prevData,
            [name]: _val,
        }));
        validate_control(e);
    };
    const validate_control = (e) => {
        const { name, value } = e.target;
        var _obj = { ...stateError };
        var _objMsg = { ...stateErrorMsg };
        var _arr = [];
        Object.keys(_obj).map((item) => {
            _arr.push(item);
        })
        if (_arr.indexOf(name) >= 0) {
            _obj[name] = false;
            _objMsg[name] = "";
            if (CommonHelper.is_empty(value)) {
                _obj[name] = true;
                _objMsg[name] = "required";
            } else {
                if (name === "veh_pax" && CommonHelper.convertToInt(value) < 1) {
                    _obj[name] = true;
                    _objMsg[name] = "invalid";
                }
                if (name === "veh_phone") {
                    var _val = value.replace(/[^0-9]/g, "");
                    var _len = _val.length;
                    if (_len < 10 || _len > 11) {
                        _obj[name] = true;
                        _objMsg[name] = "invalid";
                    }
                }
            }
            setStateError((prev) => ({
                ...prev,
                stateError: _obj,
                stateErrorMsg: _objMsg,
            }))
        }
    }
    const handle_check = (e) => {
        const { name, checked } = e.target;
        setState((prevData) => ({
            ...prevData,
            [name]: checked,
        }));
    };
    const next_step = () => {
        var _idx = steps.findIndex(x => x.code === curr_step.code);
        var flag = true;
        var _data = { ...state };
        var _objStateError = { ...stateError };
        var _objStateErrorMsg = { ...stateErrorMsg };
        if (curr_step.code === 1) {
            if (_data.pai_package["code"] === undefined) {
                flag = false;
                setAlertData((prev) => ({
                    ...prev,
                    title: "Lỗi",
                    content: "Quý khách chưa chọn gói bảo hiểm. Vui lòng chọn gói bảo hiểm!",
                    is_show: true,
                    variant: "danger"
                }))
            }
        } else if (curr_step.code === 2) {
            if (fm_dt === null) {
                flag = false;
                _objStateError.veh_fm_dt = true;
                _objStateErrorMsg.veh_fm_dt = "required";
            } else {
                setState((prev) => ({
                    ...prev,
                    veh_fm_dt: CommonHelper.formatToYYYYMMDDHHMMSS(fm_dt),
                    veh_to_dt: CommonHelper.formatToYYYYMMDDHHMMSS(to_dt),
                }))
            }
            setStateError((prev) => ({
                ...prev,
                stateError: _objStateError,
                stateErrorMsg: _objStateErrorMsg,
            }))
            if (CommonHelper.is_empty(_data.veh_customer)) {
                flag = false
            }
            if (CommonHelper.is_empty(_data.veh_phone)) {
                flag = false
            }
            if (CommonHelper.is_empty(_data.veh_email)) {
                flag = false
            }
            if (CommonHelper.is_empty(_data.veh_address) || provSel.length < 1) {
                flag = false
            } else {
                var _arr_address = [];
                if (distSel.length > 0) {
                    if (_data.veh_address.indexOf(distSel[0].name) < 0) {
                        _arr_address.push(distSel[0].name);
                    }
                    _data.veh_district_code = distSel[0].code;
                }
                if (provSel.length > 0) {
                    if (_data.veh_address.indexOf(provSel[0].name) < 0) {
                        _arr_address.push(provSel[0].name);
                    }
                    _data.veh_province_code = provSel[0].code;
                }
                _data.veh_address += ", " + _arr_address.join(", ");
            }
            if (_data.veh_issue_invoice) {
                if (CommonHelper.is_empty(_data.inv_tax)) {
                    flag = false;
                }
                if (CommonHelper.is_empty(_data.inv_company_name)) {
                    flag = false;
                }
                if (CommonHelper.is_empty(_data.inv_address)) {
                    flag = false;
                }
                setStateError((prev) => ({
                    ...prev,
                    inv_tax: CommonHelper.is_empty(_data.inv_tax),
                    inv_company_name: CommonHelper.is_empty(_data.inv_company_name),
                    inv_address: CommonHelper.is_empty(_data.inv_address),
                }))
            }
            var _len = [...state_lst_person].length;
            if (_len == 0) {
                flag = false;
                setAlertData((prev) => ({
                    ...prev,
                    title: "Lỗi",
                    content: "Vui lòng nhập danh sách người được bảo hiểm!",
                    is_show: true,
                    variant: "danger"
                }))
            } else {
                setState((prev) => ({
                    ...prev,
                    veh_pax: _len,
                }))
            }
            var _user = { ...user };
            if (_user != null && lst_sob != null && lst_sob.length > 0) {
                if (_user.user_type == 3) {
                    var sobSel = [[...lst_sob][0]]
                    setSelected((prev) => ({
                        ...prev,
                        sobSel
                    }))
                    setLockField((prev) => ({ ...prev, veh_sob: true }))
                    var veh_sob_code = sobSel[0].code;
                    var veh_sob_name = sobSel[0].name;
                    setState((prev) => ({
                        ...prev,
                        veh_sob_code,
                        veh_sob_name,
                    }))
                }
            }
            if (flag) {
                handle_save();
            }
        } else if (curr_step.code === 3) {
            if (flag) {
                get_payment({ ...state }.veh_order_no);
            }
        }
        if (flag) {
            if (_idx < steps.length - 1) {
                setSelected((prev) => ({
                    ...prev,
                    curr_step: steps[_idx + 1]
                }))
            }
        }
    };
    const back_step = () => {
        var _idx = steps.findIndex(x => x.code === curr_step.code);
        setSelected((prev) => ({
            ...prev,
            curr_step: steps[_idx - 1]
        }))
    };
    const validate_email_format = (e) => {
        const { name, value } = e.target;
        var _flag = CommonHelper.validate_email_format(value);
        setStateError((prev) => ({
            ...prev,
            stateError: { ...stateError, [name]: !_flag },
            stateErrorMsg: { ...stateErrorMsg, [name]: !_flag ? "Email không đúng định dạng. Vui lòng kiểm tra lại!" : "" },
        }))
    };
    const validate_idcard = (e) => {
        const { name, value } = e.target;
        var _flag = CommonHelper.check_idcard_valid(value);
        setStateError((prev) => ({
            ...prev,
            stateError: { ...stateError, [name]: !_flag },
            stateErrorMsg: { ...stateErrorMsg, [name]: !_flag ? "invalid" : "" },
        }))
    }
    const reset_validate = (e) => {
        const { name, value } = e.target;
        if (name === "veh_business") {
            var _obj = { ...stateError };
            //var _objMsg = {...stateErrorMsg};
            _obj.veh_customer = false;
            _obj.veh_occupation = false;
            _obj.veh_phone = false;
            _obj.veh_email = false;
            _obj.veh_prov = false;
            _obj.veh_address = false;
            _obj.veh_nation = false;
            if (value == "N") {
                _obj.dob = false;
                _obj.veh_customer_idcard = false;
                _obj.veh_job_type = false;
            } else {
                _obj.veh_tax_code = false;
                _obj.veh_rep_company = false;
            }
            setStateError((prev) => ({
                ...prev,
                stateError: _obj,
            }))
        }
    }
    const new_person = () => {
        var flag = validate_info_form();
        if (flag) {
            var _list = [...state_lst_person];
            setStatePerson((prev) => ({
                ...prev,
                "idx": _list.length + 1,
                "id": initial_person_info.id,
                "orderNo": state.veh_order_no,
                "fullName": initial_person_info.fullName,
                "idCard": initial_person_info.idCard,
                "dob": initial_person_info.dob,
                "gender": initial_person_info.gender,
                "phone": initial_person_info.phone,
                "email": initial_person_info.email,
                "nationality": initial_person_info.nationality,
                "occupation": initial_person_info.occupation,
                "relationship": initial_person_info.relationship,
                "address": initial_person_info.address,
                "occupation": initial_person_info.occupation,
                "isForeigner": initial_person_info.isForeigner,
                "objPackage": state.pai_package,
                "insPackage": initial_person_info.insPackage,
                "isInsuredPerson": false,
                "action": "new", //[new, edit]
            }));
            var _data = {};
            if (dob != null) {
                _data.veh_customer_dob = CommonHelper.formatToYYYYMMDD(dob);
            }
            if (nationalSel.length > 0) {
                _data.veh_national_code = nationalSel[0].code;
                _data.veh_national_name = nationalSel[0].name;
            }
            if (occupationSel.length > 0) {
                _data.veh_occupation_code = occupationSel[0].code;
                _data.veh_occupation_name = occupationSel[0].name;
            }
            setState((prev) => ({
                ...prev,
                veh_customer_dob: _data.veh_customer_dob,
                veh_national_code: _data.veh_national_code,
                veh_national_name: _data.veh_national_name,
                veh_occupation_code: _data.veh_occupation_code,
                veh_occupation_name: _data.veh_occupation_name,
            }))
            setSelected((prev) => ({ ...prev, show_personal_info: true }))
        }
    }
    const begin_upload = () => {
        var flag = validate_info_form();
        if (flag) {
            setSelected((prev) => ({ ...prev, show_upload: true }))
        }
    }
    const handle_close_personal_info = (flag, data) => {
        setSelected((prev) => ({
            ...prev,
            show_personal_info: flag
        }))
        if (data != null) {
            data.relationship = CommonHelper.is_empty(data.relationship) ? "0" : data.relationship;
            data.insPackage = JSON.stringify(data.objPackage);
            if (data.objPackage != null) {
                data.premium = data.objPackage.premium;
            }
            if (CommonHelper.is_not_empty(data.orderNo)) {
                var response = paiApi.post_insured_person(data);
                response.then((res) => {
                    if (res.code === 204) {
                        data.id = res.data.id;
                        reset_list(data);
                    }
                })
            } else {
                reset_list(data);
            }
        }
    }
    const reset_list = (model) => {
        if (model.action === "new") {
            var _new_lst = [...state_lst_person];
            _new_lst.push(model);
            setList((prev) => ({ ...prev, state_lst_person: _new_lst }));
        } else {
            var _arr = [...state_lst_person];
            if (_arr.length > 0) {
                var _idx = _arr.findIndex(x => x.idx == model.idx);
                if (_idx >= 0) {
                    _arr[_idx] = model;
                }
                setList((prev) => ({ ...prev, state_lst_person: _arr }));
            }
        }
    }
    const open_edit = (model) => {
        model.action = "edit";
        setStatePerson((prev) => ({
            ...prev,
            "idx": model.idx,
            "id": model.id,
            "orderNo": model.orderNo,
            "fullName": model.fullName,
            "idCard": model.idCard,
            "dob": model.dob,
            "gender": model.gender,
            "relationship": model.relationship,
            "phone": model.phone,
            "email": model.email,
            "national": model.national,
            "address": model.address,
            "occupation": model.occupation,
            "isForeigner": model.isForeigner,
            "insPackage": model.insPackage,
            "objPackage": model.objPackage,
            "action": "edit", //[new, edit]
        }));
        setSelected((prev) => ({ ...prev, show_personal_info: true }))
    }
    const open_delete = (model) => {
        if (model.id === 0) {
            setList((prev) => ({ ...prev, state_lst_person: state_lst_person.filter(a => a.idCard !== model.idCard) }));
        } else {
            var response = paiApi.delete_insured_person({ id: model.id });
            response.then((res) => {
                if (res != null && res.code === 204) {
                    setList((prev) => ({ ...prev, state_lst_person: state_lst_person.filter(a => a.idCard !== model.idCard) }));
                    //setStateLstPerson(state_lst_person.filter(a => a.idCard !== model.idCard));
                }
            })
        }
    }
    const handle_save = () => {
        var _data = { ...state };
        var _list = [...state_lst_person];
        if (dob != null) {
            _data.veh_customer_dob = CommonHelper.formatToYYYYMMDD(dob);
        }
        if (nationalSel.length > 0) {
            _data.veh_national_code = nationalSel[0].code;
            _data.veh_national_name = nationalSel[0].name;
        }
        if (occupationSel.length > 0) {
            _data.veh_occupation_code = occupationSel[0].code;
            _data.veh_occupation_name = occupationSel[0].name;
        }
        if (provSel.length > 0) {
            _data.veh_province_code = provSel[0].code;
            _data.veh_province_name = provSel[0].name;
        }
        if (distSel.length > 0) {
            _data.veh_district_code = distSel[0].code;
            _data.veh_district_name = distSel[0].name;
        }
        if (sobSel.length > 0) {
            _data.veh_sob = sobSel[0].code;
            _data.veh_sob_code = sobSel[0].code;
            _data.veh_sob_name = sobSel[0].name;
        }
        _data.veh_pax = _list.length + "";
        _data.veh_premium = 0;
        _list.forEach((item) => {
            _data.veh_premium += item.premium;
        })
        var _model = {
            veh_org_sc_code: _data.veh_org_sc_code,
            order_no: _data.veh_order_no,
            total_amt: _data.veh_premium,
            order_detail: _data,
            insured_persons: null
        };
        //Insert new order
        if (CommonHelper.is_empty(_data.veh_order_no)) {
            _model.insured_persons = _list;
        }
        setShow((prev) => ({
            ...prev,
            loading: true,
        }))
        var response = paiApi.post_pai_order(_model);
        response.then((res) => {
            setShow((prev) => ({
                ...prev,
                loading: false,
            }))
            if (res.code === 204) {
                setState((prev) => ({
                    ...prev,
                    veh_order_no: res.data.order_no
                }));
                check_aml_online(res.data.order_no);
                if (user != null && user.user_type > 1) {
                    setSelected((prev) => ({ ...prev, show_payment: true }));
                }
            }
        })
    }
    const get_payment = (order_no) => {
        setShow((prev) => ({
            ...prev,
            loading: true,
        }))
        var _model = { ...state };
        var response = paiApi.get_pai_payment({
            order_no: order_no,
            cancel_url: _model.veh_url,
        });
        response.then((res) => {
            setShow((prev) => ({
                ...prev,
                loading: false,
            }))
            if (res.code === 204) {
                if (res.data !== null && res.data.payment !== null) {
                    if (res.data.payment.result_code === "0000" && res.data.payment.result_data != null) {
                        window.location.href = res.data.payment.result_data.checkout_url;
                    }
                }
            }
        })
    }
    const validate_info_form = () => {
        var _data = { ...state };
        var flag = true;
        var _obj = { ...stateError };
        var _objMsg = { ...stateErrorMsg };

        if (CommonHelper.is_empty(_data.veh_customer)) {
            flag = false;
            _obj.veh_customer = true;
            _objMsg.veh_customer = "required";
        }
        if (CommonHelper.is_empty(_data.veh_phone)) {
            flag = false;
            _obj.veh_phone = true;
            _objMsg.veh_phone = "required";
        }
        if (CommonHelper.is_empty(_data.veh_email)) {
            flag = false;
            _obj.veh_email = true;
            _objMsg.veh_email = "required";
        }
        if (CommonHelper.is_empty(_data.veh_address)) {
            flag = false;
            _obj.veh_address = true;
            _objMsg.veh_address = "required";
        }
        if (provSel.length < 1) {
            flag = false;
            _obj.veh_prov = true;
            _objMsg.veh_prov = "required";
        }
        if (distSel.length < 1) {
            flag = false;
            _obj.veh_dist = true;
            _objMsg.veh_dist = "required";
        }
        if (occupationSel.length == 0) {
            flag = false;
            _obj.veh_occupation = true;
            _objMsg.veh_occupation = "required";
        }
        if (nationalSel.length == 0) {
            flag = false;
            _obj.veh_nation = true;
            _objMsg.veh_nation = "required";
        }
        if (confirmOccDecline == 0) {
            _obj.confirmOccDecline = true;
            _objMsg.confirmOccDecline = "required";
        } else if (confirmOccDecline == 2) {
            _obj.confirmOccDecline = true;
            _objMsg.confirmOccDecline = "Thông tin của Quý khách chưa phù hợp để cấp đơn bảo hiểm.";
        } else {
            _obj.confirmOccDecline = false;
        }
        if (_data.veh_business === "N") {
            if (CommonHelper.is_empty(_data.veh_customer_idcard)) {
                flag = false;
                _obj.veh_customer_idcard = true;
                _objMsg.veh_customer_idcard = "required";
            } else {
                if (!CommonHelper.check_idcard_valid(_data.veh_customer_idcard)) {
                    flag = false;
                    _obj.veh_customer_idcard = true;
                    _objMsg.veh_customer_idcard = "invalid";
                }
            }
            if (dob == null) {
                flag = false;
                _obj.dob = true;
                _objMsg.dob = "required";
            }            
        } else {
            if (CommonHelper.is_empty(_data.veh_rep_company)) {
                flag = false;
                _obj.veh_rep_company = true;
                _objMsg.veh_rep_company = "required";
            }
            if (CommonHelper.is_empty(_data.veh_tax_code)) {
                flag = false;
                _obj.veh_tax_code = true;
                _objMsg.veh_tax_code = "required";
            }
        }
        if (!lockField.veh_job_desc && CommonHelper.is_empty(_data.veh_job_desc)) {
            flag = false
        }
        if (_data.veh_issue_invoice) {
            if (CommonHelper.is_empty(_data.inv_company_name)) {
                flag = false;
                _obj.inv_company_name = true;
                _objMsg.inv_company_name = "required";
            }
            if (CommonHelper.is_empty(_data.inv_tax)) {
                flag = false;
                _obj.inv_tax = true;
                _objMsg.inv_tax = "required";
            }
            if (CommonHelper.is_empty(_data.inv_address)) {
                flag = false;
                _obj.inv_address = true;
                _objMsg.inv_address = "required";
            }
        }
        setStateError((prev) => ({
            ...prev,
            stateError: _obj,
            stateErrorMsg: _objMsg,
        }))
        return flag;
    }
    const get_pai_proposal = (flag, val) => {
        setSelected((prev) => ({ ...prev, show_payment: flag }));
        if (val == 1) {
            send_confirmation_to_customer();
            /*var _data = { ...state };
            setShow((prev) => ({
                ...prev,
                loading: true,
            }))
            var response = paiApi.get_pai_proposal(_data.veh_order_no);
            response.then((res) => {
                const timer = setTimeout(() => {
                    setShow((prev) => ({ ...prev, loading: false }));
                }, 2000);
            })*/
        }        
    }
    const get_pai_payment_check_order = (order_no) => {
        setShow((prev) => ({
            ...prev,
            loading: true,
        }))
        var response = paiApi.get_pai_payment_check_order(order_no);
        response.then((res) => {
            if (res.code === 204) {
                set_data(res.data);
                setSelected((prev) => ({
                    ...prev,
                    show_reject: true,
                    msg_reject: "Đơn bảo hiểm của Quý khách đã được gửi qua Email. Xin cám ơn Quý khách đã sử dụng dịch vụ của Fubon!"
                }))
            }
            const timer = setTimeout(() => {
                setShow((prev) => ({ ...prev, loading: false }));
            }, 2000);
        })
    }
    const get_pai_policy = (order_no) => {
        setShow((prev) => ({
            ...prev,
            loading: true,
        }))
        var response = paiApi.get_pai_policy(order_no);
        response.then((res) => {
            setShow((prev) => ({
                ...prev,
                loading: false,
            }))
        })
    }
    const handle_ex_occ_confirm = (flag, val) => {
        setSelected((prev) => ({
            ...prev,
            show_ex_occ: flag,
            //show_reject: !val
        }));
        setState((prev) => ({
            ...prev,
            veh_ex_occ_confirm: val
        }))
        setSelected((prev) => ({ ...prev, confirmOccDecline: val ? 1 : 2 }))

        var _obj = { ...stateError };
        var _objMsg = { ...stateErrorMsg };
        if (val) {
            _obj.confirmOccDecline = false;
        } else {
            _obj.confirmOccDecline = true;
            _objMsg.confirmOccDecline = "Thông tin của Quý khách chưa phù hợp để cấp đơn bảo hiểm.";
        }
        setStateError((prev) => ({
            ...prev,
            stateError: _obj,
            stateErrorMsg: _objMsg,
        }))
    }
    const check_aml_online = (order_no) => {
        var _model = { ...state };
        var _url = "";
        _url += "customer=" + _model.veh_customer;
        _url += "&id_card=" + _model.veh_customer_idcard;
        _url += "&tax_code=" + _model.veh_tax_code;
        _url += "&national_code=" + _model.veh_national_code;
        _url += "&occupation=" + _model.veh_occupation_code.substring(0, 7);
        _url += "&sub_class=PAI";
        _url += "&sob=" + (CommonHelper.is_empty(_model.veh_sob_code) ? "ECO0001" : _model.veh_sob_code);
        _url += "&premium=" + _model.veh_premium;

        setShow((prev) => ({
            ...prev,
            loading: true,
        }))
        var response = paiApi.get_aml_online_check(_url);
        response.then((res) => {
            setShow((prev) => ({
                ...prev,
                loading: false,
            }));
            var _aml_total_score = 0;
            var _aml_risk_score = 0;
            if (res != null && res.code == 200) {
                var _obj = JSON.parse(res.data);
                if (_obj != null) {
                    _aml_total_score = CommonHelper.convertToInt(_obj.total_score);
                    _aml_risk_score = CommonHelper.convertToInt(_obj.risk_name_score);
                    if (_obj.risk_name_score == 100) {
                        setSelected((prev) => ({
                            ...prev,
                            show_reject: true,
                            msg_reject: "Rất tiếc hồ sơ yêu cầu bảo hiểm của bạn chưa phù hợp với quy định bán hàng online của chúng tôi. Xin vui lòng liên hệ với nhân viên của Fubon để được tư vấn & phục vụ trực tiếp."
                        }))
                    } else if (_obj.total_score > 69) {
                        setSelected((prev) => ({
                            ...prev,
                            show_reject: true,
                            msg_reject: "Rất tiếc hồ sơ yêu cầu bảo hiểm của bạn chưa phù hợp với quy định bán hàng online của chúng tôi. Xin vui lòng liên hệ với nhân viên của Fubon để được tư vấn & phục vụ trực tiếp."
                            //msg_reject: "Thông tin của Quý khách đã được Fubon ghi nhận. Chúng tôi sẽ thông báo cho Quý khách sau khi tiến hành kiểm tra và thẩm định. Xin cám ơn!"
                        }))
                    }
                }
            }
            setState((prev) => ({
                ...prev,
                aml_total_score: _aml_total_score,
                aml_risk_score: _aml_risk_score,
            }))
        })
    }
    const send_confirmation_to_customer = () => {
        var _data = { ...state };
        setShow((prev) => ({ ...prev, loading: true }));
        const response = paiApi.send_confirmation_to_customer(_data.veh_order_no);
        response.then((res) => {
            if (res != null) {
                if (res.code == 200) {
                    setAlertData((prev) => ({
                        ...prev,
                        title: "Thông tin",
                        content: "Gửi mail thành công",
                        is_show: true,
                        variant: "success"
                    }))
                }
            }
            const timer = setTimeout(() => {
                setShow((prev) => ({ ...prev, loading: false }));
            }, 2000);
        })
    }
    const handle_issue_pai_policy = () => {
        setShow((prev) => ({ ...prev, loading: true }));
        var data = { ...state };
        data.veh_pax = data.veh_pax + "";
        const response = paiApi.issue_pai_policy(data);
        response.then((res) => {
            if (res.code == 506) {
                setAlertData((prev) => ({
                    ...prev,
                    title: "Thông tin",
                    content: "Phiên làm việc hết hiệu lực. Vui lòng đăng nhập lại!",
                    is_show: true,
                    variant: "danger"
                }))
                const timer = setTimeout(() => {
                    window.location.href = window.location.origin
                }, 4000);
            } else if (res.code == 200) {
                setAlertData((prev) => ({
                    ...prev,
                    title: "Thông tin",
                    content: "Hệ thống đã cấp đơn thành công và gửi qua email. Vui lòng kiểm tra email để nhận đơn bảo hiểm!",
                    is_show: true,
                    variant: "success"
                }))
                setState((prev) => ({
                    ...prev,
                    status: res.data.pol_status,
                    cert_url: res.data.cert_url,
                }))
                const timer = setTimeout(() => {
                    setShow((prev) => ({ ...prev, loading: false }));
                }, 2000);
            } else {
                setAlertData((prev) => ({
                    ...prev,
                    title: "Thông tin",
                    content: "Hệ thống đã cấp đơn bị lỗi. Vui lòng liên hệ Fubon để được hỗ trợ!",
                    is_show: true,
                    variant: "danger"
                }))
                const timer = setTimeout(() => {
                    setShow((prev) => ({ ...prev, loading: false }));
                }, 2000);
            }
        })
    }
    const get_order_status = () => {
        setShow((prev) => ({ ...prev, loading: true }));
        const response = paiApi.get_order(state.veh_order_no);
        response.then((res) => {
            if (res != null && res.code === 200) {
                setState((prev) => ({
                    ...prev,
                    status: res.data.status,
                }))
            }
            setShow((prev) => ({ ...prev, loading: false }));
        })
    }
    const handle_upload = (flag, data) => {
        setSelected((prev) => ({ ...prev, show_upload: flag }))
        var _list = [...state_lst_person];
        if (data != 0 && data.length > 0) {
            var _new_lst = [];
            data.forEach((item) => {
                var _obj = {
                    "idx": _list.length + 2,
                    "id": initial_person_info.id,
                    "orderNo": state.veh_order_no,
                    "fullName": item.fullName,
                    "idCard": item.idCard,
                    "dob": item.dob,
                    "gender": item.gender,
                    "national": JSON.stringify(item.nationality),
                    "occupation": JSON.stringify(item.occupation),
                    "relationship": CommonHelper.is_empty(item.relationship) ? "0" : item.relationship,
                    "premium": item.premium,
                    "objPackage": item.objPackage,
                    "insPackage": item.insPackage,
                    "isInsuredPerson": false,
                    "action": "new", //[new, edit]
                }
                if (CommonHelper.is_not_empty(item.idCard)) {
                    _new_lst.push(_obj);
                    _list.push(_obj);
                }
            })
            setList((prev) => ({ ...prev, state_lst_person: _list }));
            if (CommonHelper.is_not_empty(state.veh_order_no) && _new_lst.length > 0) {
                _new_lst.forEach((item) => {
                    handle_save_person(item);
                })
            }
        }
    }
    const handle_save_person = (data) => {
        if (data != null) {
            var response = paiApi.post_insured_person(data);
            response.then((res) => {
                if (res.code === 204) {
                    var _id = res.data.id;
                    var list_person = [...state_lst_person];
                    if (list_person != null && list_person.length > 0) {
                        var _idx = list_person.findIndex(x => x.idCard == data.idCard);
                        if (_idx >= 0) {
                            list_person[_idx].id = _id
                            list_person[_idx].idx = _id
                            setList((prev) => ({ ...prev, state_lst_person: list_person }));
                        }
                    }
                }
            })
        }
    }
    return (
        <>
            <div>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a href="/">Trang chủ</a></li>
                        <li className="breadcrumb-item active" aria-current="page">{_title}</li>
                    </ol>
                </nav>
            </div>
            <div className='container'>
                <div className='row mb-5'>
                    <div className='col-sm-12'>
                        <div className='position-relative' style={{ width: "60%", margin: "auto" }}>
                            {/* <h1 style={{ fontSize: "18px" }}>{_title}</h1> */}
                            <PaiProgressBar onClick={() => { }} step={curr_step.code} user={user} />
                        </div>
                    </div>
                </div>
                {curr_step.code === 1 && (
                    <div className='row'>
                        <div className='col-sm-12'>
                            <div className='text-center mt-3' id='pai_pkg'>
                                <h2 className='fs-4'>CÁC GÓI BẢO HIỂM</h2>
                            </div>
                            <div className='mt-3'>
                                <Packages onSelect={select_pkg} list={pa_packages} pkg_selected={state.pai_package} />
                            </div>
                            <div className='text-center mt-3'>
                                {/*<button className="btn btn-warning" onClick={back_step}>{"< QUAY LẠI"}</button> */}
                                <button className="btn btn-primary ms-1" onClick={next_step}>{"TIẾP TỤC >"}</button>
                            </div>
                        </div>
                    </div>
                )}
                {curr_step.code === 2 && (
                    <div className='row'>
                        <div className='col-sm-12'>
                            <div className='text-center mt-3'>
                                <h2 className='fs-4'>YÊU CẦU BẢO HIỂM</h2>
                            </div>
                            <div className='panel-form mt-3 bg-white'>
                                <div className='container'>
                                    <div className='row'>
                                        <label className="form-label fs-5 font-semibold">
                                            Bên mua bảo hiểm
                                        </label>
                                    </div>
                                    <div className='row'>
                                        <div className='col-sm-3'>
                                            <div className="form-check">
                                                <input className="form-check-input" type="radio" name="veh_business" id={`radio-1`}
                                                    checked={state.veh_business !== "Y"} value="N"
                                                    onClick={handle_change} />
                                                <label className="form-check-label" htmlFor={`radio-1`}>
                                                    Cá nhân
                                                </label>
                                            </div>
                                        </div>
                                        <div className='col-sm-9'>
                                            <div className="form-check">
                                                <input className="form-check-input" type="radio" name="veh_business" id={`radio-2`}
                                                    checked={state.veh_business === "Y"} value="Y"
                                                    onClick={handle_change} />
                                                <label className="form-check-label" htmlFor={`radio-2`}>
                                                    Tổ chức
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row mt-2'>
                                        <div className='col-sm-12'>
                                            <div className='row'>
                                                <label className="form-label required">
                                                    {state.veh_business === "Y" ? "Bên mua bảo hiểm" : "Họ tên"}
                                                </label>
                                                <div className='col-sm-12'>
                                                    <TextInput value={state.veh_customer} name="veh_customer" onChange={handle_change}
                                                        className={stateError.veh_customer ? "error" : ""} />
                                                    <ValidateMessage isShow={stateError.veh_customer} msgType={stateErrorMsg.veh_customer} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {state.veh_business === "N" && (
                                        <>
                                            <div className='row mt-2'>
                                                <div className='col-sm-6'>
                                                    <div className='row'>
                                                        <label className="form-label required">
                                                            Ngày sinh
                                                        </label>
                                                        <div className='col-sm-12'>
                                                            <ShortDateInput
                                                                selected={dob}
                                                                minDate={minDOB}
                                                                maxDate={maxDOB}
                                                                onChange={change_dob}
                                                                className={stateError.dob ? "error" : ""} />
                                                            <ValidateMessage isShow={stateError.dob} msgType={stateErrorMsg.dob} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-sm-6'>
                                                    <div className='row'>
                                                        <label className="form-label required">
                                                            Số CMND/ CCCD/ Hộ chiếu
                                                        </label>
                                                        <div className='col-sm-12'>
                                                            <TextInput value={state.veh_customer_idcard}
                                                                name="veh_customer_idcard" onChange={handle_change}
                                                                onBlur={validate_idcard}
                                                                className={stateError.veh_customer_idcard ? "error" : ""} />
                                                            <ValidateMessage isShow={stateError.veh_customer_idcard} msgType={stateErrorMsg.veh_customer_idcard} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='row mt-2'>
                                                <div className='col-sm-6'>
                                                    <div className='row'>
                                                        <label className='form-label required'>Quốc tịch</label>
                                                        <div className='col-sm-12'>
                                                            <AutoComplete
                                                                id="veh_nation"
                                                                options={lst_nation}
                                                                onChange={change_nation}
                                                                selected={nationalSel}
                                                                placeholder={"Quốc tịch"}
                                                                className={stateError.veh_nation ? "error" : ""} />
                                                            <ValidateMessage isShow={stateError.veh_nation} msgType={stateErrorMsg.veh_nation} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-sm-6'>
                                                    <div className='row'>
                                                        <label className="form-label required">
                                                            Giới tính
                                                        </label>
                                                        <div className='col-sm-12'>
                                                            <div className='d-flex'>
                                                                <div className='mr15px'>
                                                                    <div className="form-check">
                                                                        <input className="form-check-input" type="radio" name="veh_gender" id={`radio-gender-1`} defaultValue={"1"}
                                                                            checked={state.veh_gender === 1} value="1" onChange={handle_radio_check} />
                                                                        <label className="form-check-label" htmlFor={`radio-gender-1`}>
                                                                            Nam
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <div className='mr15px'>
                                                                    <div className="form-check">
                                                                        <input className="form-check-input" type="radio" name="veh_gender" id={`radio-gender-2`}
                                                                            checked={state.veh_gender === 2} value="2" onChange={handle_radio_check} />
                                                                        <label className="form-check-label" htmlFor={`radio-gender-2`}>
                                                                            Nữ
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                {/* <div className='mr15px'>
                                                                    <div className="form-check">
                                                                        <input className="form-check-input" type="radio" name="veh_gender" id={`radio-gender-0`} defaultValue={"0"}
                                                                            checked={state.veh_gender === 0} value="0" onChange={handle_radio_check} />
                                                                        <label className="form-check-label" htmlFor={`radio-gender-0`}>
                                                                            Khác
                                                                        </label>
                                                                    </div>
                                                                </div> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='row mt-2'>
                                                <div className='col-sm-6'>
                                                    <div className='row'>
                                                        <label className='form-label required'>Nghề nghiệp</label>
                                                        <div className='col-sm-12'>
                                                            <AutoComplete
                                                                id="veh_occupation"
                                                                options={lst_occupation}
                                                                onChange={change_occupation}
                                                                selected={occupationSel}
                                                                placeholder={"Nghề nghiệp"}
                                                                disabled={(["indoor", "outdoor"].indexOf(state.veh_job_type) >= 0)}
                                                                className={stateError.veh_occupation ? "error" : ""} />
                                                            <ValidateMessage isShow={stateError.veh_occupation} msgType={stateErrorMsg.veh_occupation} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-sm-6'>
                                                    <div className='row'>
                                                        <label className='form-label'>Nghề nghiệp khác</label>
                                                        <div className='col-sm-12'>
                                                            <TextInput
                                                                name="veh_job_desc"
                                                                value={state.veh_job_desc}
                                                                disabled={lockField.veh_job_desc}
                                                                onChange={handle_change}
                                                                className={stateError.veh_job_desc ? "error" : ""} />
                                                            <ValidateMessage isShow={stateError.veh_job_desc} msgType={stateErrorMsg.veh_job_desc} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    )}
                                    {state.veh_business === "Y" && (
                                        <>
                                            <div className='row mt-2'>
                                                <div className='col-sm-6'>
                                                    <div className='row'>
                                                        <label className="form-label required">
                                                            Ngành nghề
                                                        </label>
                                                        <div className='col-sm-12'>
                                                            <AutoComplete
                                                                id="veh_occupation"
                                                                options={lst_occupation}
                                                                onChange={change_occupation}
                                                                selected={occupationSel}
                                                                placeholder={"Ngành nghề"}
                                                                className={stateError.veh_occupation ? "error" : ""} />
                                                            <ValidateMessage isShow={stateError.veh_occupation} msgType={stateErrorMsg.veh_occupation} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-sm-6'>
                                                    <div className='row'>
                                                        <label className="form-label required">
                                                            Mã số thuế
                                                        </label>
                                                        <div className='col-sm-12'>
                                                            <TextInput
                                                                name="veh_tax_code"
                                                                value={state.veh_tax_code}
                                                                onChange={handle_change}
                                                                className={stateError.veh_tax_code ? "error" : ""} />
                                                            <ValidateMessage isShow={stateError.veh_tax_code} msgType={stateErrorMsg.veh_tax_code} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {!lockField.veh_job_desc && (
                                                <div className='row mt-2'>
                                                    <label className='form-label'>Ngành nghề khác</label>
                                                    <div className='col-sm-12'>
                                                        <TextInput
                                                            name="veh_job_desc"
                                                            value={state.veh_job_desc}
                                                            disabled={lockField.veh_job_desc}
                                                            onChange={handle_change}
                                                            className={stateError.veh_job_desc ? "error" : ""} />
                                                        <ValidateMessage isShow={stateError.veh_job_desc} msgType={stateErrorMsg.veh_job_desc} />
                                                    </div>
                                                </div>
                                            )}                                            
                                            <div className='row mt-2'>
                                                <div className='col-sm-6'>
                                                    <div className='row'>
                                                        <label className="form-label required">
                                                            Người đại diện pháp luật
                                                        </label>
                                                        <div className='col-sm-12'>
                                                            <TextInput
                                                                name="veh_rep_company"
                                                                value={state.veh_rep_company}
                                                                onChange={handle_change}
                                                                className={stateError.veh_rep_company ? "error" : ""} />
                                                            <ValidateMessage isShow={stateError.veh_rep_company} msgType={stateErrorMsg.veh_rep_company} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-sm-6'>
                                                    <div className='row'>
                                                        <label className='form-label required'>Quốc tịch người đại diện</label>
                                                        <div className='col-sm-12'>
                                                            <AutoComplete
                                                                id="veh_nation_rep"
                                                                options={lst_nation}
                                                                onChange={change_nation}
                                                                selected={nationalSel}
                                                                placeholder={"Quốc tịch"}
                                                                className={stateError.veh_nation ? "error" : ""} />
                                                            <ValidateMessage isShow={stateError.veh_nation} msgType={stateErrorMsg.veh_nation} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    )}
                                    <div className='row mt-2'>
                                        <div className='col-sm-6'>
                                            <div className='row'>
                                                <label className="form-label required">
                                                    Số điện thoại
                                                </label>
                                                <div className='col-sm-12'>
                                                    <TextInput
                                                        name="veh_phone"
                                                        value={CommonHelper.format_phone_number(state.veh_phone)}
                                                        onChange={handle_change}
                                                        disabled={lockField.veh_phone}
                                                        className={stateError.veh_phone ? "error" : ""} />
                                                    <ValidateMessage isShow={stateError.veh_phone} msgType={stateErrorMsg.veh_phone} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-sm-6'>
                                            <div className='row'>
                                                <label className="form-label required">
                                                    Email
                                                </label>
                                                <div className='col-sm-12'>
                                                    <TextInput
                                                        name="veh_email"
                                                        value={state.veh_email}
                                                        onChange={handle_change}
                                                        onBlur={validate_email_format}
                                                        disabled={lockField.veh_email}
                                                        className={stateError.veh_email ? "error" : ""} />
                                                    <ValidateMessage isShow={stateError.veh_email} msgType={stateErrorMsg.veh_email} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row mt-2'>
                                        <div className='col-sm-12'>
                                            <div className='row'>
                                                <label className='form-label required'>Địa chỉ liên hệ</label>
                                                <div className='col-sm-12'>
                                                    <div className='row'>
                                                        <div className="col-sm-6">
                                                            {/*<label className="form-label">Tỉnh/Thành phố</label> */}
                                                            <AutoComplete
                                                                id="veh_prov"
                                                                options={lst_prov}
                                                                onChange={change_prov}
                                                                selected={provSel}
                                                                placeholder={"Tỉnh/Thành phố"}
                                                                className={stateError.veh_prov ? "error" : ""} />
                                                            <ValidateMessage isShow={stateError.veh_prov} msgType={stateErrorMsg.veh_prov} />
                                                        </div>
                                                        <div className="col-sm-6">
                                                            {/*<label className="form-label">Quận/Huyện</label> */}
                                                            <AutoComplete
                                                                id="veh_district"
                                                                options={lst_dist}
                                                                onChange={change_dist}
                                                                selected={distSel}
                                                                placeholder={"Quận/Huyện"}
                                                                className={stateError.veh_dist ? "error" : ""} />
                                                            <ValidateMessage isShow={stateError.veh_dist} msgType={stateErrorMsg.veh_dist} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-sm-12 mt10px'>
                                                    <TextInput
                                                        name="veh_address"
                                                        value={state.veh_address}
                                                        placeholder="Số nhà, Tên đường"
                                                        onChange={handle_change}
                                                        disabled={provSel.length < 1}
                                                        className={stateError.veh_address ? "error" : ""} />
                                                    <ValidateMessage isShow={stateError.veh_address} msgType={stateErrorMsg.veh_address} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Thoi han bao hiem */}
                                    <div className='row mt-2'>
                                        <label className="form-label required">
                                            Thời hạn bảo hiểm
                                        </label>
                                        <div className="col-sm-12">
                                            <div className="float-start" style={{ width: "48%" }}>
                                                <DateInput
                                                    dateFormat={"dd/MM/yyyy HH:mm"}
                                                    selected={fm_dt}
                                                    minDate={addDays(new Date(), 1)}
                                                    maxDate={addMonths(new Date(), 2)}
                                                    onChange={change_fm_dt}
                                                    className={(stateError.veh_fm_dt ? "error" : "")}
                                                />
                                            </div>
                                            <div className="float-end" style={{ width: "48%" }}>
                                                <DateInput
                                                    dateFormat={"dd/MM/yyyy HH:mm"}
                                                    selected={to_dt}
                                                    minDate={new Date()}
                                                    disabled
                                                />
                                            </div>
                                            <div className="clearfix"></div>
                                            <ValidateMessage isShow={stateError.veh_fm_dt} msgType={"required"} />
                                        </div>
                                    </div>
                                    {/* Xuat hoa don */}

                                    <div className='row mt-2'>
                                        <div className='col-sm-12'>
                                            <div className='mt-3'>
                                                <div className="form-check form-switch">
                                                    <input id='veh_issue_invoice'
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        name="veh_issue_invoice"
                                                        checked={state.veh_issue_invoice}
                                                        onChange={handle_check}
                                                    />
                                                    <label className="form-check-label" htmlFor='veh_issue_invoice'>THÔNG TIN HÓA ĐƠN</label>
                                                </div>
                                                <div className="clearfix"></div>
                                            </div>
                                            <div className={state.veh_issue_invoice ? "" : "d-none"}>
                                                <div className="mt10px">
                                                    <label className={"form-label" + (state.veh_issue_invoice ? " required" : "")}>Mã số thuế</label>
                                                    <TextInput
                                                        name="inv_tax"
                                                        value={state.inv_tax}
                                                        onChange={handle_change}
                                                        className={stateError.inv_tax ? "error" : ""} />
                                                    <ValidateMessage isShow={stateError.inv_tax} msgType={stateErrorMsg.inv_tax} />
                                                </div>
                                                <div className="mt10px">
                                                    <label className={"form-label" + (state.veh_issue_invoice ? " required" : "")}>Tên công ty</label>
                                                    <TextInput
                                                        name="inv_company_name"
                                                        value={state.inv_company_name}
                                                        onChange={handle_change}
                                                        className={stateError.inv_company_name ? "error" : ""} />
                                                    <ValidateMessage isShow={stateError.inv_company_name} msgType={stateErrorMsg.inv_company_name} />
                                                </div>
                                                <div className="mt10px">
                                                    <label className={"form-label" + (state.veh_issue_invoice ? " required" : "")}>Địa chỉ công ty</label>
                                                    <TextInput
                                                        name="inv_address"
                                                        value={state.inv_address}
                                                        onChange={handle_change}
                                                        className={stateError.inv_address ? "error" : ""} />
                                                    <ValidateMessage isShow={stateError.inv_address} msgType={stateErrorMsg.inv_address} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row mt-2 d-none'>
                                        <div className='col-sm-6'>
                                            <div className='row'>
                                                <label className="form-label required">
                                                    Số người được bảo hiểm
                                                </label>
                                                <div className='col-sm-12'>
                                                    <NumberInput value={state.veh_pax} name="veh_pax" onChange={handle_change} className={stateError.veh_pax ? "error" : ""} />
                                                    <ValidateMessage isShow={stateError.veh_pax} msgType={stateErrorMsg.veh_pax} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-sm-6'>
                                            <div className='row'>
                                                <label className="form-label">
                                                    Mã giảm giá
                                                </label>
                                                <div className='col-sm-12'>
                                                    <TextInput value={state.pol_voucher_code} name="pol_voucher_code" onChange={handle_change} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mt-4">
                                        <div className='col-sm-12'>
                                            <p>
                                                <b>
                                                    <label className='form-label required'>
                                                        Vui lòng xác nhận tất cả Người được bảo hiểm không thuộc <a onClick={() => { setSelected((prev) => ({ ...prev, show_ex_occ: true })) }} className="cursor-pointer">Danh sách nghề nghiệp loại trừ.</a>
                                                    </label>
                                                </b>
                                            </p>
                                            <div className='d-flex'>
                                                <div className='mr15px'>
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="radio" name="confirm_ex_occ" id={`radio-confirm_ex_occ-1`} defaultValue={"1"}
                                                            checked={confirmOccDecline === 1} value="1" onChange={handle_radio_check} />
                                                        <label className="form-check-label" htmlFor={`radio-confirm_ex_occ-1`}>
                                                            Đồng ý
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className='mr15px'>
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="radio" name="confirm_ex_occ" id={`radio-confirm_ex_occ-2`}
                                                            checked={confirmOccDecline === 2} value="2" onChange={handle_radio_check} />
                                                        <label className="form-check-label" htmlFor={`radio-confirm_ex_occ-2`}>
                                                            Không đồng ý
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <ValidateMessage isShow={stateError.confirmOccDecline} msgType={stateErrorMsg.confirmOccDecline} />
                                        </div>
                                    </div>
                                    <div className='border-top border-2 border-dark' style={{ margin: "20px 0" }}></div>
                                    <div className='row mt-3'>
                                        <div className='col-sm-12 text-center'>
                                            <h2 style={{ fontSize: "15px" }}>DANH SÁCH NGƯỜI ĐƯỢC BẢO HIỂM</h2>
                                        </div>
                                    </div>
                                    {/* List of insured person */}
                                    <div className='row mt-3'>
                                        <div className='col-sm-6'>
                                            <button type="button" className="btn btn-outline-primary" onClick={new_person}><FaIcons.FaPlus /> Thêm NĐBH</button>
                                        </div>
                                        <div className='col-sm-6 text-end'>
                                            <button type="button" className="btn btn-outline-danger" onClick={begin_upload}>
                                                <FaIcons.FaUpload /> Upload danh sách</button>
                                        </div>
                                    </div>
                                    <div className='row mt-3'>
                                        <InsuredPerson data={state_lst_person} num_of_pax={state.veh_pax} hasAction={true} onEdit={(model) => { open_edit(model) }} onDel={(model) => { open_delete(model) }} />
                                    </div>
                                    <div className='row mt-4 d-none'>
                                        <div className='col-sm-12'>
                                            <div className='alert alert-info'>
                                                <div className='row'>
                                                    <div className='col'>
                                                        <b>Tổng phí bảo hiểm</b> {/*(<i>Đã bao gồm VAT</i>) */}
                                                    </div>
                                                    <div className='col text-end'>
                                                        <strong>{CommonHelper.format_currency_vnd_style(state.veh_premium)}</strong>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row mt-5'>
                                        <div className='col-sm-12 text-center'>
                                            {/*<button className="btn btn-primary" onClick={next_step}>{"CHỌN GÓI BẢO HIỂM >"}</button> */}

                                            <button className="btn btn-warning" onClick={back_step}>{"< QUAY LẠI GÓI BẢO HIỂM"}</button>
                                            <button className="btn btn-primary ms-1" onClick={next_step}>{"TIẾP TỤC >"}</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {curr_step.code === 6 && (
                    <div className='row'>
                        <div className='col-sm-12'>
                            <div className='mt-10 text-center'>
                                <h2 style={{ fontSize: "15px" }}>THÔNG TIN BÊN MUA BẢO HIỂM</h2>
                            </div>
                            <div className='mt-10 panel-form bg-white'>
                                <div className='container'>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {curr_step.code >= 3 && (
                    <div className='row'>
                        <div className='col-sm-12'>
                            <div className='mt-3 text-center'>
                                <h2 className='fs-4'>XÁC NHẬN THÔNG TIN</h2>
                            </div>
                            <div className='mt-2 panel-form bg-white' style={{ width: "900px" }}>
                                <div className='container'>
                                    <PaiConfirmation data={state} list_person={state_lst_person} user={user}
                                        onCheck={(e) => { setState((prev) => ({ ...prev, is_confirm: e })) }}
                                        onSendConfirmMail={() => { setSelected((prev) => ({ ...prev, show_payment: true })); }}
                                        onGetStatus={get_order_status} />
                                    <div className='row'>
                                        <div className='col-sm-12'>
                                            {user != null && user.user_type > 1 && (
                                                <div className="mt-3" >
                                                    <div className='container'>
                                                        <div className="row mt-3">
                                                            <label className="form-label col-sm-3">Kênh khai thác</label>
                                                            <div className="col-sm-9">
                                                                <AutoComplete
                                                                    id="veh_sob"
                                                                    options={lst_sob}
                                                                    onChange={change_sob}
                                                                    selected={sobSel}
                                                                    disabled={lockField.veh_sob}
                                                                    placeholder={"Kênh khai thác"} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                            {lst_agency.length > 0 && (
                                                <div className='mt-3'>
                                                    <div className='container'>
                                                        <div className="row mt-3">
                                                            <label className="form-label col-sm-3">Code đại lý</label>
                                                            <div className="col-sm-9">
                                                                <AutoComplete
                                                                    id="veh_agencies"
                                                                    options={lst_agency}
                                                                    onChange={(e) => { setSelected((prev) => ({ ...prev, agentSel: e })) }}
                                                                    selected={agentSel}
                                                                    disabled={lockField.veh_agent}
                                                                    placeholder={"Code đại lý"} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className='text-center mt30px'>
                                        <button className="btn btn-warning" disabled={state.aml_checked} onClick={back_step}>{"< QUAY LẠI"}</button>
                                        {user != null && user.user_type > 1 && (
                                            <>
                                                {state.status == 1 || state.status == 7 && state.aml_total_score < 70 && state.aml_total_score < 100 && (
                                                    <button className="btn btn-primary ms-1" onClick={handle_issue_pai_policy}>{"CẤP ĐƠN"}</button>
                                                )}
                                            </>
                                        )}
                                        {state.status == 1 && (
                                            <>
                                                {(user == null || user.user_type == 1) && state.aml_total_score < 70 && state.aml_total_score < 100 && (
                                                    <button className="btn btn-primary ms-1" disabled={!state.is_confirm} onClick={next_step}>{"HOÀN TẤT"}</button>
                                                )}
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>

            {loading && <Loading />}
            <PersonalInfo show={show_personal_info} data={state_person} url={"#"} title={"Quan hệ với bên mua bảo hiểm"}
                onClose={handle_close_personal_info} list={state_lst_person} masterData={state} list_pkg={pa_packages} />
            <AlertBox data={alertData} onChange={(e) => { setAlertData((prev) => ({ ...prev, is_show: e })) }} />
            <ModalPayment onClose={get_pai_proposal} show={show_payment} />
            <ModalUpload onClose={handle_upload}
                show={show_upload} onLoading={(e) => { setShow((prev) => ({ ...prev, loading: e })) }}
                packages={pa_packages} occupations={lst_occupation} nationalities={lst_nation} model={state} />
            <ModalExclusiveOccupation onConfirm={handle_ex_occ_confirm} isShow={show_ex_occ} />
            <ModalReject onClose={(flag) => { setSelected((prev) => ({ ...prev, show_reject: flag })) }} isShow={show_reject} msg={msg_reject} />
        </>
    )
}

export default PaInsurance