import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Popup from '../../../../components/popup'


const TermContent = () => {
    return (
        <>
            <p>I.	Tôi đồng ý cung cấp và đồng ý cho phép FUBON xử lý dữ liệu cá nhân mà tôi/Người được bảo hiểm cung cấp tại trang web này cho mục đích:<br />
                -	Giao kết Hợp đồng bảo hiểm (HĐBH), phát hành Giấy chứng nhận bảo hiểm (GCNBH), bao gồm thực hiện hoạt động tái bảo hiểm.<br />
                -	Giải quyết khiếu nại bồi thường, trả tiền bảo hiểm.<br />
                -	Cung cấp cho Kiểm toán độc lập thực hiện kiểm toán theo quy định pháp luật.<br />
                -	Cung cấp cho Công ty mẹ nhằm mục đích thống kê và báo cáo, chia sẻ cho các Đơn vị trong cùng Tập đoàn Fubon cho các mục đích liên quan tới nghiệp vụ mà không làm phương hại đến lợi ích chính đáng của Bên mua bảo hiểm/Người được bảo hiểm.<br />
                -	Và cho các mục đích hợp pháp khác theo quy định của pháp luật.<br />
            </p>
            <p>II.  Tôi/Người được bảo hiểm xác nhận đã đọc, được FUBON giải thích đầy đủ và hiểu rõ quyền và nghĩa vụ của Bên mua bảo hiểm khi giao kết HĐBH, quyền lợi bảo hiểm, các điều kiện, điều khoản bảo hiểm, điều khoản loại trừ bảo hiểm theo Quy tắc bảo hiểm và quy định  pháp luật về kinh doanh bảo hiểm; quyền và nghĩa vụ của Chủ thể dữ liệu theo quy định tại Nghị định 13/2023/NĐ-CP quy định về bảo vệ Dữ liệu cá nhân.</p>
            <p>III.	Tôi/Người được bảo hiểm xác nhận: Việc tham gia bảo hiểm tại FUBON là hoàn toàn tự nguyện, không bị ép buộc. <br />
                -	Tất cả những điều kê khai trong trang web này là hoàn toàn chính xác và đúng sự thật. Các thông tin này là cơ sở để FUBON chấp nhận bảo hiểm. Trường hợp các thông tin kê khai trên là không trung thực, không chính xác thì bất cứ quyền lợi bảo hiểm phát sinh liên quan sẽ không có hiệu lực.<br />
                -	Giấy yêu cầu bảo hiểm này là cơ sở để giao kết của HĐBH/GCNBH và chấp nhận các điều kiện bảo hiểm quy định tại HĐBH/GCNBH.<br />
                -	Tôi/Người được bảo hiểm cam đoan sẽ thông báo ngay cho FUBON về tất cả những thay đổi quan trọng nào có ảnh hưởng đến mức độ rủi ro của các đối tượng được bảo hiểm theo HĐBH này.<br /></p>
        </>
    )
}
function TermCondition(props) {
    const { onClose, show, ...other } = props;
    const handle_close = () => {
        onClose(false);
    }
    return (
        <Popup show={show} title="Điều kiện và điều khoản" content={<TermContent />} onClose={handle_close} />
    )
}

export default TermCondition

TermCondition.propTypes = {
    onClose: PropTypes.func.isRequired,
}