import React from 'react'
import { Typeahead } from 'react-bootstrap-typeahead'

function AutoComplete(props) {
  return (
    <Typeahead
      {...props}
      clearButton
      flip={true}
      size='sm'
      labelKey="name" />
  )
}

export default AutoComplete