/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import { useSearchParams } from 'react-router-dom';
import addMinutes from 'date-fns/addMinutes';
import "../../../../App.css";
import CommonHelper from '../../../../helpers/common';
import { Container, Modal, Button } from "react-bootstrap";
import AlertBox from '../../../../components/alert';
import QuestionNaire from './questionnaire';
import trainingApi from '../../../../api/trainingApi';
import Loading from '../../../../components/loading';
import PersonalHistory from '../history/personal-history';
import "./index.css";
import Footer from './footer';
import Header from './header';
import BeforeBeginExam from './before-begin-exam';
import { useTopics } from './hooks/use-topic';
import { initial, initialAlert } from './constants';
import { FaClock } from "react-icons/fa";
import { Link } from 'react-router-dom';
import VoteExam from '../vote';

function Exam() {
    const [state, setState] = useState(initial);
    const [searchParams] = useSearchParams();
    const [{ lst_ques, lst_exam, lst_result, lst_wrong }, setList] = useState({
        lst_ques: [],
        lst_exam: [],
        lst_result: [],
        lst_wrong: [],
    })
    const [{ curr_ques, idx, min, max, lb_time, numOfMinute,
        minutes, seconds, countDownTime, score, is_loading
    }, setSelected] = useState({
        // curr_ques: null,
        idx: 0,
        min: 0,
        max: 40,
        numOfMinute: 60,
        lb_time: "",
        minutes: 0,
        seconds: 0,
        countDownTime: null,
        score: 0,
        is_loading: false
    })
    const [alertData, setAlertData] = useState(initialAlert);
    const [stateError, setStateError] = useState({
        username: false,
        password: false,
    });
    const [topicExamination, setTopicExamination] = useState(state.topicName);
    const [listTopic, setListTopic] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [identityParam, setIdentityParam] = useState("");
    const topics = useTopics();
    const [buttons, setButtons] = useState([]);
    const [showVoteModal, setShowVoteModal] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const lst_ques_data = await trainingApi.get_questions_data();
                if (topicExamination === "") {
                    setList((prev) => ({ ...prev, lst_ques: lst_ques_data.data }));
                } else {
                    const filteredByTopic = lst_ques_data.data.filter(question =>
                        question.topic && question.topic.toLowerCase().includes(topicExamination.toLowerCase())
                    );
                    setList((prev) => ({ ...prev, lst_ques: filteredByTopic }));
                }
            } catch (error) {
                console.error("Error fetching question data:", error);
            }
        };
        renderButtons();
        fetchData();

        var _id = searchParams.get('id');
        if (CommonHelper.is_not_empty(_id) && CommonHelper.convertToInt(_id) > 0) {
            setSelected((prev) => ({
                ...prev,
                is_loading: true
            }));
            var response = trainingApi.get_examination(_id);
            response.then((res) => {
                if (res != null && res.code === 200) {
                    set_data(res.data);
                }
                setSelected((prev) => ({
                    ...prev,
                    is_loading: false
                }));
            });
        }
    }, []);

    useEffect(() => {
        if (countDownTime != null) {
            var _count = 0;
            const interval = setInterval(() => {
                _count = countDownTime - new Date().getTime();
                parse_time(_count);
            }, 1000);
            return () => {
                clearInterval(interval);
            };
        }
        if (seconds < 0) {
            setState((prev) => ({
                ...prev,
                finish: true
            }))
            setSelected((prev) => ({
                ...prev,
                lb_time: "",
            }))
        } else {
            setSelected((prev) => ({
                ...prev,
                lb_time: minutes + ":" + (seconds < 10 ? "0" + seconds : seconds),
            }))
        }
    }, [countDownTime, seconds])

    const handle_change = (name, value) => {
        var _val = value;

        setState((prevData) => ({
            ...prevData,
            [name]: _val,
        }));
    };

    const validate_form = () => {
        var flag = true;
        var _data = { ...state };
    
        let errors = {};
    
        if (CommonHelper.is_empty(_data.username)) {
            flag = false;
            errors.username = true;
        } else if (!CommonHelper.check_idcard_valid(_data.username)) {
            flag = false;
            errors.username = true;
        } else {
            errors.username = false;
        }
    
        if (CommonHelper.is_empty(_data.password)) {
            flag = false;
            errors.password = true;
        } else {
            errors.password = false;
        }
    
        setStateError((prev) => ({
            ...prev,
            ...errors
        }));
    
        // console.log(flag);
        return flag;
    }
    

    const validateCredentials = async (username, password) => {
        try {
            const _data = {
                "username": username,
                "password": password,
                "isAdmin": false
            };
            const response = await trainingApi.login_exam(_data);

            if (response.code === 200) {
                const { id, fullName, phone, email, identity, userName } = response.data;
                setState((prev) => ({
                    ...prev,
                    id: id || prev.id,
                    full_name: fullName || prev.full_name,
                    name: fullName || prev.name,
                    phone: phone || prev.phone,
                    email: email || prev.email,
                    identity: identity || prev.identity,
                    username: userName || prev.username,
                }));
                localStorage.setItem("ACC_NAME", fullName);
                localStorage.setItem("ACC_EMAIL", email);
                localStorage.setItem("ACC_PHONE", phone);
                localStorage.setItem("ACC_IDENTITY", identity);
                localStorage.setItem("ACC_USERNAME", userName);
                return response;
            } else {
                return null;
            }
        } catch (error) {
            return null;
        }
    };

    const begin_exam = async () => {
        localStorage.setItem("SCORE", 0);
        var flag = validate_form();
        if (!flag) {
            setAlertData((prev) => ({
                ...prev,
                title: "Lỗi",
                content: "Vui lòng nhập đúng thông tin đăng nhập!!!",
                is_show: true,
                variant: "danger"
            }));
        } else {
            var _data = { ...state };
            var _arr = await get_rd_arr(topicExamination);
            var _lst = [];
            if (topicExamination === "") {
                await Promise.all(_arr.map(async (i) => {
                    _lst.push([...lst_ques][i]);
                }));
            } else {
                await Promise.all(_arr.map(async (question_no) => {
                    const question = lst_ques.find(question => question.ques_no === question_no);
                    if (question) {
                        _lst.push(question);
                    }
                }));
            }

            var _ques = _lst.map((item, i) => {
                return { ques_no: item.ques_no, answer_key: item.answer, idx: i + 1 };
            })
            var _begin = new Date();
            var _evt_dt = addMinutes(_begin, numOfMinute);

            setSelected((prev) => ({
                ...prev,
                is_loading: true,
            }))

            _data.id = 0;
            _data.name = state.full_name;
            _data.phone = state.phone;
            _data.email = state.email;
            _data.questions = JSON.stringify(_ques);
            _data.topicName = topicExamination ?? "";
            _data.quesTotal = localStorage.getItem("QUES_TOTAL");
            _data.beginDate = CommonHelper.formatToYYYYMMDDHHMMSS(_begin);
            _data.identity = state.identity;
            _data.userName = state.username;
            _data.password = state.password;

            const responseLogin = await validateCredentials(_data.username, _data.password);
            if (!responseLogin) {
                setAlertData((prev) => ({
                    ...prev,
                    title: "Lỗi",
                    content: "Số CMTND hoặc mật khẩu không chính xác.",
                    is_show: true,
                    variant: "danger"
                }));
                return;
            }

            const _model = {
                "id": 0,
                "name": localStorage.getItem("ACC_NAME"),
                "phone": localStorage.getItem("ACC_PHONE"),
                "email": localStorage.getItem("ACC_EMAIL"),
                "questions": JSON.stringify(_ques),
                "topicName": topicExamination,
                "quesTotal": localStorage.getItem("QUES_TOTAL"),
                "beginDate": CommonHelper.formatToYYYYMMDDHHMMSS(_begin),
                "identity": localStorage.getItem("ACC_IDENTITY"),
                "userName": localStorage.getItem("ACC_USERNAME"),
            }

            localStorage.setItem("SCORE", 0)
            // var response = trainingApi.post_candidate(_data);
            var response = trainingApi.post_candidate(_model);
            response.then((res) => {
                setSelected((prev) => ({
                    ...prev,
                    is_loading: false,
                }))
                if (res != null && res.code === 204) {
                    setState((prev) => ({
                        ...prev,
                        id: res.data.id,
                        begin: true
                    }))
                    setList((prev) => ({
                        ...prev,
                        lst_exam: _lst
                    }))
                    setSelected((prev) => ({
                        ...prev,
                        idx: 0,
                        is_loading: false,
                        // curr_ques: _lst[0],
                        countDownTime: _evt_dt,
                    }))
                }
            })
        }
    }

    const handle_get_score = (answer, idx) => {
        const updatedExamList = lst_exam.map((item, index) => {
            if (index === idx) {
                item.btn_check = true;
                const isCorrect = item.answer == answer;

                if (isCorrect) {
                    console.log(localStorage.setItem("SCORE", parseInt(localStorage.getItem("SCORE")) + 1))
                }

                var _lst_result = [...lst_result];
                if (_lst_result.length > 0) {
                    var _res_idx = _lst_result.findIndex(x => x.ques_no == item.ques_no);
                    if (_res_idx >= 0) {
                        _lst_result[_res_idx].result = answer;
                    } else {
                        _lst_result.push({ ques_no: item.ques_no, result: answer, idx: idx + 1 });
                    }
                } else {
                    _lst_result.push({ ques_no: item.ques_no, result: answer, idx: idx + 1 });
                }
                setList((prev) => ({
                    ...prev,
                    lst_exam: lst_exam,
                    lst_result: _lst_result,
                    score: localStorage.getItem("SCORE")
                }))
            }
            return item;
        });
    };

    const get_rd_arr = async (topic) => {
        var _arr = [];
        var _lst = [];
        const MAX_QUESTIONS = 40;

        const examinationData = await trainingApi.get_questions_data();
        let filteredByTopic = [];
        var _len = 0;
        if (topicExamination === "") {
            filteredByTopic = examinationData.data;
            _len = examinationData.data.length;
            for (let i = 0; i < _len; i++) {
                _arr.push(i);
            }
            let _suff = CommonHelper.shuffle(_arr);
            let maxData = Math.min(_len, MAX_QUESTIONS);
            localStorage.setItem("QUES_TOTAL", maxData);
            for (let i = 0; i < maxData; i++) {
                _lst.push(_suff[i]);
            }
        } else {
            filteredByTopic = examinationData.data.filter(question =>
                question.topic && question.topic.toLowerCase().includes(topic.toLowerCase())
            );
            _len = filteredByTopic.length;
            for (let i = 0; i < _len; i++) {
                _arr.push(filteredByTopic[i].ques_no);
            }
            let _suff = CommonHelper.shuffle(_arr);
            const maxData = Math.min(_len, MAX_QUESTIONS);
            localStorage.setItem("QUES_TOTAL", maxData);
            for (let i = 0; i < maxData; i++) {
                _lst.push(_suff[i]);
            }
            setSelected((prev) => ({
                ...prev,
                max: _len
            }));
        }
        return _lst;
    }

    const handle_submit = () => {
        setState((prev) => ({
            ...prev,
            finish: true,
            lb_time: ""
        }));
        var _lst_result = [...lst_result];
        var _lst_wrong = [];
        var _data = {
            id: state.id,
            score: score,
            answers: JSON.stringify(_lst_result),
            endDate: CommonHelper.formatToYYYYMMDDHHMMSS(new Date()),
        }
        localStorage.setItem("END_DATE", lb_time);
        _data.name = state.full_name;
        _data.identity = state.identity;
        _data.userName = state.username;
        _data.topicName = topicExamination;
        _data.quesTotal = localStorage.getItem("QUES_TOTAL");
        _data.score = localStorage.getItem("SCORE");
        var _lst_exam = [...lst_exam];
        _lst_result.forEach((item) => {
            var _idx = _lst_exam.findIndex(x => x.ques_no == item.ques_no && x.answer == item.result);
            if (_idx < 0) {
                _lst_wrong.push({ idx: item.idx })
            }
        })
        setList((prev) => ({
            ...prev,
            lst_wrong: _lst_wrong
        }))
        setSelected((prev) => ({
            ...prev,
            is_loading: true
        }))
        var response = trainingApi.post_candidate(_data);
        response.then((res) => {
            setSelected((prev) => ({
                ...prev,
                is_loading: false
            }))
        })
    }

    const parse_time = (countDown) => {
        const _minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60));
        const _seconds = Math.floor((countDown % (1000 * 60)) / 1000);

        setSelected((prev) => ({
            ...prev,
            minutes: _minutes,
            seconds: _seconds
        }))

        setSelected((prev) => ({
            ...prev,
            lb_time: _minutes + ":" + (_seconds < 10 ? "0" + _seconds : _seconds),
        }))
    }

    const handle_renew = async () => {
        localStorage.setItem("SCORE", 0);
        renderButtons();
        var _data = { ...state };
        var _arr = await get_rd_arr(topicExamination);
        var _lst = [];
        if (topicExamination === "") {
            await Promise.all(_arr.map(async (i) => {
                _lst.push([...lst_ques][i]);
            }));
        } else {
            await Promise.all(_arr.map(async (question_no) => {
                const question = lst_ques.find(question => question.ques_no === question_no);
                if (question) {
                    _lst.push(question);
                }
            }));
        }
        var _ques = _lst.map((item) => {
            return { ques_no: item.ques_no };
        })

        var _begin = new Date();
        var _evt_dt = addMinutes(_begin, 60);
        setSelected((prev) => ({
            ...prev,
            is_loading: true,
        }))

        _data.id = 0;
        _data.name = state.full_name;
        _data.phone = state.phone;
        _data.email = state.email;
        _data.questions = JSON.stringify(_ques);
        _data.topicName = topicExamination;
        _data.quesTotal = localStorage.getItem("QUES_TOTAL");
        _data.beginDate = CommonHelper.formatToYYYYMMDDHHMMSS(_begin);
        _data.identity = state.identity;
        _data.userName = state.username;
        _data.password = state.password;

        const _model = {
            "id": 0,
            "name": localStorage.getItem("ACC_NAME"),
            "phone": localStorage.getItem("ACC_PHONE"),
            "email": localStorage.getItem("ACC_EMAIL"),
            "questions": JSON.stringify(_ques),
            "topicName": topicExamination,
            "quesTotal": localStorage.getItem("QUES_TOTAL"),
            "beginDate": CommonHelper.formatToYYYYMMDDHHMMSS(_begin),
            "identity": localStorage.getItem("ACC_IDENTITY"),
            "userName": localStorage.getItem("ACC_USERNAME"),
        }

        // var response = trainingApi.post_candidate(_data);
        var response = trainingApi.post_candidate(_model);
        response.then((res) => {
            setSelected((prev) => ({
                ...prev,
                is_loading: false,
            }))
            if (res != null && res.code === 204) {
                setState((prev) => ({
                    ...prev,
                    id: res.data.id,
                    finish: false,
                }))
                setList((prev) => ({
                    ...prev,
                    lst_exam: _lst,
                    lst_result: []
                }))
                setSelected((prev) => ({
                    ...prev,
                    idx: 0,
                    is_loading: false,
                    curr_ques: _lst[0],
                    countDownTime: _evt_dt,
                    score: 0
                }))
            }
        })
    }

    const set_data = async (data) => {
        try {
            const _ques = JSON.parse(data.questions);
            let _anws = [];
            let _lst_wrong = [];
            if (CommonHelper.is_not_empty(data.answers)) {
                _anws = JSON.parse(data.answers);
            }
            let _lst_exam = [];
            const _lst_question = trainingApi.get_questions_data();
            _ques.forEach(async (item) => {
                const _filter_ques = _lst_question.filter(x => x.ques_no === item.ques_no);
                if (_filter_ques.length > 0) {
                    const _obj = _filter_ques[0];
                    if (_anws.length > 0) {
                        const _filter_anw = _anws.filter(x => x.ques_no === _obj.ques_no);
                        if (_filter_anw.length > 0) {
                            _obj.result = _filter_anw[0].result;
                            if (_obj.answer !== _filter_anw[0].result) {
                                _lst_wrong.push({ idx: _filter_anw[0].idx });
                            }
                        }
                    }
                    _lst_exam.push(_obj);
                }
            });

            setState((prev) => ({
                ...prev,
                id: data.id,
                full_name: data.name,
                phone: data.phone,
                email: data.email,
                identity: data.identity,
                username: data.username,
                begin: true,
                finish: true
            }));

            setList((prev) => ({
                ...prev,
                lst_exam: _lst_exam,
                lst_result: _anws,
                lst_wrong: _lst_wrong,
            }));

            setSelected((prev) => ({
                ...prev,
                score: data.score,
                idx: 0,
                // curr_ques: _lst_exam[0]
            }));
        } catch (error) {
            // console.error("Error in set_data:", error);
        }
    };

    const openModal = (identity) => {
        setShowModal(true);
        setIdentityParam(identity);
    };

    const renderButtons = () => {
        const initialButtons = [];
        for (let i = 0; i < 40; i++) {
            initialButtons.push({ id: i, href: `#btn${i}`, styles: `grid-item` });
        }
        setButtons(initialButtons);
    };

    const updateButtonState = (id) => {
        buttons[id].styles = `grid-item active`;
    };

    const handleVoteModalClose = () => setShowVoteModal(false);
    const handleVoteModalShow = () => setShowVoteModal(true);

    return (
        <div>
            <Header></Header>
            <Container >
                <div className="wrapper" style={{ minHeight: "700px" }}>
                    <div className='row'>
                        <div className='col-md-12'>
                            {!state.begin && (
                                <>
                                    <BeforeBeginExam username={state.username} password={state.password} handle_change={handle_change} begin_exam={begin_exam} />
                                </>
                            )}
                            {state.begin && (
                                <>

                                    <div className='container'>
                                        <div className='row'>
                                            <div className='col-md-2 sidebar-exam'>
                                                <div className='row mt-4'>
                                                    <div className='col-md-7'>
                                                        {!state.finish && (
                                                            <>
                                                                <div className='row'>
                                                                    <div className='col-md-12'>
                                                                        <button className="btn btn-danger ms-1" style={{borderRadius: '0px'}} onClick={handle_submit} >NỘP BÀI</button>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className='row mt-3'>
                                                    <div className='col-md-7'>
                                                        <h1 style={{ color: 'red' }}>
                                                            {!state.finish ? "00:" + lb_time : "00:" + localStorage.getItem("END_DATE")}
                                                        </h1>
                                                    </div>
                                                </div>
                                                <div className='row'>
                                                    <div className="grid-container button-scrollbar">
                                                        {buttons.map(button => (
                                                            <a key={button.id} href={button.href} className={button.styles}>{button.id + 1}</a>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-md-1'></div>
                                            <div className='col-md-9 offset-md-2'>
                                                <div className='card mb-2 title-exam' style={{  margin: "auto" }}>
                                                    <div className='card-body' style={{ paddingTop: "5px", paddingBottom: "5px" }}>
                                                        <div className='row text-center mt-2'>
                                                            <h5>BÀI KIỂM TRA ĐẠI LÝ PHI NHÂN THỌ</h5>
                                                        </div>
                                                        <hr />
                                                        <div className='row'>
                                                            <div className='col-sm-3' style={{textAlign: 'left'}}>
                                                                <button className='btn btn-success reponsive-navbar-exam' style={{ marginBottom: '8px', backgroundColor: 'white', color: 'black', borderRadius: '0px', borderBottom: '4px solid green' }} onClick={() => openModal(state.username)}>
                                                                    LỊCH SỬ THI THỬ
                                                                </button>
                                                            </div>
                                                            <div className='col-sm-2' style={{textAlign: 'left'}}>
                                                                <button className="btn btn-primary reponsive-navbar-exam" style={{ backgroundColor: 'white', color: 'black', borderRadius: '0px', borderBottom: '4px solid blue', paddingTop: '8px' }} onClick={handle_renew} >THI LẠI</button>
                                                            </div>
                                                            <div className='col-sm-2' style={{textAlign: 'left'}}>
                                                                {/* <Link to="/vote" className="btn btn-warning" style={{ backgroundColor: 'white', color: 'black', borderRadius: '0px', borderBottom: '4px solid yellow' }} >LÀM KHẢO SÁT</Link> */}
                                                                <button className="btn btn-warning reponsive-navbar-exam" style={{ backgroundColor: 'white', color: 'black', borderRadius: '0px', borderBottom: '4px solid yellow' }} onClick={handleVoteModalShow} >LÀM KHẢO SÁT</button>
                                                            </div>
                                                            <div className='col-sm-3'></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='row'>
                                                    <h4 className='col-md-12 text-center' style={{ color: 'red' }}>
                                                        {state.finish && `Số điểm: ${localStorage.getItem("SCORE")}/${max}, ${localStorage.getItem("SCORE") / max * 100 >= 75 ? 'Đạt' : 'Không đạt'}`}
                                                    </h4>
                                                </div>
                                                <hr />
                                                {
                                                    lst_exam && lst_exam.map((item, i) => (
                                                        <>
                                                            <QuestionNaire key={item.ques_no} data={item} finish={state.finish}
                                                                onSelect={(answer, i) => handle_get_score(answer, i)}
                                                                score={score} list_wrong={lst_wrong} id={i} updateButtonState={updateButtonState} />
                                                            <hr />
                                                        </>
                                                    ))
                                                }
                                                <h5 style={{ marginTop: '20px', color: 'red', fontWeight: 'bold', textAlign: 'center' }}>Chúc các bạn may mắn!!!</h5>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                    <div className="push"></div>
                </div>
            </Container>
            {showModal && (
                <>
                    <PersonalHistory isOpen={showModal} onClose={() => setShowModal(false)} identity={identityParam} />
                </>
            )}
            <Footer></Footer>
            {is_loading && <Loading />}
            <AlertBox data={alertData} onChange={(e) => { setAlertData((prev) => ({ ...prev, is_show: e })) }} />
            <Modal show={showVoteModal} onHide={handleVoteModalClose} className="custom-modal" backdrop="static" size='xl' scrollable>
                <Modal.Header closeButton>
                    <Modal.Title>NHẬN XÉT/ĐÁNH GIÁ/GÓP Ý TỪ NGƯỜI ÔN TẬP</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <VoteExam />
                </Modal.Body>
                <Modal.Footer>
                    {/* <Button variant="secondary" onClick={handleVoteModalClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleVoteModalClose}>
                        Upload
                    </Button> */}
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default Exam;