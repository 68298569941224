import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

function Popup(props) {
    const { onClose, show, ...other } = props;
    const handle_close = () => {
        onClose(false);
    }
    return (
        <Modal
            show={show}
            onHide={handle_close}
            size="lg"
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
        >
            <Modal.Header closeButton>
                <Modal.Title id="example-custom-modal-styling-title">
                    {other.title}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {other.content}
            </Modal.Body>
        </Modal>
    )
}

export default Popup

Popup.propTypes = {
    onClose: PropTypes.func.isRequired,
}