  /* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import trainingApi from "../../../../../api/trainingApi";
import listOptions from "../alphabet-options";
import AlertBox from "../../../../../components/alert";

const initialAnswer = {
  ans_result: "",
  ans_list_opt: [{ ans_opt: "" }],
  checked: true
};
const initialQuestion = {
  Question: "",
  Answers: [initialAnswer],
  Topic: "",
};
const initialAlert = {
  is_show: false,
  title: "",
  content: "",
  variant: "danger"
};

const AddForm = ({
  item,
  handleValue,
  handleOptionValues,
  ...props
}) => {
  const [newQuestion, setNewQuestion] = useState({ ...initialQuestion });
  const [alertData, setAlertData] = useState(initialAlert);
  const [questionEditData, setQuestionEditData] = useState({ ...props.questionEditData });
  const [flag, setFlag] = useState(props.flag); // true is update, false is add
  const [listTopic, setListTopic] = useState([]);
  console.log("questionEditData", { ...props.questionEditData })
  useEffect(() => {
    fetchTopics(); 
  }, []);

  const fetchTopics = async () => {
      try {
          const response = await trainingApi.get_topics();
          if (response.code === 200) {
              setListTopic(response.data);
          } else {
              setAlertData((prev) => ({
                  ...prev,
                  title: "Lỗi",
                  content: "Tải dữ liệu chủ đề thi không thành công. ",
                  is_show: true,
                  variant: "danger"
              }));
          }
      } catch (error) {
          setAlertData((prev) => ({
              ...prev,
              title: "Lỗi",
              content: "Oops... Đã có lỗi xảy ra, vui lòng kiểm tra lại!",
              is_show: true,
              variant: "danger"
          }));
      }
  }

  let convertAnswerToObject = null
  if (questionEditData.answers) {
    convertAnswerToObject = JSON.parse(questionEditData.answers);
  }

  useEffect(() => {
    if (props.questionEditData) {
      setNewQuestion({
        Question: props.questionEditData.question || "",
        Topic: props.questionEditData.topic || "",
        Answers: props.questionEditData.answers
          ? JSON.parse(props.questionEditData.answers).ans_list_opt.map(ans => ({ ans_result: ans.ans_opt }))
          : [initialAnswer],
      });
    } else {
      if (newQuestion.Answers.length < 4) {
        const newAnswers = Array.from(
          { length: 3 - (newQuestion.Answers.length + 1) },
          () => ({ ...initialAnswer })
        );
        setNewQuestion((prevState) => ({
          ...prevState,
          Answers: [...prevState.Answers, ...newAnswers],
        }));
      }
    }
  }, [props.questionEditData]);

  const handleRadioClick = (e, currentIndex, alphabet) => {
    localStorage.setItem("radioValueContext", questionEditData.ans_result ? questionEditData.ans_result : alphabet);
  };

  const getAlphabetCharacter = (index) => Object.keys(listOptions[index])[0];

  const handleSaveQuestion = async () => {
    const { Question, Topic, Answers } = newQuestion;

    if (!Question || !Topic || !Answers.length || !localStorage.getItem("radioValueContext")) {
      setAlertData({
        title: "Lỗi",
        content: "Vui lòng điền đầy đủ thông tin.",
        is_show: true,
        variant: "danger"
      });
      return;
    }

    const isDataValid = Answers.every(answer => answer.ans_result.trim() !== "");

    if (!isDataValid) {
      setAlertData((prev) => ({
        ...prev,
        title: "Lỗi",
        content: "Vui lòng chọn đáp án đúng hoặc xóa bỏ câu đáp án không dùng.",
        is_show: true,
        variant: "danger"
      }));
      return;
    }

    const formattedAnswers = Answers.map(answer => ({ "ans_opt": answer.ans_result }));

    const _data = {
      "question": Question,
      "answers": JSON.stringify({
        "ans_result": localStorage.getItem("radioValueContext") !== "null" ? localStorage.getItem("radioValueContext") : questionEditData?.ans_result,
        "ans_list_opt": formattedAnswers,
      }),
      "topic": Topic,
    };

    try {
      let response = null;
      if (flag && questionEditData) {
        _data.id = questionEditData.id;
        response = await trainingApi.put_question(_data);
      } else {
        response = await trainingApi.post_question(_data);
      }
      if (response.code === 200) {
        setAlertData((prev) => ({
          ...prev,
          title: "Thông báo",
          content: "Câu hỏi đã được lưu thành công.",
          is_show: true,
          variant: "success"
        }));
      } else {
        setAlertData((prev) => ({
          ...prev,
          title: "Lỗi",
          content: "Đã xảy ra lỗi khi lưu câu hỏi.",
          is_show: true,
          variant: "danger"
        }));
      }
    } catch (error) {
      console.log(error);
      setAlertData((prev) => ({
        ...prev,
        title: "Lỗi",
        content: "Đã xảy ra lỗi khi lưu câu hỏi.",
        is_show: true,
        variant: "danger"
      }));
    }
  };

  const handleNewQuestionChange = (key, value) => {
    setNewQuestion({ ...newQuestion, [key]: value });
  };

  const handleAnswerChange = (key, value, index, alphabet) => {
    const updatedAnswers = [...newQuestion.Answers];
    const answer_value = value.startsWith(alphabet + ". ") ? value : alphabet + ". " + value
    updatedAnswers[index][key] = answer_value;
    setNewQuestion((prevState) => ({
      ...prevState,
      Answers: updatedAnswers.map((answer, i) => ({
        ...answer
      })),
    }));
  };

  const addNewAnswer = () => {
    const maxNumberOfAnswers = 5;
    const currentNumberOfAnswers = newQuestion.Answers.length;

    if (currentNumberOfAnswers < maxNumberOfAnswers) {
      const newAnswers = [...newQuestion.Answers];
      newAnswers.push({
        ans_result: "",
        ans_list_opt: [{ ans_opt: "" }],
        checked: false
      });
      setNewQuestion({
        ...newQuestion,
        Answers: newAnswers,
      });
    } 
    else if (currentNumberOfAnswers = maxNumberOfAnswers) {
      setNewQuestion({  
        ...newQuestion,
        Answers: [
          ...newQuestion.Answers,
          {
            ...initialAnswer,
          }],
      });
    }
  };

  const removeAnswer = (index) => {
    const updatedAnswers = [...newQuestion.Answers];
    updatedAnswers.splice(index, 1);
    setNewQuestion({ ...newQuestion, Answers: updatedAnswers });
  };
  
  return (
    <>
      <div
        style={{  
          marginBottom: "36px",
          border: "1px solid black",
          borderLeft: "8px solid rgb(14 233 33) !important",
          padding: "1rem",
        }}
      >
        <div>
          <div className="row g-1">
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-6">
                  <label style={{ fontWeight: 'bold', marginBottom: '8px' }}>Nhập nội dung câu hỏi * {flag ? (<span style={{fontStyle: 'italic'}}>Câu {questionEditData.id}</span>) : ""}</label>
                  <textarea
                    name="Question" 
                    onBlur={(e) => handleValue(item.id, e)}
                    className="form-control"
                    required={item.required}
                    placeholder="Nhập nội dung câu hỏi..."
                    style={{ marginBottom: "0.5rem", height: "200px", overflowWrap: "break-word" }}
                    defaultValue={flag ? questionEditData.question : newQuestion.Question}
                    onChange={(e) =>
                      handleNewQuestionChange("Question", e.target.value)
                    }
                  />
                </div>
                <div className="col-md-6">
                  <label style={{ fontWeight: 'bold', marginBottom: '8px' }}>Chọn chủ đề *</label>
                  <select
                    name="Topic"
                    className="form-control"
                    required={item.required}
                    placeholder={flag ? questionEditData.topic : "Chọn chủ đề"}
                    onChange={(e) =>
                      handleNewQuestionChange("Topic", e.target.value)
                    }
                  >
                    <option value={flag ? questionEditData.topic : "Chọn chủ đề"}>{flag ? questionEditData.topic : "Chọn chủ đề"}</option>
                    {listTopic.map((topic, index) => (
                      <option key={index} value={topic.topicName}>{topic.topicName}</option>
                    ))}
                  </select>
                </div>
              </div>
              <hr />
              <div className="row">
                <label style={{ fontWeight: 'bold', marginBottom: '8px' }}>Nhập các câu đáp án *</label>
              </div>
              {newQuestion.Answers.map((answer, index) => (
                <div className="col-md-12" key={index}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <input type="radio" name="IsResult" style={{ height: "22px", width: "22px" }}
                      defaultChecked={(
                        flag && convertAnswerToObject
                        && convertAnswerToObject.ans_result !== null
                        && convertAnswerToObject.ans_result !== undefined
                        && convertAnswerToObject.ans_result === getAlphabetCharacter(index))
                        ? true : false
                      }
                      defaultValue={(
                        flag && convertAnswerToObject
                        && convertAnswerToObject.ans_result !== null
                        && convertAnswerToObject.ans_result !== undefined)
                        ? (convertAnswerToObject.ans_result ?? getAlphabetCharacter(index))
                        : getAlphabetCharacter(index)
                      }
                      value={flag && (convertAnswerToObject.ans_result ?? getAlphabetCharacter(index))}
                      onClick={(e) => handleRadioClick(e, index, getAlphabetCharacter(index))}
                    />
                    <input name="Answers" className="form-control" style={{ marginBottom: "0.5rem", marginLeft: "0.5rem" }}
                      placeholder={`${getAlphabetCharacter(index)}. `}
                      data-name={`${getAlphabetCharacter(index)}`}
                      defaultValue={(
                        flag && convertAnswerToObject
                        && convertAnswerToObject.ans_list_opt[index]
                        && convertAnswerToObject.ans_list_opt[index].ans_opt !== null
                        && convertAnswerToObject.ans_list_opt[index].ans_opt !== "null"
                        && convertAnswerToObject.ans_list_opt[index].ans_opt !== undefined
                        && convertAnswerToObject.ans_list_opt[index].ans_opt !== "undefined"
                        && index < convertAnswerToObject.ans_list_opt.length)
                        ? (convertAnswerToObject.ans_list_opt[index].ans_opt ?? answer.ans_result)
                        : newQuestion.Answers[index].ans_result
                      }
                      onChange={(e) =>
                        handleAnswerChange("ans_result", e.target.value, index, getAlphabetCharacter(index))
                      }
                      onBlur={(e) =>
                        handleAnswerChange("ans_result", e.target.value, index, getAlphabetCharacter(index))
                      }
                      onFocus={(e) => {
                        const currentValue = e.target.value;
                        if (!currentValue.startsWith(`${getAlphabetCharacter(index)}. `)) {
                          e.target.value = `${getAlphabetCharacter(index)}. ${currentValue}`;
                        }
                      }}
                      onKeyDown={(e) => {
                        const caretPos = e.target.selectionStart;
                        if (caretPos <= `${getAlphabetCharacter(index)}. `.length && (e.key === 'Backspace' || e.key === 'Delete')) {
                          e.preventDefault();
                        }
                      }}
                    />
                    <button
                      type="button"
                      className="btn btn-outline-danger btn-sm"
                      onClick={() => removeAnswer(index)}
                      style={{ marginLeft: "0.5rem" }}
                    >Xóa</button>
                  </div>
                </div>
              ))}
              {(newQuestion.Answers.length < 5) ? (
                <button
                  style={{borderRadius: '0px'}}
                  type="button"
                  className="btn btn-primary col-md-2"
                  onClick={addNewAnswer}
                >Thêm đáp án</button>
              )
                : (
                  <button
                    type="button"
                    className="btn btn-primary col-md-2"
                    onClick={addNewAnswer}
                    style={{ display: 'none', borderRadius: '0px' }}
                  >Thêm đáp án</button>
                )
              }
              <button
                type="button"
                className="btn btn-success col-md-2"
                onClick={handleSaveQuestion}
                style={{ marginLeft: "0.5rem", borderRadius: '0px' }}
              >Lưu câu hỏi</button>
            </div>
          </div>
        </div>
        <hr />
      </div>
      <AlertBox data={alertData} onChange={(e) => { setAlertData((prev) => ({ ...prev, is_show: e })) }} />
    </>
  );
};

export default AddForm;
