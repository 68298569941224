import React, { useState, useRef, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import * as FaIcons from 'react-icons/fa';
import trainingApi from "../../../../../api/trainingApi";
import AlertBox from '../../../../../components/alert';

const initialAlert = {
    is_show: false,
    title: "",
    content: "",
    variant: "danger"
};

function ModalUpload({ showModal, handleClose }) {
    const [alertData, setAlertData] = useState(initialAlert);
    const fileUpload = useRef(null);
    const [fileName, setFileName] = useState('');

    const uploadExcel = async (e) => {
        const { name, files } = e.target;
        if (files && files.length > 0) {
            setFileName(files[0].name);
            try {
                var _data = {
                    file: files[0],
                };
                console.log("_data", _data)
                const response = await trainingApi.import_acc_exam(_data);
                if (response.code === 200) {
                    setAlertData({
                        is_show: true,
                        title: "Success",
                        content: "Upload file Excel thành công!",
                        variant: "success"
                    });
                } else {
                    setAlertData({
                        is_show: true,
                        title: "Error",
                        content: "Upload file thất bại! Vui lòng kiểm tra lại!",
                        variant: "danger"
                    });
                }
            } catch (error) {
                setAlertData({
                    is_show: true,
                    title: "Error",
                    content: "An error occurred while uploading the file",
                    variant: "danger"
                });
            } finally {
                if (fileUpload.current) {
                    fileUpload.current.value = "";
                }
            }
        }
    };

    const getTemplate = async () => {
        try {
            const response = await trainingApi.get_acc_exam_excel_template();
            if (response.status === 200) {
                // Handle the response, such as triggering a file download
                setAlertData({
                    is_show: true,
                    title: "Thông báo",
                    content: "Tải template về máy thành công!",
                    variant: "success"
                });
            }
        } catch (error) {
            setAlertData({
                is_show: true,
                title: "Error",
                content: "An error occurred while downloading the template",
                variant: "danger"
            });
        }
    };

    const handleUploadClick = () => {
        fileUpload.current.click();
    };

    return (
        <>
            <div className='container'>
                <div className='row'>
                    <div className='col-sm-12'>
                        <table className='table table-bordered'>
                            <tbody>
                                <tr>
                                    <td style={{ width: "70px" }}>Bước 1</td>
                                    <td>Tải (download) file template</td>
                                    <td className='text-center' style={{ width: "165px" }}>
                                        <button type="button" className="btn btn-outline-dark" onClick={getTemplate}><FaIcons.FaDownload /> Tải file xuống</button>
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ width: "70px" }}>Bước 2</td>
                                    <td colSpan={2}>Nhập thông tin</td>
                                </tr>
                                <tr>
                                    <td style={{ width: "70px" }}>Bước 3</td>
                                    <td>
                                        Tải (upload) file template
                                        {fileName && <div><strong>File đã chọn:</strong> {fileName}</div>}
                                    </td>
                                    <td style={{ width: "165px" }}>
                                        <div className='position-relative text-center'>
                                            <button type="button" className="btn btn-outline-primary" onClick={handleUploadClick}><FaIcons.FaUpload /> Tải file</button>
                                            <input
                                                type="file"
                                                ref={fileUpload}
                                                onChange={uploadExcel}
                                                style={{ opacity: "0", position: "absolute", width: "100%", top: 0, left: 0 }}
                                                className="position-absolute w-100"
                                            />
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ModalUpload;
