import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

function ValidateMessage(props) {
    const { isShow, msgType, ...other } = props;
    const [{ msg }, setSelected] = useState({
        msg: "",
    })
    useEffect(() => {
        if (isShow) {
            var _msg = "";
            switch (msgType) {
                case "required":
                    _msg = "Bắt buộc nhập.";
                    break;
                case "invalid":
                    _msg = "Giá trị không hợp lệ.";
                    break;
                default:
                    _msg = msgType;
                    break;
            }
            setSelected((prev) => ({
                ...prev,
                msg: _msg
            }))
        }
    }, [msgType, isShow])
    return (
        <>
            {isShow && (
                <label className='form-label text-danger'>{msg}</label>
            )}
        </>
    )
}

ValidateMessage.propTypes = {
    msgType: PropTypes.string.isRequired,
    isShow: PropTypes.bool.isRequired,
}

export default ValidateMessage
