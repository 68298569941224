/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import { Container, Row, Col } from "react-bootstrap";
import * as FaIcon from "react-icons/fa";
import fbBanner from "../../../../assets/images/logo.svg";

const Header = () => {
    return (
        <>
            <div className="bg-fubon">
                <div className="bg-fubon" style={{ height: "40px", marginBottom: "0px" }}>
                    <Container fluid>
                        <Row className="bg-fubon--gradient" style={{ padding: "5px 0", height: "40px" }}>
                            <Col className="position-relative">
                                <div className="position-absolute start-0" style={{ top: "3px" }}>
                                    <div className="container">
                                        <div className="row">
                                            <div className="col">
                                                <FaIcon.FaEnvelope style={{ color: "#fff" }} /> &nbsp;
                                                <a href="mailto:cskh.ins@fubon.com"
                                                    className="form-label"
                                                    style={{ color: "#fff", textDecoration: "none" }}>
                                                    cskh.ins@fubon.com
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row className=" navbar-light bg-light">
                            <Col>
                                <nav className="navbar navbar-expand-lg" style={{}}>
                                    <div className="container-fluid" style={{ padding: "0px" }}>
                                        <a className="navbar-brand" href="https://fubonins.com.vn/" target="_blank">
                                            <img src={fbBanner} />
                                        </a>
                                    </div>
                                </nav>
                                <div className="clearfix"></div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
            <div className="clearfix" style={{ marginTop: "75px" }}></div>
        </>
    );
};

export default Header;