/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import { Container, Row } from "react-bootstrap";
import fbBanner from "../../../../assets/images/logo.svg";

const Footer = () => {
    return (
        <>
            <footer style={{ margin: "40px 0" }}>
                <div className="bg-fubon">
                    <div className="bg-fubon--gradient" style={{ height: "4px" }}></div>
                </div>
                <Container >
                    <Row>
                        <div className="col-sm-12" style={{ paddingTop: "10px", paddingBottom: "10px", borderBottom: "2px solid #a1a1a1", marginBottom: "20px" }}>
                            <a className="navbar-brand d-block" href="https://fubonins.com.vn/" target="_blank" style={{ marginTop: "20px" }}>
                                <img src={fbBanner} />
                            </a>
                        </div>
                    </Row>
                    <Row>
                        <div className="col-sm-4">
                            <div>
                                <h5>Trụ sở chính</h5>
                            </div>
                            <div>
                                <a
                                    className="nav-link "
                                    href="https://goo.gl/maps/kRqRYbZQfKaWJvS28" target="_blank"
                                >
                                    Tầng 15, Tòa nhà Ree Tower, Số 9, Đường Đoàn Văn Bơ, Quận 4, TP.HCM
                                </a>
                            </div>
                            <div>
                                Điện thoại: <a href="tel:(84) 28 39435678">(84) 28 39435678</a>
                            </div>
                            <div>
                                Fax: (84) 28 39435363
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div>
                                <h5>Chi nhánh Hà Nội</h5>
                            </div>
                            <div>
                                <a
                                    className="nav-link "
                                    href="https://goo.gl/maps/3NcoyJdUBMHBwk7y6" target="_blank"
                                >
                                    Tầng 3, Tòa nhà Lakeside Green, Số 33 Trúc Bạch, Phường Trúc Bạch, Quận Ba Đình, Hà Nội
                                </a>
                            </div>
                            <div>
                                Điện thoại: <a href="tel:(84) 24 39347225">(84) 24 39347225</a>
                            </div>
                            <div>
                                Fax: (84) 24 39347226
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div>
                                <h5>Chi nhánh Bình Dương</h5>
                            </div>
                            <div>
                                <a
                                    className="nav-link "
                                    href="https://goo.gl/maps/xqktFArUDiGDhwhu7" target="_blank"
                                >
                                    Số 226, đường GS 01, Khu ĐT-TM-DV Quảng Trường Xanh, khu phố Nhị Đồng 2, phường Dĩ An, thành phố Dĩ An, tỉnh Bình Dương
                                </a>
                            </div>
                            <div>
                                Điện thoại: <a href="tel:(84) 274 3776531">(84) 274 3776531</a>
                            </div>
                            <div>
                                Fax: (84) 274 3776534
                            </div>
                        </div>
                    </Row>
                </Container>
            </footer>
        </>
    );
};

export default Footer;