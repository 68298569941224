import React, { useState, useEffect, useRef } from 'react';
import { Accordion } from 'react-bootstrap';
import CommonHelper from '../../../helpers/common';
import TermCondition from '../../ctpl/vehicle/component/term-condition';
import wording from '../../../assets/doc/WORDING PET.pdf';
import wording_summary from '../../../assets/doc/PET INS 2024 VN.pdf';
import InsuredPetConfirmation from './insured-pet-confirmation';
import { FaFileDownload } from "react-icons/fa";
import { initial_confirmation } from '../util';
import * as FaIcons from 'react-icons/fa';

function PetConfirmation(props) {
    const {
        onCheck,
        onSendConfirmMail,
        onGetStatus,
        list_pet,
        user,
        user_type,
        data: valueProp,
        step_code,
        res_post_order,
        paymentStatus,
        paymentSuccess,
        state_appraisal,
        setStateAppraisal,
        ...other
    } = props;
    const [state, setState] = useState(initial_confirmation);
    const [{
        showLoading,
        showModal,
        confirm1,
        confirm2,
        objUser
    }, setShow] = useState(
        {
            showLoading: false,
            showModal: false,
            confirm1: false,
            confirm2: false,
            objUser: null
        }
    );
    const [listPetPass, setListPetPass] = useState([]);
    const [listPetFailed, setListPetFailed] = useState([]);
    useEffect(() => {
        if (valueProp != null) {
            setState(valueProp);
        } else {
            setState(initial_confirmation);
        }
    }, [valueProp]);
    useEffect(() => {
        setShow((prev) => ({
            ...prev, objUser: user ?? { user_type: 1 }
        }))
    }, [user]);
    useEffect(() => {
        onCheck(confirm1 && confirm2);
    }, [confirm1, confirm2]);

    const handle_check = (e) => {
        const { name, checked } = e.target;
        setShow((prevData) => ({
            ...prevData,
            [name]: checked,
        }));
    };
    const get_order = () => {
        onGetStatus();
    }

    return (
        <>
            <div className='row'>
                <div className='col-sm-12 mt15px'>
                    <div className='card'>
                        <div className='card-body'>
                            <h5 
                                style={{ 
                                    borderLeft: '4px solid #20c997', 
                                    paddingLeft: '8px' 
                                }}
                            >
                                THÔNG TIN BẢO HIỂM
                            </h5>
                            <div className='mb10px d-flex'>
                                <div>
                                    <label>
                                        Hiệu lực bảo hiểm:
                                    </label>
                                </div>
                                <div className='px-2' >
                                    <strong>
                                        {CommonHelper.convertToDDMMYYYYHHMM(state.veh_fm_dt) + " - " + CommonHelper.convertToDDMMYYYYHHMM(state.veh_to_dt)}
                                    </strong>
                                </div>
                                <div className='clearfix'></div>
                            </div>
                            <div className='mb10px d-flex'>
                                <div >
                                    <label>
                                        Số thú cưng được bảo hiểm:
                                    </label>
                                </div>
                                <div className='px-2'>
                                    <strong>
                                        {state.veh_pax}
                                    </strong>
                                    {/* <strong>{listPetPass.length ?? ""}</strong> */}
                                </div>
                                <div className='clearfix'></div>
                            </div>
                            <div className='mb10px d-none'>
                                <div 
                                    className='float-start' 
                                    style={{ width: "40%" }}
                                >
                                    <label>
                                        Gói bảo hiểm:
                                    </label>
                                </div>
                                <div 
                                    className='float-end text-end' 
                                    style={{ width: "59%" }}
                                >
                                    <strong>
                                        {state.pet_package.pkg_name}
                                    </strong>
                                </div>
                                <div className='clearfix'></div>
                            </div>
                            <div className='mb10px d-none'>
                                <div 
                                    className='float-start' 
                                    style={{ width: "40%" }}
                                >
                                    <label>
                                        Phí:
                                    </label>
                                </div>
                                <div 
                                    className='float-end text-end' 
                                    style={{ width: "59%" }}
                                >
                                    <strong>
                                        {CommonHelper.format_currency_vnd_style(state.pet_package.dog_premium ?? state.pet_package.cat_premium)}
                                    </strong>
                                </div>
                                <div className='clearfix'></div>
                            </div>
                        </div>
                    </div>
                    <div className='card mt-1'>
                        <div className='card-body'>
                            <h5 
                                style={{ 
                                    borderLeft: '4px solid #20c997', 
                                    paddingLeft: '8px' 
                                }}
                            >
                                BÊN MUA BẢO HIỂM
                            </h5>
                            <p style={{ fontStyle: 'italic' }}>
                                <span 
                                    style={{ 
                                        color: 'red', 
                                        fontWeight: '700' 
                                    }}
                                >
                                    (*) Bên mua bảo hiểm
                                </span> đồng thời là Người được bảo hiểm (Chủ thú cưng)
                            </p>
                            <div className='mb10px d-flex'>
                                <div >
                                    <label>
                                        Họ và tên:
                                    </label>
                                </div>
                                <div className='px-2' >
                                    <strong>
                                        {CommonHelper.to_upper(state.veh_customer)}
                                    </strong>
                                </div>
                                <div className='clearfix'></div>
                            </div>
                            <div className='mb10px d-flex'>
                                <div >
                                    <label>
                                        Số điện thoại:
                                    </label>
                                </div>
                                <div className='px-2'>
                                    <strong>
                                        {state.veh_phone}
                                    </strong>
                                </div>
                                <div className='clearfix'></div>
                            </div>
                            <div className='mb10px d-flex'>
                                <div >
                                    <label>
                                        Email:
                                    </label>
                                </div>
                                <div className='px-2'>
                                    <strong>
                                        {state.veh_email}
                                    </strong>
                                </div>
                                <div className='clearfix'></div>
                            </div>
                            <div className='mb10px d-flex'>
                                <div >
                                    <label>
                                        Địa chỉ:
                                    </label>
                                </div>
                                <div className='px-2'>
                                    {
                                        (state.pet_action == "new") ? (<>
                                            <strong>
                                                {state.veh_address + ", " + state.veh_district_name + ", " + state.veh_province_name}
                                            </strong>
                                        </>) : (<>
                                            <strong>
                                                {state.veh_address}
                                            </strong>
                                        </>)
                                    }
                                    {/* <strong>
                                        {extractAddressDetails(state.veh_address)}
                                    </strong> */}
                                    {/* <strong>
                                        {state.veh_address}
                                    </strong> */}
                                </div>
                                <div className='clearfix'></div>
                            </div>
                        </div>
                    </div>
                    {objUser?.user_type > 1 && state.status != 3 && (
                        <div className='card mt-1'>
                            <div className='card-body'>
                                <h5 
                                    style={{ 
                                        borderLeft: '4px solid #20c997', 
                                        paddingLeft: '8px' 
                                    }}
                                >
                                    XÁC NHẬN
                                </h5>
                                <div className='row'>
                                    {(state.status == 1 || state.status == 6) && (
                                        <>
                                            <div className='col-sm-10'>
                                                <a 
                                                    className="link-primary cursor-pointer" 
                                                    onClick={() => { onSendConfirmMail() }}
                                                >
                                                    Gửi mail yêu cầu xác  nhận
                                                </a>
                                            </div>
                                            <div className='col-sm-2'>
                                                <a 
                                                    className="link-primary cursor-pointer" 
                                                    onClick={get_order}
                                                >
                                                    <FaIcons.FaUndo style={{ fontSize: "20px" }} />
                                                </a>
                                            </div>
                                        </>
                                    )}
                                    {(state.status == 7 || state.status == 2) && (
                                        <div className='col-sm-12'>
                                            <span>
                                                <FaIcons.FaCheck style={{ color: "#07a556" }} /> Khách hàng đã xác nhận
                                            </span>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    )}
                    <div className='card mt-1'>
                        <Accordion defaultActiveKey="0">
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>
                                    <p
                                        style={{
                                            borderLeft: '4px solid #20c997',
                                            paddingLeft: '8px',
                                            fontSize: '1.25rem',
                                            color: 'black',
                                            fontWeight: '700'
                                        }}
                                    >
                                        DANH SÁCH THÚ CƯNG
                                    </p>
                                </Accordion.Header>
                                <Accordion.Body>
                                    <InsuredPetConfirmation
                                        dataPet={list_pet}
                                        num_of_pax={valueProp.veh_pax}
                                        hasActionPet={false}
                                        onEditPet={() => { }}
                                        onDelPet={() => { }}
                                        step_code={step_code}
                                        res_post_order={res_post_order}
                                        updateListPetPass={setListPetPass}
                                        updateListPetFailed={setListPetFailed}
                                        state_appraisal={state_appraisal}
                                        setStateAppraisal={setStateAppraisal}
                                    />
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </div>
                </div>
                <div className='col-sm-12 mt15px d-none'>
                    <div className='alert alert-info'>
                        <div className='row'>
                            <div className='col'>
                                <b>
                                    Tổng phí bảo hiểm
                                </b>
                            </div>
                            <div className='col text-end'>
                                <strong>
                                    {CommonHelper.format_currency_vnd_style(state.veh_premium)}
                                </strong>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-sm-12 mt-2 d-none'>
                    <div className='card'>
                        <div className='card-body'>
                            <h5 
                                style={{ 
                                    borderLeft: '4px solid #20c997', 
                                    paddingLeft: '8px' 
                                }}
                            >
                                MÃ GIẢM GIÁ/VOUCHER
                            </h5>
                            <div className='row mt-4'>
                                <div className='col-sm-6'>
                                    <label className={"form-label"}>
                                        Nhập mã giảm giá/ Voucher:
                                    </label>
                                    <input
                                        name="ma_voucher"
                                        class="form-control form-control-sm ptb-16px "
                                        type="text"
                                        value=""
                                        style={{ 
                                            marginTop: '8px', 
                                            paddingTop: '8px', 
                                            paddingBottom: '8px' 
                                        }}
                                    />
                                </div>
                                <div className='col-sm-6'>
                                    <button
                                        className="btn btn-primary ms-1"
                                        style={{
                                            borderRadius: '0px',
                                            marginTop: '40px',
                                            backgroundColor: 'rgb(32, 201, 151)',
                                            border: 'none'
                                        }}
                                    >
                                        {"ÁP DỤNG"}
                                    </button>
                                </div>
                            </div>
                            <div className='form-check'>
                            </div>
                        </div>
                    </div>
                </div>
                {state.status == 1 && (
                    <>
                        {(paymentSuccess == false
                            && !paymentStatus.veh_order_no
                            && !paymentStatus.cert_url
                            && state.aml_risk_score < 70
                            && state.aml_total_score < 70)
                            && (user_type == null || user_type == "" || user_type <= 1)
                            && (
                                <div className='col-sm-12 mt-2'>
                                    <div className='card'>
                                        <div className='card-body'>
                                            <div className='form-check'>
                                                <input
                                                    type="checkbox"
                                                    className='form-check-input'
                                                    value=""
                                                    disabled={state.status == 2}
                                                    checked={confirm1}
                                                    id="chk-confirm1"
                                                    name='confirm1'
                                                    onChange={handle_check}
                                                    style={{cursor: 'pointer'}}
                                                />
                                                <label 
                                                    className='form-check-label' 
                                                    htmlFor='chk-confirm1'
                                                >
                                                    Tôi cam kết: Các thông tin được cung cấp ở trên đều trung thực, chính xác;
                                                    Tôi đồng ý cho Công ty Fubon sử dụng các thông tin này cho mục đích giao kết,
                                                    thực hiện Hợp đồng bảo hiểm và chia sẽ cho các Công ty trong cùng
                                                    Tập đoàn Tài chính Fubon cho mục đích báo cáo, thống kê và các hoạt động nghiệp vụ.
                                                </label>
                                            </div>
                                            <div className='form-check'>
                                                <input
                                                    type="checkbox"
                                                    className='form-check-input'
                                                    value=""
                                                    disabled={state.status == 2}
                                                    checked={confirm2}
                                                    id="chk-confirm2"
                                                    name='confirm2'
                                                    onChange={handle_check}
                                                    style={{cursor: 'pointer'}}
                                                />
                                                <label 
                                                    className='form-check-label' 
                                                    htmlFor='chk-confirm2'
                                                >
                                                    Tôi cam kết: Tôi đã đọc, hiểu rõ
                                                    <a
                                                        href={wording_summary}
                                                        target="_blank"
                                                        style={{
                                                            marginLeft: '4px',
                                                            marginRight: '4px',
                                                            textDecoration: 'none',
                                                            fontWeight: '700'
                                                        }}
                                                        rel="noreferrer"
                                                    >
                                                        các quyền lợi bảo hiểm, điều khoản loại trừ trách nhiệm bảo hiểm,
                                                        quyền và nghĩa vụ của Bên mua bảo hiểm</a>
                                                    và đồng ý với các điều kiện, điều khoản bảo hiểm trong
                                                    <a
                                                        href={wording}
                                                        target="_blank"
                                                        style={{
                                                            marginLeft: '4px',
                                                            textDecoration: 'none',
                                                            fontWeight: '700'
                                                        }}
                                                        rel="noreferrer"
                                                    >
                                                        Quy tắc bảo hiểm thú cưng
                                                    </a>.
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                    </>
                )}
                {paymentSuccess == true && (
                    <div className='col-sm-12 mt-2 text-center'>
                        <a
                            href={state.cert_url} target='_blank'
                            download={"policy-" + state.veh_order_no + ".pdf"}
                            className='btn btn-success'
                            style={{ fontSize: '20px', borderRadius: '0px' }}
                            rel="noreferrer"
                        >
                            Download giấy chứng nhận
                            <FaFileDownload style={{ marginLeft: '4px' }} />
                        </a>
                    </div>
                )}
            </div>
            <TermCondition 
                show={showModal} 
                onClose={(e) => { setShow((prev) => ({ ...prev, showModal: e })) }} 
            />
        </>
    )
}

export default PetConfirmation;