/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-no-target-blank */
import React, { useState, useEffect } from 'react'
import fbBanner from "../../../../../assets/images/logo.svg";
import CommonHelper from '../../../../../helpers/common';
import { Container, Row, Col, Modal, Button } from "react-bootstrap";
import * as FaIcon from "react-icons/fa";
import TextInput from '../../../../../components/text-input';
import DateInput from '../../../../../components/date-input';
import ShortDateInput from '../../../../../components/short-date-input';
import AlertBox from '../../../../../components/alert';
import trainingApi from '../../../../../api/trainingApi';
import { Link } from 'react-router-dom';
import file from "../../../../../assets/doc/THU DONG Y XU LY.pdf";
import SwitchCheck from '../../../../../components/switch-check';
import "./cms-register-agent-exam.css";
import OnlineApi from '../../../../../api/onlineApi';
import addYears from "date-fns/addYears";
import ValidateMessage from '../../../../../components/validate-msg';
import { addDays, addMonths } from 'date-fns';
import ReactDatePicker from 'react-datepicker';
import ModalUpload from '../component/modal-upload';
import UploadAgentList from '../component/upload-agent-reg-info';

const initialAlert = {
    is_show: false,
    title: "",
    content: "",
    variant: "danger"
};

const initial = {
    fullname: "",
    dob: "",
    sex: "",
    identity: "",
    date_of_issue: "",
    place_of_issue: "",
    address: "",
    phone: "",
    email: "",
    exam_purpose: "",
    organization: "",
    exam_time: "",
    exam_place: "",
};

function CMSRegisterAgentExam() {
    const [state, setState] = useState(initial);
    const [alertData, setAlertData] = useState(initialAlert);
    const [stateError, setStateError] = useState({
        fullname: false,
        dob: false,
        sex: false,
        identity: false,
        date_of_issue: false,
        place_of_issue: false,
        // address: false,
        // phone: false,
        email: false,
        exam_purpose: false,
        // organization: false,
        exam_time: false,
        exam_place: false,
    });
    const [termsAccepted, setTermsAccepted] = useState(false);
    const [provinces, setProvinces] = useState([]);
    const [{ dob, minDOB, maxDOB, exam_time, minExamTime, maxExamTime, doi, minDOI, maxDOI }, setSelected] = useState({
        dob: null,
        minDOB: addYears(new Date(), -65),
        maxDOB: addYears(new Date(), -18),
        exam_time: null,
        minExamTime: addMonths(new Date(), 1),
        maxExamTime: addMonths(new Date(), 6),
        minDOI: addYears(new Date(), -10),
        maxDOI: new Date(),
    })
    const [startDate, setStartDate] = useState(null);
    const [showModalAcc, setShowModalAcc] = useState(false);
    const [showModalAgent, setShowModalAgent] = useState(false);

    const getMinDate = () => {
        const today = new Date();
        return new Date(today.getFullYear(), today.getMonth(), 1);
    };

    const getMaxDate = () => {
        const today = new Date();
        return new Date(today.getFullYear(), 11, 31);
    };

    useEffect(() => {
        const fetchProvinces = async () => {
            try {
                const response = await OnlineApi.get_provinces();
                if (response) {
                    setProvinces(response);
                }
            } catch (error) {
                console.error("Error fetching provinces:", error);
            }
        };
        fetchProvinces();
    }, []);

    const handle_change = (e) => {
        const { name, value } = e.target;
        var _val = value;

        setState((prevData) => ({ ...prevData, [name]: _val }));
        if(_val !== null && _val !== undefined) {
            setStateError((prev) => ({ ...prev, [name]: false }))
        }
    };

    const validate_form = () => {
        var flag = true;
        var _data = { ...state };

        let errors = {};
        if (CommonHelper.is_empty(_data.fullname)) {
            flag = false;
            errors.fullname = true;
        } else if (CommonHelper.is_empty(_data.dob)) {
            flag = false;
            errors.dob = true;
        } else if (CommonHelper.is_empty(_data.sex)) {
            flag = false;
            errors.sex = true;
        } else if (CommonHelper.is_empty(_data.identity)) {
            flag = false;
            errors.identity = true;
        } else if (!CommonHelper.check_idcard_valid(_data.identity)) {
            flag = false;
            errors.identity = true;
        } else if (CommonHelper.is_empty(_data.date_of_issue)) {
            flag = false;
            errors.date_of_issue = true;
        } else if (CommonHelper.is_empty(_data.place_of_issue)) {
            flag = false;
            errors.place_of_issue = true;
        } else if (!CommonHelper.is_empty(_data.email) && !CommonHelper.validate_email_format(_data.email)) {
            flag = false;
            errors.email = true;
        } else if (CommonHelper.is_empty(_data.exam_time)) {
            flag = false;
            errors.exam_time = true;
        } else if (CommonHelper.is_empty(_data.exam_place)) {
            flag = false;
            errors.exam_place = true;
        } else {
            errors.fullname = false;
        }

        setStateError((prev) => ({
            ...prev,
            ...errors
        }));

        // console.log("_flag", flag);
        return flag;
    }

    const handleRegister = async (state) => {
        try {
            const _data = {...state};
            _data.sex = (state.sex === "true");
            _data.dob = CommonHelper.formatToYYYYMMDD(state.dob);
            _data.date_of_issue = CommonHelper.formatToYYYYMMDD(state.date_of_issue);
            _data.exam_purpose = (state.exam_purpose === "true") ? "Làm đại lý bảo hiểm cá nhân" : "Làm đại lý viên của tổ chức";
            _data.exam_time = CommonHelper.formatToYYYYMMDD(state.exam_time);
            console.log("_data", _data)

            const response = await trainingApi.post_agent_reg_info(JSON.stringify(_data));
            console.log(response);
            if (response.code === 200) {
                return response;
            } else {
                console.error("Invalid response format", response);
                return null;
            }
        } catch (error) {
            console.error("Error during post_agent_reg_info API call:", error);
            return null;
        }
    };

    const submit = async () => {
        var flag = validate_form();
        // console.log("flag", flag);
        if (!flag) {
            // console.log("stateError", stateError);
            const isChecked = stateError.fullname || stateError.dob 
            || stateError.sex || stateError.identity 
            || stateError.date_of_issue || stateError.place_of_issue;
            // console.log("isChecked", isChecked)
            if(isChecked) {
                setAlertData((prev) => ({
                    ...prev,    
                    title: "Lỗi",
                    content: "Vui lòng nhập đầy đủ thông tin đăng ký!",
                    is_show: true,
                    variant: "danger"
                }));
            }
            if(stateError.exam_time === true) {
                setAlertData((prev) => ({
                    ...prev,    
                    title: "Lỗi",
                    content: "Vui lòng nhập thông tin thời gian thi mong muốn!",
                    is_show: true,
                    variant: "danger"
                }));
            }
            if(stateError.exam_place === true) {
                setAlertData((prev) => ({
                    ...prev,    
                    title: "Lỗi",
                    content: "Vui lòng nhập thông tin địa điểm thi mong muốn!",
                    is_show: true,
                    variant: "danger"
                }));
            }
            if(stateError.identity === true) {
                setAlertData((prev) => ({
                    ...prev,    
                    title: "Cảnh báo",
                    content: "Vui lòng nhập đúng số CMTND quy định (CMND: 9 số, CCCD: 12 số)!",
                    is_show: true,
                    variant: "warning"
                }));
            }
        } else {
            const response = await handleRegister(state);
            // console.log("response", response)
            if (!response) {
                setAlertData((prev) => ({
                    ...prev,
                    title: "Lỗi",
                    content: "Đăng ký không thành công. Vui lòng kiểm tra lại thông tin đăng ký!",
                    is_show: true,
                    variant: "danger"
                }));
                return;
            } else {
                setTermsAccepted(false);
                setAlertData((prev) => ({
                    ...prev,
                    title: "Thông báo",
                    content: "Đăng ký thông tin thi chứng chỉ Đại lý bảo hiểm thành công!",
                    is_show: true,
                    variant: "success"
                }));
            }
        }
    }

    const change_dob = (val) => {
        setState((prev) => ({ ...prev, dob: val }));
        setStateError((prev) => ({ ...prev, dob: false }))
    };

    const change_doi = (val) => {
        setState((prev) => ({ ...prev, date_of_issue: val }));
        setStateError((prev) => ({ ...prev, date_of_issue: false }))
    };

    const change_exam_time = (val) => {
        setStartDate(val);
        setState((prev) => ({ ...prev, exam_time: val }));
        setStateError((prev) => ({ ...prev, exam_time: false }))
    };

    const handleModalClose_Acc = () => setShowModalAcc(false);
    const handleModalShow_Acc = () => setShowModalAcc(true);
    const handleModalClose_Agent = () => setShowModalAgent(false);
    const handleModalShow_Agent = () => setShowModalAgent(true);

    return (
        <div className='bg-agent-exam-info responsive-container'>
            <div style={{ position: "absolute", top: "20px", left: "20px" }}>
                <a className="navbar-brand" href="https://fubonins.com.vn/" target="_blank">
                    <img src={fbBanner} />
                </a>
            </div>
            <div className='login-panel agent-reg-info' style={{ width: '700px', marginTop: '0px' }}>
                <div className='text-center responsive-title'><h1>ĐĂNG KÝ THI CHỨNG CHỈ <br /> ĐẠI LÝ BẢO HIỂM - HỖ TRỢ GIÚP NGƯỜI ĐĂNG KÝ</h1></div>
                <div >
                    <div className='row mt15px'>
                        <div className='col-md-6'>
                            <div className="form-floating responsive-control">
                                <TextInput name="fullname" placeholder="Họ và tên" value={state.fullname} onChange={handle_change} 
                                    style={{ borderRadius: '0px' }} className={stateError.fullname === true ? "error" : ""} />
                                <label className='required'>Họ và tên</label>
                            </div>
                            <div className="mt10px responsive-control">
                                <div className='col-sm-12'>
                                <label className='form-label required' style={{ marginBottom: '4px' }}>Ngày sinh</label>
                                    <ShortDateInput selected={state.dob} minDate={minDOB} maxDate={maxDOB} onChange={change_dob}
                                        style={{ height: '58px', borderRadius: '0px' }} className={stateError.dob === true ? "error" : ""} 
                                    />
                                </div>
                            </div>
                            <div className="form-floating mt15px responsive-control">
                                <select name="sex" value={state.sex} onChange={handle_change} style={{ cursor: 'pointer', borderRadius: '0px' }}
                                    className={stateError.sex === true ? "error form-select" : "form-select"} 
                                >
                                    <option value="">Chọn giới tính</option>
                                    <option value="true">Nam</option>
                                    <option value="false">Nữ</option>
                                </select>
                                <label >Chọn giới tính</label>
                            </div>
                            <div className="form-floating mt15px responsive-control">
                                <TextInput name="identity" placeholder="Nhập số CMTND" value={state.identity} onChange={handle_change} 
                                    style={{ borderRadius: '0px' }} className={stateError.identity === true ? "error" : ""} 
                                />
                                <label className='required'>Nhập số CMTND</label>
                            </div>
                            <div className="mt5px responsive-control">
                                <div className='col-sm-12'>
                                <label className='form-label required' style={{ marginBottom: '4px', borderRadius: '0px' }}>Ngày cấp</label>
                                    <ShortDateInput selected={state.date_of_issue} minDate={minDOI} maxDate={maxDOI} onChange={change_doi}
                                        style={{ height: '58px', borderRadius: '0px' }} className={stateError.date_of_issue === true ? "error" : ""} 
                                    />
                                </div>
                            </div>
                            <div className="form-floating mt15px responsive-control" style={{ marginBottom: '15px' }}>
                                <TextInput name="place_of_issue" placeholder="Nơi cấp" value={state.place_of_issue} onChange={handle_change} 
                                    style={{ borderRadius: '0px' }} className={stateError.place_of_issue === true ? "error" : ""} 
                                />
                                <label className='required'>Nơi cấp</label>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className="form-floating responsive-control">
                                <TextInput name="address" placeholder="Địa chỉ thường trú" value={state.address} onChange={handle_change} style={{ borderRadius: '0px' }} />
                                <label >Địa chỉ thường trú</label>
                            </div>
                            <div className="form-floating mt15px responsive-control">
                                <TextInput name="email" placeholder="Email" value={state.email} 
                                    onChange={handle_change} style={{ borderRadius: '0px' }} className={stateError.email === true ? "error" : ""} 
                                />
                                <label >Email</label>
                            </div>
                            <div className="form-floating mt15px responsive-control">
                                <TextInput name="phone" placeholder="Điện thoại" value={state.phone} onChange={handle_change} style={{ borderRadius: '0px' }} 
                                />
                                <label >Điện thoại</label>
                            </div>
                            <div className="form-floating mt15px responsive-control">
                                <div style={{ marginBottom: '0px', fontWeight: 'bold' }}>Mục đích đăng ký thi:</div>
                                <div style={{ fontSize: '14px' }}>Làm đại lý bảo hiểm cá nhân: 
                                    <input name="exam_purpose" type="radio" checked={state.exam_purpose !== "true"} value={false}
                                        onClick={handle_change} style={{ cursor: 'pointer', height: '16px', width: '16px', marginLeft: '8px' }} 
                                    />
                                </div>
                                <div style={{ fontSize: '14px' }}>Làm đại lý viên của tổ chức: 
                                    <input name="exam_purpose" type="radio" checked={state.exam_purpose === "true"} value={true} 
                                        onClick={handle_change} style={{ cursor: 'pointer', marginLeft: '17px', height: '16px', width: '16px' }} 
                                    />
                                </div>
                            </div>
                            <div className="form-floating mt5px responsive-control">
                                <TextInput name="organization" placeholder="Tên tổ chức" value={state.organization} onChange={handle_change} style={{ borderRadius: '0px' }} />
                                <label >Tên tổ chức</label>
                            </div>
                            <div className="mt15px responsive-control">
                                <label className='form-label required' style={{ marginBottom: '4px' }}>Thời gian thi mong muốn</label>
                                <div className='col-sm-12'>
                                    <ReactDatePicker selected={startDate} onChange={(date) => change_exam_time(date)} dateFormat="MM/yyyy"
                                        minDate={getMinDate()} maxDate={getMaxDate()}
                                        showMonthYearPicker isClearable style={{ height: '58px', borderRadius: '0px', border: '0px solid #ccc' }}
                                        className={stateError.exam_time ? "error" : ""}
                                    />
                                </div>
                            </div>
                            <div className="form-floating mt15px responsive-control">
                                <select name="exam_place" value={state.exam_place} onChange={handle_change} style={{ cursor: 'pointer', borderRadius: '0px' }}
                                    className={stateError.exam_place === true ? "error form-select" : "form-select"} 
                                >
                                    <option value="">Chọn địa điểm</option>
                                    {provinces.map((province, index) => (
                                        <option key={index} value={province.name}>{province.name}</option>
                                    ))}
                                </select>
                                <label >Địa điểm thi mong muốn</label>
                            </div>
                        </div>
                    </div>
                    <div className='mt15px responsive-button' style={{ textAlign: 'center' }}>
                        <button className="btn btn-primary ms-1 " style={{ height: '60px', width: '150px', marginRight: '16px', borderRadius: '0px', fontWeight: '700' }} onClick={() => submit()} disabled={!termsAccepted}>
                            ĐĂNG KÝ
                        </button>
                        <button className="btn btn-success responsive-button-detail" style={{ height: '60px', width: '150px', marginLeft: '16px', marginRight: '16px', borderRadius: '0px', fontWeight: '700' }} onClick={handleModalShow_Acc} >
                            UPLOAD DS TK MỞ THÊM
                        </button>
                        <button className="btn btn-danger responsive-button-detail" style={{ height: '60px', width: '150px', marginLeft: '16px', marginRight: '16px', borderRadius: '0px', fontWeight: '700', backgroundColor: 'red' }} onClick={handleModalShow_Agent} >
                            UPLOAD DS THI CHỨNG CHỈ
                        </button>
                    </div>
                    <div className='mt10px'>
                        <div className='row mt-3'>
                            <div className='col-sm-12'>
                                <div className='row'>
                                    <div className='col-md-1' style={{ paddingTop: '8px', paddingLeft: '16px' }}>
                                        <input className='responsive-checkbox' style={{ height: '20px', width: '20px', cursor: 'pointer', padding: '8px', borderRadius: '0px' }} type="checkbox" onChange={(e) => setTermsAccepted(e.target.checked)} checked={termsAccepted} />
                                    </div>
                                    <div className='col-md-11'>
                                        <label className="form-label responsive-term" style={{ marginLeft: '10px', textAlign: 'justify' }}>
                                            Tôi đã đọc <a style={{ color: 'red', fontWeight: 'bold' }} href={file}
                                                target="_blank">Thư đồng ý </a>về việc xử lý dữ liệu cá nhân và đồng ý cho phép Fubon được phép xử lý dữ liệu cá nhân của tôi cho các mục đích và phạm vi được nêu tại <a style={{ color: 'red', fontWeight: 'bold' }} href={file}
                                                    target="_blank">Thư đồng ý </a> về việc xử lý dữ liệu cá nhân
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <Modal show={showModalAcc} onHide={handleModalClose_Acc} className="custom-modal" backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>Upload file Excel chứa danh sách thông tin để tạo thêm tài khoản ôn thi</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ModalUpload />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleModalClose_Acc}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleModalClose_Acc}>
                        Upload
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={showModalAgent} onHide={handleModalClose_Agent} className="custom-modal" backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>Upload file Excel chứa thông tin đăng ký thi Chứng chỉ ĐLBH</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <UploadAgentList />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleModalClose_Agent}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleModalClose_Agent}>
                        Upload
                    </Button>
                </Modal.Footer>
            </Modal>
            <AlertBox data={alertData} onChange={(e) => { setAlertData((prev) => ({ ...prev, is_show: e })) }} />
        </div>
    )
}

export default CMSRegisterAgentExam;