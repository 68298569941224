/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import trainingApi from "../../../../../api/trainingApi";
import AlertBox from "../../../../../components/alert";
import { FaCheckCircle } from "react-icons/fa";
import CommonHelper from "../../../../../helpers/common";
import { Modal, Button } from "react-bootstrap";

const initialAlert = {
    is_show: false,
    title: "",
    content: "",
    variant: "danger"
};

const VotesList = () => {
    const [alertData, setAlertData] = useState(initialAlert);
    const [listVote, setListVote] = useState([]);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [voteToDelete, setVoteToDelete] = useState(null);

    useEffect(() => {
        fetchVotes();
    }, []);

    const fetchVotes = async () => {
        try {
            const response = await trainingApi.get_votes();
            console.log("response", response)
            if (response.code === 200) {
                setListVote(response.data);
            } else {
                setAlertData((prev) => ({
                    ...prev,
                    title: "Lỗi",
                    content: "Tải dữ liệu đánh giá/góp ý từ học viên không thành công. ",
                    is_show: true,
                    variant: "danger"
                }));
            }
        } catch (error) {
            setAlertData((prev) => ({
                ...prev,
                title: "Lỗi",
                content: "Oops... Đã có lỗi xảy ra, vui lòng kiểm tra lại!",
                is_show: true,
                variant: "danger"
            }));
        }
    }

    const handleDeleteClick = (vote) => {
        setVoteToDelete(vote);
        setShowConfirmModal(true);
    };

    const handleConfirmDelete = async () => {
        try {
            const response = await trainingApi.delete_vote(voteToDelete.id);
            if (response.code === 200) {
                setAlertData({
                    is_show: true,
                    title: "Success",
                    content: "Xóa đánh giá/góp ý thành công!",
                    variant: "success"
                });
                setListVote((prevList) => prevList.filter((vote) => vote.id !== voteToDelete.id));
            } else {
                setAlertData({
                    is_show: true,
                    title: "Error",
                    content: "Xóa đánh giá/góp ý thất bại! Vui lòng thử lại!",
                    variant: "danger"
                });
            }
        } catch (error) {
            setAlertData({
                is_show: true,
                title: "Error",
                content: "An error occurred while deleting the feedback",
                variant: "danger"
            });
        } finally {
            setShowConfirmModal(false);
        }
    };

    const handleCloseConfirmModal = () => {
        setShowConfirmModal(false);
        setVoteToDelete(null);
    };

    return (
        <>
            <div className="container" style={{maxWidth: '1920px'}}>
                <div className="row row-frm">
                    <h1 className="text-center">Góp ý từ học viên</h1>
                    <div className="table-container" style={{ overflowY: 'auto', maxHeight: '700px' }}>
                        <table className="table table-striped table-bordered table-hover">
                            <thead className="center-vertically" style={{ backgroundColor: '#3498db', position: 'sticky', top: '-2px' }}>
                                <tr style={{ verticalAlign: 'middle', color: 'white' }}>
                                    <th style={{ width: '3%' }}>#</th>
                                    <th style={{ width: '20%' }}>Nội dung góp ý</th>
                                    <th style={{ width: '6%' }}>Người tạo</th>
                                    <th style={{ width: '6%' }}>Ngày tạo</th>
                                    <th style={{ width: '3%' }}>Thao tác</th>
                                    {/* <th style={{ width: '15%' }}>Thi theo chủ đề</th> */}
                                </tr>
                            </thead>
                            <tbody className="center-vertically">
                                {listVote.length === 0 ? (
                                    <tr>
                                        <td colSpan="4" style={{ textAlign: 'center' }}>Không có dữ liệu phù hợp</td>
                                    </tr>
                                ) :
                                    (
                                        listVote.map((vote, index) => (
                                            <>
                                                <tr key={index} style={{ verticalAlign: 'middle' }}>
                                                    <td style={{ textAlign: 'center', }}>
                                                        {/* <p>{vote.id}</p> */}
                                                        <p>{listVote.length - index}</p>
                                                    </td>
                                                    <td style={{ textAlign: 'left', }}>
                                                        <p>{vote.review_6 ? "Điều học viên thích: " + vote.review_6 : ""}</p>
                                                        <p>{vote.review_7 ? "Điều học viên không thích: " + vote.review_7 : ""}</p>
                                                    </td>
                                                    <td style={{ textAlign: 'center', }}>
                                                        <p>{vote.fullName ?? vote.userName}</p>
                                                    </td>
                                                    <td style={{ textAlign: 'center', }}>
                                                        <p>
                                                            {vote.createdDate ? CommonHelper.formatDateExam(vote.createdDate) : ""}
                                                        </p>
                                                    </td>
                                                    <td style={{ textAlign: 'center', }}>
                                                        <button 
                                                            className="btn btn-danger" 
                                                            style={{borderRadius: '0px'}} 
                                                            onClick={() => handleDeleteClick(vote)}
                                                        >Xóa</button>
                                                    </td>
                                                </tr>
                                            </>
                                        )).reverse()
                                    )
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
                <AlertBox data={alertData} onChange={(e) => { setAlertData((prev) => ({ ...prev, is_show: e })) }} />
                <Modal show={showConfirmModal} onHide={handleCloseConfirmModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Xác nhận xóa</Modal.Title>
                </Modal.Header>
                <Modal.Body>Bạn có chắc chắn muốn xóa đánh giá/góp ý này?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseConfirmModal}>
                        Hủy
                    </Button>
                    <Button variant="danger" onClick={handleConfirmDelete}>
                        Xóa
                    </Button>
                </Modal.Footer>
            </Modal>
            </div>
        </>
    );
}

export default VotesList;
