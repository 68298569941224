import api from "./api";
import axios from "axios";
import CommonHelper from "../helpers/common";

const paiApi = {
    get_occupations: async (id) => {
        var callApi = await api.get(
            "pai/get_occupations"
        );
        if (callApi.code === 401) {
            console.log(callApi);
        }
        return callApi;
    },
    get_nationalities: async (id) => {
        var callApi = await api.get(
            "pai/get_nationalities"
        );
        if (callApi.code === 401) {
            console.log(callApi);
        }
        return callApi;
    },
    get_pai_premium: async (id) => {
        var callApi = await api.get(
            "pai/get_pai_premium"
        );
        if (callApi.code === 401) {
            console.log(callApi);
        }
        return callApi;
    },
    get_order: async (id) => {
        var callApi = await api.get(
            "order/get_pai_order/" + id
        );
        if (callApi.code === 401) {
            console.log(callApi);
        }
        return callApi;
    },
    get_sob: async (id) => {
        var callApi = await api.get(
            "online/get_sob?code=" + id
        );
        if (callApi.code === 401) {
            console.log(callApi);
        }
        return callApi;
    },
    get_pai_proposal: async (id) => {
        var token = await CommonHelper.get_token();
        var session_id = CommonHelper.get_session_id();
        axios({
            url: process.env.REACT_APP_API_ENDPOINT + "order/get_pai_proposal?order_no=" + id + "&session_id=" + session_id,
            method: 'GET',
            headers: { Authorization: `Bearer ${token}`, },
            responseType: 'blob', // important
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', id + '.pdf');
            document.body.appendChild(link);
            link.click();
        });
    },
    get_pai_policy: async (id) => {
        var token = await CommonHelper.get_token();
        axios({
            url: process.env.REACT_APP_API_ENDPOINT + "order/get_pai_policy?order_no=" + id,
            method: 'GET',
            headers: { Authorization: `Bearer ${token}`, },
            responseType: 'blob', // important
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', id + '.pdf');
            document.body.appendChild(link);
            link.click();
        });
    },
    get_pai_debitnote: async (id) => {
        var token = await CommonHelper.get_token();
        axios({
            url: process.env.REACT_APP_API_ENDPOINT + "order/get_pai_debitnote?order_no=" + id,
            method: 'GET',
            headers: { Authorization: `Bearer ${token}`, },
            responseType: 'blob', // important
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', id + '.pdf');
            document.body.appendChild(link);
            link.click();
        });
    },
    post_pai_order: async (model) => {
        if (model != null) {
            var session_id = CommonHelper.get_session_id();
            var data = {
                session_id: session_id,
                order_no: model.order_no,
                sc_code: model.veh_org_sc_code,
                total_amt: model.total_amt,
                order_detail: model.order_detail,
                insured_persons: model.insured_persons,
            };
            var callApi = await api.post(
                "order/post_pai_order", data
            );
            if (callApi.code === 401) {
                console.log(callApi);
            }
            return callApi;
        }
    },
    post_insured_person: async (model) => {
        if (model != null) {
            var session_id = CommonHelper.get_session_id();
            var data = {
                session_id: session_id,
                insured_person: model,
            };
            var callApi = await api.post(
                "order/post_insured_person", data
            );
            if (callApi.code === 401) {
                console.log(callApi);
            }
            return callApi;
        }
    },
    issue_pai_policy: async (model) => {
        if (model != null) {
            var session_id = CommonHelper.get_session_id();
            var data = {
                session_id: session_id,
                order_no: model.veh_order_no,
                order_detail: model,
            };
            var callApi = await api.post(
                "order/issue_pai_policy", data
            );
            if (callApi.code === 401) {
                console.log(callApi);
            }
            return callApi;
        }
    },
    delete_insured_person: async (model) => {
        if (model != null) {
            var session_id = CommonHelper.get_session_id();
            var data = {
                session_id: session_id,
                id: model.id
            };
            //console.log(data);
            var callApi = await api.post(
                "order/delete_insured_person", data
            );
            if (callApi.code === 401) {
                console.log(callApi);
            }
            return callApi;
        }
    },
    get_pai_payment: async (model) => {
        if (model != null) {
            var session_id = CommonHelper.get_session_id();
            var data = {
                session_id: session_id,
                order_no: model.order_no,
                return_url: window.location.origin,
                cancel_url: model.cancel_url,
                notify_url: process.env.REACT_APP_API_ENDPOINT
                /*sc_code: model.veh_org_sc_code,
                total_amt: model.total_amt,
                order_detail: model.order_detail,
                insured_persons: model.insured_persons,*/
            };
            var callApi = await api.post(
                "order/get_pai_payment", data
            );
            if (callApi.code === 401) {
                console.log(callApi);
            }
            return callApi;
        }
    },
    upload_pai_proposal: async (model) => {
        var token = await CommonHelper.get_token();
        var session_id = CommonHelper.get_session_id();
        // create a new FormData object and append the file to it
        const formData = new FormData();
        formData.append("pai_pp", model.file);
        formData.append("order_no", model.order_no);
        formData.append("session_id", session_id);
        var response = await axios.post(process.env.REACT_APP_API_ENDPOINT + "order/upload_pai_proposal_form", formData, {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "multipart/form-data",
            },
        })
        return response;
    },
    get_pai_payment_check_order: async (id) => {
        var callApi = await api.get(
            "order/get_pai_payment_check_order?order=" + id
        );
        if (callApi.code === 401) {
            console.log(callApi);
        }
        return callApi;
    },
    get_aml_online_check: async (model) => {
        if (model != null) {
            /*var session_id = CommonHelper.get_session_id();
            var data = {
                session_id: session_id,
                insured_person: model,
            };*/
            var callApi = await api.get(
                "customer/get_aml_online_check?" + model
            );
            if (callApi.code === 401) {
                console.log(callApi);
            }
            return callApi;
        }
    },
    send_notification_check_aml: async (model) => {
        if (model != null) {
            var callApi = await api.post(
                "order/send_notification_check_aml", model
            );
            if (callApi.code === 401) {
                console.log(callApi);
            }
            return callApi;
        }
    },
    get_pai_order_confirmation: async (req) => {
        var callApi = await api.post(
            "order/get_pai_order_confirmation", req
        );
        if (callApi.code === 401) {
            console.log(callApi);
        }
        return callApi;
    },
    get_pai_excel_template: async () => {
        var token = await CommonHelper.get_token();
        axios({
            url: process.env.REACT_APP_API_ENDPOINT + "order/get_pai_excel_template",
            method: 'GET',
            headers: { Authorization: `Bearer ${token}`, },
            responseType: 'blob', // important
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'pa_template.xlsx');
            document.body.appendChild(link);
            link.click();
        });
    },
    import_insured_person: async (model) => {
        var token = await CommonHelper.get_token();
        var session_id = CommonHelper.get_session_id();
        // create a new FormData object and append the file to it
        const formData = new FormData();
        formData.append("pai_import", model.file);
        formData.append("default_pkg", model.pkg_code);
        formData.append("veh_business", model.veh_business);
        formData.append("session_id", session_id);
        var response = await axios.post(process.env.REACT_APP_API_ENDPOINT + "order/import_insured_person", formData, {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "multipart/form-data",
            },
        })
        return response;
    },
    send_confirmation_to_customer: async (order_no) => {
        if (order_no != null) {
            var session_id = CommonHelper.get_session_id();
            var url_redirect = window.location.origin
            var data = {
                session_id,
                order_no,
                url_redirect
            };
            var callApi = await api.post(
                "order/send_confirmation_to_customer", data
            );
            if (callApi.code === 401) {
                console.log(callApi);
            }
            return callApi;
        }
    },
    get_pai_customer_confirmation: async (req) => {
        if (CommonHelper.is_not_empty(req)) {
            var callApi = await api.get(
                "order/pai_customer_confirmation" + req
            );
            if (callApi.code === 401) {
                console.log(callApi);
            }
            return callApi;
        }
        return null;
    },
};

export default paiApi;