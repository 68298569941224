import React, { useEffect, useState } from "react";
import qrCodeApi from "../../../api/qrcodeApi";
import AlertBox from "../../../components/alert";
import { Container, Row, Col, Form, Button, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";

const initialAlert = {
    is_show: false,
    title: "",
    content: "",
    variant: "danger"
};

const QRList = () => {
    const [employees, setEmployees] = useState([]);
    const [alertData, setAlertData] = useState(initialAlert);
    const [empParams, setEmpParams] = useState(0);
    const [loading, setLoading] = useState(false);
    const [searchKeyword, setSearchKeyword] = useState("");

    //#region HANDLE
    useEffect(() => {
        fetchEmployees();
    }, []);

    const fetchEmployees = async () => {
        setLoading(true); // Start loading
        try {
            const response = await qrCodeApi.get_employees(empParams);
            console.log("response", response);
            const _data = JSON.parse(response.data);
            if (response.code === 200) {
                setEmployees(_data);
            } else {
                setAlertData((prev) => ({
                    ...prev,
                    title: "Lỗi",
                    content: "Tải dữ liệu danh sách QR không thành công. ",
                    is_show: true,
                    variant: "danger"
                }));
            }
        } catch (error) {
            setAlertData((prev) => ({
                ...prev,
                title: "Lỗi",
                content: "Oops... Đã có lỗi xảy ra, vui lòng kiểm tra lại!",
                is_show: true,
                variant: "danger"
            }));
            console.log(error);
        } finally {
            setLoading(false);
        }
    };

    const filteredByKeyword = employees.filter(agent =>
        agent.EMP_CODE?.toLowerCase().includes(searchKeyword.toLowerCase())
    );

    //#endregion

    return (
        <>
            <div className="clearfix" style={{ marginTop: "75px" }}></div>
            <div className="container" >
                <div className="row row-frm">
                    <h1 className="text-center">
                        Quản lý NameCard
                    </h1>
                    <hr />
                    <div className="d-flex justify-content-between align-items-center mb-2">
                        <Link to="/cms/tao-namecard" className="btn btn-success btn-sm">
                            <span style={{ display: 'flex', alignItems: 'center', borderRadius: '0px' }}>
                                Thêm Namecard
                            </span>
                        </Link>
                        <input
                            type="text"
                            placeholder="Tìm Namecard nhân viên..."
                            value={searchKeyword}
                            onChange={(e) => setSearchKeyword(e.target.value)}
                            className="form-control"
                            style={{ maxWidth: '300px' }}
                        />
                    </div>
                    <div 
                        className="table-container" 
                        style={{ 
                            overflowY: 'auto', 
                            maxHeight: '600px' 
                        }}
                    >
                        <table className="table table-striped table-bordered table-hover">
                            <thead 
                                className="center-vertically" 
                                style={{ 
                                    backgroundColor: '#3498db', 
                                    position: 'sticky', 
                                    top: '-2px' 
                                }}
                            >
                                <tr style={{ verticalAlign: 'middle', color: 'white' }}>
                                    <th style={{ width: '5%' }}>
                                        #
                                    </th>
                                    <th style={{ width: '25%' }}>
                                        Employee Code
                                    </th>
                                    <th style={{ width: '10%' }}>
                                        QR Code
                                    </th>
                                    <td style={{ width: '50%', fontWeight: '700' }}>
                                        Xem trước Namecard
                                    </td>
                                    {/* <th style={{ width: '15%' }}>Trạng thái</th> */}
                                </tr>
                            </thead>
                            <tbody>
                                {loading ? (
                                    <tr className="text-center" style={{ textAlign: 'center', alignItems: 'center' }}>
                                        <td colSpan="7" style={{ textAlign: 'center' }}>
                                            <Spinner /> Đang tải dữ liệu ...
                                        </td>
                                    </tr>
                                ) :
                                    (<>
                                        {filteredByKeyword.length === 0 ? (
                                            <tr>
                                                <td colSpan="5" style={{ textAlign: 'center' }}>
                                                    Không có dữ liệu phù hợp
                                                </td>
                                            </tr>
                                        ) :
                                            (
                                                filteredByKeyword.map((emp, index) => (
                                                    <>
                                                        <tr 
                                                            key={emp.EMP_ID} 
                                                            style={{ verticalAlign: 'middle', textAlign: 'center' }}
                                                        >
                                                            <td>
                                                                {emp.EMP_ID}
                                                            </td>
                                                            <td style={{ textAlign: 'center', fontWeight: '500' }}>
                                                                {emp.EMP_CODE}
                                                                {/* {history.name} (Thi lần {history.numberOfTests}) */}
                                                            </td>
                                                            {/* <td>
                                                        {emp.HashKey}
                                                    </td> */}
                                                            <td>
                                                                <img 
                                                                    src={`${emp.Image}`} 
                                                                    alt="QR Code" 
                                                                    style={{ height: '100px', width: '100px' }} 
                                                                />
                                                                {/* {emp.Image} */}
                                                            </td>
                                                            <td>
                                                                {/* <a 
                                                                    style={{ textDecoration: 'none' }} 
                                                                    href={"https://test.fubonins.com.vn/qr/" + emp.HashKey}
                                                                >
                                                                    {"https://test.fubonins.com.vn/qr/" + emp.HashKey}
                                                                </a> */}
                                                                {/* <a 
                                                                    style={{ textDecoration: 'none' }} 
                                                                    href={emp.Namecard_url}
                                                                >
                                                                    {emp.Namecard_url}
                                                                </a> */}
                                                                <a 
                                                                    style={{ textDecoration: 'none' }} 
                                                                    href={`${window.location.origin}/qr/${emp.HashKey}`}
                                                                >
                                                                    {`${window.location.origin}/qr/${emp.HashKey}`}
                                                                </a>
                                                            </td>
                                                            {/* <td>
                                                        <Form>
                                                            <Form.Check
                                                                checked={emp.IsActive}
                                                                type="switch"
                                                                // readOnly
                                                                label={emp.IsActive && "Đang hoạt động"}
                                                            />
                                                        </Form>
                                                    </td> */}
                                                        </tr>
                                                    </>
                                                ))
                                            )
                                        }
                                    </>)}
                            </tbody>
                        </table>
                    </div>
                </div>
                <AlertBox data={alertData} onChange={(e) => { setAlertData((prev) => ({ ...prev, is_show: e })) }} />
            </div>
        </>
    );
}

export default QRList;
