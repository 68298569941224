import React, { useRef, useState } from 'react'
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import PropTypes from 'prop-types';
import CommonHelper from '../../../helpers/common';
import * as FaIcons from 'react-icons/fa'
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

// import required modules
import { Navigation } from 'swiper/modules';

function Packages(props) {
    const { onSelect, list, pkg_selected, ...other } = props;

    const [{ pa_level }, setSelected] = useState({
        pa_level: 1,
    })
    const handle_change_pkg_level = (val) => {
        setSelected((prev) => ({ ...prev, pa_level: val }));
        handle_select({});
    }
    const handle_select = (e) => {
        //setSelected((prev) => ({ ...prev, pa_package: e }))
        onSelect(e);
    }
    return (
        <>
            <div className='d-none' style={{ position: "relative", height: "40px", width: "40%", margin: "20px auto" }}>
                <ul className='pai-nav'>
                    <li className={'nav-item ' + (pa_level === 0 ? "active" : "")} onClick={() => { handle_change_pkg_level(0) }}>Ít mua</li>
                    <li className={'nav-item ' + (pa_level === 1 ? "active" : "")} onClick={() => { handle_change_pkg_level(1) }}>Mua nhiều nhất</li>
                </ul>
                <div className='clearfix'></div>
            </div>
            <div className='d-block'>
                <div className='d-flex'>
                    <div className='w-25'>
                        <div className="plan-description mat-card" style={{ background: "#03a9f436", marginTop: "60px" }}>
                            <div className='head text-center d-none'>QUYỀN LỢI</div>
                            <div className='body'>
                                <p className='fs-4 fw-bolder' style={{ height: "48px" }}>Phí bảo hiểm <span className='fs-6'>(VND/năm)</span></p>
                                <p className='p-location'>Phạm vi địa lý</p>
                                <p className='p-death'>Tử vong/Thương tật vĩnh viễn</p>
                                <p className='p-full-damage'>Thương tật bộ phận vĩnh viễn</p>
                                <p className='p-medical-fee'>Chi phí y tế</p>
                                <p className='p-allowance'>Trợ cấp ngày, tối đa 60 ngày/ năm</p>
                                <p className='p-missing'>Mất tích</p>
                                <p className='p-funeral-allowance'>Trợ cấp mai táng</p>
                                {/*<p className='p-toxic'>Nhiễm độc khí ga, khí độc</p> 
                                    <p className='p-sos'>Dịch vụ SOS</p>*/}
                            </div>
                            {/*<div className='foot fw-bolder'>
                                    Phí bảo hiểm/ năm
                                </div> */}
                        </div>
                    </div>
                    <div className='w-75 px-4'>
                        <Swiper
                            slidesPerView={3}
                            spaceBetween={10}
                            navigation={true}
                            modules={[Navigation]}
                            initialSlide={3}
                            className="mySwiper"
                        >
                            {list.map((item, i) => {
                                return (
                                    <SwiperSlide key={i}>
                                        <div key={i} className={((pkg_selected !== null && pkg_selected.code) == item.code ? "active" : '')} style={{ flex: "1" }}>
                                            <div className={"plan-pricing mat-card " + item.text_color} style={{ background: item.bg_color }}>
                                                <div className='head text-center position-relative'>
                                                    <label className='d-block'>{item.title}</label>
                                                    {item.suggestion && (
                                                        <div className='position-absolute package-star'>
                                                            <FaIcons.FaTrophy />
                                                        </div>
                                                    )}
                                                </div>
                                                <div className='body'>
                                                    <p className='fs-3 fw-bolder' style={{ height: "48px" }}>{item.premium > 0 ? CommonHelper.format_currency(item.premium) + "" : "-"}</p>
                                                    <p className='text-center'>{item.location}</p>
                                                    <p className='p-death'>{CommonHelper.format_currency(item.death_si) + " VND"}</p>
                                                    <p className='p-full-damage'>{item.partial_damage}</p>
                                                    <p className='p-medical-fee'>{item.medical_fee > 0 ? CommonHelper.format_currency(item.medical_fee) + " VND" : "-"}</p>
                                                    <p className='p-allowance'>{item.daily_allowance > 0 ? CommonHelper.format_currency(item.daily_allowance) + " VND" : "-"}</p>
                                                    <p className='p-missing'>{item.missing > 0 ? CommonHelper.format_currency(item.missing) + " VND" : "-"}</p>
                                                    <p className='p-funeral-allowance'>{item.funeral_allowance > 0 ? CommonHelper.format_currency(item.funeral_allowance) + " VND" : "-"}</p>
                                                </div>
                                                <div className='foot fw-bolder text-center'>
                                                    <div className="read-more-btn">
                                                        <a className="edu-btn btn-small btn-secondary" href='javascript:;' onClick={() => { handle_select(item) }} >Chọn</a></div>
                                                </div>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                )
                            })}
                        </Swiper>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Packages

Packages.propTypes = {
    onSelect: PropTypes.func.isRequired,
    list: PropTypes.array.isRequired,
    pkg_selected: PropTypes.object.isRequired,
}