import React, { useState, useEffect } from 'react';
import Intro1 from "../../../assets/images/shield.png";
import Intro2 from "../../../assets/images/pet-care.png";
import Banner1 from "../../../assets/images/hinh-cho-meo-3220.png";
import { MdPolicy } from "react-icons/md";
import PetWording from "../../../assets/doc/WORDING PET.pdf";
import { Link } from 'react-router-dom';
import { MdOutlinePets } from "react-icons/md";

function PetIntroduce(props) {
    const {t, toggleLanguage, ...other} = props;
    const [isSticky, setIsSticky] = useState(false);
    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 800) { // Adjust this value as needed
                setIsSticky(true);
            } else {
                setIsSticky(false);
            }
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <>
            <section 
                className="container" 
                style={{ marginBottom: '40px' }}
            >
                <div 
                    className="title_section" 
                    data-descr={t('pet_introduce_title')}
                    style={{ color: '#564741' }}
                >
                    <span>
                        {t('pet_introduce_title')}
                    </span>
                </div>
                <div className="row">
                    <div className="col-lg-5">
                        <h2 
                            style={{ 
                                fontWeight: "700", 
                                color: "#564741" 
                            }}
                        >
                            {t('pet_introduce_petInsuranceTitle')}
                        </h2>
                        <h3 
                            style={{ 
                                fontWeight: "700", 
                                color: "#564741" 
                            }}
                        >
                            {t('pet_introduce_petInsuranceSubtitle')}
                        </h3>
                        <div style={{ marginTop: '35px' }}>
                            <img
                                src={Intro1}
                                className="float-left mg-r-24"
                                alt='intro1'
                                style={{ 
                                    height: '64px', 
                                    width: '64px', 
                                    float: 'left !important', 
                                    marginRight: '24px' 
                                }}
                            />
                            <p 
                                style={{ 
                                    fontSize: '20px', 
                                    fontWeight: '700' 
                                }}
                            >
                                {t('pet_introduce_petInsuranceDesc1')}
                            </p>
                            <p 
                                style={{ 
                                    fontSize: '20px', 
                                    fontWeight: '700' 
                                }}
                            >
                                {t('pet_introduce_petInsuranceDesc2')}
                            </p>
                        </div>
                        <div className="clearfix mg-t-28">
                            <img
                                src={Intro2}
                                className="float-left mg-r-24"
                                alt='intro2'
                                style={{ 
                                    height: '64px', 
                                    width: '64px', 
                                    float: 'left !important', 
                                    marginRight: '24px' 
                                }}
                            />
                            <p 
                                style={{ textAlign: 'justify' }}
                                dangerouslySetInnerHTML={{ __html: t('pet_introduce_petInsuranceDetail') }}
                            />
                        </div>
                        <div className="clearfix mg-t-28">
                            <div className='row'>
                                <div className='col-md-6'>
                                    <a
                                        href={PetWording}
                                        target="_blank"
                                        className="btn btn-success"
                                        style={{
                                            backgroundColor: "#20c997",
                                            fontWeight: '700',
                                            borderRadius: '0px',
                                            border: 'none',
                                            height: '60px',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            textAlign: 'center'
                                        }}
                                        rel="noreferrer"
                                    >
                                        {t('pet_introduce_insuranceInfo')}
                                        <MdPolicy 
                                            style={{ 
                                                fontSize: '30px', 
                                                marginLeft: '12px' 
                                            }} 
                                        />
                                    </a>
                                </div>

                                <div className='col-md-6' >
                                    <Link
                                        to="/sale/pet-insurance"
                                        className={`btn btn-primary buy-now__media-760 ${isSticky ? 'btn-animation-buy-now' : ''}`}
                                        style={{
                                            marginLeft: '60px',
                                            borderRadius: '0px',
                                            fontWeight: '700',
                                            height: '60px',
                                            backgroundColor: 'orange',
                                            border: 'none',
                                            paddingLeft: '40px',
                                            paddingRight: '32px',
                                            position: isSticky ? 'fixed' : 'relative',
                                            top: isSticky ? '20px' : 'auto',
                                            right: isSticky ? '20px' : 'auto',
                                            zIndex: 1000,
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            textAlign: 'center',
                                        }}
                                    >
                                        {t('pet_introduce_buyNow')}
                                        <MdOutlinePets 
                                            style={{ 
                                                fontSize: '30px', 
                                                marginLeft: '12px' 
                                            }} 
                                        />
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-7 col-md-6 col-sm-12 d-none d-lg-block">
                        <img
                            className="b-lazy"
                            src={Banner1}
                            alt="Banner"
                            style={{ 
                                marginLeft: '40px', 
                                width: '100%', 
                                height: 'auto' 
                            }}
                        />
                    </div>
                </div>
            </section>
        </>
    );
};

export default PetIntroduce;
