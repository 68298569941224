const Occupation = [
    { "code": "OCCR001", "name": "Ngân hàng", "value": "OCCR001", "organization": true, "exception": false },
    { "code": "OCCR002", "name": "Tín thác đầu tư/Quỹ tín thác đầu tư", "value": "OCCR002", "organization": true, "exception": false },
    { "code": "OCCR003", "name": "Hợp tác xã tín dụng", "value": "OCCR003", "organization": true, "exception": false },
    { "code": "OCCR004", "name": "Tổ chức tín dụng ngư nghiệp", "value": "OCCR004", "organization": true, "exception": false },
    { "code": "OCCR005", "name": "Kho bạc nông nghiệp quốc gia", "value": "OCCR005", "organization": true, "exception": false },
    { "code": "OCCR006", "name": "Tổ chức bưu chính thực hiện hoạt động tiền gửi", "value": "OCCR006", "organization": true, "exception": false },
    { "code": "OCCR007", "name": "Tài chính cho vay  chứng khoán", "value": "OCCR007", "organization": true, "exception": false },
    { "code": "OCCR008", "name": "Thẻ tín dụng", "value": "OCCR008", "organization": true, "exception": false },
    { "code": "OCCR009", "name": "Bảo hiểm", "value": "OCCR009", "organization": true, "exception": false },
    { "code": "OCCR010", "name": "Chứng khoán", "value": "OCCR010", "organization": true, "exception": false },
    { "code": "OCCR011", "name": "Nghiệp vụ tư vấn đầu tư chứng khoán", "value": "OCCR011", "organization": true, "exception": false },
    { "code": "OCCR012", "name": "Nghiệp vụ ủy thác đầu tư chứng khoán", "value": "OCCR012", "organization": true, "exception": false },
    { "code": "OCCR013", "name": "Nghiệp vụ tài chính chứng khoán", "value": "OCCR013", "organization": true, "exception": false },
    { "code": "OCCR014", "name": "Nghiệp vụ tín thác", "value": "OCCR014", "organization": true, "exception": false },
    { "code": "OCCR015", "name": "Kinh doanh cầm đồ", "value": "OCCR015", "organization": true, "exception": false },
    { "code": "OCCR016", "name": "Đại lý tiền tệ", "value": "OCCR016", "organization": true, "exception": false },
    { "code": "OCCR017", "name": "Cho thuê tài chính", "value": "OCCR017", "organization": true, "exception": false },
    { "code": "OCCR018", "name": "Tổ chức thanh toán điện tử", "value": "OCCR018", "organization": true, "exception": false },
    { "code": "OCCR019", "name": "Tổ chức thanh toán thông qua kênh trung gian", "value": "OCCR019", "organization": true, "exception": false },
    { "code": "OCCR020", "name": "Môi giới bất động sản (bao gồm trung gian giới thiệu và ký gửi BĐS)", "value": "OCCR020", "organization": true, "exception": false },
    { "code": "OCCR021", "name": "Vàng bạc đá quý (bao gồm chế tác, bán sỉ và bán lẻ những trang sức kim loại quý, châu báu, đồng hồ)", "value": "OCCR021", "organization": true, "exception": false },
    { "code": "OCCR022", "name": "Kinh doanh ngành nghề đấu giá", "value": "OCCR022", "organization": true, "exception": false },
    { "code": "OCCR023", "name": "Tổ chức tôn giáo", "value": "OCCR023", "organization": true, "exception": false },
    { "code": "OCCR024", "name": "Văn phòng Luật sư", "value": "OCCR024", "organization": true, "exception": false },
    { "code": "OCCR025", "name": "Dịch vụ kiểm toán", "value": "OCCR025", "organization": true, "exception": false },
    { "code": "OCCR026", "name": "Nghiệp vụ lưu ký chứng khoán", "value": "OCCR026", "organization": true, "exception": false },
    { "code": "OCCR027", "name": "Ngành giải trí (bao gồm cá cược online)", "value": "OCCR027", "organization": true, "exception": false },
    { "code": "OCCR028", "name": "Ngành liên quan đến vũ khí quốc phòng và thiết bị chiến tranh", "value": "OCCR028", "organization": true, "exception": false },
    { "code": "OCCR029", "name": "Ngân hàng chợ đen", "value": "OCCR029", "organization": true, "exception": false },
    { "code": "OCCR030", "name": "Thu đổi ngoại tệ thị trường chợ đen", "value": "OCCR030", "organization": true, "exception": false },
    { "code": "OCCR031", "name": "Nhân viên địa chính", "value": "OCCR031", "organization": false, "exception": false },
    { "code": "OCCR032", "name": "Công chứng viên", "value": "OCCR032", "organization": false, "exception": false },
    { "code": "OCCR033", "name": "Nhân viên ghi sổ kế toán", "value": "OCCR033", "organization": false, "exception": false },
    { "code": "OCCR034", "name": "Đại lý thuế", "value": "OCCR034", "organization": false, "exception": false },
    { "code": "OCCR035", "name": "Luật sư", "value": "OCCR035", "organization": false, "exception": false },
    { "code": "OCCR036", "name": "Nhân viên kiểm toán", "value": "OCCR036", "organization": false, "exception": false },
    { "code": "OCCR037", "name": "Lãnh đạo có ảnh hưởng chính trị", "value": "OCCR037", "organization": false, "exception": false },
    { "code": "OCCR041", "name": "Chuyển tiền lương của NLĐ nước ngoài ra nước ngoài", "value": "OCCR041", "organization": true, "exception": false },
    { "code": "OCCR040", "name": "Doanh nghiệp kinh doanh tiền ảo, các app ( nền tảng) tiền ảo, tiền điện tử, tiền mã hóa", "value": "OCCR040", "organization": true, "exception": false },
    { "code": "OCCR039", "name": "Kinh doanh phái sinh", "value": "OCCR039", "organization": true, "exception": false },
    { 'code': 'OCCR038_1100001', 'name': 'Bất động sản', 'value': 'OCCR038', 'organization': true, 'exception': false },
    { 'code': 'OCCR038_1100002', 'name': 'Chế biến thực phẩm', 'value': 'OCCR038', 'organization': true, 'exception': false },
    { 'code': 'OCCR038_1100003', 'name': 'Đại lý bảo hiểm ', 'value': 'OCCR038', 'organization': true, 'exception': false },
    { 'code': 'OCCR038_1100004', 'name': 'Dược phẩm', 'value': 'OCCR038', 'organization': true, 'exception': false },
    { 'code': 'OCCR038_1100005', 'name': 'Giáo dục ', 'value': 'OCCR038', 'organization': true, 'exception': false },
    { 'code': 'OCCR038_1100006', 'name': 'In ấn', 'value': 'OCCR038', 'organization': true, 'exception': false },
    { 'code': 'OCCR038_1100007', 'name': 'Khách sạn', 'value': 'OCCR038', 'organization': true, 'exception': false },
    { 'code': 'OCCR038_1100008', 'name': 'Khám, chữa bệnh', 'value': 'OCCR038', 'organization': true, 'exception': false },
    { 'code': 'OCCR038_1100009', 'name': 'Kinh doanh đồ dùng khác cho gia đình', 'value': 'OCCR038', 'organization': true, 'exception': false },
    { 'code': 'OCCR038_1100010', 'name': 'Kinh doanh thực phẩm', 'value': 'OCCR038', 'organization': true, 'exception': false },
    { 'code': 'OCCR038_1100011', 'name': 'Kinh doanh văn phòng phẩm', 'value': 'OCCR038', 'organization': true, 'exception': false },
    { 'code': 'OCCR038_1100012', 'name': 'Môi giới bảo hiểm ', 'value': 'OCCR038', 'organization': true, 'exception': false },
    { 'code': 'OCCR038_1100013', 'name': 'Nhà hàng', 'value': 'OCCR038', 'organization': true, 'exception': false },
    { 'code': 'OCCR038_1100014', 'name': 'Nhà máy sản xuất bánh kẹo', 'value': 'OCCR038', 'organization': true, 'exception': false },
    { 'code': 'OCCR038_1100015', 'name': 'Sản xuất bao bì carton, bao bì công nghiệp', 'value': 'OCCR038', 'organization': true, 'exception': false },
    { 'code': 'OCCR038_1100016', 'name': 'Sản xuất keo', 'value': 'OCCR038', 'organization': true, 'exception': false },
    { 'code': 'OCCR038_1100017', 'name': 'Siêu thị, trung tâm thương mại', 'value': 'OCCR038', 'organization': true, 'exception': false },
    { 'code': 'OCCR038_1100018', 'name': 'Văn phòng đại diện', 'value': 'OCCR038', 'organization': true, 'exception': false },
    { 'code': 'OCCR038_1100019', 'name': 'Dịch vụ lưu trú & ăn uống ', 'value': 'OCCR038', 'organization': true, 'exception': false },
    { 'code': 'OCCR038_1100020', 'name': 'Dịch vụ ăn uống', 'value': 'OCCR038', 'organization': true, 'exception': false },
    { 'code': 'OCCR038_1100021', 'name': 'Thông tin và truyền thông ', 'value': 'OCCR038', 'organization': true, 'exception': false },
    { 'code': 'OCCR038_1100022', 'name': 'Khoa học và công nghệ ', 'value': 'OCCR038', 'organization': true, 'exception': false },
    { 'code': 'OCCR038_1100023', 'name': 'Y tế ', 'value': 'OCCR038', 'organization': true, 'exception': false },
    { 'code': 'OCCR038_1100024', 'name': 'Dịch vụ lắp ráp, sửa chữa và mua bán linh kiện máy móc thiết bị', 'value': 'OCCR038', 'organization': true, 'exception': false },
    { 'code': 'OCCR038_1100025', 'name': 'Ngành nghề khác', 'value': 'OCCR038', 'organization': true, 'exception': false },
    { 'code': 'OCCR038_1200001', 'name': 'Điện tử viễn thông', 'value': 'OCCR038', 'organization': true, 'exception': false },
    { 'code': 'OCCR038_1200002', 'name': 'Du lịch ', 'value': 'OCCR038', 'organization': true, 'exception': false },
    { 'code': 'OCCR038_1200003', 'name': 'Hệ thống xử lý khí thải môi trường', 'value': 'OCCR038', 'organization': true, 'exception': false },
    { 'code': 'OCCR038_1200004', 'name': 'Hóa chất', 'value': 'OCCR038', 'organization': true, 'exception': false },
    { 'code': 'OCCR038_1200005', 'name': 'Nông nghiệp', 'value': 'OCCR038', 'organization': true, 'exception': false },
    { 'code': 'OCCR038_1200006', 'name': 'Lâm nghiệm', 'value': 'OCCR038', 'organization': true, 'exception': false },
    { 'code': 'OCCR038_1200007', 'name': 'Nghệ thuật ', 'value': 'OCCR038', 'organization': true, 'exception': false },
    { 'code': 'OCCR038_1200008', 'name': 'Kho hàng', 'value': 'OCCR038', 'organization': true, 'exception': false },
    { 'code': 'OCCR038_1200009', 'name': 'Kho hóa chất ', 'value': 'OCCR038', 'organization': true, 'exception': false },
    { 'code': 'OCCR038_1200010', 'name': 'Kinh doanh dịch vụ karaoke, vũ trường, quán bar', 'value': 'OCCR038', 'organization': true, 'exception': false },
    { 'code': 'OCCR038_1200011', 'name': 'Kinh doanh đồ gỗ', 'value': 'OCCR038', 'organization': true, 'exception': false },
    { 'code': 'OCCR038_1200012', 'name': 'Kinh doanh hàng may mặc, giày dép, hàng da', 'value': 'OCCR038', 'organization': true, 'exception': false },
    { 'code': 'OCCR038_1200013', 'name': 'Kinh doanh máy bơm nước', 'value': 'OCCR038', 'organization': true, 'exception': false },
    { 'code': 'OCCR038_1200014', 'name': 'Kinh doanh thiết bị điện', 'value': 'OCCR038', 'organization': true, 'exception': false },
    { 'code': 'OCCR038_1200015', 'name': 'Kinh doanh thiết bị và linh kiện điện tử, viễn thông', 'value': 'OCCR038', 'organization': true, 'exception': false },
    { 'code': 'OCCR038_1200016', 'name': 'Kinh doanh vải, hàng may sẵn, giày dép', 'value': 'OCCR038', 'organization': true, 'exception': false },
    { 'code': 'OCCR038_1200017', 'name': 'Kinh doanh vật liệu xây dựng', 'value': 'OCCR038', 'organization': true, 'exception': false },
    { 'code': 'OCCR038_1200018', 'name': 'Lắp đặt hệ thống điện', 'value': 'OCCR038', 'organization': true, 'exception': false },
    { 'code': 'OCCR038_1200019', 'name': 'May mặc', 'value': 'OCCR038', 'organization': true, 'exception': false },
    { 'code': 'OCCR038_1200020', 'name': 'Nhà máy bia, rượu, nước giải khát', 'value': 'OCCR038', 'organization': true, 'exception': false },
    { 'code': 'OCCR038_1200021', 'name': 'Nhà máy chỉ khâu', 'value': 'OCCR038', 'organization': true, 'exception': false },
    { 'code': 'OCCR038_1200022', 'name': 'Nhà máy dệt', 'value': 'OCCR038', 'organization': true, 'exception': false },
    { 'code': 'OCCR038_1200023', 'name': 'Nhà máy giày', 'value': 'OCCR038', 'organization': true, 'exception': false },
    { 'code': 'OCCR038_1200024', 'name': 'Nhà máy gốm sứ', 'value': 'OCCR038', 'organization': true, 'exception': false },
    { 'code': 'OCCR038_1200025', 'name': 'Nhà máy lưu hóa cao su', 'value': 'OCCR038', 'organization': true, 'exception': false },
    { 'code': 'OCCR038_1200026', 'name': 'Nhà máy nhiệt điện', 'value': 'OCCR038', 'organization': true, 'exception': false },
    { 'code': 'OCCR038_1200027', 'name': 'Nhà máy sản xuất bình ắc quy', 'value': 'OCCR038', 'organization': true, 'exception': false },
    { 'code': 'OCCR038_1200028', 'name': 'Nhà máy sản xuất chất dẻo, cao su đặc', 'value': 'OCCR038', 'organization': true, 'exception': false },
    { 'code': 'OCCR038_1200029', 'name': 'Nhà máy sản xuất chế biến hạt nhựa', 'value': 'OCCR038', 'organization': true, 'exception': false },
    { 'code': 'OCCR038_1200030', 'name': 'Nhà máy sản xuất nến', 'value': 'OCCR038', 'organization': true, 'exception': false },
    { 'code': 'OCCR038_1200031', 'name': 'Nhà máy sản xuất vỏ xe', 'value': 'OCCR038', 'organization': true, 'exception': false },
    { 'code': 'OCCR038_1200032', 'name': 'Nhà máy thủy điện', 'value': 'OCCR038', 'organization': true, 'exception': false },
    { 'code': 'OCCR038_1200033', 'name': 'Quảng cáo', 'value': 'OCCR038', 'organization': true, 'exception': false },
    { 'code': 'OCCR038_1200034', 'name': 'Sản xuất bao bì nhựa', 'value': 'OCCR038', 'organization': true, 'exception': false },
    { 'code': 'OCCR038_1200035', 'name': 'Sản xuất bình nước nóng năng lượng mặt trời', 'value': 'OCCR038', 'organization': true, 'exception': false },
    { 'code': 'OCCR038_1200036', 'name': 'Sản xuất các mặt hàng làm từ da thuộc', 'value': 'OCCR038', 'organization': true, 'exception': false },
    { 'code': 'OCCR038_1200037', 'name': 'Sản xuất các sản phẩm từ cao su', 'value': 'OCCR038', 'organization': true, 'exception': false },
    { 'code': 'OCCR038_1200038', 'name': 'Sản xuất cân điện tử', 'value': 'OCCR038', 'organization': true, 'exception': false },
    { 'code': 'OCCR038_1200039', 'name': 'Sản xuất dây giày', 'value': 'OCCR038', 'organization': true, 'exception': false },
    { 'code': 'OCCR038_1200040', 'name': 'Sản xuất đế giày', 'value': 'OCCR038', 'organization': true, 'exception': false },
    { 'code': 'OCCR038_1200041', 'name': 'Sản xuất điện', 'value': 'OCCR038', 'organization': true, 'exception': false },
    { 'code': 'OCCR038_1200042', 'name': 'Sản xuất dụng cụ câu cá', 'value': 'OCCR038', 'organization': true, 'exception': false },
    { 'code': 'OCCR038_1200043', 'name': 'Sản xuất dụng cụ thể thao', 'value': 'OCCR038', 'organization': true, 'exception': false },
    { 'code': 'OCCR038_1200044', 'name': 'Sản xuất găng tay', 'value': 'OCCR038', 'organization': true, 'exception': false },
    { 'code': 'OCCR038_1200045', 'name': 'Sản xuất giấy ', 'value': 'OCCR038', 'organization': true, 'exception': false },
    { 'code': 'OCCR038_1200046', 'name': 'Sản xuất hoặc chế biến tấm bọt xốp', 'value': 'OCCR038', 'organization': true, 'exception': false },
    { 'code': 'OCCR038_1200047', 'name': 'Sản xuất lều', 'value': 'OCCR038', 'organization': true, 'exception': false },
    { 'code': 'OCCR038_1200048', 'name': 'Sản xuất linh kiện điện thoại ', 'value': 'OCCR038', 'organization': true, 'exception': false },
    { 'code': 'OCCR038_1200049', 'name': 'Sản xuất linh kiện điện tử', 'value': 'OCCR038', 'organization': true, 'exception': false },
    { 'code': 'OCCR038_1200050', 'name': 'Sản xuất loa ', 'value': 'OCCR038', 'organization': true, 'exception': false },
    { 'code': 'OCCR038_1200051', 'name': 'Sản xuất máy móc thiết bị', 'value': 'OCCR038', 'organization': true, 'exception': false },
    { 'code': 'OCCR038_1200052', 'name': 'Sản xuất máy tính, linh kiện máy tính', 'value': 'OCCR038', 'organization': true, 'exception': false },
    { 'code': 'OCCR038_1200053', 'name': 'Sản xuất motor', 'value': 'OCCR038', 'organization': true, 'exception': false },
    { 'code': 'OCCR038_1200054', 'name': 'Sản xuất mực in', 'value': 'OCCR038', 'organization': true, 'exception': false },
    { 'code': 'OCCR038_1200055', 'name': 'Sản xuất muỗng nĩa và các đồ kim loại khác', 'value': 'OCCR038', 'organization': true, 'exception': false },
    { 'code': 'OCCR038_1200056', 'name': 'Sản xuất mút xốp', 'value': 'OCCR038', 'organization': true, 'exception': false },
    { 'code': 'OCCR038_1200057', 'name': 'Sản xuất nhựa', 'value': 'OCCR038', 'organization': true, 'exception': false },
    { 'code': 'OCCR038_1200058', 'name': 'Sản xuất nội thất văn phòng', 'value': 'OCCR038', 'organization': true, 'exception': false },
    { 'code': 'OCCR038_1200059', 'name': 'Sản xuất ống nước', 'value': 'OCCR038', 'organization': true, 'exception': false },
    { 'code': 'OCCR038_1200060', 'name': 'Sản xuất phụ gia xi măng', 'value': 'OCCR038', 'organization': true, 'exception': false },
    { 'code': 'OCCR038_1200061', 'name': 'Sản xuất phụ kiện máy điều hòa', 'value': 'OCCR038', 'organization': true, 'exception': false },
    { 'code': 'OCCR038_1200062', 'name': 'Sản xuất pin', 'value': 'OCCR038', 'organization': true, 'exception': false },
    { 'code': 'OCCR038_1200063', 'name': 'Sản xuất sản phẩm từ da lông thú', 'value': 'OCCR038', 'organization': true, 'exception': false },
    { 'code': 'OCCR038_1200064', 'name': 'Sản xuất sợi ', 'value': 'OCCR038', 'organization': true, 'exception': false },
    { 'code': 'OCCR038_1200065', 'name': 'Sản xuất tã giấy và băng vệ sinh', 'value': 'OCCR038', 'organization': true, 'exception': false },
    { 'code': 'OCCR038_1200066', 'name': 'Sản xuất thiết bị điện', 'value': 'OCCR038', 'organization': true, 'exception': false },
    { 'code': 'OCCR038_1200067', 'name': 'Sản xuất van, vòi nước', 'value': 'OCCR038', 'organization': true, 'exception': false },
    { 'code': 'OCCR038_1200068', 'name': 'Sản xuất vỏ bình ga', 'value': 'OCCR038', 'organization': true, 'exception': false },
    { 'code': 'OCCR038_1200069', 'name': 'Sản xuất vôi', 'value': 'OCCR038', 'organization': true, 'exception': false },
    { 'code': 'OCCR038_1200070', 'name': 'Sản xuất, xử lý và phân phối khí, gas', 'value': 'OCCR038', 'organization': true, 'exception': false },
    { 'code': 'OCCR038_1200071', 'name': 'Sữa chữa máy móc thiết bị', 'value': 'OCCR038', 'organization': true, 'exception': false },
    { 'code': 'OCCR038_1200072', 'name': 'Chế biến thủy sản ', 'value': 'OCCR038', 'organization': true, 'exception': false },
    { 'code': 'OCCR038_1200073', 'name': 'Cung cấp nước ', 'value': 'OCCR038', 'organization': true, 'exception': false },
    { 'code': 'OCCR038_1200074', 'name': 'Thiết bị vệ sinh', 'value': 'OCCR038', 'organization': true, 'exception': false },
    { 'code': 'OCCR038_1200075', 'name': 'Thiết kế kiến trúc ', 'value': 'OCCR038', 'organization': true, 'exception': false },
    { 'code': 'OCCR038_1200076', 'name': 'Thoát nước và xử lý nước thải', 'value': 'OCCR038', 'organization': true, 'exception': false },
    { 'code': 'OCCR038_1200077', 'name': 'Tổ chức sự kiện', 'value': 'OCCR038', 'organization': true, 'exception': false },
    { 'code': 'OCCR038_1200078', 'name': 'Trang trí nội thất', 'value': 'OCCR038', 'organization': true, 'exception': false },
    { 'code': 'OCCR038_1200079', 'name': 'Tư vấn xuất nhập khẩu', 'value': 'OCCR038', 'organization': true, 'exception': false },
    { 'code': 'OCCR038_1200080', 'name': 'Vệ sinh nhà cửa và các công trình khác', 'value': 'OCCR038', 'organization': true, 'exception': false },
    { 'code': 'OCCR038_1200081', 'name': 'Xưởng nệm, mút xốp', 'value': 'OCCR038', 'organization': true, 'exception': false },
    { 'code': 'OCCR038_1200082', 'name': 'Xưởng sản xuất đèn trang trí', 'value': 'OCCR038', 'organization': true, 'exception': false },
    { 'code': 'OCCR038_1200083', 'name': 'Xưởng sơn', 'value': 'OCCR038', 'organization': true, 'exception': false },
    { 'code': 'OCCR038_1200084', 'name': 'Năng lượng mặt trời', 'value': 'OCCR038', 'organization': true, 'exception': false },
    { 'code': 'OCCR038_1300001', 'name': 'Khai thác, chế biến thủy sản', 'value': 'OCCR038', 'organization': true, 'exception': false },
    { 'code': 'OCCR038_1300002', 'name': 'Sản xuất khí đốt ', 'value': 'OCCR038', 'organization': true, 'exception': false },
    { 'code': 'OCCR038_1300003', 'name': 'Cơ sở sản xuất vật liệu nổ, cơ sở khai thác, chế biến dầu mỏ, khí đốt;', 'value': 'OCCR038', 'organization': true, 'exception': false },
    { 'code': 'OCCR038_1300004', 'name': 'Gia công cơ khí, xử lý và tráng phủ kim loại', 'value': 'OCCR038', 'organization': true, 'exception': false },
    { 'code': 'OCCR038_1300005', 'name': 'Gia công kim loại', 'value': 'OCCR038', 'organization': true, 'exception': false },
    { 'code': 'OCCR038_1300006', 'name': 'Khai thác than', 'value': 'OCCR038', 'organization': true, 'exception': false },
    { 'code': 'OCCR038_1300007', 'name': 'Kho vật liệu nổ, kho dầu mỏ và sản phẩm dầu mỏ, kho khí đốt hóa lỏng', 'value': 'OCCR038', 'organization': true, 'exception': false },
    { 'code': 'OCCR038_1300008', 'name': 'Kho xăng dầu', 'value': 'OCCR038', 'organization': true, 'exception': false },
    { 'code': 'OCCR038_1300009', 'name': 'Kinh doanh khoáng sản', 'value': 'OCCR038', 'organization': true, 'exception': false },
    { 'code': 'OCCR038_1300010', 'name': 'Mạ kim loại ', 'value': 'OCCR038', 'organization': true, 'exception': false },
    { 'code': 'OCCR038_1300011', 'name': 'Máy nén khí', 'value': 'OCCR038', 'organization': true, 'exception': false },
    { 'code': 'OCCR038_1300012', 'name': 'Nhà máy luyện than cốc', 'value': 'OCCR038', 'organization': true, 'exception': false },
    { 'code': 'OCCR038_1300013', 'name': 'Nhà máy sản xuất phụ tùng máy bay', 'value': 'OCCR038', 'organization': true, 'exception': false },
    { 'code': 'OCCR038_1300014', 'name': 'Nhà máy sản xuất phụ tùng ô tô, xe đạp, xe máy và phụ tùng các loại', 'value': 'OCCR038', 'organization': true, 'exception': false },
    { 'code': 'OCCR038_1300015', 'name': 'Nhà máy xi măng', 'value': 'OCCR038', 'organization': true, 'exception': false },
    { 'code': 'OCCR038_1300016', 'name': 'Sản xuất bu lông, ốc vít', 'value': 'OCCR038', 'organization': true, 'exception': false },
    { 'code': 'OCCR038_1300017', 'name': 'Sản xuất cửa nhôm kính ', 'value': 'OCCR038', 'organization': true, 'exception': false },
    { 'code': 'OCCR038_1300018', 'name': 'Sản xuất đồ gỗ ', 'value': 'OCCR038', 'organization': true, 'exception': false },
    { 'code': 'OCCR038_1300019', 'name': 'Sản xuất gang thép', 'value': 'OCCR038', 'organization': true, 'exception': false },
    { 'code': 'OCCR038_1300020', 'name': 'Sản xuất giường, ghế, bàn, tủ', 'value': 'OCCR038', 'organization': true, 'exception': false },
    { 'code': 'OCCR038_1300021', 'name': 'Sản xuất gỗ thớt và đồ dùng nhà bếp', 'value': 'OCCR038', 'organization': true, 'exception': false },
    { 'code': 'OCCR038_1300022', 'name': 'Sản xuất khung sườn máy hợp kim nhôm, gia công máy CNC', 'value': 'OCCR038', 'organization': true, 'exception': false },
    { 'code': 'OCCR038_1300023', 'name': 'Sản xuất khuôn', 'value': 'OCCR038', 'organization': true, 'exception': false },
    { 'code': 'OCCR038_1300024', 'name': 'Sản xuất kim loại', 'value': 'OCCR038', 'organization': true, 'exception': false },
    { 'code': 'OCCR038_1300025', 'name': 'Sản xuất kính', 'value': 'OCCR038', 'organization': true, 'exception': false },
    { 'code': 'OCCR038_1300026', 'name': 'Sản xuất nhôm', 'value': 'OCCR038', 'organization': true, 'exception': false },
    { 'code': 'OCCR038_1300027', 'name': 'Sản xuất than cốc', 'value': 'OCCR038', 'organization': true, 'exception': false },
    { 'code': 'OCCR038_1300028', 'name': 'Sản xuất thép', 'value': 'OCCR038', 'organization': true, 'exception': false },
    { 'code': 'OCCR038_1300029', 'name': 'Sản xuất ván ép', 'value': 'OCCR038', 'organization': true, 'exception': false },
    { 'code': 'OCCR038_1300030', 'name': 'Thi công xây dựng', 'value': 'OCCR038', 'organization': true, 'exception': false },
    { 'code': 'OCCR038_1300031', 'name': 'Thiết bị cơ khí', 'value': 'OCCR038', 'organization': true, 'exception': false },
    { 'code': 'OCCR038_1300032', 'name': 'Thiết kế và xây dựng công trình', 'value': 'OCCR038', 'organization': true, 'exception': false },
    { 'code': 'OCCR038_1300033', 'name': 'Vận tải', 'value': 'OCCR038', 'organization': true, 'exception': false },
    { 'code': 'OCCR038_1300034', 'name': 'Xăng dầu', 'value': 'OCCR038', 'organization': true, 'exception': false },
    { 'code': 'OCCR038_1300035', 'name': 'Xây dựng', 'value': 'OCCR038', 'organization': true, 'exception': false },
    { 'code': 'OCCR038_1300036', 'name': 'Xưởng hàn, cắt', 'value': 'OCCR038', 'organization': true, 'exception': false },
    { 'code': 'OCCR038_1300037', 'name': 'Khai khoáng', 'value': 'OCCR038', 'organization': true, 'exception': false },
    { 'code': 'OCCR038_0100001', 'name': 'Bác sĩ ', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0100002', 'name': 'Giáo viên ', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0100003', 'name': 'Kế toán ', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0100004', 'name': 'Kiến trúc sư', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0100005', 'name': 'Môi giới bất động sản', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0100006', 'name': 'Nhà thiết kế', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0100007', 'name': 'Nhân viên bán hàng', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0100008', 'name': 'Nhân viên ngân hàng', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0100009', 'name': 'Nhân viên văn phòng ', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0100010', 'name': 'Thợ cắt tóc', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0100011', 'name': 'Thư ký', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0100012', 'name': 'Thu ngân', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0100013', 'name': 'Y tá ', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0100014', 'name': 'Thẩm phán', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0100015', 'name': 'Kiểm soát viên', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0100016', 'name': 'Chuyên viên đánh giá (nhà phê bình văn học, hội họa, ẩm thực...)', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0100017', 'name': 'Nhân viên thẩm mỹ viện', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0100018', 'name': 'Quản lý chăn nuôi ', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0100019', 'name': 'Quản lý nhà ga ', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0100020', 'name': 'Nhân viên ga tàu ', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0100021', 'name': 'Quản lý hành chính ', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0100022', 'name': 'Thiết kế thời trang ', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0100023', 'name': 'Dược sĩ', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0100024', 'name': 'Y sĩ', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0100025', 'name': 'Hành chính bệnh viện ', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0100026', 'name': 'Kỹ thuật viên vật lý trị liệu ', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0100027', 'name': 'Nhân viên hành chính', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0100028', 'name': 'Hội họa', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0100029', 'name': 'Biên kịch', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0100030', 'name': 'Nhân viên tráng phim', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0100031', 'name': 'Nhân viên bán vé, soát vé rạp chiếu phim', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0100032', 'name': 'Nhân viên rạp chiếu phim', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0100033', 'name': 'Nhạc sỹ ', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0100034', 'name': 'Nhân viên bán hàng trong khu vui chơi', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0100035', 'name': 'Quản lý nhà hàng ', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0100036', 'name': 'Quản lý khách sạn ', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0100037', 'name': 'Quản lý hồ bơi', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0100038', 'name': 'Nhân viên bán vé', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0100039', 'name': 'Giảng viên ', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0100040', 'name': 'Sinh viên ', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0100041', 'name': 'Học sinh', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0100042', 'name': 'Trẻ em ', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0100043', 'name': 'Biên dịch viên ', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0100044', 'name': 'Người tu hành ', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0100045', 'name': 'Mục sư, linh mục ', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0100046', 'name': 'Nhân viên spa', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0100047', 'name': 'Hành chính nhân sự ', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0100048', 'name': 'Nhà chuyên môn về khoa học trái đất và vật lý ', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0100049', 'name': 'Nhà toán học, thống kê ', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0100050', 'name': 'Điều dưỡng ', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0100051', 'name': 'Nhà tư vấn tài chính', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0100052', 'name': 'Nghề nghiệp khác', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0200001', 'name': 'Bác sỹ thú y', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0200002', 'name': 'Bảo mẫu', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0200003', 'name': 'Bảo vệ ', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0200004', 'name': 'Đầu bếp', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0200005', 'name': 'Huấn luyện viên thể thao', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0200006', 'name': 'Hướng dẫn viên du lịch', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0200007', 'name': 'May mặc', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0200008', 'name': 'Người đi chào hàng', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0200009', 'name': 'Nhân viên giao hàng', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0200010', 'name': 'Nhân viên kinh doanh ', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0200011', 'name': 'Nhân viên phục vụ', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0200012', 'name': 'Nội trợ ', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0200013', 'name': 'Buôn bán phế liệu', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0200014', 'name': 'Bán hàng rong ', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0200015', 'name': 'Buôn bán động vật ', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0200016', 'name': 'Quản lý kho', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0200017', 'name': 'Kinh doanh vàng bạc, đá quý', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0200018', 'name': 'Ghi đồng hồ, thu phí điện nước', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0200019', 'name': 'Pha chế ', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0200020', 'name': 'Thợ chụp hình/ nhiếp ảnh gia', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0200021', 'name': 'Người làm dịch vụ ma chay cưới hỏi', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0200022', 'name': 'Giặt ủi ', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0200023', 'name': 'Thợ sửa khóa ', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0200024', 'name': 'Thợ trang điểm ', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0200025', 'name': 'Nhân viên trạm xăng ', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0200026', 'name': 'Nhân viên quản lý/ kiểm định chất lượng sản phẩm ', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0200027', 'name': 'Công nhân trồng cây', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0200028', 'name': 'Kỹ sư nông nghiệp ', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0200029', 'name': 'Giám đốc nông trường, hợp tác xã, cán bộ khuyến nông', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0200030', 'name': 'Chăn nuôi gia cầm, gia súc', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0200031', 'name': 'Trồng cây cảnh ', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0200032', 'name': 'Thiết kế công trình ', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0200033', 'name': 'Quản lý hệ thống cung cấp nước ', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0200034', 'name': 'Nuôi thủy sản', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0200035', 'name': 'Nhân viên phát thư', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0200036', 'name': 'Quản lý bến xe', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0200037', 'name': 'Kiểm định, đăng kiểm', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0200038', 'name': 'Hải quan ', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0200039', 'name': 'Thiết kế, quản lý cửa hàng nội thất ', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0200040', 'name': 'Kỹ thuật điện tử/ cơ điện ', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0200041', 'name': 'Lắp ráp thiết bị điện tự', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0200042', 'name': 'Công nhân giày da', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0200043', 'name': 'Công nhân bao bì ', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0200044', 'name': 'Công nhân sản xuất hóa chất ', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0200045', 'name': 'Chế biến thực phẩm ', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0200046', 'name': 'Mổ gia súc, gia cầm ', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0200047', 'name': 'Gia công thủ công mỹ nghệ trên giấy/ vải ', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0200048', 'name': 'Thợ làm đồ gốm ', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0200049', 'name': 'Công nhân sản xuất nhựa', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0200050', 'name': 'Công nhân gia công sản phẩm từ cao su', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0200051', 'name': 'Công nhân xưởng giấy ', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0200052', 'name': 'Đạo diễn ', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0200053', 'name': 'Quay phim ', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0200054', 'name': 'Nhân viên hậu cần', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0200055', 'name': 'Phóng viên ', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0200056', 'name': 'Biên tập viên ', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0200057', 'name': 'MC', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0200058', 'name': 'Công nhân xưởng in', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0200059', 'name': 'Nhân viên khử trùng ', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0200060', 'name': 'Thầy lang', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0200061', 'name': 'Bác sỹ/ y tá bệnh viện tâm thần ', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0200062', 'name': 'Bác sỹ/ y tá tại trại giam', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0200063', 'name': 'Sửa chữa máy móc văn phòng ', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0200064', 'name': 'Tạp vụ ', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0200065', 'name': 'Hộ lý ', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0200066', 'name': 'Nhân viên vệ sinh văn phòng ', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0200067', 'name': 'Chăm sóc thú ', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0200068', 'name': 'Vệ sinh chuồng thú ', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0200069', 'name': 'Nhân viên đạo cụ, dựng cảnh', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0200070', 'name': 'Trợ lý, thư ký trường quay', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0200071', 'name': 'Nhân viên phụ trách âm thanh, ánh sáng, phụ trách kỹ thuật hiện trường', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0200072', 'name': 'Phóng viên truyền hình', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0200073', 'name': 'Nhân viên phục trang', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0200074', 'name': 'Diễn viên', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0200075', 'name': 'Ca sỹ ', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0200076', 'name': 'Bầu show', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0200077', 'name': 'Người mẫu ', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0200078', 'name': 'Quản lý khu giải trí, quán bar, karaoke', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0200079', 'name': 'Công an', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0200080', 'name': 'Quản lý trại giam ', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0200081', 'name': 'Trọng tài ', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0200082', 'name': 'Thầy cúng ', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0200083', 'name': 'Thầy bói ', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0200084', 'name': 'Môi giới chứng khoán ', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0200085', 'name': 'Nhân viên massage, spa, sauna, xông hơi (không phải trong thẩm mĩ viện)', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0200086', 'name': 'Thất nghiệp ', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0200087', 'name': 'Bán buôn, bán lẻ', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0200088', 'name': 'Truyền thông ', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0200089', 'name': 'Nông ', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0200090', 'name': 'Nghiên cứu thị trường ', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0200091', 'name': 'Kỹ sư môi trường ', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0200092', 'name': 'Kỹ sư hóa học ', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0300001', 'name': 'Cảnh sát hình sự', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0300002', 'name': 'Công an, an ninh (không làm việc tại cơ quan, văn phòng)', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0300003', 'name': 'Công nhân cưa gỗ', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0300004', 'name': 'Công nhân sản xuất ghép gỗ, sản xuất gỗ ép, thợ mộc', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0300005', 'name': 'Công nhân vệ sinh đường cống', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0300006', 'name': 'Gia công kim loại', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0300007', 'name': 'Giám sát xây dựng', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0300008', 'name': 'Khuân vác', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0300009', 'name': 'Kiểm lâm', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0300010', 'name': 'Kỹ sư cơ khí', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0300011', 'name': 'Kỹ sư dân dụng ', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0300012', 'name': 'Làm việc liên quan đến điện cao áp ', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0300013', 'name': 'Lao động tự do ', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0300014', 'name': 'Lính cứu hỏa', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0300015', 'name': 'Người lau cửa kính bên ngoài tòa nhà tại độ cao dưới 30 mét', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0300016', 'name': 'Người nuôi cá sấu, rắn, động vật nguy hiểm', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0300017', 'name': 'Nhân viên bán thịt', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0300018', 'name': 'Nhân viên bảo vệ xe (quán bar, vũ trường, karaoke)', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0300019', 'name': 'Nhân viên cứu hộ vùng sông nước, đồi núi', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0300020', 'name': 'Nhân viên thi công, lắp đặt nội thất ', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0300021', 'name': 'Nhân viên phòng độc, diệt côn trùng', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0300022', 'name': 'Quản lý công trường hầm mỏ', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0300023', 'name': 'Tài xế', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0300024', 'name': 'Tài xế bồn chở xăng, dầu, khí đốt, chất nổ', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0300025', 'name': 'Tài xế xe chở tiền ', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0300026', 'name': 'Tài xế xe cứu hỏa, xe cứu thương', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0300027', 'name': 'Tài xế, phụ xe khách đường dài, liên tỉnh', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0300028', 'name': 'Thám tử', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0300029', 'name': 'Thi công công trình thủy lợi', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0300030', 'name': 'Thợ điện ', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0300031', 'name': 'Thợ hàn', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0300032', 'name': 'Thợ lặn', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0300033', 'name': 'Thợ lắp kính công trình', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0300034', 'name': 'Thợ nhôm', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0300035', 'name': 'Thợ sắt/ thép', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0300036', 'name': 'Thợ sơn', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0300037', 'name': 'Thợ sửa xe', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0300038', 'name': 'Thợ sửa xe gắn máy, ô tô', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0300039', 'name': 'Thợ xây, thợ hồ, phụ hồ', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0300040', 'name': 'Tra xét, bắt buôn lậu', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0300041', 'name': 'Vận đông viên chuyên nghiệp', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0300042', 'name': 'Nhân viên bán vật liệu xây dựng', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0300043', 'name': 'Công nhân vệ sinh đường phố', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0300044', 'name': 'Nhân viên phòng chống cháy rừng ', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0300045', 'name': 'Hái dừa, hái cau', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0300046', 'name': 'Nuôi ong', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0300047', 'name': 'Giám sát công trình thủy lợi', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0300048', 'name': 'Công nhân bảo trì, thi công, sửa chữa đường sắt ', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0300049', 'name': 'Lái tàu', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0300050', 'name': 'Nhân viên soát vé trên tàu ', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0300051', 'name': 'Nhân viên phục vụ trên tàu', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0300052', 'name': 'Công nhân bảo trì, sửa chữa khoang tàu', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0300053', 'name': 'Công nhân bốc dỡ hàng hóa trên tàu ', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0300054', 'name': 'Nhân viên tiếp nhiên liệu', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0300055', 'name': 'Công nhân lắp đặt thang máy ', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0300056', 'name': 'Xây dựng', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0300057', 'name': 'Bảo trì văn phòng ', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0300058', 'name': 'Sữa chữa/ đóng tàu thuyền ', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0300059', 'name': 'Khai thác khoáng sản ', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0300060', 'name': 'Thợ tiện ', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0300061', 'name': 'Thợ khoang', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0300062', 'name': 'Thợ đánh bóng ', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0300063', 'name': 'Thợ nồi hơi', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0300064', 'name': 'Công nhân luyện/ cán/ đúc kim loại ', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0300065', 'name': 'Luyện than', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0300066', 'name': 'Công nhân phòng đông lạnh', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0300067', 'name': 'Điêu khắc ', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0300068', 'name': 'Huấn luyện viên sở thú, rạp xiếc ', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0300069', 'name': 'Tiếp viên (phục vụ hộp đêm, quán bar, karaoke)', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0300070', 'name': 'Cứu hộ hồ bơi/ bãi biển ', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0300071', 'name': 'Cảnh sát giao thông ', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0300072', 'name': 'Dân quân tự vệ, dân phòng', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0300073', 'name': 'Dạy lái xe ', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0300074', 'name': 'Học sinh trường xiếc, thể thao, nghệ thuật', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0300075', 'name': 'Khai thác đá ', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0300076', 'name': 'Nhà khoa học hạt nhân ', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0300077', 'name': 'Người huấn luyện chó ', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0300078', 'name': 'Nhân viên làm việc tại quán bar, vũ trường ', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0300079', 'name': 'Nhân viên sòng bạc ', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0300080', 'name': 'Nhân viên múa cột, vũ nữ', 'value': 'OCCR038', 'organization': false, 'exception': false },
    { 'code': 'OCCR038_0300081', 'name': 'Vận tải ', 'value': 'OCCR038', 'organization': false, 'exception': false },

];
export default Occupation;