import React, { useState, useEffect } from 'react'
import { redirect, useLoaderData } from 'react-router-dom';
import CommonHelper from '../../helpers/common';
import Authentication from '../../api/authApi';
import bgLogin from "../../assets/images/service-img3.jpg";
import TextInput from '../../components/text-input';
import Loading from '../../components/loading';
import AlertBox from '../../components/alert';
import MemberRegister from './components/member-reg';


export async function loader() {
    var _q = window.location.search;
    if (CommonHelper.is_not_empty(_q)) {
        _q = _q.substring(5);
    } else {
        _q = "/";
    }
    var obj = await Authentication.get_user();
    if (obj.code === 200) {
        window.location.href = _q;
    }
    return null;
}

/*var initial = {
    phone: "",
    email: "",
    pwd: "",
    full_name: ""
};*/

const initial = {
    cus_reg_dob: null,
    cus_name: "",
    cus_title: "",
    cus_idcard: "",
    cus_tel: "",
    cus_email: "",
    cus_address: "",
    cus_dist_code: "",
    cus_dist_name: "",
    cus_prov_code: "",
    cus_prov_name: "",
    cus_confirm_collect: false,
    cus_pwd: "",
    cus_pwd_cfm: "",
    veh_sob_code: "",
    veh_sob_name: "",
    veh_agency_code: "",
    veh_agency_name: "",
    veh_broker_code: "",
    veh_broker_name: "",
};

const initialAlert = {
    is_show: false,
    title: "",
    content: "",
    variant: "danger"
};

function Register() {
    const [state, setState] = useState(initial);
    const [alertData, setAlertData] = useState(initialAlert);
    const [{ showLoading }, setShow] = useState(
        { showLoading: false }
    );
    const handle_register = (res) => {
        if (res != null) {
            if (res.code === 200) {
                setTimeout(
                    function () {
                        window.location.href = "/sale/ctpl-vehicle";
                    },
                    1500
                );
            }
        }
    }
    return (
        <>
            <MemberRegister data={state} onCancel={() => { }}
                onRegister={handle_register}
                label={{ "lb_cancel": "ĐĂNG NHẬP", "lb_reg": "ĐĂNG KÝ" }}
                title="ĐĂNG KÝ TRỞ THÀNH KHÁCH HÀNG THÂN THIẾT CỦA FUBON" />
            {showLoading && <Loading />}
            <AlertBox data={alertData} onChange={(e) => { setAlertData((prev) => ({ ...prev, is_show: e })) }} />
        </>
    )
}

export default Register