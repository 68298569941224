import React, { useState, useEffect } from 'react'
import { redirect, useLoaderData } from 'react-router-dom';
import CommonHelper from '../../helpers/common';
import Authentication from '../../api/authApi';
import bgLogin from "../../assets/images/bg-login.jpg";
import TextInput from '../../components/text-input';
import Loading from '../../components/loading';
import AlertBox from '../../components/alert';

export async function loader() {
    var _q = window.location.search;
    if (CommonHelper.is_not_empty(_q)) {
        _q = _q.substring(5);
    } else {
        _q = "/";
    }
    var obj = await Authentication.get_user();
    if (obj.code === 200) {
        window.location.href = _q;
    }
    return null;
}

const initial = {
    "user_name": "",
    "password": ""
};

const initialAlert = {
    is_show: false,
    title: "",
    content: "",
    variant: "danger"
};

function Login() {
    const [state, setState] = useState(initial);
    const [alertData, setAlertData] = useState(initialAlert);
    const [showLoading, setShowLoading] = useState(false);
    const handle_change = (e) => {
        const { name, value } = e.target;
        setState((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    }
    const handle_login = () => {
        var _req = { ...state };
        var flag = true;
        if (CommonHelper.is_empty(_req.user_name)) {
            flag = false;
        }
        if (CommonHelper.is_empty(_req.password)) {
            flag = false;
        }
        if (flag) {
            setShowLoading(true);
            var response = Authentication.login(_req);
            response.then((res) => {
                setShowLoading(false);
                var _q = window.location.search;
                if (CommonHelper.is_not_empty(_q)) {
                    _q = _q.substring(5);
                } else {
                    _q = "/sale/ctpl-vehicle";
                }
                if (res.code === 208) {
                    window.location.href = _q;
                } else if (res.code === 700) {
                    setAlertData((prev) => ({
                        ...prev,
                        is_show: true,
                        variant: "danger",
                        title: "Lỗi",
                        content: "Tài khoản không tồn tại!"
                    }))
                } else if (res.code === 710) {
                    setAlertData((prev) => ({
                        ...prev,
                        is_show: true,
                        variant: "danger",
                        title: "Lỗi",
                        content: "Sai mật khẩu. Vui lòng kiểm tra lại!"
                    }))
                }
            })
        } else {
            console.log(flag);
            setAlertData((prev) => ({
                ...prev,
                is_show: true,
                variant: "danger",
                title: "Lỗi",
                content: "Vui lòng nhập đầy đủ thông tin đăng nhập!"
            }))
        }
    }
    const handle_enter = (e) => {
        console.log(e);
        if (e.key === "Enter") {
            handle_login();
        }
    }
    return (
        <>
            <div className='row'>
                <div className='col-sm-6'>
                    <div className='mt15px'>
                        <h1 className='text-center'>ĐĂNG NHẬP</h1>
                    </div>
                    <div style={{ width: "60%", margin: "auto" }}>
                        <div className='mt15px'>
                            <div className="form-floating">
                                <TextInput name="user_name" placeholder="Số điện thoại/Email" value={state.user_name} onChange={handle_change} onKeyDown={handle_enter} />
                                <label className='required'>Số điện thoại/Email</label>
                            </div>
                            <div className="form-floating mt15px">
                                <input name="password" type="password" placeholder="Mật khẩu" className='form-control' value={state.password} onChange={handle_change} onKeyDown={handle_enter} />
                                <label className='required'>Mật khẩu</label>
                            </div>
                        </div>
                        <div className='mt15px'>
                            <button className='btn btn-info btn-login' style={{ color: "#fff", height: "60px", width: "200px" }} onClick={handle_login}>ĐĂNG NHẬP</button>
                        </div>
                        <div className='mt10px'>
                            <a href='/quen-mat-khau'>Quên mật khẩu?</a>
                        </div>
                        <div className='mt10px'>
                            <a href='/dang-ky'>Đăng ký tài khoản?</a>
                        </div>
                    </div>
                </div>
                <div className='col-sm-6'>
                    <div className='row'>
                        <img src={bgLogin} style={{ width: "100%" }} />
                    </div>
                </div>
            </div>
            {showLoading && <Loading />}
            <AlertBox data={alertData} onChange={(e) => { setAlertData((prev) => ({ ...prev, is_show: e })) }} />
        </>
    )
}

export default Login