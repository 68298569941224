import React, { useState, useEffect } from 'react'
import * as FaIcons from 'react-icons/fa'
import CommonHelper from '../../../helpers/common';
import { initial_insured_pet } from '../util';

function InsuredPet(props) {
    const [state, setState] = useState([initial_insured_pet]);
    const {
        dataPet: valuePropPet,
        onEditPet,
        onDelPet,
        hasActionPet,
        kind_of_pet,
        step_code,
        disabled,
        ...other
    } = props;
    const [{
        total_premium,
        total_tax_amt
    }, setSelected] = useState({
        total_premium: 0,
        total_tax_amt: 0,
    });
    useEffect(() => {
        var _total = 0;
        var _total_tax = 0;
        if (valuePropPet != null && valuePropPet.length > 0) {
            valuePropPet.forEach((item) => {
                _total += item.premium + item.objPackage.taxAmt;
                _total_tax += item.objPackage.taxAmt;
            })
            setSelected((prev) => ({
                ...prev,
                total_premium: _total,
                total_tax_amt: _total_tax,
            }))
        }

        let state_lst_pet_exclusive = [];
        valuePropPet.forEach((item) => {
            let hasExclusiveQuestion = false;

            item.survey_questions.forEach((question) => {
                if (question.answer_yn === 'Y') {
                    hasExclusiveQuestion = true;
                }
            });

            if (hasExclusiveQuestion) {
                state_lst_pet_exclusive.push(item);
            }
        });

        setState(valuePropPet);// list pet
    }, [valuePropPet]);

    const handle_edit_pet = (item) => {
        onEditPet(item);
    };
    const handle_delete_pet = (item) => {
        onDelPet(item);
    };

    return (
        <div>
            {state !== null && state.length > 0 && (
                <div>
                    <div id="insured-pet">
                        <div className='row mt-3'>
                            {hasActionPet && (
                                <div className='col-sm-12'>
                                    Số lượng thú cưng được bảo hiểm: <span className='fw-bolder'>
                                        {state.length}
                                    </span>
                                </div>
                            )}
                        </div>
                        <div className='row mt-3'>
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th scope="col" style={{ width: "45px" }}>
                                            STT
                                        </th>
                                        <th scope="col">
                                            Loại
                                        </th>
                                        <th scope="col" >
                                            Tên
                                        </th>
                                        <th scope="col" >
                                            Số Chip
                                        </th>
                                        <th scope="col" >
                                            Giống
                                        </th>
                                        <th scope="col" >
                                            Loài
                                        </th>
                                        <th scope="col" >
                                            Ngày sinh
                                        </th>
                                        <th scope="col" >
                                            Tuổi
                                        </th>
                                        <th scope="col" >
                                            Gói bảo hiểm
                                        </th>
                                        {hasActionPet && (<th scope="col" style={{ width: "65px" }}></th>)}
                                    </tr>
                                </thead>
                                <tbody>
                                    {state.map((item, i) => {
                                        return (
                                            <tr
                                                key={i}
                                                style={{
                                                    textAlign: 'center',
                                                    verticalAlign: 'middle'
                                                }}
                                            >
                                                <td scope="row">
                                                    {i + 1}
                                                </td>
                                                <td>
                                                    {item.kindOfPet == "PET_DOG" ? "Chó" : "Mèo"}
                                                </td>
                                                <td>
                                                    {item.pet_name}
                                                </td>
                                                <td>
                                                    {item.chipNo}
                                                </td>
                                                <td>
                                                    {item.gender == "1" ? "Đực" : "Cái"}
                                                </td>
                                                <td>
                                                    {item.typeOfPet}
                                                </td>
                                                <td>
                                                    {CommonHelper.convertToDDMMYYYY(item.dob)}</td>
                                                <td>
                                                    {CommonHelper.calculate_age_pet(CommonHelper.parseStringToDate(item.dob, "yyyyMMdd"))}
                                                </td>
                                                <td>
                                                    {item.objPackage != null && (
                                                        <>
                                                            <div>
                                                                {item.objPackage.pkg_name}
                                                            </div>
                                                            <div className='text-end'>
                                                                <b>
                                                                    {item.objPackage.premium ? CommonHelper.format_currency_vnd_style(item.objPackage.premium) : ""}
                                                                </b>
                                                            </div>
                                                        </>
                                                    )}
                                                </td>
                                                {/* {hasActionPet && (
                                                    <td>
                                                        <a 
                                                            href='javascript:;' 
                                                            onClick={() => { handle_edit_pet(item) }}
                                                            disabled={disabled}
                                                        >
                                                            <FaIcons.FaPencilAlt />
                                                        </a>
                                                        &nbsp;&nbsp;&nbsp;
                                                        <a 
                                                            href='javascript:;' 
                                                            onClick={() => { handle_delete_pet(item) }}
                                                            disabled={disabled}
                                                        >
                                                            <FaIcons.FaTimes style={{ color: "#ff0000" }} />
                                                        </a>
                                                    </td>
                                                )} */}
                                                {hasActionPet && (
                                                    <td>
                                                        <a
                                                            href='javascript:;'
                                                            onClick={(e) => {
                                                                if (!disabled) handle_edit_pet(item);
                                                                e.preventDefault(); // Prevent navigation if disabled
                                                            }}
                                                            style={{ 
                                                                color: disabled ? '#ccc' : '#0000ff', 
                                                                cursor: disabled ? 'not-allowed' : 'pointer', 
                                                                pointerEvents: disabled ? 'none' : 'auto' 
                                                            }}
                                                        >
                                                            <FaIcons.FaPencilAlt />
                                                        </a>
                                                        &nbsp;&nbsp;&nbsp;
                                                        <a
                                                            href='javascript:;'
                                                            onClick={(e) => {
                                                                if (!disabled) handle_delete_pet(item);
                                                                e.preventDefault(); // Prevent navigation if disabled
                                                            }}
                                                            style={{ 
                                                                color: disabled ? '#ccc' : '#ff0000', 
                                                                cursor: disabled ? 'not-allowed' : 'pointer', 
                                                                pointerEvents: disabled ? 'none' : 'auto' 
                                                            }}
                                                        >
                                                            <FaIcons.FaTimes />
                                                        </a>
                                                    </td>
                                                )}
                                            </tr>
                                        )
                                    })}
                                    {
                                        step_code && (
                                            <>
                                                <tr>
                                                    <td></td>
                                                    <td colSpan={4}>
                                                        Tổng phí VAT
                                                    </td>
                                                    <td colSpan={4}>
                                                        <div className='text-end'>
                                                            <b style={{ color: 'blue' }}>
                                                                {CommonHelper.format_currency_vnd_style(total_tax_amt)}
                                                            </b>
                                                        </div>
                                                    </td>
                                                    {hasActionPet && (
                                                        <td></td>
                                                    )}
                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    <td colSpan={4} style={{ verticalAlign: 'middle' }}>
                                                        <b>
                                                            Tổng phí bảo hiểm
                                                        </b>
                                                    </td>
                                                    <td colSpan={4}>
                                                        <div className='text-end'>
                                                            <b style={{ fontSize: '24px', color: 'red' }}>
                                                                {CommonHelper.format_currency_vnd_style(total_premium)}
                                                            </b>
                                                        </div>
                                                    </td>
                                                    {hasActionPet && (
                                                        <td></td>
                                                    )}
                                                </tr>
                                            </>
                                        )
                                    }
                                </tbody>
                            </table>
                        </div>
                        <div className='row mt-3 d-none'>
                            <div className='col-sm-12'>
                                <p style={{ fontWeight: '700', fontStyle: 'italic' }}>
                                    Lưu ý:
                                </p>
                                <p style={{ fontStyle: "italic" }}>
                                    Nếu muốn "Tăng/Giảm" số lượng thú cưng được bảo hiểm. Quý khách click vào nút "Quay lại" và cập nhật trường "Số lượng thú cưng được bảo hiểm".
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            )}

        </div>
    )
}

export default InsuredPet;