import React, { useState, useEffect } from 'react'
import paiApi from '../../../api/paiApi'
import CommonHelper from '../../../helpers/common'
import { useLoaderData, useSearchParams } from 'react-router-dom'
import Loading from '../../../components/loading'
import AlertBox from '../../../components/alert'

export async function loader({ params }) {
    console.log(params)
    if (params.id !== undefined && CommonHelper.is_not_empty(params.id)) {
        return { data: params.id };
    }
    return null;
}
const initialAlert = {
    is_show: false,
    title: "",
    content: "",
    variant: "danger"
};
function PaiOrderConfirmation() {
    const [alertData, setAlertData] = useState(initialAlert);
    const policy = useLoaderData();
    useEffect(() => {
        if (policy != null) {
            if (CommonHelper.is_not_empty(window.location.search)) {
                var _param = window.location.search;
                _param += "&status=" + policy.data;
                const response = paiApi.get_pai_customer_confirmation(_param);
                response.then((res) => {
                    if (res.code == 200) {
                        setAlertData((prev) => ({
                            ...prev,
                            title: "Thông tin",
                            content: "Hệ thống đã ghi nhận thông tin của Quý khách. Xin cám ơn!",
                            is_show: true,
                            variant: "success"
                        }))
                    } else {
                        setAlertData((prev) => ({
                            ...prev,
                            title: "Thông tin",
                            content: "Xử lý thất bại. Vui lòng liên hệ nhân viên Fubon. Xin cám ơn!",
                            is_show: true,
                            variant: "danger"
                        }))
                    }

                    const timer = setTimeout(() => {
                        window.location.href = window.location.origin;
                    }, 4000);
                })
            }
        }


    }, [policy])
    return (
        <div style={{ height: "400px" }}>
            <Loading />
            <AlertBox data={alertData} onChange={(e) => { setAlertData((prev) => ({ ...prev, is_show: e })) }} />
        </div>
    )
}

export default PaiOrderConfirmation