import React, { useEffect, useState } from "react";
import { useLocation, NavLink, Link } from "react-router-dom";
import { Nav } from "react-bootstrap";
import fbBanner from "../../../assets/images/logo.svg"


const sidebarRoutes = [
  {
    path: "/dai-ly/ctpl-moto",
    name: "Bảo hiểm xe máy",
    // icon: "nc-icon nc-circle-09",
    layout: "/dai-ly"
  },
  {
    path: "/dai-ly/ctpl-vehicle",
    name: "Bảo hiểm ô tô",
    // icon: "nc-icon nc-delivery-fast",
    layout: "/dai-ly"
  },
  {
    path: "/dai-ly/pa-insurance",
    name: "Bảo hiểm tai nạn",
    // icon: "nc-icon nc-pin-3",
    layout: "/dai-ly"
  },
  /*{
    path: "/dai-ly/pet-insurance",
    name: "Bảo hiểm thú cưng",
    // icon: "nc-icon nc-pin-3",
    layout: "/dai-ly"
  },*/
  {
    path: "/dai-ly/danh-sach-hop-dong",
    name: "Quản lý hợp đồng",
    // icon: "nc-icon nc-notes",
    layout: "/dai-ly"
  },
];

function Sidebar() {
  const location = useLocation();
  const [{ active }, setActive] = useState({
    active: "",
  })
  useEffect(() => {
    setActive((prev) => ({ ...prev, active: location.pathname }))
  }, [location])

  return (
    <div className="sidebar">
      {/* <div className="sidebar-background" /> */}
      <div className="sidebar-wrapper bg-white">
        <div className="logo d-flex align-items-center justify-content-start bg-white">
          <a href="/" className="simple-text logo-mini mx-1">
            <img src={fbBanner} />
          </a>
        </div>
        <div className="mt-1">
          <ul className="list-group list-group-flush">
            {sidebarRoutes.map((prop, key) => {
              if (!prop.redirect)
                return (
                  <li className={"list-group-item " + (active === prop.path ? "active" : "")} key={key} >
                    <a href={prop.path} className="nav-link fw-bolder" activeClassName="active">
                      {prop.name}
                    </a>
                  </li>
                );
              return null;
            })}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Sidebar;
