import React, { useEffect, useRef, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import CommonHelper from '../../../helpers/common';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Navigation } from 'swiper/modules';
import { FaCheckCircle } from "react-icons/fa";
import "./index.css";

function PetBenefits(props) {
    const { 
        onSelect, 
        list, 
        pkg_selected, 
        pet_option, 
        name, 
        kind_of_pet, 
        t,
        toggleLanguage,
        ...other 
    } = props;
    const [listPetRender, setListPetRender] = useState([]);
    useEffect(() => {
        setListPetRender(convertPackages(list));
    }, [list]);

    const convertPackages = (packages) => {
        const result = [];
        packages.forEach(pkg => {
            const existingPackage = result.find(r => r.pkg_code === pkg.pkg_code);
            if (existingPackage) {
                existingPackage.premium.push(pkg.premium);
                existingPackage.taxRatio.push(pkg.taxRatio);
                existingPackage.taxAmt.push(pkg.taxAmt);
                existingPackage.type.push(pkg.type);
            } else {
                result.push({
                    pkg_code: pkg.pkg_code,
                    pkg_name: pkg.pkg_name,
                    premium: [pkg.premium],
                    taxRatio: [pkg.taxRatio],
                    taxAmt: [pkg.taxAmt],
                    type: [pkg.type],
                    smi1: pkg.smi1,
                    smi2: pkg.smi2,
                    smi3: pkg.smi3,
                    smi4: pkg.smi4,
                    smi5: pkg.smi5,
                    smi6: pkg.smi6,
                    smi7: pkg.smi7
                });
            }
        });
        return result;
    };

    return (
        <>
            <section className="container">
                <div 
                    className="title_section" 
                    // data-descr="PHẠM VI BẢO HIỂM & PHÍ BẢO HIỂM" 
                    data-descr={t('pet_benefits_title')}
                    style={{ color: '#564741' }}
                >
                    <span>
                        {/* PHẠM VI BẢO HIỂM & PHÍ BẢO HIỂM */}
                        {t('pet_benefits_title')}
                    </span>
                </div>
            </section>
            <section 
                className="container" 
                style={{
                    maxWidth: '1480px', 
                }}
            >
                <div 
                    className="row justify-content-center" 
                    style={{ alignItems: 'center' }}
                >
                    <div className='d-block'>
                        <h3 
                            className='text-center' 
                            style={{ color: '#20c997' }}
                        >
                            {/* PHẠM VI BẢO HIỂM & PHÍ BẢO HIỂM */}
                            {t('pet_benefits_small_title')}
                        </h3>
                        <p 
                            style={{ 
                                textAlign: 'right', 
                                fontWeight: '700', 
                                fontStyle: 'italic' 
                            }}
                        >
                            {/* Đơn vị: VNĐ */}
                            {t('pet_benefits_money_unit')}
                        </p>
                        <div 
                            className='d-flex' 
                            style={{ 
                                border: '4px dashed rgb(175, 71, 62)', 
                                padding: '8px' 
                            }}
                        >
                            <div className='w-40'>
                                <div 
                                    className="plan-description mat-card" 
                                    style={{ 
                                        marginTop: '6px', 
                                        border: 'none', 
                                        fontSize: '15px' 
                                    }}
                                >
                                    <div className='head text-center d-none'>
                                        {t('pet_benefits_sub_title')}
                                    </div>
                                    <div 
                                        className='body' 
                                        style={{ height: '800px' }}
                                    >
                                        <p 
                                            className='fs-4 fw-bolder' 
                                            style={{ height: "48px" }}
                                        >
                                            {t('pet_benefits_subtitle')}
                                        </p>
                                        <p className='p-location'>
                                            <div className='row'>
                                                <div className='col-md-3'>
                                                    <div 
                                                        className='row' 
                                                        style={{ marginTop: '120px' }}
                                                    >
                                                        <p 
                                                            className='p-location' 
                                                            style={{ borderBottom: 'none' }}
                                                        >
                                                            <FaCheckCircle 
                                                                style={{ 
                                                                    fontSize: '20px', 
                                                                    marginRight: '8px', 
                                                                    color: '#20c997' 
                                                                }} 
                                                            />
                                                            {t('pet_benefits_desc1')}
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className='col-md-4'>
                                                    <div className='row'>
                                                        <p 
                                                            className='p-location' 
                                                            style={{ paddingBottom: '5px' }}
                                                        >
                                                            {t('pet_benefits_desc2')}
                                                        </p>
                                                    </div>
                                                    <div className='row'>
                                                        <p 
                                                            className='p-location' 
                                                            style={{ 
                                                                height: '104px', 
                                                                paddingTop: '32px' 
                                                            }}
                                                        >
                                                            {t('pet_benefits_desc3')}
                                                        </p>
                                                    </div>
                                                    <div className='row'>
                                                        <p 
                                                            className='p-location'
                                                            style={{ 
                                                                border: 'none', 
                                                                paddingTop: '24px' 
                                                            }}
                                                        >
                                                            {t('pet_benefits_desc4')}
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className='col-md-5'>
                                                    <div className='row'>
                                                        <p 
                                                            className='p-location' 
                                                            style={{ paddingBottom: '20px' }}
                                                        >
                                                            {t('pet_benefits_desc5')}
                                                        </p>
                                                    </div>
                                                    <div className='row'>
                                                        <p 
                                                            className='p-location' 
                                                            style={{ paddingBottom: '22px' }}
                                                        >
                                                            {t('pet_benefits_desc6')}
                                                        </p>
                                                    </div>
                                                    <div className='row'>
                                                        <p 
                                                            className='p-location' 
                                                            style={{ paddingBottom: '22px' }}
                                                        >
                                                            {t('pet_benefits_desc5')}
                                                        </p>
                                                    </div>
                                                    <div className='row'>
                                                        <p 
                                                            className='p-location' 
                                                            style={{ paddingBottom: '22px' }}
                                                        >
                                                            {t('pet_benefits_desc6')}
                                                        </p>
                                                    </div>
                                                    <div className='row'>
                                                        <p 
                                                            className='p-location' 
                                                            style={{ paddingBottom: '12px' }}
                                                        >
                                                            {t('pet_benefits_desc5')}
                                                        </p>
                                                    </div>
                                                    <div className='row'>
                                                        <p 
                                                            className='p-location' 
                                                            style={{ 
                                                                border: 'none', 
                                                                paddingBottom: '0px', 
                                                                marginBottom: '0px' 
                                                            }}
                                                        >
                                                            {t('pet_benefits_desc6')}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </p>
                                        <p 
                                            className='p-location' 
                                            style={{ borderBottom: 'none' }}
                                        >
                                            <div className='row'>
                                                <div className='col-md-7'>
                                                    <p 
                                                        className='p-death' 
                                                        style={{ height: '60px' }}
                                                    >
                                                        <FaCheckCircle 
                                                            style={{ 
                                                                fontSize: '20px', 
                                                                marginRight: '8px', 
                                                                color: '#20c997' 
                                                            }} 
                                                        />
                                                        {t('pet_benefits_desc7')}
                                                    </p>
                                                    <p 
                                                        className='p-full-damage' 
                                                        style={{ height: '60px' }}
                                                    >
                                                        <FaCheckCircle 
                                                            style={{ 
                                                                fontSize: '20px', 
                                                                marginRight: '8px', 
                                                                color: '#20c997' 
                                                            }} 
                                                        />
                                                        {t('pet_benefits_desc8')}
                                                    </p>
                                                    <p 
                                                        className='p-medical-fee' 
                                                        style={{ height: '90px' }}
                                                    >
                                                        <FaCheckCircle 
                                                            style={{ 
                                                                fontSize: '20px', 
                                                                marginRight: '8px', 
                                                                color: '#20c997' 
                                                            }} 
                                                        />
                                                        {t('pet_benefits_desc9')}
                                                    </p>
                                                    <p 
                                                        className='p-allowance' 
                                                        style={{ 
                                                            height: '40px', 
                                                            paddingBottom: '0px', 
                                                            marginBottom: '0px' 
                                                        }}
                                                    >
                                                        <FaCheckCircle 
                                                            style={{ 
                                                                fontSize: '20px', 
                                                                marginRight: '8px', 
                                                                color: '#20c997' 
                                                            }} 
                                                        />
                                                        {t('pet_benefits_desc10')}
                                                    </p>
                                                    <p
                                                        className='p-allowance'
                                                        style={{
                                                            height: '50px',
                                                            border: 'none',
                                                            paddingBottom: '40px',
                                                            marginBottom: '0px',
                                                            paddingTop: '30px',
                                                            fontWeight: '700',
                                                            fontSize: '20px',
                                                            color: 'rgb(252, 168, 109)'
                                                        }}
                                                    >
                                                        {t('pet_benefits_desc11')}
                                                    </p>
                                                </div>
                                                <div className='col-md-5'>
                                                    <div className='row'>
                                                        <p 
                                                            className='p-location' 
                                                            style={{ height: '60px' }} 
                                                        >
                                                            {t('pet_benefits_desc6')}
                                                        </p>
                                                    </div>
                                                    <div className='row'>
                                                        <p 
                                                            className='p-location' 
                                                            style={{ height: '60px' }}
                                                        >
                                                            {t('pet_benefits_desc6')}
                                                        </p>
                                                    </div>
                                                    <div className='row'>
                                                        <p 
                                                            className='p-location' 
                                                            style={{ height: '90px' }}
                                                        >
                                                            {t('pet_benefits_desc6')}
                                                        </p>
                                                    </div>
                                                    <div className='row'>
                                                        <p 
                                                            className='p-location' 
                                                            style={{ height: '40px' }} 
                                                        >
                                                            {t('pet_benefits_desc6')}
                                                        </p>
                                                    </div>
                                                    <div className='row'>
                                                        <div 
                                                            className='row' 
                                                            style={{ 
                                                                display: 'flex', 
                                                                flexDirection: 'column', 
                                                                justifyContent: 'center', 
                                                                alignItems: 'center', 
                                                                textAlign: 'center', 
                                                                fontWeight: '700', 
                                                                fontSize: '18px', 
                                                                height: '80px', 
                                                                marginTop: '0px', 
                                                                marginBottom: '0px' 
                                                            }}
                                                        >
                                                            <p 
                                                                className='p-location' 
                                                                style={{ color: 'rgb(252, 168, 109)' }}
                                                            >
                                                                {t('pet_benefits_desc12')}
                                                            </p>
                                                        </div>
                                                        <div className='row'>
                                                            <p 
                                                                className='p-location' 
                                                                style={{ 
                                                                    display: 'flex', 
                                                                    flexDirection: 'column', 
                                                                    justifyContent: 'center', 
                                                                    alignItems: 'center', 
                                                                    border: 'none', 
                                                                    textAlign: 'center', 
                                                                    fontWeight: '700', 
                                                                    fontSize: '18px', 
                                                                    marginTop: '-16px', 
                                                                    color: 'rgb(252, 168, 109)' 
                                                                }}
                                                            >
                                                                {t('pet_benefits_desc13')}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className='w-60 px-1'>
                                <Swiper
                                    slidesPerView={3}
                                    spaceBetween={10}
                                    navigation={true}
                                    modules={[Navigation]}
                                    initialSlide={3}
                                    className="mySwiper"
                                >
                                    {listPetRender.map((item, i) => {
                                        return (
                                            <SwiperSlide key={i}>
                                                <div 
                                                    key={i}
                                                    style={{ flex: "1" }}
                                                >
                                                    <div 
                                                        className={"plan-pricing mat-card pet-benefit"} 
                                                        style={{ border: 'none' }}
                                                    >
                                                        <div 
                                                            className='head text-center position-relative'
                                                            style={{ 
                                                                backgroundColor: '#564741', 
                                                                color: 'white' 
                                                            }}
                                                        >
                                                            <label className='d-block'>
                                                                {item.pkg_name}
                                                            </label>
                                                            {
                                                                item.pkg_code === "PETRISK02" && <span class="bestsell">
                                                                    BEST SELL
                                                                </span>
                                                            }
                                                        </div>
                                                        <div className='body'>
                                                            <p 
                                                                className='text-center' 
                                                                rowspan="2" 
                                                                style={{ 
                                                                    height: '49px', 
                                                                    verticalAlign: 'center', 
                                                                    fontSize: '24px' 
                                                                }}
                                                            >
                                                                {item.smi1.list[0].si_amt ? item.smi1.list[0].si_amt : "-"}
                                                            </p>
                                                            <p 
                                                                className='text-center' 
                                                                rowspan="2" 
                                                                style={{ 
                                                                    height: '46px', 
                                                                    verticalAlign: 'center', 
                                                                    fontSize: '24px' 
                                                                }}
                                                            >
                                                                {item.smi1.list[1].si_amt ? item.smi1.list[1].si_amt : "-"}
                                                            </p>
                                                            <p 
                                                                style={{ 
                                                                    height: '47px', 
                                                                    fontSize: '24px' 
                                                                }}
                                                            >
                                                                {item.smi2.list[0].si_amt ? item.smi2.list[0].si_amt : "-"}
                                                            </p>
                                                            <p 
                                                                style={{ 
                                                                    height: '47px', 
                                                                    fontSize: '24px' 
                                                                }}
                                                            >
                                                                {item.smi2.list[1].si_amt ? item.smi2.list[1].si_amt : "-"}
                                                            </p>
                                                            <p 
                                                                style={{ 
                                                                    height: '46px', 
                                                                    fontSize: '24px', 
                                                                    marginTop: '-8px', 
                                                                    marginBottom: '16px' 
                                                                }}
                                                            >
                                                                {item.smi3.list[0].si_amt ? item.smi3.list[0].si_amt : "-"}
                                                            </p>
                                                            <p 
                                                                style={{ 
                                                                    height: '44px', 
                                                                    fontSize: '24px', 
                                                                    marginTop: '-16px', 
                                                                    marginBottom: '16px' 
                                                                }}
                                                            >
                                                                {item.smi3.list[1].si_amt ? item.smi3.list[1].si_amt : "-"}
                                                            </p>
                                                            <p 
                                                                style={{ 
                                                                    height: '52px', 
                                                                    fontSize: '24px' 
                                                                }}
                                                            >
                                                                {item.smi4.list[0].si_amt ? item.smi4.list[0].si_amt : "-"}
                                                            </p>
                                                            <p 
                                                                style={{ 
                                                                    height: '60px', 
                                                                    fontSize: '24px' 
                                                                }}
                                                            >
                                                                {item.smi5.list[0].si_amt ? item.smi5.list[0].si_amt : "-"}
                                                            </p>
                                                            <p 
                                                                style={{ 
                                                                    height: '72px', 
                                                                    fontSize: '24px', 
                                                                    marginTop: '32px', 
                                                                    marginBottom: '16px' 
                                                                }}
                                                            >
                                                                {item.smi6.list[0].si_amt ? item.smi6.list[0].si_amt : "-"}
                                                            </p>
                                                            <p 
                                                                style={{ 
                                                                    height: '44px', 
                                                                    fontSize: '24px', 
                                                                    marginTop: '-8px', 
                                                                    marginBottom: '16px' 
                                                                }}
                                                            >
                                                                {item.smi7.list[0].si_amt ? item.smi7.list[0].si_amt : "-"}
                                                            </p>
                                                            <p
                                                                style={{ 
                                                                    fontSize: '24px', 
                                                                    fontWeight: '600', 
                                                                    color: 'rgb(252, 168, 109)' 
                                                                }}
                                                            >
                                                                {item.premium[0] ? CommonHelper.format_currency_normal(item.premium[0]) : "-"}
                                                            </p>
                                                            <p
                                                                style={{ 
                                                                    border: 'none', 
                                                                    fontSize: '24px', 
                                                                    fontWeight: '600', 
                                                                    color: 'rgb(252, 168, 109)' 
                                                                }}
                                                            >
                                                                {item.premium[1] ? CommonHelper.format_currency_normal(item.premium[1]) : "-"}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </SwiperSlide>
                                        )
                                    })}
                                </Swiper>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default PetBenefits;