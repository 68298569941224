import React from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'react-bootstrap'
import * as FaIcons from 'react-icons/fa'

var lst_occupation_decline = [
    'Diễn viên đóng thế',
    'Xử lý và chế tạo thuốc nổ',
    'Ngư dân',
    'Người đốn gỗ',
    'Người lau cửa kính bên ngoài tòa nhà tại độ cao từ 20 mét trở lên',
    'Người vận hành thiết bị nặng/ máy xúc, đào trong khu vực khai thác gỗ',
    'Thành viên phi hành đoàn/ thủy thủ đoàn',
    'Thành viên quân đội hoặc nhân viên thi hành án',
    'Cảnh sát hình sự, cảnh sát cơ động',
    'Thành viên đội thể thao chuyên nghiệp hoặc giải trí chuyên nghiệp',
    'Thủy thủ, thợ lặn chuyên nghiệp, nhân viên tại giàn khoan dầu',
    'Công nhân khai thác mỏ hoặc sử dụng thuốc nổ',
    'Người đua xe hoặc đua ngựa',
    'Người trình diễn xiếc',
    'Công nhân làm việc tại đường hầm hoặc dưới lòng đất',
    'Nhân viên an ninh/ vệ sĩ (có vũ trang)',
    'Làm bảng quảng cáo trên cao (từ 20 mét trở lên)',
    'Phóng viên vùng chiến tranh',
]

function ModalExclusiveOccupation(props) {
    const { onConfirm, isShow, ...other } = props;

    return (
        <>
            <Modal
                show={isShow}
                onHide={() => { onConfirm(false, false); }}
                size="lg"
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton onClick={() => { onConfirm(false, false); }}>
                    <Modal.Title>Vui lòng xác nhận: Tất cả người được bảo hiểm <span className='text-red'>KHÔNG</span> làm những công việc sau:</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='container'>
                        <div className='row'>
                            {/* <div className='col-sm-12'>
                                <h2 className='fs-3 font-weight'>Người được bảo hiểm (bao gồm tất cả người tham gia bảo hiểm) <span className='text-red'>KHÔNG</span> làm những công việc sau:</h2>
                            </div> */}
                            <div className='col-sm-12 mt-3'>
                                <ol>
                                    {lst_occupation_decline.map((item, i) => {
                                        return (
                                            <li key={i}>{item}</li>
                                        )
                                    })}
                                </ol>
                            </div>
                            <div className='col-sm-12'>
                                <div className='d-flex'>
                                    <div className='w-50 text-center'>
                                        <button type='button' className='btn btn-warning' onClick={() => { onConfirm(false, false); }}>KHÔNG ĐỒNG Ý</button>
                                    </div>
                                    <div className='w-50 text-center'>
                                        <button type='button' className='btn btn-success' onClick={() => { onConfirm(false, true); }}>ĐỒNG Ý</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

ModalExclusiveOccupation.propTypes = {
    isShow: PropTypes.bool.isRequired,
    onConfirm: PropTypes.func.isRequired,
}

export default ModalExclusiveOccupation
