import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import addYears from 'date-fns/addYears';
import addDays from 'date-fns/addDays';
import CommonHelper from '../../helpers/common';
import Loading from '../../components/loading';
import AutoComplete from '../../components/auto-complete';
import TextInput from '../../components/text-input';
import NumberInput from '../../components/number-input';
import DateInput from '../../components/date-input';
import SwitchCheck from '../../components/switch-check';
import Conditions from './component/conditions';
import vehicleApi from '../../api/vehicleApi';

const flagLoading = true;
const initial = {
    veh_pol_no: "",
    veh_certificate_no: "",
    veh_org_sc_code: "MVC",
    veh_sc_code: "MVC",
    veh_business: "N",
    veh_type: "",
    veh_type_name: "",
    veh_reg_month: "",
    veh_reg_year: "",
    veh_seat: "0",
    veh_weight: "0",
    veh_reg_no: "",
    veh_chassis_no: "",
    veh_engine_no: "",
    veh_month: "12",
    veh_fm_dt: null,
    veh_to_dt: null,
    veh_issued_date: null,
    veh_handler_code: "",
    veh_handler_name: "",
    veh_handler_name_vi: "",
    veh_is_pax: false,
    veh_pax: "0",
    veh_customer: "",
    veh_phone: "",
    veh_email: "",
    veh_address: "",
    veh_issue_invoice: false,
    inv_tax: "",
    inv_company_name: "",
    inv_address: "",
    //TNDS BB
    veh_mvc_origin_premium: 0,
    veh_mvc_tax_ratio: 0,
    veh_mvc_tax_amt: 0,
    veh_mvc_premium: 0,
    veh_si_amt1: 0,
    veh_si_amt2: 0,

    veh_pax_deduct: false,
    veh_pax_premium: 0,
    veh_premium: 0,
    veh_discount_amt: 0,
    veh_discount_ratio: 0,
    veh_si_amt: 0,//mvi si amt
    veh_si_amt3: 0,
    veh_si_amt4: 0,//ctpl haman
    veh_si_amt5: 0,//ctpl prop
    veh_si_amt6: 8000000000,
    veh_deduct_amt: 500000,
    veh_is_truck: false,
    veh_url: window.location.href,
    veh_order_no: "",
    veh_ctpl_human_premium: 0,
    veh_ctpl_human_ratio: 0,
    veh_ctpl_prop_premium: 0,
    veh_ctpl_prop_ratio: 0,
    veh_ctpl_tax_ratio: 0,
    veh_ctpl_tax_amt: 0,
    veh_ctpl_premium: 0,
    veh_ctpl_deduct: false,
    veh_mvi_ratio: 0,
    veh_mvi_premium: 0,
    veh_mvi_origin_premium: 0,
    veh_mvi_tax_ratio: 0,
    veh_mvi_tax_amt: 0,
    veh_mvi_con_origin_premium: 0,
    veh_mvi_con_vat_premium: 0,
    veh_mvi_con_total_premium: 0,
    veh_before_dis_amt: 0,
    veh_after_dis_amt: 0,
    veh_tax_ratio: 10,
    veh_tax_amt: 0,
};
const initialCondition = {
    fa_id: '',
    fa_fq_id: '',
    fa_currency: 'VNĐ',
    fa_no: 0,
    fa_ac_id: '',
    fa_addition_vn: '',
    fa_addition_en: '',
    fa_addition_ch: '',
    fa_rate: 0,
    fa_prem: 0,
    fa_prem_tax: 0,
    fa_cr_id: '',
    fa_cr_dt: '',
    fa_upd_id: '',
    fa_upd_dt: '',
    fa_fq_idx: 0,
    fa_rate_orin: 0,
    fa_checked: false,
};
function Quotation() {
    const _title = "Báo giá xe cơ giới";
    const [state, setState] = useState(initial);
    const [showLoading, setShowLoading] = useState(flagLoading);
    const [{ typeOpts, monthOpts, yearOpts, conditionOpts }, setOption] = useState({
        typeOpts: [
            { 'id': 'LXF004', 'name': 'Xe ô tô chở người' },
            { 'id': 'LXF005', 'name': 'Xe ô tô chở hàng' },
            { 'id': 'LXF006', 'name': 'Xe ô tô pickup, minivan' },
            { 'id': 'LXF007', 'name': 'Xe ô tô cứu thương' },
            { 'id': 'LXF008', 'name': 'Xe ô tô chở tiền' },
            { 'id': 'LXF009', 'name': 'Xe ô tô đầu kéo' },
            { 'id': 'LXF010', 'name': 'Xe rơ moóc' },
            { 'id': 'LXF011', 'name': 'Xe ô tô chuyên dùng khác' },
            //{ 'id': 'LXF012', 'name': 'Xe máy chuyên dùng' },
            { 'id': 'LXF013', 'name': 'Xe tập lái' },
            { 'id': 'LXF014', 'name': 'Xe kinh doanh chở người (<10 chỗ)' },
            //{ 'id': 'LXF015', 'name': 'Xe buýt' },
            { 'id': 'LXF016', 'name': 'Xe đông lạnh' },
            { 'id': 'LXF017', 'name': 'Xe bồn chở xăng dầu' },
            //{ 'id': 'LXF018', 'name': 'Xe tải hoạt động trong khu vực khai thác mỏ' },
            //{ 'id': 'LXF019', 'name': 'Các loại xe vận chuyển hàng hóa còn lại' },
            //{ 'id': 'LXF020', 'name': 'Xe hoạt động trong khu vực nội bộ tại Cảng, khu công nghiệp, sân bay' },
            //{ 'id': 'LXF021', 'name': 'Xe khách hoạt động liên tỉnh' },
            //{ 'id': 'LXF022', 'name': 'Xe cho thuê tự lái' },
        ],
        monthOpts: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
        yearOpts: [],
        conditionOpts: [
            { 'id': '100103', 'name': 'Bảo hiểm không tính khấu hao khi thay mới', "idx": 1 },
            { 'id': '100102', 'name': 'Bảo hiểm thiệt hại động cơ do hiện tượng thủy kích', "idx": 2 },
            { 'id': '100101', 'name': 'Bảo hiểm trộm cắp, trộm cướp bộ phận xe ô tô', "idx": 3 },
            { 'id': '100105', 'name': 'Bảo hiểm Thuê xe trong thời gian sửa chữa', "idx": 4 },
            { 'id': '100104', 'name': 'Bảo hiểm lựa chọn cơ sở sửa chữa xe', "idx": 5 },
        ],
    });
    const [conditions, setCondition] = useState([]);
    const [{ vehTypeSel, fm_dt, to_dt, chk_ctpl, chk_md, chk_md_1, chk_md_2, chk_md_3 }, setSelected] = useState({
        vehTypeSel: null,
        fm_dt: null,
        to_dt: null,
        chk_ctpl: false,
        chk_md: false,
        chk_md_1: false,
        chk_md_2: false,
        chk_md_3: false,
    });
    const [stateError, setStateError] = useState({
        veh_type: false,
        veh_reg_no: false,
        veh_seat: false,
        veh_fm_dt: false,
        veh_customer: false,
        veh_phone: false,
        veh_email: false,
        veh_address: false,
    })
    useEffect(() => {
        document.title = "Fubon Insurance - " + _title;
        var _date = new Date();
        var _curr_year = _date.getFullYear();
        var lstYear = [];
        for (var i = _curr_year; i >= _curr_year - 50; i--) {
            lstYear.push(i);
        }
        setOption((prev) => ({
            ...prev,
            yearOpts: lstYear
        }))
        setState((prev) => ({
            ...prev,
            veh_reg_year: _curr_year,
            veh_reg_month: _date.getMonth() + 1
        }));
        /*conditionOpts.map((item) => {
            //console.log(item);
            var con = { ...initialCondition };
            con.fa_ac_id = item.id;
            con.fa_addition_vn = item.name;
            con.fa_no = item.idx;
            setCondition(cur => [...cur, con]);
        })*/

        /*if (dataLoader !== null && dataLoader.list != null) {
            setPolicies(dataLoader.list);
        }*/
        setShowLoading(false);
    }, []);

    useEffect(() => {
        setState((prev) => ({
            ...prev,
            veh_pax: chk_md_2 ? state.veh_seat : 0,
        }))
    }, [chk_md_2])

    useEffect(() => {
        if (chk_md_3) {
            var lst = [];
            conditionOpts.map((item) => {
                //console.log(item);
                var con = { ...initialCondition };
                con.fa_ac_id = item.id;
                con.fa_addition_vn = item.name;
                con.fa_no = item.idx;
                lst.push(con);
            })
            setCondition(lst);
            setState((prev) => ({
                ...prev,
                veh_mvi_con_origin_premium: 0,
                veh_mvi_con_vat_premium: 0,
                veh_mvi_con_total_premium: 0,
            }))
        } else {
            setCondition([]);
        }
    }, [chk_md_3]);

    useEffect(() => {
        var _premium = 0;
        if (state.veh_si_amt3 < 400) {
            _premium = (state.veh_si_amt3 * 0.1 / 100) * 1000000;
        }
        _premium = _premium * state.veh_pax;
        setState((prev) => ({
            ...prev,
            veh_pax_premium: _premium
        }))
    }, [state.veh_si_amt3, state.veh_pax])

    useEffect(() => {
        var _total = 0, _dis_amt = 0, _tax_amt = 0;
        var _dis_ratio = state.veh_discount_ratio * 1;
        if (state.veh_ctpl_deduct) {
            _total += state.veh_ctpl_human_premium + state.veh_ctpl_prop_premium;
        }
        if (state.veh_pax_deduct) {
            _total += state.veh_pax_premium;
        }
        _total += state.veh_mvi_origin_premium + state.veh_mvi_con_origin_premium;
        if (_dis_ratio > 0) {
            _dis_amt = (_total * _dis_ratio) / 100;
        }
        _tax_amt = (_total - _dis_amt) * state.veh_tax_ratio / 100;
        setState((prev) => ({
            ...prev,
            veh_before_dis_amt: _total,
            veh_discount_amt: _dis_amt,
            veh_after_dis_amt: _total - _dis_amt,
            veh_tax_amt: _tax_amt,
            veh_premium: _total - _dis_amt + _tax_amt,
        }))

    }, [state.veh_ctpl_human_premium
        , state.veh_ctpl_prop_premium
        , state.veh_pax_premium
        , state.veh_mvi_origin_premium
        , state.veh_mvi_con_origin_premium
        , state.veh_discount_ratio
        , state.veh_ctpl_deduct
        , state.veh_pax_deduct])

    const handle_change_business = (e) => {
        setState((prev) => ({ ...prev, veh_business: e.target.value }));
    }
    const change_veh_type = (val) => {
        var _type_id = "";
        var _type_name = "";
        setSelected((prev) => ({ ...prev, vehTypeSel: val }));
        /* if (val.length > 0) {
            _type_id = val[0].id;
            _type_name = val[0].name;
            setStateError((prev) => ({ ...prev, veh_type: false }));
            var arr = ["LXF005", "LXF008", "LXF009", "LXF010", "LXF016", "LXF017"];
            state.veh_is_truck = arr.indexOf(val[0].id) >= 0;
        } else {
            setStateError((prev) => ({ ...prev, veh_type: true }));
        }
        setState((prev) => ({
            ...prev,
            veh_type: _type_id,
            veh_type_name: _type_name,
            veh_seat: 0,
            veh_weight: 0,
        }));*/
    };
    const handle_change = (e) => {
        const { name, value } = e.target;
        setState((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };
    const change_fm_dt = (val) => {
        if (val != null) {
            var _to_date = addYears(val, 1);
            setSelected((prev) => ({
                ...prev,
                fm_dt: val,
                to_dt: _to_date,
            }));
        } else {
            setSelected((prev) => ({
                ...prev,
                fm_dt: null,
                to_dt: null,
            }));
        }
    };
    const handle_check = (e) => {
        var { id, checked } = e;
        var lst = [...conditions];
        var _idx = lst.findIndex(x => x.fa_ac_id === id);
        if (_idx >= 0) {
            var _item = lst[_idx];
            _item.fa_checked = checked;
            if (checked) {
                var _req = {
                    "veh_type": id,
                    "from_date": CommonHelper.formatToYYYYMMDD(fm_dt),
                    "year": state.veh_reg_year + "",
                    "month": state.veh_reg_month + "",
                    "si_amt": state.veh_mvi_origin_premium * 1
                };
                var flag = true;
                if (CommonHelper.is_empty(_req.veh_type)) {
                    flag = false;
                }
                if (CommonHelper.is_empty(_req.from_date)) {
                    flag = false;
                }
                if (flag) {
                    setShowLoading(true);
                    var response = vehicleApi.get_mvi_condition_premium(_req);
                    response.then((res) => {
                        setShowLoading(false);
                        if (res != null) {
                            _item.fa_prem = res.premium;
                            _item.fa_rate_orin = state.veh_mvi_ratio;
                            _item.fa_rate = res.ratio;
                            _item.fa_prem_tax = res.tax_amt;
                        }
                        var con_org_premium = 0, con_vat_premium = 0, con_total_premium = 0;
                        lst.forEach((o) => {
                            con_org_premium += o.fa_prem;
                            con_vat_premium += o.fa_prem_tax;
                            console.log(con_org_premium);
                            console.log(con_vat_premium);
                            con_total_premium += o.fa_prem + o.fa_prem_tax;
                        });
                        setState((prev) => ({
                            ...prev,
                            veh_mvi_con_origin_premium: con_org_premium,
                            veh_mvi_con_vat_premium: con_vat_premium,
                            veh_mvi_con_total_premium: con_total_premium,
                        }))
                        setCondition(lst);
                    })
                }
            } else {
                var con_org_premium = 0, con_vat_premium = 0, con_total_premium = 0;
                _item.fa_prem = 0;
                _item.fa_rate_orin = 0;
                _item.fa_rate = 0;
                _item.fa_prem_tax = 0;
                lst.forEach((o) => {
                    con_org_premium += o.fa_prem;
                    con_vat_premium += o.fa_prem_tax;
                    con_total_premium += o.fa_prem + o.fa_prem_tax;
                });
                setState((prev) => ({
                    ...prev,
                    veh_mvi_con_origin_premium: con_org_premium,
                    veh_mvi_con_vat_premium: con_vat_premium,
                    veh_mvi_con_total_premium: con_total_premium,
                }))
                setCondition(lst);
            }
        }
    };
    const get_mvc_premium = (e) => {
        var { id, checked } = e.target;
        setSelected((prev) => ({
            ...prev,
            [id]: checked
        }))
        if (checked) {
            var _req = {
                "veh_usage": state.veh_business,
                "veh_type": (vehTypeSel !== null && vehTypeSel.length) > 0 ? vehTypeSel[0].id : "",
                "from_date": CommonHelper.formatToYYYYMMDD(fm_dt),
                "to_date": CommonHelper.formatToYYYYMMDD(to_dt),
                "sc_code": "MVC",
                "seat": state.veh_seat + "",
                "weight": state.veh_weight + "",
            };
            var flag = true;
            flag = !CommonHelper.is_empty(_req.from_date);
            _req.weight = _req.weight + "";
            _req.seat = _req.seat + "";
            if (flag) {
                setShowLoading(true);
                var response = vehicleApi.get_premium_vehicle(_req);
                response.then((res) => {
                    setShowLoading(false);
                    setState((prev) => ({
                        ...prev,
                        veh_mvc_premium: res.premium,
                        veh_mvc_origin_premium: res.origin_premium,
                        veh_mvc_tax_amt: res.vat,
                        veh_si_amt1: res.si_amt1,
                        veh_si_amt2: res.si_amt2,
                    }))
                })
            }
        } else {
            setState((prev) => ({
                ...prev,
                veh_mvc_premium: 0,
                veh_mvc_origin_premium: 0,
                veh_mvc_tax_amt: 0,
            }))
        }
    };
    const get_mvi_ctplext_premium = () => {
        if (chk_md_1) {
            var _req = {
                "veh_usage": state.veh_business,
                "veh_type": (vehTypeSel !== null && vehTypeSel.length) > 0 ? vehTypeSel[0].id : "",
                "from_date": CommonHelper.formatToYYYYMMDD(fm_dt),
                "seat": state.veh_seat + "",
                "weight": state.veh_weight + "",
                "si_amt": state.veh_si_amt4 * 1000000,
                "si_amt_1": state.veh_si_amt5 * 1000000,
            };
            var flag = true;
            if (CommonHelper.is_empty(_req.veh_type)) {
                flag = false;
            }
            if (CommonHelper.is_empty(_req.from_date)) {
                flag = false;
            }
            if (flag) {
                setShowLoading(true);
                var response = vehicleApi.get_mvi_ctplext_premium(_req);
                response.then((res) => {
                    setShowLoading(false);
                    if (res != null) {
                        setState((prev) => ({
                            ...prev,
                            veh_ctpl_human_premium: res.premium_1,
                            veh_ctpl_human_ratio: res.ratio,
                            veh_ctpl_prop_premium: res.premium_2,
                            veh_ctpl_prop_ratio: res.ratio_2,
                            veh_ctpl_tax_ratio: res.tax_ratio,
                            veh_ctpl_tax_amt: res.tax_amt,
                            veh_ctpl_premium: res.premium,
                        }))
                    }
                })
            }
        }
    };
    const get_mvi_premium = (e) => {
        var { id, checked } = e.target;
        setSelected((prev) => ({
            ...prev,
            [id]: checked
        }))
        if (checked) {
            var _req = {
                "veh_usage": state.veh_business,
                "veh_type": (vehTypeSel !== null && vehTypeSel.length) > 0 ? vehTypeSel[0].id : "",
                "from_date": CommonHelper.formatToYYYYMMDD(fm_dt),
                "seat": state.veh_seat + "",
                "weight": state.veh_weight + "",
                "year": state.veh_reg_year + "",
                "month": state.veh_reg_month + "",
                "si_amt": state.veh_si_amt * 1000000
            };

            var flag = true;
            if (CommonHelper.is_empty(_req.veh_type)) {
                flag = false;
            }
            if (CommonHelper.is_empty(_req.from_date)) {
                flag = false;
            }
            if (flag) {
                setShowLoading(true);
                var response = vehicleApi.get_mvi_premium(_req);
                response.then((res) => {
                    setShowLoading(false);
                    //console.log(res);
                    if (res != null) {
                        setState((prev) => ({
                            ...prev,
                            veh_mvi_premium: res.premium + res.tax_amt,
                            veh_mvi_origin_premium: res.premium,
                            veh_mvi_ratio: res.ratio,
                            veh_mvi_tax_ratio: res.tax_ratio,
                            veh_mvi_tax_amt: res.tax_amt,
                        }))
                    }
                })
            }
        }
    };
    return (
        <div className='container'>
            <Row>
                <Col>
                    <h1>{_title}</h1>
                </Col>
            </Row>
            <Row className="mt15px">
                <div className='col-sm-5'>
                    <Row className='mt10px'>
                        <div className="col-sm-6">
                            <div className="form-check">
                                <input className="form-check-input" type="radio" name="isBusiness" id="radio-1" defaultValue="N"
                                    defaultChecked={state.veh_business === "N"}
                                    onChange={handle_change_business} />
                                <label className="form-check-label" htmlFor="radio-1">
                                    Không kinh doanh vận tải
                                </label>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="form-check">
                                <input className="form-check-input" type="radio" name="isBusiness" id="radio-2" defaultValue="Y"
                                    defaultChecked={state.veh_business === "Y"} onChange={handle_change_business} />
                                <label className="form-check-label" htmlFor="radio-2">
                                    Kinh doanh vận tải
                                </label>
                            </div>
                        </div>
                    </Row>
                </div>
                <div className='col-sm-3'>
                    <Row>
                        <Col>
                            <div className='mt10px'>
                                <label className="form-label required">Tháng ĐKLĐ</label>
                                <select className='form-control form-control-sm' name="veh_reg_month" onChange={handle_change} value={state.veh_reg_month}>
                                    {monthOpts.length > 0 && monthOpts.map((v) => (
                                        <option key={v} value={v}>{v}</option>
                                    ))}
                                </select>
                            </div>
                        </Col>
                        <Col>
                            <div className='mt10px'>
                                <label className="form-label required">Năm ĐKLĐ</label>
                                <select className='form-control form-control-sm' name="veh_reg_year" onChange={handle_change} value={state.veh_reg_year}>
                                    {yearOpts.length > 0 && yearOpts.map((v) => (
                                        <option key={v} value={v}>{v}</option>
                                    ))}
                                </select>
                            </div>
                        </Col>
                    </Row>
                </div>
                <div className='col-sm-4'>
                    <div className="mt10px">
                        <label className="form-label required">Loại xe</label>
                        <AutoComplete
                            id="veh_type"
                            options={typeOpts}
                            onChange={change_veh_type}
                            selected={vehTypeSel}
                            className={stateError.veh_type ? "error" : ""}
                        />
                    </div>
                </div>
            </Row>
            <Row>
                <div className='col-sm-4 mt15px'>
                    <Row>
                        <Col>
                            <label className={"form-label " + (!state.veh_is_truck ? "required" : "")}>Số chỗ</label>
                            <NumberInput
                                name="veh_seat"
                                onChange={handle_change}
                                value={state.veh_seat}
                                className={stateError.veh_seat ? "error" : ""}
                                readOnly={state.veh_is_truck}
                            />
                        </Col>
                        <Col>
                            <label className={"form-label " + (state.veh_is_truck ? "required" : "")}>Trọng tải</label>
                            <div className="input-group input-group-sm">
                                <NumberInput
                                    name="veh_weight"
                                    value={state.veh_weight}
                                    onChange={handle_change}
                                    readOnly={!state.veh_is_truck}
                                />
                                <span className="input-group-text">Tấn</span>
                            </div>
                        </Col>
                    </Row>
                </div>
                <div className='col-sm-4 mt15px'>
                    <label className="form-label required">
                        Hiệu lực bảo hiểm
                    </label>
                    <div>
                        <div className="float-start" style={{ width: "47%" }}>
                            <DateInput
                                selected={fm_dt}
                                minDate={addDays(new Date(), 1)}
                                onChange={change_fm_dt}
                                className={stateError.veh_fm_dt ? "error" : ""}
                            />
                        </div>
                        <div className="float-end" style={{ width: "47%" }}>
                            <DateInput
                                selected={to_dt}
                                minDate={new Date()}
                                disabled
                            />
                        </div>
                        <div className="clearfix"></div>
                    </div>
                </div>
                <div className='col-sm-4 mt15px'>
                    <label className="form-label required">Số tiền bảo hiểm</label>
                    <div className="input-group input-group-sm">
                        <TextInput
                            name="veh_si_amt"
                            onChange={handle_change}
                            className="text-end"
                            value={CommonHelper.format_currency(state.veh_si_amt)}
                        />
                        <span className="input-group-text">,000,000 VNĐ</span>
                    </div>
                </div>
            </Row>
            <Row>
                <div className='col-sm-12 mt15px'>
                    <SwitchCheck id="chk_ctpl" label="I. Bảo hiểm TNDS bắt buộc" checked={chk_ctpl} onChange={get_mvc_premium} />
                </div>
                <div className={chk_ctpl ? "" : "d-none"}>
                    <Row>
                        <div className='col-sm-4 mt15px'>
                            <Row>
                                <Col>
                                    <label className="form-label">Phí bảo hiểm</label>
                                    <div className="input-group input-group-sm">
                                        <TextInput
                                            name="veh_mvc_origin_premium"
                                            onChange={handle_change}
                                            className="text-end"
                                            disabled
                                            value={CommonHelper.format_currency(state.veh_mvc_origin_premium)}
                                        />
                                        <span className="input-group-text">VNĐ</span>
                                    </div>
                                </Col>
                                <Col>
                                    <label className="form-label">VAT (10%)</label>
                                    <div className="input-group input-group-sm">
                                        <TextInput
                                            name="veh_mvc_tax_amt"
                                            onChange={handle_change}
                                            className="text-end"
                                            disabled
                                            value={CommonHelper.format_currency(state.veh_mvc_tax_amt)}
                                        />
                                        <span className="input-group-text">VNĐ</span>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        <div className='col-sm-4 mt15px'>
                            <label className="form-label">Tổng phí BH TNDS bắt buộc</label>
                            <div className="input-group input-group-sm">
                                <TextInput
                                    className="text-end"
                                    onChange={handle_change}
                                    disabled
                                    value={CommonHelper.format_currency(state.veh_mvc_premium)}
                                />
                                <span className="input-group-text">VNĐ</span>
                            </div>
                        </div>
                    </Row>
                    <div className='clearfix'></div>
                </div>
            </Row>
            <Row>
                <div className='col-sm-12 mt15px'>
                    <SwitchCheck id="chk-md" label="II. Bảo hiểm tự nguyện" checked={chk_md} onChange={(e) => { setSelected((prev) => ({ ...prev, chk_md: e.target.checked })) }} />
                </div>
                <div className={chk_md ? "" : "d-none"}>
                    <div className='col-sm-12 mt15px'>
                        <div className='container'>
                            <Row className='mt15px'>
                                <div className='col-sm-12'>
                                    <SwitchCheck id="chk_md_1" label="1. Bảo hiểm trách nhiệm dân sự" checked={chk_md_1} onChange={(e) => { setSelected((prev) => ({ ...prev, chk_md_1: e.target.checked })) }} />
                                </div>
                                <div className={chk_md_1 ? "" : "d-none"}>
                                    <Row>
                                        <div className='col-sm-4 mt15px'>
                                            <Row>
                                                <Col xs={8}>
                                                    <label className="form-label">Thiệt hại về người</label>
                                                    <div className="input-group input-group-sm">
                                                        <TextInput
                                                            name="veh_si_amt4"
                                                            onChange={handle_change}
                                                            className="text-end"
                                                            onBlur={get_mvi_ctplext_premium}
                                                            value={CommonHelper.format_currency(state.veh_si_amt4)}
                                                        />
                                                        <span className="input-group-text">,000,000 VNĐ</span>
                                                        <span className="input-group-text">/người/vụ</span>
                                                    </div>
                                                </Col>
                                                <Col xs={4}>
                                                    <label className="form-label">Tỉ lệ</label>
                                                    <div className="input-group input-group-sm">
                                                        <TextInput
                                                            onChange={() => { }}
                                                            className="text-end"
                                                            disabled
                                                            value={CommonHelper.format_ratio(state.veh_ctpl_human_ratio)}
                                                        />
                                                        <span className="input-group-text">%</span>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                        <div className='col-sm-4 mt15px'>
                                            <Row>
                                                <Col xs={8}>
                                                    <label className="form-label">Thiệt hại về tài sản</label>
                                                    <div className="input-group input-group-sm">
                                                        <TextInput
                                                            name="veh_si_amt5"
                                                            onChange={handle_change}
                                                            className="text-end"
                                                            onBlur={get_mvi_ctplext_premium}
                                                            value={CommonHelper.format_currency(state.veh_si_amt5)}
                                                        />
                                                        <span className="input-group-text">,000,000 VNĐ</span>
                                                        <span className="input-group-text">/ vụ</span>
                                                    </div>
                                                </Col>
                                                <Col xs={4}>
                                                    <label className="form-label">Tỉ lệ</label>
                                                    <div className="input-group input-group-sm">
                                                        <TextInput
                                                            onChange={() => { }}
                                                            className="text-end"
                                                            disabled
                                                            value={CommonHelper.format_ratio(state.veh_ctpl_prop_ratio)}
                                                        />
                                                        <span className="input-group-text">%</span>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                        <div className='col-sm-4 mt15px'>
                                            <Row>
                                                <Col xs={8}>
                                                    <label className="form-label">Mức trách nhiệm tối đa</label>
                                                    <div className="input-group input-group-sm">
                                                        <TextInput
                                                            onChange={() => { }}
                                                            className="text-end"
                                                            disabled
                                                            value={CommonHelper.format_currency(state.veh_si_amt6)}
                                                        />
                                                        <span className="input-group-text">/ vụ</span>
                                                    </div>
                                                </Col>
                                                <Col xs={4}>
                                                    <label className="form-label">VAT</label>
                                                    <div className="input-group input-group-sm">
                                                        <TextInput
                                                            onChange={() => { }}
                                                            className="text-end"
                                                            disabled
                                                            value={CommonHelper.format_ratio(state.veh_ctpl_tax_ratio)}
                                                        />
                                                        <span className="input-group-text">%</span>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Row>
                                    <Row>
                                        <div className='col-sm-4 mt15px'>
                                            <Row>
                                                <Col xs={6}>
                                                    <label className="form-label">Phí gốc</label>
                                                    <div className="input-group input-group-sm">
                                                        <TextInput
                                                            onChange={() => { }}
                                                            className="text-end"
                                                            disabled
                                                            value={CommonHelper.format_currency(state.veh_ctpl_human_premium + state.veh_ctpl_prop_premium)}
                                                        />
                                                        <span className="input-group-text">VNĐ</span>
                                                    </div>
                                                </Col>
                                                <Col xs={6}>
                                                    <label className="form-label">VAT (10%)</label>
                                                    <div className="input-group input-group-sm">
                                                        <TextInput
                                                            onChange={() => { }}
                                                            className="text-end"
                                                            disabled
                                                            value={CommonHelper.format_currency(state.veh_ctpl_tax_amt)}
                                                        />
                                                        <span className="input-group-text">VNĐ</span>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                        <div className='col-sm-4 mt15px'>
                                            <label className="form-label">Phí BH trách nhiệm dân sự</label>
                                            <div className="input-group input-group-sm">
                                                <TextInput
                                                    onChange={() => { }}
                                                    className="text-end"
                                                    disabled
                                                    value={CommonHelper.format_currency(state.veh_ctpl_premium)}
                                                />
                                                <span className="input-group-text">VNĐ</span>
                                            </div>
                                        </div>
                                        <div className='col-sm-4 mt15px'>
                                            <div style={{ marginTop: "37px" }}>
                                                <SwitchCheck id="veh_ctpl_deduct" label="Khấu trừ" checked={state.veh_ctpl_deduct}
                                                    onChange={(e) => { setState((prev) => ({ ...prev, veh_ctpl_deduct: e.target.checked })) }} />
                                            </div>
                                        </div>
                                        <div className='clearfix'></div>
                                    </Row>
                                </div>
                            </Row>
                            <Row className='mt15px'>
                                <div className='col-sm-12'>
                                    <SwitchCheck id="chk_md_2" label="2. Bảo hiểm TN LPX & NNTX" checked={chk_md_2} onChange={(e) => { setSelected((prev) => ({ ...prev, chk_md_2: e.target.checked })) }} />
                                </div>
                                <div className={chk_md_2 ? "" : "d-none"}>
                                    <Row>
                                        <div className='col-sm-4 mt15px'>
                                            <Row>
                                                <Col xs={7}>
                                                    <label className="form-label">Số tiền bảo hiểm</label>
                                                    <div className="input-group input-group-sm">
                                                        <TextInput
                                                            name="veh_si_amt3"
                                                            onChange={handle_change}
                                                            className="text-end"
                                                            value={CommonHelper.format_currency(state.veh_si_amt3)}
                                                        />
                                                        <span className="input-group-text">,000,000 VNĐ</span>
                                                    </div>
                                                </Col>
                                                <Col xs={5}>
                                                    <label className="form-label">Số người</label>
                                                    <div className="input-group input-group-sm">
                                                        <TextInput
                                                            name="veh_pax"
                                                            onChange={handle_change}
                                                            className="text-end"
                                                            value={CommonHelper.format_currency(state.veh_pax)}
                                                        />
                                                        <span className="input-group-text">người</span>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                        <div className='col-sm-4 mt15px'>
                                            <label className="form-label">Tổng phí  TN LPX & NNTX</label>
                                            <div className="input-group input-group-sm">
                                                <TextInput
                                                    name="veh_pax_premium"
                                                    onChange={handle_change}
                                                    className="text-end"
                                                    disabled
                                                    value={CommonHelper.format_currency(state.veh_pax_premium)}
                                                />
                                                <span className="input-group-text">VNĐ</span>
                                            </div>
                                        </div>
                                        <div className='col-sm-4 mt15px'>
                                            <div style={{ marginTop: "37px" }}>
                                                <SwitchCheck id="veh_pax_deduct" label="Khấu trừ" checked={state.veh_pax_deduct}
                                                    onChange={(e) => { setState((prev) => ({ ...prev, veh_pax_deduct: e.target.checked })) }} />
                                            </div>
                                        </div>
                                    </Row>
                                    <div className='clearfix'></div>
                                </div>
                            </Row>
                            <Row className='mt15px'>
                                <div className='col-sm-12'>
                                    <SwitchCheck id="chk_md_3" label="3. Bảo hiểm thiệt hại vật chất xe" checked={chk_md_3} onChange={get_mvi_premium} />
                                </div>
                                <div className={chk_md_3 ? "" : "d-none"}>
                                    <Row>
                                        <div className='col-sm-4 mt15px'>
                                            <Row>
                                                <Col xs={6}>
                                                    <label className="form-label">Tỉ lệ phí BH VCX</label>
                                                    <div className="input-group input-group-sm">
                                                        <TextInput
                                                            name="veh_mvi_ratio"
                                                            onChange={() => { }}
                                                            className="text-end"
                                                            disabled
                                                            value={CommonHelper.format_ratio(state.veh_mvi_ratio)}
                                                        />
                                                        <span className="input-group-text">%</span>
                                                    </div>
                                                </Col>
                                                <Col xs={6}>
                                                    <label className="form-label">Mức khấu trừ</label>
                                                    <div className="input-group input-group-sm">
                                                        <TextInput
                                                            name="veh_deduct_amt"
                                                            onChange={() => { }}
                                                            className="text-end"
                                                            disabled
                                                            value={CommonHelper.format_currency(state.veh_deduct_amt)}
                                                        />
                                                        <span className="input-group-text">VNĐ / vụ</span>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                        <div className='col-sm-4 mt15px'>
                                            <Row>
                                                <Col xs={6}>
                                                    <label className="form-label">Tổng phí gốc</label>
                                                    <div className="input-group input-group-sm">
                                                        <TextInput
                                                            name="veh_pax"
                                                            onChange={() => { }}
                                                            className="text-end"
                                                            disabled
                                                            value={CommonHelper.format_currency(state.veh_mvi_origin_premium)}
                                                        />
                                                        <span className="input-group-text">VNĐ</span>
                                                    </div>
                                                </Col>
                                                <Col xs={6}>
                                                    <label className="form-label">VAT (10%)</label>
                                                    <div className="input-group input-group-sm">
                                                        <TextInput
                                                            name="veh_mvi_tax_amt"
                                                            onChange={() => { }}
                                                            className="text-end"
                                                            disabled
                                                            value={CommonHelper.format_currency(state.veh_mvi_tax_amt)}
                                                        />
                                                        <span className="input-group-text">VNĐ</span>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                        <div className='col-sm-4 mt15px'>
                                            <label className="form-label">Tổng phí BH VCX</label>
                                            <div className="input-group input-group-sm">
                                                <TextInput
                                                    name="veh_pax"
                                                    onChange={() => { }}
                                                    className="text-end"
                                                    disabled
                                                    value={CommonHelper.format_currency(state.veh_mvi_premium)}
                                                />
                                                <span className="input-group-text">VNĐ</span>
                                            </div>
                                        </div>
                                        <div className='clearfix'></div>
                                    </Row>
                                    <Row>
                                        <div className='col-sm-12 mt15px'>
                                            <label className='form-label'>Điều khoản bổ sung</label>
                                        </div>
                                        <div className='col-sm-12'>
                                            {conditions != null && conditions.length > 0 && (
                                                <Conditions data={conditions} onChange={handle_check} />
                                            )}
                                        </div>
                                        <div className='col-sm-12'>
                                            <div className='container'>
                                                <Row className='mt15px' style={{ borderTop: "1px solid #ccc", paddingTop: "15px" }}>
                                                    <div className='col-sm-4'>
                                                        <label className='form-label'>Phạm vi bảo hiểm mở rộng</label>
                                                    </div>
                                                    <div className='col-sm-4'>
                                                        <Row>
                                                            <Col>
                                                                <label className="form-label">Phí</label>
                                                                <div className="input-group input-group-sm">
                                                                    <TextInput
                                                                        className="text-end"
                                                                        onChange={() => { }}
                                                                        disabled
                                                                        value={CommonHelper.format_currency(state.veh_mvi_con_origin_premium)}
                                                                    />
                                                                    <span className="input-group-text">VNĐ</span>
                                                                </div>
                                                            </Col>
                                                            <Col>
                                                                <label className="form-label">VAT (10%)</label>
                                                                <div className="input-group input-group-sm">
                                                                    <TextInput
                                                                        className="text-end"
                                                                        onChange={() => { }}
                                                                        disabled
                                                                        value={CommonHelper.format_currency(state.veh_mvi_con_vat_premium)}
                                                                    />
                                                                    <span className="input-group-text">VNĐ</span>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                    <div className='col-sm-4'>
                                                        <label className="form-label">Tổng phí BH mở rộng</label>
                                                        <div className="input-group input-group-sm">
                                                            <TextInput
                                                                className="text-end"
                                                                onChange={() => { }}
                                                                disabled
                                                                value={CommonHelper.format_currency(state.veh_mvi_con_total_premium)}
                                                            />
                                                            <span className="input-group-text">VNĐ</span>
                                                        </div>
                                                    </div>
                                                </Row>
                                            </div>
                                        </div>
                                    </Row>
                                </div>
                            </Row>
                        </div>
                    </div>

                </div>
            </Row>
            <Row>
                <div className='col-sm-12'>
                    <div className='container'>
                        <Row>
                            <div className='col-sm-9 mt15px'>
                                <label className="form-label">Tổng phí BH tự nguyện (chưa thuế)</label>
                            </div>
                            <div className='col-sm-3 mt15px'>
                                <div className="input-group input-group-sm">
                                    <TextInput
                                        className="text-end"
                                        onChange={() => { }}
                                        disabled
                                        value={CommonHelper.format_currency(state.veh_before_dis_amt)}
                                    />
                                    <span className="input-group-text"> VNĐ</span>
                                </div>
                            </div>
                        </Row>
                        <Row>
                            <div className='col-sm-6 mt15px'>
                                <label className="form-label">Đề xuất giảm phí (Không bao gồm BH TNDS bắt buộc)</label>
                            </div>
                            <div className='col-sm-3 mt15px'>
                                <div className="input-group input-group-sm">
                                    <TextInput
                                        name="veh_discount_ratio"
                                        className="text-end"
                                        onChange={handle_change}
                                        value={CommonHelper.format_ratio(state.veh_discount_ratio)}
                                    />
                                    <span className="input-group-text"> %</span>
                                </div>
                            </div>
                            <div className='col-sm-3 mt15px'>
                                <div className="input-group input-group-sm">
                                    <TextInput
                                        className="text-end"
                                        onChange={() => { }}
                                        disabled
                                        value={CommonHelper.format_currency(state.veh_discount_amt)}
                                    />
                                    <span className="input-group-text"> VNĐ</span>
                                </div>
                            </div>
                        </Row>
                        <Row>
                            <div className='col-sm-9 mt15px'>
                                <label className="form-label">Phí BH còn lại</label>
                            </div>
                            <div className='col-sm-3 mt15px'>
                                <div className="input-group input-group-sm">
                                    <TextInput
                                        className="text-end"
                                        onChange={() => { }}
                                        disabled
                                        value={CommonHelper.format_currency(state.veh_after_dis_amt)}
                                    />
                                    <span className="input-group-text"> VNĐ</span>
                                </div>
                            </div>
                        </Row>
                        <Row>
                            <div className='col-sm-6 mt15px'>
                                <label className="form-label">Tổng phí BH tự nguyện (bao gồm thuế)</label>
                            </div>
                            <div className='col-sm-3 mt15px'>
                                <div className="input-group input-group-sm">
                                    <TextInput
                                        className="text-end"
                                        onChange={() => { }}
                                        disabled
                                        value={CommonHelper.format_currency(state.veh_tax_amt)}
                                    />
                                    <span className="input-group-text"> VNĐ</span>
                                </div>
                            </div>
                            <div className='col-sm-3 mt15px'>
                                <div className="input-group input-group-sm">
                                    <TextInput
                                        className="text-end"
                                        onChange={() => { }}
                                        disabled
                                        value={CommonHelper.format_currency(state.veh_premium)}
                                    />
                                    <span className="input-group-text"> VNĐ</span>
                                </div>
                            </div>
                        </Row>
                    </div>
                </div>
            </Row>
            {showLoading && <Loading />}
        </div>
    )
}

export default Quotation