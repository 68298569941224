import React, { useState, useEffect } from 'react'
import { redirect, useLoaderData } from 'react-router-dom';
import Authentication from '../../../api/authApi';
import vehicleApi from '../../../api/vehicleApi';
import Loading from '../../../components/loading';
import CommonHelper from '../../../helpers/common';
import { FaCheck, FaTimes } from "react-icons/fa";
import { Spinner } from 'react-bootstrap'

const flagLoading = true;

export async function loader({ params }) {
    if (params.id !== undefined && CommonHelper.is_not_empty(params.id)) {
        var data = await vehicleApi.get_payment_check_status(params.id);
        if (data != null) {
            return { data, order_no: params.id };
        }
    }
    return null;
}

const initial = {
    veh_pol_no: "",
    veh_order_no: "",
    veh_certificate_no: "",
    veh_org_sc_code: "MBC",
    veh_sc_code: "MBC",
    veh_business: "N",
    veh_type: "",
    veh_type_name: "",
    veh_seat: "2",
    veh_weight: "0",
    veh_reg_no: "",
    veh_chassis_no: "",
    veh_engine_no: "",
    veh_month: "1",
    veh_fm_dt: null,
    veh_to_dt: null,
    veh_issued_date: null,
    veh_handler_code: "",
    veh_handler_name: "",
    veh_handler_name_vi: "",
    veh_is_pax: false,
    veh_pax: "0",
    veh_customer_id: 0,
    veh_customer: "",
    veh_customer_idcard: "",
    veh_phone: "",
    veh_email: "",
    veh_address: "",
    veh_issue_invoice: false,
    inv_tax: "",
    inv_company_name: "",
    inv_address: "",
    veh_origin_premium: 0,
    veh_tax_ratio: 0,
    veh_tax_amt: 0,
    veh_pax_premium: 0,
    veh_premium: 0,
    veh_si_amt1: 0,
    veh_si_amt2: 0,
    veh_si_amt3: 0,
    veh_si_amt4: 0,
    veh_is_truck: false,
    veh_url: window.location.href,
    veh_order_no: "",
    veh_get_cert_via: 1,
    veh_sob_code: "",
    veh_sob_name: "",
    veh_agency_code: "",
    veh_agency_name: "",
    veh_broker_code: "",
    veh_broker_name: "",
    cert_url: ""
};
function TransactionSuccess() {
    const [state, setState] = useState(initial);
    const _title = "Giao dịch thành công";
    const policy = useLoaderData();
    const [showLoading, setShowLoading] = useState(flagLoading);
    const [{ flag_success }, setFlag] = useState({
        flag_success: false
    });

    useEffect(() => {
        document.title = "Fubon Insurance - " + _title;
        if (policy !== null && policy.data !== null) {
            set_data(policy.data, policy.order_no);
            //setShowLoading(false);
        }

        setTimeout(
            function () {
                setShowLoading(false);
            },
            3000
        );
    }, []);
    const set_data = (data, order_no) => {
        //console.log(data);
        var _order_detail = data.data;
        if (_order_detail != null) {
            _order_detail["cert_url"] = data.data.cert_url;
            setState(_order_detail);
            setFlag((prev) => ({ ...prev, flag_success: true }));
        } else {
            var _back_url = "";
            if (CommonHelper.is_not_empty(order_no)) {
                var _sc_code = order_no.substr(0, 3);
                switch (_sc_code) {
                    case "MVC":
                        _back_url = "/sale/ctpl-vehicle/" + order_no;
                        break;
                    case "MBC":
                        _back_url = "/sale/ctpl-moto/" + order_no;
                        break;
                };
            }
            setState((prev) => ({
                ...prev,
                cert_url: _back_url
            }))
        }
    }

    return (
        <>
            <div className='row'>
                <div className='col-sm-3'></div>
                <div className='col-sm-6'>
                    <div className='container'>
                        {showLoading && (
                            <div className='row' style={{ height: "400px" }}>
                                <div className='col-sm-12 text-center' style={{ color: "#2196F3" }}>
                                    <Spinner />
                                    <h2 style={{ color: "#2196F3" }}>Giao dịch đang xử lý</h2>
                                </div>
                            </div>
                        )}
                        {flag_success && !showLoading && (
                            <div className='row'>
                                <div className='col-sm-12 text-center'>
                                    <label className='form-label' style={{ fontSize: "50px", color: "#4caf50" }}>
                                        <FaCheck />
                                    </label>
                                    <h2 style={{ color: "#4caf50" }}>Giao dịch thành công</h2>
                                </div>
                                <div className='trans-detail'>
                                    <p>Cảm ơn quý khách hàng đã tin tưởng và mua hàng tại <b>Công ty TNHH Bảo hiểm Fubon (Việt Nam)</b></p>
                                    <p className='mt15px'>
                                        <label className='form-label col-sm-5'>Ngày, giờ giao dịch:</label>
                                        <label className='form-label col-sm-7'>{CommonHelper.convertToDDMMYYYYHHMM(state.veh_issued_date)}</label>
                                    </p>
                                    <p>
                                        <label className='form-label col-sm-5'>Mã đơn hàng:</label>
                                        <label className='form-label col-sm-7'>{state.veh_order_no}</label>
                                    </p>
                                    <p>
                                        <label className='form-label col-sm-5'>Số giấy chứng nhận:</label>
                                        <label className='form-label col-sm-7'>{state.veh_certificate_no}</label>
                                    </p>
                                    <p>
                                        <label className='form-label col-sm-5'>Phí bảo hiểm đã thanh toán:</label>
                                        <label className='form-label col-sm-7'>{CommonHelper.format_currency_vnd_style(state.veh_premium)}</label>
                                    </p>
                                    <p>
                                        <label className='form-label col-sm-5'>Chứng nhận bảo hiểm:</label>
                                        <label className='form-label col-sm-7'><a href={state.cert_url} target="_blank">Link</a></label>
                                    </p>
                                </div>
                            </div>
                        )}
                        {!flag_success && !showLoading && (
                            <div className='row'>
                                <div className='col-sm-12 text-center' style={{ height: "400px" }}>
                                    <label className='form-label' style={{ fontSize: "50px", color: "#F44336" }}>
                                        <FaTimes />
                                    </label>
                                    <h2 style={{ color: "#F44336" }}>Giao dịch thất bại</h2>
                                    <div className='trans-detail mt15px'>
                                        <p><label className='form-label col-sm-7'><a href={state.cert_url}>Quay về trang mua hàng</a></label></p>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <div className='col-sm-3'></div>
            </div>
        </>

    )
}

export default TransactionSuccess