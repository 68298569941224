import React, { useState, useEffect } from 'react'
import { Row, Col } from 'react-bootstrap';
import { redirect, useLoaderData } from 'react-router-dom';
import CommonHelper from '../../helpers/common';
import Loading from '../../components/loading';
import vehicleApi from '../../api/vehicleApi';

const flagLoading = true;

export async function loader() {
  var data = await vehicleApi.get_order_by_customer();
  if (data.code === 200) {
    return { list: data.data };
  } else if (data.code === 506) {
    window.location.href = "/";
  }
  return { list: null };
}

function Policy() {
  const _title = "Danh sách hợp đồng";
  const dataLoader = useLoaderData();
  const [showLoading, setShowLoading] = useState(flagLoading);
  const [policies, setPolicies] = useState(null);

  useEffect(() => {
    document.title = "Fubon Insurance - " + _title;
    if (dataLoader !== null && dataLoader.list != null) {
      setPolicies(dataLoader.list);
    }
    setShowLoading(false);
  }, []);
  return (
    <div className='container'>
      <Row>
        <Col>
          <h1>{_title}</h1>
        </Col>
      </Row>
      <div className='row mt15px'>
        <div className='col-sm-12' style={{ minHeight: "500px" }}>
          <table className="table table-bordered table-striped table-responsive-sm">
            <thead>
              <tr>
                <th scope="col" style={{ width: "8%" }}>#</th>
                <th scope="col" style={{ width: "10%" }}>Số đơn BH</th>
                <th scope="col" style={{ width: "20%" }}>Tên khách hàng</th>
                <th scope="col" style={{ width: "10%" }}>Hiệu lực</th>
                <th scope="col" style={{ width: "10%" }}>Trạng thái</th>
                <th scope="col">Giấy chứng nhận</th>
              </tr>
            </thead>
            {(policies != null && policies.length > 0) && (
              <tbody>
                {policies.map((item, i) => (
                  <tr key={i}>
                    <th scope='row'>{i + 1}</th>
                    <td ><a target="_blank" href={item.href}> {item.order_no}</a></td>
                    <td >{item.customer_name}</td>
                    <td >{CommonHelper.convertToMMDDYYYY(item.from_date) + " - "
                      + CommonHelper.convertToMMDDYYYY(item.to_date)}</td>
                    <td >{item.is_paid ? "Đã thanh toán" : ""}</td>
                    <td >{CommonHelper.is_not_empty(item.cert_url) && (
                      <a href={item.cert_url} target="_blank">Giấy chứng nhận</a>
                    )}
                    </td>
                  </tr>
                ))}

              </tbody>
            )}

          </table>
        </div>
      </div>
      {showLoading && <Loading />}
    </div>
  )
}

export default Policy