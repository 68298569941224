import React from "react";
import { Navbar, Container, Nav, Dropdown, Button } from "react-bootstrap";
import { Link } from "react-router-dom";

function Navigation(props) {
  const { onLogout, data, ...other } = props;
  // console.log("props", props)
  const log_out = () => {
    onLogout();
  }
  return (
    <Navbar bg="light" expand="lg">
      <Container fluid>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto" navbar>
            <Nav.Item>
              <Nav.Link
                className="m-0"
                href="#pablo"
                onClick={(e) => e.preventDefault()}
              >
                <span className="no-icon">{"Xin chào, " + (data.title ? data.title : "")}</span>
              </Nav.Link>
            </Nav.Item>
            <Dropdown as={Nav.Item}>
              <Dropdown.Toggle
                aria-expanded={false}
                aria-haspopup={true}
                as={Nav.Link}
                data-toggle="dropdown"
                id="navbarDropdownMenuLink"
                variant="default"
                className="m-0"
              >
                <span className="no-icon">Cài đặt</span>
              </Dropdown.Toggle>
              <Dropdown.Menu aria-labelledby="navbarDropdownMenuLink">
                <Dropdown.Item >
                  Thông tin tài khoản
                </Dropdown.Item>
                <Dropdown.Item>
                  <Link style={{textDecoration: 'none', color: 'black'}} to="/cms/doi-mat-khau">Thay đổi mật khẩu</Link>
                </Dropdown.Item>
                <div className="divider"></div>
                <Dropdown.Item onClick={log_out} >
                  Đăng xuất
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Navigation;
