import React, { useState, useEffect } from 'react'
import { useLocation, NavLink, Link } from "react-router-dom";
import { Nav } from "react-bootstrap";
import fbBanner from "../../../assets/images/logo.svg"
import { RiQuestionAnswerFill } from "react-icons/ri";
import { FaClipboardList } from "react-icons/fa";
import { MdAccountCircle } from "react-icons/md";
import { FaHistory } from "react-icons/fa";
import { FaQrcode } from "react-icons/fa";
import { BsQrCode } from "react-icons/bs";
import { MdOutlinePassword } from "react-icons/md";
import { FaComment } from "react-icons/fa";

const sidebarRoutes = [
    {
        path: "/cms/tao-cau-hoi",
        name: "Tạo câu hỏi",
        icon: <RiQuestionAnswerFill />,
        layout: "/cms"
    },
    {
        path: "/cms/danh-sach-cau-hoi",
        name: "Danh sách Câu hỏi",
        icon: <FaClipboardList />,
        layout: "/cms"
    },
    {
        path: "/cms/chu-de-cau-hoi",
        name: "Chủ đề câu hỏi",
        icon: <FaClipboardList />,
        layout: "/cms"
    },
    {
        path: "/cms/tai-khoan-thi",
        name: "Tài khoản thi",
        icon: <MdAccountCircle />,
        layout: "/cms"
    },
    {
        path: "/cms/lich-su-thi",
        name: "Lịch sử thi",
        icon: <FaHistory />,
        layout: "/cms"
    },
    {
        path: "/cms/dang-ky-thi-dai-ly",
        name: "Form Đăng ký thi Chứng chỉ ĐLBH - Hỗ trợ mở TK",
        icon: <MdAccountCircle />,
        layout: "/cms"
    },
    {
        path: "/cms/danh-sach-dang-ky-thi-chung-chi",
        name: "Danh sách ĐK thi Chứng chỉ ĐLBH",
        icon: <MdAccountCircle />,
        layout: "/cms"
    },
    {
        path: "/cms/danh-gia-dao-tao",
        name: "Góp ý từ học viên",
        icon: <FaComment />,
        layout: "/cms"
    },
    {
        path: "/cms/danh-sach-qr",
        name: "Danh sách QR code",
        icon: <FaQrcode />,
        layout: "/cms"
    },
    {
        path: "/cms/qr",
        name: "Lấy QR code",
        icon: <BsQrCode />,
        layout: "/cms"
    },
    {
        path: "/cms/doi-mat-khau",
        name: "Đổi mật khẩu",
        icon: <MdOutlinePassword />,
        layout: "/cms"
    },
];

function Sidebar() {
    const location = useLocation();
    const [{ active }, setActive] = useState({
        active: "",
    })
    useEffect(() => {
        setActive((prev) => ({ ...prev, active: location.pathname }))
    }, [location])

    return (
        <div className="sidebar">
            {/* <div className="sidebar-background" /> */}
            <div className="sidebar-wrapper bg-white">
                <div className="logo d-flex align-items-center justify-content-start bg-white">
                    <a href="/" className="simple-text logo-mini mx-1">
                        <img src={fbBanner} />
                    </a>
                </div>
                <div className="mt-1">
                    <ul className="list-group list-group-flush">
                        {sidebarRoutes.map((prop, key) => {
                            if (!prop.redirect)
                                return (
                                    <li className={"list-group-item " + (active === prop.path ? "active" : "")} key={key} >
                                        <a href={prop.path} className="nav-link fw-bolder" activeClassName="active">
                                            {prop.icon} {prop.name}
                                        </a>
                                    </li>
                                );
                            return null;
                        })}
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default Sidebar