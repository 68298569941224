import React, { useState, useEffect } from 'react';
import { Modal, Button, Table } from 'react-bootstrap';
import { FaSortUp, FaSortDown, FaMedal } from "react-icons/fa";
import trainingApi from "../../../../api/trainingApi";
import AlertBox from "../../../../components/alert";
import CommonHelper from "../../../../helpers/common";
import { FaCheckCircle } from "react-icons/fa";

const initialAlert = {
    is_show: false,
    title: "",
    content: "",
    variant: "danger"
};

const PersonalHistory = ({ identity, isOpen, onClose }) => {
    const [histories, setHistories] = useState([]);
    const [historiesDetail, setHistoriesDetail] = useState([]);
    const [alertData, setAlertData] = useState(initialAlert);
    const [status, setStatus] = useState(false);
    const [historyParams, setHistoryParams] = useState(identity);
    const [sortOrder, setSortOrder] = useState("default");
    const [show, setShow] = useState(isOpen);
    const [filter, setFilter] = useState("all");

    useEffect(() => {
        setShow(isOpen);
    }, [isOpen]);

    const handleClose = () => {
        setShow(false);
        onClose();
    };

    useEffect(() => {
        fetchQuestions();
    }, []);

    const fetchQuestions = async () => {
        try {
            const response = await trainingApi.get_personal_history_exam(historyParams);
            const responseDetail = await trainingApi.get_personal_history_exam_detail(historyParams);
            if (response.code === 200 && responseDetail.code === 200) {
                setHistories(response.data);
                setHistoriesDetail(responseDetail.data);
            } else {
                setAlertData((prev) => ({
                    ...prev,
                    title: "Lỗi",
                    content: "Tải dữ liệu lịch sử thi không thành công. ",
                    is_show: true,
                    variant: "danger"
                }));
            }
        } catch (error) {
            setAlertData((prev) => ({
                ...prev,
                title: "Lỗi",
                content: "Oops... Đã có lỗi xảy ra, vui lòng kiểm tra lại!",
                is_show: true,
                variant: "danger"
            }));
            console.log(error);
        }
    };

    const handleSort = () => {
        const sortedHistories = [...historiesDetail].sort((a, b) => {
            if (sortOrder === "default") {
                if (b.score === a.score) {
                    const durationA = new Date(a.endDate) - new Date(a.beginDate);
                    const durationB = new Date(b.endDate) - new Date(b.beginDate);
                    return durationA - durationB;
                }
                return b.score - a.score;
            } else {
                const dateA = new Date(a.beginDate);
                const dateB = new Date(b.beginDate);
                return dateB - dateA;
            }
        });
        setHistoriesDetail(sortedHistories);
        setSortOrder(sortOrder === "default" ? "toprank" : "default");
    };
    

    const renderScore = (score, index) => {
        let icon = null;
        if (sortOrder === "toprank") {
            if (index === 0) icon = <FaMedal style={{ color: 'gold', marginLeft: '5px', fontSize: '30px' }} />;
            if (index === 1) icon = <FaMedal style={{ color: 'silver', marginLeft: '5px', fontSize: '30px' }} />;
            if (index === 2) icon = <FaMedal style={{ color: 'bronze', marginLeft: '5px', fontSize: '30px' }} />;
        }
        return <>{score}{icon}</>;
    };

    const filteredHistoriesDetail = historiesDetail.filter(history => {
        if (filter === "all") return true;
        if (filter === "pass") return (history.score / history.quesTotal * 100) >= 75;
        if (filter === "fail") return (history.score / history.quesTotal * 100) < 75;
    });

    const renderFilterButtons = () => (
        <div className="filter-buttons">
            <button className={`btn ${filter === 'all' ? 'btn-primary' : 'btn-secondary'}`} onClick={() => setFilter('all')}>Tất cả</button>
            <button className={`btn ${filter === 'pass' ? 'btn-primary' : 'btn-secondary'}`} onClick={() => setFilter('pass')}>Đạt</button>
            <button className={`btn ${filter === 'fail' ? 'btn-primary' : 'btn-secondary'}`} onClick={() => setFilter('fail')}>Không đạt</button>
        </div>
    );

    return (
        <>
            <Modal show={show} onHide={handleClose} size="xl">
                <Modal.Header closeButton>
                    <Modal.Title>Xem lịch sử thi đại lý</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ maxHeight: '70vh', overflowY: 'auto' }}>
                    <>
                    {
                        histories && historiesDetail ? (
                            <div className="container" >
                            <div className="row row-frm">
                                <div className="row">
                                    <div className="col col-md-4">
                                        {
                                            !status ? <button className="btn btn-success" onClick={() => setStatus(!status)}>Xem tổng quan</button> :
                                                <button className="btn btn-primary" onClick={() => setStatus(!status)}>Xem chi tiết</button>
                                        }
                                    </div>
                                </div>
                                <h1 className="text-center">Lịch sử thi đại lý FUBON</h1>
                                <div className="table-container" style={{ overflowY: 'auto', maxHeight: '70vh' }}>
                                    <table className="table table-striped table-bordered table-hover">
                                        {
                                            !status ? (
                                                <>
                                                    <thead className="center-vertically" style={{ backgroundColor: '#3498db', position: 'sticky', top: '-2px' }}>
                                                        <tr style={{ verticalAlign: 'middle', color: 'white' }}>
                                                            <th style={{ width: '5%' }}>#</th>
                                                            <th style={{ width: '10%' }}>Tên</th>
                                                            <th style={{ width: '10%' }}>Số CCCD</th>
                                                            <th style={{ width: '10%' }} onClick={handleSort}>
                                                                Số điểm {sortOrder === "default"
                                                                    ? <FaSortUp style={{ width: '30px', height: '30px', cursor: 'pointer' }} />
                                                                    : <FaSortDown style={{ width: '30px', height: '30px', cursor: 'pointer' }} />}
                                                            </th>
                                                            <td style={{ width: '15%', fontWeight: '700' }}>Đánh giá</td>
                                                            <th style={{ width: '10%' }}>Chủ đề thi</th>
                                                            <th style={{ width: '10%' }}>Thời gian bắt đầu</th>
                                                            <th style={{ width: '10%' }}>Thời gian kết thúc</th>
                                                            <th style={{ width: '10%' }}>Thời gian đã thi</th>
                                                            <th style={{ width: '10%' }}>KQ</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {historiesDetail.length === 0 ? (
                                                            <tr>
                                                                <td colSpan="5" style={{ textAlign: 'center' }}>Không có dữ liệu phù hợp</td>
                                                            </tr>
                                                        ) :
                                                            (
                                                                historiesDetail.map((history, index) => (
                                                                    <>
                                                                        <tr key={history.id} style={{ verticalAlign: 'middle', textAlign: 'center' }}>
                                                                            <td>{history.id}</td>
                                                                            <td style={{ textAlign: 'center', fontWeight: '500' }}>{history.name} (Thi lần {history.numberOfTests})</td>
                                                                            <td>{identity}</td>
                                                                            <td>
                                                                                {history.score ? renderScore(history.score, index, history.quesTotal) : "Không có điểm"}
                                                                            </td>
                                                                            <td>
                                                                                {history.score ? (
                                                                                    history.quesTotal ? (
                                                                                        `${history.score}/${history.quesTotal} Câu, Được ${(history.score / history.quesTotal * 100).toFixed()}%`
                                                                                    ) : (
                                                                                        <p style={{ fontStyle: 'italic' }}>Chưa có thông tin</p>
                                                                                    )
                                                                                ) : (
                                                                                    "Không có điểm"
                                                                                )}
                                                                            </td>
                                                                            <td>
                                                                                {history.topicName ? history.topicName : <p style={{ fontStyle: 'italic' }}>Tất cả</p>}
                                                                            </td>
                                                                            <td>{history.beginDate ? CommonHelper.formatDateExam(history.beginDate) : ""}</td>
                                                                            <td>{history.endDate ? CommonHelper.formatDateExam(history.endDate) : ""}</td>
                                                                            <td>
                                                                                {history.beginDate && history.endDate ? (
                                                                                    (() => {
                                                                                        const beginDate = new Date(history.beginDate);
                                                                                        const endDate = new Date(history.endDate);
                                                                                        const diffInMs = endDate - beginDate;
                                                                                        const diffInMinutes = Math.floor(diffInMs / 1000 / 60);
                                                                                        return diffInMinutes + " phút";
                                                                                    })()
                                                                                ) : ""}
                                                                            </td>
                                                                            <td style={{ 
                                                                                backgroundColor: history.score / history.quesTotal * 100 >= 75 ? 'lightgreen' : '',
                                                                                fontWeight: history.score / history.quesTotal * 100 >= 75 ? 'bold' : '',
                                                                                color: history.score / history.quesTotal * 100 >= 75 ? 'green' : '' 
                                                                            }}>
                                                                                {history.score / history.quesTotal * 100 >= 75 ? <FaCheckCircle style={{ marginRight: '5px' }} /> : ''}
                                                                                {history.score / history.quesTotal * 100 >= 75 ? 'Đạt' : 'Không đạt'}
                                                                            </td>
                                                                        </tr>
                                                                    </>
                                                                ))
                                                            )
                                                        }
                                                    </tbody>
                                                </>
                                            ) : (
                                                <>
                                                    <thead className="center-vertically" style={{ backgroundColor: '#3498db', position: 'sticky', top: '-2px' }}>
                                                        <tr style={{ verticalAlign: 'middle', color: 'white' }}>
                                                            <th style={{ width: '20%' }}>Tài khoản người thi</th>
                                                            <th style={{ width: '7%' }}>Số lần thi</th>
                                                            <th style={{ width: '53%' }}>Số điểm các lần thi</th>
                                                            <th style={{ width: '20%' }}>Tổng thời gian thi (phút)</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {histories.length === 0 ? (
                                                            <tr>
                                                                <td colSpan="4" style={{ textAlign: 'center' }}>Không có dữ liệu phù hợp</td>
                                                            </tr>
                                                        ) :
                                                            (
                                                                histories.map((history, index) => (
                                                                    <>
                                                                        <tr key={index} style={{ verticalAlign: 'middle', textAlign: 'center' }}>
                                                                            <td style={{ textAlign: 'center', fontWeight: '500' }}>{history.name}</td>
                                                                            <td>
                                                                                {history.numberOfTests
                                                                                    || history.numberOfTests === "0" ? history.numberOfTests
                                                                                    : "Chưa thi lần nào"}
                                                                            </td>
                                                                            <td>
                                                                                {history.score
                                                                                    ? history.score.split(', ').map((score, index) => `Lần ${index + 1}: ${score}đ`).join("; ")
                                                                                    : "Không có điểm"}
                                                                            </td>
                                                                            <td >{history.totalTime ? history.totalTime : "0"}</td>
                                                                        </tr>
                                                                    </>
                                                                ))
                                                            )
                                                        }
                                                    </tbody>
                                                </>
                                            )
                                        }
                                    </table>
                                </div>
                            </div>
                            <AlertBox data={alertData} onChange={(e) => { setAlertData((prev) => ({ ...prev, is_show: e })) }} />
                        </div>
                        ) : (
                            <p>Chưa có bài thì nào hết!!!</p>
                        )
                    }           
                    </>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default PersonalHistory;