/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
// import "./index.css";
import { Link } from "react-router-dom";
import trainingApi from "../../../../../api/trainingApi";
import { Modal } from "react-bootstrap";
// import FormBuilder from "../form/add-form";
import AlertBox from "../../../../../components/alert";
import { Container, Row, Col, Button } from "react-bootstrap";
// import * as FaIcon from "react-icons/fa";
// import fbBanner from "../../../../assets/images/logo.svg";
import { IoMdMale } from "react-icons/io";
import { IoMdFemale } from "react-icons/io";
import CommonHelper from "../../../../../helpers/common";
import EditAgent from "./edit-agent-reg-info";

const initialAlert = {
    is_show: false,
    title: "",
    content: "",
    variant: "danger"
};

const AgentRegInfoList = () => {
    const [agents, setAgents] = useState([]);
    // const [showModal, setShowModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [deleteAgentId, setDeleteAgentId] = useState(null);
    const [alertData, setAlertData] = useState(initialAlert);
    const [searchKeyword, setSearchKeyword] = useState("");

    const [showEditModal, setShowEditModal] = useState(false);
    const [selectedAgent, setSelectedAgent] = useState(null);

    //#region HANDLE
    useEffect(() => {
        fetchAgents();
    }, []);

    const fetchAgents = async () => {
        try {
            const response = await trainingApi.get_agent_reg_info();
            if (response.code === 200) {
                setAgents(response.data);
            } else {
                setAlertData((prev) => ({
                    ...prev,
                    title: "Lỗi",
                    content: "Tải dữ liệu Thông tin đăng ký thi Chứng chỉ ĐLBH không thành công.",
                    is_show: true,
                    variant: "danger"
                }));
            }
        } catch (error) {
            setAlertData((prev) => ({
                ...prev,
                title: "Lỗi",
                content: "Oops... Đã có lỗi xảy ra, vui lòng kiểm tra lại!",
                is_show: true,
                variant: "danger"
            }));
        }
    };

    // handle update question
    const handleEditAgent = (agent) => {
        setSelectedAgent(agent);
        setShowEditModal(true);
    };

    const handleCloseModal = () => {
        setShowEditModal(false);
        fetchAgents();
    };

    const handleDeleteAgent = (id) => {
        setDeleteAgentId(id);
        setShowDeleteModal(true);
    }

    const confirmDeleteQuestion = async () => {
        try {
            const response = await trainingApi.delete_agent_reg_info(deleteAgentId);
            console.log("response", response)
            if (response.code === 200) {
                fetchAgents();
                setAlertData((prev) => ({
                    ...prev,
                    title: "Thông báo",
                    content: "Xóa dữ liệu thông tin Đăng ký thành công. ",
                    is_show: true,
                    variant: "success"
                }));
            } else {
                setAlertData((prev) => ({
                    ...prev,
                    title: "Lỗi",
                    content: "Xóa dữ liệu thông tin Đăng ký không thành công. ",
                    is_show: true,
                    variant: "danger"
                }));
            }
        } catch (error) {
            setAlertData((prev) => ({
                ...prev,
                title: "Lỗi",
                content: "Oops... Đã có lỗi xảy ra, vui lòng kiểm tra lại!",
                is_show: true,
                variant: "danger"
            }));
        } finally {
            setShowDeleteModal(false);
        }
    }

    const filteredByKeyword = agents.filter(agent =>
        agent.fullname.toLowerCase().includes(searchKeyword.toLowerCase())
    );

    const handleModalClose = () => {
        setShowEditModal(false);
        setSelectedAgent(null);
        fetchAgents();
    };
    //#endregion

    return (
        <>
            <div className="container" >
                <div className="row row-frm">
                    <h1 className="text-center">Danh sách Đăng ký thi Chứng chỉ ĐLBH</h1>
                    <hr />
                    <div className="d-flex justify-content-between align-items-center mb-2">
                        <Link to="/cms/dang-ky-thi-dai-ly" className="btn btn-success btn-sm">
                            <span style={{ display: 'flex', alignItems: 'center', borderRadius: '0px' }}>
                                Đăng ký mới
                            </span>
                        </Link>
                        <input
                            type="text"
                            placeholder="Tìm kiếm thông tin đăng ký..."
                            value={searchKeyword}
                            onChange={(e) => setSearchKeyword(e.target.value)}
                            className="form-control"
                            style={{ maxWidth: '300px' }}
                        />
                    </div>
                    <hr />
                    <div className="table-container" style={{ overflowY: 'auto', maxHeight: '600px' }}>
                        <table className="table table-striped table-bordered table-hover">
                            <thead className="center-vertically" style={{ backgroundColor: '#3498db', position: 'sticky', top: '-2px' }}>
                                <tr style={{ verticalAlign: 'middle', color: 'white' }}>
                                    <th>#</th>
                                    <th>Họ và tên</th>
                                    <th>Ngày sinh</th>
                                    <th>Giới tính</th>
                                    <th>Số CMTND</th>
                                    <th>Ngày cấp</th>
                                    <th>Số điện thoại</th>
                                    <th colSpan={2} style={{ width: '15%' }}>Thao tác</th>
                                </tr>
                            </thead>
                            <tbody className="center-vertically">
                                {filteredByKeyword.length === 0 ? (
                                    <tr>
                                        <td colSpan="8" style={{ textAlign: 'center' }}>Không có dữ liệu phù hợp</td>
                                    </tr>
                                ) :
                                    (
                                        filteredByKeyword.map((agent, index) => (
                                            <>
                                                <tr key={index} style={{ verticalAlign: 'middle' }}>
                                                    <td>{agent.id}</td>
                                                    <td>{agent.fullname}</td>
                                                    <td>{agent.dob ? CommonHelper.formatOnlyDateExam(agent.dob) : ""}</td>
                                                    <td>{agent.sex === "true" ?
                                                        <span style={{ backgroundColor: 'lightgreen', padding: '4px', borderRadius: '10px', color: 'white' }}>Nam <IoMdMale style={{ color: 'green' }}></IoMdMale></span>
                                                        : <span style={{ backgroundColor: 'pink', padding: '4px', borderRadius: '10px', color: 'white' }}>Nữ <IoMdFemale style={{ color: 'red' }}></IoMdFemale></span>}</td>
                                                    <td>{agent.identity}</td>
                                                    <td>{agent.date_of_issue ? CommonHelper.formatOnlyDateExam(agent.date_of_issue) : ""}</td>
                                                    <td>{agent.phone}</td>
                                                    <td>
                                                        <div>
                                                            <button
                                                                style={{ marginRight: '8px', borderRadius: '0px', background: 'darkblue' }}
                                                                className="btn btn-primary btn-sm mt-2"
                                                                onClick={() => handleEditAgent(agent)}
                                                            >
                                                                Xem / Sửa
                                                            </button>
                                                            <button
                                                                style={{ marginRight: '8px', borderRadius: '0px' }}
                                                                className="btn btn-danger btn-sm mt-2"
                                                                onClick={() => handleDeleteAgent(agent.id)}
                                                            >
                                                                Xóa
                                                            </button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </>
                                        ))
                                    )
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
                {showEditModal && (
                    <EditAgent
                        showModal={showEditModal}
                        handleClose={handleModalClose}
                        selectedAgent={selectedAgent}
                    />
                )}
                {showDeleteModal && (   
                    <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)} centered>
                        <Modal.Header closeButton>
                            <Modal.Title>Xác nhận xóa</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            Bạn có chắc chắn muốn xóa thông tin đăng ký này không?
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
                                Hủy
                            </Button>
                            <Button variant="danger" onClick={confirmDeleteQuestion}>
                                Xóa
                            </Button>
                        </Modal.Footer>
                    </Modal>
                )}
                <AlertBox data={alertData} onChange={(e) => { setAlertData((prev) => ({ ...prev, is_show: e })) }} />
            </div>
        </>
    );
}

export default AgentRegInfoList;
