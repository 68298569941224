import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { FaCheck } from "react-icons/fa";
import { ImCross } from "react-icons/im";

const initial = {
    "ques_no": "0",
    "ques": "",
    "answer": "", "result": "",
    "list": [{ "key": "A", "answer": "BMBH", },
    { "key": "B", "answer": "Người thụ hưởng", },
    { "key": "C", "answer": "A, B đúng", },
    { "key": "D", "answer": "A, B sai", },
    ]
}
function Questionnaire(props) {
    const { data: valueProp, begin, finish, list_wrong, onSelect, ...other } = props;
    const [state, setState] = useState(initial);
    const [{ full_time, score }, setSelected] = useState({
        full_time: finish,
        score: other.score
    })

    useEffect(() => {
        //console.log(valueProp);
        //valueProp.result = "B";
        var _score = 0;
        if (finish) {
            _score = other.score;
        }
        setState(valueProp);
        setSelected((prev) => ({
            ...prev,
            full_time: finish,
            score: _score,
        }))
    }, [valueProp, finish])
    const handle_change = (e) => {
        const { name, value } = e.target;
        setState((prev) => ({ ...prev, [name]: value }));
        onSelect(value);
    }
    return (
        <div className='container'>
            <div className='row'>
                {state != null && (
                    <>
                        <div className='col-sm-12'>
                            <h2 className='fs-4'>{"Câu " + state.ques_no + ": " + state.ques}</h2>
                            {state.list.map((item, i) => {
                                return (
                                    <>
                                        
                                        <div className="form-check" key={i}>
                                        
                                            <input className="form-check-input" type="radio" name="result" id={"radio-" + item.key}
                                                checked={state.result == item.key} value={item.key}
                                                onClick={handle_change} disabled={full_time} />
                                            <label className={"form-check-label " + ((full_time && item.key == state.answer) ? "text-success" : "")} htmlFor={"radio-" + item.key}>
                                                <b>{item.key}</b> - {item.answer}
                                                {full_time && item.key == state.answer ? (
                                                    <FaCheck style={{ fontSize: '16px', color: 'green',display: 'inline', marginLeft: '20px' }} />
                                                ) : (
                                                    full_time && <ImCross style={{ fontSize: '16px', color: 'red', display: 'inline', marginLeft: '20px' }} />
                                                )}
                                            </label>
                                        </div>
                                    </>
                                )
                            })}
                        </div>
                    </>
                )}
                {(state === null || state === undefined) && (
                    <>
                        {!full_time && (
                            <div className='mb-5'>
                                <div className='col-sm-12'>
                                    <h2 className='fs-6'>Hãy click vào nút "Kết thúc" nếu bạn chắc chắn đã hoàn thành bài thi!</h2>
                                </div>
                            </div>
                        )}
                        {full_time && (
                            <>
                                <div className='row mb-5'>
                                    <div className='col-sm-12'>
                                        <h3 className='fs-4 text-center mb-3'>Số điểm của bạn:</h3>
                                        <h1 className='fs-2 text-center'>{score}</h1>
                                    </div>
                                </div>
                            </>
                        )}
                    </>
                )}
            </div>
        </div >
    )
}

Questionnaire.propTypes = {
    finish: PropTypes.bool.isRequired,
    onSelect: PropTypes.func.isRequired,
}

export default Questionnaire
