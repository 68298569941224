/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-no-target-blank */
import React, { useState, useEffect } from 'react'
import fbBanner from "../../../../assets/images/logo.svg";
import CommonHelper from '../../../../helpers/common';
import { Container, Row, Col } from "react-bootstrap";
import * as FaIcon from "react-icons/fa";
import TextInput from '../../../../components/text-input';
import AlertBox from '../../../../components/alert';
import trainingApi from '../../../../api/trainingApi';
import { Link } from 'react-router-dom';
import file from "../../../../assets/doc/THU DONG Y XU LY.pdf";

const initialAlert = {
    is_show: false,
    title: "",
    content: "",
    variant: "danger"
};

const initial = {
    fullname: "",
    phone: "",
    email: "",
    username: "",
    password: "",
};

function RegisterExam() {
    const [state, setState] = useState(initial);
    const [alertData, setAlertData] = useState(initialAlert);
    const [stateError, setStateError] = useState({
        fullname: false,
        // phone: false,
        username: false,
        password: false,
    });
    const [termsAccepted, setTermsAccepted] = useState(false);

    const handle_change = (e) => {
        const { name, value } = e.target;
        var _val = value;

        setState((prevData) => ({ ...prevData, [name]: _val,}));
        if(_val !== null && _val !== undefined) {
            setStateError((prev) => ({ ...prev, [name]: false }))
        }
    };

    // const validate_form = () => {
    //     var flag = true;
    //     var _data = { ...state };
    //     if (CommonHelper.is_empty(_data.fullname)) {
    //         flag = false;
    //     }
    //     // if (CommonHelper.is_empty(_data.phone)) {
    //     //     flag = false;
    //     // }
    //     if (CommonHelper.is_empty(_data.username)) {
    //         flag = false;
    //     }
    //     if (CommonHelper.is_empty(_data.password)) {
    //         flag = false;
    //     }
    //     setStateError((prev) => ({
    //         ...prev,
    //         fullname: CommonHelper.is_empty(_data.fullname),
    //         // phone: CommonHelper.is_empty(_data.phone),
    //         username: CommonHelper.is_empty(_data.username),
    //         password: CommonHelper.is_empty(_data.password),
    //     }))
    //     return flag;
    // }

    const validate_form = () => {
        var flag = true;
        var _data = { ...state };
    
        let errors = {};
        if(CommonHelper.is_empty(_data.fullname)) {
            flag = false;
            errors.fullname = true;
        } else if (CommonHelper.is_empty(_data.username)) {
            flag = false;
            errors.username = true;
        } else if (!CommonHelper.check_idcard_valid(_data.username)) {
            flag = false;
            errors.username = true;
        } else if (CommonHelper.is_empty(_data.password)) {
            flag = false;
            errors.password = true;
        } else {
            errors.username = false;
        }
    
        // if (CommonHelper.is_empty(_data.password)) {
        //     flag = false;
        //     errors.password = true;
        // } else {
        //     errors.password = false;
        // }
    
        setStateError((prev) => ({
            ...prev,
            ...errors
        }));
    
        console.log(flag);
        return flag;
    }

    const handleRegister = async (fullname, phone, email, username, password) => {
        try {
            const _data = {
                "fullName": fullname,
                "phone": phone,
                "email": email,
                "userName": username,
                "password": password,
            };
            const response = await trainingApi.register_exam(_data);
            console.log(response);
            console.log("Response from register_exam:", response);

            if (response && response.code === 200) {
                return response;
            } else {
                console.error("Invalid response format", response);
                return null;
            }
        } catch (error) {
            console.error("Error during register_exam API call:", error);
            return null;
        }
    };

    const submit = async () => {
        var flag = validate_form();
        if (!flag) {
            // setAlertData((prev) => ({
            //     ...prev,
            //     title: "Lỗi",
            //     content: "Vui lòng nhập đúng thông tin đăng ký!!!",
            //     is_show: true,
            //     variant: "danger"
            // }));
            const isChecked = stateError.fullname || stateError.username || stateError.password;
            // console.log("isChecked", isChecked)
            if(isChecked) {
                setAlertData((prev) => ({
                    ...prev,    
                    title: "Lỗi",
                    content: "Vui lòng nhập đầy đủ thông tin đăng ký!",
                    is_show: true,
                    variant: "danger"
                }));
            }
            if(stateError.username === true) {
                setAlertData((prev) => ({
                    ...prev,    
                    title: "Cảnh báo",
                    content: "Vui lòng nhập đúng số CMTND quy định (CMND: 9 số, CCCD: 12 số)!",
                    is_show: true,
                    variant: "warning"
                }));
            }
        } else {
            var _data = { ...state };

            const response = await handleRegister(
                _data.fullname,
                _data.phone,
                _data.email,
                _data.username,
                _data.password
            );

            if (!response) {
                setAlertData((prev) => ({
                    ...prev,
                    title: "Lỗi",
                    content: "Vui lòng kiểm tra lại thông tin đăng ký.",
                    is_show: true,
                    variant: "danger"
                }));
                return;
            } else {
                setTermsAccepted(false);
                setAlertData((prev) => ({
                    ...prev,
                    title: "Thông báo",
                    content: "Đăng ký tài khoản thi thử Đại lý Fubon thành công!!!",
                    is_show: true,
                    variant: "success"
                }));
            }
        }
    }

    return (
        <div className='bg-modal-gate bg-agent-exam-register'>
            <div style={{ position: "absolute", top: "20px", left: "20px" }}>
                <a className="navbar-brand" href="https://fubonins.com.vn/" target="_blank">
                    <img src={fbBanner} />
                </a>
            </div>
            <div className='login-panel' >
                <div className='text-center'><h1>ĐĂNG KÝ MỞ THÊM TÀI KHOẢN PHỤ ÔN THI ĐẠI LÝ</h1></div>
                <div >
                    <div className='mt15px'>
                        <div className="form-floating">
                            <TextInput name="fullname" placeholder="Họ và tên" value={state.fullname} onChange={handle_change} className={stateError.fullname === true ? "error" : ""} />
                            <label className='required'>Họ và tên</label>
                        </div>
                        <div className="form-floating mt15px">
                            <TextInput name="phone" placeholder="Số điện thoại" value={state.phone} onChange={handle_change} />
                            <label>Số điện thoại</label>
                        </div>
                        <div className="form-floating mt15px">
                            <TextInput name="email" placeholder="Email" value={state.email} onChange={handle_change} />
                            <label>Email</label>
                        </div>
                        <div className="form-floating mt15px">
                            <TextInput name="username" placeholder="Nhập số CMTND" value={state.username} onChange={handle_change} className={stateError.username === true ? "error" : ""}/>
                            <label className='required'>Nhập số CMTND</label>
                        </div>
                        <div className="form-floating mt15px">
                            <input name="password" type="password" placeholder="Mật khẩu" onChange={handle_change} className={stateError.password === true ? "error form-control" : "form-control"} />
                            <label className='required'>Mật khẩu</label>
                        </div>
                    </div>
                    <div className='mt15px'>
                        <button className="btn btn-primary ms-1" style={{ height: '60px', width: '150px', marginRight: '35px' }} onClick={() => submit()} disabled={!termsAccepted}>
                            ĐĂNG KÝ
                        </button>
                        <Link className="btn btn-outline-primary ms-1" style={{ height: '60px', width: '150px', paddingTop: '16px', textAlign: 'center' }} to="/exam">
                            ĐĂNG NHẬP
                        </Link>
                    </div>
                    <div className='mt10px'>
                        <div className='row mt-3'>
                            <div className='col-sm-12'>
                                <div className='row'>
                                    <div className='col-md-1' style={{ paddingTop: '8px', paddingLeft: '16px' }}>
                                        <input style={{ height: '20px', width: '20px', cursor: 'pointer', padding: '8px' }} type="checkbox" onChange={(e) => setTermsAccepted(e.target.checked)} checked={termsAccepted}/>
                                    </div>
                                    <div className='col-md-11'>
                                        <label className="form-label" style={{ marginLeft: '10px', textAlign: 'justify' }}>
                                            Tôi đã đọc <a style={{ color: 'red', fontWeight: 'bold' }} href={file}
                                                target="_blank">Thư đồng ý </a>về việc xử lý dữ liệu cá nhân và đồng ý cho phép Fubon được phép xử lý dư liệu cá nhân của tôi cho các mục đích và phạm vi được nêu tại <a style={{ color: 'red', fontWeight: 'bold' }} href={file}
                                                    target="_blank">Thư đồng ý </a> về việc xử lý dữ liệu cá nhân
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <AlertBox data={alertData} onChange={(e) => { setAlertData((prev) => ({ ...prev, is_show: e })) }} />
        </div>
    )
}

export default RegisterExam;