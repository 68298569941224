/* eslint-disable react/jsx-no-target-blank */
import React, { useState } from 'react';
import TextInput from '../../../../components/text-input';
import { Link } from 'react-router-dom';
import fbBanner from "../../../../assets/images/logo.svg";
import file from "../../../../assets/doc/THU DONG Y XU LY.pdf";

const BeforeBeginExam = ({
    username,
    password,
    handle_change,
    begin_exam
}) => {

    const [termsAccepted, setTermsAccepted] = useState(false);

    return (
        <>
            <div className='bg-modal-gate bg-agent-exam-login'>
                <div style={{ position: "absolute", top: "20px", left: "20px" }}>
                    <a className="navbar-brand" href="https://fubonins.com.vn/" target="_blank">
                        <img src={fbBanner} />
                    </a>
                </div>
                <div className='login-panel' >
                    <div className='text-center'><h1>ĐĂNG NHẬP THI ĐẠI LÝ</h1></div>
                    <div >
                        <div className='mt15px'>
                            <div className="form-floating">
                                <TextInput name="username" placeholder="Nhập số CMTND" value={username} onChange={(e) => handle_change(e.target.name, e.target.value)} />
                                <label className='required'>Nhập số CMTND</label>
                            </div>
                            <div className="form-floating mt15px">
                                <input name="password" type="password" placeholder="Mật khẩu" className='form-control' value={password} onChange={(e) => handle_change(e.target.name, e.target.value)} />
                                <label className='required'>Mật khẩu</label>
                            </div>
                        </div>
                        <div className='mt15px'>
                            <button className="btn btn-primary ms-1" style={{ height: '60px', width: '150px', marginRight: '35px' }} onClick={() => begin_exam()} disabled={!termsAccepted}>
                                ĐĂNG NHẬP
                            </button>
                            <Link className="btn btn-outline-primary ms-1" style={{ height: '60px', width: '150px', paddingTop: '16px', textAlign: 'center' }} to="/register-exam">
                                ĐĂNG KÝ
                            </Link>
                        </div>
                        <div className='mt10px'>
                            <div className='row mt-3'>
                                <div className='col-sm-12'>
                                    <div className='row'>
                                        <div className='col-md-1' style={{paddingTop: '8px', paddingLeft: '16px'}}>
                                            <input style={{ height: '20px', width: '20px', cursor: 'pointer', padding: '8px' }} type="checkbox" onChange={(e) => setTermsAccepted(e.target.checked)} />
                                        </div>
                                        <div className='col-md-11'>
                                            <label className="form-label" style={{ marginLeft: '10px', textAlign: 'justify' }}>
                                                Tôi đã đọc <a style={{ color: 'red', fontWeight: 'bold' }} href={file}
                                                    target="_blank">Thư đồng ý </a>về việc xử lý dữ liệu cá nhân và đồng ý cho phép Fubon được phép xử lý dư liệu cá nhân của tôi cho các mục đích và phạm vi được nêu tại <a style={{ color: 'red', fontWeight: 'bold' }} href={file}
                                                    target="_blank">Thư đồng ý </a> về việc xử lý dữ liệu cá nhân
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default BeforeBeginExam;