import React, { Component } from "react";
import { useLocation } from "react-router-dom";
import { Navbar, Container, Nav, Dropdown, Button } from "react-bootstrap";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
//import routes from "../../routes.js";
import { Row, Col } from "react-bootstrap";
//import Authentication from '../../api/authApi.js';

function Navigation(props) {
  const { onLogout, data, ...other } = props;
  // const location = useLocation();
  // const mobileSidebarToggle = (e) => {
  //   e.preventDefault();
  //   document.documentElement.classList.toggle("nav-open");
  //   var node = document.createElement("div");
  //   node.id = "bodyClick";
  //   node.onclick = function () {
  //     this.parentElement.removeChild(this);
  //     document.documentElement.classList.toggle("nav-open");
  //   };
  //   document.body.appendChild(node);
  // };

  // const getBrandText = () => {
  //   for (let i = 0; i < routes.length; i++) {
  //     if (location.pathname.indexOf(routes[i].layout + routes[i].path) !== -1) {
  //       return routes[i].name;
  //     }
  //   }
  //   return "Đại lý";
  // };
  const log_out = () => {
    onLogout();
    /*var response = Authentication.logout();
    response.then((res) => {
      window.location.href = "/";
    })*/
  }
  return (
    <Navbar bg="light" expand="lg">
      <Container fluid>
        {/* <div className="d-flex justify-content-center align-items-center ml-2 ml-lg-0">
          <Button
            variant="dark"
            className="d-lg-none btn-fill d-flex justify-content-center align-items-center rounded-circle p-2"
            onClick={mobileSidebarToggle}
          >
            <i className="fas fa-ellipsis-v"></i>
          </Button>
          <Navbar.Brand
            href="#home"
            onClick={(e) => e.preventDefault()}
            className="mr-2"
          >
          </Navbar.Brand>
        </div>
        <Navbar.Toggle aria-controls="basic-navbar-nav" className="mr-2">
          <span className="navbar-toggler-bar burger-lines"></span>
          <span className="navbar-toggler-bar burger-lines"></span>
          <span className="navbar-toggler-bar burger-lines"></span>
        </Navbar.Toggle> */}
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto" navbar>
            <Nav.Item>
              <Nav.Link
                className="m-0"
                href="#pablo"
                onClick={(e) => e.preventDefault()}
              >
                <span className="no-icon">{"Xin chào, " + (data.name !== undefined ? data.name : "")}</span>
              </Nav.Link>
            </Nav.Item>
            <Dropdown as={Nav.Item}>
              <Dropdown.Toggle
                aria-expanded={false}
                aria-haspopup={true}
                as={Nav.Link}
                data-toggle="dropdown"
                id="navbarDropdownMenuLink"
                variant="default"
                className="m-0"
              >
                <span className="no-icon">Cài đặt</span>
              </Dropdown.Toggle>
              <Dropdown.Menu aria-labelledby="navbarDropdownMenuLink">
                <Dropdown.Item >
                  Thông tin tài khoản
                </Dropdown.Item>
                <Dropdown.Item>
                  <Link style={{textDecoration: 'none', color: 'black'}} to="/dai-ly/doi-mat-khau">Thay đổi mật khẩu</Link>
                </Dropdown.Item>
                <div className="divider"></div>
                <Dropdown.Item onClick={log_out} >
                  Đăng xuất
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Navigation;
