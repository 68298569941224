import React, { useState, useEffect } from 'react'
import "./index.css"
import "../../App.css"
import { Link, Outlet } from 'react-router-dom'
import Sidebar from './component/sidebar'
import Navigation from './component/navbars'
import Authentication from '../../api/authApi'
import CommonHelper from '../../helpers/common'

function AgentLayout() {
    const [state_user, setStateUser] = useState({});

    const get_user = () => {
        var data = Authentication.get_user();
        data.then((res) => {
            if (res.code === 506) {
                CommonHelper.clear_storage_user();
                window.location.href = "/dai-ly/dang-nhap";
            } else {
                var objUser = CommonHelper.get_user();
                objUser.then((e) => {
                    var _url = window.location.pathname;
                    if (e.user_type == 2) {
                        window.location.href = _url != "/" ? _url.replace("dai-ly", "fubon") : "/fubon/ctpl-moto";
                    }
                    setStateUser(e);
                })
            }
        })
    };
    useEffect(() => {
        get_user();
    }, [])
    const log_out = () => {
        var response = Authentication.logout();
        response.then((res) => {
            window.location.href = "/dai-ly/dang-nhap";
        })
    }
    return (
        <>
            <div className="portal-wrapper">
                <Sidebar></Sidebar>
                <div className="main-panel">
                    <Navigation onLogout={log_out} data={state_user} />
                    <div className="content" style={{ background: "#ededed" }}>
                        <Outlet />
                    </div>
                </div>
            </div>
        </>

    )
}

export default AgentLayout