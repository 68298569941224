import api from "./api";
import CommonHelper from "../helpers/common";
import axios from "axios";

const qrCodeApi = {
    get_employee_by_id: async (emp_code = 0) => {
        var callApi = await api.get(
            "qr/get_employee/" + emp_code
        );
        if (callApi.code === 401) {
            console.log(callApi);
        }
        return callApi;
    },
    get_employee_active: async () => {
        var callApi = await api.get(
            "qr/get_employee_active"
        );
        if (callApi.code === 401) {
            console.log(callApi);
        }
        return callApi;
    },
    get_employee_hashkey: async (hash) => {
        var callApi = await api.get(
            "qr/get_employee_hashkey/" + hash
        );
        if (callApi.code === 401) {
            console.log(callApi);
        }
        return callApi;
    },
    get_employees: async (id = 0) => {
        var callApi = await api.get(
            "qr/get_employees/" + id
        );
        if (callApi.code === 401) {
            console.log(callApi);
        }
        return callApi;
    },
    get_qr_by_id: async (id = 0) => {
        var callApi = await api.get(
            "qr/get_qr_by_id/" + id
        );
        if (callApi.code === 401) {
            console.log(callApi);
        }
        return callApi;
    },
    post_namecard: async (model) => {
        var token = await CommonHelper.get_token();
        var session_id = CommonHelper.get_session_id();
        const formData = new FormData();
        formData.append("EMP_CODE", model.EMP_CODE);
        formData.append("EMP_FULL_NAME", model.EMP_FULL_NAME);
        formData.append("EMP_FULLNAME_VN", model.EMP_FULLNAME_VN);
        formData.append("EMP_FULLNAME_TW", model.EMP_FULLNAME_TW);
        formData.append("EMP_REMARKS_1", model.EMP_REMARKS_1);
        formData.append("EMP_REMARKS_2", model.EMP_REMARKS_2);
        formData.append("EMP_REMARKS_3", model.EMP_REMARKS_3);
        formData.append("EMP_REMARKS_4", model.EMP_REMARKS_4);
        formData.append("EMP_MOBILE_PHONE", model.EMP_MOBILE_PHONE);
        formData.append("EMP_EMAIL", model.EMP_EMAIL);
        formData.append("EMP_POSITION_1", model.EMP_POSITION_1);
        formData.append("EMP_POSITION_2", model.EMP_POSITION_2);
        formData.append("EMP_POSITION_3", model.EMP_POSITION_3);
        formData.append("EMP_POSITION_4", model.EMP_POSITION_4);
        formData.append("Namecard_image", model.Namecard_image);
        // formData.append("Image", model.Image);
        // formData.append("ImagePath", model.ImagePath);
        formData.append("session_id", session_id);
        console.log("FORM_DATA", formData, model);
        var response = await axios.post(process.env.REACT_APP_API_ENDPOINT + "qr/post_namecard", formData, {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "multipart/form-data",
            },
        })
        return response.data;
    },
    get_namecard_image_by_url: async (url) => {
        const encodedUrl = encodeURIComponent(url);
        var temp = encodedUrl.replace('(', '%28').replace(')', '%29');
        var callApi = await api.get(`/qr/get_namecard_image_by_url?url=${temp}`, { responseType: 'blob' });
        console.log(callApi);
        if (callApi.code === 401) {
            console.log(callApi);
        }
        return callApi;
    },
}

export default qrCodeApi;