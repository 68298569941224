import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'react-bootstrap'
import CommonHelper from '../../../helpers/common';

function ModalPackage(props) {
    const { onClose, list, show, ...other } = props;
    useEffect(() => {
        if (show) {
            console.log(list);
        }
    }, [])
    return (
        <>
            <Modal
                show={show}
                size="lg"
                backdrop="static"
                keyboard={false}>
                <Modal.Header closeButton onClick={() => { onClose(false, 0); }}>
                    <Modal.Title>GÓI BẢO HIỂM</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='d-flex w-75'>
                        {list.map((item, i) => {
                            return item.level === 1 && (
                                <div key={i} style={{ flex: "1" }}>
                                    <div className={"plan-pricing mat-card " + item.text_color} style={{ background: item.bg_color }}>
                                        <div className='head text-center'>{item.title}</div>
                                        <div className='body'>
                                            <p className='fs-3 fw-bolder' style={{ height: "48px" }}>{item.premium > 0 ? CommonHelper.format_currency(item.premium) + "" : "-"}</p>
                                            <p className='text-center'>{item.location}</p>
                                            <p className='p-death'>{CommonHelper.format_currency(item.death_si) + " VND"}</p>
                                            <p className='p-full-damage'>{item.partial_damage}</p>
                                            <p className='p-medical-fee'>{item.medical_fee > 0 ? CommonHelper.format_currency(item.medical_fee) + " VND" : "-"}</p>
                                            <p className='p-allowance'>{item.daily_allowance > 0 ? CommonHelper.format_currency(item.daily_allowance) + " VND" : "-"}</p>
                                            <p className='p-missing'>{item.missing > 0 ? CommonHelper.format_currency(item.missing) + " VND" : "-"}</p>
                                            <p className='p-funeral-allowance'>{item.funeral_allowance > 0 ? CommonHelper.format_currency(item.funeral_allowance) + " VND" : "-"}</p>
                                        </div>
                                        {/* <div className='foot fw-bolder text-center'>
                                            <div className="read-more-btn">
                                                <a className="edu-btn btn-small btn-secondary" href='javascript:;' onClick={() => { handle_select(item) }} >Chọn</a></div>
                                        </div> */}
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

ModalPackage.propTypes = {
    onClose: PropTypes.func.isRequired,
    list: PropTypes.array.isRequired,
}

export default ModalPackage
