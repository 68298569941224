import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Container, Row } from 'react-bootstrap'

function FBGate(props) {
    const { onClose, type, ...other } = props
    const handle_close = (e) => {
        onClose(e);
    }
    return (
        <div className='bg-modal-gate'>
            <div className='opt'>
                <Container>
                    <Row>
                        <div className='col-sm-12'>
                            <ul>
                                {type == "login" && (
                                    <li className='text-center'><a className='align-middle cursor-pointer' href='/dang-nhap'>KHÁCH HÀNG</a></li>
                                )}
                                {type === undefined && (
                                    <li className='text-center'><a className='align-middle cursor-pointer' onClick={handle_close}>KHÁCH HÀNG</a></li>
                                )}
                                <li className='text-center'><a className='align-middle' href='/dai-ly/dang-nhap'>ĐẠI LÝ</a></li>
                                <li className='text-center'><a className='align-middle' href='/nhan-vien/dang-nhap'>NHÂN VIÊN FUBON</a></li>
                            </ul>
                            <div className='clearfix'></div>
                        </div>
                    </Row>
                </Container>
            </div>
        </div>
    )
}

FBGate.propTypes = {}

export default FBGate
