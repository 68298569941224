import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import CommonHelper from '../../../helpers/common'
import { Modal } from 'react-bootstrap'
import * as FaIcons from 'react-icons/fa'
import paiApi from '../../../api/paiApi'

const data = [
    {
        "full_name": "Trần Văn Chương",
        "gender": "1",
        "id_card": "023456789",
        "dob": "20060412",
        "relationship": "5",
        "package": "PAIPKG04",
        "nationality": [
            {
                "code": "VT",
                "name": "VIETNAM"
            }
        ],
        "occupation": [
            {
                "code": "100001",
                "name": "Bác sĩ "
            }
        ],
        "error": false,
        "err_msg": ""
    },
    {
        "full_name": "Nguyễn Ngọc Ánh",
        "gender": "2",
        "id_card": "089189001457",
        "dob": "19890202",
        "relationship": "2",
        "package": "PAIPKG04",
        "nationality": [
            {
                "code": "VT",
                "name": "VIETNAM"
            }
        ],
        "occupation": [
            {
                "code": "100001",
                "name": "Bác sĩ "
            }
        ],
        "error": false,
        "err_msg": ""
    }
]

const lst_relationship = [
    { 'code': '1', 'name': 'Bản thân' },
    { 'code': '2', 'name': 'Vợ/Chồng' },
    { 'code': '3', 'name': 'Con cái' },
    { 'code': '4', 'name': 'Bố/Mẹ' },
    { 'code': '5', 'name': 'Anh/Chị/Em Ruột' },
    { 'code': '6', 'name': 'Người có quan hệ nuôi dưỡng, cấp dưỡng' },
];

function ModalUpload(props) {
    const { onClose, onLoading, model, show, ...other } = props;
    const [{ lst_data, lst_pkg, lst_occ, lst_nation }, setList] = useState({
        lst_data: [],
        lst_pkg: other.packages,
        lst_occ: [],
        lst_nation: [],
    });
    const [state, setState] = useState(null);
    useEffect(() => {
        if (show) {
            setList((prev) => ({
                ...prev,
                lst_occ: other.occupations,
                lst_nation: other.nationalities,
            }))
            if (data.length > 0) {
                //render_data(data);
            }
            console.log(model)
            setState(model);
        }
    }, [show])
    const fileUpload = useRef(null);
    const uploadExcel = (e) => {
        const { name, files } = e.target;
        if (files != null && files.length > 0) {
            var pa_pakage = state.pai_package;
            var pkg_code = "";
            if (pa_pakage != null) {
                pkg_code = pa_pakage.code;
            }
            var _data = {
                file: files[0],
                veh_business: state.veh_business,
                pkg_code
            };
            onLoading(true);
            var response = paiApi.import_insured_person(_data);
            response.then((res) => {
                if (res.status === 200) {
                    if (res.data.code === 200 && res.data.data != null) {
                        render_data(res.data.data);
                    }
                }
                const timer = setTimeout(() => {
                    onLoading(false);
                    if (fileUpload.current) {
                        fileUpload.current.value = "";
                        fileUpload.current.type = "text";
                        fileUpload.current.type = "file";
                    }
                }, 2000);
            })
        }
    };
    const get_template = () => {
        onLoading(true);
        var response = paiApi.get_pai_excel_template();
        response.then((res) => {
            const timer = setTimeout(() => {
                onLoading(false);
            }, 2000);
        })
    }
    const handleUpload = () => {
        fileUpload.current.click()
    };
    const render_data = (data) => {
        var lst = [];
        data.forEach((item, i) => {
            var premium = 0;
            var _package = '';
            var nationName = '';
            var nationality = [];
            var occupationName = "";
            var occupation = [];
            var relationship = CommonHelper.is_empty(item.relationship) ? "0" : item.relationship;
            var relationshipName = "";
            var err_msg = "";
            var error = false;
            var objPackage = null;
            var insPackage = "";
            if (lst_pkg.length > 0) {
                var _objPkg = [...lst_pkg].filter(x => x.code == item.package);
                if (_objPkg != null && _objPkg.length > 0) {
                    console.log(_objPkg)
                    premium = _objPkg[0].premium;
                    _package = _objPkg[0].title;
                    objPackage = {
                        code: _objPkg[0].code,
                        pkg_name: _objPkg[0].title,
                        pkg_location: _objPkg[0].location,
                        si: _objPkg[0].death_si,
                        si_medical_fee: _objPkg[0].medical_fee,
                        si_daily_allowance: _objPkg[0].daily_allowance,
                        si_missing: _objPkg[0].missing,
                        si_funeral_allowance: _objPkg[0].funeral_allowance,
                        si_sos: _objPkg[0].sos_service,
                        premium: _objPkg[0].premium,
                        conditions: _objPkg[0].condition,
                    };
                    console.log(objPackage)
                    insPackage = JSON.stringify(objPackage);
                }
            }
            var _objRelationship = lst_relationship.filter(x => x.code == item.relationship);
            if (_objRelationship != null && _objRelationship.length > 0) {
                relationshipName = _objRelationship[0].name;
            }
            if (item.nationality != null && item.nationality.length > 0) {
                nationality = item.nationality;
                nationName = item.nationality[0].name
            }
            if (item.occupation != null && item.occupation.length > 0) {
                occupationName = item.occupation[0].name
                occupation = item.occupation;
            }
            if (CommonHelper.is_not_empty(item.id_card)) {
                if (!CommonHelper.check_idcard_valid(item.id_card)) {
                    error = true;
                    err_msg = "id_card";
                }
            }
            if (CommonHelper.is_not_empty(item.id_card)) {
                var _person = {
                    fullName: item.full_name,
                    idCard: item.id_card,
                    gender: item.gender,
                    package: _package,
                    dob: item.dob,
                    insPackage,
                    objPackage,
                    premium,
                    nationName,
                    nationality,
                    occupationName,
                    occupation,
                    relationship,
                    relationshipName,
                    error,
                    err_msg,
                };
                lst.push(_person);
            }

        })
        setList((prev) => ({
            ...prev,
            lst_data: lst,
        }))
    }
    const handle_save = () => {
        var lst = [...lst_data];
        if (lst != null && lst.length > 0) {
            onClose(false, lst);
        }        
    }
    return (
        <>
            <Modal
                show={show}
                onHide={() => { onClose(false, 0); }}
                backdrop="static"
                dialogClassName='modal-70w'
                keyboard={false}
            >
                <Modal.Header closeButton onClick={() => { onClose(false, 0); }}>
                    <Modal.Title>Upload danh sách người được bảo hiểm</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-sm-12'>
                                <table className='table table-bordered'>
                                    <tbody>
                                        <tr>
                                            <td style={{ width: "70px" }}>Bước 1</td>
                                            <td>Tải (download) file template</td>
                                            <td className='text-center' style={{ width: "165px" }}>
                                                <button type="button" className="btn btn-outline-dark" onClick={get_template}><FaIcons.FaDownload /> Tải file xuống</button>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ width: "70px" }}>Bước 2</td>
                                            <td colSpan={2}>Nhập thông tin</td>
                                        </tr>
                                        <tr>
                                            <td style={{ width: "70px" }}>Bước 3</td>
                                            <td>Tải (upload) file template</td>
                                            <td style={{ width: "165px" }}>
                                                <div className='position-relative text-center'>
                                                    <button type="button" className="btn btn-outline-primary" onClick={handleUpload}><FaIcons.FaUpload /> Tải file</button>
                                                    <input
                                                        type="file"
                                                        ref={fileUpload}
                                                        onChange={uploadExcel}
                                                        style={{ opacity: "0" }}
                                                        className="position-absolute w-100"
                                                    />
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            {lst_data.length > 0 && (
                                <div className='col-sm-12' style={{ height: "400px", overflowY: "scroll" }}>
                                    <table className='table table-bordered'>
                                        <thead>
                                            <tr>
                                                <th style={{ width: "5%" }} className='text-center'>#</th>
                                                <th style={{ width: "20%" }} className='text-center'>Họ tên</th>
                                                <th style={{ width: "10%" }} className='text-center'>Giới tính</th>
                                                <th style={{ width: "6%" }} className='text-center'>Ngày sinh</th>
                                                <th style={{ width: "15%" }} className='text-center'>Số CMND/ CCCD/ Định danh/ Hộ chiếu </th>
                                                <th style={{ width: "8%" }} className='text-center'>Quốc tịch</th>
                                                <th style={{ width: "8%" }} className='text-center'>Công việc</th>
                                                <th style={{ width: "10%" }} className='text-center'>Quan hệ</th>
                                                <th style={{ width: "8%" }} className='text-center'>Gói bảo hiểm</th>
                                                <th style={{ width: "10%" }} className='text-center'>Phí</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {lst_data.map((item, i) => (
                                                <tr key={i}>
                                                    <td className='text-end'>{i + 1}</td>
                                                    <td>{item.fullName}</td>
                                                    <td>{item.gender == "1" ? "Nam" : "Nữ"}</td>
                                                    <td className={CommonHelper.is_empty(item.fullName) ? "error" : ""}>{CommonHelper.convertToDDMMYYYY(item.dob)}</td>
                                                    <td className={(item.error && item.err_msg == "id_card") ? "error" : ""}>{item.idCard}</td>
                                                    <td className={(item.error && item.err_msg == "nationality") ? "error" : ""}>{item.nationName}</td>
                                                    <td className={(item.error && item.err_msg == "occupation") ? "error" : ""}>{item.occupationName}</td>
                                                    <td className={(item.error && item.err_msg == "relationship") ? "error" : ""}>{item.relationshipName}</td>
                                                    <td className={(item.error && item.err_msg == "package") ? "error" : ""}>{item.package}</td>
                                                    <td className='text-end'>{CommonHelper.format_currency_vnd_style(item.premium)}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            )}
                            <div className='col-sm-12 text-end'>
                                <button type='button' className='btn btn-primary' style={{ marginRight: "20px" }} onClick={handle_save}>Lưu</button>
                                <button type='button' className='btn btn-secondary' onClick={() => { onClose(false, 0); }}>Huỷ bỏ</button>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default ModalUpload

PropTypes.propTypes = {
    onClose: PropTypes.func.isRequired,
}


function ListOfPerson(props) {
    const { data, ...other } = props
    return (
        <>
            <h4 className='fs-4'>Danh sách người được bảo hiểm</h4>
        </>
    )
}
