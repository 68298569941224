import React, { useEffect, useState } from 'react'
import propTypes from 'prop-types'
import addMonths from "date-fns/addMonths";
import addDays from "date-fns/addDays";
import addYears from "date-fns/addYears";
import { Container, Row, Col } from "react-bootstrap";
import TextInput from "../../../components/text-input";
import ShortDateInput from "../../../components/short-date-input";
import CommonHelper from "../../../helpers/common";
import AlertBox from "../../../components/alert";
import * as FaIcon from "react-icons/fa";
import Tooltip from "../../../components/tooltip";
import OnlineApi from "../../../api/onlineApi";
import bgPromotion from "../../../assets/images/banner-promotion.jpg";
import AutoComplete from '../../../components/auto-complete';
import { id } from 'date-fns/locale';
import Authentication from '../../../api/authApi';
import Loading from '../../../components/loading';
import Popup from '../../../components/popup';
import TermCondition from './term-condition';


const initial = {
    cus_id: 0,
    cus_reg_dob: null,
    cus_name: "",
    cus_title: "Anh",
    cus_idcard: "",
    cus_tel: "",
    cus_email: "",
    cus_address: "",
    cus_dist_code: "",
    cus_dist_name: "",
    cus_prov_code: "",
    cus_prov_name: "",
    cus_confirm_collect: false,
    cus_pwd: "",
    cus_pwd_cfm: "",
    veh_sob_code: "",
    veh_sob_name: "",
    veh_agency_code: "",
    veh_agency_name: "",
    veh_broker_code: "",
    veh_broker_name: "",
};

const initialAlert = {
    is_show: false,
    title: "",
    content: "",
    variant: "danger"
};
function MemberRegister(props) {
    const { onRegister, onCancel, data: valueProp, ...other } = props;
    const [state, setState] = useState(initial);
    const [alertData, setAlertData] = useState(initialAlert);
    const [{ showLoading, showModal }, setShow] = useState(
        { showLoading: false, showModal: false }
    );
    const [{ prov_opt, dist_opt }, setLocation] = useState({
        prov_opt: [],
        dist_opt: [],
    });
    const [{ provSel, distSel, reg_dob }, setSelected] = useState({
        provSel: [],
        distSel: [],
        reg_dob: null,
    });
    useEffect(() => {
        if (valueProp != null) {
            setState(valueProp);
        } else {
            setState(initial);
        }
        var res_provinces = OnlineApi.get_provinces();
        res_provinces.then((data) => {
            if (data !== null) {
                setLocation((prev) => ({
                    ...prev,
                    prov_opt: data,
                }));
            }
        })
    }, [valueProp]);

    const handle_change = (e) => {
        const { name, value } = e.target;
        var _val = value;
        if (name === "cus_tel") {
            _val = CommonHelper.to_phone_number(value);
        }
        /*if (name === "veh_month") {
            if (fm_dt != null) {
                var _to_date = addMonths(fm_dt, value);
                setEffDt((prev) => ({
                    ...prev,
                    to_dt: _to_date,
                }));
                setPremiumRq((prev) => ({
                    ...prev,
                    to_date: _to_date
                }))
            }
            //get_premium_moto();
        } else if (name === "veh_customer_idcard") {
            _val = value.replace(/[^0-9]/g, "");
        }*/
        setState((prevData) => ({
            ...prevData,
            [name]: _val,
        }));
    };
    const handle_check = (e) => {
        const { name, checked } = e.target;
        setState((prevData) => ({
            ...prevData,
            [name]: checked,
        }));
    };
    const change_prov = (val) => {
        var _code = "";
        if (val.length > 0) {
            _code = val[0].code;
        }
        get_districts(_code);
        setSelected((prev) => ({
            ...prev,
            provSel: val
        }))
    };
    const change_dist = (val) => {
        setSelected((prev) => ({
            ...prev,
            distSel: val
        }))
    };
    const change_reg_dob = (val) => {
        setSelected((prev) => ({
            ...prev,
            reg_dob: val,
        }));
    };
    const get_districts = (id) => {
        setSelected((prev) => ({
            ...prev,
            distSel: [],
        }));
        var res_districts = OnlineApi.get_districts(id, "");
        res_districts.then((data) => {
            if (data === null) {
                data = [];
            }
            setLocation((prev) => ({
                ...prev,
                dist_opt: data,
            }));
        })
    };
    const validate_email_format = (e) => {
        const { name, value } = e.target;
        var flag = false;
        if (!CommonHelper.validate_email_format(value)) {
            setAlertData((prev) => ({
                ...prev,
                title: "Lỗi",
                content: "Email không đúng định dạng. Vui lòng kiểm tra lại!",
                is_show: true,
                variant: "danger"
            }))
            flag = true;
        }
        return flag;
    };
    const check_cfm_pwd = () => {
        var flag = false;
        if (state.cus_pwd !== state.cus_pwd_cfm) {
            setAlertData((prev) => ({
                ...prev,
                title: "Lỗi",
                content: "Xác nhận mật khẩu không trùng khớp. Vui lòng kiểm tra lại!",
                is_show: true,
                variant: "error"
            }))
            flag = true;
        }
        return flag;
    }
    const on_cancel = (e) => {
        onCancel(false);
    }
    const on_register = (e) => {
        var _data = { ...state };
        if (reg_dob !== null) {
            _data.cus_reg_dob = CommonHelper.formatToYYYYMMDD(reg_dob);
        }
        if (provSel.length > 0) {
            _data.cus_prov_code = provSel[0].code;
            _data.cus_prov_name = provSel[0].name;
        }
        if (distSel.length > 0) {
            _data.cus_dist_code = distSel[0].code;
            _data.cus_dist_name = distSel[0].name;
        }
        //console.log(_data);
        var _req = {
            "phone": _data.cus_tel,
            "email": _data.cus_email,
            "pwd": _data.cus_pwd,
            "full_name": _data.cus_name,
            "title": _data.cus_title,
            "address": _data.cus_address,
            "province_code": _data.cus_prov_code,
            "province_name": _data.cus_prov_name,
            "district_code": _data.cus_dist_code,
            "district_name": _data.cus_dist_name,
            "dob": _data.cus_reg_dob,
            "id_card": _data.cus_idcard
        };

        var flag = true;
        if (CommonHelper.is_empty(_req.phone)) {
            flag = false;
        }
        if (CommonHelper.is_empty(_req.email)) {
            flag = false;
        }
        if (CommonHelper.is_empty(_req.full_name)) {
            flag = false;
        }
        if (CommonHelper.is_empty(_req.pwd)) {
            flag = false;
        }
        if (check_cfm_pwd()) {
            flag = false;
        }
        if (flag) {
            /*onRegister({
                code: 200,
                data: _data
            });*/
            setShow((prev) => ({
                ...prev,
                showLoading: true,
            }));
            var response = Authentication.register(_req);
            response.then((res) => {
                setShow((prev) => ({
                    ...prev,
                    showLoading: false,
                }));
                if (res.code === 205) {
                    CommonHelper.set_storage_skip_reg(true);
                    _data.cus_id = res.data.info.id;
                    setTimeout(
                        function () {
                            onRegister({
                                code: 200,
                                data: _data
                            });
                        },
                        1500
                    );
                    setAlertData((prev) => ({
                        ...prev,
                        is_show: true,
                        variant: "success",
                        title: "Thông báo",
                        content: "Đăng ký thành công!"
                    }))
                } else if (res.code === 709) {
                    setAlertData((prev) => ({
                        ...prev,
                        is_show: true,
                        variant: "danger",
                        title: "Lỗi",
                        content: "Đăng ký thất bại. Vui lòng liên hệ Trung tâm chăm sóc khách hàng!"
                    }))
                } else if (res.code === 714) {
                    setAlertData((prev) => ({
                        ...prev,
                        is_show: true,
                        variant: "danger",
                        title: "Lỗi",
                        content: "<b>Email</b> đã tồn tại. Vui lòng nhập email khác!"
                    }))
                } else if (res.code === 715) {
                    setAlertData((prev) => ({
                        ...prev,
                        is_show: true,
                        variant: "danger",
                        title: "Lỗi",
                        content: "<b>Số điện thoại</b> đã tồn tại. Vui lòng nhập số điện thoại khác!"
                    }))
                }
            })
        } else {
            setAlertData((prev) => ({
                ...prev,
                is_show: true,
                variant: "danger",
                title: "Lỗi",
                content: "Vui lòng nhập đầy đủ thông tin đăng ký!"
            }))
        }
    }
    return (
        <>
            <Row >
                <div className="col-sm-12">
                    <h1>{other.title}</h1>
                </div>
            </Row>
            <Row>
                <div className='col-sm-6 mt15px'>
                    <div className="container">
                        <Row className="mt10px">
                            <label className="form-label col-sm-3 required">Họ và tên</label>
                            <div className="col-sm-9">
                                <TextInput
                                    name="cus_name"
                                    onChange={handle_change}
                                    value={state.cus_name}
                                />
                            </div>
                        </Row>
                        <Row className="mt10px">
                            <label className="form-label col-sm-3">Danh xưng</label>
                            <div className="col-sm-9">
                                <select
                                    className="form-select form-select-sm"
                                    name="cus_title"
                                    value={state.cus_title}
                                    onChange={handle_change}
                                >
                                    <option value="Anh">Anh</option>
                                    <option value="Ông">Ông</option>
                                    <option value="Cô">Cô</option>
                                    <option value="Chị">Chị</option>
                                </select>
                            </div>
                        </Row>
                        <Row className="mt10px">
                            <label className="form-label col-sm-3 required">Ngày sinh</label>
                            <div className="col-sm-9">
                                <ShortDateInput
                                    selected={reg_dob}
                                    maxDate={addYears(new Date(), -18)}
                                    minDate={addYears(new Date(), -98)}
                                    dateFormat="dd/MM/yyyy"
                                    onChange={change_reg_dob}
                                />
                            </div>
                        </Row>
                        <Row className="mt10px">
                            <label className="form-label col-sm-3 required">Giấy tờ tùy thân</label>
                            <div className="col-sm-9">
                                <TextInput
                                    name="cus_idcard"
                                    onChange={handle_change}
                                    placeholder="CMND/CCCD/Passport No"
                                    value={state.cus_idcard} />
                            </div>
                        </Row>
                        <Row className="mt10px">
                            <label className="form-label col-sm-3 required">Số điện thoại</label>
                            <div className="col-sm-9">
                                <TextInput
                                    name="cus_tel"
                                    onChange={handle_change}
                                    value={CommonHelper.format_phone_number(state.cus_tel)} />
                            </div>
                        </Row>
                        <Row className="mt10px">
                            <label className="form-label col-sm-3 required">Email</label>
                            <div className="col-sm-9">
                                <TextInput
                                    name="cus_email"
                                    onChange={handle_change}
                                    onBlur={validate_email_format}
                                    value={state.cus_email} />
                            </div>
                        </Row>
                        <Row className="mt10px">
                            <label className="form-label col-sm-3">Địa chỉ liên hệ</label>
                            <div className="col-sm-9">
                                <TextInput
                                    name="cus_address"
                                    onChange={handle_change}
                                    value={state.cus_address} />
                            </div>
                        </Row>
                        <Row className='mt10px' style={{ paddingBottom: "10px", borderBottom: "1px solid #d2d2d2" }}>
                            <div className="col-sm-6">
                                <label className="form-label">Tỉnh/Thành phố</label>
                                <AutoComplete
                                    id="veh_type"
                                    options={prov_opt}
                                    onChange={change_prov}
                                    selected={provSel}
                                />
                            </div>
                            <div className="col-sm-6">
                                <label className="form-label">Quận/Huyện</label>
                                <AutoComplete
                                    id="veh_type"
                                    options={dist_opt}
                                    onChange={change_dist}
                                    selected={distSel}
                                />
                            </div>
                        </Row>
                        <Row className="mt10px">
                            <label className="form-label col-sm-3 required">Mật khẩu</label>
                            <div className="col-sm-9">
                                <input name="cus_pwd" type="password" className='form-control form-control-sm' value={state.cus_pwd} onChange={handle_change} onBlur={check_cfm_pwd} />
                            </div>
                        </Row>
                        <Row className="mt10px">
                            <label className="form-label col-sm-3 required">Xác nhận mật khẩu</label>
                            <div className="col-sm-9">
                                <input name="cus_pwd_cfm" type="password" className='form-control form-control-sm' value={state.cus_pwd_cfm} onChange={handle_change} onBlur={check_cfm_pwd} />
                            </div>
                        </Row>
                        <Row className="mt10px">
                            <div className="col-sm-12">
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" checked={state.cus_confirm_collect} name="cus_confirm_collect" id="cus_confirm_collect" onChange={handle_check} />
                                    <label className="form-check-label" htmlFor="cus_confirm_collect">
                                        Tôi đã hiểu và đồng ý với các <a onClick={() => { setShow((prev) => ({ ...prev, showModal: true })) }} style={{ color: "var(--bs-link-color)", cursor: "pointer" }}>điều kiện và điều khoản</a> cho phép Fubon thu thập và xử lý dữ liệu cá nhân của tôi.
                                    </label>
                                </div>
                            </div>
                        </Row>
                        <Row className="mt10px">
                            <Col className="col-sm-6 text-start">
                                <button type="button" className="btn btn-warning" onClick={on_cancel}>{other.label.lb_cancel}</button>
                            </Col>
                            <Col className="col-sm-6 text-end">
                                <button type="button" disabled={!state.cus_confirm_collect} className="btn btn-info" onClick={on_register}>{other.label.lb_reg}</button>
                            </Col>
                        </Row>
                    </div>
                </div>
                <div className='col-sm-6 mt15px'>
                    <div>
                        <a href="#">
                            <img src={bgPromotion} style={{ width: "100%" }} />
                        </a>
                    </div>
                </div>
                <div className='clearfix'></div>
            </Row>

            {showLoading && <Loading />}
            <AlertBox data={alertData} onChange={(e) => { setAlertData((prev) => ({ ...prev, is_show: e })) }} />
            <TermCondition show={showModal} onClose={(e) => { setShow((prev) => ({ ...prev, showModal: e })) }} />
        </>
    )
}

export default MemberRegister

MemberRegister.propTypes = {
    onRegister: propTypes.func.isRequired,
    onCancel: propTypes.func.isRequired,
}