import React, { useState, useEffect, useRef } from 'react'
import * as FaIcons from 'react-icons/fa'
import { useLoaderData } from 'react-router-dom';
import petApi from '../../../api/petApi';
import CommonHelper from '../../../helpers/common';
import Loading from '../../../components/loading';
import TextInput from '../../../components/text-input';

export async function loader({ params }) {
    if (params.id !== undefined && CommonHelper.is_not_empty(params.id)) {
        var data = await petApi.get_order(params.id);
        if (data != null && data.code === 200) {
            return { data };
        }
    }
    return null;
}
const flagLoading = true;

const initial = {
    veh_pol_no: "",
    veh_certificate_no: "",
    veh_org_sc_code: "PET",
    veh_sc_code: "PET",
    veh_business: "N",
    veh_type: "",
    veh_type_name: "",
    veh_seat: "0",
    veh_weight: "0",
    veh_reg_no: "",
    veh_chassis_no: "",
    veh_engine_no: "",
    veh_month: "1",
    veh_fm_dt: null,
    veh_to_dt: null,
    veh_issued_date: null,
    veh_handler_code: "",
    veh_handler_name: "",
    veh_handler_name_vi: "",
    veh_is_pax: false,
    veh_pax: "",
    veh_customer_id: 0,
    veh_customer: "",
    veh_customer_idcard: "",
    veh_phone: "",
    veh_email: "",
    veh_address: "",
    "veh_province_code": "",
    "veh_province_name": "",
    "veh_district_code": "",
    "veh_district_name": "",
    veh_issue_invoice: false,
    inv_tax: "",
    inv_company_name: "",
    inv_address: "",
    veh_origin_premium: 0,
    veh_tax_ratio: 0,
    veh_tax_amt: 0,
    veh_pax_premium: 0,
    veh_premium: 0,
    veh_si_amt1: 0,
    veh_si_amt2: 0,
    veh_si_amt3: 0,
    veh_si_amt4: 0,
    veh_is_truck: false,
    veh_url: window.location.href,
    veh_order_no: "",
    veh_get_cert_via: 1,
    veh_sob_code: "",
    veh_sob_name: "",
    veh_is_agent: false,
    veh_agency_code: "",
    veh_agency_name: "",
    veh_broker_code: "",
    veh_broker_name: "",
    pol_voucher_code: "",
    veh_gender: 1,
    veh_customer_dob: "",
    veh_time_eff: "000000",
    veh_rep_company: "",
    veh_tax_code: "",
    veh_occupation_code: "",
    veh_occupation_name: "",
    veh_national_code: "",
    veh_national_name: "",
    veh_job_type: "",
    veh_job_desc: "",
    veh_ex_occ_confirm: false,
    veh_ex_pet_confirm: false,
    is_confirm: false,
    aml_total_score: 0,
    aml_risk_score: 0,
    aml_checked: false,
    status: 1,
    user_type: 1,
    // pai_package: {}, //contains a list of insured persons
    pet_option: {}, // save item of pet_options list
    pet_package: {},
    // ins_object: [],
    res_post_order: {}, // save response of post pet order
};

function PetIssuePolicy() {
    const [state, setState] = useState(initial);
    const [{ loading, link_file }, setShow] = useState({
        loading: true,
        link_file: false,
    });
    const [stateError, setStateError] = useState({
        veh_type: false,
        veh_occupation: false,
        veh_nation: false,
        veh_fm_dt: false,
        veh_customer: false,
        veh_phone: false,
        veh_email: false,
        veh_address: false,
        veh_customer_idcard: false,
        veh_rep_company: false,
        veh_prov: false,
        dob: false,
        veh_tax_code: false,
        veh_pax: false,
        inv_tax: false,
        inv_company_name: false,
        inv_address: false,
    });
    const policy = useLoaderData();
    useEffect(() => {
        if (policy !== undefined) {
            if (policy !== null && policy.data !== null) {
                var _loader_data = policy.data.data;
                var _order_detail = _loader_data.order_detail;
                _order_detail.veh_order_no = _loader_data.veh_order_no;
                _order_detail["pol_status"] = _loader_data.status;
                if (CommonHelper.is_not_empty(_loader_data.file_upload)) {
                    _order_detail.file_upload = JSON.parse(_loader_data.file_upload);
                    _order_detail.allow_issue_policy = true;
                }
                if (_loader_data.status == 2) {
                    _order_detail.allow_issue_policy = false;
                    _order_detail["cert_url"] = _loader_data.cert_url;
                }
                setState(_order_detail);
            }
        }
        const timer = setTimeout(() => {
            setShow((prev) => ({ ...prev, loading: false }));
        }, 2000);
        return () => clearTimeout(timer);
    }, [])

    const get_pet_proposal = () => {
        var _data = { ...state };
        setShow((prev) => ({
            ...prev,
            loading: true,
        }))
        var response = petApi.get_pet_proposal(_data.veh_order_no);
        response.then((res) => {
            const timer = setTimeout(() => {
                setShow((prev) => ({ ...prev, loading: false }));
            }, 2000);
            //return res;
        })
    }

    const handle_change = (e) => {
        const { name, value } = e.target;
        var _val = value;
        setState((prevData) => ({
            ...prevData,
            [name]: _val,
        }));
    };
    const handle_check = (e) => {
        const { name, checked } = e.target;
        setState((prevData) => ({
            ...prevData,
            [name]: checked,
        }));
    };
    const fileUpload = useRef(null);
    const uploadProfilePic = (e) => {
        const { name, files } = e.target;
        if (files != null && files.length > 0) {
            var _data = {
                order_no: state.veh_order_no,
                file: files[0]
            };
            var response = petApi.upload_pet_proposal(_data);
            response.then((res) => {
                if (res.status === 200) {
                    if (res.data.code === 202 && res.data.data != null) {
                        setState((prev) => ({
                            ...prev,
                            file_upload: res.data.data.file_upload,
                            allow_issue_policy: res.data.data.allow_issue_policy
                        }))
                    }
                }
            })
        }

    };
    const handleUpload = () => {
        fileUpload.current.click();
    };
    const get_pet_policy = () => {
        var _data = { ...state };
        setShow((prev) => ({
            ...prev,
            loading: true,
        }))
        var response = petApi.get_pet_policy(_data.veh_order_no);
        response.then((res) => {
            setShow((prev) => ({
                ...prev,
                loading: false,
            }))
        })
    }
    const get_pet_debitnote = () => {
        var _data = { ...state };
        setShow((prev) => ({
            ...prev,
            loading: true,
        }))
        var response = petApi.get_pet_debitnote(_data.veh_order_no);
        response.then((res) => {
            setShow((prev) => ({
                ...prev,
                loading: false,
            }))
        })
    }
    const issue_pet_policy = () => {
        var _data = { ...state };
        var _model = {
            order_no: _data.veh_order_no,
            order_detail: _data,
        }
        setShow((prev) => ({
            ...prev,
            loading: true,
        }))
        var response = petApi.issue_pet_policy(_model);
        response.then((res) => {
            setShow((prev) => ({
                ...prev,
                loading: false,
            }))
            if (res != null && res.code === 200) {
                setState((prev) => ({
                    ...prev,
                    allow_issue_policy: false,
                    pol_status: 2
                }))
                //get_pai_policy();
                //get_pai_debitnote();
            } else {

            }
        })
    }
    return (
        <>
            <div className='container'>
                <div className='row'>
                    <div className='col-sm-12'>
                        <div className='mt-3 text-center'>
                            <h2 style={{ fontSize: "15px" }}>CẤP ĐƠN BẢO HIỂM PET</h2>
                        </div>
                        <div className='mt-2 panel-form'>
                            <div className='container'>
                                <div className='row'>
                                    <div className='col-sm-12'>
                                        <div className='card'>
                                            <div className='card-body'>
                                                <h5>THÔNG TIN BẢO HIỂM</h5>
                                                <div className='mb10px'>
                                                    <div 
                                                        className='float-start' 
                                                        style={{ width: "40%" }}
                                                    >
                                                        <label>Hiệu lực bảo hiểm:</label>
                                                    </div>
                                                    <div 
                                                        className='float-end text-end' 
                                                        style={{ width: "59%" }}
                                                    >
                                                        <strong>
                                                            {CommonHelper.convertToDDMMYYYYHHMM(state.veh_fm_dt) + " - " + CommonHelper.convertToDDMMYYYYHHMM(state.veh_to_dt)}
                                                        </strong>
                                                    </div>
                                                    <div className='clearfix'></div>
                                                </div>
                                                <div className='mb10px'>
                                                    <div 
                                                        className='float-start' 
                                                        style={{ width: "40%" }}
                                                    >
                                                        <label>Số thú cưng được bảo hiểm:</label>
                                                    </div>
                                                    <div 
                                                        className='float-end text-end' 
                                                        style={{ width: "59%" }}
                                                    >
                                                        <strong>{state.veh_pax}</strong>
                                                    </div>
                                                    <div className='clearfix'></div>
                                                </div>
                                                <div className='mb10px'>
                                                    <div 
                                                        className='float-start' 
                                                        style={{ width: "40%" }}
                                                    >
                                                        <label>Gói bảo hiểm:</label>
                                                    </div>
                                                    <div 
                                                        className='float-end text-end' 
                                                        style={{ width: "59%" }}
                                                    >
                                                        <strong>{state.pai_package.pkg_name}</strong>
                                                    </div>
                                                    <div className='clearfix'></div>
                                                </div>
                                                <div className='mb10px'>
                                                    <div 
                                                        className='float-start' 
                                                        style={{ width: "40%" }}
                                                    >
                                                        <label>Phí:</label>
                                                    </div>
                                                    <div 
                                                        className='float-end text-end' 
                                                        style={{ width: "59%" }}
                                                    >
                                                        <strong>{CommonHelper.format_currency_vnd_style(state.pai_package.premium)}</strong>
                                                    </div>
                                                    <div className='clearfix'></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='card mt-1'>
                                            <div className='card-body'>
                                                <h5>NGƯỜI MUA BẢO HIỂM</h5>
                                                <div className='mb10px'>
                                                    <div 
                                                        className='float-start' 
                                                        style={{ width: "40%" }}
                                                    >
                                                        <label>Họ và tên:</label>
                                                    </div>
                                                    <div 
                                                        className='float-end text-end' 
                                                        style={{ width: "59%" }}
                                                    >
                                                        <strong>{CommonHelper.to_upper(state.veh_customer)}</strong>
                                                    </div>
                                                    <div className='clearfix'></div>
                                                </div>
                                                <div className='mb10px'>
                                                    <div 
                                                        className='float-start' 
                                                        style={{ width: "40%" }}
                                                    >
                                                        <label>Số điện thoại:</label>
                                                    </div>
                                                    <div 
                                                        className='float-end text-end' 
                                                        style={{ width: "59%" }}
                                                    >
                                                        <strong>{state.veh_phone}</strong>
                                                    </div>
                                                    <div className='clearfix'></div>
                                                </div>
                                                <div className='mb10px'>
                                                    <div 
                                                        className='float-start' 
                                                        style={{ width: "40%" }}
                                                    >
                                                        <label>Email:</label>
                                                    </div>
                                                    <div 
                                                        className='float-end text-end' 
                                                        style={{ width: "59%" }}
                                                    >
                                                        <strong>{state.veh_email}</strong>
                                                    </div>
                                                    <div className='clearfix'></div>
                                                </div>
                                                <div className='mb10px'>
                                                    <div 
                                                        className='float-start' 
                                                        style={{ width: "40%" }}
                                                    >
                                                        <label>Địa chỉ:</label>
                                                    </div>
                                                    <div 
                                                        className='float-end text-end' 
                                                        style={{ width: "59%" }}
                                                    >
                                                        <strong>{state.veh_address + ", " + state.veh_district_name + ", " + state.veh_province_name}</strong>
                                                    </div>
                                                    <div className='clearfix'></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-sm-12 mt15px'>
                                        <div className='alert alert-info'>
                                            <div className='row'>
                                                <div className='col'>
                                                    <b>Tổng phí bảo hiểm</b> {/*(<i>Đã bao gồm VAT</i>) */}
                                                </div>
                                                <div className='col text-end'>
                                                    <strong>
                                                        {CommonHelper.format_currency_vnd_style(state.veh_premium)}
                                                    </strong>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {state.pol_status == 1 && (
                                    <>
                                        <div className='row'>
                                            <div className='col-sm-4'>
                                                <button 
                                                    type="button" 
                                                    className="btn btn-outline-dark" 
                                                    onClick={get_pet_proposal}
                                                >
                                                    <FaIcons.FaDownload /> Tải giấy yêu cầu
                                                </button>
                                            </div>
                                            <div className='col-sm-6'>
                                                <div className='position-relative'>
                                                    <button 
                                                        type="button" 
                                                        className="btn btn-outline-primary" 
                                                        onClick={handleUpload} 
                                                        style={{ width: "100%" }} 
                                                    >
                                                        <FaIcons.FaUpload /> Tải lên xác nhận khách hàng
                                                    </button>
                                                    <input
                                                        type="file"
                                                        ref={fileUpload}
                                                        onChange={uploadProfilePic}
                                                        style={{ opacity: "0", inset: "0" }}
                                                        accept='application/pdf'
                                                        className="position-absolute w-100" 
                                                    />
                                                </div>
                                            </div>
                                            <div className='col-sm-6'>
                                                {state.file_upload != null && state.file_upload.length > 0 && (
                                                    <>
                                                        {state.file_upload.map((item, i) => {
                                                            return (
                                                                <p style={{ overflow: "hidden" }}>
                                                                    <a 
                                                                        key={i} 
                                                                        target='_blank' 
                                                                        href={item.file_path} 
                                                                        className="link-underline-primary" rel="noreferrer"
                                                                    >
                                                                        01 Tập tin
                                                                    </a>
                                                                </p>
                                                            )
                                                        })}
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-sm-12'>
                                                <div className='mt-3'>
                                                    <div className="form-check form-switch">
                                                        <input
                                                            type="checkbox"
                                                            className="form-check-input"
                                                            name="veh_issue_invoice"
                                                            checked={state.veh_issue_invoice}
                                                            onChange={handle_check}
                                                        />
                                                        <label className="form-check-label">
                                                            THÔNG TIN HÓA ĐƠN
                                                        </label>
                                                    </div>
                                                    <div className="clearfix"></div>
                                                </div>
                                                <div className={state.veh_issue_invoice ? "" : "d-none"}>
                                                    <div className="mt10px">
                                                        <label className={"form-label" + (state.veh_issue_invoice ? " required" : "")}>
                                                            Mã số thuế
                                                        </label>
                                                        <TextInput
                                                            name="inv_tax"
                                                            value={state.inv_tax}
                                                            onChange={handle_change}
                                                            className={stateError.inv_tax ? "error" : ""} 
                                                        />
                                                    </div>
                                                    <div className="mt10px">
                                                        <label className={"form-label" + (state.veh_issue_invoice ? " required" : "")}>
                                                            Tên công ty
                                                        </label>
                                                        <TextInput
                                                            name="inv_company_name"
                                                            value={state.inv_company_name}
                                                            onChange={handle_change}
                                                            className={stateError.inv_company_name ? "error" : ""} 
                                                        />
                                                    </div>
                                                    <div className="mt10px">
                                                        <label className={"form-label" + (state.veh_issue_invoice ? " required" : "")}>
                                                            Địa chỉ công ty
                                                        </label>
                                                        <TextInput
                                                            name="inv_address"
                                                            value={state.inv_address}
                                                            onChange={handle_change}
                                                            className={stateError.inv_address ? "error" : ""} 
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )}
                                {state.allow_issue_policy && state.pol_status == 1 && (
                                    <div className='row mt-3 text-center'>
                                        <div style={{ width: "40%", margin: "auto" }}>
                                            <button className="btn btn-primary ms-1" onClick={issue_pet_policy}>
                                                {"CẤP ĐƠN BẢO HIỂM"}
                                            </button>
                                        </div>
                                    </div>
                                )}
                                {state.pol_status == 2 && CommonHelper.is_not_empty(state.cert_url) && (
                                    <div className='row'>
                                        <div className='col-sm-12'>
                                            <a href={state.cert_url} target='_blank' download={"policy-" + state.veh_order_no + ".pdf"}>
                                                Download giấy chứng nhận
                                            </a>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {loading && <Loading />}
        </>

    )
}

export default PetIssuePolicy;