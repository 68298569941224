import React, { useState } from "react";
import qrCodeApi from "../../../api/qrcodeApi";
import AlertBox from "../../../components/alert";
import { Form, Button, Spinner } from "react-bootstrap";

const initialAlert = {
    is_show: false,
    title: "",
    content: "",
    variant: "danger"
};

const GetQR = () => {
    const [qr, setQR] = useState(null);
    const [alertData, setAlertData] = useState(initialAlert);
    const [empCode, setEmpCode] = useState("");
    const [loading, setLoading] = useState(false);
    //#region HANDLE
    const fetchEmployees = async () => {
        setLoading(true); // Start loading
        try {
            const response = await qrCodeApi.get_qr_by_id(empCode);
            console.log("response", response);
            const _data = JSON.parse(response.data);
            if (response.code === 200) {
                setQR(_data);
            } else {
                setAlertData((prev) => ({
                    ...prev,
                    title: "Warning!",
                    content: "Downloading QR data failed.",
                    is_show: true,
                    variant: "warning"
                }));
            }
        } catch (error) {
            setAlertData((prev) => ({
                ...prev,
                title: "Error!",
                content: "Please enter employee code to search!",
                is_show: true,
                variant: "danger"
            }));
            console.log(error);
        } finally {
            setLoading(false);
        }
    };

    const handleSearch = (e) => {
        e.preventDefault();
        fetchEmployees();
    };

    const handleDownload = () => {
        const link = document.createElement("a");
        link.href = qr.Image;
        link.download = qr.EmpCode + ".png";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };
    //#endregion

    return (
        <>
            <div 
                className="clearfix" 
                style={{ marginTop: "75px" }}
            >
            </div>
            <div 
                className="container" 
                style={{ maxWidth: '500px' }}
            >
                <div className="row row-frm">
                    <h1 className="text-center">
                        Get QR
                    </h1>
                    <Form 
                        onSubmit={handleSearch} 
                        className="d-flex justify-content-center my-4"
                    >
                        <Form.Control
                            type="text"
                            placeholder="Enter Employee Code (Ex: VN00432)"
                            value={empCode}
                            onChange={(e) => setEmpCode(e.target.value)}
                            style={{ 
                                width: '300px', 
                                marginRight: '10px' 
                            }}
                        />
                        <Button 
                            type="submit" 
                            variant="primary"
                        >
                            Search
                        </Button>
                    </Form>
                    <div 
                        className="table-container" 
                        style={{ 
                            overflowY: 'auto', 
                            maxHeight: '600px' 
                        }}
                    >
                        <table className="table table-bordered">
                            <thead 
                                className="center-vertically" 
                                style={{ 
                                    backgroundColor: '#3498db', 
                                    position: 'sticky', 
                                    top: '-2px' 
                                }}
                            >
                                <tr 
                                    style={{ 
                                        verticalAlign: 'middle', 
                                        color: 'white' 
                                    }}
                                >
                                    <th style={{ width: '10%' }}>
                                        QR Code
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {loading ? (
                                    <tr 
                                        className="text-center" 
                                        style={{ 
                                            textAlign: 'center', 
                                            alignItems: 'center' 
                                        }}
                                    >
                                        <td 
                                            colSpan="7" 
                                            style={{ textAlign: 'center' }}
                                        >
                                            <Spinner /> Đang tải dữ liệu ...
                                        </td>
                                    </tr>
                                ) :
                                    (<>
                                        {qr === null ? (
                                            <tr>
                                                <td 
                                                    colSpan="5" 
                                                    style={{ textAlign: 'center' }}
                                                >
                                                    No data matching!!!
                                                </td>
                                            </tr>
                                        ) :
                                            (
                                                <>
                                                    <tr 
                                                        key={qr.Id} 
                                                        style={{ 
                                                            verticalAlign: 'middle', 
                                                            textAlign: 'center' 
                                                        }}
                                                    >
                                                        <td>
                                                            <img 
                                                                src={`${qr.Image}`} 
                                                                alt="QR Code" 
                                                                style={{ 
                                                                    height: '300px', 
                                                                    width: '300px' 
                                                                }} 
                                                            />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <div className="row">
                                                            <div className="col col-md-12">
                                                                <button 
                                                                    className="btn btn-success" 
                                                                    onClick={handleDownload}
                                                                    style={{ 
                                                                        verticalAlign: 'middle', 
                                                                        textAlign: 'center', 
                                                                        alignItems: 'center' 
                                                                    }}
                                                                >
                                                                    Download QR
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </tr>
                                                </>
                                            )
                                        }
                                    </>)}
                            </tbody>
                        </table>
                    </div>
                </div>
                <AlertBox 
                    data={alertData} 
                    onChange={(e) => { setAlertData((prev) => ({ ...prev, is_show: e })) }} 
                />
            </div>
        </>
    );
}

export default GetQR;
