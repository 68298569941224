import React from 'react'
import PropTypes from 'prop-types'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

function ModalPayment(props) {
    const { onClose, show, ...other } = props;
    return (
        <Modal
            show={show}
            backdrop="static"
            keyboard={false}
        >
            <Modal.Header closeButton onClick={() => { onClose(false, 0); }}>
                <Modal.Title>Gửi giấy yêu cầu bảo hiểm</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className='container'>
                    <div className='row'>
                        <div className='col-sm-12 text-center'>
                            <a className='text-center' onClick={() => { onClose(false, 1); }} style={{ cursor: "pointer", textDecoration: "none" }}>
                                <div className='card'>
                                    <div className='card-body'>
                                        Gửi email
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div className='col-sm-6 d-none'>
                            <a className='text-center' onClick={() => { onClose(false, 1); }} style={{ cursor: "pointer", textDecoration: "none" }}>
                                <div className='card'>
                                    <div className='card-body'>
                                        THANH TOÁN NGAY
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default ModalPayment

ModalPayment.propTypes = {
    onClose: PropTypes.func.isRequired,
}