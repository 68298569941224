/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import "./index.css";
import { Link } from "react-router-dom";
import trainingApi from "../../../../api/trainingApi";
import { Modal } from "react-bootstrap";
import FormBuilder from "../form/add-form";
import AlertBox from "../../../../components/alert";
import { Container, Row, Col, Button } from "react-bootstrap";
import * as FaIcon from "react-icons/fa";
import fbBanner from "../../../../assets/images/logo.svg";

const initialAlert = {
    is_show: false,
    title: "",
    content: "",
    variant: "danger"
};

const QuestionsList = () => {
    const [questions, setQuestions] = useState([]);
    const [selectedRowIndex, setSelectedRowIndex] = useState(null);
    const [selectedEditQuestion, setSelectedEditQuestion] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [deleteQuestionId, setDeleteQuestionId] = useState(null);
    const [alertData, setAlertData] = useState(initialAlert);
    const [searchKeyword, setSearchKeyword] = useState("");
    const [filterTopic, setFilterTopic] = useState("");
    const [listTopic, setListTopic] = useState([]);

    useEffect(() => {
        fetchTopics();
        fetchQuestions();
    }, []);

    const fetchTopics = async () => {
        try {
            const response = await trainingApi.get_topics();
            if (response.code === 200) {
                setListTopic(response.data);
            } else {
                setAlertData((prev) => ({
                    ...prev,
                    title: "Lỗi",
                    content: "Tải dữ liệu chủ đề thi không thành công. ",
                    is_show: true,
                    variant: "danger"
                }));
            }
        } catch (error) {
            setAlertData((prev) => ({
                ...prev,
                title: "Lỗi",
                content: "Oops... Đã có lỗi xảy ra, vui lòng kiểm tra lại!",
                is_show: true,
                variant: "danger"
            }));
        }
    }

    const fetchQuestions = async () => {
        try {
            const response = await trainingApi.get_questions();
            if (response.code === 200) {
                setQuestions(response.data);
            } else {
                setAlertData((prev) => ({
                    ...prev,
                    title: "Lỗi",
                    content: "Tải dữ liệu câu hỏi không thành công. ",
                    is_show: true,
                    variant: "danger"
                }));
            }
        } catch (error) {
            setAlertData((prev) => ({
                ...prev,
                title: "Lỗi",
                content: "Oops... Đã có lỗi xảy ra, vui lòng kiểm tra lại!",
                is_show: true,
                variant: "danger"
            }));
        }
    };

    const toggleAnswers = (index) => {
        setSelectedRowIndex(selectedRowIndex === index ? null : index);
    };

    const parseAnswers = (answersString, ansResult) => {
        try {
            const answersObject = JSON.parse(answersString);
            if (answersObject && answersObject.ans_list_opt) {
                return answersObject.ans_list_opt.map((opt, idx) => (
                    <div key={idx} style={{ fontWeight: opt.ans_opt[0] === ansResult ? 'bold' : 'normal' }}>
                        {opt.ans_opt}
                    </div>
                ));
            } else {
                return <div>Invalid answer format</div>;
            }
        } catch (error) {
            return <div>Error parsing answers</div>;
        }
    };

    const handleEditQuestion = (question) => {
        setSelectedEditQuestion(question);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        fetchQuestions();
    };

    const handleDeleteQuestion = (id) => {
        setDeleteQuestionId(id);
        setShowDeleteModal(true);
    }

    const confirmDeleteQuestion = async () => {
        try {
            const response = await trainingApi.delete_question(deleteQuestionId);
            if (response.code === 200) {
                fetchQuestions();
                setAlertData((prev) => ({
                    ...prev,
                    title: "Thông báo",
                    content: "Xóa dữ liệu câu hỏi thành công. ",
                    is_show: true,
                    variant: "success"
                }));
            } else {
                setAlertData((prev) => ({
                    ...prev,
                    title: "Lỗi",
                    content: "Xóa dữ liệu câu hỏi không thành công. ",
                    is_show: true,
                    variant: "danger"
                }));
            }
        } catch (error) {
            setAlertData((prev) => ({
                ...prev,
                title: "Lỗi",
                content: "Oops... Đã có lỗi xảy ra, vui lòng kiểm tra lại!",
                is_show: true,
                variant: "danger"
            }));
        } finally {
            setShowDeleteModal(false);
        }
    }

    const filteredByTopic = questions.filter(question =>
        question.topic && question.topic.toLowerCase().includes(filterTopic.toLowerCase())
    );

    const filteredByKeyword = filteredByTopic.filter(question =>
        question.question.toLowerCase().includes(searchKeyword.toLowerCase())
        || String(question.id).includes(searchKeyword)
    );

    return (
        <>
            <div className="container" >
                <div className="row row-frm">
                    <h1 className="text-center">Bộ đề bảo hiểm Fubon</h1>
                    <hr />
                    <div className="d-flex justify-content-between align-items-center mb-2">
                        <Link to="/cms/tao-cau-hoi" className="btn btn-success btn-sm">
                            <span style={{ display: 'flex', alignItems: 'center', borderRadius: '0px' }}>
                                Thêm Câu hỏi
                            </span>
                        </Link>
                        <input
                            type="text"
                            placeholder="Tìm kiếm câu hỏi..."
                            value={searchKeyword}
                            onChange={(e) => setSearchKeyword(e.target.value)}
                            className="form-control"
                            style={{ maxWidth: '300px' }}
                        />
                    </div>
                    <hr />
                    <div>
                        <select
                            onChange={(e) => setFilterTopic(e.target.value)}
                            className="form-control"
                            style={{ maxWidth: '200px', marginBottom: '10px', fontWeight: '500' }}
                            value={filterTopic}
                        >
                            <option value=""> Lọc chủ đề</option>
                            {listTopic.map((topic, index) => (
                                <option key={index} value={topic.topicName}>{topic.topicName}</option>
                            ))}
                        </select>
                    </div>
                    <div className="table-container" style={{ overflowY: 'auto', maxHeight: '600px' }}>
                        <table className="table table-striped table-bordered table-hover">
                            <thead className="center-vertically" style={{ backgroundColor: '#3498db', position: 'sticky', top: '-2px' }}>
                                <tr style={{ verticalAlign: 'middle', color: 'white' }}>
                                    <th style={{ width: '60%' }}>Nội dung câu hỏi</th>
                                    <th style={{ width: '7%' }}>Đáp án</th>
                                    <th>Chủ đề</th>
                                    <th colSpan={2} style={{ width: '15%' }}>Thao tác</th>
                                </tr>
                            </thead>
                            <tbody className="center-vertically">
                                {filteredByKeyword.length === 0 ? (
                                    <tr>
                                        <td colSpan="4" style={{ textAlign: 'center' }}>Không có dữ liệu phù hợp</td>
                                    </tr>
                                ) :
                                    (
                                        filteredByKeyword.map((question, index) => (
                                            <>
                                                <tr key={index} style={{ verticalAlign: 'middle' }}>
                                                    <td style={{ textAlign: 'left', fontWeight: '500' }}>{question.id + ". " + question.question}</td>
                                                    {(JSON.parse(question.answers).ans_result !== "null") ? (
                                                        <td style={{ fontWeight: '500' }}>{JSON.parse(question.answers).ans_result}</td>
                                                    ) : (
                                                        <td style={{ fontWeight: '500' }}>Chưa có đáp án</td>
                                                    )}
                                                    <td>{question.topic}</td>
                                                    <td>
                                                        <div>
                                                            <button
                                                                style={{ marginRight: '8px', borderRadius: '0px' }}
                                                                className="btn btn-warning btn-sm mt-2"
                                                                onClick={() => toggleAnswers(index)}
                                                            >
                                                                Xem
                                                            </button>
                                                            <button
                                                                style={{ marginRight: '8px', borderRadius: '0px' }}
                                                                className="btn btn-primary btn-sm mt-2"
                                                                onClick={() => handleEditQuestion(question)}
                                                            >
                                                                Sửa
                                                            </button>
                                                            <button
                                                                style={{ marginRight: '8px', borderRadius: '0px' }}
                                                                className="btn btn-danger btn-sm mt-2"
                                                                onClick={() => handleDeleteQuestion(question.id)}
                                                            >
                                                                Xóa
                                                            </button>
                                                        </div>
                                                    </td>
                                                </tr>
                                                {selectedRowIndex === index && (
                                                    <tr>
                                                        <td style={{ textAlign: 'left' }} colSpan={1}>{parseAnswers(question.answers, JSON.parse(question.answers).ans_result)}</td>
                                                        {(JSON.parse(question.answers).ans_result !== "null") ? (
                                                            <td style={{ fontWeight: '500' }}>{JSON.parse(question.answers).ans_result}</td>
                                                        ) : (
                                                            <td style={{ fontWeight: '500' }}>Chưa có đáp án</td>
                                                        )}
                                                        <td></td>
                                                        <td></td>
                                                    </tr>
                                                )}
                                            </>
                                        ))
                                    )
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
                {showModal && (
                    <Modal
                        dialogClassName="modal-xl-edit"
                        fullscreen="xxl-down"
                        show={showModal}
                        onHide={handleCloseModal}
                        backdrop="static"
                        contentClassName="modal-fullscreen"
                    >
                        <Modal.Header closeButton style={{ marginRight: '46px' }}>
                            <Modal.Title as="h3">
                                Cập nhật câu hỏi
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="modal-content-edit modal-body-edit modal-body">
                            <FormBuilder
                                flag={true}
                                questionEditData={selectedEditQuestion}
                                onQuestionUpdate={fetchQuestions}
                            />
                        </Modal.Body>
                    </Modal>
                )}
                {showDeleteModal && (
                    <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)} centered>
                        <Modal.Header closeButton>
                            <Modal.Title>Xác nhận xóa</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            Bạn có chắc chắn muốn xóa câu hỏi này không?
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
                                Hủy
                            </Button>
                            <Button variant="danger" onClick={confirmDeleteQuestion}>
                                Xóa
                            </Button>
                        </Modal.Footer>
                    </Modal>
                )}
                <AlertBox data={alertData} onChange={(e) => { setAlertData((prev) => ({ ...prev, is_show: e })) }} />
            </div>
        </>
    );
}

export default QuestionsList;
