import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types';
import addYears from "date-fns/addYears";
import addMonths from 'date-fns/addMonths';
import NumberInput from '../../../components/number-input';
import TextInput from '../../../components/text-input';
import ShortDateInput from '../../../components/short-date-input';
import CommonHelper from '../../../helpers/common';
import AlertBox from '../../../components/alert';
import Nationality from '../../../nationality';
//import Occupation from '../../../occupation';
import AutoComplete from '../../../components/auto-complete';
import AutoInput from '../../../components/auto-input';
import { Highlighter } from 'react-bootstrap-typeahead';
import ModalPackage from './modal-package';
import ValidateMessage from '../../../components/validate-msg';

const Occupations = [
    { 'code': '100001', 'name': 'Bác sĩ ' },
    { 'code': '100002', 'name': 'Giáo viên ' },
    { 'code': '100003', 'name': 'Kế toán ' },
    { 'code': '100004', 'name': 'Kiến trúc sư' },
    { 'code': '100005', 'name': 'Môi giới bất động sản' },
    { 'code': '100006', 'name': 'Nhà thiết kế' },
    { 'code': '100007', 'name': 'Nhân viên bán hàng' },
    { 'code': '100008', 'name': 'Nhân viên ngân hàng' },
    { 'code': '100009', 'name': 'Nhân viên văn phòng ' },
    { 'code': '100010', 'name': 'Thợ cắt tóc' },
    { 'code': '100011', 'name': 'Thư ký' },
    { 'code': '100012', 'name': 'Trợ lý ' },
    { 'code': '100013', 'name': 'Thu ngân' },
    { 'code': '100014', 'name': 'Y tá ' },
    { 'code': '100015', 'name': 'Thẩm phán' },
    { 'code': '100016', 'name': 'Kiểm soát viên' },
    { 'code': '100017', 'name': 'Chuyên viên đánh giá (nhà phê bình văn học, hội họa, ẩm thực...)' },
    { 'code': '100018', 'name': 'Nhân viên thẩm mỹ viện' },
    { 'code': '100019', 'name': 'Quản lý chăn nuôi ' },
    { 'code': '100020', 'name': 'Quản lý nhà ga ' },
    { 'code': '100021', 'name': 'Nhân viên ga tàu ' },
    { 'code': '100022', 'name': 'Quản lý hành chính ' },
    { 'code': '100023', 'name': 'Thiết kế thời trang ' },
    { 'code': '100024', 'name': 'Dược sĩ' },
    { 'code': '100025', 'name': 'Y sĩ' },
    { 'code': '100026', 'name': 'Hành chính bệnh viện ' },
    { 'code': '100027', 'name': 'Kỹ thuật viên vật lý trị liệu ' },
    { 'code': '100028', 'name': 'Nhân viên hành chính' },
    { 'code': '100029', 'name': 'Hội họa' },
    { 'code': '100030', 'name': 'Biên kịch' },
    { 'code': '100031', 'name': 'Nhân viên tráng phim' },
    { 'code': '100032', 'name': 'Nhân viên bán vé, soát vé rạp chiếu phim' },
    { 'code': '100033', 'name': 'Nhân viên rạp chiếu phim' },
    { 'code': '100034', 'name': 'Nhạc sỹ ' },
    { 'code': '100035', 'name': 'Nhân viên bán hàng trong khu vui chơi' },
    { 'code': '100036', 'name': 'Quản lý nhà hàng ' },
    { 'code': '100037', 'name': 'Quản lý khách sạn ' },
    { 'code': '100038', 'name': 'Quản lý hồ bơi' },
    { 'code': '100039', 'name': 'Nhân viên bán vé' },
    { 'code': '100040', 'name': 'Giảng viên ' },
    { 'code': '100041', 'name': 'Sinh viên ' },
    { 'code': '100042', 'name': 'Học sinh' },
    { 'code': '100043', 'name': 'Trẻ em ' },
    { 'code': '100044', 'name': 'Biên dịch viên ' },
    { 'code': '100045', 'name': 'Người tu hành ' },
    { 'code': '100046', 'name': 'Mục sư, linh mục ' },
    { 'code': '100047', 'name': 'Nhân viên spa' },
    { 'code': '100048', 'name': 'Hành chính nhân sự ' },
    { 'code': '100049', 'name': 'Nhà chuyên môn về khoa học trái đất và vật lý ' },
    { 'code': '100050', 'name': 'Nhà toán học, thống kê ' },
    { 'code': '100051', 'name': 'Điều dưỡng ' },
    { 'code': '100052', 'name': 'Nhà tư vấn tài chính ' },
    { 'code': '100053', 'name': 'Phiên dịch' },
    { 'code': '100054', 'name': 'Nhân viên y tế' },
    { 'code': '100055', 'name': 'Nhân viên tổng đài' },
    { 'code': '100056', 'name': 'Nhân viên thiết kế ' },
    { 'code': '100057', 'name': 'Nhân viên kế hoạch' },
    { 'code': '100058', 'name': 'Kế toán trưởng ' },
    { 'code': '100059', 'name': 'Tổng giám đốc' },
    { 'code': '100060', 'name': 'Phó tổng giám đốc' },
    { 'code': '100061', 'name': 'Giám đốc ' },
    { 'code': '100062', 'name': 'Giám đốc tài chính' },
    { 'code': '100063', 'name': 'Quản lý' },
    { 'code': '100064', 'name': 'Trưởng phòng ' },
    { 'code': '100065', 'name': 'Phó phòng ' },
    { 'code': '100066', 'name': 'Trưởng nhóm ' },
    { 'code': '100067', 'name': 'Công nhân phòng thí nghiệm ' },
    { 'code': '100068', 'name': 'Công nhân phòng thuốc' },
    { 'code': '100069', 'name': 'Trưởng ban' },
    { 'code': '100070', 'name': 'Đóng gói' },
    { 'code': '100071', 'name': 'Dán nhãn' },
    { 'code': '100072', 'name': 'Kiểm hàng' },
    { 'code': '100073', 'name': 'Tổng vụ' },
    { 'code': '100074', 'name': 'Nhân viên địa chính' },
    { 'code': '100075', 'name': 'Công chứng viên' },
    { 'code': '100076', 'name': 'Nhân viên ghi sổ kế toán' },
    { 'code': '100077', 'name': 'Đại lý thuế' },
    { 'code': '100078', 'name': 'Luật sư' },
    { 'code': '100079', 'name': 'Nhân viên kiểm toán' },
    { 'code': '100080', 'name': 'Lãnh đạo có ảnh hưởng chính trị' },
    { 'code': '200001', 'name': 'Bộ phận kỹ thuật ' },
    { 'code': '200002', 'name': 'Bảo trì điện' },
    { 'code': '200003', 'name': 'Cố vấn' },
    { 'code': '200004', 'name': 'Bảo trì máy ' },
    { 'code': '200005', 'name': 'Bộ phận chất lượng' },
    { 'code': '200006', 'name': 'Công nhân ' },
    { 'code': '200007', 'name': 'Thu mua' },
    { 'code': '200008', 'name': 'Công nhân lắp ráp' },
    { 'code': '200009', 'name': 'Nhân viên vẽ 2D, 3D, đứng máy CNC' },
    { 'code': '200010', 'name': 'CNC' },
    { 'code': '200011', 'name': 'Phụ xe' },
    { 'code': '200012', 'name': 'Quản lý chất lượng' },
    { 'code': '200013', 'name': 'Bác sỹ thú y' },
    { 'code': '200014', 'name': 'Công nhân may' },
    { 'code': '200015', 'name': 'Công nhân nhuộm' },
    { 'code': '200016', 'name': 'Công nhân sơn' },
    { 'code': '200017', 'name': 'Công nhân thêu' },
    { 'code': '200018', 'name': 'Công nhân sản xuất ' },
    { 'code': '200019', 'name': 'Quản lý sản xuất ' },
    { 'code': '200020', 'name': 'Trưởng máy ' },
    { 'code': '200021', 'name': 'Giám đốc kỹ thuật ' },
    { 'code': '200022', 'name': 'Giám đốc thi công' },
    { 'code': '200023', 'name': 'Giám đốc sản xuất ' },
    { 'code': '200024', 'name': 'Giám sát kỹ thuật ' },
    { 'code': '200025', 'name': 'Giám sát thu mua ' },
    { 'code': '200026', 'name': 'Nhân viên thực tập' },
    { 'code': '200027', 'name': 'Giám sát kinh doanh ' },
    { 'code': '200028', 'name': 'Kho' },
    { 'code': '200029', 'name': 'Nhân viên' },
    { 'code': '200030', 'name': 'Nhân viên kỹ thuật' },
    { 'code': '200031', 'name': 'Nhân viên vận hành máy ' },
    { 'code': '200032', 'name': 'Kiểm phẩm ' },
    { 'code': '200033', 'name': 'Kỹ sư' },
    { 'code': '200034', 'name': 'Nấu ăn ' },
    { 'code': '200035', 'name': 'Bảo mẫu' },
    { 'code': '200036', 'name': 'Bảo vệ ' },
    { 'code': '200037', 'name': 'Đầu bếp' },
    { 'code': '200038', 'name': 'Huấn luyện viên thể thao' },
    { 'code': '200039', 'name': 'May mặc' },
    { 'code': '200040', 'name': 'Người đi chào hàng' },
    { 'code': '200041', 'name': 'Nhân viên giao hàng' },
    { 'code': '200042', 'name': 'Nhân viên kinh doanh ' },
    { 'code': '200043', 'name': 'Nhân viên phục vụ' },
    { 'code': '200044', 'name': 'Nội trợ ' },
    { 'code': '200045', 'name': 'Buôn bán phế liệu' },
    { 'code': '200046', 'name': 'Bán hàng rong ' },
    { 'code': '200047', 'name': 'Buôn bán động vật ' },
    { 'code': '200048', 'name': 'Quản lý kho' },
    { 'code': '200049', 'name': 'Kinh doanh vàng bạc, đá quý' },
    { 'code': '200050', 'name': 'Ghi đồng hồ, thu phí điện nước' },
    { 'code': '200051', 'name': 'Pha chế ' },
    { 'code': '200052', 'name': 'Thợ chụp hình/ nhiếp ảnh gia' },
    { 'code': '200053', 'name': 'Người làm dịch vụ ma chay cưới hỏi' },
    { 'code': '200054', 'name': 'Giặt ủi ' },
    { 'code': '200055', 'name': 'Thợ sửa khóa ' },
    { 'code': '200056', 'name': 'Thợ trang điểm ' },
    { 'code': '200057', 'name': 'Nhân viên trạm xăng ' },
    { 'code': '200058', 'name': 'Nhân viên quản lý/ kiểm định chất lượng sản phẩm ' },
    { 'code': '200059', 'name': 'Công nhân trồng cây' },
    { 'code': '200060', 'name': 'Kỹ sư nông nghiệp ' },
    { 'code': '200061', 'name': 'Giám đốc nông trường, hợp tác xã, cán bộ khuyến nông' },
    { 'code': '200062', 'name': 'Chăn nuôi gia cầm, gia súc' },
    { 'code': '200063', 'name': 'Trồng cây cảnh ' },
    { 'code': '200064', 'name': 'Thiết kế công trình ' },
    { 'code': '200065', 'name': 'Quản lý hệ thống cung cấp nước ' },
    { 'code': '200066', 'name': 'Nuôi thủy sản' },
    { 'code': '200067', 'name': 'Nhân viên phát thư' },
    { 'code': '200068', 'name': 'Quản lý bến xe' },
    { 'code': '200069', 'name': 'Kiểm định, đăng kiểm' },
    { 'code': '200070', 'name': 'Hải quan ' },
    { 'code': '200071', 'name': 'Thiết kế, quản lý cửa hàng nội thất ' },
    { 'code': '200072', 'name': 'Kỹ thuật điện tử/ cơ điện ' },
    { 'code': '200073', 'name': 'Lắp ráp thiết bị điện tự' },
    { 'code': '200074', 'name': 'Công nhân giày da' },
    { 'code': '200075', 'name': 'Công nhân bao bì ' },
    { 'code': '200076', 'name': 'Công nhân sản xuất hóa chất ' },
    { 'code': '200077', 'name': 'Chế biến thực phẩm ' },
    { 'code': '200078', 'name': 'Mổ gia súc, gia cầm ' },
    { 'code': '200079', 'name': 'Gia công thủ công mỹ nghệ trên giấy/ vải ' },
    { 'code': '200080', 'name': 'Thợ làm đồ gốm ' },
    { 'code': '200081', 'name': 'Công nhân sản xuất nhựa' },
    { 'code': '200082', 'name': 'Công nhân gia công sản phẩm từ cao su' },
    { 'code': '200083', 'name': 'Công nhân xưởng giấy ' },
    { 'code': '200084', 'name': 'Đạo diễn ' },
    { 'code': '200085', 'name': 'Quay phim ' },
    { 'code': '200086', 'name': 'Nhân viên hậu cần' },
    { 'code': '200087', 'name': 'Phóng viên ' },
    { 'code': '200088', 'name': 'Biên tập viên ' },
    { 'code': '200089', 'name': 'MC' },
    { 'code': '200090', 'name': 'Công nhân xưởng in' },
    { 'code': '200091', 'name': 'Nhân viên khử trùng ' },
    { 'code': '200092', 'name': 'Bác sỹ/ y tá bệnh viện tâm thần ' },
    { 'code': '200093', 'name': 'Bác sỹ/ y tá tại trại giam' },
    { 'code': '200094', 'name': 'Sửa chữa máy móc văn phòng ' },
    { 'code': '200095', 'name': 'Tạp vụ ' },
    { 'code': '200096', 'name': 'Tổ phó' },
    { 'code': '200097', 'name': 'Tổ trưởng' },
    { 'code': '200098', 'name': 'Hộ lý ' },
    { 'code': '200099', 'name': 'Nhân viên vệ sinh văn phòng ' },
    { 'code': '200100', 'name': 'Chăm sóc thú ' },
    { 'code': '200101', 'name': 'Vệ sinh chuồng thú ' },
    { 'code': '200102', 'name': 'Nhân viên đạo cụ, dựng cảnh' },
    { 'code': '200103', 'name': 'Trợ lý, thư ký trường quay' },
    { 'code': '200104', 'name': 'Nhân viên phụ trách âm thanh, ánh sáng, phụ trách kỹ thuật hiện trường' },
    { 'code': '200105', 'name': 'Phóng viên truyền hình' },
    { 'code': '200106', 'name': 'Nhân viên phục trang' },
    { 'code': '200107', 'name': 'Diễn viên' },
    { 'code': '200108', 'name': 'Ca sỹ ' },
    { 'code': '200109', 'name': 'Bầu show' },
    { 'code': '200110', 'name': 'Người mẫu ' },
    { 'code': '200111', 'name': 'Quản lý khu giải trí, quán bar, karaoke' },
    { 'code': '200112', 'name': 'Công an' },
    { 'code': '200113', 'name': 'Quản lý trại giam ' },
    { 'code': '200114', 'name': 'Trọng tài ' },
    { 'code': '200115', 'name': 'Môi giới chứng khoán ' },
    { 'code': '200116', 'name': 'Nhân viên massage, spa, sauna, xông hơi (không phải trong thẩm mĩ viện)' },
    { 'code': '200117', 'name': 'Thất nghiệp ' },
    { 'code': '200118', 'name': 'Bán buôn, bán lẻ' },
    { 'code': '200119', 'name': 'Truyền thông ' },
    { 'code': '200120', 'name': 'Nông ' },
    { 'code': '200121', 'name': 'Nghiên cứu thị trường ' },
    { 'code': '200122', 'name': 'Kỹ sư môi trường ' },
    { 'code': '200123', 'name': 'Kỹ sư hóa học ' },
    { 'code': '300001', 'name': 'Cảnh sát hình sự' },
    { 'code': '300002', 'name': 'Công an, an ninh (không làm việc tại cơ quan, văn phòng)' },
    { 'code': '300003', 'name': 'Công nhân cưa gỗ' },
    { 'code': '300004', 'name': 'Công nhân sản xuất ghép gỗ, sản xuất gỗ ép, thợ mộc' },
    { 'code': '300005', 'name': 'Công nhân vệ sinh đường cống' },
    { 'code': '300006', 'name': 'Gia công kim loại' },
    { 'code': '300007', 'name': 'Giám sát xây dựng' },
    { 'code': '300008', 'name': 'Khuân vác' },
    { 'code': '300009', 'name': 'Kiểm lâm' },
    { 'code': '300010', 'name': 'Kỹ sư cơ khí' },
    { 'code': '300011', 'name': 'Kỹ sư dân dụng ' },
    { 'code': '300012', 'name': 'Làm việc liên quan đến điện cao áp ' },
    { 'code': '300013', 'name': 'Lao động tự do ' },
    { 'code': '300014', 'name': 'Lính cứu hỏa' },
    { 'code': '300015', 'name': 'Người lau cửa kính bên ngoài tòa nhà tại độ cao dưới 30 mét' },
    { 'code': '300016', 'name': 'Người nuôi cá sấu, rắn, động vật nguy hiểm' },
    { 'code': '300017', 'name': 'Nhân viên bán thịt' },
    { 'code': '300018', 'name': 'Nhân viên bảo vệ xe (quán bar, vũ trường, karaoke)' },
    { 'code': '300019', 'name': 'Nhân viên cứu hộ vùng sông nước, đồi núi' },
    { 'code': '300020', 'name': 'Nhân viên thi công, lắp đặt nội thất ' },
    { 'code': '300021', 'name': 'Nhân viên phòng độc, diệt côn trùng' },
    { 'code': '300022', 'name': 'Quản lý công trường hầm mỏ' },
    { 'code': '300023', 'name': 'Tài xế' },
    { 'code': '300024', 'name': 'Tài xế bồn chở xăng, dầu, khí đốt, chất nổ' },
    { 'code': '300025', 'name': 'Tài xế xe chở tiền ' },
    { 'code': '300026', 'name': 'Tài xế xe cứu hỏa, xe cứu thương' },
    { 'code': '300027', 'name': 'Tài xế, phụ xe khách đường dài, liên tỉnh' },
    { 'code': '300028', 'name': 'Thám tử' },
    { 'code': '300029', 'name': 'Thi công công trình thủy lợi' },
    { 'code': '300030', 'name': 'Thợ điện ' },
    { 'code': '300031', 'name': 'Thợ hàn' },
    { 'code': '300032', 'name': 'Thợ lặn' },
    { 'code': '300033', 'name': 'Thợ lắp kính công trình' },
    { 'code': '300034', 'name': 'Thợ nhôm' },
    { 'code': '300035', 'name': 'Thợ sắt/ thép' },
    { 'code': '300036', 'name': 'Thợ sơn' },
    { 'code': '300037', 'name': 'Thợ sửa xe' },
    { 'code': '300038', 'name': 'Thợ sửa xe gắn máy, ô tô' },
    { 'code': '300039', 'name': 'Thợ xây, thợ hồ, phụ hồ' },
    { 'code': '300040', 'name': 'Tra xét, bắt buôn lậu' },
    { 'code': '300041', 'name': 'Vận đông viên chuyên nghiệp' },
    { 'code': '300042', 'name': 'Nhân viên bán vật liệu xây dựng' },
    { 'code': '300043', 'name': 'Công nhân vệ sinh đường phố' },
    { 'code': '300044', 'name': 'Nhân viên phòng chống cháy rừng ' },
    { 'code': '300045', 'name': 'Hái dừa, hái cau' },
    { 'code': '300046', 'name': 'Nuôi ong' },
    { 'code': '300047', 'name': 'Giám sát công trình thủy lợi' },
    { 'code': '300048', 'name': 'Công nhân bảo trì, thi công, sửa chữa đường sắt ' },
    { 'code': '300049', 'name': 'Lái tàu' },
    { 'code': '300050', 'name': 'Nhân viên soát vé trên tàu ' },
    { 'code': '300051', 'name': 'Nhân viên phục vụ trên tàu' },
    { 'code': '300052', 'name': 'Công nhân bảo trì, sửa chữa khoang tàu' },
    { 'code': '300053', 'name': 'Công nhân bốc dỡ hàng hóa trên tàu ' },
    { 'code': '300054', 'name': 'Nhân viên tiếp nhiên liệu' },
    { 'code': '300055', 'name': 'Công nhân lắp đặt thang máy ' },
    { 'code': '300056', 'name': 'Xây dựng' },
    { 'code': '300057', 'name': 'Bảo trì văn phòng ' },
    { 'code': '300058', 'name': 'Sữa chữa/ đóng tàu thuyền ' },
    { 'code': '300059', 'name': 'Khai thác khoáng sản ' },
    { 'code': '300060', 'name': 'Thợ tiện ' },
    { 'code': '300061', 'name': 'Thợ khoang' },
    { 'code': '300062', 'name': 'Thợ đánh bóng ' },
    { 'code': '300063', 'name': 'Thợ nồi hơi' },
    { 'code': '300064', 'name': 'Công nhân luyện/ cán/ đúc kim loại ' },
    { 'code': '300065', 'name': 'Luyện than' },
    { 'code': '300066', 'name': 'Công nhân phòng đông lạnh' },
    { 'code': '300067', 'name': 'Điêu khắc ' },
    { 'code': '300068', 'name': 'Huấn luyện viên sở thú, rạp xiếc ' },
    { 'code': '300069', 'name': 'Tiếp viên (phục vụ hộp đêm, quán bar, karaoke)' },
    { 'code': '300070', 'name': 'Cứu hộ hồ bơi/ bãi biển ' },
    { 'code': '300071', 'name': 'Cảnh sát giao thông ' },
    { 'code': '300072', 'name': 'Dân quân tự vệ, dân phòng' },
    { 'code': '300073', 'name': 'Dạy lái xe ' },
    { 'code': '300074', 'name': 'Học sinh trường xiếc, thể thao, nghệ thuật' },
    { 'code': '300075', 'name': 'Khai thác đá ' },
    { 'code': '300076', 'name': 'Nhà khoa học hạt nhân ' },
    { 'code': '300077', 'name': 'Người huấn luyện chó ' },
    { 'code': '300078', 'name': 'Nhân viên làm việc tại quán bar, vũ trường ' },
    { 'code': '300079', 'name': 'Nhân viên sòng bạc ' },
    { 'code': '300080', 'name': 'Nhân viên múa cột, vũ nữ' },
    { 'code': '300081', 'name': 'Vận tải ' },
    { 'code': '300082', 'name': 'Quản lý công trình' },
    { 'code': '300083', 'name': 'Mài' },
    { 'code': '300084', 'name': 'Bảo trì lò hơi' },
    { 'code': '300085', 'name': 'Bộ phận chặt' },
    { 'code': '300086', 'name': 'Công nhân cắt ' },
    { 'code': '300087', 'name': 'Công nhân khuôn mẫu ' },
    { 'code': '300088', 'name': 'Công nhân nghiền' },
    { 'code': '300089', 'name': 'Công nhân đứng máy' },
    { 'code': '300090', 'name': 'Giám sát công trình' }
]

const initial = {
    "idx": 0,
    "id": 0,
    "orderNo": "",
    "fullName": "",
    "idCard": "",
    "dob": "",
    "gender": 1,
    "phone": "",
    "email": "",
    "national": "",
    "address": "",
    "occupation": "",
    "national": "",
    "relationship": "",
    "isForeigner": false,
    "createdBy": "",
    "createdDate": "",
    "modifiedBy": "",
    "modifiedDate": "",
    "isInsuredPerson": false,
    "action": "new", //[new, edit]
};

const initialAlert = {
    is_show: false,
    title: "",
    content: "",
    variant: "danger"
};
function PersonalInfo(props) {
    const [state, setState] = useState(initial);
    const { onClose, data: valueProp, show, ...other } = props;
    const pkg_props = {}
    const handle_close = () => {
        setSelected((prev) => ({ ...prev, showPackage: false }))
        onClose(false, null);
        /*if (valueProp !== null && valueProp.action === "edit") {
            onClose(false, valueProp);
        } else {
        }*/
    }
    const [{ stateError, stateErrorMsg }, setStateError] = useState({
        stateError: {
            relationship: false,
            fullName: false,
            idCard: false,
            dob: false,
            national: false,
            address: false,
            occupation: false,
            paiPackage: false,
            phone: false,
            email: false,
        },
        stateErrorMsg: {
            relationship: "",
            fullName: "",
            idCard: "",
            dob: "",
            national: "",
            address: "",
            occupation: "",
            paiPackage: "",
            phone: "",
            email: "",
        }
    });
    const [{ lst_nation, lst_occupation, lst_pkg, lst_person, lst_relationship }, setList] = useState({
        lst_nation: Nationality,
        lst_occupation: Occupations,
        lst_person: [],
        lst_pkg: [],
        lst_relationship: [{ 'code': '1', 'name': 'Bản thân' },
        { 'code': '2', 'name': 'Vợ/Chồng' },
        { 'code': '3', 'name': 'Con cái' },
        { 'code': '4', 'name': 'Bố/Mẹ' },
        { 'code': '5', 'name': 'Anh/Chị/Em Ruột' },
        { 'code': '6', 'name': 'Người có quan hệ nuôi dưỡng, cấp dưỡng' },
        ]
    })
    const [alertData, setAlertData] = useState(initialAlert);
    const [{ dob, maxDOB, minDOB, nationalSel, occupationSel, isBusiness, packageSel, showPackage }, setSelected] = useState({
        dob: null,
        minDOB: addYears(new Date(), -65),
        maxDOB: addMonths(new Date(), -6),
        nationalSel: [],
        occupationSel: [],
        isBusiness: false,
        packageSel: [],
        showPackage: show,
    })
    const handle_change = (e) => {
        const { name, value } = e.target;
        var _val = value;
        var _state = { ...state };
        setState((prevData) => ({
            ...prevData,
            [name]: _val,
        }));
        if (name === "relationship") {
            if (_val === "1") {
                setState((prevData) => ({
                    ...prevData,
                    fullName: other.masterData.veh_customer,
                    gender: other.masterData.veh_gender,
                    idCard: other.masterData.veh_customer_idcard,
                    dob: other.masterData.veh_customer_dob,
                    occupation: JSON.stringify([{ code: other.masterData.veh_occupation_code, name: other.masterData.veh_occupation_name }]),
                    national: JSON.stringify([{ code: other.masterData.veh_national_code, name: other.masterData.veh_national_name }]),
                }));
                setSelected((prev) => ({
                    ...prev,
                    dob: CommonHelper.parseStringToDate(other.masterData.veh_customer_dob, "yyyyMMdd"),
                    nationalSel: [{ code: other.masterData.veh_national_code, name: other.masterData.veh_national_name }],
                    occupationSel: [{ code: other.masterData.veh_occupation_code, name: other.masterData.veh_occupation_name }],
                }))
            } else {
                if (_state.relationship == 1) {
                    setState((prevData) => ({
                        ...prevData,
                        fullName: "",
                        //gender: other.masterData.veh_gender,
                        idCard: "",
                        dob: "",
                        occupation: "",
                        national: "",
                    }));
                    setSelected((prev) => ({
                        ...prev,
                        dob: null,
                        nationalSel: [],
                        occupationSel: [],
                    }))
                }
            }

            reset_validate();
        }
        validate_control(e);
    }
    const reset_validate = () => {
        var _obj = { ...stateError };
        Object.keys(_obj).map((item) => {
            _obj[item] = false;
        })
        setStateError((prev) => ({
            ...prev,
            stateError: _obj
        }))
    }
    const validate_control = (e) => {
        const { name, value } = e.target;
        var _obj = { ...stateError };
        var _objMsg = { ...stateErrorMsg };
        var _arr = [];
        Object.keys(_obj).map((item) => {
            _arr.push(item);
        })
        if (_arr.indexOf(name) >= 0) {
            _obj[name] = false;
            _objMsg[name] = "";
            if (CommonHelper.is_empty(value)) {
                _obj[name] = true;
                _objMsg[name] = "required";
            }
            //console.log(_objMsg)
            setStateError((prev) => ({
                ...prev,
                stateError: _obj,
                stateErrorMsg: _objMsg,
            }))
        }
    }
    const validate_idcard = (e) => {
        const { name, value } = e.target;
        var _flag = CommonHelper.check_idcard_valid(value);
        setStateError((prev) => ({
            ...prev,
            stateError: { ...stateError, [name]: !_flag },
            stateErrorMsg: { ...stateErrorMsg, [name]: !_flag ? "invalid" : "" },
        }))
    }
    const change_dob = (val) => {
        setSelected((prev) => ({
            ...prev,
            dob: val,
        }));
        var _dob = "";
        if (val != null) {
            _dob = CommonHelper.formatToYYYYMMDD(val);
        }
        setState((prev) => ({
            ...prev,
            dob: _dob,
        }))
        setStateError((prev) => ({
            ...prev,
            stateError: { ...stateError, dob: val == null },
            stateErrorMsg: { ...stateErrorMsg, dob: (val == null ? "required" : "") },
        }))
    };
    const change_raw_dob = (e) => {
        var _val = e.target.value;
        if (CommonHelper.is_not_empty(_val)) {
            _val = _val.replace(/[^0-9]/g, "");
            if (_val.length === 8) {
                var _dob = CommonHelper.parseStringToDate(_val, "ddMMyyyy");
                if (_dob >= minDOB && _dob <= maxDOB) {
                    setSelected((prev) => ({
                        ...prev,
                        dob: _dob,
                    }));
                } else {

                }
            }
        }
    }
    const handle_radio_check = (e) => {
        const { name, value } = e.target;
        setState((prevData) => ({
            ...prevData,
            [name]: name === "gender" ? (parseInt(value)) : value,
        }));
    };
    const change_nation = (val) => {
        if (val.length > 0) {
            setState((prevData) => ({
                ...prevData,
                national: JSON.stringify(val),
            }));
        } else {
            setState((prevData) => ({
                ...prevData,
                national: null,
            }));
        }
        setSelected((prev) => ({
            ...prev,
            nationalSel: val
        }))
        setStateError((prev) => ({
            ...prev,
            stateError: { ...stateError, national: val.length == 0 },
            stateErrorMsg: { ...stateErrorMsg, national: (val.length == 0 ? "required" : "") },
        }))
    };
    const change_occupation = (val) => {
        var _code = "";
        if (val.length > 0) {
            _code = val[0].code;
            if (val[0].exception) {
                setAlertData((prev) => ({
                    ...prev,
                    title: "Lỗi",
                    content: "\"" + val[0].name + "\" thuộc ngành nghề không được bảo hiểm. Vui lòng chọn ngành nghề khác!",
                    is_show: true,
                    variant: "danger"
                }));
                /*setStateError((prev) => ({
                    ...prev,
                    occupation: true,
                }))*/
            }
            setState((prevData) => ({
                ...prevData,
                occupation: JSON.stringify(val),
            }));
        } else {
            setState((prevData) => ({
                ...prevData,
                occupation: null,
            }));
        }
        setSelected((prev) => ({
            ...prev,
            occupationSel: val
        }))
        setStateError((prev) => ({
            ...prev,
            stateError: { ...stateError, occupation: val.length == 0 },
            stateErrorMsg: { ...stateErrorMsg, occupation: (val.length == 0 ? "required" : "") },
        }))
    };
    const validate_email_format = (e) => {
        const { name, value } = e.target;
        var flag = false;
        if (!CommonHelper.validate_email_format(value)) {
            setAlertData((prev) => ({
                ...prev,
                title: "Lỗi",
                content: "Email không đúng định dạng. Vui lòng kiểm tra lại!",
                is_show: true,
                variant: "danger"
            }))
            flag = true;
        }
        /*setStateError((prev) => ({
            ...prev,
            veh_email: flag,
        }))*/
    };
    const validate_form = () => {
        var flag = true;
        var _data = { ...state };
        var _obj = { ...stateError };
        var _objMsg = { ...stateErrorMsg };
        var _arr = [];
        Object.keys(_obj).map((item) => {
            //_arr.push(item);
            if (_obj[item]) {
                flag = false;
            }
        })
        if (CommonHelper.is_empty(_data.relationship)) {
            flag = false;
            _obj.relationship = true;
            _objMsg.relationship = "required";
        }
        if (CommonHelper.is_empty(_data.fullName)) {
            flag = false;
            _obj.fullName = true;
            _objMsg.fullName = "required";
        }
        if (CommonHelper.is_empty(_data.idCard)) {
            flag = false
            _obj.idCard = true;
            _objMsg.idCard = "required";
        }
        if (occupationSel.length === 0) {
            flag = false
            _obj.occupation = true;
            _objMsg.occupation = "required";
        }
        if (nationalSel.length === 0) {
            flag = false
            _obj.national = true;
            _objMsg.national = "required";
        }
        if (dob === null) {
            flag = false
            _obj.dob = true;
            _objMsg.dob = "required";
        }
        var _arr = other.list;
        if (_arr.length > 0) {
            _arr.forEach((item) => {
                if (_data.action === "new") {
                    if (item.fullName === _data.fullName) {
                        flag = false;
                        _obj.fullName = true;
                        _objMsg.fullName = "Tên người được bảo hiểm đã tồn tại trong danh sách. Vui lòng kiểm tra lại!";
                        /*setAlertData((prev) => ({
                            ...prev,
                            title: "Lỗi",
                            content: "Tên người được bảo hiểm đã tồn tại trong danh sách. Vui lòng kiểm tra lại!",
                            is_show: true,
                            variant: "danger"
                        }))*/
                    }
                    if (item.idCard === _data.idCard) {
                        flag = false;
                        _obj.idCard = true;
                        _objMsg.idCard = "Số CMND/CCND/số định danh đã tồn tại trong danh sách. Vui lòng kiểm tra lại!";
                        /*setAlertData((prev) => ({
                            ...prev,
                            title: "Lỗi",
                            content: "Số CMND/CCND/số định danh đã tồn tại trong danh sách. Vui lòng kiểm tra lại!",
                            is_show: true,
                            variant: "danger"
                        }))*/
                    }
                    if (_data.relationship === "1" && _data.relationship == item.relationship) {
                        flag = false;
                        _obj.relationship = true;
                        _objMsg.relationship = "\"Bản thân\" đã tồn tại trong danh sách. Vui lòng kiểm tra lại!";
                        /*setAlertData((prev) => ({
                            ...prev,
                            title: "Lỗi",
                            content: "\"Bản thân\" đã tồn tại trong danh sách. Vui lòng kiểm tra lại!",
                            is_show: true,
                            variant: "danger"
                        }))*/
                    }
                    //return flag
                }
            })
        }
        if (!flag) {
            setStateError((prev) => ({
                ...prev,
                stateError: _obj,
                stateErrorMsg: _objMsg,
            }))
        }
        return flag
    }
    const handle_save = () => {
        var flag = validate_form();
        if (flag) {
            var _data = { ...state };
            setSelected((prev) => ({ ...prev, showPackage: false }))
            onClose(false, _data);
        }
    }
    const select_pkg = (e) => {
        var objPackage = {};
        console.log(e);
        setSelected((prev) => ({
            ...prev,
            packageSel: e
        }));
        var _premium = 0;
        if (e.length > 0) {
            _premium = e[0].premium;
            objPackage = {
                code: e[0].code,
                pkg_name: e[0].title,
                pkg_location: e[0].location,
                si: e[0].death_si,
                si_medical_fee: e[0].medical_fee,
                si_daily_allowance: e[0].daily_allowance,
                si_missing: e[0].missing,
                si_funeral_allowance: e[0].funeral_allowance,
                si_sos: e[0].sos_service,
                premium: e[0].premium,
                conditions: e[0].condition,
                //poison: e.toxic,
            };
        }
        setState((prev) => ({
            ...prev,
            objPackage,
            premium: _premium,
        }))
        setStateError((prev) => ({
            ...prev,
            stateError: { ...stateError, paiPackage: e.length == 0 },
            stateErrorMsg: { ...stateErrorMsg, paiPackage: (e.length == 0 ? "required" : "") },
        }))
    }
    const set_opt_pkg = (lst) => {
        if (lst != null && lst.length > 0) {
            lst.forEach((item) => {
                item["name"] = item.title;
            })
        }
        setList((prev) => ({
            ...prev,
            lst_pkg: lst,
        }))
    }
    useEffect(() => {
        if (show) {
            var _nationSel = [];
            var _occSel = [];
            var _dob = null;
            var _def_pkg = []
            //console.log(other.masterData);
            //console.log(other.list_pkg);
            //console.log(valueProp);
            var _lst_person = other.list;
            if (_lst_person != null && _lst_person.length > 0) {
                var _idx = _lst_person.findIndex(x => x.relationship === "1");
                var _lst_relationship = [...lst_relationship];
                if (_idx >= 0) {
                    _lst_relationship = _lst_relationship.filter(x => x.code != "1");
                }
                setList((prev) => ({
                    ...prev,
                    lst_relationship: _lst_relationship,
                    lst_person: _lst_person
                }))
            }
            set_opt_pkg(other.list_pkg);
            if (CommonHelper.is_not_empty(valueProp.occupation)) {
                _occSel = JSON.parse(valueProp.occupation);
            }
            if (CommonHelper.is_not_empty(valueProp.national)) {
                _nationSel = JSON.parse(valueProp.national);
            }
            if (valueProp.objPackage != null && CommonHelper.is_not_empty(valueProp.objPackage.code)) {
                _def_pkg = other.list_pkg.filter(x => x.code == valueProp.objPackage.code);
            }
            if (valueProp.action === "edit") {
                _dob = CommonHelper.parseStringToDate(valueProp.dob, "yyyyMMdd");
            }
            //console.log(valueProp);
            setState((prev) => ({
                ...prev,
                "idx": valueProp.idx,
                "id": valueProp.id,
                "orderNo": valueProp.orderNo,
                "fullName": valueProp.fullName,
                "idCard": valueProp.idCard,
                "dob": valueProp.dob,
                "gender": valueProp.gender,
                "phone": valueProp.phone,
                "email": valueProp.email,
                "national": valueProp.national,
                "address": valueProp.address,
                "relationship": valueProp.relationship + "",
                "occupation": valueProp.occupation,
                "isForeigner": valueProp.isForeigner,
                "premium": valueProp.objPackage.premium,
                "action": valueProp.action,
                "objPackage": valueProp.objPackage,
            }))
            setSelected((prev) => ({
                ...prev,
                isBusiness: other.masterData.veh_business === "Y",
                nationalSel: _nationSel,
                occupationSel: _occSel,
                packageSel: _def_pkg,
                dob: _dob,
            }))
            setStateError((prev) => ({
                ...prev,
                stateError: {
                    relationship: false,
                    fullName: false,
                    idCard: false,
                    dob: false,
                    national: false,
                    address: false,
                    occupation: false,
                    paiPackage: false,
                    phone: false,
                    email: false,
                },
                stateErrorMsg: {
                    relationship: "",
                    fullName: "",
                    idCard: "",
                    dob: "",
                    national: "",
                    address: "",
                    occupation: "",
                    paiPackage: "",
                    phone: "",
                    email: "",
                }
            }))
        }
    }, [showPackage, valueProp])
    return (
        <>
            <div className={'bg-modal-bottom-sheet' + (show ? " showbs" : "")}>
                <div className={'bg-bottom-sheet' + (show ? " showbs" : "")} >
                    <div className='bottom-sheet-header'>
                        <div className='close-box'>
                            <button type="button" className="btn-close" aria-label="Close" onClick={handle_close}></button>
                        </div>
                        <span className='bottom-sheet-header-title'>{other.title}</span>
                    </div>
                    <div className='bottom-sheet-body'>
                        <div className='panel-form' style={{ marginTop: "10px" }}>
                            <div className='container'>
                                {!isBusiness && (
                                    <div className='row'>
                                        <div className='col-sm-12'>
                                            <label className="form-label required">
                                                Quan hệ với người mua bảo hiểm
                                            </label>
                                            <div className='col-sm-12'>
                                                <select className={'form-select form-select-sm ' + (stateError.relationship ? "error" : "")} name='relationship'
                                                    onChange={handle_change} value={state.relationship}>
                                                    <option value="" className='d-none'></option>
                                                    {lst_relationship.map((item, i) => {
                                                        return (
                                                            <option value={item.code}>{item.name}</option>
                                                        )
                                                    })}
                                                    {/* <option value="1">Bản thân</option>
                                                    <option value="2">Vợ/Chồng</option>
                                                    <option value="3">Con cái</option>
                                                    <option value="4">Bố/Mẹ</option>
                                                    <option value="5">Anh/Chị/Em Ruột</option>
                                                    <option value="6">Người nuôi dưỡng</option> */}
                                                </select>
                                                <ValidateMessage isShow={stateError.relationship} msgType={stateErrorMsg.relationship} />
                                            </div>
                                        </div>
                                    </div>
                                )}
                                <div className='row mt-2'>
                                    <div className='col-sm-6'>
                                        <div className='row'>
                                            <label className="form-label required">
                                                Họ Tên
                                            </label>
                                            <div className='col-sm-12'>
                                                <TextInput value={state.fullName} name="fullName" onChange={handle_change}
                                                    className={stateError.fullName ? "error" : ""} />
                                                <ValidateMessage isShow={stateError.fullName} msgType={stateErrorMsg.fullName} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-sm-6'>
                                        <div className='row'>
                                            <label className="form-label required">
                                                Số CMND/ CCCD/ Định danh/ Hộ chiếu
                                            </label>
                                            <div className='col-sm-12'>
                                                <TextInput value={state.idCard}
                                                    name="idCard"
                                                    onBlur={validate_idcard}
                                                    onChange={handle_change}
                                                    className={stateError.idCard ? "error" : ""} />
                                                <ValidateMessage isShow={stateError.idCard} msgType={stateErrorMsg.idCard} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='row mt-2'>
                                    <div className='col-sm-6'>
                                        <div className='row'>
                                            <label className="form-label required">
                                                Ngày sinh
                                            </label>
                                            <div className='col-sm-12'>
                                                <ShortDateInput
                                                    selected={dob}
                                                    minDate={minDOB}
                                                    maxDate={maxDOB}
                                                    onChange={change_dob} onChangeRaw={change_raw_dob}
                                                    className={stateError.dob ? "error" : ""} />
                                                <ValidateMessage isShow={stateError.dob} msgType={stateErrorMsg.dob} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-sm-6'>
                                        <div className='row'>
                                            <label className="form-label required">
                                                Giới tính
                                            </label>
                                            <div className='col-sm-12'>
                                                <div className='d-flex'>
                                                    <div className='mr15px'>
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="radio" name="gender" id={`radio-per-gender-1`} defaultValue={"1"}
                                                                checked={state.gender === 1} value="1" onChange={handle_radio_check} />
                                                            <label className="form-check-label" htmlFor={`radio-per-gender-1`}>
                                                                Nam
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className='mr15px'>
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="radio" name="gender" id={`radio-per-gender-2`}
                                                                checked={state.gender === 2} value="2" onChange={handle_radio_check} />
                                                            <label className="form-check-label" htmlFor={`radio-per-gender-2`}>
                                                                Nữ
                                                            </label>
                                                        </div>
                                                    </div>
                                                    {/* <div className='mr15px'>
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="radio" name="gender" id={`radio-gender-0`} defaultValue={"0"}
                                                                checked={state.gender === 0} value="0" onChange={handle_radio_check} />
                                                            <label className="form-check-label" htmlFor={`radio-gender-0`}>
                                                                Khác
                                                            </label>
                                                        </div>
                                                    </div> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='row mt-2'>
                                    <div className='col-sm-6'>
                                        <div className='row'>
                                            <label className='form-label required'>Quốc tịch</label>
                                            <div className='col-sm-12'>
                                                <AutoComplete
                                                    id="national"
                                                    options={lst_nation}
                                                    onChange={change_nation}
                                                    selected={nationalSel}
                                                    placeholder={"Quốc tịch"}
                                                    className={stateError.national ? "error" : ""} />
                                                <ValidateMessage isShow={stateError.national} msgType={stateErrorMsg.national} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-sm-6'>
                                        <div className='row'>
                                            <label className='form-label required'>Công việc</label>
                                            <div className='col-sm-12'>
                                                <AutoComplete
                                                    id="veh_occupation"
                                                    options={lst_occupation}
                                                    onChange={change_occupation}
                                                    selected={occupationSel}
                                                    placeholder={"Công việc"}
                                                    className={stateError.occupation ? "error" : ""} />
                                                <ValidateMessage isShow={stateError.occupation} msgType={stateErrorMsg.occupation} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='row mt-2'>
                                    <label className='form-label required'>Gói bảo hiểm</label>
                                    <div className='col-sm-6'>
                                        <AutoComplete
                                            id="veh_pai_pkg"
                                            renderMenuItemChildren={(option) => (
                                                <>
                                                    <div >
                                                        <b>{option.name}</b>
                                                    </div>
                                                    <div>
                                                        <small>Phí (VND/năm): {CommonHelper.format_currency_vnd_style(option.premium)}</small>
                                                    </div>
                                                </>
                                            )}
                                            className={stateError.paiPackage ? "error" : ""}
                                            options={lst_pkg}
                                            selected={packageSel}
                                            onChange={select_pkg} />
                                        <ValidateMessage isShow={stateError.paiPackage} msgType={stateErrorMsg.paiPackage} />
                                    </div>
                                    <div className='col-sm-6'>
                                        <label className='form-label'>
                                            {packageSel.length > 0 && (
                                                <>
                                                    {"Phí (VND/năm): " + CommonHelper.format_currency_vnd_style(packageSel[0].premium)}
                                                </>
                                            )}
                                        </label>
                                    </div>
                                </div>
                                <div className='row mt-3'>
                                    <div className='text-center'>
                                        <button className="btn btn-warning" onClick={handle_close}>{"Hủy"}</button>
                                        <button className="btn btn-primary ms-1" onClick={handle_save}>{"Lưu"}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <AlertBox data={alertData} onChange={(e) => { setAlertData((prev) => ({ ...prev, is_show: e })) }} />
            {/* <ModalPackage onClose={() => { setSelected((prev) => ({ ...prev, showPackage: false })) }} show={showPackage} list={lst_pkg} /> */}
        </>
    )
}

export default PersonalInfo

PersonalInfo.propTypes = {
    onClose: PropTypes.func.isRequired,
    show: PropTypes.bool.isRequired,
    data: PropTypes.object.isRequired,
}