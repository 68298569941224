import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

function PaiProgressBar(props) {
    const { onClick, step, user, ...other } = props;
    const [{ volume, curr_step }, setSelected] = useState({
        volume: 0,
        curr_step: 0,
    })
    useEffect(() => {

        setSelected((prev) => ({
            ...prev,
            curr_step: step,
            volume: ((step - 1) * 100 / 3)
        }))

    }, [step])
    return (
        <>
            <div className='progress' style={{ height: "5px" }}>
                <div className='progress-bar' role="progressbar" style={{ width: volume + "%" }}
                    aria-valuenow={volume} aria-valuemin={0} aria-valuemax={100}></div>
            </div>
            <div className='d-flex position-absolute w-100' style={{ alignItems: "center", top: "-8px" }}>
                <div className='w-1per3'>
                    <div className='progress-circle active'></div>
                </div>
                <div className='w-1per3'>
                    <div className={'progress-circle ' + (curr_step >= 2 ? "active" : "")}></div>
                </div>
                <div className='w-1per3'>
                    <div className={'progress-circle ' + (curr_step >= 3 ? "active" : "")}></div>
                    <div className='progress-circle position-absolute end-0 top-0'></div>
                </div>
            </div>
            <div className='d-flex mt-1'>
                <div className='w-25'><span className='font-semibold' style={{ marginLeft: "-32px" }}>Gói bảo hiểm</span></div>
                <div className='w-25'><span className={curr_step >= 2 ? "font-semibold" : ""} style={{ marginLeft: "30px" }}>Yêu cầu bảo hiểm</span></div>
                <div className='w-25 position-relative'>
                    <span className={'position-absolute ' + (curr_step >= 4 ? "font-semibold" : "")} style={{ right: "-30px" }}>Xác nhận thanh toán</span>
                </div>
                <div className='w-25 position-relative'>
                    <span className={'position-absolute ' + (curr_step >= 5 ? "font-semibold" : "")} style={{ right: "-30px" }}>Thanh toán</span>
                </div>
            </div>
        </>
    )
}

PaiProgressBar.propTypes = {
    onClick: PropTypes.func.isRequired,
}

export default PaiProgressBar
