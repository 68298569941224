import axios from "axios";
import Authentication from "./authApi";
import CommonHelper from "../helpers/common";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT,
});

api.interceptors.request.use(
  async (config) => {
    var token = await CommonHelper.get_token();
    if (config.method === "get") {
      config.headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/x-www-form-urlencoded",
      };
    } else if (config.method === "post" || config.method === "put") {
      config.headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };
    }

    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => {
    if (response && response.data) {
      return response.data;
    }
    return response;
  },
  (error) => {
    console.log(error);
    var _result = { code: 500, msg: error.message };
    if (error.response != null) {
      _result.code = error.response.status;
      if (_result.code === 401) {
        Authentication.reset_token();
        //window.location.reload(false);
      }
    }
    return _result;
    //throw error;
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    //return Promise.reject(error);
  }
);

export default api;
