import React, { useState, useEffect } from 'react';
import qrCodeApi from '../../api/qrcodeApi';
import { FaPhoneAlt } from "react-icons/fa";
import { AiOutlineMail } from "react-icons/ai";
import { TbWorldWww } from "react-icons/tb";
import { FaUserPlus } from "react-icons/fa";
import { FaBriefcase } from "react-icons/fa";
import { MdLocationOn } from "react-icons/md";
import { FaCopy } from "react-icons/fa";
import DownloadInfoModal from './component/modal';
import AlertBox from '../../components/alert';
import { useParams } from "react-router-dom";
import CommonHelper from '../../helpers/common';
import FubonLogo from "../../assets/images/fubon_pnt_logo.png";
import Fubon from "../../assets/images/fubon.png";
import { FiLayout } from "react-icons/fi";
import "./index.css";

const initialAlert = {
    is_show: false,
    title: "",
    content: "",
    variant: "danger"
};

const QRCode = () => {
    const [showModal, setShowModal] = useState(false);
    const [employee, setEmployee] = useState(null);
    const [alertData, setAlertData] = useState(initialAlert);
    const { hash } = useParams();
    const [block, setBlock] = useState(true);
    const [isEsignCard, setIsEsignCard] = useState(false);
    const [logo_namecard, setLogoNamecard] = useState(FubonLogo);

    useEffect(() => {
        if (hash) {
            fetchEmployee();
        }
    }, [hash]);

    const fetchEmployee = async () => {
        try {
            setBlock(false);
            const res = await qrCodeApi.get_employee_hashkey(hash);
            if (res.code !== 200) {
                // alert("Không khớp mã hõa bảo mật!!!");
                // console.error("Không khớp mã hõa!!!");
                return;
            }
            const convertToObject = JSON.parse(res.data);
            const _data = convertToObject;
            const response = await qrCodeApi.get_employee_by_id(_data.EMP_CODE);
            if (response.code === 200) {
                const res_logo = await fetchImage(JSON.parse(response.data).ImagePath);
                setLogoNamecard(res_logo);
            }
            const responseData = JSON.parse(response.data);
            setEmployee(responseData);
        } catch (error) {
            setBlock(true);
            console.error("Error fetching employee:", error);
        }
    };

    const handleOpenModal = () => {
        setShowModal(true);
    };

    const handleModalClose = () => {
        setShowModal(false);
    };

    const handleAddToContacts = () => {
        if (window.confirm("Do you want to add this contact to your contacts?")) {
            const vCardData = `
                BEGIN:VCARD
                VERSION:3.0
                FN:${employee.EMP_FULL_NAME}
                ORG:Fubon Insurance Viet Name Co., Ltd.
                TITLE:${employee.EMP_POSITION_1}
                TEL;TYPE=WORK,VOICE:${employee.EMP_MOBILE_PHONE}
                EMAIL:${employee.EMP_EMAIL}
                END:VCARD
            `;

            const blob = new Blob([vCardData], { type: 'text/vcard' });
            const url = URL.createObjectURL(blob);

            const link = document.createElement('a');
            link.href = url;
            link.download = `${employee.EMP_FULL_NAME}.vcf`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    };

    const formatPhoneNumber = (internationalNumber) => {
        internationalNumber = internationalNumber.replace(/\./g, '');

        if (internationalNumber.startsWith("+84")) {
            return '0' + internationalNumber.slice(3);
        }
        return internationalNumber;
    }

    const fetchImage = async (imagePath) => {
        try {
            const response = await qrCodeApi.get_namecard_image_by_url(imagePath);
            console.log("fetchImage", response, Fubon)
            // const blob = await response.data;
            const blob = response;
            return URL.createObjectURL(blob) || Fubon;
        } catch (error) {
            console.error(error);
            return Fubon;
        }
    };


    return (
        hash && (hash.includes("VN0") || hash.includes("KTV")) ? (
            <>
                <h1
                    style={{
                        textAlign: 'center',
                        alignItems: 'center'
                    }}
                >
                    Not Found!!!
                </h1>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}
                >
                    <a
                        style={{
                            alignItems: 'center',
                            textAlign: 'center'
                        }}
                        href='https://fubonins.com.vn/'
                        className='btn btn-primary'
                    >
                        Trang chủ
                    </a>
                </div>
            </>
        ) : (!block &&
            <>
                {
                    !isEsignCard ? (
                        <>
                            <div
                                className="container-fluid body"
                                style={{ backgroundColor: '#f7f7f7' }}
                            >
                                <div className="row">
                                    <div className="qr-code px-0 col-md-6 col-sm-12 offset-md-3">
                                        <div className="item-header">
                                            <div className="px-0 w-100">
                                                <div className="text-center qr-card-wrapper">
                                                    <div className="p-3 img-wrap">
                                                        <img
                                                            style={{
                                                                border: "4px solid white",
                                                                width: '250px',         
                                                                height: '250px',        
                                                                borderRadius: '50%',    
                                                                objectFit: 'cover'      
                                                            }}
                                                            className="img-body"
                                                            src={logo_namecard}
                                                            alt='Logo Namecard'
                                                        />
                                                    </div>
                                                    <div className="p-4">
                                                        <h6
                                                            style={{ wordWrap: "break-word" }}
                                                            className="text-white qr-card-title"
                                                        >
                                                            {
                                                                employee
                                                                && !CommonHelper.is_empty(employee.EMP_FULL_NAME)
                                                                && employee.EMP_FULL_NAME
                                                            }
                                                        </h6>
                                                        <div className="text-white card-subtitle">
                                                            <p style={{ fontSize: '18px' }}>
                                                                {
                                                                    employee
                                                                    && !CommonHelper.is_empty(employee.EMP_POSITION_2)
                                                                    && employee.EMP_POSITION_2 + " - "
                                                                }
                                                                {
                                                                    employee
                                                                    && !CommonHelper.is_empty(employee.EMP_POSITION_1)
                                                                    && employee.EMP_POSITION_1
                                                                }
                                                            </p>
                                                            <p>Fubon Insurance Viet Name Co., Ltd.</p>
                                                        </div>
                                                    </div>
                                                    <div className="fbcard-functions p-3">
                                                        <div className="fbcard-functions-wrapper">
                                                            <a
                                                                href={
                                                                    employee
                                                                    && !CommonHelper.is_empty(employee.EMP_MOBILE_PHONE)
                                                                    && "tel:" + formatPhoneNumber(employee.EMP_MOBILE_PHONE)
                                                                }
                                                                className=" bg-solid "
                                                                style={{ backgroundColor: '#0dcaf0' }}
                                                            >
                                                                <FaPhoneAlt style={{ fontSize: '25px' }} />
                                                                <small className="dynamicText">
                                                                    Phone
                                                                </small>
                                                            </a>
                                                            <a
                                                                href={
                                                                    employee
                                                                    && !CommonHelper.is_empty(employee.EMP_EMAIL)
                                                                    && "mailto:" + employee.EMP_EMAIL
                                                                }
                                                                target="_newEmail"
                                                                className=" bg-solid "
                                                                style={{ backgroundColor: '#0dcaf0' }}
                                                            >
                                                                <AiOutlineMail style={{ fontSize: '25px' }} />
                                                                <small className="dynamicText">
                                                                    Email
                                                                </small>
                                                            </a>
                                                            <a
                                                                href="https://www.fubonins.com.vn/gioi-thieu"
                                                                target="_blank"
                                                                className="border-right-0  bg-solid "
                                                                rel="noreferrer"
                                                                style={{ backgroundColor: '#0dcaf0' }}
                                                            >
                                                                <TbWorldWww style={{ fontSize: '25px' }} />
                                                                <small className="dynamicText">
                                                                    Website
                                                                </small>
                                                            </a>
                                                            <a
                                                                href="#"
                                                                className="border-right-0  bg-solid "
                                                                rel="noreferrer"
                                                                style={{ backgroundColor: '#0dcaf0' }}
                                                                onClick={() => setIsEsignCard(!isEsignCard)}
                                                            >
                                                                <FiLayout style={{ fontSize: '25px' }} />
                                                                <small className="dynamicText">
                                                                    Esign
                                                                </small>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="item-body mt-3 p-3">
                                            <div className="details-row w-100">
                                                <a
                                                    className="details-info"
                                                    href={
                                                        employee
                                                        && !CommonHelper.is_empty(employee.EMP_MOBILE_PHONE)
                                                        && "tel:" + formatPhoneNumber(employee.EMP_MOBILE_PHONE)
                                                    }
                                                >
                                                    <FaPhoneAlt
                                                        style={{
                                                            fontSize: '25px',
                                                            marginRight: '1rem',
                                                            color: '#089a8d'
                                                        }}
                                                    />
                                                    <div>
                                                        <small style={{ color: '#b3b4bb' }}>
                                                            Mobile Phone
                                                        </small>
                                                        <div>
                                                            {
                                                                employee
                                                                && !CommonHelper.is_empty(employee.EMP_MOBILE_PHONE)
                                                                && formatPhoneNumber(employee.EMP_MOBILE_PHONE)
                                                            }
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>
                                            <div className="details-row w-100">
                                                <a
                                                    className="details-info"
                                                    href={
                                                        employee
                                                        && !CommonHelper.is_empty(employee.EMP_EMAIL)
                                                        && "mailto:" + employee.EMP_EMAIL
                                                    }
                                                >
                                                    <AiOutlineMail
                                                        style={{
                                                            fontSize: '25px',
                                                            marginRight: '1rem',
                                                            color: '#089a8d'
                                                        }}
                                                    />
                                                    <div>
                                                        <small style={{ color: '#b3b4bb' }}>
                                                            Email
                                                        </small>
                                                        <div className="text-truncate">
                                                            {
                                                                employee
                                                                && !CommonHelper.is_empty(employee.EMP_EMAIL)
                                                                && employee.EMP_EMAIL
                                                            }
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>
                                            <div className="details-row w-100">
                                                <a className="details-info">
                                                    <FaBriefcase
                                                        style={{
                                                            fontSize: '25px',
                                                            marginRight: '1rem',
                                                            color: '#089a8d'
                                                        }}
                                                    />
                                                    <div>
                                                        <small style={{ color: '#b3b4bb' }}>
                                                            Position
                                                        </small>
                                                        <div>
                                                            {
                                                                employee
                                                                && !CommonHelper.is_empty(employee.EMP_POSITION_1)
                                                                && !CommonHelper.is_empty(employee.EMP_REMARKS_1)
                                                                && employee.EMP_POSITION_1 + " / " + employee.EMP_REMARKS_1
                                                            }
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>
                                            <div className="details-row w-100">
                                                <a
                                                    className="details-info"
                                                    href={`https://www.google.com/maps/dir/?api=1&destination=15F+REE+Tower,+No.9+Doan+Van+Bo+Street,+Dist+4,+Ho+Chi+Minh+City,+Vietnam`}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    <MdLocationOn
                                                        style={{
                                                            fontSize: '40px',
                                                            marginRight: '1rem',
                                                            color: '#089a8d'
                                                        }}
                                                    />
                                                    <div>
                                                        <small style={{ color: '#b3b4bb' }}>
                                                            Address
                                                        </small>
                                                        <div>
                                                            15F REE Tower, No.9 Doan Van Bo Street, Dist 4 HCM City, Viet Nam
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>
                                            <div className="details-row w-100">
                                                <a
                                                    className="details-info"
                                                    href="https://www.fubonins.com.vn/gioi-thieu"
                                                >
                                                    <TbWorldWww
                                                        style={{
                                                            fontSize: '25px',
                                                            marginRight: '1rem',
                                                            color: '#089a8d'
                                                        }}
                                                    />
                                                    <div>
                                                        <small style={{ color: '#b3b4bb' }}>
                                                            Website
                                                        </small>
                                                        <div>
                                                            https://www.fubonins.com.vn/gioi-thieu
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="">
                                            <button
                                                className="btn btn-block btn-lg btn-fbcard d-none d-sm-block bg-solid"
                                                style={{ width: '100%' }}
                                                onClick={() => handleOpenModal()}
                                            >
                                                <FaCopy
                                                    style={{
                                                        fontSize: '25px',
                                                        marginRight: '8px'
                                                    }}
                                                />
                                                <span>
                                                    Copy Card
                                                </span>
                                            </button>
                                            <button
                                                className="btn btn-add btn-fbcard-round d-sm-none bg-solid"
                                                // onClick={() => handleOpenModal()}
                                                onClick={handleAddToContacts}
                                            >
                                                <FaUserPlus
                                                    style={{
                                                        fontSize: '20px',
                                                        marginRight: '8px',
                                                        float: 'left',
                                                        marginTop: '12px'
                                                    }}
                                                />
                                                <span>
                                                    Add to contacts
                                                </span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <footer
                                style={{
                                    textAlign: 'center',
                                    height: '120px',
                                    backgroundColor: '#f7f7f7'
                                }}
                            >
                                <div className="mx-auto">
                                    Powered by Fubon Insurance
                                </div>
                            </footer>
                        </>
                    ) : (
                        <>
                            <div
                                className="ecard-container"
                                style={{ borderLeft: '30px solid rgb(0, 168, 142)' }}
                            >
                                <div className="ecard-header" >
                                    <img
                                        src={FubonLogo}
                                        alt="Fubon Insurance Logo"
                                        className="ecard-logo"
                                        style={{
                                            height: '100%',
                                            width: '70%',
                                            marginLeft: '120px'
                                        }}
                                    />
                                </div>
                                <div className="ecard-body">
                                    <h3 className="ecard-name">
                                        {
                                            (employee && !CommonHelper.is_empty(employee.EMP_FULL_NAME) && employee.EMP_FULL_NAME)
                                            + " / " +
                                            (employee && !CommonHelper.is_empty(employee.EMP_FULLNAME_TW) && employee.EMP_FULLNAME_TW)
                                        }
                                    </h3>
                                    <p className="ecard-position">
                                        {
                                            (employee && !CommonHelper.is_empty(employee.EMP_REMARKS_1) && employee.EMP_REMARKS_1)
                                            + " - " +
                                            (employee && !CommonHelper.is_empty(employee.EMP_REMARKS_2) && employee.EMP_REMARKS_2)
                                        }
                                    </p>
                                    <p className="ecard-department">
                                        {
                                            (employee && !CommonHelper.is_empty(employee.EMP_POSITION_1) && employee.EMP_POSITION_1)
                                            + " - " +
                                            (employee && !CommonHelper.is_empty(employee.EMP_POSITION_2) && employee.EMP_POSITION_2)
                                        }
                                    </p>
                                    <div className="ecard-company-info">
                                        <p style={{ fontWeight: '700' }}>Fubon Insurance Viet Nam Co., Ltd.</p>
                                        <p>15F REE Tower, No.9 Doan Van Bo Street, Dist 4, HCM City, Viet Nam.</p>
                                        <p>
                                            Mobile: <a href={`tel:${employee && !CommonHelper.is_empty(employee.EMP_MOBILE_PHONE) ? formatPhoneNumber(employee.EMP_MOBILE_PHONE) : ''}`}>
                                                {employee && !CommonHelper.is_empty(employee.EMP_MOBILE_PHONE) && employee.EMP_MOBILE_PHONE}
                                            </a>
                                        </p>
                                        <p>
                                            Email: <a href={`mailto:${employee && !CommonHelper.is_empty(employee.EMP_EMAIL) ? employee.EMP_EMAIL : ''}`}>
                                                {employee && !CommonHelper.is_empty(employee.EMP_EMAIL) && employee.EMP_EMAIL}
                                            </a>
                                        </p>
                                    </div>
                                    <div className="">
                                        <button
                                            className="btn btn-block btn-lg btn-fbcard d-none d-sm-block bg-solid"
                                            style={{ width: '100%' }}
                                            onClick={() => handleOpenModal()}
                                        >
                                            <FaCopy
                                                style={{
                                                    fontSize: '25px',
                                                    marginRight: '8px'
                                                }}
                                            />
                                            <span>
                                                Copy Card
                                            </span>
                                        </button>
                                        <button
                                            className="btn btn-add btn-fbcard-round d-sm-none bg-solid"
                                            // onClick={() => handleOpenModal()}
                                            onClick={handleAddToContacts}
                                        >
                                            <FaUserPlus
                                                style={{
                                                    fontSize: '20px',
                                                    marginRight: '8px',
                                                    float: 'left',
                                                    marginTop: '12px'
                                                }}
                                            />
                                            <span>
                                                Add to contacts
                                            </span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <footer
                                style={{
                                    textAlign: 'center',
                                    height: '120px',
                                    backgroundColor: '#f7f7f7'
                                }}
                            >
                                <div className="mx-auto">
                                    Powered by Fubon Insurance
                                </div>
                                <div className='row mt15px'>
                                    <div
                                        className='col-md-12'
                                        style={{
                                            cursor: 'pointer',
                                            fontWeight: '700',
                                            fontSize: '20px',
                                            textDecoration: 'underline',
                                            color: 'blue',
                                        }}
                                        onClick={() => setIsEsignCard(!isEsignCard)}
                                    >
                                        Quay lại
                                    </div>
                                </div>
                            </footer>
                        </>
                    )}
                {showModal && (
                    <DownloadInfoModal
                        showModal={showModal}
                        handleClose={handleModalClose}
                        data={window.location.href}
                    />
                )}
                <AlertBox
                    data={alertData}
                    onChange={(e) => { setAlertData((prev) => ({ ...prev, is_show: e })) }}
                />
            </>
        )
    );
};

export default QRCode;