import api from "./api";
import axios from "axios";
import CommonHelper from "../helpers/common";

const trainingApi = {
    get_candidates: async (id) => {
        var callApi = await api.get(
            "training/get_candidates?course_id=" + id
        );
        if (callApi.code === 401) {
            console.log(callApi);
        }
        return callApi;
    },
    post_candidate: async (model) => {
        if (model != null) {
            var session_id = CommonHelper.get_session_id();
            var data = model;
            data.session_id = session_id;
            var callApi = await api.post(
                "training/post_candidate", data
            );
            if (callApi.code === 401) {
                console.log(callApi);
            }
            return callApi;
        }
    },
    get_examination: async (id) => {
        var callApi = await api.get(
            "training/get_examination_by_id?id=" + id
        );
        if (callApi.code === 401) {
            console.log(callApi);
        }
        return callApi;
    },
    get_questions_data: async (id = 0) => {
        var callApi = await api.get(
            "training/get_questions_data/" + id,
        );
        if (callApi.code === 401) {
            console.log(callApi);
        }
        return callApi;
    },
    get_questions: async (id = 0) => {
        var callApi = await api.get(
            "training/get_questions/" + id
        );
        if (callApi.code === 401) {
            console.log(callApi);
        }
        return callApi;
    },
    post_question: async (model) => {
        if (model != null) {
            var data = model;
            console.log("model", model);
            var callApi = await api.post(
                "training/post_question", data)
            if (callApi.code === 500) {
                console.log(callApi);
            }
            return callApi;
        }
    },
    put_question: async (model) => {
        if (model != null) {
            var data = model;
            console.log("model", model);
            console.table(model);
            var callApi = await api.put(
                "training/put_question", data)
            if (callApi.code === 500) {
                console.log(callApi);
            }
            return callApi;
        }
    },
    delete_question: async (id) => {
        if (id != null) {
            console.log("id", id);
            var callApi = await api.put(
                `training/delete_question/${id}`)
            if (callApi.code === 500) {
                console.log(callApi);
            }
            return callApi;
        }
    },
    get_history_exam: async (month, year, id = 0) => {
        var callApi = await api.get(
            `training/get_history_exam?month=${month}&year=${year}&id=${id}`
        );
        console.log(callApi);
        if (callApi.code === 401) {
            console.log(callApi);
        }
        return callApi;
    },
    get_history_exam_detail: async (month, year, id = 0) => {
        var callApi = await api.get(
            `training/get_history_exam_detail?month=${month}&year=${year}&id=${id}`
        );
        console.log(callApi);
        if (callApi.code === 401) {
            console.log(callApi);
        }
        return callApi;
    },
    get_personal_history_exam: async (identity) => {
        var callApi = await api.get(
            "training/get_personal_history_exam/" + identity
        );
        if (callApi.code === 401) {
            console.log(callApi);
        }
        return callApi;
    },
    get_personal_history_exam_detail: async (identity) => {
        var callApi = await api.get(
            "training/get_personal_history_exam_detail/" + identity
        );
        if (callApi.code === 401) {
            console.log(callApi);
        }
        return callApi;
    },
    login_exam: async (model) => {
        if (model != null) {
            var data = model;
            console.log("model", model);
            var callApi = await api.post(
                "training/login_exam", data)
            if (callApi.code === 500) {
                console.log(callApi);
            }
            return callApi;
        }
    },
    get_topics: async (id = 0) => {
        var callApi = await api.get(
            "training/get_topics/" + id
        );
        if (callApi.code === 401) {
            console.log(callApi);
        }
        return callApi;
    },
    register_exam: async (model) => {
        if (model != null) {
            var data = model;
            console.log("model", model);
            var callApi = await api.post(
                "training/register_exam", data)
            if (callApi.code === 500) {
                console.log(callApi);
            }
            return callApi;
        }
    },
    get_accounts_exam: async (id = 0) => {
        var callApi = await api.get(
            "training/get_accounts_exam/" + id
        );
        if (callApi.code === 401) {
            console.log(callApi);
        }
        return callApi;
    },
    put_account_exam: async (model) => {
        if (model != null) {
            var data = model;
            console.log("model", model);
            console.table(model);
            var callApi = await api.put(
                "training/put_account_exam", data)
            if (callApi.code === 500) {
                console.log(callApi);
            }
            return callApi;
        }
    },
    get_vote_criteria: async (id = 0) => {
        var callApi = await api.get(
            "training/get_vote_criteria/" + id
        );
        if (callApi.code === 401) {
            console.log(callApi);
        }
        return callApi;
    },
    get_vote_criterias: async (id = 0, type) => {
        var callApi = await api.get(
            "training/get_vote_criterias?id=" + id + "&type=" + type
        );
        if (callApi.code === 401) {
            console.log(callApi);
        }
        return callApi;
    },
    get_votes: async (id = 0) => {
        var callApi = await api.get(
            "training/get_votes/" + id
        );
        if (callApi.code === 401) {
            console.log(callApi);
        }
        return callApi;
    },
    post_vote: async (model) => {
        if (model != null) {
            var data = model;
            console.log("model", model);
            var callApi = await api.post(
                "training/post_vote", data)
            if (callApi.code === 500) {
                console.log(callApi);
            }
            return callApi;
        }
    },
    delete_vote: async (id) => {
        if (id != null) {
            console.log("id", id);
            var callApi = await api.put(
                `training/delete_vote/${id}`)
            if (callApi.code === 500) {
                console.log(callApi);
            }
            return callApi;
        }
    },
    post_agent_reg_info: async (model) => {
        if (model != null) {
            var data = model;
            console.log("model", model);
            var callApi = await api.post(
                "training/post_agent_reg_info", data)
            if (callApi.code === 500) {
                console.log(callApi);
            }
            return callApi;
        }
    },
    get_agent_reg_info: async (id = 0) => {
        var callApi = await api.get(
            "training/get_agent_reg_info/" + id
        );
        if (callApi.code === 401) {
            console.log(callApi);
        }
        return callApi;
    },
    delete_agent_reg_info: async (id) => {
        if (id != null) {
            console.log("id", id);
            var callApi = await api.put(
                `training/delete_agent_reg_info/${id}`)
            console.log("callApi", callApi);
            if (callApi.code === 500) {
                console.log(callApi);
            }
            return callApi;
        }
    },
    put_agent_reg_info: async (model) => {
        if (model != null) {
            var data = model;
            console.log("model", model);
            console.table(model);
            var callApi = await api.put(
                "training/put_agent_reg_info", data)
            if (callApi.code === 500) {
                console.log(callApi);
            }
            return callApi;
        }
    },
    get_acc_exam_excel_template: async () => {
        var token = await CommonHelper.get_token();
        axios({
            url: process.env.REACT_APP_API_ENDPOINT + "training/get_acc_exam_excel_template",
            method: 'GET',
            headers: { Authorization: `Bearer ${token}`, },
            responseType: 'blob', // important
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'import_acc_exam.xlsx');
            document.body.appendChild(link);
            link.click();
        });
    },
    import_acc_exam: async (model) => {
        var token = await CommonHelper.get_token();
        var session_id = CommonHelper.get_session_id();
        // create a new FormData object and append the file to it
        const formData = new FormData();
        formData.append("import_acc_exam", model.file);
        formData.append("session_id", session_id);
        var response = await axios.post(process.env.REACT_APP_API_ENDPOINT + "training/import_acc_exam", formData, {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "multipart/form-data",
            },
        })
        return response;
    },
    get_agent_reg_info_excel_template: async () => {
        var token = await CommonHelper.get_token();
        axios({
            url: process.env.REACT_APP_API_ENDPOINT + "training/get_agent_reg_info_template",
            method: 'GET',
            headers: { Authorization: `Bearer ${token}`, },
            responseType: 'blob', // important
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'import_agent_reg_info.xlsx');
            document.body.appendChild(link);
            link.click();
        });
    },
    import_agent_reg_info: async (model) => {
        var token = await CommonHelper.get_token();
        var session_id = CommonHelper.get_session_id();
        // create a new FormData object and append the file to it
        const formData = new FormData();
        formData.append("import_agent_reg_info", model.file);
        formData.append("session_id", session_id);
        var response = await axios.post(process.env.REACT_APP_API_ENDPOINT + "training/import_agent_reg_info", formData, {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "multipart/form-data",
            },
        })
        return response;
    },
}
export default trainingApi;