import React, { useState } from 'react';
import Image1 from "../../../assets/images/dog.jpg";
import Image2 from "../../../assets/images/cat.jpg";
import { FaDog } from "react-icons/fa";
import { FaCat } from "react-icons/fa";
import { FaBan } from "react-icons/fa";
import { Modal } from 'react-bootstrap';

const PetOption = (props) => {
    const { 
        onClick, 
        onSelect, 
        list, 
        pet_type_selected, 
        ...other 
    } = props;
    const [selectedPetType, setSelectedPetType] = useState(pet_type_selected);
    const [showExclusiveModal, setShowExclusiveModal] = useState(false);

    const handle_select = (item) => {
        setSelectedPetType(item);
        onSelect(item);
    };

    return (
        <>
            <section 
                className="container" 
                style={{ 
                    border: '1px solid black', 
                    boxShadow: '10px 10px rgb(131, 219, 193)', 
                    marginBottom: '16px', 
                    borderRadius: "16px" 
                }}
            >
                <h2 
                    className='text-center mt-4' 
                    style={{ color: '#20c997' }}
                >
                    CHỌN LOẠI THÚ CƯNG MUỐN MUA
                </h2>
                <div className="row">
                    <div className="non-carousel-insurance-content">
                        {
                            list.map((item, i) => {
                                return (
                                    <>
                                        <div 
                                            key={i} 
                                            className={`pet-mobile-card package-info package-border pti-header-normal text-center ${selectedPetType.pet_code === item.pet_code ? "active" : ""}`}
                                            style={{ 
                                                marginLeft: '150px', 
                                                padding: '16px', 
                                                border: selectedPetType !== null && selectedPetType.pet_code === item.pet_code ? '2px solid black' : 'none' 
                                            }}
                                        >
                                            <div 
                                                className="pti-header" 
                                                style={{ background: item.bg_color }}
                                            >
                                                <span 
                                                    className="title font-weight-bold" 
                                                    style={{ 
                                                        fontWeight: '700', 
                                                        fontSize: '30px' 
                                                    }}
                                                >
                                                    {
                                                        item.pet_code === "PETPKG01"
                                                        ? <FaDog 
                                                            style={{ 
                                                                marginRight: '12px', 
                                                                color: 'yellow',
                                                                marginBottom: '10px'
                                                            }} 
                                                        />
                                                        : <FaCat 
                                                            style={{ 
                                                                marginRight: '12px', 
                                                                color: 'yellow', 
                                                                marginBottom: '10px' 
                                                            }} 
                                                        />
                                                    }
                                                    {item.name}
                                                </span>
                                                <br />
                                            </div>
                                            <div 
                                                className="package-price-container package-price-display" 
                                                style={{ background: "rgb(131 219 193)" }}
                                            >
                                                <div
                                                    className="buy-package-btn cursor-pointer"
                                                    style={{ background: item.btn_color }}
                                                    onClick={() => { handle_select(item) }}
                                                >
                                                    <a style={{ padding: '6px auto' }}>
                                                        CHỌN
                                                    </a>
                                                </div>
                                            </div>
                                            <div 
                                                className="pti-content" 
                                                style={{ 
                                                    height: '300px', 
                                                    padding: '0px', 
                                                    borderBottomLeftRadius: item.pet_type !== "PET_DOG" ? '20px' : "", 
                                                    borderBottomRightRadius: item.pet_type !== "PET_DOG" ? '20px' : "" 
                                                }}
                                            >
                                                <img 
                                                    src={i == 0 ? Image1 : Image2}
                                                    alt={item.bg_image} style={{ width: "370px" }}
                                                    loading='lazy'
                                                />
                                            </div>
                                            {
                                                item.pet_type === "PET_DOG" && (
                                                    <>
                                                        <div 
                                                            className="package-price-container package-price-display" 
                                                            style={{ 
                                                                background: "#ccc", 
                                                                borderBottomLeftRadius: item.pet_type === "PET_DOG" ? '20px' : "", 
                                                                borderBottomRightRadius: item.pet_type === "PET_DOG" ? '20px' : "" 
                                                            }}
                                                        >
                                                            <div
                                                                style={{ background: item.btn_color }}
                                                                onClick={() => { handle_select(item) }}
                                                            >
                                                                <button
                                                                    className="buy-package-btn cursor-pointer"
                                                                    style={{ 
                                                                        padding: '6px auto', 
                                                                        background: "rgb(129 27 27)", 
                                                                        color: 'white', 
                                                                        fontSize: '16px' 
                                                                    }}
                                                                    onClick={() => setShowExclusiveModal(true)}
                                                                >
                                                                    CÁC GIỐNG CHÓ <br />KHÔNG BẢO HIỂM <FaBan style={{color: 'red', fontSize: '30px'}} />
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </>
                                                )
                                            }
                                        </div>
                                    </>
                                )
                            })
                        }
                    </div>
                </div>
                <div className='row mb-4'>
                    <div className='col-sm-12'>
                        <div className='text-center mt-3'>
                            <button
                                className="btn ms-1"
                                style={{ 
                                    backgroundColor: 'rgb(0, 86, 145)', 
                                    color: 'white', 
                                    borderRadius: '0px' 
                                }}
                                onClick={onClick}
                            >
                                {"TIẾP TỤC >"}
                            </button>
                        </div>
                    </div>
                </div>
            </section>
            {showExclusiveModal && (
                <Modal 
                    show={showExclusiveModal} 
                    onHide={() => setShowExclusiveModal(false)} 
                    centered 
                    size='md' 
                    backdrop="static"
                >
                    <Modal.Header closeButton>
                        <Modal.Title 
                            style={{
                                textAlign: 'center', 
                                padding: '0px', 
                                margin: '0px'
                            }}
                        >
                            <p>DANH SÁCH <span style={{color: "red"}}>GIỐNG CHÓ</span> KHÔNG ĐƯỢC BẢO HIỂM <FaBan style={{color: 'red'}} /> </p>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className='row'>
                            <div className='col-md-12'>
                                <ol>
                                    {lst_pet_decline.map((item, i) => {
                                        return (
                                            <li key={i}>{item}</li>
                                        )
                                    })}
                                </ol>
                            </div>
                        </div>
                    </Modal.Body>
                    {/* <Modal.Footer>
                    </Modal.Footer> */}
                </Modal>
            )}
        </>
    );
};

export default PetOption;

var lst_pet_decline = [
    'Chó French Bulldog',
    'Chó Pit Bull Terrier',
    'Chó Tosa Nhật Bản',
    'Chó Neapolitan',
    'Chó Argentine Dogo',
    'Chó Fila Brasileiro',
    'Chó Mastiff',
]